<template>
    <div>
      <v-app-bar v-if="widgetLoaded" app color="" class="header-top px-5" elevation="0" height="80" style="position: absolute; top: 0px;">
          <img :src="require('@/assets/icon-green.png')" alt="" />

            <div>
                <hr role="separator" aria-orientation="vertical" class="ml-6 mr-6 v-divider v-divider--vertical theme--light" style="height: 60px;" />
            </div>
  
            <h1 v-if="widget" v-show="!edit_name" class="text-lg font-semibold">{{ widget.name }}</h1>

            <i  v-show="!edit_name" class="mdi mdi-pencil-outline cursor-pointer ml-4" @click="edit_name = true;" style="font-size: 20px;"></i>

            <v-text-field
                v-if="widget"
                v-show="edit_name"
                placeholder="Widget name..."
                hide-details="true"
                v-model="widget.name"
                outlined
                dense
                style="max-width: 300px !important;"
                :append-icon="'mdi-check'"
                @click:append="edit_name = false"
            ></v-text-field>
  
        <div class="bg-blue text-white text-bold text-xs inline-block rounded py-2 px-3 cursor-pointer float-right" :class="{'spinner': savingWidget}" @click="saveWidget()" style="margin-left: auto;">ADD TO WEBSITE</div>
      </v-app-bar>
  
      <v-skeleton-loader v-if="!widgetLoaded && !$route.params.action" type="article, article, article, article, article"></v-skeleton-loader>
  
      <div id="rg-widget-editor-full-wrapper" v-if="widgetLoaded || $route.params.action" class="bg-white" style="padding-top: 80px;">
          <div class="flex flex-row flex-wrap -mx-3" style="height: 100%;">
  
              <div class="w-full lg:w-1/3 md:w-1/3 sm:w-1/3 pl-12 pt-6 border-t border-gray-100" style="height: 100%;">
                  <h2 class="text-lg font-semibold">Widget Preferences</h2>
  
                  <div class="pr-3">
  
                      <v-tabs>
                            <v-tab>Layout</v-tab>
                            <v-tab>Platform</v-tab>
                            <v-tab>Settings</v-tab>
  
                          <v-tab-item>
                              <div class="pt-4" style="width: 95%;">
                                  <v-select
                                      v-model="selected_tags"
                                      :items="available_tags"
                                      multiple
                                      outlined
                                      dense
                                      style="background: #FFFFFF;"
                                      append-icon="mdi-chevron-down"
                                      hide-details
                                      small-chips
                                      placeholder="Filter"
                                      >
                                      <template v-slot:selection="{ item, index }">
                                          <v-chip v-if="index === 0 || index === 1 || index === 2">
                                          <span>{{ item }}</span>
                                          </v-chip>
                                          <span
                                          v-if="index === 3"
                                          class="grey--text text-caption"
                                          >
                                          (+{{ selected_tags.length - 3 }} more)
                                          </span>
                                      </template>
                                  </v-select>
                              </div>
                              <div class="mt-4 rg-widget-templates" v-if="widgetLoaded"
                                  style="overflow: auto;"
                                  >
                                  <div v-for="(template, index) in selected_widget_templates" :key="index" class="rg-widget-template rg-widget-template-reviews"
                                      @click="widget.template = template.type"
                                      :class="((widget.template == template.type) ? 'selected' : '')"
                                  >
                                      <div class="rg-widget-template-name">{{ template.name }}</div>
  
                                      <div v-if="widget.template == template.type" class="rg-widget-template-selected-icon">
                                          <div class="rg-widget-template-selected-icon-inner"></div>
                                      </div>
                                      <div class="rg-widget-template-image" :class="((template.float) ? 'floater' : '')">
                                          <img :src="template.src" />
                                      </div>
  
                                      <div class="rg-widget-template-tags -mt-3 pb-2">
                                          <v-chip v-for="(item, index) in template.tags" :key="index"
                                              small
                                              color="grey lighten-2"
                                              class="mr-1"
                                              text-color="grey darken-1"
                                          >
                                              {{ item }}
                                          </v-chip>
                                      </div>
                                  </div>
                              </div>
  
                              <br clear="all" />
                          </v-tab-item>

                          <v-tab-item>
                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Your Platform URL: </strong></p>
  
                            <v-text-field
                                v-model="platform_url"
                                label=""
                                required
                                outlined
                                dense
                                hide-details
                                placeholder="e.g. https://www.google.com/search?q=your+company_name"
                            ></v-text-field>
                          </v-tab-item>
                          
                          <v-tab-item class="widget-form">
                              <div class="pt-5 rg-widget-settings" v-if="widgetLoaded"
                                  style="overflow: auto;"
                                  >
  
                                  <v-expansion-panels accordion 
                                      flat
                                      dense
                                      >
                                      <v-expansion-panel>
                                          <v-expansion-panel-header>General Settings</v-expansion-panel-header>
                                          <v-expansion-panel-content class="pt-5">
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_widget_on_website"
                                                  label="Show widget on website"
                                              ></v-switch>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_widget_on_mobile"
                                                  label="Show widget on mobile"
                                              ></v-switch>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.auto_add_new_reviews"
                                                  label="Auto add new reviews"
                                              ></v-switch>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Font Family</strong></p>
  
                                              <v-select
                                                  v-model="widget.settings.font_family"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :items="font_family_values"
                                              ></v-select>
                                          
  
                                          </v-expansion-panel-content>
                                      </v-expansion-panel>
  
                                      <v-expansion-panel class="mt-1">
                                          <v-expansion-panel-header>Appearance</v-expansion-panel-header>
                                          <v-expansion-panel-content>
                                                  <div v-if="widget.template == 'hover-1'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_1_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_1_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'hover-2'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_2_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_2_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'hover-3'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_3_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_3_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'hover-4'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_4_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_4_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'hover-5'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_5_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_5_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'hover-6'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_6_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_6_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'hover-7'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.hover_7_title"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-1'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_1_title"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-2'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_2_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_2_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-3'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_3_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_3_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-4'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_4_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_4_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-8'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_8_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_8_subtitle"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Link text </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_8_link_text"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-9'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_9_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_9_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-10'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_10_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_10_subtitle"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Link text </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_10_bottom_text"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-11'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_11_title"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-12'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_12_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_12_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-13'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_13_title"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-14'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_14_title"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-15'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_15_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_15_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-16'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_16_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_16_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-17'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_17_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_17_subtitle"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Bottom Text </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_17_bottom_text"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-18'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_18_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_18_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="widget.template == 'badge-19'" class="mb-5">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_19_title"
                                                          label=""
                                                      ></v-text-field>
  
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Sub Title </strong></p>
  
                                                      <v-text-field style="margin-top: -20px; margin-bottom: -20px;"
                                                          v-model="widget.settings.badge_19_subtitle"
                                                          label=""
                                                      ></v-text-field>
                                                  </div>
  
                                                  <div v-if="
                                                      widget.template.indexOf('hover') != -1 ||
                                                      widget.template.indexOf('badge') != -1
                                                  " class="bg-gray-100 rounded p-2 mb-4">
                                                      <div class="bg-gray-100 text-xs rounded">
                                                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[reviews_nr]</span> will be replaced by the number of total reviews</div>
                                                          <div class="text-gray-600"><span class="font-semibold p-1 text-gray-900">[rating_nr]</span> will be replaced with the current average rating</div>
                                                          <div class="text-gray-600"><span class="font-semibold p-1 text-gray-900">[bold][/bold]</span> will bolden that part of text</div>
                                                      </div>
                                                  </div>
  
                                                  <div class="float-left has-color-picker pr-4">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Star Color</strong></p>
  
                                                      <color-picker v-model="widget.settings.star_color"></color-picker>
                                                  </div>
  
  
                                                  <div class="float-left has-color-picker pr-4">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Background</strong></p>
  
                                                      <color-picker v-model="widget.settings.background_color"></color-picker>
                                                  </div>
  
  
                                                  <div class="float-left has-color-picker pr-4">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Text Color</strong></p>
  
                                                      <color-picker v-model="widget.settings.text_color"></color-picker>
                                                  </div>
  
                                                  <div class="float-left has-color-picker pr-4">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Footer Text Color</strong></p>
  
                                                      <color-picker v-model="widget.settings.footer_text_color"></color-picker>
                                                  </div>
  
                                                  <div class="float-left has-color-picker pr-4">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Footer Link Color</strong></p>
  
                                                      <color-picker v-model="widget.settings.footer_link_color"></color-picker>
                                                  </div>
  
                                                  <br clear="all" />
                                                  
                                                  <template v-if="widget.template.indexOf('embed') == -1">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Position</strong></p>
  
                                                      <v-select
                                                          v-model="widget.settings.align"
                                                          outlined
                                                          dense
                                                          hide-details
                                                          :items="align_values"
                                                      ></v-select>
                                                  </template>
                                          </v-expansion-panel-content>
                                      </v-expansion-panel>
  
                                      <v-expansion-panel
                                          v-show="
                                              widget.template != 'rotator-1' &&
                                              widget.template != 'rotator-2' &&
                                              widget.template != 'rotator-3' &&
                                              widget.template != 'rotator-4' &&
                                              widget.template != 'rotator-5'
                                          "
                                      class="mt-1">
                                          <v-expansion-panel-header>
                                              <span v-if="widget_data.widget.template.indexOf('sidebar') != -1">Sidebar</span>
                                              <span v-else>Header</span>
                                          </v-expansion-panel-header>
                                          <v-expansion-panel-content>
                                              <p v-show="widget_data.widget.template.indexOf('sidebar') == -1" class="mb-1 text-xs mb-2 mt-4"><strong>Header alignment</strong></p>
  
                                              <div v-show="widget_data.widget.template.indexOf('sidebar') == -1" class="clearfix"></div>
  
                                              <v-btn-toggle
                                                  v-show="widget_data.widget.template.indexOf('sidebar') == -1"
                                                  v-model="widget.settings.header_alignment"
                                                  borderless
                                              >
                                                  <v-btn value="left" small>
                                                  <span class="hidden-sm-and-down"></span>
  
                                                  <v-icon right>
                                                      mdi-format-align-left
                                                  </v-icon>
                                                  </v-btn>
  
                                                  <v-btn value="center" small>
                                                  <span class="hidden-sm-and-down"></span>
  
                                                  <v-icon right>
                                                      mdi-format-align-center
                                                  </v-icon>
                                                  </v-btn>
  
                                                  <v-btn value="right" small>
                                                  <span class="hidden-sm-and-down"></span>
  
                                                  <v-icon right>
                                                      mdi-format-align-right
                                                  </v-icon>
                                                  </v-btn>
                                              </v-btn-toggle>
  
                                              <div v-show="widget_data.widget.template.indexOf('sidebar') == -1" class="clearfix"></div>
  
                                              <p v-show="widget_data.widget.template.indexOf('sidebar') == -1" class="mb-1 text-xs mb-2 mt-4"><strong>Space between header and feed</strong></p>
  
                                              <v-select
                                                  v-show="widget_data.widget.template.indexOf('sidebar') == -1"
                                                  v-model="widget.settings.space_between_header_and_feed"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :items="space_between_header_and_feed_values"
                                              ></v-select>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Title </strong></p>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_title"
                                                  label="Show title"
                                              ></v-switch>
  
                                              <v-text-field
                                                  v-model="widget.settings.title"
                                                  label=""
                                                  required
                                                  outlined
                                                  dense
                                                  hide-details
                                              ></v-text-field>
  
                                              <div class="flex flex-row flex-wrap">
                                                  <div class="w-full sm:w-1/2 pr-3">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Title Font Size</strong></p>
  
                                                      <v-select
                                                          v-model="widget.settings.title_font_size"
                                                          outlined
                                                          dense
                                                          hide-details
                                                          :items="title_font_sizes"
                                                      ></v-select>
                                                  </div>
                                                  <div class="w-full sm:w-1/2 pl-3">
                                                      <div class="has-color-picker pr-4 -mr-4">
                                                          <p class="mb-1 text-xs mb-2 mt-4"><strong>Title Color</strong></p>
  
                                                          <color-picker v-model="widget.settings.title_color"></color-picker>
                                                      </div>
                                                  </div>
                                              </div>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Subtitle</strong></p>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_subtitle"
                                                  label="Show subtitle"
                                              ></v-switch>
  
                                              <div class="flex flex-row flex-wrap">
                                                  <div class="w-full sm:w-1/2 pr-3">
                                                      <p class="mb-1 text-xs mb-2 mt-4"><strong>Subtitle Font Size</strong></p>
  
                                                      <v-select
                                                          v-model="widget.settings.subtitle_font_size"
                                                          outlined
                                                          dense
                                                          hide-details
                                                          :items="subtitle_font_sizes"
                                                      ></v-select>
                                                  </div>
                                                  <div class="w-full sm:w-1/2 pl-3">
                                                      <div class="has-color-picker pr-4 -mr-4">
                                                          <p class="mb-1 text-xs mb-2 mt-4"><strong>Subtitle Color</strong></p>
  
                                                          <color-picker v-model="widget.settings.subtitle_color"></color-picker>
                                                      </div>
                                                  </div>
                                              </div>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Write a review</strong></p>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_leave_a_review_button"
                                                  label="Show button"
                                              ></v-switch>
  
                                          </v-expansion-panel-content>
                                      </v-expansion-panel>
  
                                      <v-expansion-panel
                                          v-show="widget.template != 'rotator-1' &&
                                                  widget.template != 'rotator-2' &&
                                                  widget.template != 'rotator-3' &&
                                                  widget.template != 'rotator-4' &&
                                                  widget.template != 'rotator-5'"
                                          class="mt-1">
                                          <v-expansion-panel-header>Feed</v-expansion-panel-header>
                                          <v-expansion-panel-content>
  
                                              <div v-show="widget.template != 'embed-slider' && widget.template != 'embed-carousel' && widget.template != 'embed-nuggets' && widget.template != 'embed-sidebar-slider' && widget.template != 'embed-sidebar-nuggets'">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Padding between reviews</strong></p>
  
                                                  <v-select
                                                      v-model="widget.settings.padding_between_reviews"
                                                      outlined
                                                      dense
                                                      hide-details
                                                      :items="padding_between_reviews_options"
                                                  ></v-select>
                                              </div>
  
                                              <div v-show="widget.template == 'embed-slider' || widget.template == 'embed-carousel' || widget.template == 'embed-nuggets' || widget.template == 'embed-sidebar-slider' || widget.template == 'embed-sidebar-nuggets'">
                                                  <label>Autoplay Span</label>
  
                                                  <v-select
                                                      v-model="widget.settings.auto_play_span"
                                                      outlined
                                                      dense
                                                      hide-details
                                                      :items="auto_play_span_options"
                                                  ></v-select>
                                              </div>
  
                                              <div v-show="(widget.template.indexOf('rotator') != -1)">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Rotator Delay</strong></p>
  
                                                  <v-select
                                                      v-model="widget.settings.rotator_delay"
                                                      outlined
                                                      dense
                                                      hide-details
                                                      :items="rotator_delay_options"
                                                  ></v-select>
                                              </div>
  
                                              <div v-show="widget.template == 'embed-grid' || widget.template == 'embed-directory'">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Columns</strong></p>
  
                                                  <v-select
                                                      v-model="widget.settings.feed_columns"
                                                      outlined
                                                      dense
                                                      hide-details
                                                      :items="feed_columns_options"
                                                  ></v-select>
                                              </div>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Reviews per page</strong></p>
  
                                              <v-text-field
                                                  v-model="widget.settings.reviews_per_page"
                                                  type="number"
                                                  hide-details
                                                  min="1"
                                                  outlined
                                                  dense
                                              ></v-text-field>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Review Box</strong></p>
  
                                              <v-select
                                                  class="mb-5"
                                                  v-model="widget.settings.reviewer_name"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :items="reviewer_name_options"
                                              ></v-select>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_profile_pic"
                                                  label="Show avatar"
                                              ></v-switch>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_date"
                                                  label="Show date"
                                              ></v-switch>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_review_site"
                                                  label="Show review site"
                                              ></v-switch>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_stars"
                                                  label="Show stars"
                                              ></v-switch>
  
                                              <v-switch color="#48bb78" inset dense hide-details :ripple="false"
                                                  v-model="widget.settings.show_only_profile_pic"
                                                  label="Only show reviews with profile pic"
                                              ></v-switch>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Review text</strong></p>
  
                                              <v-radio-group
                                                  row
                                                  v-model="widget.settings.feed_review_text"
                                                  >
                                                  <v-radio
                                                      :ripple="false"
                                                      label="Full Text"
                                                      value="full-text"
                                                      on-icon="mdi-circle"
                                                      off-icon="mdi-circle-outline"
                                                  ></v-radio>
                                                  <v-radio
                                                      :ripple="false"
                                                      label="Short Text"
                                                      value="short-text"
                                                      on-icon="mdi-circle"
                                                      off-icon="mdi-circle-outline"
                                                  ></v-radio>
                                              </v-radio-group>
  
                                              <p class="mb-1 text-xs mb-2"><strong>Text toggle</strong></p>
  
                                              <v-switch
                                                  color="#48bb78" inset dense hide-details
                                                  v-model="widget.settings.show_text_toggle"
                                                  label="Show text toggle"
                                              ></v-switch>
  
                                              <v-text-field
                                                  v-model="widget.settings.text_toggle"
                                                  label=""
                                                  required
                                                  value="See More"
                                                  hide-details
                                                  outlined
                                                  dense
                                              ></v-text-field>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Pagination</strong></p>
  
                                              <v-radio-group
                                                  row
                                                  v-model="widget.settings.feed_pagination"
                                                  >
                                                  <v-radio
                                                  :ripple="false"
                                                      label="Pagination"
                                                      value="pagination"
                                                      on-icon="mdi-circle"
                                                      off-icon="mdi-circle-outline"
                                                  ></v-radio>
                                                  <v-radio
                                                  :ripple="false"
                                                      label="Button"
                                                      value="button"
                                                      on-icon="mdi-circle"
                                                      off-icon="mdi-circle-outline"
                                                  ></v-radio>
                                              </v-radio-group>
                                              
                                          </v-expansion-panel-content>
                                      </v-expansion-panel>
  
                                      <v-expansion-panel
                                          v-show="widget.template.indexOf('badge') != -1"
                                          class="mt-1">
                                          <v-expansion-panel-header>Badge</v-expansion-panel-header>
                                          <v-expansion-panel-content>
  
                                              <div>
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Display</strong></p>
  
                                                  <v-select
                                                      v-model="widget.settings.badge_display"
                                                      outlined
                                                      dense
                                                      hide-details
                                                      :items="badge_display_options"
                                                  ></v-select>
                                              </div>
  
                                              <div v-show="widget.template == 'badge-2'" class="float-left has-color-picker pr-4">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Top Color</strong></p>
  
                                                  <color-picker v-model="widget.settings.badge2_top_color"></color-picker>
                                              </div>
  
                                              <div v-show="widget.template == 'badge-3'" class="float-left has-color-picker pr-4">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Bottom Color</strong></p>
  
                                                  <color-picker v-model="widget.settings.badge3_bottom_color"></color-picker>
                                              </div>
  
                                              <div v-show="widget.template == 'badge-4'" class="float-left has-color-picker pr-4">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Color</strong></p>
  
                                                  <color-picker v-model="widget.settings.badge4_color"></color-picker>
                                              </div>
  
                                              <div v-show="widget.template == 'badge-7'" class="float-left has-color-picker pr-4">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Label Color</strong></p>
  
                                                  <color-picker v-model="widget.settings.badge7_bottom_color"></color-picker>
                                              </div>
  
                                              <div v-show="widget.template == 'badge-11'" class="float-left has-color-picker pr-4">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Background Color</strong></p>
  
                                                  <color-picker v-model="widget.settings.badge11_background_color"></color-picker>
                                              </div>
  
                                              <div v-show="widget.template == 'badge-17'" class="float-left has-color-picker pr-4">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Bottom Color</strong></p>
  
                                                  <color-picker v-model="widget.settings.badge17_bottom_color"></color-picker>
                                              </div>
  
                                              <div v-show="widget.template == 'badge-19'" class="float-left has-color-picker pr-4">
                                                  <p class="mb-1 text-xs mb-2 mt-4"><strong>Bottom Color</strong></p>
  
                                                  <color-picker v-model="widget.settings.badge19_bottom_color"></color-picker>
                                              </div>
                                              
                                          </v-expansion-panel-content>
                                      </v-expansion-panel>
  
                                      <v-expansion-panel class="mt-1">
                                          <v-expansion-panel-header>Filters</v-expansion-panel-header>
                                          <v-expansion-panel-content>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Reviews Sorting</strong></p>
  
                                              <v-select
                                                  v-model="widget.settings.reviews_sorting"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :items="sorting_values"
                                                  prepend-inner-icon="mdi-sort"
                                                  class="mt-1"
                                              ></v-select>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Minimum rating</strong></p>
  
                                              <v-select
                                                  v-model="widget.settings.minimum_rating"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :items="ratings_values"
                                                  prepend-inner-icon="mdi-star"
                                              ></v-select>
  
                                              <p class="mb-1 text-xs mb-2 mt-4"><strong>Filter by keyword</strong></p>
  
                                              <v-combobox
                                                  v-model="widget.settings.filter_keywords"
                                                  :items="[]"
                                                  multiple
                                                  small-chips
                                                  color="blue"
                                                  outlined
                                                  dense
                                                  hide-details
                                              ></v-combobox>
  
                                              <p class="text-xs text-gray-600 mt-2">Use a comma (,) to filter reviews by one OR more keywords, and minus (-) to exclude. Press 'Enter' to apply a keyword.</p>
                                              
                                          </v-expansion-panel-content>
                                      </v-expansion-panel>
  
                                      <v-expansion-panel class="mt-1">
                                          <v-expansion-panel-header>Custom CSS</v-expansion-panel-header>
                                          <v-expansion-panel-content>
                                              
                                              <div class="w-full pt-5">
                                                      <textarea name="" id="" rows="10" class="text-sm block appearance-none focus:outline-none focus:border-gray-500 w-full bg-gray-100 hover:border-grey px-4 py-4 rounded" maxlength="1000" v-model="widget.settings.custom_css" placeholder="/* Example */
  h3.reviewer-title {
      font-size: 15px;
  }" ></textarea>
  
                                                      <p class="text-xs text-gray-600 mt-4">Enter custom CSS to change the UI of the widget.</p>
                                                  </div>
  
                                          </v-expansion-panel-content>
                                      </v-expansion-panel>
  
                                      <v-expansion-panel class="mt-1" v-show="(widget.template.indexOf('embed') == -1)">
                                          <v-expansion-panel-header>Preview on URL</v-expansion-panel-header>
                                          <v-expansion-panel-content>
                                              
                                              <div class="w-full pt-5">
                                                  <v-text-field
                                                      label="Preview URL"
                                                      outlined
                                                      dense
                                                      hide-details
                                                      v-model="preview_url"
                                                      placeholder="https://..."
                                                      class="mb-2"
                                                  ></v-text-field>
  
                                                  <div class="bg-blue text-white text-bold text-xs inline-block rounded py-2 px-3 cursor-pointer" style="margin-left: auto;" @click="previewUrl()">PREVIEW</div>
                                              </div>
  
                                          </v-expansion-panel-content>
                                      </v-expansion-panel>
                                  </v-expansion-panels>
                              </div>
                          </v-tab-item>
                      
                      </v-tabs>
                    </div>
              </div>
  
              <div class="w-full sm:w-2/3 bg-gray-100 -mt-7 pb-0" style="height: 100%; border-left: solid 1px #f5f5f5; border-top: solid 1px #f5f5f5;">
                  <div class="w-full" style="height: 100%;">
                      <div class="pl-4 bg-white" style="height: 100%;">
                          <div class="mb-4 pt-4">
                              <h2 class="font-bold text-gray-800">Preview</h2>
                          </div>
                          <div id="rg-widget-preview-wrapper" class="w-full" style="width: 100%; position: relative; overflow: auto; padding-top: 10px;">
                              <WidgetsRender :preview_widget="widget" :fw="fw" :key="widgetPreviewKey" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  
      <!--<div>
        <button class="bg-gray-200 py-2 px-3 text-gray-600 hover:bg-gray-300 rounded" @click="$refs.dialogPlatform.show()"><font-awesome-icon icon="plus-circle" class="mr-1"></font-awesome-icon> Add platform</button>
      </div>-->
    </div>
  
  </template>
  
  <script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import urlParser from 'url-parse'
  import PanelNavigation from '@/components/PanelNavigation.vue'
  import WidgetsRender from '@/views/panel/Business/Widgets/Render/Index.vue'
  import ColorPicker from '@/components/common/ColorPicker.vue'
  
  export default {
    metaInfo () {
      return {
        title: "Widgets Edit | " + this.$appName
      }
    },
    components: {
      PanelNavigation,
      WidgetsRender,
      ColorPicker
    },
    data () {
      return {
          edit_name: false,
  
          available_tags: [
              'embed', 'grid', 'hover', 'column', 'slider', 'carousel', 'page', 'hover', 'table', 'rotator', 'directory', 'list', 'badge', 'nuggets'
          ],
          selected_tags: [],
  
          widget_templates: [
              {
                  'type': 'embed-grid',
                  'name': 'Grid',
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-grid.a04e8cdf.png',
                  'tags': ['embed', 'grid']
              },
              {
                  'type': 'hover-1',
                  'name': 'Hover 1',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/hover-1.a52b4996.png',
                  'tags': ['hover']
              },
              {
                  'type': 'hover-2',
                  'name': 'Hover 2',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/hover-2.cbe3ca0f.png',
                  'tags': ['hover']
              },
              {
                  'type': 'embed-column',
                  'name': 'Column',
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-column.b6f47dbf.png',
                  'tags': ['embed', 'column']
              },
              {
                  'type': 'embed-slider',
                  'name': 'Slider',
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-slider.881b15e8.png',
                  'tags': ['embed', 'slider']
              },
              {
                  'type': 'embed-carousel',
                  'name': 'Carousel',
                  'src': "https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-carousel.e1544dca.png",
                  'tags': ['embed', 'carousel']
              },
              {
                  'type': 'embed-page',
                  'name': 'Page',
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-page.3b7fa5ee.png',
                  'tags': ['embed', 'page']
              },
              {
                  'type': 'hover-3',
                  'name': 'Hover 3',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/hover-3.4737da6f.png',
                  'tags': ['hover']
              },
              {
                  'type': 'hover-4',
                  'name': 'Hover 4',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/hover-4.d3de8dd6.png',
                  'tags': ['hover']
              },
              {
                  'type': 'embed-table',
                  'name': 'Table',
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-table.99f07644.png',
                  'tags': ['embed', 'table']
              },
              {
                  'type': 'hover-5',
                  'name': 'Hover 5',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/hover-5.9c196dab.png',
                  'tags': ['hover']
              },
              {
                  'type': 'hover-6',
                  'name': 'Hover 6',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/hover-6.a4f33373.png',
                  'tags': ['hover']
              },
              {
                  'type': 'rotator-1',
                  'name': 'Rotator 1',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/rotator-1.01dc4942.png',
                  'tags': ['rotator']
              },
              {
                  'type': 'rotator-2',
                  'name': 'Rotator 2',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/rotator-2.2ed0db40.png',
                  'tags': ['rotator']
              },
              {
                  'type': 'rotator-3',
                  'name': 'Rotator 3',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/rotator-3.png',
                  'tags': ['rotator']
              },
              {
                  'type': 'rotator-4',
                  'name': 'Rotator 4',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/rotator-4.png',
                  'tags': ['rotator']
              },
              {
                  'type': 'rotator-5',
                  'name': 'Rotator 5',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/rotator-5.png',
                  'tags': ['rotator']
              },
              {
                  'type': 'embed-directory',
                  'name': 'Directory',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-directory.png',
                  'tags': ['embed', 'directory']
              },
              {
                  'type': 'hover-7',
                  'name': 'Hover',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/hover-7.png',
                  'tags': ['hover']
              },
              {
                  'type': 'embed-list',
                  'name': 'List',
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-list.png',
                  'tags': ['embed', 'list']
              },
              {
                  'type': 'badge-1',
                  'name': 'Badge 1',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-1.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-2',
                  'name': 'Badge 2',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-2.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-3',
                  'name': 'Badge 3',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-3.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-4',
                  'name': 'Badge 4',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-4.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-5',
                  'name': 'Badge 5',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-5.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-6',
                  'name': 'Badge 6',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-6.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-7',
                  'name': 'Badge 7',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-7.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-8',
                  'name': 'Badge 8',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-8.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-9',
                  'name': 'Badge 9',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-9.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-10',
                  'name': 'Badge 10',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-10.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-11',
                  'name': 'Badge 11',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-11.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-12',
                  'name': 'Badge 12',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-12.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-13',
                  'name': 'Badge 13',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-13.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-14',
                  'name': 'Badge 14',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-14.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-15',
                  'name': 'Badge 15',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-15.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-16',
                  'name': 'Badge 16',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-16.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-17',
                  'name': 'Badge 17',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-17.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-18',
                  'name': 'Badge 18',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-18.png',
                  'tags': ['badge']
              },
              {
                  'type': 'badge-19',
                  'name': 'Badge 19',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/badge-19.png',
                  'tags': ['badge']
              },
              {
                  'type': 'embed-nuggets',
                  'name': 'Nuggets',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/embed-nuggets.png',
                  'tags': ['embed', 'nuggets']
              },
              {
                  'type': 'embed-sidebar-slider',
                  'name': 'Sidebar Slider',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-sidebar-slider.png',
                  'tags': ['embed', 'slider']
              },
              {
                  'type': 'embed-sidebar-nuggets',
                  'name': 'Sidebar Nuggets',
                  'float': true,
                  'src': 'https://regro-cdn.s3.amazonaws.com/images/widget-templates/reviews/embed-sidebar-nuggets.png',
                  'tags': ['embed', 'nuggets']
              },
          ],
  
          selected_widget_templates: [],
  
        savingWidget: false,
        saved: false,
        widget: {
          name: "",
            settings: {
              star_color: "#FFAA00",
              background_color: "#FFFFFF",
              text_color: "#000000",
                minimum_rating: "5 stars",
                reviews_sorting: "Most Recent First"
            }
        },
        widgetPreviewKey: 1,
        fw: null,
        widgetLoaded: false,
        font_family_values: [
            "Open Sans (Google)",
            "Source Sans Pro (Google)",
            "Roboto (Google)",
            "Lato (Google)",
            "PT Sans (Google)",
            "EB Garamond (Google)",
            "Raleway (Google)",
            "Ubuntu (Google)",
            "Montserrat (Google)",
            "Courgette (Google)",
            "Arial",
            "Helvetica",
            "Sans-serif",
        ],
        space_between_header_and_feed_values: ['0px','5px','10px','15px','20px','25px','30px','35px','40px','45px','50px','55px','60px','65px','70px','75px','80px','85px','90px','95px','100px','105px','110px','115px','120px','125px','130px','135px','140px','145px','150px','155px','160px','165px','170px','175px','180px','185px','190px','195px','200px'],
        title_font_sizes: ['10px','11px','12px','13px','14px','15px','16px','17px','18px','19px','20px','21px','22px','23px','24px','25px','26px','27px','28px','29px','30px','31px','32px','33px','34px'],
        subtitle_font_sizes: ['10px','11px','12px','13px','14px','15px','16px','17px','18px','19px','20px','21px','22px','23px','24px','25px','26px','27px','28px','29px','30px','31px','32px','33px','34px'],
        padding_between_reviews_options: ['None','1px','2px','3px','4px','5px','6px','7px','8px','9px','10px','15px','20px','25px','30px'],
        badge_display_options: ['Embed','Floating'],
        auto_play_span_options: ['No autoplay','1.5s','2s','2.5s','3s','3.5s','4s','4.5s','5s','5.5s','6s','6.5s','7s','7.5s','8s','8.5s','9s','9.5s','10s','15s','30s','45s','60s','90s','120s'],
        rotator_delay_options: ['1s', '2s', '3s', '4s', '5s', '10s', '20s', '30s', '60s'],
        feed_columns_options: ["Dynamic", "1", "2", "3", "4", "5"],
        reviewer_name_options: ["Show name", "Show first name", "Hide name"],
        ratings_values: [
            "5 stars",
            "4 stars",
            "3 stars",
            "2 stars",
            "All",
        ],
        sorting_values: [
            "Most Recent First",
          "Oldest First",
          "Best Rating First",
          "Reviews With Text First",
            "Random",
        ],
        align_values: [
            "Left",
            "Right"
        ],
        preview_url: "",
        platform_url: ""
      }
    },
    computed: {
      ...mapState({

      })
    },
    methods: {
        getMessage2: function() {
            return '<script type="application/ld+json" class="rg-reviews-schema">' + this.getReviewsSchemaCode2() +  '<\/script>';
        },
      onCopy: function (e) {
          this.$swal({
              title: "Copied",
              text: "Code copied successfully!",
              showCancelButton: false,
              });
      },
      onError: function (e) {
          alert('Failed to copy the text to the clipboard')
          console.log(e);
      },
      getHostname(url) {
        return urlParser(url).hostname.replace("www.", "")
      },
      getPathname(url) {
        return urlParser(url).pathname
      },
      getIconUrl(url) {
  
        return require('@/assets/images/review-services/www.' + this.getHostname(url) + ".png");
  
      },
      getWidget: async function() {
          try {
              // let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/get-widget/' + this.$route.params.token, {
  
              // } , {
              // headers: {
              // 	'Authorization': 'Bearer ' + this.$store.state.auth.jwt
              // },
              // })
  
              let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/widgets/get-data/' + this.widget.token, {
  
              } , {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.state.auth.jwt
              },
              })
              
              this.widget = response.data.widget_data.widget
              this.widget_data = response.data.widget_data
              
              // this.widget = response.data.widget
              this.widgetLoaded = true
          }catch(e){
              console.log(e)
          }
      },
      saveWidget: async function() {

          if (!this.platform_url) {
            this.$swal({
                title: "",
                text: "Please enter your platform URL!",
                showCancelButton: false,
                icon: 'error',
            });

            return;
          }

          this.$cookie.set("RGEmbedWidgetToken", this.widget.token);
          this.$cookie.set("RGEmbedPlatformURL", this.platform_url);

            this.savingWidget = true
            this.saved = true
            let self = this
            try {
                let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/update-widget/' + this.widget.token, {
                    widget: self.widget
                } , {
                headers: {

                    },
                })
                setTimeout(function() {
                    self.savingWidget = false
                    self.saved = false
                }, 500)
            }catch(e){
                console.log(e)
            }

          this.$router.push('/signup/b/plan/67347345ts03');
      },
      updatePreview: function() {
          if (this.widget.template.indexOf('embed') != -1) {
              this.align_values = ["Full"];
              this.widget.settings.align = "Full";
          }
          else {
              if (this.widget.template.indexOf('badge') != -1) {
                  if (this.widget.settings.badge_display == "Embed") {
                      this.align_values = ["Full"];
                      this.widget.settings.align = "Full";
                  }
                  else {
                      this.align_values = ["Left", "Right"];
                      if (this.widget.settings.align == "Full")
                          this.widget.settings.align = "Left";
                  }
              }
              else {
                  this.align_values = ["Left", "Right"];
                  if (this.widget.settings.align == "Full")
                      this.widget.settings.align = "Left";
              }
          }
  
          let widget_stringified = JSON.stringify(this.widget);
  
          let widget_encoded = btoa(unescape(encodeURIComponent(widget_stringified)));
          this.fw = widget_encoded;
  
          this.widgetPreviewKey++;
      },
      previewUrl() {
          let widget_stringified = JSON.stringify(this.widget);
          let widget_encoded = btoa(widget_stringified);
          let new_preview_url = '/widgets/preview/' + this.$route.params.token + "?fw=" + widget_encoded + "&url=" + encodeURIComponent(this.preview_url);
      
          window.open(new_preview_url)
      },
        createWidget: async function() {
            let self = this
            try {
                let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/create-widget', {

                } , {
                headers: {
                },
                })
                
                self.widget = response.data.widget;
                await self.getWidget();
            }catch(e){
                console.log(e)
            }
        },

        styleBody() {
            var styleSheet = document.createElement("style")
            styleSheet.type = "text/css"
            styleSheet.innerText = 'body::-webkit-scrollbar { display: none;  }';
            styleSheet.id = 'rg-form-embed-custom-css-sheet';
            document.body.appendChild(styleSheet);
        }
    },
    created() {

      for (var i in this.widget_templates)
          this.selected_widget_templates.push(JSON.parse(JSON.stringify(this.widget_templates[i])));
    
       this.createWidget();

       this.styleBody();

       var body = document.body,
            html = document.documentElement;

        var windowHeight = Math.max( body.scrollHeight, body.offsetHeight, 
                            html.clientHeight, html.scrollHeight, html.offsetHeight );
      
      function setTemplatesHeight() {
          try {
              let new_height = windowHeight - 280;
              document.querySelector(".rg-widget-templates").style.height = new_height + "px";
              document.querySelector(".rg-widget-settings").style.height = new_height + "px";
          }
          catch(e) {
              setTimeout(function() {
                  setTemplatesHeight();
              }, 100);
          }
      }
  
      setTemplatesHeight();
  
      function setPreviewHeight() {
          try {
              let new_height = windowHeight - 180;
              document.querySelector("#rg-widget-preview-wrapper").style.height = new_height + "px";
          }
          catch(e) {
              setTimeout(function() {
                  setPreviewHeight();
              }, 100);
          }
      }
  
      setPreviewHeight();
  
      function setFullHeight() {
          try {
              let new_height = windowHeight - 10;
              document.querySelector("#rg-widget-editor-full-wrapper").style.height = new_height + "px";
          }
          catch(e) {
              setTimeout(function() {
                  setFullHeight();
              }, 100);
          }
      }
  
      setFullHeight();
    },
      watch: {
          'widget.template': {
              handler() {
                  this.updatePreview()
              }
          },
          'widget.settings': {
              deep: true,
  
              handler() {
                  this.updatePreview()
              }
          },
  
          'selected_tags': {
              handler() {
                  this.selected_widget_templates = [];
  
                  if (this.selected_tags == 0) {
                      for (var i in this.widget_templates)
                          this.selected_widget_templates.push(JSON.parse(JSON.stringify(this.widget_templates[i])));
                  }
                  else {
                      for (var i in this.widget_templates) {
                          let template = this.widget_templates[i];
  
                          for (var j in this.selected_tags) {
                              let tag = this.selected_tags[j];
  
                              if (template.tags.includes(tag))
                                  this.selected_widget_templates.push(JSON.parse(JSON.stringify(template)));
                          }
                      }
                  }
              }
          }
      }
  }
  </script>
  
  <style scoped>
    @import url('https://fonts.googleapis.com/css?family=Open+Sans&family=Source+Sans+Pro&family=Roboto&family=Lato&family=PT+Sans&family=EB+Garamond&family=Raleway&family=Ubuntu&family=Montserrat&family=Courgette');
  
    .widget-form input, select {
      border-style: solid;
    }
  
    .widget-form .has-color-picker button {
        width: 90px !important;
    }
  
    .widget-form #preview_iframe {
        width: 100%;
        height: 650px;
        border: solid 1px #CCCCCC;
    }
  
    .widget-form .v-input--radio-group.v-input--radio-group--row .v-radio {
        width: 130px;
    }
  
    .widget-form .v-label {
        padding-top: 10px !important;
    }
  
    .widget-form .v-input--selection-controls.v-input--switch {
        height: 35px;
    }
  
    .widget-form label {
        font-weight: bold;
        font-size: 14px;
        /* margin-top: 10px; */
    }
  </style>