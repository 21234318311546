<template>

  <div v-if="showModal" @click.self="showModal = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column" style="background-color: rgba(0,0,0,.2);">

    <div class="w-full sm:w-2/5 lg:w-2/5 bg-white rounded-lg" style="position: fixed; right: 0px; top: 64px; bottom: 0px">

      <div class="rg-modal-header">
          <span v-if="mode == 'add'">{{ capitalizeFirstLetter(item.type) }}</span>
          <span v-else>{{ capitalizeFirstLetter(item.type) }}</span>

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
        </div>

      <div class="p-6" style="height: calc(100% - 110px);">

        <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          {{success}}
        </div>

        <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div v-html="error"></div>
        </div>

        <div class="mb-3">
            <p class="mb-1 text-xs mb-2"><strong>
                Title
            </strong></p>
            <input v-model="item.title" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
        </div>

        <div v-if="item.filters">
          <div v-for="(item, index) in item.filters" :key="'filter-' + index" class="flex flex-wrap pb-2" style="position: relative;">
            <a href="javascript: void(0);" @click.stop="deleteFilter(index)" class="" style="position: absolute; right: 0px; top: 7px;"><i class="mdi mdi-delete" style="color: gray; font-size: 20px;"></i></a>

            <div class="w-full md:w-1/3 pr-3">
              <input :value="item.name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" disabled>
            </div>

            <div v-if="item.type == 'custom_field'" class="w-full md:w-1/3 pr-3">
              <v-select
                  :items="['Is', 'Is not', 'Contains', 'Does not contain', 'Is not empty', 'Is empty']"
                  v-model="item.operator"
                  outlined
                  dense
                  label="Operator"
                  hide-details
                >
              </v-select>
            </div>

            <div v-if="item.type == 'custom_field'" class="w-full md:w-1/3 pr-6">
              <input v-model="item.value" label="Value" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'Has Tag' || item.name == 'Tags Added' || item.name == 'Tags Removed' || item.name == 'Tags Removed' || item.name == 'Has Tag' || item.name == 'Doesn\'t Have Tag')" class="w-full md:w-2/3 pr-6">
              <v-combobox
                v-model="item.tags"
                :items="['15 min call', 'application complete', 'completed survey', 'go live', 'lead', 'payment made', 'scheduled with calendly', 'value video optin']"
                multiple
                outlined
                dense
                hide-details
              ></v-combobox>
            </div>

            <div v-if="item.type == 'standard_field' && item.name == 'DND'" class="w-full md:w-1/3 pr-3">
              <v-select
                  :items="['Has Changed To']"
                  v-model="item.operator"
                  outlined
                  dense
                  hide-details
                >
              </v-select>
            </div>

            <div v-if="item.type == 'standard_field' && item.name == 'DND'" class="w-full md:w-1/3 pr-6">
              <v-select
                  :items="['Anything', 'Enabled', 'Disabled']"
                  v-model="item.value"
                  outlined
                  dense
                  hide-details
                >
              </v-select>
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'DND Flag is')" class="w-full md:w-2/3 pr-6">
              <v-select
                v-model="item.tags"
                :items="['Disabled DND for all channels', 'Disabled DND for specific channels', 'Enabled DND for all channels', 'Enabled DND for specific channels']"
                outlined
                dense
                hide-details
              ></v-select>
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'Contains Phrase' || item.name == 'Exact Match Phrase')" class="w-full md:w-2/3 pr-6">
              <input v-model="item.value" label="Value" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'Intent Type')" class="w-full md:w-2/3 pr-6">
              <v-select
                v-model="item.tags"
                :items="['Positive/Yes', 'Negative/No']"
                outlined
                dense
                hide-details
              ></v-select>
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'Replied To Campaign' || item.name == 'Clicked On Campaign' || item.name == 'Submitted To Campaign')" class="w-full md:w-2/3 pr-6">
              <v-autocomplete
                :items="campaigns"
                dense
                outlined
                clearable
                item-value="id"
                item-text="name"
                hide-details
                v-model="item.campaign"
              >

              </v-autocomplete>
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'Reply Channel')" class="w-full md:w-2/3 pr-6">
              <v-select
                v-model="item.tags"
                :items="['Email', 'SMS']"
                outlined
                dense
                hide-details
              ></v-select>
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'After no. of days' || item.name == 'Before no. of days')" class="w-full md:w-2/3 pr-6">
              <input v-model="item.value" label="Value" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'Day is')" class="w-full md:w-2/3 pr-6">
              <v-select
                v-model="item.value"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]"
                outlined
                dense
                hide-details
              ></v-select>
            </div>

            <div v-if="item.type == 'standard_field' && (item.name == 'Month is')" class="w-full md:w-2/3 pr-6">
              <v-select
                v-model="item.value"
                :items="['January', 'Februaru', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']"
                outlined
                dense
                hide-details
              ></v-select>
            </div>
          </div>
        </div>

        <div class="pt-2">
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <a href="javascript: void(0);" style="font-size: 12px;"
                v-bind="attrs"
                v-on="on"
              >+ Add Filter
              </a>
            </template>

            <v-list class="table-menu-actions p-2">
              <v-list-item><v-list-item-title style="font-weight: bold; font-size: 14px;">Standard Fields</v-list-item-title></v-list-item>
              <v-list-item class="hover:bg-gray-200" v-for="(item, index) in standard_fields" :key="'standard-field-' + index"><v-list-item-title><a href="javascript: void(0);" @click="addFilter('standard_field', item)">{{ item }}</a></v-list-item-title></v-list-item>
              <v-list-item><v-list-item-title class="mt-2" style="font-weight: bold; font-size: 14px;">Custom Fields</v-list-item-title></v-list-item>
              <v-list-item class="hover:bg-gray-200" v-for="(item, index) in custom_fields" :key="'custom-field-' + index"><v-list-item-title><a href="javascript: void(0);" @click="addFilter('custom_field', item)">{{ item }}</a></v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': saving}" @click="save">
            SAVE
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
            CANCEL
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
        me: state => state.me.me,
        businesses: state => state.businesses.businesses,
        campaigns: state => state.campaigns.campaigns
    }),
  },

  data: function() {
    return {
      showModal: false,
      saving: false,
      success: null,
      error: null,
      success2: null,
      error2: null,
      index: 0,
      mode: 'add',
      item: {},

      custom_fields: [],
      standard_fields: [],

      filters_options: {
        'Has Tag': [

        ],
        'DND': [

        ],
        'Tags Added': [

        ],
        'Tags Removed': [

        ],
        'DND Flag is': [

        ],
        'Contains Phrase': [

        ],
        'Exact Match Phrase': [

        ],
        'Has Tag': [

        ],
        'Doesn\'t Have Tag': [

        ],
        'Intent Type': [

        ],
        'Replied To Campaign': [

        ],
        'Reply Channel': [

        ],
        'Clicked On Campaign': [

        ],
        'Submitted To Campaign': [

        ]
      }
    }
  },
  methods: {
    show() {
      this.showModal = true

      this.getStandardFields();
      this.getCustomFields();

      this.$store.dispatch('campaigns/fetch')
    },
    hide() {
      this.showModal = false
      this.saving = false
    },
    async save(shouldHide = true) {
      this.saving = true
      this.error = null
      try {

        await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/save-get-reviews-campaign-trigger` + '?fbid=' + this.businesses[this.businesses.length - 1].id, {
            index: this.index,
            mode: this.mode,
            item: this.item,
            campaign_id: this.$route.params.id
        }, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
        })

        //this.hide()
        if (shouldHide)
          this.hide()
        
        this.$parent.$parent.getGetReviewsCampaign();

    	this.error = null

      } catch (e) {
          console.log('this.error', e)
        this.error = e.response.data.message
      }
      this.saving = false
    },

    async getCustomFields() {
      let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/contacts/get-custom-fields` + '?fbid=' + this.businesses[this.businesses.length - 1].id, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      })

      this.custom_fields = response.data.custom_fields;
    },

    getStandardFields() {
      this.standard_fields = [];

      console.log('this.item.type', this.item.type);
      
      switch (this.item.type) {
        case 'Birthday':
          this.standard_fields.push('After no. of days');
          this.standard_fields.push('Before no. of days');
          this.standard_fields.push('Day is');
          this.standard_fields.push('Month is');
        break;

        case 'Contact Created':
          this.standard_fields.push('Has Tag');
        break;

        case 'Contact Changed':
          this.standard_fields.push('DND');
          this.standard_fields.push('Tags Added');
          this.standard_fields.push('Tags Removed');
        break;

        case 'Contact DND':
          this.standard_fields.push('DND Flag is');
        break;

        case 'Contact Tag':
          this.standard_fields.push('Tags Added');
          this.standard_fields.push('Tags Removed');
        break;

        case 'Contact Replied':
          this.standard_fields.push('Contains Phrase');
          this.standard_fields.push('Exact Match Phrase');
          this.standard_fields.push('Has Tag');
          this.standard_fields.push('Doesn\'t Have Tag');
          this.standard_fields.push('Intent Type');
          this.standard_fields.push('Replied To Campaign');
          this.standard_fields.push('Reply Channel');
        break;

        case 'Clicked Email Link':
        case 'Clicked Any Link':
        case 'Clicked SMS Link':
        case 'Clicked Review Platform Link':
          this.standard_fields.push('Clicked On Campaign');
        break;

        case 'Feedback Submitted':
          this.standard_fields.push('Contains Phrase');
          this.standard_fields.push('Exact Match Phrase');
          this.standard_fields.push('Intent Type');
          this.standard_fields.push('Submitted To Campaign');
        break;

        case 'Survey Submitted':
          this.standard_fields.push('Submitted To Campaign');
        break;
      }
    },

    addFilter(filter_type, filter_name) {
      if (!this.item.filters) {
        this.item.filters = [];
      }

      this.item.filters.push({
        type: filter_type,
        name: filter_name,
        operator: "",
        value: "",
        tags: [],
        campaigns: []
      });

      console.log('this.item.filters', this.item.filters);
    },

    deleteFilter(index) {
      let new_filters = [];

      for (var i in this.item.filters) {
        if (i != index)
          new_filters.push(this.item.filters[i]);
      }

      this.item.filters = JSON.parse(JSON.stringify(new_filters));
    },

    capitalizeFirstLetter(string) {
        string = string.replace(/\_/g, " ");
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  mounted() {
    
  }
}
</script>
