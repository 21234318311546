<template>
    <div class="hover-7-wrapper">
        <v-card
            elevation="2"
            class="reviews-badge"
            @click="$parent.showDialog()"
            :style="'background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';'"
        >
            <div class="text-gray-600" style="font-size: 14px; padding: 5px;">
                <i class="fa fa-star" :style="'font-size: 18px; color:' + widget_data.widget.settings.star_color"></i>
                <div style="display: inline-block; padding-left: 7px;" v-html="getText()"></div>
            </div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },

    methods: {
        getText() {
            return (
                (this.widget_data.widget.settings.hover_7_title)
                ? this.widget_data.widget.settings.hover_7_title
                    .replace('[reviews_nr]', this.widget_data.total_reviews)
                    .replace('[rating_nr]', this.widget_data.average_rating)
                    .replace(/\[bold\]/g, '<strong>')
                    .replace(/\[\/bold\]/g, '</strong>')
                : "We're rated <strong>" + this.widget_data.average_rating + "</strong> out of <strong>5</strong> based on <strong>" + this.widget_data.total_reviews + "</strong> reviews");
        }
    }
}
</script>

<style type="text/css" scoped>
    .hover-7-wrapper {
        width: 330px;
        padding-bottom: 10px;
    }
</style>