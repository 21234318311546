<template>

    <div class="fill-height">
      <panel-navigation class="fill-height" :inbox="true">
          <div class="fill-height" v-if="me">
                 <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right mr-6 mt-3" @click="$router.push('/contacts')" style="margin-top: -5px;"><i class="fa fa-chevron-left mr-2"> </i> Back</div>

              <div :class="(($vuetify.breakpoint.xs) ? '' : 'pt-5 pl-8 pr-8')">
                  <div class="flex flex-row items-top justify-between">
                      <h1 class="text-lg font-semibold mb-3">Contact Details</h1>
                  </div>
              </div>

              	<div class='pl-8 pr-8' v-if="loading">
					<v-skeleton-loader type="article, article, article"></v-skeleton-loader>
				</div>
                
                <div v-else>
                    <v-row class="rg-cd-wrapper no-gutters ">
                        <v-col
                            cols="12" sm="3"
                            class="fill-height flex-grow-1 flex-shrink-0"
                            style="padding: 0px !important; margin: 0px !important;"
                        >
                            <div class="rg-contact-details-heightz" 
                                
                                :style="(($vuetify.breakpoint.xs) ? 'padding-left: 0px; padding-right: 0px;' : 'float: left; width: 100%;')"
                                
                                >
                                
                                <div class="dashboard-box" style="height: 100%;">
                                	<v-card
							          class="pa-2"
							          tile
							          style="height: 100%;"
							        >
							        	<div class="p-3 pt-1" style="height: 100%; overflow: auto;">
							        		<div style="text-align: center; padding-bottom: 15px;">
							                    <v-gravatar class="gravatar-pic" :email="contact.email" default-img="https://regro-cdn.s3.amazonaws.com/images/contact_image.png" />
							                    <!-- <div v-else style="border: solid 2px #CCCCCC; width: 100px; height: 100px; border-radius: 50%; text-align: center; width: 100px; margin: auto; padding-top: 22px;">
							                    	<i class="fa fa-user" style="font-size: 50px; opacity: 0.7;"></i>
							                    </div> -->
							                    <div style="padding-top: 10px;">
							                    	<strong v-if="contact.first_name || contact.last_name">{{ contact.first_name }} {{ contact.last_name }}</strong>
							                    	<strong v-else>(name not set)</strong>
							                    </div>
							                </div>

						                    <hr />

						                    <div class="flex flex-wrap pt-4">
									            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
									                <p class="mb-1 text-xs mb-2"><strong>
									                    First Name
									                </strong></p>
									                <input name="first_name" v-model="contact.first_name" placeholder="e.g. John" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
									            </div>

									            <div class="w-full md:w-1/2 mb-6 md:mb-0">
									                <p class="mb-1 text-xs mb-2"><strong>
									                    Last Name
									                </strong></p>
									                <input name="last_name" v-model="contact.last_name" placeholder="e.g. Doe" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
									            </div>
									        </div>

									        <!-- <p class="text-xs text-gray-700 mb-4">Your contact's name.</p> -->

									        <div class="mb-3">
									          <p class="mb-1 text-xs mb-2"><strong>
									            Email
									          </strong></p>
									          <input name="email" v-model="contact.email" placeholder="e.g. johndoe@example.com" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
									        </div>

									        <!-- <p class="text-xs text-gray-700 mb-4">Your contact's email.</p> -->

									        <div class="mb-3">
									          <p class="mb-1 text-xs mb-2"><strong>
									            Phone Number
									          </strong></p>
									          <input name="phone" v-model="contact.phone" placeholder="e.g. +18131234567" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
									        </div>

									        <div class="mb-3">
									          <p class="mb-1 text-xs mb-2"><strong>
									            Date of Birth
									          </strong></p>
									          <date-picker style="width: 100%;" v-model="contact.dob" type="date" valueType="format"></date-picker>
									        </div>

									        <div class="mb-3">
									          <p class="mb-1 text-xs mb-2"><strong>
									            Contact Source
									          </strong></p>
									          <input name="source" v-model="contact.source" placeholder="e.g. CSV Import" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
									        </div>

									        <div class="mb-3">
									          <p class="mb-1 text-xs mb-2"><strong>
									            Contact Type
									          </strong></p>
									          
									          <v-select
									          	v-model="contact.contact_type"
									          	:items="['Prospect','Lead', 'Customer']"
									          	outlined
									          	dense
									          	hide-details
									          >

									          </v-select>
									        </div>

									        <!-- <p class="text-xs text-gray-700 mb-4">Your contact's phone number.</p> -->

									        <a href="javascript: void(0);" @click="addField();" class="text-xs">+ Add Field</a>

									        <div class="mt-2 mb-2" v-for="(page, index) in contact.custom_fields" :key="index">
									          <div class="flex flex-wrap">
									            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
									              <v-text-field
									                    v-model="contact.custom_fields[index].name"
									                    placeholder="Field Name"
									                    label=""
									                    outlined
									                    dense
									                    hide-details
									                ></v-text-field>
									              </div>
									            
									              <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
									                <v-text-field
									                    v-model="contact.custom_fields[index].value"
									                    placeholder="Field Value"
									                    label=""
									                    outlined
									                    dense
									                    hide-details
									                    :append-outer-icon="'mdi-trash-can-outline'"
									                    @click:append-outer="removeField(index)"
									                ></v-text-field>
									              </div>
									            </div>
									        </div>

									        <button class="rg-dialog-button-save w-full rounded text-white text-xs text-bold focus:outline-none py-2 px-4 mt-2" :class="{'spinner': saving}" @click="saveContactDetails()">
					                            SAVE
					                        </button>

									        <hr class="mt-4 mb-4" />

						                    <v-expansion-panels accordion flat dense>
				                                <v-expansion-panel>
				                                    <v-expansion-panel-header>Tags</v-expansion-panel-header>
				                                    <v-expansion-panel-content>
				                                    	<br />
				                                    	<v-combobox
															v-model="contact_tags"
															:items="tags_items"
															label="Add tags"
															multiple
															outlined
															dense
															hide-details
												        ></v-combobox>

												        <div class="pt-2">
												        	<v-chip
												        		v-for="(item, index) in contact.tags"
												        		:key="index"
		                                                        small
		                                                        style="font-size: 11px;"
		                                                        class="mr-1 mb-2"
		                                                    >
		                                                        {{ item.tag.tag }}

		                                                        <a href="javascript: void(0);" @click="removeTags([contact.id], [item.tag.tag], 'contact')" class="ml-1">x</a>
		                                                    </v-chip>
												        </div>
				                                    </v-expansion-panel-content>
				                                </v-expansion-panel>
				                                <v-expansion-panel>
				                                    <v-expansion-panel-header>Campaigns</v-expansion-panel-header>
				                                    <v-expansion-panel-content>
				                                    	<p class="mb-1 text-xs mb-2 mt-2"><strong>
										                    Active
										                </strong></p>

										                <span
										                	v-for="(item, index2) in contact.campaigns_to_contact"
											        		:key="'active-item-' + item.id"
											        	>
											                <v-chip
												        		v-if="item.status != 'completed'"
		                                                        small
		                                                        style="font-size: 11px;"
		                                                        class="mr-1 mb-2"
		                                                    >
		                                                        {{ item.campaign.name }}
		                                                    </v-chip>
		                                                </span>

														<div class="pt-1 pb-1">
															<v-menu
										                        bottom
										                        left
										                        nudge-top="-17"
										                      >
										                        <template v-slot:activator="{ on, attrs }">
										                        	<v-chip
											                            v-bind="attrs"
											                            v-on="on"
				                                                        small
				                                                        style="font-size: 11px; cursor: pointer; color: #FFFFFF;"
				                                                        class="mr-1 mb-2"
				                                                        color="grey darken-1"
				                                                    >
				                                                        <strong class="mr-1">+</strong> Add
				                                                    </v-chip>
										                        </template>

										                        <v-list class="table-menu-actions">
										                          <v-list-item><v-list-item-title class='text-sm text-bold'>Choose Campaign:</v-list-item-title></v-list-item>

										                          <v-list-item v-for="(campaign, index_campaign) in campaigns" :key="index_campaign" class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="startInviteCampaign(contact, campaign)">{{ campaign.name }}</a></v-list-item-title></v-list-item>
										                        </v-list>
										                      </v-menu>

											                
		                                                </div>

										                <p class="mb-1 text-xs mb-2"><strong>
										                    Past
										                </strong></p>

										                <span
										                	v-for="(item, index3) in contact.campaigns_to_contact"
											        		:key="'past-item-' + item.id"
											        	>
											                <v-chip
												        		v-if="item.status == 'completed'"
		                                                        small
		                                                        style="font-size: 11px;"
		                                                        class="mr-1 mb-2"
		                                                    >
		                                                        {{ item.campaign.name }}
		                                                    </v-chip>
		                                                </span>
				                                    </v-expansion-panel-content>
				                                </v-expansion-panel>
				                                <v-expansion-panel>
				                                    <v-expansion-panel-header>DND</v-expansion-panel-header>
				                                    <v-expansion-panel-content>
				                                    	<br />
				                                    	<v-switch color="#48bb78" inset dense hide-details :ripple="false" class="" v-if="me"
				                                            v-model="contact.dnd_all_channels"
				                                            label="DND All Channels"
				                                            hide-details
				                                            @click="saveContactDetails()"
				                                        ></v-switch>

				                                        <hr class="mt-3 mb-3" />

				                                        <v-switch color="#48bb78" inset dense hide-details :ripple="false" class="mb-3" v-if="me"
				                                            v-model="contact.dnd_email"
				                                            @click="saveContactDetails()"
				                                            label="DND Email"
				                                            hide-details
				                                        ></v-switch>

				                                        <v-switch color="#48bb78" inset dense hide-details :ripple="false" class="mb-3" v-if="me"
				                                            v-model="contact.dnd_sms"
				                                            @click="saveContactDetails()"
				                                            label="DND SMS"
				                                            hide-details
				                                        ></v-switch>

				                                        <v-switch color="#48bb78" inset dense hide-details :ripple="false" class="" v-if="me"
				                                            v-model="contact.dnd_inbound_sms"
				                                            @click="saveContactDetails()"
				                                            label="DND Inbound SMS"
				                                            hide-details
				                                        ></v-switch>
				                                    </v-expansion-panel-content>
				                                </v-expansion-panel>
				                            </v-expansion-panels>


				                            <div class="text-xs pt-2">
				                            	Created On: {{ formatDate2(contact.created_at) }}
				                            </div>
							        	</div>
							        </v-card>
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="auto"
                            class="flex-grow-1 flex-shrink-0"
                        >
                            <div :class="(($vuetify.breakpoint.xs) ? '' : 'pl-2 pr-5')" style="height: 100%;">
                                <div>
                                    <InboxThread :key="activeChatKey" :activeChat="thread.id" :search_results="false" :contact_details="true"></InboxThread>
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="12" sm="3"
                            class="fill-height flex-grow-1 flex-shrink-0"
                            style="padding: 0px !important; margin: 0px !important; padding-right: 24px;"
            
                            v-show="!$vuetify.breakpoint.xs || !this.activeChat"
                        >
                            <div class="rg-contact-details-heightz2" 
                                
                                :style="(($vuetify.breakpoint.xs) ? 'padding-left: 0px; padding-right: 0px;' : 'float: left; width: 100%;')"
                                
                                >
                                
                                <div class="dashboard-box" style="height: 100%;">
                                	<v-card
							          class="pa-2"
							          tile
							          style="height: 100%;"
							        >
							        	<div class="p-3 pt-1" style="height: 100%; overflow: auto;">
							        		<div><strong>Activity</strong></div>

							        		<div v-if="contact.activity.length == 0" style="padding: 50px; text-align: center; font-size: 12px;">
							        			No activity yet.
							        		</div>
							        		<div v-else>
							        			<div v-for="activity_item in contact.activity" :key="activity_item.id" class="text-sm pt-3 text-gray-800 bg-white rounded">
								                  <div class="dashboard-activity-item">
								                    {{ activity_item.details.toLowerCase() }}
								                    <span class="float-right not-mobile" style="font-size: 12px;">
								                      {{ formatDate(activity_item.created_at) }}
								                    </span>
								                    <div class="mobile-only mt-1" style="font-size: 12px; font-style: italic;">
								                      <i class="fa fa-calendar"></i> {{ formatDate(activity_item.created_at) }}
								                    </div>
								                    <br class="not-mobile" clear="all" />
								                  </div>
								                  <div style="clear: both;"></div>
								                </div>
							        		</div>
							        	</div>
							        </v-card>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
          </div>
  
      </panel-navigation>
    </div>
  
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import PanelNavigation from '@/components/PanelNavigation.vue'
  import InboxThread from '@/views/panel/InboxThread.vue'
  import axios from 'axios'
  import moment from 'moment'
  import DialogBuyNumber from '@/components/dialogs/integrations/buyNumber.vue'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    metaInfo () {
      return {
        title: "Contact Details | " + this.$appName
      }
    },
    components: {
      PanelNavigation,
      InboxThread,
      DialogBuyNumber,
      DatePicker
    },
    data() {
      return {
          contact: null,
          thread: null,
	      loading: true,
	      saving: false,

	      activeChatKey: 0,

	      error: null,

	      contact_tags: [],

	      tags_items: ['15 min call', 'application complete', 'completed survey', 'go live', 'lead', 'payment made', 'scheduled with calendly', 'value video optin'],
	      original_tags_items: ['15 min call', 'application complete', 'completed survey', 'go live', 'lead', 'payment made', 'scheduled with calendly', 'value video optin'],

	      activeChat: 0
      }
    },
    computed: {
      ...mapState({
        me: state => state.me.me,
        campaigns: state => state.campaigns.campaigns
      }),
    },
    methods: {
	    async getContact(silent = false) {
	    	if (!silent)
	      		this.loading = true;
	      
	      	let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/contacts/contact-details/' + this.$route.params.id, {
	          headers: {
	            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
	          }
	        })

	        this.contact = response.data.contact;
	        this.thread = response.data.thread;
	        this.loading = false;
	    },
  
        calculateHeight() {
            return window.outerHeight - 226;
        },
        calculateHeightLeft() {
            return window.outerHeight - 366;
        },
		formatDate: function(date) {
			return moment(String(date)).fromNow()
		},
		formatDate2: function(date) {
			return moment(String(date))
		},
		addField() {
	        this.contact.custom_fields.push({
	          'name': 'Field Name',
	          'value': ''
	        });
	    },
	    removeField(index) {
	        let all_pages = this.contact.custom_fields;
	        let new_fields = [];

	        for (var i in all_pages) {
	            if (i != index) {
	              new_fields.push(all_pages[i]);
	            }
	        }

	        this.contact.custom_fields = new_fields;
	    },

	    async addTags(objects_ids, tags, object_type) {
	    	this.contact_tags = [];

	    	let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/tags/assign-tags', {
	    		tags: tags,
	    		object_type: object_type,
	    		objects_ids: objects_ids
	    	}, {
	          headers: {
	            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
	          }
	        });

	        this.getContact(true);
	    },

	    async removeTags(objects_ids, tags, object_type) {
	    	let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/tags/remove-tags', {
	    		tags: tags,
	    		object_type: object_type,
	    		objects_ids: objects_ids
	    	}, {
	          headers: {
	            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
	          }
	        });

	        this.getContact(true);
	    },

	    async saveContactDetails() {
	      this.saving = true
	      this.error = null
	      try {

	        let formData = new FormData();
	        // formData.append("name", this.name);
	        
	        if (this.contact.id)
	          formData.append("id", this.contact.id);

	        formData.append("first_name", this.contact.first_name);
	        formData.append("last_name", this.contact.last_name);
	        formData.append("email", this.contact.email);
	        formData.append("phone", this.contact.phone);
	        formData.append("custom_fields", JSON.stringify(this.contact.custom_fields));

	        formData.append("dob", this.contact.dob);
	        formData.append("source", this.contact.source);
	        formData.append("contact_type", this.contact.contact_type);

	        formData.append("contact_details", 1);

	        formData.append("dnd_all_channels", ((this.contact.dnd_all_channels) ? 1 : 0));
	        formData.append("dnd_email", ((this.contact.dnd_email) ? 1 : 0));
	        formData.append("dnd_sms", ((this.contact.dnd_sms) ? 1 : 0));
	        formData.append("dnd_inbound_sms", ((this.contact.dnd_inbound_sms) ? 1 : 0));

	        await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/contacts`, formData, {
	            headers: {
	              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
	              'Content-Type': 'multipart/form-data'
	            }
	        })
	      } catch (e) {
	        this.error = e.response.data.message
	      }
	      this.saving = false
	    },

	    async startInviteCampaign(contact, campaign) {
	    	await this.$store.dispatch('contacts/invite', {contactId: contact.id, campaignId: campaign.id})
	    	this.getContact(true);
	    },
    },
    mounted() {
          let self = this;
  
          function setHeightz() {
              try {
                  if (!self.$vuetify.breakpoint.xs) {
                      let new_height = window.outerHeight - 225;
                      document.querySelector(".rg-contact-details-heightz").style.height = new_height + "px";
                      document.querySelector(".rg-contact-details-heightz2").style.height = new_height + "px";
                  }
              }
              catch(e) {
                  setTimeout(function() {
                      setHeightz();
                  }, 100);
              }
          }
  
          setHeightz();
    },
    beforeDestroy() {
    },
    watch: {
	    contact_tags: {
	      handler() {
	      	if (this.contact_tags.length > 0)
	        	this.addTags([this.contact.id], this.contact_tags, "contact");
	      }
	    }
	  },
	created() {
	    this.getContact();
	    this.$store.dispatch('campaigns/fetch')
	},
  }
  </script>
  
  <style lang='scss' scoped>
      .col {
          padding: 0px !important;
      }
  
      .rg-contact-details-heightz {
          padding-left: 32px;
          padding-right: 10px;
          overflow-y: auto;
      }
</style>

<style scoped>
	.gravatar-pic {
		  border-radius: 50%;
		  width: 100px;
		  display: initial;
		  margin-bottom: 6px;
	}
</style>