<template>

    <div>
      <panel-navigation>
        <h1 class="text-lg font-semibold mb-3">Calls</h1>
  
        <hr class="border-b border-gray-100 mb-3">
  
        <v-skeleton-loader v-if="fetchingCalls" type="article, article, article"></v-skeleton-loader>
  
        <template v-if="!fetchingCalls">
          <div class="dashboard-box">
            <v-card
              class="pa-2"
              tile
            >
              <div class="p-3">
                <div style="clear: both; padding-top: 0px;">
                  <v-data-table
                    :headers="headers"
                    :items="calls"
                    :items-per-page="20"
                    class="rg-datatable elevation-1"
                    :footer-props="{
                    itemsPerPageOptions: [1, 5, 10, 20, 30, 40, 50, 100],
                    showFirstLastPage: true
                    }"
                    mobile-breakpoint="10"
                    :search="search"
                  >
                    <template v-slot:top>
  
                      <!-- <v-text-field
                        v-model="search"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Search"
                        style="max-width: 200px; margin-bottom: 20px;"
                      ></v-text-field> -->
                    </template>
  
                    <template v-slot:item.recording_url="{ item }">
                        <audio class="audio-element" :id="'audio-element-' + item.id" controls="play, pause, volume" style="height: 38px;">
                            <source :src="item.recording_url" type="audio/mpeg">
                        </audio>
                    </template>
  
                    <template v-slot:item.duration="{ item }">
                      {{ toDuration(item.duration) }}
                    </template>
  
                    <template v-slot:item.created_at="{ item }">
                      {{ formatDate(item.created_at) }}
                    </template>
                  </v-data-table>
                </div>
            </div>
            </v-card>
          </div>
        </template>
  
      </panel-navigation>
    </div>
  
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import axios from 'axios';
  import PanelNavigation from '@/components/PanelNavigation.vue'
  import moment from 'moment-timezone'
  
  export default {
    metaInfo () {
      return {
        title: "calls | " + this.$appName
      }
    },
    components: {
      PanelNavigation
    },
    data() {
      return {
        headers: [
          { text: 'Date', value: 'created_at' },
          { text: "From", value: 'from'},
          { text: "To", value: 'to'},
          { text: "Status", value: 'call_status'},
          { text: "Duration", value: 'duration'},
          { text: 'Recording', value: 'recording_url' }
        ],
  
        search: ""
      }
    },
    computed: {
      ...mapState({
        me: state => state.me.me,
        calls: state => state.calls.calls,
        fetchingCalls: state => state.calls.fetchingCalls
      })
    },
    methods: {
      formatDate: function(date) {
        return moment(String(date)).format("LLL")
      },

      toDuration(duration) {
            var date = new Date(0);
            date.setSeconds(duration); // specify value for SECONDS here
            var timeString = date.toISOString().substring(14, 19);
            return timeString;
        }
    },
    created() {
      this.$store.state.calls.fetchingCalls = true
      this.$store.dispatch('calls/fetch')
    },
  }
  </script>
  