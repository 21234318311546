import axios from 'axios'

export default {
  namespaced: true,
  state: {
    feedback: [],
    fetchingFeedback: true
  },
  getters: {
    getFeedback(state){ return state.feedback }
  },
  mutations: {
    setFeedback(state, data) {
      state.feedback = data.feedback
      state.fetchingFeedback = false
      state.notRespondedCount = data.notRespondedCount
    }
  },
  actions: {
    fetch({commit}, {review_sites, date, search = null, showByResponded = null}) {
      return new Promise(async (resolve, reject) => {
  
        try {
          let url = `${process.env.VUE_APP_API_ENDPOINT}/me/feedback`;

          if (review_sites && date)
            url += '?review_sites=' + review_sites.join(",") + "&date=" + date;

          if (search) {
            url += '?search=' + search;
          }

          if (showByResponded) {
            url += '?showByResponded=' + showByResponded;
          }

          let response = await axios.get(url, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
  
          if (response.status == 200) {
            commit('setFeedback', response.data)
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }
  
        reject()
  
      })
    }
  }

}