<template>

  <panel-navigation>

    <div v-if="showQrCodeModal" @click.self="showQrCodeModal = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-1/2 bg-white rounded-lg mx-6">

      <div class="rg-modal-header">
        Review Funnel QR Code

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="showQrCodeModal = false"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6">

        <div id="qr-code-wrapper" class="mb-3 text-center">
          <p class="mb-1 text-xs mb-2"><strong>
              QR Code
          </strong></p>
          
          <div id="qrcode"></div>

          <a href="javascript: void(0);" @click="downloadQrCode();" class="mt-5" style="font-size: 13px;">Download PNG</a>
        </div>

      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="showQrCodeModal = false">
            CLOSE
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

    <v-app-bar v-if="funnelLoaded" app color="" class="header-top px-5" elevation="0" height="80">
        <a href="javascript: void(0);" @click="$router.go(-1)" class="mr-6"><i class="mdi mdi-chevron-left text-gray-700" style="font-size: 40px;"></i></a>

        <img v-if="$agency && $agency.settings.favicon" :src="$agency.settings.favicon" alt="" class="mr-6" style="width: 30px; height: 30px;" />

        <div v-if="$agency && $agency.settings.favicon">
            <hr role="separator" aria-orientation="vertical" class="mr-6 v-divider v-divider--vertical theme--light" style="height: 60px;" />
        </div>

        <h1 class="text-lg font-semibold">Review Funnel Editor</h1>

        <div class="float-right" style="margin-left: auto;">

            <div class="bg-blue text-white text-bold text-xs inline-block rounded py-2 px-3 cursor-pointer float-right ml-2" :class="{'spinner': savingFunnel}" @click="saveFunnel()" style="margin-left: auto;">SAVE FUNNEL</div>

            <div class="border-blue text-primary border-1 text-bold text-xs inline-block rounded py-2 px-3 cursor-pointer float-right ml-2" @click="openFunnel()" style="margin-left: auto;"><i class="fa fa-link mr-2"> </i> VIEW FUNNEL</div>

            <div class="border-blue text-primary border-1 text-bold text-xs inline-block rounded py-2 px-3 cursor-pointer float-right" @click="openQrCode();" style="margin-left: auto;"><i class="fa fa-building mr-2"> </i> QR CODE</div>
            
        </div>
    </v-app-bar>

      <div v-if="source_form_show" @click.self="source_form_show = false;" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
        <div class="w-full sm:w-1/2 lg:w-2/5 bg-white rounded-lg mx-6">

            <div class="rg-modal-header">
                <span v-if="source_form_mode == 'add'">Add a Source</span>
                <span v-if="source_form_mode == 'edit'">Edit Source</span>

                <a href="javascript: void(0);" class="rg-modal-close-btn" @click="source_form_show = false;"><i class="fa fa-times"></i></a>
            </div>

            <div class="p-6">

                <div v-if="source_form_error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                    <div v-html="source_form_error"></div>
                </div>

                <div class="mb-3">
                    <p class="mb-1 text-xs mb-2"><strong>
                        URL
                    </strong></p>
                    <input v-model="source_form_url" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                </div>

                <div v-show="false" class="mb-3">
                    <p class="mb-1 text-xs mb-2"><strong>
                        Leave a review link
                    </strong></p>
                    <input v-model="source_form_link" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                </div>

                <p class="text-xs text-gray-600">You can add any review platform (Google Maps, Facebook, Yelp, Tripadvisor, Zomato etc.), even add your own custom platforms.</p>

                <div v-if="source_form_url && !valid_url" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4 mt-1" role="alert">
                    <div>
                        We could not find that platform. In order to add a custom platform, please add a site name and upload an icon for it. Recomended sizes: 48x48px OR 128x128px

                        <div class="mt-3 mb-3">
                        <p class="mb-1 text-xs mb-2"><strong>
                            Site Name
                        </strong></p>
                        <input v-model="source_site_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" placeholder="Add your custom site name here..." maxlength="12">
                        </div>
                        
                        <v-file-input
                            accept="image/*"
                            label="Click here to upload"
                            style="width: calc(100% - 50px);"
                            v-model="file_source_logo"
                            @change="onUploadSourceLogo"
                        ></v-file-input>

                        <div v-if="source_logo" style="padding-top: 0px;">
                            <img :src="source_logo" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                        </div>

                        <!-- URL is invalid, please enter a valid URL.
                    
                        <div v-if="current_url_format" class="mt-2">
                            <strong>Correct URL format for this platform:</strong>
                            <br />
                            {{ current_url_format }}
                        </div> -->
                    </div>
                </div>

            </div>

            <div class="bg-gray-100 rounded-b-lg px-6 py-4">

                <button v-if="source_form_url" class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" @click="addSource()">
                    <span v-if="gettingReviewLink"><i class="fa fa-spin fa-spinner"></i></span>
                    <span v-else>SAVE</span>
                </button>

                <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="source_form_show = false">
                    CANCEL
                </button>

                <div class="clearfix"></div>

            </div>

        </div>
    </div>

	<v-skeleton-loader v-if="!funnelLoaded && !$route.params.action" type="article, article, article, article, article"></v-skeleton-loader>

    <div v-if="funnelLoaded || $route.params.action" class="bg-white" style="height: 100%;">

        <div class="flex flex-row flex-wrap -mx-3" style="height: 100%;">

            <div class="w-full lg:w-1/3 md:w-1/3 sm:w-1/3 pl-12 pt-6 border-t border-gray-100">
                <h2 class="text-lg font-semibold">Review Funnel Preferences</h2>

                <div class="pr-3">

                    <v-tabs>
						<v-tab>Layout</v-tab>
                        <v-tab>Design</v-tab>
						<v-tab>Settings</v-tab>
						<v-tab>Embed Code</v-tab>

                        <v-tab-item>
                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Style </strong></p>

                            <div class="mt-4 rg-widget-templates" style="overflow: auto;">
                                <div class="rg-widget-template rg-widget-template-reviews"
                                    @click="review_funnel.style = 'Style 1'"
                                    :class="((review_funnel.style == 'Style 1') ? 'selected' : '')"
                                >
                                    <div class="rg-widget-template-name">Style 1</div>

                                    <div v-if="review_funnel.style == 'Style 1'" class="rg-widget-template-selected-icon">
                                        <div class="rg-widget-template-selected-icon-inner"></div>
                                    </div>
                                    <div class="rg-widget-template-image">
                                        <img :src="require('@/assets/images/review-funnel-templates/review_funnel_template1.png')" />
                                    </div>
                                </div>

                                <div class="rg-widget-template rg-widget-template-reviews"
                                    @click="review_funnel.style = 'Style 2'"
                                    :class="((review_funnel.style == 'Style 2') ? 'selected' : '')"
                                >
                                    <div class="rg-widget-template-name">Style 2</div>

                                    <div v-if="review_funnel.style == 'Style 2'" class="rg-widget-template-selected-icon">
                                        <div class="rg-widget-template-selected-icon-inner"></div>
                                    </div>
                                    <div class="rg-widget-template-image">
                                        <img :src="require('@/assets/images/review-funnel-templates/review_funnel_template2.png')" />
                                    </div>
                                </div>

                                <!-- <div class="rg-widget-template rg-widget-template-reviews"
                                    @click="review_funnel.style = 'Style 3'"
                                    :class="((review_funnel.style == 'Style 3') ? 'selected' : '')"
                                >
                                    <div class="rg-widget-template-name">Style 3</div>

                                    <div v-if="review_funnel.style == 'Style 3'" class="rg-widget-template-selected-icon">
                                        <div class="rg-widget-template-selected-icon-inner"></div>
                                    </div>
                                    <div class="rg-widget-template-image">
                                        <img :src="require('@/assets/images/review-funnel-templates/review_funnel_template3.png')" />
                                    </div>
                                </div> -->
                            </div>
                        </v-tab-item>
                        <v-tab-item>

                            <div style="float: left;">
                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Logo </strong></p>

                                <v-file-input
                                    accept="image/*"
                                    v-model="file"
                                    @change="onUpload"
                                    class="rg-uploader"
                                    prepend-icon="mdi-camera"
                                    outlined
                                    hide-input
                                ></v-file-input>

                                    <div v-if="review_funnel.logo" style="padding-top: 0px;">
                                    <span v-if="review_funnel.logo" style="text-align: center;">
                                        <v-btn
                                        depressed
                                        color="error"
                                        
                                        small
                                        @click="review_funnel.logo = ''; removeLogo()"
                                        class="mb-4"
                                        >
                                        Remove
                                        </v-btn>
                                    </span>
                                </div>
                            </div>

                            <div v-show="review_funnel.style == 'Style 2'" style="float: right; width: 60%; padding-right: 70px;">
                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Image</strong></p>

                                <v-select
                                    v-model="review_funnel.style2_image_type"
                                    hide-details
                                    :items="['Image 1', 'Image 2', 'Custom']"
                                    outlined
                                    dense
                                ></v-select>

                                <v-file-input
                                    v-show="review_funnel.style2_image_type == 'Custom'"
                                    accept="image/*"
                                    v-model="image"
                                    @change="onUploadImage"
                                    class="rg-uploader"
                                    prepend-icon="mdi-camera"
                                    outlined
                                    hide-input
                                ></v-file-input>
                            </div>

                            <div style="clear: both;"></div>

                            <div class="float-left has-color-picker pr-4" style="width: 45%;">
                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Header</strong></p>

                                <color-picker v-model="review_funnel.header_background_color"></color-picker>
                            </div>

                            <div class="float-left has-color-picker pr-4" style="width: 45%;">
                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Header Text</strong></p>

                                <color-picker v-model="review_funnel.header_text_color"></color-picker>
                            </div>

                            <div class="float-left has-color-picker pr-4 mt-3" style="width: 45%;">
                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Background</strong></p>

                                <color-picker v-model="review_funnel.background_color"></color-picker>
                            </div>

                            <div class="float-left has-color-picker pr-4 mt-3" style="width: 45%;">
                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Text Color</strong></p>

                                <color-picker v-model="review_funnel.text_color"></color-picker>
                            </div>

                            <div class="float-left has-color-picker pr-4 mt-3" style="width: 45%;">
                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Footer</strong></p>

                                <color-picker v-model="review_funnel.footer_background_color"></color-picker>
                            </div>

                            <div class="float-left has-color-picker pr-4 mt-3" style="width: 45%;">
                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Footer Text</strong></p>

                                <color-picker v-model="review_funnel.footer_text_color"></color-picker>
                            </div>

                            <br clear="all" />

                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Header alignment</strong></p>

                            <v-btn-toggle
                                v-model="review_funnel.header_alignment"
                                borderless
                            >
                                <v-btn value="Left" small>
                                <span class="hidden-sm-and-down"></span>

                                <v-icon>
                                    mdi-format-align-left
                                </v-icon>
                                </v-btn>

                                <v-btn value="Center" small>
                                <span class="hidden-sm-and-down"></span>

                                <v-icon>
                                    mdi-format-align-center
                                </v-icon>
                                </v-btn>

                                <v-btn value="Right" small>
                                <span class="hidden-sm-and-down"></span>

                                <v-icon>
                                    mdi-format-align-right
                                </v-icon>
                                </v-btn>
                            </v-btn-toggle>

                            <br />

                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Footer alignment</strong></p>

                            <v-btn-toggle
                                v-model="review_funnel.footer_alignment"
                                borderless
                            >
                                <v-btn value="Left" small>
                                <span class="hidden-sm-and-down"></span>

                                <v-icon>
                                    mdi-format-align-left
                                </v-icon>
                                </v-btn>

                                <v-btn value="Center" small>
                                <span class="hidden-sm-and-down"></span>

                                <v-icon>
                                    mdi-format-align-center
                                </v-icon>
                                </v-btn>

                                <v-btn value="Right" small>
                                <span class="hidden-sm-and-down"></span>

                                <v-icon>
                                    mdi-format-align-right
                                </v-icon>
                                </v-btn>
                            </v-btn-toggle>

                            <br />

                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Custom CSS</strong></p>
                            <div class="w-full">
                                    <textarea name="" id="" rows="13" class="text-sm block appearance-none focus:outline-none focus:border-gray-500 w-full bg-gray-100 hover:border-grey px-4 py-4 rounded" maxlength="1000" v-model="review_funnel.custom_css" placeholder="/* Example */
.rgrf-header {
height: 120px !important;
}

.rgrf-subtitle {
font-size: 17px !important;
font-weight: bold;
}

.rgrf-face-image {
height: 50px !important;
}
" ></textarea>

                                    <p class="text-xs text-gray-600 mt-2">Enter custom CSS to change the UI of the funnel.</p>
                                </div>
                        </v-tab-item>
						<v-tab-item>
                            <v-expansion-panels accordion flat dense>
                                <v-expansion-panel>
                                    <br />
                                    <v-expansion-panel-header>General Settings</v-expansion-panel-header>
                                    <v-expansion-panel-content>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Brand/Business Name </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="review_funnel.company_name"
                                            label=""
                                            required
                                            hide-details
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-5"><strong>URL </strong></p>

                                        <div v-if="!edit_url" class="bg-gray-100 p-3">
                                            <a :href="getFunnelLink()" target="review-funnel-link" class="text-xs">{{ getFunnelLink() }}</a>

                                            <a href="javascript: void(0);" @click="edit_url = true" class="ml-3 text-sm lighten-2"><i class="fa fa-edit"></i></a>
                                        </div>

                                        <div v-if="edit_url">
                                            <v-text-field
                                                outlined
                                                dense
                                                :prefix="getFunnelPrefix()"
                                                v-model="short_name"
                                                label=""
                                                required
                                                :append-icon="'mdi-check'"
                                                clear-icon="mdi-close-circle"
                                                clearable
                                                @click:clear="cancelEditShortName()"
                                                @click:append="saveShortName()"
                                                hide-details
                                            ></v-text-field>

                                            <v-alert
                                                v-if="short_name_error"
                                                color="red lighten-2 white--text mt-3"
                                            >
                                                {{ short_name_error }}
                                            </v-alert>
                                        </div>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Font Family</strong></p>

                                        <v-select
                                            v-model="review_funnel.font_family"
                                            hide-details
                                            :items="font_family_values"
                                            outlined
                                            dense
                                        ></v-select>
                                    

                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel class="mt-1">
                                    <v-expansion-panel-header>Content</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Header Subtitle </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.header_subtitle"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Footer Text </strong></p>

                                        <vue-editor v-model="review_funnel.footer_text" :editor-toolbar="customToolbar" style="height: 80px;" />

                                        <!-- <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.footer_text"
                                            label=""
                                        ></v-text-field> -->

                                        <p class="mb-1 text-xs mb-2 mt-15"><strong>Positive Feedback Text </strong></p>

                                        <v-text-field @click="selected_view = 1"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.positive_feedback_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Negative Feedback Text </strong></p>

                                        <v-text-field @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.negative_feedback_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Negative Feedback Confirmation </strong></p>

                                        <v-text-field  @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.negative_feedback_confirmation_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Feedback Form First Name Text </strong></p>

                                        <v-text-field  @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.first_name_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Feedback Form Last Name Text </strong></p>

                                        <v-text-field  @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.last_name_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Feedback Form Email Text </strong></p>

                                        <v-text-field  @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.email_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Feedback Form Phone Text </strong></p>

                                        <v-text-field  @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.phone_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Feedback Form Enter Feedback Text </strong></p>

                                        <v-text-field  @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.enter_feedback_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Feedback Form Button Text </strong></p>

                                        <v-text-field  @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.send_feedback_button_text"
                                            label=""
                                        ></v-text-field>

                                        <v-switch color="#48bb78" inset dense hide-details :ripple="false" class="mt-10" v-if="me"
                                            v-model="review_funnel.google_compliant_link_enabled"
                                            @click="selected_view = 2"
                                            label="Google compliant review link"
                                        ></v-switch>

                                        <p class="mb-1 text-xs mb-2 mt-4" v-if="review_funnel.google_compliant_link_enabled"><strong>Link Text</strong></p>

                                        <v-text-field v-if="review_funnel.google_compliant_link_enabled" @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.google_compliant_link_text"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4" v-if="review_funnel.google_compliant_link_enabled"><strong>URL</strong></p>

                                        <v-text-field v-if="review_funnel.google_compliant_link_enabled" @click="selected_view = 2"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.google_compliant_link"
                                            label=""
                                        ></v-text-field>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel class="mt-1">
                                    <v-expansion-panel-header>Leave a review process</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Type</strong></p>

                                        <v-select
                                            @change="((review_funnel.leave_a_review_process == 'Segmentation') ? selected_view = 0 : selected_view = 1)"
                                            v-model="review_funnel.leave_a_review_process"
                                            outlined
                                            dense
                                            hide-details
                                            :items="leave_a_review_process_items"
                                        ></v-select>

                                        <p v-show="review_funnel.leave_a_review_process == 'Segmentation'" class="mb-1 text-xs mb-2 mt-4"><strong>Segmentation Text</strong></p>

                                        <v-text-field @click="selected_view = 0" v-show="review_funnel.leave_a_review_process == 'Segmentation'"
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.segmentation_text"
                                            label=""
                                        ></v-text-field>

                                        <p v-show="review_funnel.leave_a_review_process == 'Segmentation'" class="mb-1 text-xs mb-2 mt-4"><strong>Segmentation Style</strong></p>

                                        <v-select @click="selected_view = 0" v-show="review_funnel.leave_a_review_process == 'Segmentation'"
                                            v-model="review_funnel.segmentation_option"
                                            outlined
                                            dense
                                            hide-details
                                            :items="segmentation_options"
                                        ></v-select>

                                        <p v-show="review_funnel.leave_a_review_process == 'Segmentation' && (review_funnel.segmentation_option == 'Stars' || review_funnel.segmentation_option == 'Hearts')" class="mb-1 text-xs mb-2 mt-4"><strong>Good Rating Threshold</strong></p>

                                        <v-select @click="selected_view = 0" v-show="review_funnel.leave_a_review_process == 'Segmentation' && (review_funnel.segmentation_option == 'Stars' || review_funnel.segmentation_option == 'Hearts')"
                                            v-model="review_funnel.custom_gating_stars"
                                            outlined
                                            dense
                                            hide-details
                                            :items="custom_gating_stars_options"
                                            item-value="value"
                                            item-text="text"
                                        ></v-select>

                                        <p v-show="review_funnel.leave_a_review_process == 'Segmentation' && (review_funnel.segmentation_option == 'NPS')" class="mb-1 text-xs mb-2 mt-4"><strong>Good Rating Threshold</strong></p>

                                        <v-select @click="selected_view = 0" v-show="review_funnel.leave_a_review_process == 'Segmentation' && (review_funnel.segmentation_option == 'NPS')"
                                            v-model="review_funnel.custom_gating_nps"
                                            outlined
                                            dense
                                            hide-details
                                            :items="custom_gating_nps_options"
                                        ></v-select>

                                        <div v-if="review_funnel.leave_a_review_process == 'Direct Link from Email/SMS'">
                                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Direct Link URL</strong></p>

                                            <v-text-field
                                                outlined
                                                dense
                                                v-model="review_funnel.direct_link_url"
                                                label=""
                                            ></v-text-field>
                                        </div>
                                        <div v-else>

                                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Action</strong></p>

                                            <v-select
                                                @change="selected_view = 0"
                                                v-model="review_funnel.links_type"
                                                outlined dense
                                                hide-details
                                                :items="links_type_items"
                                            ></v-select>


                                            <label v-if="review_funnel.links_type == 'External Links'" class="mt-5">Negative Action Link</label>

                                            <v-text-field v-if="review_funnel.links_type == 'External Links'"
                                                outlined
                                                dense
                                                v-model="review_funnel.external_link_frowny_face"
                                                label=""
                                            ></v-text-field>

                                            <label v-if="review_funnel.links_type == 'External Links'" class="mt-5">Positive Action Link</label>

                                            <v-text-field v-if="review_funnel.links_type == 'External Links'"
                                                outlined
                                                dense
                                                v-model="review_funnel.external_link_smiley_face"
                                                label=""
                                            ></v-text-field>


                                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Links Display</strong></p>

                                            <v-select @click="selected_view = 1"
                                                v-model="review_funnel.direct_links_display"
                                                outlined dense
                                                hide-details
                                                :items="direct_links_display_options"
                                            ></v-select>

                                            <div class="float-left has-color-picker pr-4">
                                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Button Background</strong></p>

                                                <color-picker v-model="review_funnel.direct_links_button_background"></color-picker>
                                            </div>

                                            <div class="float-left has-color-picker pr-4">
                                                <p class="mb-1 text-xs mb-2 mt-4"><strong>Button Text Color</strong></p>

                                                <color-picker v-model="review_funnel.direct_links_button_text_color"></color-picker>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>

                                        <div v-if="review_funnel.leave_a_review_process == 'Segmentation'">
                                            <p class="mb-1 text-xs mb-2 mt-4"><strong>Survey </strong></p>

                                            <v-switch
                                                color="#48bb78" inset dense hide-details :ripple="false"
                                                v-model="review_funnel.survey_enabled"
                                                label="Show"
                                                @click="selected_view = 0"
                                                class="mb-3"
                                            ></v-switch>
                                        </div>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel v-show="review_funnel.leave_a_review_process == 'Segmentation' && review_funnel.survey_enabled" class="mt-1">
                                    <v-expansion-panel-header>Edit Survey</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Survey Title </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.survey_title"
                                            label=""
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Additional Comments </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.additional_comments_text"
                                            label=""
                                        ></v-text-field>

                                        <div class="test-survey-builder pt-5">
                                            <SurveyQuestionsView :questions="questionsList" :readOnly="true" />
                                            <div v-if="addQuestion">
                                                <SurveyBuilder :options="sampleQuestion" />
                                            </div>
                                            <div class="pt-3">
                                                <a href="javascript: void(0);" class="text-xs" @click="addNewQuestion();">
                                                    <i class="fa fa-plus"></i> Add Question
                                                </a>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel v-show="review_funnel.leave_a_review_process == 'Segmentation'" class="mt-1">
                                    <v-expansion-panel-header>Feedback Form Fields</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>First Name </strong></p>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="review_funnel.feedback_field_first_name_show"
                                            label="Show"
                                            @click="selected_view = 2"
                                            class="mb-3"
                                        ></v-switch>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="review_funnel.feedback_field_first_name_required"
                                            label="Required"
                                            @click="selected_view = 2"
                                            class="mb-3"
                                        ></v-switch>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Last Name </strong></p>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="review_funnel.feedback_field_last_name_show"
                                            label="Show"
                                            @click="selected_view = 2"
                                            class="mb-3"
                                        ></v-switch>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="review_funnel.feedback_field_last_name_required"
                                            label="Required"
                                            @click="selected_view = 2"
                                            class="mb-3"
                                        ></v-switch>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Email </strong></p>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="review_funnel.feedback_field_email_show"
                                            label="Show"
                                            @click="selected_view = 2"
                                            class="mb-3"
                                        ></v-switch>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="review_funnel.feedback_field_email_required"
                                            label="Required"
                                            @click="selected_view = 2"
                                            class="mb-3"
                                        ></v-switch>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Phone Number </strong></p>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="review_funnel.feedback_field_phone_show"
                                            label="Show"
                                            @click="selected_view = 2"
                                            class="mb-3"
                                        ></v-switch>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="review_funnel.feedback_field_phone_required"
                                            label="Required"
                                            @click="selected_view = 2"
                                            class="mb-3"
                                        ></v-switch>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Message </strong></p>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="feedback_field_message"
                                            checked
                                            label="Show"
                                            class="mb-3"
                                            disabled
                                        ></v-switch>

                                        <v-switch
												color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="feedback_field_message"
                                            label="Required"
                                            disabled
                                            class="mb-3"
                                        ></v-switch>

                                        <a href="javascript: void(0);" @click="addField();" class="text-xs">+ Add Field</a>

                                        <div class="mt-2" v-for="(page, index) in review_funnel.custom_fields" :key="index">
                                        <div class="flex flex-wrap">
                                            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
                                            <v-text-field
                                                    v-model="review_funnel.custom_fields[index].name"
                                                    placeholder="Field Name"
                                                    label=""
                                                    outlined
                                                    dense
                                                    hide-details
                                                    :append-outer-icon="'mdi-trash-can-outline'"
                                                    @click:append-outer="removeField(index)"
                                                ></v-text-field>
                                            </div>
                                            
                                            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0" style="padding-top: 9px;">
                                                <div style="display: inline-block; padding-right: 20px;">
                                                    <v-switch
                                                            color="#48bb78" inset dense hide-details :ripple="false"
                                                        v-model="review_funnel.custom_fields[index].visible"
                                                        checked
                                                        label="Show"
                                                        class="mb-3"
                                                    ></v-switch>
                                                </div>

                                                <div style="display: inline-block;">
                                                    <v-switch
                                                            color="#48bb78" inset dense hide-details :ripple="false"
                                                        v-model="review_funnel.custom_fields[index].required"
                                                        label="Required"
                                                        class="mb-3"
                                                    ></v-switch>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel v-show="review_funnel.leave_a_review_process == 'Segmentation'" class="mt-1">
                                    <v-expansion-panel-header>Evaluation Texts</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Bad </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_bad"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Not Bad </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_not_bad"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Good </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_good"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Subpar </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_subpar"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Okay </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_okay"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Great </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_great"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Not At All </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_not_at_all"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Not Likely </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_not_likely"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Neutral </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_neutral"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Likely </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_likely"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Very Likely </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_very_likely"
                                            label=""
                                           
                                        ></v-text-field>

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Extremely Likely </strong></p>

                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="review_funnel.evaluation_text_extremly_likely"
                                            label=""
                                           
                                        ></v-text-field>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel class="mt-1">
                                    <v-expansion-panel-header>Feed</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Sources</strong></p>

                                        <div v-for="(source, index) in review_funnel.sources" :key="index" class="bg-gray-100 rounded-l flex flex-row items-center justify-between bg-white rounded w-full mb-2">

                                            <div class="flex flex-row items-center truncate">
                                            <div class="w-12 flex-shrink-0">
                                                <img :src="getIconUrl(source)" alt="" class="rounded-l border border-gray-100">
                                            </div>
                                            <div class="truncate p-3 text-gray-400">
                                                <div class="float-right">
                                                    <v-btn
                                                        class="mx-2"
                                                        fab
                                                        dark
                                                        x-small
                                                        color="red lighten-2"
                                                        @click="deleteSource(index, source)"
                                                        >
                                                        <v-icon dark>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </div>

                                                <span class="text-gray-900">{{ getHostname(source.site) }}</span>{{ getPathname(source.site) }}</div>
                                            </div>
                                        </div>

                                        <a href="javascript: void(0);" class="text-xs" @click="source_form_mode = 'add'; source_form_show = 'true'; source_form_url = ''; source_form_link = ''; source_form_error = '', selected_view = 1;">
                                            <i class="fa fa-plus"></i> Add Source
                                        </a>

                                        <br />

                                        <p class="mb-1 text-xs mb-2 mt-4"><strong>Reviews Widget</strong></p>

                                        <div class="w-full">
                                            <textarea name="" id="" rows="10" class="text-sm rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="border: solid 1px #e0e0e0 !important;" maxlength="1000" v-model="review_funnel.reviews_widget" placeholder="Add your code here..." ></textarea>

                                            <p class="text-xs text-gray-600 mt-2">Enter your reviews widget code to be displayed on the funnel page.</p>
                                        </div>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-tab-item>
						
						<v-tab-item>
							<br />
                            <p class="text-xs text-gray-600">Copy and paste the following code snippet into your website, before the end of the &lt;body&gt; tag.</p>

                            <div class="bg-gray-100 rounded p-1">
                                <div class="tab-pane-content p-2">
                                    <div class="card card-code bg-gray-100 border-0 text-gray-600" style="word-break: break-all; padding: 10px; font-size: 12px; min-height: 100px;">
                                        &lt;script src="{{ getEmbedCode() }}" type="text/javascript"&gt;&lt;/script&gt;

                                        <a
                                            href="javascript: void(0);"
                                            v-clipboard:copy="message"
                                            v-clipboard:success="onCopy"
                                            v-clipboard:error="onError"
                                            class="text-xs float-right"
                                            style="position: absolute; bottom: 0px; right: 10px;"
                                        >
                                            <i class="mdi mdi-content-copy"></i>
                                            Copy
                                        </a>
                                    </div>
                                </div>
                            </div>
						</v-tab-item>
                    </v-tabs>
		          	
                    <br />
		        </div>
            </div>

            <div class="w-full sm:w-2/3 px-3 bg-gray-100 p-3 -mt-7">
                <div class="w-full" style="height: 100%;">
                    <div class="p-4 bg-white" style="height: 100%;">
						<div class="mb-4">
						<h2 class="font-bold text-gray-800">Preview</h2>
						</div>
						<div class="w-full" style="height: 100%;">

                            <v-tabs v-model="selected_view">
                                <v-tab v-show="review_funnel.leave_a_review_process == 'Segmentation'" >Segmentation</v-tab>
                                <v-tab >Links</v-tab>
                                <v-tab >Feedback</v-tab>
                            </v-tabs>

                            <div class="w-full" style="border: solid 1px #CCCCCC; margin-top: 10px; height: calc(100% - 100px);">
                                <div v-if="review_funnel.style == 'Style 1'">
                                    <div :style="'width: 100%; position: relative; font-family: ' + review_funnel.font_family.replace(' (Google)', '') + ';'">
                                        <div class="rgrf-header" :style="'background-color: ' + review_funnel.header_background_color + '; color: ' + review_funnel.header_text_color + '; text-align: ' + review_funnel.header_alignment.toLowerCase() + ';'">
                                            <div class="rgrf-business-name">
                                                {{ review_funnel.company_name }}
                                            </div>

                                            <div class="rgrf-subtitle">
                                                {{ review_funnel.header_subtitle }}
                                            </div>
                                        </div>

                                        <div class="rgrf-body" :style="'background-color: ' + review_funnel.background_color + '; color: ' + review_funnel.text_color">
                                            <div class="rgrf-logo" v-if="review_funnel.logo">
                                                <img :src="review_funnel.logo" alt="" style="height: 60px;">
                                            </div>

                                            <div class="rgrf-review-process" :style="((review_funnel.direct_links_display == 'Icon + URL') ? '' : 'max-width: 600px;')">
                                                <div class="shadow p-6" v-if="selected_view == 1">
                                                    <div class="text-center text-lg mb-4">
                                                        {{ review_funnel.positive_feedback_text }}
                                                    </div>

                                                    <div class="" :style="((review_funnel.direct_links_display == 'Icon' || review_funnel.direct_links_display == 'Icon + Site Name') ? 'text-align: center;' : '')">
                                                        <a :href="source.url" v-for="(source, index) in review_funnel.sources" :key="index" class="rounded-l shadow items-center rounded mb-2 p-3"
                                                            :class="((review_funnel.direct_links_display == 'Icon + Site Name' || review_funnel.direct_links_display == 'Icon') ? 'rgrf-icon-diret-link' : ' flex flex-row w-full')"
                                                            :style="'background-color: ' + review_funnel.direct_links_button_background + '; color: ' + review_funnel.direct_links_button_text_color + ' !important;'"
                                                        target="review-platform">
                                                            <div v-if="review_funnel.direct_links_display == 'Icon + URL'" class="w-12 flex-shrink-0">
                                                                <img :src="source.logo || require('@/assets/images/review-services/www.' + getHostname(source.site) + '.png')" alt="" class="rounded">
                                                            </div>
                                                            <div v-if="review_funnel.direct_links_display == 'Icon + URL'" class="truncate p-3"><span class="">{{ getHostname(source.site) }}</span>{{ getPathname(source.site) }}</div>

                                                            <div v-if="review_funnel.direct_links_display == 'Icon + Site Name'" class="flex-shrink-0 icon_site_name text-center">
                                                                <img :src="source.logo || require('@/assets/images/review-services/www.' + getHostname(source.site) + '.png')" alt="" class="rounded" style="width: 70px; height: 70px;">
                                                            </div>
                                                            <div v-if="review_funnel.direct_links_display == 'Icon + Site Name'" class="truncate pt-3 text-center"><span class="" style="font-size: 14px;">{{ reviewSitesNamesAll["www." + getHostname(source.site)] || source.site_name }}</span></div>

                                                            <div v-if="review_funnel.direct_links_display == 'Icon'" class="w-12 flex-shrink-0">
                                                                <img :src="source.logo || require('@/assets/images/review-services/www.' + getHostname(source.site) + '.png')" alt="" class="rounded" style="width: 48px; height: 48px;">
                                                            </div>
                                                        </a>

                                                        <br clear="all" v-if="review_funnel.direct_links_display == 'Icon'" />
                                                    </div>
                                                </div>

                                                <div class="shadow p-6" v-if="selected_view == 0">
                                                    <div v-if="review_funnel.survey_enabled">
                                                        <div class="text-center text-lg mb-4">
                                                            {{ review_funnel.survey_title }}
                                                            
                                                            <div style="text-align: left; padding-top: 20px; padding-bottom: 20px; border-bottom: 1px solid rgba(0, 0, 0, 0.125);">
                                                                <SurveyQuestionsView :questions="questionsList" :readOnly="false" />

                                                                <div class="mb-3" style="padding-top: 20px; padding-bottom: 20px; margin-top: 10px; border-top: 1px solid rgba(0, 0, 0, 0.125);">
                                                                    <p class="mb-1 text-xs mb-2"><strong>
                                                                        {{ review_funnel.additional_comments_text }}
                                                                    </strong></p>
                                                                    <textarea name="additonal_comments" ref="additonal_comments" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="height: 150px;"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="text-center text-lg mb-4">
                                                        {{ review_funnel.segmentation_text }}
                                                    </div>

                                                    <div v-if="review_funnel.segmentation_option == 'Faces'" class="flex flex-row items-center justify-center rounded-lg">

                                                        <img @click="happy = false; choosedSmiley = true" @mouseover="rating_selected = review_funnel.evaluation_text_bad;" @mouseout="rating_selected = '';" src="@/assets/images/icons/sad.svg" class="wp-3 cursor-pointer rounded-l-lg rgrf-face-image" style="" alt="sad">
                                                        <img @click="happy = false; choosedSmiley = true" @mouseover="rating_selected = review_funnel.evaluation_text_not_bad;" @mouseout="rating_selected = '';" src="@/assets/images/icons/confused.svg" class="wp-3 cursor-pointer rgrf-face-image" alt="indifferent">
                                                        <img @click="happy = true; choosedSmiley = true" @mouseover="rating_selected = review_funnel.evaluation_text_good;" @mouseout="rating_selected = '';" src="@/assets/images/icons/smiling.svg" class="wp-3 cursor-pointer rounded-r-lg rgrf-face-image" alt="happy">

                                                    </div>

                                                    <div v-if="review_funnel.segmentation_option == 'Thumbs'" class="flex flex-row items-center justify-center rounded-lg">

                                                        <i class="fa fa-thumbs-down rgrf-segmentation-thumbs red--text text--lighten-1" @mouseover="rating_selected = review_funnel.evaluation_text_bad;" @mouseout="rating_selected = '';"></i>
                                                        <i class="fa fa-thumbs-up rgrf-segmentation-thumbs green--text text--lighten-1" @mouseover="rating_selected = review_funnel.evaluation_text_good;" @mouseout="rating_selected = '';"></i>

                                                    </div>

                                                    <div v-if="review_funnel.segmentation_option == 'Hearts'" class="flex flex-row items-center justify-center rounded-lg">

                                                        <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 0) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_bad; hovered_item = 0;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                        <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 1) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_subpar; hovered_item = 1;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                        <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 2) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_okay; hovered_item = 2;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                        <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 3) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_good; hovered_item = 3;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                        <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 4) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_great; hovered_item = 4;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>

                                                    </div>

                                                    <div v-if="review_funnel.segmentation_option == 'Stars'" class="flex flex-row items-center justify-center rounded-lg">

                                                        <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 0) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_bad; hovered_item = 0;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                        <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 1) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_subpar; hovered_item = 1;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                        <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 2) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_okay; hovered_item = 2;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                        <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 3) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_good; hovered_item = 3;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                        <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 4) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_great; hovered_item = 4;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>

                                                    </div>

                                                    <div v-if="review_funnel.segmentation_option == 'NPS'" class="flex flex-row items-center justify-center rounded-lg">

                                                        <v-rating
                                                            v-model="rating"
                                                            length="11"
                                                            large
                                                        >
                                                            <template v-slot:item="props">
                                                            <v-icon
                                                                :color="getNpsColor(props)"
                                                                v-text="`mdi-numeric-${props.index}-box`"
                                                                @click="rating = props.index + 1;"
                                                                @mouseover="selectNpsScore(props.index)"
                                                                large
                                                            ></v-icon>
                                                            </template>
                                                        </v-rating>

                                                    </div>

                                                    <div class="rgrf-rating-selected">{{ rating_selected }}</div>
                                                </div>

                                                <div class="shadow p-6" v-if="selected_view == 2">
                                                    <div class="text-center text-lg mb-4">
                                                        {{ review_funnel.negative_feedback_text }}
                                                    </div>

                                                    <div v-show="review_funnel.feedback_field_first_name_show" class="mb-3">
                                                        <p class="mb-1 text-xs mb-2"><strong>
                                                            {{ review_funnel.first_name_text }}
                                                        </strong></p>
                                                        <input name="first_name" ref="first_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                                                        </div>

                                                        <div v-show="review_funnel.feedback_field_last_name_show" class="mb-3">
                                                        <p class="mb-1 text-xs mb-2"><strong>
                                                            {{ review_funnel.last_name_text }}
                                                        </strong></p>
                                                        <input name="last_name" ref="last_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                                                        </div>

                                                        <!-- <p class="text-xs text-gray-600 mb-4">Your contact's name.</p> -->

                                                        <div v-show="review_funnel.feedback_field_email_show" class="mb-3">
                                                        <p class="mb-1 text-xs mb-2"><strong>
                                                            {{ review_funnel.email_text }}
                                                        </strong></p>
                                                        <input name="email" ref="email" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
                                                        </div>

                                                        <!-- <p class="text-xs text-gray-600 mb-4">Your contact's email.</p> -->

                                                        <div v-show="review_funnel.feedback_field_phone_show" class="mb-3">
                                                            <p class="mb-1 text-xs mb-2"><strong>
                                                                {{ review_funnel.phone_text }}
                                                            </strong></p>
                                                            <input name="phone" ref="phone" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
                                                        </div>

                                                        <div v-for="(item, index) in review_funnel.custom_fields" :key="index">
                                                            <div v-show="item.visible" class="mb-3">
                                                                <p class="mb-1 text-xs mb-2"><strong>
                                                                    {{ item.name }}
                                                                </strong></p>
                                                                <input :name="'custom_field_' + index" :ref="'custom_field_' + index" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
                                                            </div>
                                                        </div>

                                                    <div>
                                                        <textarea name="" id="" rows="10" class="rg-text-field w-full border rounded p-2" v-model="text" :placeholder="review_funnel.enter_feedback_text" style="height: 130px;"></textarea>
                                                        <button :disabled="!text" class="border bg-gray-200 w-full p-2 rounded hover:bg-gray-100">{{ review_funnel.send_feedback_button_text }}</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="rgrf-reviews_widget">
                                                <WidgetsRender v-if="widget" :preview_widget="widget" />
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>

                                <div v-if="review_funnel.style == 'Style 2' || review_funnel.style == 'Style 3'" style="height: 100%;">
                                    <div style="float: left; height: 100%; width: 50%;">
                                        <img v-if="review_funnel.style2_image_type == 'Image 1'" :src="require('@/assets/images/review-funnel-templates/style2.png')" style="width: 100%; height: 100%;transform: rotate(270deg);object-fit: contain;" />
                                        <img v-if="review_funnel.style2_image_type == 'Image 2'" :src="require('@/assets/images/review-funnel-templates/style3.png')" style="width: 100%; height: 100%;" />
                                        <img v-if="review_funnel.style2_image_type == 'Custom' && review_funnel.style2_image_url" :src="review_funnel.style2_image_url" style="width: 100%; height: 100%;" />
                                    </div>
                                    <div style="float: left; height: 100%; width: 50%;">
                                        <div style="display: table; width: 100%; height: 100%;">
                                            <div class="rgrf-body" :style="'background-color: ' + review_funnel.background_color + '; color: ' + review_funnel.text_color + '; display: table-cell; vertical-align: middle; width: 100%;'">

                                                <div style="width: 500px; margin: auto;">
                                                    <div class="rgrf-logo" v-if="review_funnel.logo">
                                                        <img :src="review_funnel.logo" alt="" style="height: 60px;">
                                                    </div>

                                                    <div class="rgrf-review-process" :style="((review_funnel.direct_links_display == 'Icon + URL') ? '' : 'max-width: 600px;')">
                                                        <div class="shadow p-6" v-if="selected_view == 1">
                                                            <div class="text-center text-lg mb-4">
                                                                {{ review_funnel.positive_feedback_text }}
                                                            </div>

                                                            <div class="" :style="((review_funnel.direct_links_display == 'Icon' || review_funnel.direct_links_display == 'Icon + Site Name') ? 'text-align: center;' : '')">
                                                                <a :href="source.url" v-for="(source, index) in review_funnel.sources" :key="index" class="rounded-l shadow items-center rounded mb-2 p-3"
                                                                    :class="((review_funnel.direct_links_display == 'Icon + Site Name' || review_funnel.direct_links_display == 'Icon') ? 'rgrf-icon-diret-link' : ' flex flex-row w-full')"
                                                                    :style="'background-color: ' + review_funnel.direct_links_button_background + '; color: ' + review_funnel.direct_links_button_text_color + ' !important;'"
                                                                target="review-platform">
                                                                    <div v-if="review_funnel.direct_links_display == 'Icon + URL'" class="w-12 flex-shrink-0">
                                                                        <img :src="source.logo || require('@/assets/images/review-services/www.' + getHostname(source.site) + '.png')" alt="" class="rounded">
                                                                    </div>
                                                                    <div v-if="review_funnel.direct_links_display == 'Icon + URL'" class="truncate p-3"><span class="">{{ getHostname(source.site) }}</span>{{ getPathname(source.site) }}</div>

                                                                    <div v-if="review_funnel.direct_links_display == 'Icon + Site Name'" class="flex-shrink-0 icon_site_name text-center">
                                                                        <img :src="source.logo || require('@/assets/images/review-services/www.' + getHostname(source.site) + '.png')" alt="" class="rounded" style="width: 70px; height: 70px;">
                                                                    </div>
                                                                    <div v-if="review_funnel.direct_links_display == 'Icon + Site Name'" class="truncate pt-3 text-center"><span class="" style="font-size: 14px;">{{ reviewSitesNamesAll["www." + getHostname(source.site)] || source.site_name }}</span></div>

                                                                    <div v-if="review_funnel.direct_links_display == 'Icon'" class="w-12 flex-shrink-0">
                                                                        <img :src="source.logo || require('@/assets/images/review-services/www.' + getHostname(source.site) + '.png')" alt="" class="rounded" style="width: 48px; height: 48px;">
                                                                    </div>
                                                                </a>

                                                                <br clear="all" v-if="review_funnel.direct_links_display == 'Icon'" />
                                                            </div>
                                                        </div>

                                                        <div class="shadow p-6" v-if="selected_view == 0">
                                                            <div v-if="review_funnel.survey_enabled">
                                                                <div class="text-center text-lg mb-4">
                                                                    {{ review_funnel.survey_title }}
                                                                    
                                                                    <div style="text-align: left; padding-top: 20px; padding-bottom: 20px; border-bottom: 1px solid rgba(0, 0, 0, 0.125);">
                                                                        <SurveyQuestionsView :questions="questionsList" :readOnly="false" />

                                                                        <div class="mb-3" style="padding-top: 20px; padding-bottom: 20px; margin-top: 10px; border-top: 1px solid rgba(0, 0, 0, 0.125);">
                                                                            <p class="mb-1 text-xs mb-2"><strong>
                                                                                {{ review_funnel.additional_comments_text }}
                                                                            </strong></p>
                                                                            <textarea name="additonal_comments" ref="additonal_comments" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="height: 150px;"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="text-center text-lg mb-4">
                                                                {{ review_funnel.segmentation_text }}
                                                            </div>

                                                            <div v-if="review_funnel.segmentation_option == 'Faces'" class="flex flex-row items-center justify-center rounded-lg">

                                                                <img @click="happy = false; choosedSmiley = true" @mouseover="rating_selected = review_funnel.evaluation_text_bad;" @mouseout="rating_selected = '';" src="@/assets/images/icons/sad.svg" class="wp-3 cursor-pointer rounded-l-lg rgrf-face-image" style="" alt="sad">
                                                                <img @click="happy = false; choosedSmiley = true" @mouseover="rating_selected = review_funnel.evaluation_text_not_bad;" @mouseout="rating_selected = '';" src="@/assets/images/icons/confused.svg" class="wp-3 cursor-pointer rgrf-face-image" alt="indifferent">
                                                                <img @click="happy = true; choosedSmiley = true" @mouseover="rating_selected = review_funnel.evaluation_text_good;" @mouseout="rating_selected = '';" src="@/assets/images/icons/smiling.svg" class="wp-3 cursor-pointer rounded-r-lg rgrf-face-image" alt="happy">

                                                            </div>

                                                            <div v-if="review_funnel.segmentation_option == 'Thumbs'" class="flex flex-row items-center justify-center rounded-lg">

                                                                <i class="fa fa-thumbs-down rgrf-segmentation-thumbs red--text text--lighten-1" @mouseover="rating_selected = review_funnel.evaluation_text_bad;" @mouseout="rating_selected = '';"></i>
                                                                <i class="fa fa-thumbs-up rgrf-segmentation-thumbs green--text text--lighten-1" @mouseover="rating_selected = review_funnel.evaluation_text_good;" @mouseout="rating_selected = '';"></i>

                                                            </div>

                                                            <div v-if="review_funnel.segmentation_option == 'Hearts'" class="flex flex-row items-center justify-center rounded-lg">

                                                                <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 0) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_bad; hovered_item = 0;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                                <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 1) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_subpar; hovered_item = 1;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                                <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 2) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_okay; hovered_item = 2;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                                <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 3) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_good; hovered_item = 3;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                                <i class="fa fa-heart rgrf-segmentation-heart" :style="((hovered_item >= 4) ? 'color: red' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_great; hovered_item = 4;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>

                                                            </div>

                                                            <div v-if="review_funnel.segmentation_option == 'Stars'" class="flex flex-row items-center justify-center rounded-lg">

                                                                <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 0) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_bad; hovered_item = 0;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                                <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 1) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_subpar; hovered_item = 1;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                                <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 2) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_okay; hovered_item = 2;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                                <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 3) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_good; hovered_item = 3;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>
                                                                <i class="fa fa-star rgrf-segmentation-star" :style="((hovered_item >= 4) ? 'color: #FFCA28' : '')" @mouseover="rating_selected = review_funnel.evaluation_text_great; hovered_item = 4;" @mouseout="rating_selected = ''; hovered_item = -1;"></i>

                                                            </div>

                                                            <div v-if="review_funnel.segmentation_option == 'NPS'" class="flex flex-row items-center justify-center rounded-lg">

                                                                <v-rating
                                                                    v-model="rating"
                                                                    length="11"
                                                                    large
                                                                >
                                                                    <template v-slot:item="props">
                                                                    <v-icon
                                                                        :color="getNpsColor(props)"
                                                                        v-text="`mdi-numeric-${props.index}-box`"
                                                                        @click="rating = props.index + 1;"
                                                                        @mouseover="selectNpsScore(props.index)"
                                                                        large
                                                                    ></v-icon>
                                                                    </template>
                                                                </v-rating>

                                                            </div>

                                                            <div class="rgrf-rating-selected">{{ rating_selected }}</div>
                                                        </div>

                                                        <div class="shadow p-6" v-if="selected_view == 2">
                                                            <div class="text-center text-lg mb-4">
                                                                {{ review_funnel.negative_feedback_text }}
                                                            </div>

                                                            <div v-show="review_funnel.feedback_field_first_name_show" class="mb-3">
                                                                <p class="mb-1 text-xs mb-2"><strong>
                                                                    {{ review_funnel.first_name_text }}
                                                                </strong></p>
                                                                <input name="first_name" ref="first_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                                                                </div>

                                                                <div v-show="review_funnel.feedback_field_last_name_show" class="mb-3">
                                                                <p class="mb-1 text-xs mb-2"><strong>
                                                                    {{ review_funnel.last_name_text }}
                                                                </strong></p>
                                                                <input name="last_name" ref="last_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                                                                </div>

                                                                <!-- <p class="text-xs text-gray-600 mb-4">Your contact's name.</p> -->

                                                                <div v-show="review_funnel.feedback_field_email_show" class="mb-3">
                                                                <p class="mb-1 text-xs mb-2"><strong>
                                                                    {{ review_funnel.email_text }}
                                                                </strong></p>
                                                                <input name="email" ref="email" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
                                                                </div>

                                                                <!-- <p class="text-xs text-gray-600 mb-4">Your contact's email.</p> -->

                                                                <div v-show="review_funnel.feedback_field_phone_show" class="mb-3">
                                                                    <p class="mb-1 text-xs mb-2"><strong>
                                                                        {{ review_funnel.phone_text }}
                                                                    </strong></p>
                                                                    <input name="phone" ref="phone" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
                                                                </div>

                                                                <div v-for="(item, index) in review_funnel.custom_fields" :key="index">
                                                                    <div v-show="item.visible" class="mb-3">
                                                                        <p class="mb-1 text-xs mb-2"><strong>
                                                                            {{ item.name }}
                                                                        </strong></p>
                                                                        <input :name="'custom_field_' + index" :ref="'custom_field_' + index" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
                                                                    </div>
                                                                </div>

                                                            <div>
                                                                <textarea name="" id="" rows="10" class="rg-text-field w-full border rounded p-2" v-model="text" :placeholder="review_funnel.enter_feedback_text" style="height: 130px;"></textarea>
                                                                <button :disabled="!text" class="border bg-gray-200 w-full p-2 rounded hover:bg-gray-100">{{ review_funnel.send_feedback_button_text }}</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id="rgrf-reviews_widget">
                                                        <WidgetsRender v-if="widget" :preview_widget="widget" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="clear: both;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<div>
      <button class="bg-gray-200 py-2 px-3 text-gray-600 hover:bg-gray-300 rounded" @click="$refs.dialogPlatform.show()"><font-awesome-icon icon="plus-circle" class="mr-1"></font-awesome-icon> Add platform</button>
    </div>-->
  </panel-navigation>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import urlParser from 'url-parse'
import PanelNavigation from '@/components/PanelNavigation.vue'
import WidgetsRender from '@/views/panel/Business/Widgets/Render/Index.vue'
import reviewSitesNames from '@/config/review_sites/names';
import reviewSitesNamesAll from '@/config/review_sites/names_all';
import reviewSitesUrls from '@/config/review_sites/urls';
import ColorPicker from '@/components/common/ColorPicker.vue'
import SurveyBuilder from './SurveyBuilder.vue';
import SurveyQuestionsView from './SurveyQuestionsView';
import sampleQuestionObj from './survey-builder.json';

import { VueEditor } from "vue2-editor";

export default {
    props: ['force_business'],
  metaInfo () {
    return {
      title: "Review Funnel Builder | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
	WidgetsRender,
    VueEditor,
    ColorPicker,
    SurveyBuilder,
    SurveyQuestionsView
  },
  data () {
    return {
        savingFunnel: false,
        saved: false,
        file: null,
        image: null,

        rating: 8,

        short_name: "",
        short_name_error: "",

        edit_url: false,

        review_funnel: {},

        rating_selected: "",

        hovered_item: -1,

        selected_view: 0,

        source_form_show: false,
        source_form_mode: 'add',
        source_form_url: '',
        file_source_logo: null,
        source_logo: "",
        source_site_name: "",
        source_form_error: '',
        valid_url: true,
        current_url_format: '',

        widget: null,

        text: "",

        reviewSitesUrls: reviewSitesUrls,

        leave_a_review_process_items: [
            "Direct Link from Email/SMS",
            "Direct Links",
            "Segmentation",
        ],

        links_type_items: [
            "Internal Links/Feedback",
            "External Links"
        ],

        reviewSitesNames: reviewSitesNames,

        reviewSitesNamesAll: reviewSitesNamesAll,

        segmentation_options: [
            "Faces",
            "Thumbs",
            "Hearts",
            "Stars",
            "NPS"
        ],

        custom_gating_stars_options: [
            { value: -1, text: 'None (all reviews go to feedback form)' },
            { value: 1, text: 1 },
            { value: 2, text: 2 },
            { value: 3, text: 3 },
            { value: 4, text: 4 },
            { value: 5, text: 5 }
        ],

        custom_gating_nps_options: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],

        direct_links_display_options: [
            "Icon + URL",
            "Icon + Site Name",
            "Icon",
        ],

	  funnelLoaded: false,
	  font_family_values: [
		  "Open Sans (Google)",
		  "Source Sans Pro (Google)",
		  "Roboto (Google)",
		  "Lato (Google)",
		  "PT Sans (Google)",
		  "EB Garamond (Google)",
		  "Raleway (Google)",
		  "Ubuntu (Google)",
		  "Montserrat (Google)",
		  "Courgette (Google)",
		  "Arial",
		  "Helvetica",
		  "Sans-serif",
	  ],
	  align_values: [
		  "Left",
          "Center",
		  "Right"
	  ],

      customToolbar: [
            ["bold", "italic", "underline"],
            // [{ list: "ordered" }, { list: "bullet" }],
            ["image", "link"]
        ],

        message: null,

        feedback_field_message: true,

        questionsList: [],
        addQuestion: false,

        showQrCodeModal: false,
        qrc: null,

        gettingReviewLink: false
    }
  },
  computed: {
    ...mapState({
      listings: state => state.listings.listings,
      fetchingListings: state => state.listings.fetchingListings,
      me: state => state.me.me
    })
  },
  methods: {
        openFunnel() {
            window.open(this.getFunnelLink());
        },

        openQrCode() {
            let self = this;

            self.showQrCodeModal = true;

            // if (!self.qrc) {
                setTimeout(function() {
                    self.qrc = new QRCode(document.getElementById("qrcode"), self.getFunnelLink());
                }, 200);
            // }
        },

        downloadQrCode() {
            let img = document.querySelector("#qr-code-wrapper img");
            let imgSrc = img.src;

            // window.location.href = 'data:application/octet-stream;base64,' + img;
            window.location.href = imgSrc;

            var a = document.createElement("a"); //Create <a>
            // a.href = "data:image/png;base64," + ImageBase64; //Image Base64 Goes here
            a.href = imgSrc //Image Base64 Goes here
            a.download = "ReviewFunnelQrCode.png"; //File name Here
            a.click(); //Downloaded file
        },

      async onUpload() {
      if (this.file !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

        //   let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/review-funnels/save-logo`, {
        //     logo: response.data.url
        //   }, {
        //     headers: {
        //       'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        //     }
        //   })

          this.review_funnel.logo = response.data.url

        }catch(e){
        }

        this.file = null
        this.uploading = false
      }
    },

    async onUploadSourceLogo() {
      if (this.file_source_logo !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file_source_logo)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.source_logo = response.data.url

        }catch(e){
        }

        this.file_source_logo = null
        this.uploading = false
      }
    },

    async onUploadImage() {
        if (this.image !== null) {
            this.uploading = true
            let formData = new FormData()
            formData.append("file", this.image)

            try {
                let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
                    headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                })

                this.review_funnel.style2_image_url = response.data.url;

            }
            catch(e) {
                console.log('e', e);
            }

            this.image = null
            this.uploading = false
        }
    },

    saveFunnel: async function() {
        this.savingFunnel = true
		this.saved = true
		let self = this

        self.review_funnel.survey_questions = this.questionsList;

		try {
			let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/review-funnels/save-funnel?' + ((this.force_business) ? 'fbid=' + this.force_business.id : ''), {
				review_funnel: self.review_funnel
			} , {
			headers: {
					'Authorization': 'Bearer ' + this.$store.state.auth.jwt
				},
			})
			setTimeout(function() {
                self.savingFunnel = false
				self.saved = false
			}, 500)
		}catch(e){
		}
	},

    getFunnelPrefix() {
        return window.location.origin + '/';
    },

    getFunnelLink() {
        return window.location.origin + '/' + this.review_funnel.short_name;
    },

    async saveShortName() {
        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/review-funnels/check-short-name`, {
            short_name: this.short_name
        }, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
        })

        if (response.data.success) {
            this.review_funnel.short_name = this.short_name;
            this.edit_url = false;
            this.short_name_error = '';
        }
        else {
            this.short_name_error = response.data.error;
            // this.short_name_error = "Invalid URL. Please only add letters, numbers, hyphens and underscore characters. No spaces allowed.";
        }
    },

    cancelEditShortName() {
        this.short_name = this.review_funnel.short_name;
        this.edit_url = false;
        this.short_name_error = '';
    },

    setCustomCSS() {
        // Apply custom CSS
        if (this.review_funnel.custom_css) {
            if (document.getElementById("rgrf-custom-css-sheet") === null) {
                var styleSheet = document.createElement("style")
                styleSheet.type = "text/css"
                styleSheet.innerText = this.review_funnel.custom_css.replace(/\n/g, " ")
                styleSheet.id = 'rgrf-custom-css-sheet';
                document.body.appendChild(styleSheet);
            }
            else {
                document.getElementById("rgrf-custom-css-sheet").innerText = this.review_funnel.custom_css.replace(/\n/g, " ");
            }
        }
        else {
            if (document.getElementById("rgrf-custom-css-sheet") === null) {
                var styleSheet = document.createElement("style")
                styleSheet.type = "text/css"
                styleSheet.innerText = ""
                styleSheet.id = 'rgrf-custom-css-sheet';
                document.body.appendChild(styleSheet);
            }
            else {
                document.getElementById("rgrf-custom-css-sheet").innerText = '';
            }
        }
        // END Apply custom CSS
    },

    async removeLogo() {
    //    await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/review-funnels/remove-logo`, {}, {
    //     headers: {
    //       'Authorization': 'Bearer ' + this.$store.state.auth.jwt
    //     }
    //   })

      this.review_funnel.logo = '';
    },
    getHostname(url) {
      return urlParser(url).hostname.replace("www.", "").replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com").replace("tripadvisor.co.uk", "tripadvisor.com").replace("opentable.co.uk", "opentable.com").replace("airbnb.co.uk", "airbnb.com").replace("yellowpages.ca", "yellowpages.com")
    },
    getPathname(url) {
      let parsed_url = urlParser(url)

      if (url.includes("google"))
        return parsed_url.pathname + decodeURIComponent(parsed_url.query)

      return parsed_url.pathname
    },
    getIconUrl(source) {
        if (source.logo)
            return source.logo;

        return require('@/assets/images/review-services/www.' + this.getHostname(source.site) + ".png");
    },
	getReviewFunnel: async function() {
        console.log('this.force_business', this.force_business)
        let self = this;

		try {
			let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/review-funnels/get-settings?' + ((this.force_business) ? 'fbid=' + this.force_business.id : ''), {
			headers: {
				'Authorization': 'Bearer ' + this.$store.state.auth.jwt
			},
			})
			
			this.review_funnel = response.data.review_funnel

            if (this.review_funnel.leave_a_review_process == 'Direct Links')
                this.selected_view = 1
            
            this.short_name = this.review_funnel.short_name;

			this.funnelLoaded = true

            this.message = '<script src="' + this.getEmbedCode() + '" type="text/javascript"><\/script>';

            if (this.review_funnel.reviews_widget) {
                let self = this;
                try {
                    let exploded = this.review_funnel.reviews_widget.split('token=');
                    let exploded2 = exploded[1].split('"');
                    let exploded3 = exploded2[0].split('&');

                    let widget_token = exploded3[0];

                    let url = process.env.VUE_APP_API_ENDPOINT + '/widgets/get-data/' + widget_token;

                    axios.get(url, {
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                        },
                    }).then(function(response) {
                        self.widget = response.data.widget_data.widget
                    });
                }
                catch(e) {
                    self.widget = null
                }
            }

            if (!this.review_funnel.google_compliant_link) {
                for (var i in this.review_funnel.sources) {
                    let source = this.review_funnel.sources[i];
                    console.log(source);

                    if (source.url.indexOf('google') != -1) {
                        this.review_funnel.google_compliant_link = source.url;
                    }
                }
            }

            if (this.review_funnel.survey_enabled) {
                this.questionsList = this.review_funnel.survey_questions;
            }

            self.setCustomCSS();
		}catch(e){
            console.log(e);
		}
	},

    addSource() {
        if (this.gettingReviewLink)
            return;

        let theSourceUrl = this.source_form_url;

        if (!this.valid_url && !this.source_site_name) {
            this.source_form_error = 'Please add site name.';
            return;
        }

        if (!this.valid_url && !this.source_logo) {
            this.source_form_error = 'Please upload an icon.';
            return;
        }

        if (theSourceUrl.indexOf('facebook') !== -1) {
            theSourceUrl = theSourceUrl + "/reviews";
            theSourceUrl = theSourceUrl.replace("//reviews", "/reviews");
        }

        let newSource = {
            url: this.source_form_link || theSourceUrl,
            site: theSourceUrl
        };

        if (this.source_logo)
            newSource.logo = this.source_logo;

        if (this.source_site_name)
            newSource.site_name = this.source_site_name;

        this.review_funnel.sources.push(newSource);

        this.source_form_show = false;
        this.file_source_logo = null;
        this.source_logo = "";
        this.source_site_name = "";
    },

    checkUrlValid() {
        // return true;
        
      try {
        let parsed_url = new URL(this.source_form_url)

        let reviewSiteUrl = this.reviewSitesUrls[parsed_url.host.replace("www.", "").replace(".ca", ".com")
                    .replace("doctor.webmd", "webmd")
                    .replace("uk.trustpilot.com", "trustpilot.com")
                    .replace("ebay.co.uk", "ebay.com")
                    .replace("amazon.co.uk", "amazon.com")
                    .replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com")
                    .replace("tripadvisor.co.uk", "tripadvisor.com").replace("opentable.co.uk", "opentable.com").replace("airbnb.co.uk", "airbnb.com").replace("yellowpages.ca", "yellowpages.com")]

        if (!reviewSiteUrl) {
          this.valid_url = false;
          return;
        }

        let explodedSlashes1 = reviewSiteUrl.split("/");
        let explodedSlashes2 = this.source_form_url.split("/");

        if (reviewSiteUrl.indexOf('facebook') != -1) {
            if (explodedSlashes1.length - explodedSlashes2.length > 0 || explodedSlashes2.length - explodedSlashes1.length > 0) {
                this.valid_url = false;
                this.current_url_format = reviewSiteUrl;
                return;
            }
        }
        else {
            if (explodedSlashes1.length - explodedSlashes2.length > 1 || explodedSlashes2.length - explodedSlashes1.length > 1) {
                this.valid_url = false;
                this.current_url_format = reviewSiteUrl;
                return;
            }
        }

        let explodedQuestionMarks1 = reviewSiteUrl.split("?");
        let explodedQuestionMarks2 = this.source_form_url.split("?");

        if (explodedQuestionMarks1.length != explodedQuestionMarks2.length) {
          this.valid_url = false;
          this.current_url_format = reviewSiteUrl;
          return;
        }

        let explodedAmpersands1 = reviewSiteUrl.split("&");
        let explodedAmpersands2 = this.source_form_url.split("&");

        if (explodedAmpersands1.length != explodedAmpersands2.length) {
          this.valid_url = false;
          this.current_url_format = reviewSiteUrl;
          return;
        }
      }
      catch(e) {
        this.valid_url = false;
        return;
      }

      this.valid_url = true;
    },

    deleteSource(index, source) {
        this.selected_view = 1;

        let new_sources = [];
        for (var i in this.review_funnel.sources) {
            if (i != index) {
                new_sources.push(this.review_funnel.sources[i]);
            }
        }

        this.review_funnel.sources = [];

        for (var i in new_sources)
            this.review_funnel.sources.push(new_sources[i]);
    },

    getNpsColor(props) {
        if (props.isFilled) {
            if (props.index == 0)
                return "blue lighten-2";

            if (props.index == 1)
                return "blue lighten-1";

            if (props.index == 2)
                return "blue";

            if (props.index == 3)
                return "blue darken-1";

            if (props.index == 4)
                return "blue darken-2";

            if (props.index == 5)
                return "green lighten-2";

            if (props.index == 6)
                return "green lighten-1";

            if (props.index == 7)
                return "green";

            if (props.index == 8)
                return "green darken-1";

            if (props.index == 9)
                return "green darken-2";

            if (props.index == 10)
                return "green darken-3";
        }

        return '';
    },

    selectNpsScore(score) {
        this.rating = score + 1;

        console.log('score', score)

        if (score == 0)
            this.rating_selected = this.review_funnel.evaluation_text_not_at_all;
        else if (score <= 4)
            this.rating_selected = this.review_funnel.evaluation_text_not_likely;

        if (score == 5)
            this.rating_selected = this.review_funnel.evaluation_text_neutral;
        
        if (score == 6)
            this.rating_selected = this.review_funnel.evaluation_text_likely;
        
        if (score == 7 || score == 8)
            this.rating_selected = this.review_funnel.evaluation_text_very_likely;
        
        if (score == 9 || score == 10)
            this.rating_selected = this.review_funnel.evaluation_text_extremly_likely;
    },

    getEmbedCode: function() {

		let self = this

		let url = ((process.env.NODE_ENV == 'development') ? "http://localhost:8000" : "https://" + window.location.host) + "/js/v1/embed.js?token=" + ((this.review_funnel) ? this.review_funnel.short_name : "placeholder-short-name") + "&type=review_funnel";

		// Dev
		if (process.env.NODE_ENV == 'development') {
			url = url + "&env=dev";
		}

		return url;
	},

    onCopy: function (e) {
		this.$swal({
			title: "Copied",
			text: "Embed code copied successfully!",
			showCancelButton: false,
			});
	},

	onError: function (e) {
		alert('Failed to copy the text to the clipboard')
		console.log(e);
	},

    updateQuestionsList(question) {
        let questionDetails = question.question;
      const questionIndex = this.questionsList.findIndex(x => x.id === questionDetails.id);
      if (questionIndex >= 0) {
        this.questionsList.splice(questionIndex, 1, questionDetails);
      } else {
        this.questionsList.push(JSON.parse(JSON.stringify(questionDetails)));
      }
      this.addQuestion = false;
      this.$root.$emit('selected-question', null);
      window.console.log(question, this.addQuestion, this.questionsList);
    },
    addNewQuestion() {
      this.sampleQuestion = JSON.parse(JSON.stringify(sampleQuestionObj));
      this.sampleQuestion.uuid = uuidv4();

      function uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
        }

      this.addQuestion = true;
    },
    addField() {
        this.selected_view = 2
        this.review_funnel.custom_fields.push({
          'name': 'Field Name',
          'visible': true,
          'required': false,
          'value': ''
        });
    },
    removeField(index) {
        this.selected_view = 2
        let all_pages = this.review_funnel.custom_fields;
        let new_fields = [];

        for (var i in all_pages) {
            if (i != index) {
              new_fields.push(all_pages[i]);
            }
        }

        this.review_funnel.custom_fields = new_fields;
    },
    async getGooglePlaceId() {
        this.gettingReviewLink = true;

        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/review-funnels/get-google-placeid`, {
            source_form_url: this.source_form_url
        }, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
        })

        this.gettingReviewLink = false;

        if (response.data.place_id) {
            console.log('response.data.place_id',response.data.place_id)
            this.source_form_link = 'https://search.google.com/local/writereview?placeid=' + response.data.place_id;
            console.log('this.source_form_link', this.source_form_link)
        }
    }
  },
  mounted() {
    this.$root.$on('add-update-question', q => {
      this.updateQuestionsList(q);
    });

    this.$root.$on('add-cancel-question', q => {
        this.addQuestion = false;
        this.$root.$emit('selected-question', null);
    });
  },
  created() {
    this.getReviewFunnel()
  },
	watch: {
		'review_funnel.custom_css': {
			handler() {
				this.setCustomCSS()
			}
		},
        "source_form_url": {
            handler () {
                let self = this;

                this.checkUrlValid()

                if (this.source_form_url.split('google').length > 1)
                    this.getGooglePlaceId();
            }
        },
        "review_funnel.reviews_widget": {
            handler () {
                let self = this;
                try {
                    let exploded = this.review_funnel.reviews_widget.split('token=');
                    let exploded2 = exploded[1].split('"');
                    let exploded3 = exploded2[0].split('&');

                    let widget_token = exploded3[0];

                    let url = process.env.VUE_APP_API_ENDPOINT + '/widgets/get-data/' + widget_token;

                    axios.get(url, {
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                        },
                    }).then(function(response) {
                        self.widget = response.data.widget_data.widget
                    });
                }
                catch(e) {
                    self.widget = null
                }
            }
        },
	}
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Open+Sans&family=Source+Sans+Pro&family=Roboto&family=Lato&family=PT+Sans&family=EB+Garamond&family=Raleway&family=Ubuntu&family=Montserrat&family=Courgette');
  
  a {
      text-decoration: none !important;
  }

  #editor1 {
      height: 120px;
  }

  .rgrf-header {
      width: 100%;
      min-height: 80px;
      padding: 20px;
  }

  .rgrf-footer {
      /* margin-top: 30px; */
      width: 100%;
      min-height: 60px;
      background-color: #f5f5f5;
      padding: 20px;
  }

  .rgrf-footer-text {
      font-size: 15px;
  }

  .rgrf-business-name {
      font-size: 20px;
  }

  .rgrf-subtitle {
      padding-top: 10px;
      font-size: 14px;
  }

  .rgrf-body {
      /* background-color: #f5f5f5; */
      width: 100%;
      max-width: 1200px;
      margin: auto;
      padding: 20px;
  }

  .rgrf-body .rgrf-logo {
      padding-top: 20px;
      padding-bottom: 10px;
      text-align: center;
  }

  .rgrf-body .rgrf-logo img {
      display: initial;
  }

  .rgrf-review-process {
      width: 100%;
      max-width: 800px;
      margin: auto;
      padding-top: 40px;
  }

  .rgrf-review-process img.rgrf-face-image {
      height: 100px;
      margin: 20px;
  }
  
  .rgrf-icon-diret-link {
      margin-right: 10px;
      display: inline-block;
  }

  .rgrf-segmentation-thumbs {
      cursor: pointer;
      font-size: 70px;
      color: #727272;
      margin: 30px;
  }

  .rgrf-segmentation-heart, .rgrf-segmentation-star {
      cursor: pointer;
      font-size: 70px;
      color: grey;
      margin: 10px;
  }

  /* .rgrf-segmentation-heart:hover {
      color: red;
  }

  .rgrf-segmentation-star:hover {
      color: #FFCA28;
  } */

  .rgrf-rating-selected {
      padding-top: 10px;
      text-align: center;
      height: 20px;
  }

  div#rgrf-reviews_widget {
      padding-top: 40px;
      position: relative;
  }

  div.rgrf-google-compliant-link-wrapper {
      position: absolute;
      width: 100%;
      left: 0px;
      margin-top: -50px;
  }

  div.rgrf-google-compliant-link-wrapper a {
      color: #CCCCCC !important;
      font-size: 12px;
  }

  #qrcode {
    margin: auto; width: 250px;
  }
</style>