<template>

    <panel-navigation>
        <div>
            <h1 class="text-lg font-semibold mb-3">Resources</h1>

            <hr class="border-b border-gray-100 mb-3">

              <div>
                <div v-if="selected_folder_id">
                  <div class="border-1 border-blue bg-white text-blue text-xs text-bold px-4 py-2 rounded cursor-pointer float-left mr-3" @click="selected_folder_id = null;" style="margin-top: -7px;">&lt; Back</div>

                  <br clear="all" /><br clear="all" />

                  <div v-for="(item, index) in this.members_area" :key="index" class="float-left">
                    <div v-if="item.folder_id == selected_folder_id" class="dashboard-box stats-box mr-4 mb-4" style='width: 300px !important;'>
                      <v-card
                        class="pa-2"
                        tile
                      >

                        <div style="text-align: center;">
                          <img :src="item.image" style="height: 200px; display: inline-block;" />
                        </div>
                        <div class="p-3">
                          <strong>{{ item.title }} </strong>
                          <p class="text-gray-600 text-sm" style="min-height: 70px;">
                            {{ item.description }}
                          </p>
                          <a class="text-sm" :href="item.link" target="_blank">{{ item.link_text }} &nbsp; <i class="fa fa-chevron-right" style="font-size: 12px;"></i></a>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
                
                <div v-if="!selected_folder_id">
                  <div v-for="(folder, index) in this.members_area_folders" :key="index" class="dashboard-box stats-box float-left mr-4 mb-4" style='width: 100%; max-width: 250px !important; cursor: pointer;' @click="selected_folder_id = folder.id">
                    <v-card
                      class="pa-1"
                      tile
                    >
                      <div style="text-align: center;">
                        <i class="mdi mdi-folder-outline" style="font-size: 80px; color: blue;"></i>
                      </div>
                      <div class="p-1 pb-3" style="text-align: center;">
                        <strong>{{ folder.title }} </strong>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
    </panel-navigation>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
import PanelNavigation from '@/components/PanelNavigation.vue'

export default {
  metaInfo () {
    return {
      title: "Resources | " + this.$appName
    }
  },
  data() {
    return {
      selected_folder_id: null,
      members_area: null,
      members_area_folders: null
    }
  },
  components: {
    PanelNavigation
  },
  created() {
    let self = this;
    axios.get(`${process.env.VUE_APP_API_ENDPOINT}/agency/get-agency-details` + "?host=" + window.location.host).then(function(response) {
      self.members_area = response.data.agency.members_area;
      self.members_area_folders = response.data.agency.members_area_folders;
    });
  }
}
</script>