module.exports = {
    "www.facebook.com": "Facebook",
	"www.google.com": "Google",
	"www.yelp.com": "Yelp",
    "www.agoda.com": "Agoda",
    "www.avvo.com": "Avvo",
	"www.expedia.com": "Expedia",
	"www.tripadvisor.com": "Tripadvisor",
	"www.zillow.com": "Zillow",
	"www.zomato.com": "Zomato",
	"www.airbnb.com": "Airbnb",
	"www.aliexpress.com": "Aliexpress",
	"www.alternativeto.com": "Alternativeto",
	"www.amazon.com": "Amazon",
	"www.angi.com": "Angie's List",
	"www.apartmentratings.com": "Apartmentratings",
	"www.apartments.com": "Apartments",
	"www.apps.apple.com": "Appstore",
	"www.bbb.org": "Bbb",
	"www.bookatable.co.uk": "Bookatable",
	"www.booking.com": "Booking",
	"www.capterra.com": "Capterra",
	"www.cargurus.com": "Cargurus",
	"www.cars.com": "Cars",
	"www.citysearch.com": "Citysearch",
	"www.classpass.com": "Classpass",
	"www.consumeraffairs.com": "Consumeraffairs",
	"www.creditkarma.com": "Creditkarma",
	"www.customerlobby.com": "Customerlobby",
	"www.dealerrater.com": "Dealerrater",
	"www.ebay.com": "Ebay",
	"www.edmunds.com": "Edmunds",
	"www.etsy.com": "Etsy",
	"www.findlaw.com": "Findlaw",
	"www.foursquare.com": "Foursquare",
	"www.g2crowd.com": "G2crowd",
	"www.g2.com": "G2",
	"www.gartner.com": "Gartner",
	"www.glassdoor.com": "Glassdoor",
	"www.greatschools.org": "Greatschools",
	"www.healthgrades.com": "Healthgrades",
	"www.homeadvisor.com": "Homeadvisor",
	"www.homeaway.com.au": "Homeaway",
	"www.homestars.com": "Homestars",
	"www.houzz.com": "Houzz",
	"www.hotels.com": "Hotels",
	"www.indeed.com": "Indeed",
	"www.influenster.com": "Influenster",
	"www.insiderpages.com": "Insiderpages",
	"www.itcentralstation.com": "Itcentralstation",
	"www.lawyers.com": "Lawyers",
	"www.lendingtree.com": "Lendingtree",
	"www.martindale.com": "Martindale",
	"www.niche.com": "Niche",
	"www.openrice.com": "Openrice",
	"www.opentable.com": "Opentable",
	"www.producthunt.com": "Producthunt",
	"www.productreview.com.au": "Productreview",
	"www.ratemds.com": "Ratemds",
	"www.realself.com": "RealSelf",
	"www.reserveout.com": "Reserveout",
	"www.sitejabber.com": "Sitejabber",
	"www.softwareadvice.com": "Softwareadvice",
	"www.talabat.com": "Talabat",
	"www.theknot.com": "Theknot",
	"www.thumbtack.com": "Thumbtack",
	"www.trulia.com": "Trulia",
	"www.trustedshops.com": "Trustedshops",
	"www.trustpilot.com": "Trustpilot",
	"www.trustradius.com": "Trustradius",
	"www.vitals.com": "Vitals",
	"www.vrbo.com": "Vrbo",
	"www.walmart.com": "Walmart",
	"www.webmd.com": "Webmd",
	"www.weddingwire.com": "Weddingwire",
	"www.yell.com": "Yell",
	"www.yellowpages.com": "Yellowpages",
	"www.zocdoc.com": "Zocdoc",
};