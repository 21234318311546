<template>

  <panel-navigation>

    <v-app-bar app color="" class="header-top px-5" elevation="0" height="80">
        <a href="javascript: void(0);" @click="$router.go(-1)" class="mr-6"><i class="mdi mdi-chevron-left text-gray-700" style="font-size: 40px;"></i></a>

        <img v-if="$agency.settings.favicon" :src="$agency.settings.favicon" alt="" class="mr-6" style="width: 30px; heigh: 30px;" />

        <div v-if="$agency.settings.favicon">
            <hr role="separator" aria-orientation="vertical" class="mr-6 v-divider v-divider--vertical theme--light" style="height: 60px;" />
        </div>

      <h1 class="text-lg font-semibold">Contact Form Editor</h1>

      <div class="bg-blue text-white text-bold text-xs inline-block rounded py-2 px-3 cursor-pointer float-right" :class="{'spinner': savingForm}" @click="saveForm()" style="margin-left: auto;">SAVE FORM</div>
    </v-app-bar>

	<v-skeleton-loader v-if="!contactFormLoaded && !$route.params.action" type="article, article, article, article, article"></v-skeleton-loader>

    <div v-if="contactFormLoaded || $route.params.action" class="bg-white" style="height: 100%;">

        <div class="flex flex-row flex-wrap -mx-3" style="height: 100%;">

            <div class="w-full lg:w-1/3 md:w-1/3 sm:w-1/3 pl-12 pt-6 border-t border-gray-100">
                <div class="pr-3" v-if="contactFormLoaded">

                    <h2 class="text-lg font-semibold">Contact Form Preferences</h2>

                    <v-tabs>
                        <v-tab>General</v-tab>
                        <v-tab>Layout</v-tab>
                        <v-tab>Content</v-tab>

                        <v-tab-item class="pt-5">
                            <p class="mb-1 text-xs mb-2"><strong>Brand/Business Name </strong></p>

                            <v-text-field
                                v-model="contact_form.company_name"
                                label=""
                                required
                                outlined
                                dense
                            ></v-text-field>

                            <p class="mb-1 text-xs mb-2"><strong>Font Family</strong></p>

                            <v-select
                                v-model="contact_form.font_family"
                                hide-details
                                :items="font_family_values"
                                outlined
                                dense
                            ></v-select>

                            <p class="mb-1 text-xs mb-2 mt-5"><strong>URL </strong></p>

                            <div v-if="!edit_url" class="bg-gray-100 p-3">
                                <a :href="contact_form.url" target="review-funnel-link" class="text-xs">{{ contact_form.url }}</a>
                            </div>
                        </v-tab-item>

                        <v-tab-item class="pt-5">
                            <p class="mb-1 text-xs mb-2"><strong>Logo </strong></p>

                                <v-file-input
                                    accept="image/*"
                                    v-model="file"
                                    @change="onUpload"
                                    class="rg-uploader"
                                    prepend-icon="mdi-camera"
                                    outlined
                                    hide-input
                                ></v-file-input>

                                    <div v-if="contact_form.logo" style="padding-top: 0px;">
                                    <span v-if="contact_form.logo" style="text-align: center;">
                                        <v-btn
                                        depressed
                                        color="error"
                                        
                                        small
                                        @click="contact_form.logo = ''; removeLogo()"
                                        class="mb-4"
                                        >
                                        Remove
                                        </v-btn>
                                    </span>
                                </div>

                                <div class="float-left has-color-picker pr-4 mb-3">
                                    <p class="mb-1 text-xs mb-2"><strong>Header</strong></p>

                                    <color-picker v-model="contact_form.header_background_color"></color-picker>
                                </div>

                                <div class="float-left has-color-picker pr-4 mb-3">
                                    <p class="mb-1 text-xs mb-2"><strong>Header Text</strong></p>

                                    <color-picker v-model="contact_form.header_text_color"></color-picker>
                                </div>

                                <div class="float-left has-color-picker pr-4 mb-3">
                                    <p class="mb-1 text-xs mb-2"><strong>Background</strong></p>

                                    <color-picker v-model="contact_form.background_color"></color-picker>
                                </div>

                                <div class="float-left has-color-picker pr-4 mb-3">
                                    <p class="mb-1 text-xs mb-2"><strong>Text Color</strong></p>

                                    <color-picker v-model="contact_form.text_color"></color-picker>
                                </div>

                                <div class="float-left has-color-picker pr-4 mb-3">
                                    <p class="mb-1 text-xs mb-2"><strong>Footer</strong></p>

                                    <color-picker v-model="contact_form.footer_background_color"></color-picker>
                                </div>

                                <div class="float-left has-color-picker pr-4 mb-3">
                                    <p class="mb-1 text-xs mb-2"><strong>Footer Text</strong></p>

                                    <color-picker v-model="contact_form.footer_text_color"></color-picker>
                                </div>

                                <div class="float-left has-color-picker pr-4 mb-3">
                                    <p class="mb-1 text-xs mb-2"><strong>Button</strong></p>

                                    <color-picker v-model="contact_form.button_background_color"></color-picker>
                                </div>

                                <div class="float-left has-color-picker pr-4 mb-3">
                                    <p class="mb-1 text-xs mb-2"><strong>Button Text</strong></p>

                                    <color-picker v-model="contact_form.button_text_color"></color-picker>
                                </div>

                                <br clear="all" /><br />

                                <div class="float-left pr-5">

                                    <p class="mb-1 text-xs mb-2"><strong>Header alignment</strong></p>

                                    <br />

                                    <v-btn-toggle
                                        v-model="contact_form.header_alignment"
                                        borderless
                                    >
                                        <v-btn value="Left" small>
                                        <span class="hidden-sm-and-down"></span>

                                        <v-icon>
                                            mdi-format-align-left
                                        </v-icon>
                                        </v-btn>

                                        <v-btn value="Center" small>
                                        <span class="hidden-sm-and-down"></span>

                                        <v-icon>
                                            mdi-format-align-center
                                        </v-icon>
                                        </v-btn>

                                        <v-btn value="Right" small>
                                        <span class="hidden-sm-and-down"></span>

                                        <v-icon>
                                            mdi-format-align-right
                                        </v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>

                                <div class="float-left">

                                    <p class="mb-1 text-xs mb-2"><strong>Footer alignment</strong></p>

                                    <br />

                                    <v-btn-toggle
                                        v-model="contact_form.footer_alignment"
                                        borderless
                                    >
                                        <v-btn value="Left" small>
                                        <span class="hidden-sm-and-down"></span>

                                        <v-icon>
                                            mdi-format-align-left
                                        </v-icon>
                                        </v-btn>

                                        <v-btn value="Center" small>
                                        <span class="hidden-sm-and-down"></span>

                                        <v-icon>
                                            mdi-format-align-center
                                        </v-icon>
                                        </v-btn>

                                        <v-btn value="Right" small>
                                        <span class="hidden-sm-and-down"></span>

                                        <v-icon>
                                            mdi-format-align-right
                                        </v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>

                                <br clear="all" /><br />

                                <p class="mb-1 text-xs mb-2"><strong>Custom CSS</strong></p>

                                <div class="w-full">
                                        <textarea name="" id="" rows="13" class="text-sm block appearance-none focus:outline-none focus:border-gray-500 w-full bg-gray-100 hover:border-grey px-4 py-4 rounded" maxlength="1000" v-model="contact_form.custom_css" placeholder="/* Example */
.rgcf-header {
height: 120px !important;
}

.rgcf-subtitle {
font-size: 17px !important;
font-weight: bold;
}

.rgcf-logo img {
height: 50px !important;
}
" ></textarea>

                                        <p class="text-xs text-gray-700 mt-2">Enter custom CSS to change the UI of the funnel.</p>
                                    </div>
                        </v-tab-item>

                        <v-tab-item class="pt-5 pr-5">
                            <p class="mb-1 text-xs mb-2"><strong>Header Subtitle </strong></p>

                            <v-text-field
                                v-model="contact_form.header_subtitle"
                                label=""
                                outlined
                                dense
                            ></v-text-field>

                            <p class="mb-1 text-xs mb-2 mt-0"><strong>Footer Text </strong></p>

                            <vue-editor v-model="contact_form.footer_text" :editor-toolbar="customToolbar" style="height: 80px;" />

                            <p class="mb-1 text-xs mb-2 mt-20"><strong>Form Title </strong></p>

                            <v-text-field @click="selected_view = 0"
                                v-model="contact_form.form_title"
                                label=""
                                outlined
                                dense
                            ></v-text-field>

                            <div class="flex flex-row flex-wrap" style="height: 100%;">
                                <div class="w-full lg:w-1/2 md:w-1/2 sm:w-full pr-3">
                                    <p class="mb-1 text-xs mb-2 mt-0"><strong>First Name </strong></p>

                                    <v-text-field @click="selected_view = 0"
                                        v-model="contact_form.first_name_text"
                                        label=""
                                        outlined
                                        dense
                                    ></v-text-field>
                                </div>

                                <div class="w-full lg:w-1/2 md:w-1/2 sm:w-full pl-3">

                                    <p class="mb-1 text-xs mb-2 mt-0"><strong>Last Name </strong></p>

                                    <v-text-field @click="selected_view = 0"
                                        v-model="contact_form.last_name_text"
                                        label=""
                                        outlined
                                        dense
                                    ></v-text-field>
                                </div>
                            </div>

                            <div class="flex flex-row flex-wrap" style="height: 100%;">
                                <div class="w-full lg:w-1/2 md:w-1/2 sm:w-full pr-3">
                                    <p class="mb-1 text-xs mb-2 mt-0"><strong>Email </strong></p>

                                    <v-text-field @click="selected_view = 0"
                                        v-model="contact_form.email_text"
                                        label=""
                                        outlined
                                        dense
                                    ></v-text-field>
                                </div>

                                <div class="w-full lg:w-1/2 md:w-1/2 sm:w-full pl-3">

                                    <p class="mb-1 text-xs mb-2 mt-0"><strong>Phone Number </strong></p>

                                    <v-text-field @click="selected_view = 0"
                                        v-model="contact_form.phone_number_text"
                                        label=""
                                        outlined
                                        dense
                                    ></v-text-field>
                                </div>
                            </div>

                            <div class="flex flex-row flex-wrap" style="height: 100%;">
                                <div class="w-full lg:w-1/2 md:w-1/2 sm:w-full pr-3">
                                    <p class="mb-1 text-xs mb-2 mt-0"><strong>Add Contact Button </strong></p>

                                    <v-text-field @click="selected_view = 0"
                                        v-model="contact_form.add_contact_button"
                                        label=""
                                        outlined
                                        dense
                                    ></v-text-field>
                                </div>

                                <div class="w-full lg:w-1/2 md:w-1/2 sm:w-full pl-3">

                                    <p class="mb-1 text-xs mb-2 mt-0"><strong>Validation Email or Phone </strong></p>

                                    <v-text-field @click="selected_view = 0"
                                        v-model="contact_form.validation_email_or_phone"
                                        label=""
                                        outlined
                                        dense
                                    ></v-text-field>
                                </div>
                            </div>

                            <div class="flex flex-row flex-wrap" style="height: 100%;">
                                <div class="w-full lg:w-1/2 md:w-1/2 sm:w-full pr-3">
                                    <p class="mb-1 text-xs mb-2 mt-0"><strong>Validation Email Already Exists </strong></p>

                                    <v-text-field @click="selected_view = 0"
                                        v-model="contact_form.validation_email_already_taken"
                                        label=""
                                        outlined
                                        dense
                                    ></v-text-field>
                                </div>

                                <div class="w-full lg:w-1/2 md:w-1/2 sm:w-full pl-3">

                                    <p class="mb-1 text-xs mb-2 mt-0"><strong>Validation Phone Already Exists </strong></p>

                                    <v-text-field @click="selected_view = 0"
                                        v-model="contact_form.validation_phone_already_taken"
                                        label=""
                                        outlined
                                        dense
                                    ></v-text-field>
                                </div>
                            </div>

                            <p class="mb-1 text-xs mb-2 mt-0"><strong>Confirmation </strong></p>

                            <v-text-field @click="selected_view = 1"
                                v-model="contact_form.confirmation_text"
                                label=""
                                outlined
                                dense
                            ></v-text-field>

                            <a href="javascript: void(0);" @click="addField();" class="text-xs">+ Add Field</a>

                            <div class="mt-2" v-for="(page, index) in contact_form.custom_fields" :key="index">
                            <div class="flex flex-wrap">
                                <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
                                <v-text-field
                                        v-model="contact_form.custom_fields[index].name"
                                        placeholder="Field Name"
                                        label=""
                                        outlined
                                        dense
                                        hide-details
                                        :append-outer-icon="'mdi-trash-can-outline'"
                                        @click:append-outer="removeField(index)"
                                    ></v-text-field>
                                </div>
                                
                                <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0" style="padding-top: 9px;">
                                    <div style="display: inline-block; padding-right: 20px;">
                                        <v-switch
                                                color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="contact_form.custom_fields[index].visible"
                                            checked
                                            label="Show"
                                            class="mb-3"
                                        ></v-switch>
                                    </div>

                                    <div style="display: inline-block;">
                                        <v-switch
                                                color="#48bb78" inset dense hide-details :ripple="false"
                                            v-model="contact_form.custom_fields[index].required"
                                            label="Required"
                                            class="mb-3"
                                        ></v-switch>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <br /><br />
                        </v-tab-item>
                    </v-tabs>
                </div>
            </div>

            <div class="w-full sm:w-2/3 px-6 bg-gray-100 p-6 -mt-7">

                <div class="w-full" style="height: 100%;">
                    <div class="p-4 bg-white" style="height: 100%;">
                        <v-tabs v-model="selected_view">
                            <v-tab >Form</v-tab>
                            <v-tab >Confirmation</v-tab>
                        </v-tabs>

                        <div class="w-full" style="border: solid 1px #CCCCCC; margin-top: 10px;">
                            <div :style="'width: 100%; position: relative; font-family: ' + contact_form.font_family.replace(' (Google)', '') + ';'">
                                <div class="rgcf-header" :style="'background-color: ' + contact_form.header_background_color + '; color: ' + contact_form.header_text_color + '; text-align: ' + contact_form.header_alignment.toLowerCase() + ';'">
                                    <div class="rgcf-business-name">
                                        {{ contact_form.company_name }}
                                    </div>

                                    <div class="rgcf-subtitle">
                                        {{ contact_form.header_subtitle }}
                                    </div>
                                </div>

                                <div class="rgcf-body" :style="'background-color: ' + contact_form.background_color + '; color: ' + contact_form.text_color">
                                    <div class="rgcf-logo" v-if="contact_form.logo">
                                        <img :src="contact_form.logo" alt="" style="height: 60px;">
                                    </div>
                                    

                                        <div class="rgcf-contact-form" :style="'max-width: 500px;'">
                                            <div class="shadow p-6" v-if="selected_view == 0">
                                                <h1 class="text-2xl mb-6 text-center font-semibold text-gray-700">{{ contact_form.form_title }}</h1>

                                                <div class="mb-6">
                                                    <p class="mb-1 text-xs mb-2"><strong>{{ contact_form.first_name_text }}</strong></p>
                                                    <input type="text" ref="first_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                                                </div>

                                                <div class="mb-6">
                                                    <p class="mb-1 text-xs mb-2"><strong>{{ contact_form.last_name_text }}</strong></p>
                                                    <input type="text" ref="last_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                                                </div>

                                                <div class="mb-6">
                                                    <p class="mb-1 text-xs mb-2"><strong>{{ contact_form.email_text }}</strong></p>
                                                    <input type="email" ref="email" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                                                </div>

                                                <div class="mb-6">
                                                    <p class="mb-1 text-xs mb-2"><strong>{{ contact_form.phone_number_text }}</strong></p>
                                                    <input type="text" ref="phone" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                                                </div>

                                                <div v-for="(item, index) in contact_form.custom_fields" :key="index">
                                                    <div v-show="item.visible" class="mb-3">
                                                        <p class="mb-1 text-xs mb-2"><strong>
                                                            {{ item.name }}
                                                        </strong></p>
                                                        <input v-model="item.value" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
                                                    </div>
                                                </div>

                                                <div class="">

                                                    <button class="w-full rounded text-white focus:outline-none py-3 px-4" :style="'background-color: ' + contact_form.button_background_color + '; color: ' + contact_form.button_text_color + ' !important;'">
                                                        {{ contact_form.add_contact_button }}
                                                    </button>

                                                </div>
                                            </div>

                                            <div class="shadow p-6" v-if="selected_view == 1">
                                                <div class="rgcf-confirmation-text">{{ contact_form.confirmation_text }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="rgcf-footer" :style="'background-color: ' + contact_form.footer_background_color + '; color: ' + contact_form.footer_text_color + '; text-align: ' + contact_form.footer_alignment.toLowerCase() + ';'">

                                    <div class="rgcf-google-compliant-link-wrapper text-center" v-if="selected_view == 2 && contact_form.google_compliant_link_enabled">
                                        <a :href="contact_form.google_compliant_link" target="google-leave-review-link">{{ contact_form.google_compliant_link_text }}</a>
                                    </div>

                                    <div class="rgcf-footer-text" v-html="contact_form.footer_text"></div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>

    </div>

    <!--<div>
      <button class="bg-gray-200 py-2 px-3 text-gray-600 hover:bg-gray-300 rounded" @click="$refs.dialogPlatform.show()"><font-awesome-icon icon="plus-circle" class="mr-1"></font-awesome-icon> Add platform</button>
    </div>-->
  </panel-navigation>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import urlParser from 'url-parse'
import PanelNavigation from '@/components/PanelNavigation.vue'
import ColorPicker from '@/components/common/ColorPicker.vue'

import { VueEditor } from "vue2-editor";

export default {
  metaInfo () {
    return {
      title: "Contact Form Builder | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    VueEditor,
    ColorPicker
  },
  data () {
    return {
        saved: false,
        file: null,

        edit_url: false,

        contact_form: {},

        selected_view: 0,

        widget: null,

        text: "",

	  contactFormLoaded: false,
	  font_family_values: [
		  "Open Sans (Google)",
		  "Source Sans Pro (Google)",
		  "Roboto (Google)",
		  "Lato (Google)",
		  "PT Sans (Google)",
		  "EB Garamond (Google)",
		  "Raleway (Google)",
		  "Ubuntu (Google)",
		  "Montserrat (Google)",
		  "Courgette (Google)",
		  "Arial",
		  "Helvetica",
		  "Sans-serif",
	  ],
	  align_values: [
		  "Left",
          "Center",
		  "Right"
	  ],

      customToolbar: [
            ["bold", "italic", "underline"],
            // [{ list: "ordered" }, { list: "bullet" }],
            ["image", "link"]
        ],

        savingForm: false
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me
    })
  },
  methods: {
      async onUpload() {
      if (this.file !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

        //   let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/review-funnels/save-logo`, {
        //     logo: response.data.url
        //   }, {
        //     headers: {
        //       'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        //     }
        //   })

          this.contact_form.logo = response.data.url

        }catch(e){
        }

        this.file = null
        this.uploading = false
      }
    },

    async onUploadSourceLogo() {
      if (this.file_source_logo !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file_source_logo)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.source_logo = response.data.url

        }catch(e){
        }

        this.file_source_logo = null
        this.uploading = false
      }
    },

    saveForm: async function() {
        this.savingForm = true;
		this.saved = true
		let self = this
		try {
			let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/contacts/save-form', {
				contact_form: self.contact_form
			} , {
			headers: {
					'Authorization': 'Bearer ' + this.$store.state.auth.jwt
				},
			})
			setTimeout(function() {

				self.saved = false
                self.savingForm = false
			}, 500)
		}catch(e){
		}
	},

    setCustomCSS() {
        // Apply custom CSS
        if (this.contact_form.custom_css) {
            if (document.getElementById("rgcf-custom-css-sheet") === null) {
                var styleSheet = document.createElement("style")
                styleSheet.type = "text/css"
                styleSheet.innerText = this.contact_form.custom_css.replace(/\n/g, " ")
                styleSheet.id = 'rgcf-custom-css-sheet';
                document.body.appendChild(styleSheet);
            }
            else {
                document.getElementById("rgcf-custom-css-sheet").innerText = this.contact_form.custom_css.replace(/\n/g, " ");
            }
        }
        else {
            if (document.getElementById("rgcf-custom-css-sheet") === null) {
                var styleSheet = document.createElement("style")
                styleSheet.type = "text/css"
                styleSheet.innerText = ""
                styleSheet.id = 'rgcf-custom-css-sheet';
                document.body.appendChild(styleSheet);
            }
            else {
                document.getElementById("rgcf-custom-css-sheet").innerText = '';
            }
        }
        // END Apply custom CSS
    },

    async removeLogo() {
    //    await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/review-funnels/remove-logo`, {}, {
    //     headers: {
    //       'Authorization': 'Bearer ' + this.$store.state.auth.jwt
    //     }
    //   })

      this.contact_form.logo = '';
    },
	getContactForm: async function() {
        let self = this;

        try {
          let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/contacts/get-form', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          },
          })
          
            this.contact_form = response.data.contact_form
        }catch(e){
            console.log(e);
        }

        this.contactFormLoaded = true;
    },

    addField() {
        this.selected_view = 2
        this.contact_form.custom_fields.push({
          'name': 'Field Name',
          'visible': true,
          'required': false,
          'value': ''
        });
    },
    removeField(index) {
        this.selected_view = 2
        let all_pages = this.contact_form.custom_fields;
        let new_fields = [];

        for (var i in all_pages) {
            if (i != index) {
              new_fields.push(all_pages[i]);
            }
        }

        this.contact_form.custom_fields = new_fields;
    }
  },
  created() {
    this.getContactForm()
  },
	watch: {
		'contact_form.custom_css': {
			handler() {
				this.setCustomCSS()
			}
		}
	}
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Open+Sans&family=Source+Sans+Pro&family=Roboto&family=Lato&family=PT+Sans&family=EB+Garamond&family=Raleway&family=Ubuntu&family=Montserrat&family=Courgette');
  
  a {
      text-decoration: none !important;
  }

  #editor1 {
      height: 120px;
  }

  .rgcf-header {
      width: 100%;
      min-height: 80px;
      padding: 20px;
  }

  .rgcf-footer {
      /* margin-top: 30px; */
      width: 100%;
      min-height: 60px;
      background-color: #f5f5f5;
      padding: 20px;
  }

  .rgcf-footer-text {
      font-size: 15px;
  }

  .rgcf-business-name {
      font-size: 20px;
  }

  .rgcf-subtitle {
      padding-top: 10px;
      font-size: 14px;
  }

  .rgcf-body {
      /* background-color: #f5f5f5; */
      width: 100%;
      max-width: 1200px;
      margin: auto;
      padding: 20px;
  }

  .rgcf-contact-form {
      width: 100%;
      max-width: 800px;
      margin: auto;
      padding-top: 40px;
      padding-bottom: 40px;
  }

  .rgcf-confirmation-text {
      padding: 30px;
      text-align: center;
  }

  .rgcf-body .rgcf-logo {
      padding-top: 20px;
      padding-bottom: 10px;
      text-align: center;
  }

  .rgcf-body .rgcf-logo img {
      display: initial;
  }
</style>