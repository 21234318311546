<template>

  <div>

    <div v-if="$agency" class="float-right ml-5 -mt-1">
      <div v-show="$agency.settings.dashboard_link" class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="openUrl($agency.settings.dashboard_link_url)" style="margin-top: -5px;"><i :class="$agency.settings.dashboard_link_icon + ' mr-2'"> </i> {{ $agency.settings.dashboard_link_text }}</div>
      
      <div class="border-1 bg-blue border-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="refreshReport()" style="margin-top: -5px;"><i class="fa fa-file-invoice mr-2"> </i> GENERATE REPORT</div>
    </div>

    <div v-if="listings" class="rg-top-filter float-right" style="margin-top: -10px;">
      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
          left
      >
          <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="date"
              append-icon="mdi-calendar-blank-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              style="background: #FFFFFF; border: 0px !important;"
              hide-details
              placholder="Choose Date"
          ></v-text-field>
          </template>
          <v-date-picker
          v-model="date"
          type="month"
          no-title
          scrollable
          @input="menu = false"
          >
          </v-date-picker>
      </v-menu>
  </div>

  <div v-if="listings" class="rg-top-filter float-right mr-5" style="margin-top: -10px; max-width: 300px;">
      <v-select
          v-model="selected_review_sites"
          :items="review_sites"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          style="background: #FFFFFF;"
          append-icon="mdi-chevron-down"
          hide-details
          small-chips
          >
          <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0 || index === 1">
              <span>{{ item.name }}</span>
              </v-chip>
              <span
              v-if="index === 2"
              class="grey--text text-caption"
              >
              (+{{ selected_review_sites.length - 2 }} more)
              </span>
          </template>
      </v-select>
  </div>
    
    <h1 class="text-lg font-semibold mb-3">Business Dashboard</h1>

    <hr class="border-b border-gray-100 mb-3">

    <div v-if="loading || !listings">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="article"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="!loading && listings" class="-mx-2">
      <div class="flex flex-row flex-wrap mb-3">
        <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3 mb-4" v-if="!chart_data.initial_loading">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div class="dashboard-box-title">Overall Ratings</div>

              <div class="stats-box-number large">{{ chart_data.overall_rating || 0 }}</div>
              
              <div class="stats-box-icon-bg" style="top: 60px;"></div>
              <div class="stats-box-icon" style="top: 60px;">
                <i class="mdi mdi-star"></i>
              </div>

              <div class="clearfix"></div>
              
              <v-rating
                class="mt-1 -ml-1"
                :value="(parseFloat(chart_data.overall_rating) || 0)"
                :color="'orange ligten-1'"
                dense
                half-increments
                readonly
                size="30"
              ></v-rating>
              
              <div class="text-sm pt-2 pb-2" style="color: #757575;">
                Based on

                <span class="mr-1">{{ chart_data.reviews_count || 0 }}</span>

                <span class="text-gray-600" v-show="chart_data.reviews_count == 1">Review</span>
                <span class="text-gray-600" v-show="chart_data.reviews_count != 1">Reviews</span>
              </div>

              <div class="w-full">
                <div style="padding-right: 10px; clear: both; height: 17px; margin-top: 5px; color: #757575; font-size: 13px;">
                  <div style="float: left; width: 40px;">
                    <i class="fa fa-star" style="color: #ff9800;"></i>&nbsp; 5
                  </div>
                  <div style="float: left; width: calc(100% - 60px); padding-top: 6px;">
                    <v-progress-linear
                      rounded
                      :value="(chart_data.reviews_count_five_star/chart_data.reviews_count) * 100"
                      color="#3EBB80"
                      background-color="grey lighten-4"
                      height="6"
                    ></v-progress-linear>
                  </div>
                  <div style="float: right;">
                    {{ chart_data.reviews_count_five_star }}
                  </div>
                </div>

                <div style="padding-right: 10px; clear: both; height: 17px; margin-top: 15px; color: #757575; font-size: 13px;">
                  <div style="float: left; width: 40px;">
                    <i class="fa fa-star" style="color: #ff9800;"></i>&nbsp; 4
                  </div>
                  <div style="float: left; width: calc(100% - 60px); padding-top: 6px;">
                    <v-progress-linear
                      rounded
                      :value="(chart_data.reviews_count_four_star/chart_data.reviews_count) * 100"
                      color="#3EBB80"
                      background-color="grey lighten-4"
                      height="6"
                    ></v-progress-linear>
                  </div>
                  <div style="float: right;">
                    {{ chart_data.reviews_count_four_star }}
                  </div>
                </div>

                <div style="padding-right: 10px; clear: both; height: 17px; margin-top: 15px; color: #757575; font-size: 13px;">
                  <div style="float: left; width: 40px;">
                    <i class="fa fa-star" style="color: #ff9800;"></i>&nbsp; 3
                  </div>
                  <div style="float: left; width: calc(100% - 60px); padding-top: 6px;">
                    <v-progress-linear
                      rounded
                      :value="(chart_data.reviews_count_three_star/chart_data.reviews_count) * 100"
                      color="#3EBB80"
                      background-color="grey lighten-4"
                      height="6"
                    ></v-progress-linear>
                  </div>
                  <div style="float: right;">
                    {{ chart_data.reviews_count_three_star }}
                  </div>
                </div>

                <div style="padding-right: 10px; clear: both; height: 17px; margin-top: 15px; color: #757575; font-size: 13px;">
                  <div style="float: left; width: 40px;">
                    <i class="fa fa-star" style="color: #ff9800;"></i>&nbsp; 2
                  </div>
                  <div style="float: left; width: calc(100% - 60px); padding-top: 6px;">
                    <v-progress-linear
                      rounded
                      :value="(chart_data.reviews_count_two_star/chart_data.reviews_count) * 100"
                      color="#3EBB80"
                      background-color="grey lighten-4"
                      height="6"
                    ></v-progress-linear>
                  </div>
                  <div style="float: right;">
                    {{ chart_data.reviews_count_two_star }}
                  </div>
                </div>

                <div class="mb-2" style="padding-right: 10px; clear: both; height: 17px; margin-top: 10px; color: #757575; font-size: 13px;">
                  <div style="float: left; width: 40px;">
                    <i class="fa fa-star" style="color: #ff9800;"></i>&nbsp; 1
                  </div>
                  <div style="float: left; width: calc(100% - 60px); padding-top: 6px;">
                    <v-progress-linear
                      rounded
                      :value="(chart_data.reviews_count_one_star/chart_data.reviews_count) * 100"
                      color="#3EBB80"
                      background-color="grey lighten-4"
                      height="6"
                    ></v-progress-linear>
                  </div>
                  <div style="float: right;">
                    {{ chart_data.reviews_count_one_star }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </div>

        <div class="dashboard-box stats-box w-full px-2 md:w-2/3 lg:w-2/3 mb-4" v-if="!chart_data.initial_loading">
          <v-card
            class="pa-2"
            tile
          >
            <v-container class="chart-container">
              <apexchart height="269" type="line" :options="options" :series="series"></apexchart>
            </v-container>
          </v-card>
        </div>

        <div class="dashboard-box stats-box px-2 mb-4" :class="calculateListingClass(listing.id)" v-for="listing in listings_displayed" :key="listing.id">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div class="float-left">
                <div class="stats-box-number">{{ ((listing.average_rating) ? (Math.floor(parseFloat(listing.average_rating) * 10) / 10) : 0) }} <i class="fa fa-star" style="color: #ff9800; font-size: 26px;"></i></div>

                <div class="text-sm pt-2 pb-2" style="color: #757575;">
                  Based on

                  <span class="mr-1">{{ listing.reviews_count || 0 }}</span>

                  <span class="text-gray-600" v-show="listing.reviews_count == 1">Review</span>
                  <span class="text-gray-600" v-show="listing.reviews_count != 1">Reviews</span>
                </div>
              </div>
              <div class="float-right pt-2">
                <img :src="getIconUrl(listing.url)" alt="" class="source-logo-dashboard mr-3"></img>
              </div>

              <div class="clearfix"></div>
            </div>
          </v-card>
        </div>
      </div>


      <v-skeleton-loader v-if="chart_data.initial_loading"
        type="date-picker"
      ></v-skeleton-loader>
    </div>

    <div class="flex flex-row flex-wrap mb-3 -mx-2">
      <div class="w-full pl-2 mb-4">
        <div class="dashboard-box stats-box">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div class="dashboard-box-title">
                Frequently Used Keywords
                
                <div class="text-sm mb-4 pt-2" style="font-weight: normal;">
                  Positive and negative words that frequently appeared in your reviews.
                </div>
              </div>

              <div v-if="(chart_data.reviews_count || 0) == 0" class="mb-4">
                <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                  You don't have any reviews. Please add your review platforms and wait until reviews are found by our system in order to populate this widget.
                </div>
              </div>

              <div class="mb-4 reviews-wrapper dashboard-box-content-border">
                <div class="mt-4">
                  <div v-if="me">
                    <div v-if="me.sentiment_data.status == 'fetching'" style="padding-top: 20px; font-size: 12px;">
                      We're currently analysing your data.. Please check back later <i class="fa fa-spin fa-spinner"></i>
                    </div>
                    <div v-else>
                      <div style="background: rgba(0, 0, 0, 0.02); padding: 20px; border-radius: 5px;">
                        <div><strong>Top Positive Mentions</strong></div>

                        <hr class="mt-4 mb-4" />

                        <div v-if="me.sentiment_data.data.positive_keywords.length > 0">
                          <div style="max-height: 150px; overflow: hidden;"
                            :style="((frequently_keywords_positive_more || me.sentiment_data.data.positive_keywords.length <= 9) ? 'max-height: none; overflow: auto;' : '')"
                          >
                            <div v-for="(item, index) in me.sentiment_data.data.positive_keywords" :key="'positive-keyword-' + index" style="float: left; width: 33%; padding-top: 15px; padding-bottom: 15px; font-size: 14px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" style="float: left; margin-right: 10px; margin-top: 4px;">
                                <circle cx="5" cy="5" r="5" fill="#3EBB80"/>
                              </svg>

                              <div style="float: left; width: 220px;">
                                {{ item.keyword || item.word }}
                              </div>

                              <div style="float: left; font-size: 12px; color: #757B89;">
                                {{ item.frequency }}
                              </div>

                              <div style="clear: both;"></div>
                            </div>

                            <div style="clear: both;"></div>
                          </div>

                          <div style="clear: both;"></div>

                          <div style="text-align: right; margin-top: -40px;" v-if="me.sentiment_data.data.positive_keywords.length > 0">
                            <a v-show="!frequently_keywords_positive_more && me.sentiment_data.data.positive_keywords.length > 0" href="javascript: void(0);" style="font-size: 14px;" @click="frequently_keywords_positive_more = true">View More</a>

                            <a v-show="frequently_keywords_positive_more" href="javascript: void(0);" style="font-size: 14px;" @click="frequently_keywords_positive_more = false">View Less</a>
                          </div>
                        </div>
                        <div v-else>
                          <div style="padding-top: 20px; padding-bottom: 20px; font-size: 12px;">No positive keywords found.</div>
                        </div>
                        
                      </div>

                      <div style="background: rgba(0, 0, 0, 0.02); padding: 20px; border-radius: 5px; margin-top: 20px;">
                        <div><strong>Top Negative Mentions</strong></div>

                        <hr class="mt-4 mb-4" />

                        <div v-if="me.sentiment_data.data.negative_keywords.length > 0">
                          <div style="max-height: 150px; overflow: hidden;"
                            :style="((frequently_keywords_negative_more || me.sentiment_data.data.negative_keywords.length <= 9) ? 'max-height: none; overflow: auto;' : '')"
                          >
                            <div v-for="(item, index) in me.sentiment_data.data.negative_keywords" :key="'negative-keyword-' + index" style="float: left; width: 33%; padding-top: 15px; padding-bottom: 15px; font-size: 14px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" style="float: left; margin-right: 10px; margin-top: 4px;">
                                <circle cx="5" cy="5" r="5" fill="#FF0000"/>
                              </svg>

                              <div style="float: left; width: 220px;">
                                {{ item.keyword || item.word }}
                              </div>

                              <div style="float: left; font-size: 12px; color: #757B89;">
                                {{ item.frequency }}
                              </div>

                              <div style="clear: both;"></div>
                            </div>

                            <div style="clear: both;"></div>
                          </div>

                          <div style="clear: both;"></div>

                          <div style="text-align: right; margin-top: -40px;" v-if="me.sentiment_data.data.negative_keywords.length > 0">
                            <a v-show="!frequently_keywords_negative_more && me.sentiment_data.data.negative_keywords.length > 0" href="javascript: void(0);" style="font-size: 14px;" @click="frequently_keywords_negative_more = true">View More</a>

                            <a v-show="frequently_keywords_negative_more" href="javascript: void(0);" style="font-size: 14px;" @click="frequently_keywords_negative_more = false">View Less</a>
                          </div>
                        </div>
                        <div v-else>
                          <div style="padding-top: 20px; padding-bottom: 20px; font-size: 12px;">No negative keywords found.</div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div v-else>
                      <v-skeleton-loader type="article, article, article"></v-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>

    <div class="flex flex-row flex-wrap mb-3 -mx-2">
      <div class="w-full pl-2 mb-4">
        <div class="dashboard-box stats-box">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div class="dashboard-box-title">Sentiment Analysis</div>

              <div class="mb-4 reviews-wrapper dashboard-box-content-border">

                <div v-if="(chart_data.reviews_count || 0) == 0" class="mt-4">
                  <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                    You don't have any reviews. Please add your review platforms and wait until reviews are found by our system in order to populate this widget.
                  </div>
                </div>

                <div v-if="me">
                  <div v-if="me.sentiment_data.status == 'fetching'" style="padding-top: 20px; font-size: 12px;">
                    We're currently analysing your data.. Please check back later <i class="fa fa-spin fa-spinner"></i>
                  </div>
                  <div v-else>
                    <div v-if="me.sentiment_data.data.overall_message" class="text-sm mb-4 pt-4" style="font-weight: normal;">
                      {{ me.sentiment_data.data.overall_message }}
                    </div>

                    <v-expansion-panels accordion flat dense>
                      <v-expansion-panel v-for="(item, index) in me.sentiment_data.data.category_keywords" :key="'sentiment-category-' + index">
                          <br />
                          <v-expansion-panel-header>
                            <div style="position: absolute; width: 250px; right: 90px;">
                              <v-progress-linear
                                rounded
                                :value="item.positive_percentage"
                                :color="'#65C999'"
                                background-color="rgba(101, 201, 153, 0.20)"
                                height="6"
                              ></v-progress-linear>
                            </div>

                            <div style="position: absolute; right: 50px;">
                              {{ item.positive_percentage }} %
                            </div>

                            {{ index.charAt(0).toUpperCase() + index.slice(1) }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div class="-mx-2" style="background: rgba(0, 0, 0, 0.02); padding: 20px; border-radius: 3px; margin-top: 30px;">
                                <div><strong style="font-size: 12px;">Positive Mentions</strong></div>

                                <div v-if="item.positive.length > 0">
                                  <div style="max-height: 150px; overflow: hidden;"
                                    :style="((frequently_keywords_categories[index].positive_more || item.positive.length <= 9) ? 'max-height: none; overflow: auto;' : '')"
                                  >
                                    <div v-for="(item, index) in item.positive" :key="'positive-keyword-' + index" style="float: left; width: 33%; padding-top: 15px; padding-bottom: 15px; font-size: 14px;">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" style="float: left; margin-right: 10px; margin-top: 4px;">
                                        <circle cx="5" cy="5" r="5" fill="#3EBB80"/>
                                      </svg>

                                      <div style="float: left; width: 220px;">
                                        {{ item.keyword || item.word }}
                                      </div>

                                      <div style="float: left; font-size: 12px; color: #757B89;">
                                        {{ item.frequency }}
                                      </div>

                                      <div style="clear: both;"></div>
                                    </div>

                                    <div style="clear: both;"></div>
                                  </div>

                                  <div style="clear: both;"></div>

                                  <div style="text-align: right; margin-top: -40px;" v-if="item.positive.length > 9">
                                    <a v-show="!frequently_keywords_categories[index].positive_more && item.positive.length > 9" href="javascript: void(0);" style="font-size: 14px;" @click="frequently_keywords_categories[index].positive_more = true">View more from this category</a>

                                    <a v-show="frequently_keywords_categories[index].positive_more" href="javascript: void(0);" style="font-size: 14px;" @click="frequently_keywords_categories[index].positive_more = false">View less from this category</a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div style="padding-top: 20px; padding-bottom: 20px; font-size: 12px;">No positive keywords found.</div>
                                </div>
                                
                              </div>

                              <div class="-mx-2" style="background: rgba(0, 0, 0, 0.02); padding: 20px; border-radius: 5px; margin-top: 30px;">
                                <div><strong style="font-size: 12px;">Negative Mentions</strong></div>

                                <div v-if="item.negative.length > 0">
                                  <div style="max-height: 150px; overflow: hidden;"
                                    :style="((frequently_keywords_categories[index].negative_more || item.negative.length <= 9) ? 'max-height: none; overflow: auto;' : '')"
                                  >
                                    <div v-for="(item, index) in item.negative" :key="'negative-keyword-' + index" style="float: left; width: 33%; padding-top: 15px; padding-bottom: 15px; font-size: 14px;">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" style="float: left; margin-right: 10px; margin-top: 4px;">
                                        <circle cx="5" cy="5" r="5" fill="#FF0000"/>
                                      </svg>

                                      <div style="float: left; width: 220px;">
                                        {{ item.keyword || item.word }}
                                      </div>

                                      <div style="float: left; font-size: 12px; color: #757B89;">
                                        {{ item.frequency }}
                                      </div>

                                      <div style="clear: both;"></div>
                                    </div>

                                    <div style="clear: both;"></div>
                                  </div>

                                  <div style="clear: both;"></div>

                                  <div style="text-align: right; margin-top: -40px;" v-if="item.negative.length > 9">
                                    <a v-show="!frequently_keywords_categories[index].negative_more && item.negative.length > 9" href="javascript: void(0);" style="font-size: 14px;" @click="frequently_keywords_categories[index].negative_more = true">View more from this category</a>

                                    <a v-show="frequently_keywords_categories[index].negative_more" href="javascript: void(0);" style="font-size: 14px;" @click="frequently_keywords_categories[index].negative_more = false">View less from this category</a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div style="padding-top: 20px; padding-bottom: 20px; font-size: 12px;">No negative keywords found.</div>
                                </div>
                                
                              </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                  </div>
                </div>
                <div v-else>
                    <v-skeleton-loader type="article, article, article"></v-skeleton-loader>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="flex flex-row flex-wrap mb-3 -mx-2">
      <div class="dashboard-box stats-box w-full px-2 mb-4 md:w-1/2 lg:w-1/2">
        <v-card
          class="pa-2"
          tile
        >
          <div class="p-3">
            <div class="dashboard-box-title">Reviews</div>

            <ReviewsTableDashboard :key="reviews_table_key" dashboard="true" :review_sites="selected_review_sites" :date="date" /> 
          </div>
        </v-card>
      </div>

      <div class="w-full pl-2 mb-4 md:w-1/2 lg:w-1/2">
        <div class="dashboard-box stats-box">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div class="dashboard-box-title">Private Feedback</div>

              <div class="mb-4 reviews-wrapper dashboard-box-content-border">

                <div v-if="fetchingFeedback">
                  <v-skeleton-loader type="article, article, article"></v-skeleton-loader>
                </div>

                <div v-else>
                
                  <div v-if="recentFiveFeedback.length == 0" class="pb-4">
                    <br /><br /><br /><br />

                    <div class="dashboard-box-iconsquare">
                      <div class="dashboard-box-icon-background"></div>
                      <div class="dashboard-box-icon">
                        <i class="mdi mdi-forum-outline"></i>
                      </div>
                    </div>

                    <div style="text-align: center;">
                      <div class="pt-2 pb-2">
                        <strong>No Feedback</strong>
                      </div>

                      <div class="text-sm mb-4 text-gray-600">
                        There is no feedback to be displayed.
                      </div>
                    </div>
                    
                    <br /><br /><br /><br />
                  </div>

                  <div v-else class="">
                    <div v-for="fb in recentFiveFeedback" :key="fb.id" class="text-sm pt-3 text-gray-800 bg-white rounded">
                      <!-- <div class="float-left mr-2"><svg width="20" height="20"  class="fill-current" viewBox="0 0 1941 1941" xmlns="http://www.w3.org/2000/svg"><path d="M906.667 1088v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136V768q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136zm896 0v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136V768q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136z"/></svg></div> -->
                      <div class="dashboard-activity-item">
                        <strong>
                          <strong v-if="fb.contact.first_name || fb.contact.last_name">{{ fb.contact.first_name }} {{ fb.contact.last_name }}:</strong>
                        </strong>
                        {{ fb.text }}
                        <span class="float-right not-mobile" style="font-size: 12px;">
                          {{ formatDate(fb.created_at) }}
                        </span>
                        <div class="mobile-only mt-1" style="font-size: 12px; font-style: italic;">
                          <i class="fa fa-calendar"></i> {{ formatDate(fb.created_at) }}
                        </div>
                        <br class="not-mobile" clear="all" />

                        <span v-if="fb.contact.email && fb.contact.phone" class="text-xs">
                          {{ fb.contact.email }} &nbsp; • &nbsp; {{ fb.contact.phone }}
                        </span>
                        <span v-else class="text-xs">
                          <span v-if="fb.contact.email">
                            {{ fb.contact.email }}
                          </span>
                          <span v-else>
                            {{ fb.contact.phone }}
                          </span>
                        </span>
                        <span v-if="fb.contact.custom_fields && fb.contact.custom_fields != '[]' && fb.contact.custom_fields != 'null'" class="text-xs">
                          -
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <a href="javascript: void(0)"
                                v-bind="attrs"
                                v-on="on"
                              >custom fields</a>
                            </template>
                            <div style="background: #FFFFFF; padding: 10px; font-size: 14px;" v-html="displayCustomFields(fb.contact.custom_fields)"></div>
                          </v-menu>
                        </span>
                      </div>
                      <div style="clear: both;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </div>

        <div class="dashboard-box stats-box mt-4">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div class="dashboard-box-title">Review Sites Distribution</div>

              <div class="mb-4 reviews-wrapper dashboard-box-content-border">

                <div v-if="loading">
                  <v-skeleton-loader type="article, article, article"></v-skeleton-loader>
                </div>
                <div v-else>
                  <div v-if="listings">
                    <div v-for="listing in listings_distribution" :key="'distribution-' + listing.id">
                      <div style="padding-top: 20px; padding-bottom: 20px;">
                        <div style="float: left; width: 34px;">
                          <img :src="getIconUrl(listing.url)" alt="" class="source-logo-dashboard mr-3" style="width: 34px; height: 34px;"></img>
                        </div>

                        <div style="float: left; width: 50px; padding-left: 15px; padding-top: 7px; font-size: 12px;">
                          {{ ((listing.average_rating) ? (Math.floor(parseFloat(listing.average_rating) * 10) / 10) : 0) }} <i class="fa fa-star" style="color: #ff9800; font-size: 14px;"></i>
                        </div>

                        <div style="float: left; width: calc(100% - 190px); padding-left: 15px; padding-top: 13px;">
                          <v-progress-linear
                            rounded
                            :value="listing.reviews_count_percentage"
                            :color="listing.reviews_count_color"
                            background-color="grey lighten-4"
                            height="6"
                          ></v-progress-linear>
                        </div>

                        <div style="float: left; width: 90px; font-size: 12px; padding-left: 15px; padding-top: 7px;">
                          {{ listing.reviews_count }} ({{ listing.reviews_count_percentage }}%)
                        </div>

                        <div style="clear: both;"></div>

                        <hr class="border-b border-gray-100 mt-3 -mb-2">
                      </div>
                    </div>
                  </div>
                  <div v-else class="pb-4">
                    <br /><br /><br /><br />

                    <div class="dashboard-box-iconsquare">
                      <div class="dashboard-box-icon-background"></div>
                      <div class="dashboard-box-icon">
                        <i class="mdi mdi-star-outline"></i>
                      </div>
                    </div>

                    <div style="text-align: center;">
                      <div class="pt-2 pb-2">
                        <strong>No Review Sites</strong>
                      </div>

                      <div class="text-sm mb-4 text-gray-600">
                        You haven't added any review sites yet.
                      </div>
                    </div>
                    
                    <br /><br /><br /><br />
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import ReviewsTableDashboard from '@/components/ReviewsTableDashboard.vue'
import urlParser from 'url-parse'
import reviewSitesNames from '@/config/review_sites/names_all';
import reviewSitesNamesReports from '@/config/review_sites/names_all';
import reviewSitesColors from '@/config/review_sites/colors';
import moment from 'moment'

export default {
  components: {
    ReviewsTableDashboard
  },
  data () {
    return {
      loading: false,
      datacollection: {},
      date: "",
      review_sites: [],
      selected_review_sites: [],
      listings_displayed: [],
      listings_distribution: [],
      reviews_table_key: 1,
      menu: false,
      myStyles: {
        height: '220px',
        width: '100%',
        position: 'relative',
      },
      options: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          },
        },
        stroke: {
          curve: 'smooth',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left'
        },
        xaxis: {
          categories: [],
          tickAmount: 10,
          labels: {
                rotate: 0
            }
        },
        colors: [],
        grid: {
          show: false,      // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: false  //or just here to disable only x axis grids
            }
          },  
          yaxis: {
            lines: { 
              show: false  //or just here to disable only y axis
            }
          },
        }
      },
      series: [],
      reviewSitesNames: reviewSitesNames,
      loading: true,
      listingsInterval: null,

      frequently_keywords_positive_more: false,
      frequently_keywords_negative_more: false,

      frequently_keywords_categories: {
        products: {
          positive_more: false,
          negative_more: false
        },
        services: {
          positive_more: false,
          negative_more: false
        },
        location: {
          positive_more: false,
          negative_more: false
        },
        value: {
          positive_more: false,
          negative_more: false
        },
        customer: {
          positive_more: false,
          negative_more: false
        },
      }
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me,
      listings: state => state.listings.listings,
      chart_data: state => state.listings.chart_data,
      feedback: state => state.feedback.feedback,
      fetchingFeedback: state => state.feedback.fetchingFeedback
    }),
    recentFiveFeedback() {
      return this.feedback.slice(0,5)
    },
    countPendingInvites() {
      return this.contacts.filter(value => {
        return (!value.invitedEmail && value.requestedInviteEmail ) || (!value.invitedPhone && value.requestedInvitePhone )
      }).length
    },
    countInvites() {
      return this.contacts.filter(value => {
        return value.invitedEmail || value.invitedPhone
      }).length
    },
    chartStyles () {
      return {
        //height: '300px',
        position: 'relative'
      }
    }
  },
  methods: {
    getHostname(url) {
      return urlParser(url).hostname.replace("www.", "")
    },
    getPathname(url) {
      return urlParser(url).pathname
    },
    getIconUrl(url) {
      return require('@/assets/images/review-services/www.' + this.getHostname(url).replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com").replace("tripadvisor.co.uk", "tripadvisor.com").replace("opentable.co.uk", "opentable.com").replace("airbnb.co.uk", "airbnb.com").replace("yellowpages.ca", "yellowpages.com") + ".png");
    },
    calculateListingClass(key) {
      let thisIndex = this.getItemIndex(key);

      switch (true) {
        case (this.selected_review_sites.length == 1):
          return 'w-full';
        break;

        case (this.selected_review_sites.length == 2):
          return 'md:w-1/2 lg:w-1/2'
        break;

        case (this.selected_review_sites.length == 3):
          return 'md:w-1/3 lg:w-1/3'
        break;

        case (this.selected_review_sites.length == 4):
          return 'md:w-1/2 lg:w-1/2'
        break;

        case (this.selected_review_sites.length == 5):
          return (thisIndex < 2) ? 'md:w-1/2 lg:w-1/2' : 'md:w-1/3 lg:w-1/3';
        break;

        case (this.selected_review_sites.length == 7):
          return (thisIndex < 4) ? 'md:w-1/2 lg:w-1/2' : 'md:w-1/3 lg:w-1/3';
        break;

        case (this.selected_review_sites.length == 9):
          return (thisIndex < 2) ? 'md:w-1/2 lg:w-1/2' : ((thisIndex < 5) ? 'md:w-1/3 lg:w-1/3' : 'md:w-1/4 lg:w-1/4');
        break;

        case (this.selected_review_sites.length == 8):
          return (thisIndex < 2) ? 'md:w-1/2 lg:w-1/2' : 'md:w-1/3 lg:w-1/3';
        break;

        case (this.listings.length%3 == 0):
          return 'md:w-1/3 lg:w-1/3'
        break;

        // case (this.listings.length%2 == 0):
        //   return 'md:w-1/2 lg:w-1/2'
        // break;
      }

      return 'md:w-1/3 lg:w-1/3'
    },
    getItemIndex(key) {
      let index = 0;

      for (var i in this.listings) {
        if (key == this.listings[i].id)
          return index;

        index++;
      }

      return index;
    },
    formatDate: function(date) {
      return moment(String(date)).format(this.me.settings.date_format)
    },
    async refreshReport() {
        var that = this
        that.loading = true;

        await that.$store.dispatch('listings/fetch_dashboard_chart_data', {
            review_sites: that.selected_review_sites,
            date: that.date
        })

        await that.$store.dispatch('feedback/fetch', {
            review_sites: that.selected_review_sites,
            date: that.date
        })

        await that.$store.dispatch('listings/fetch', { dashboard: true, date: that.date })

        that.listings_displayed = [];
        for (var i in that.listings) {
            if (that.selected_review_sites.includes(that.listings[i].id))
                that.listings_displayed.push(that.listings[i]);
        }

        that.buildListingsDistribution();

        that.reviews_table_key++
        that.loading = false;
    },
    displayCustomFields(custom_fields) {
      let html = '';

      let parsed_fields = JSON.parse(custom_fields);

      for (var i in parsed_fields) {
        html += '<strong>' + parsed_fields[i].name + "</strong>: " + parsed_fields[i].value + "<br />";
      }

      return html;
    },
    openUrl(url) {
      window.open(url);
    },

    buildListingsDistribution() {
      let that = this;

      let total_reviews = 0;
      for (var i in that.listings) {
        total_reviews += that.listings[i].reviews_count;
      }

      let listings_distribution = [];
      for (var i in that.listings) {
          if (that.selected_review_sites.includes(that.listings[i].id)) {
            let this_listing = that.listings[i];

            let reviews_count_percentage = (this_listing.reviews_count/total_reviews) * 100;

            this_listing.reviews_count_percentage = reviews_count_percentage.toFixed(2);

            if (this_listing.reviews_count_percentage < 35)
              this_listing.reviews_count_color = "#FF0000";
            else
            this_listing.reviews_count_color = "#3EBB80";

            listings_distribution.push(this_listing);
          }
      }

      that.listings_distribution = listings_distribution;
    }
  },
  created() {
    let self = this;
    // this.$store.dispatch('contacts/fetch')
    this.$store.state.feedback.fetchingFeedback = true
    this.$store.dispatch('feedback/fetch', {
      review_sites: self.selected_review_sites,
      date: self.date
    })
    this.$store.dispatch('listings/fetch', { dashboard: true })

    let hasPending = false;

    for (var i in this.listings) {
      if (this.listings[i].crawl_status == "pending" || !this.listings[i].crawl_status)
        hasPending = true;
    }

    var that = this;

    if (hasPending) {
      that.listingsInterval = setInterval(function() {
        that.$store.dispatch('listings/fetch', { dashboard: true })
      }, 5000);
    }

    setTimeout(function() {
      console.log('self.listings', self.listings)
        for (var i in self.listings) {
            let siteName = reviewSitesNamesReports["www." + self.getHostname(self.listings[i].url).replace(".ca", ".com")]

            if (typeof(siteName) == "undefined") siteName = "Direct";

            self.review_sites.push({
                id: self.listings[i].id,
                name: siteName
            });

            self.selected_review_sites.push(self.listings[i].id);
        }

        console.log('self.selected_review_sites', self.selected_review_sites)

        that.loading = false;
    }, 2000);
  },
  mounted () {
    var that = this
    this.$store.state.listings.chart_data.initial_loading = true
    this.$store.state.listings.listings = false
    that.$store.dispatch('listings/fetch_dashboard_chart_data', {
      review_sites: null,
      date: null
    })

    setTimeout(function() {
        that.$store.dispatch('listings/fetch_dashboard_chart_data', {
            review_sites: that.selected_review_sites,
            date: that.date
        })

        that.listings_displayed = [];
        for (var i in that.listings) {
            if (that.selected_review_sites.includes(that.listings[i].id))
                that.listings_displayed.push(that.listings[i]);
        }

        that.buildListingsDistribution();
    }, 2000);
  },
  beforeDestroy () {
     clearInterval(this.listingsInterval)
  },
  watch: {
    chart_data: {
      handler () {

        this.options.xaxis.categories = this.chart_data.chart_data.labels;
        this.series = [];

        for (var url in this.chart_data.chart_data.datasets) {
          let thisHostName = this.getHostname(url);
          let thisName = this.reviewSitesNames["www." + thisHostName.replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com").replace("tripadvisor.co.uk", "tripadvisor.com").replace("opentable.co.uk", "opentable.com").replace("airbnb.co.uk", "airbnb.com").replace("yellowpages.ca", "yellowpages.com")];

          if (!thisName)
            thisName = "Direct";

          this.series.push({
            name: thisName,
            data:this.chart_data.chart_data.datasets[url]
          });

          this.options.colors.push(reviewSitesColors["www." + thisHostName.replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com").replace("tripadvisor.co.uk", "tripadvisor.com").replace("opentable.co.uk", "opentable.com").replace("airbnb.co.uk", "airbnb.com").replace("yellowpages.ca", "yellowpages.com")]);
        }
      }
    }
  }
}
</script>

<style scoped>


  .source-logo-dashboard {
    float: left;
    width: 50px;
    height: 50px;
    margin-right: 5px;
    opacity: 1;
  }
</style>