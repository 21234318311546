<template>

    <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/2 lg:w-3/4 bg-white rounded-lg mx-6" style="max-width: 500px;">
  
        <div class="rg-modal-header">
          <span v-if="id">Edit Folder</span>
          <span v-if="!id">Add a Folder</span>
  
          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
        </div>
  
        <div class="p-6">
  
          <div class="flex flex-row flex-wrap">
            <div class="w-full sm:w-full">
  
              <div class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                  Title
                </strong></p>
                <input name="title" ref="title" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
              </div>
  
              <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                {{success}}
              </div>
  
              <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                <div v-html="error"></div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="bg-gray-100 rounded-b-lg px-6 py-4">
  
          <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': saving}" @click="save">
              SAVE
          </button>
  
          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
              CANCEL
          </button>
  
          <div class="clearfix"></div>
  
        </div>
  
      </div>
    </div>
  
  </template>
  
  <script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  
  export default {
    data: function() {
      return {
        showModal: false,
        saving: false,
        success: null,
        error: null,
        id: null
      }
    },
    methods: {
      show() {
        this.showModal = true
      },
      hide() {
        this.showModal = false
        this.saving = false
      },
      async save() {
        this.saving = true
        this.error = null
        try {
  
          let formData = new FormData();
          // formData.append("name", this.name);
          if (this.id)
            formData.append("id", this.id);
  
          formData.append("title", this.$refs.title.value);
  
          await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/members-area/add-folder`, formData, {
              headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
                'Content-Type': 'multipart/form-data'
              }
          })
  
          this.hide()
  
          this.$parent.$parent.getFolders();
          this.error = null
  
        } catch (e) {
            console.log(e);
          this.error = e.response.data.message
        }
        this.saving = false
      },
  
      addFolder() {
        let self = this;
        this.id = null;
        setTimeout(function() {
          try {
            this.$refs.title.value = "";
          }
          catch (e) {}
        }, 10);
      },
  
      editFolder(item) {
        let self = this;
        this.id = item.id;

        setTimeout(function() {
          self.$refs.title.value = item.title;
        }, 100);
      }
    },
    created() {
  
    }
  }
  </script>
  