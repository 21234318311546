<template>

  <div>

    <dialog-customize-themes ref="customizeThemes"></dialog-customize-themes>
    <dialog-matching-reviews ref="dialogMatchingReviews"></dialog-matching-reviews>
    <dialog-posting-log ref="dialogPostingLog"></dialog-posting-log>
    <dialog-posting-queue ref="dialogPostingQueue"></dialog-posting-queue>
    
    <panel-navigation>

      <div class="float-right ml-5">
        <div v-if="$route.params.review_id" class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" :class="{'spinner': saving}" @click="publishSocialPost"><i class="fa fa-check mr-2"> </i> PUBLISH</div>
        <div v-else class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" :class="{'spinner': saving}" @click="save"><i class="fa fa-check mr-2"> </i> SAVE SETTINGS</div>
      </div>

      <h1 class="text-lg font-semibold mb-3">Social Posting</h1>

      <hr class="border-b border-gray-100 mb-3" />

      <div v-if="me" class="flex flex-wrap">
        <div class="w-full md:w-1/2 sm:pr-10 mb-6">
          <div class="dashboard-box">
            <v-card
              class="pa-2"
              tile
            >
              <div class="p-3 pt-1">
                <v-tabs>
                  <v-tab>Accounts</v-tab>
                  <v-tab>Posting</v-tab>
                  <v-tab  v-if="me && !$route.params.review_id">Sources</v-tab>
                  <v-tab>Content</v-tab>
                  <v-tab>Themes</v-tab>

                  <v-tab-item>
                    <div class="pt-4">
                      <p class="mb-1 text-xs mb-4"><strong>
                        <span v-if="!$route.params.review_id">Connect your accounts to share on social media automatically.</span>
                        <span v-if="$route.params.review_id">Select which accounts you would like.</span>
                      </strong></p>

                      <div @click="AuthProvider('facebook')" class="auth-provider float-left mr-4 mb-4 cursor-pointer" style="position: relative;"
                        :class="((me.facebook) ? 'connected' : '')"
                      >
                        <div class="float-left">
                          <img src="@/assets/images/review-services/www.facebook.com.png" alt="" style="height: 36px;" v-bind:style="{ opacity: ((me && !me.facebook) ? 0.5 : 1) }">
                        </div>
                        <!-- <div class="bg-gray-300 text-gray-600 text-sm text-center py-1 rounded-b-sm">facebook</div> -->

                        <div v-if="me && me.facebook" class="float-left pl-1 text-center" style="font-size: 12px; padding-top: 6px;">
                          <v-checkbox
                            v-model="me.social_posting_accounts"
                            :label="me.facebook.name"
                            value="facebook"
                            hide-details
                             :ripple="false"
                          ></v-checkbox>

                          <button v-if="!$route.params.review_id" class="bg-red-500 text-white inline-block rounded px-2 cursor-pointer ml-1" style="padding-top: 2px; position: absolute; top: 17px; right: 15px;" @click="removeSocialAccount('facebook')"><i class="fa fa-times text-red"></i></button>
                        </div>

                        <div v-if="me && !me.facebook" class="text-right" style="padding-top: 6px; padding-right: 2px;">
                          <button class="bg-blue-700 hover:bg-blue-600 text-white inline-block rounded px-2 cursor-pointer" style="padding-top: 2px; font-size: 12px;">Connect</button>
                        </div>

                      </div>

                      <div v-show="false" @click="AuthProvider('instagram')" class="auth-provider float-left mr-4 mb-4 cursor-pointer" style="position: relative;"
                          :class="((me.instagram) ? 'connected' : '')"
                        >
                        <div class="float-left">
                          <img src="@/assets/images/review-services/www.instagram.com.png" alt="" style="height: 36px;" v-bind:style="{ opacity: ((me && !me.instagram) ? 0.5 : 1) }">
                        </div>
                        <!-- <div class="bg-gray-300 text-gray-600 text-sm text-center py-1 rounded-b-sm">instagram</div> -->

                        <div v-if="me && me.instagram" class="float-left pl-1 text-center" style="font-size: 12px; padding-top: 6px;">
                          <v-checkbox
                            v-model="me.social_posting_accounts"
                            :label="me.instagram.name"
                            value="instagram"
                            hide-details
                             :ripple="false"
                          ></v-checkbox>

                          <button v-if="!$route.params.review_id" class="bg-red-500 text-white inline-block rounded px-2 cursor-pointer ml-1" style="padding-top: 2px; position: absolute; top: 17px; right: 15px;" @click="removeSocialAccount('instagram')"><i class="fa fa-times text-red"></i></button>
                        </div>

                        <div v-if="me && !me.instagram" class="text-right" style="padding-top: 6px; padding-right: 2px;">
                          <button class="bg-indigo-700 hover:bg-indigo-600 text-white inline-block rounded px-2 cursor-pointer" style="padding-top: 2px; font-size: 12px;">Connect</button>
                        </div>

                      </div>

                      <div @click="AuthProvider('google')" class="auth-provider float-left mr-4 mb-4 cursor-pointer" style="position: relative;"
                        :class="((me.google) ? 'connected' : '')"
                      >
                        <div class="float-left">
                          <img src="@/assets/images/review-services/www.google.com.png" alt="" style="height: 36px;" v-bind:style="{ opacity: ((me && !me.google) ? 0.5 : 1) }">
                        </div>
                        <!-- <div class="bg-gray-300 text-gray-600 text-sm text-center py-1 rounded-b-sm">google</div> -->

                        <div v-if="me && me.google" class="float-left pl-1 text-center" style="font-size: 12px; padding-top: 6px;">
                          <v-checkbox
                            v-model="me.social_posting_accounts"
                            :label="me.google.name"
                            value="google"
                            hide-details
                             :ripple="false"
                          ></v-checkbox>

                          <button v-if="!$route.params.review_id" class="bg-red-500 text-white inline-block rounded px-2 cursor-pointer ml-1" style="padding-top: 2px; position: absolute; top: 17px; right: 15px;" @click="removeSocialAccount('google')"><i class="fa fa-times text-red"></i></button>
                        </div>

                        <div v-if="me && !me.google" class="text-right" style="padding-top: 6px; padding-right: 2px;">
                          <button class="bg-red-500 hover:bg-red-400 text-white inline-block rounded px-2 cursor-pointer" style="padding-top: 2px; font-size: 12px;">Connect</button>
                        </div>

                      </div>

                      <div @click="AuthProvider('twitter')" class="auth-provider float-left mr-4 mb-4 cursor-pointer" style="position: relative;"
                        :class="((me.twitter) ? 'connected' : '')"
                      >
                        <div class="float-left">
                          <img src="@/assets/images/review-services/www.twitter.com.png" alt="" class="" style="height: 36px;" v-bind:style="{ opacity: ((me && !me.twitter) ? 0.5 : 1) }">
                        </div>
                        
                        <!-- <div class="bg-gray-300 text-gray-600 text-sm text-center py-1 rounded-b-sm">Twitter</div> -->

                        <div v-if="me && me.twitter" class="float-left pl-1 text-center" style="font-size: 12px; padding-top: 6px;">
                          <v-checkbox
                            v-model="me.social_posting_accounts"
                            :label="me.twitter.name"
                            value="twitter"
                            hide-details
                             :ripple="false"
                          ></v-checkbox>
                          
                          <button v-if="!$route.params.review_id" class="bg-red-500 text-white inline-block rounded px-2 cursor-pointer ml-1" style="padding-top: 2px; position: absolute; top: 17px; right: 15px;" @click="removeSocialAccount('twitter')"><i class="fa fa-times text-red"></i></button>
                        </div>

                        <div v-if="me && !me.twitter" class="text-right" style="padding-top: 6px; padding-right: 2px;">
                          <button class="bg-blue-400 hover:bg-blue-300 text-white inline-block rounded px-2 cursor-pointer" style="padding-top: 2px; font-size: 12px;">Connect</button>
                        </div>

                      </div>

                      <br clear="all" /><br clear="all" />

                      <template v-if="me">
                        <v-row class="-mt-3">
                          <v-col v-if="me.facebook" cols="12" md="12" sm="12" xs="12">
                            <p class="text-sm mb-2 text-gray-700">Select Facebook Page:</p>

                            <div>
                              <i class="fa fa-spin fa-spinner" v-if="!facebook_pages_loaded"></i>

                              <v-select v-if="facebook_pages_loaded"
                                  v-model="me.selected_facebook_page"
                                  outlined
                                  dense
                                  hide-details
                                  :items="facebook_pages"
                                  item-value='id'
                                  item-text='name'
                              ></v-select>
                            </div>
                          </v-col>

                          <v-col v-if="me.google" cols="12" md="12" sm="12" xs="12">
                            <p class="mb-1 text-xs mb-4"><strong>Select GMB Listing:</strong></p>

                            <div>
                              <i class="fa fa-spin fa-spinner" v-if="!gmb_listings_loaded"></i>

                              <v-select v-if="gmb_listings_loaded"
                                  v-model="me.selected_gmb"
                                  outlined
                                  dense
                                  hide-details
                                  :items="gmb_listings"
                                  item-value='id'
                                  item-text='name'
                              ></v-select>
                            </div>
                          </v-col>

                          <v-col v-if="me.instagram" cols="12" md="12" sm="12" xs="12">
                            <p class="mb-1 text-xs mb-4"><strong>Select Instagram Business Account:</strong></p>

                            <div>
                              <i class="fa fa-spin fa-spinner" v-if="!instagram_profiles_loaded"></i>

                              <v-select v-if="instagram_profiles_loaded"
                                  v-model="me.selected_insta"
                                  outlined
                                  dense
                                  hide-details
                                  :items="instagram_profiles"
                                  item-value='id'
                                  item-text='name'
                              ></v-select>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="pt-4">
                      <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="$refs.dialogPostingLog.show()" style="margin-top: -5px;"><i class="fa fa-file-alt mr-2"> </i> VIEW LOG</div>

                      <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right mr-3" @click="$refs.dialogPostingQueue.show()" style="margin-top: -5px;"><i class="fa fa-sync mr-2"> </i> VIEW QUEUE</div>

                      <div class="flex flex-wrap">
                        <div class="w-full">
                          <p class="mb-1 text-xs mb-4"><strong>Days of week</strong></p>

                          <div style="display: inline-block; padding-right: 10px;">
                            <v-checkbox
                              v-model="me.settings.social_posting_day_of_week"
                              value="Monday"
                              label="Monday"
                              :ripple="false"
                            >
                            </v-checkbox>
                          </div>

                          <div style="display: inline-block; padding-right: 10px;">
                            <v-checkbox
                              v-model="me.settings.social_posting_day_of_week"
                              value="Tuesday"
                              label="Tuesday"
                              :ripple="false"
                            >
                            </v-checkbox>
                          </div>

                          <div style="display: inline-block; padding-right: 10px;">
                            <v-checkbox
                              v-model="me.settings.social_posting_day_of_week"
                              value="Wednesday"
                              label="Wednesday"
                              :ripple="false"
                            >
                            </v-checkbox>
                          </div>

                          <div style="display: inline-block; padding-right: 10px;">
                            <v-checkbox
                              v-model="me.settings.social_posting_day_of_week"
                              value="Thursday"
                              label="Thursday"
                              :ripple="false"
                            >
                            </v-checkbox>
                          </div>

                          <div style="display: inline-block; padding-right: 10px;">
                            <v-checkbox
                              v-model="me.settings.social_posting_day_of_week"
                              value="Friday"
                              label="Friday"
                              :ripple="false"
                            >
                            </v-checkbox>
                          </div>

                          <div style="display: inline-block; padding-right: 10px;">
                            <v-checkbox
                              v-model="me.settings.social_posting_day_of_week"
                              value="Saturday"
                              label="Saturday"
                              :ripple="false"
                            >
                            </v-checkbox>
                          </div>

                          <div style="display: inline-block; padding-right: 10px;">
                            <v-checkbox
                              v-model="me.settings.social_posting_day_of_week"
                              value="Sunday"
                              label="Sunday"
                              :ripple="false"
                            >
                            </v-checkbox>
                          </div>
                        </div>
                      </div>

                      <div v-show="false" class="flex flex-wrap">
                        <div class="w-full">
                          <p class="mb-1 text-xs mb-4"><strong>Repeat Every</strong></p>

                          <v-text-field
                            v-model="me.social_posting_frequency"
                            suffix="days"
                            type="number"
                            hide-details
                            outlined
                            dense
                            min="1"
                            class="mb-4"
                          ></v-text-field>
                        </div>
                      </div>

                      <div class="flex flex-wrap">
                        <div class="w-full md:w-1/2 pr-3">
                          <p class="mb-1 text-xs mb-4"><strong>Time of day</strong></p>

                          <select v-model="me.settings.social_posting_time_of_day" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                              <optgroup label="Any">
                                <option value="any">As soon as they are queued</option>
                              </optgroup>
                              <optgroup label="Randomize">
                                <option value="randomize">Spread throughout the day</option>
                              </optgroup>
                              <optgroup label="Specific time of day">
                                <option value="0">12 AM</option>
                                <option value="1">1 AM</option>
                                <option value="2">2 AM</option>
                                <option value="3">3 AM</option>
                                <option value="4">4 AM</option>
                                <option value="5">5 AM</option>
                                <option value="6">6 AM</option>
                                <option value="7">7 AM</option>
                                <option value="8">8 AM</option>
                                <option value="9">9 AM</option>
                                <option value="10">10 AM</option>
                                <option value="11">11 AM</option>
                                <option value="12">12 PM</option>
                                <option value="13">1 PM</option>
                                <option value="14">2 PM</option>
                                <option value="15">3 PM</option>
                                <option value="16">4 PM</option>
                                <option value="17">5 PM</option>
                                <option value="18">6 PM</option>
                                <option value="19">7 PM</option>
                                <option value="20">8 PM</option>
                                <option value="21">9 PM</option>
                                <option value="22">10 PM</option>
                                <option value="23">11 PM</option>
                              </optgroup>
                          </select>
                        </div>
                        <div class="w-full md:w-1/2 pl-3">
                          <p class="mb-1 text-xs mb-4"><strong>Timezone</strong></p>

                          <v-autocomplete
                              class="-mt-1"
                              :items="timezones"
                              v-model="me.settings.social_posting_timezone"
                              label="Timezone"
                              outlined
                              dense
                          ></v-autocomplete>
                        </div>
                      </div>

                      <div class="flex flex-wrap">
                        <div class="w-full md:w-1/2 pr-3">
                          <p class="mb-1 text-xs mb-4"><strong>Limit per day</strong></p>

                          <v-text-field
                            placeholder="Leave empty for unlimited"
                            v-model="me.settings.social_posting_daily_limit"
                            hide-details
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                        
                        <div class="w-full md:w-1/2 pl-3">
                          <p class="mb-1 text-xs mb-4"><strong>Long Reviews</strong></p>

                          <v-select
                            v-model="me.social_posting_long_reviews"
                            outlined
                            dense
                            hide-details
                            :items="long_reviews_options"
                            item-value='id'
                            item-text='text'
                          ></v-select>
                        </div>
                      </div>

                      <br clear="all" />

                      <v-switch color="#48bb78" inset dense hide-details :ripple="false" v-if="me"
                        v-model="me.social_posting_enabled"
                        label="Enable Auto Posting"
                        class="ml-1"
                      ></v-switch>

                      <br clear="all" />

                      <p class="mb-1 text-xs mb-4">You can still add reviews manually to the queue, if auto posting is disabled.</p>

                      <div v-show="me.social_posting_enabled" class="flex flex-wrap">
                        <div class="w-full md:w-1/2 pr-3">
                          <p class="mb-1 text-xs mb-4"><strong>Reviews at least</strong></p>

                          <v-select
                            v-model="me.social_posting_rating"
                            outlined
                            dense
                            hide-details
                            :items="rating_value_options"
                            item-value='id'
                            item-text='text'
                          ></v-select>
                        </div>
                      </div>

                      <br clear="all" />
                    </div>
                  </v-tab-item>
                  <v-tab-item v-if="me && !$route.params.review_id">
                    <div class="pt-4">
                      <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="$refs.dialogMatchingReviews.show()" style="margin-top: -5px;">
                        <i class="fa fa-star mr-2"></i> &nbsp; &nbsp;
                        <i v-if="matching_reviews_loading" class="fa fa-spinner fa-spin mr-2"></i>
                        <span v-if="!matching_reviews_loading">{{ matching_reviews_count }} Found</span>
                      </div>

                      <p class="mb-1 text-xs mb-4"><strong>Select review platforms to post from:</strong></p>

                      <div class="clearfix"></div>

                      <div v-if="listings.length > 0" class="mb-6 mt-3">
                        <div v-for="reviewplatform in listings" :key="reviewplatform.id" class="flex flex-row items-center justify-between rounded w-full mb-2" style="background: rgba(25, 35, 58, 0.02); border: 1px solid #2477F6; border-radius: 5px;">

                          <div class="flex flex-row items-center truncate">
                            <div class="w-6 flex-shrink-0">
                              <img :src="getIconUrl(reviewplatform.url)" alt="" class="rounded-l border border-gray-100 ml-2">
                            </div>
                            <div class="truncate p-3 text-gray-400 text-sm">
                              <div class="float-left">
                                <v-checkbox
                                  :value="reviewplatform.id"
                                  v-model="me.social_posting_review_sites"
                                  hide-details
                                   :ripple="false"
                                >
                                </v-checkbox>
                              </div>

                              <span class="text-gray-600 text-sm">{{ getHostname(reviewplatform.url) }}</span>{{ getPathname(reviewplatform.url) }}</div>
                          </div>

                        </div>

                        <div v-if="listings.length == 0">
                          You don't have any review platforms. <a href="#">Click here</a> to add review platforms.
                        </div>
                      </div>

                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="pt-4">
                      <p class="mb-1 text-xs mb-4"><strong>Enter your default text to post along with the social review image</strong></p>

                      <template class="mt-5"  v-if="me">
                        <textarea
                            v-model="me.social_content"
                            class="rg-text-field"
                            style="background: rgba(25, 35, 58, 0.02); width: 100%; height: 130px; border-radius: 5px; padding: 10px; font-size: 12px;"
                        ></textarea>
                      </template>

                      <p class="mb-1 text-xs mt-3">The following tokens are available to be used in your posts:</p>

                      <div class="bg-gray-100 text-xs p-3 mb-3 rounded">
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[name]</span> will be replaced by the name of the reviewer</div>
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[date]</span> will be replaced by the date of the review</div>
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[rating]</span> will be replaced by the rating of the review</div>
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[text]</span> will be replaced by the text of the review</div>
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[source]</span> will be replaced by the name of the website the review was added on (e.g. Google)</div>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="pt-4" v-if="selected_theme">
                      <p class="mb-1 text-xs mb-4"><strong>Themes</strong></p>
                      <hr class="border-b border-gray-100">
                      <!-- <div class="float-right"><a href="javascript: void(0);" @click="$refs.customizeThemes.show()"><i class="fa fa-cog"></i> customize</a></div> -->
                      <p class="text-sm mb-4 text-gray-700 mt-2">
                        Select themes to be used for auto posting.
                        <br />
                        <strong>Note:</strong> choose multiple and they will be randomly used for posting.
                      </p>

                      <div class="pl-3">
                        <template v-if="me">
                          <v-row class="pl-2 pt-3">
                            <v-avatar v-for="theme in me.social_posting_themes" :key="theme.id" :class="'mr-7 mb-3 ' + ((isThemeSelected(theme.id)) ? 'selected' : 'unselected')" v-bind:style="{ backgroundColor: theme.background_color, color: theme.text_color }" @click="selectTheme(theme)">
                              <img
                                :src="theme.background_image"

                                class="social-share-circle"

                                v-if="theme.background_image"
                              >
                              <span class="headline" style="position: absolute;">Aa</span>
                            </v-avatar>

                            <v-avatar class="add-more" @click="$refs.customizeThemes.show()">
                              <span class="headline"><i class="fa fa-images"></i></span>
                            </v-avatar>
                          </v-row>
                        </template>

                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-card>
          </div>
        </div>

        <div class="w-full md:w-1/2 mb-6">
          <div class="dashboard-box">
            <v-card
              class="pa-2"
              tile
            >
              <div class="p-3">
                <div class="mb-4">
                  <h2 class="font-bold text-gray-800">Preview</h2>
                </div>

                <hr class="border-b border-gray-100">
                <p class="text-sm mb-4 text-gray-700 mt-2">Use filters to preview theme for Facebook, Google, Twitter or Instagram at different sizes.</p>
                <div class="">

                  <div>
                    <v-row>
                          <v-col cols="12" md="6" sm="6" xs="6">
                              <v-select
                                  v-model="previewOption"
                                  outlined
                                  dense
                                  hide-details
                                  :items="preview_options"
                                  @change="runGenerateImage()"
                              ></v-select>
                          </v-col>
                          <v-col cols="12" md="6" sm="6" xs="6">
                              <v-select
                                v-model="previewSize"
                                outlined
                                dense
                                hide-details
                                :items="preview_sizes"
                                @change="runGenerateImage()"
                              ></v-select>
                          </v-col>
                        </v-row>

                        <div class="mt-4">
                          <v-card
                          elevation="2"
                      >

                        <div v-if="!generatingImage && !generatedImage" style="text-align: center; padding: 100px;">
                          Please select a theme to generate preview.
                        </div>

                        <div class="text-gray-600 text-sm" v-if="generatingImage" style="text-align: center; padding: 100px;">
                          <i class="fa fa-spin fa-spinner"></i>
                          <br />
                          Generating Image...
                        </div>

                        <img :src="generatedImage" v-if="!generatingImage && generatedImage" style="width: 100%;" />

                      </v-card>
                    </div>

                    <div class="mt-2 float-right">
                      <a class="text-sm" :href="generatedImage" v-if="!generatingImage && generatedImage" target="download-graphic"><i class="fa fa-download mr-2"></i> Download Graphic</a>
                    </div>
                  
                    <div class="clearfix"></div>
                  </div>

                  <div class="clearfix"></div>
                </div>
                
              </div>
            </v-card>
          </div>
        </div>

      </div>

    </panel-navigation>

  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import PanelNavigation from '@/components/PanelNavigation.vue'
import DialogCustomizeThemes from '@/components/dialogs/dialogCustomizeThemes.vue'
import moment from 'moment'
import urlParser from 'url-parse'
import DialogMatchingReviews from '@/components/dialogs/social-posting/matchingReviews.vue'
import DialogPostingLog from '@/components/dialogs/social-posting/postingLog.vue'
import DialogPostingQueue from '@/components/dialogs/social-posting/postingQueue.vue'

export default {
  prop: ['force_business'],
  metaInfo () {
    return {
      title: "Social Posting | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    DialogCustomizeThemes,
    DialogMatchingReviews,
    DialogPostingLog,
    DialogPostingQueue
  },
  data: function() {
    return {
      saved: false,

      previewOption: "Facebook",
      preview_options: [
        'Facebook',
        'Google',
        'Twitter',
        'Instagram (download only)'
      ],
      previewSize: "Text: Medium",
      preview_sizes: [
        'Text: Small',
        'Text: Medium',
        'Text: Large'
      ],
      rating_value_options: [
        {id: 3, text: 'Rating at least 3'},
        {id: 4, text: 'Rating at least 4'},
        {id: 5, text: 'Rating at least 5'}
      ],
      long_reviews_options: [
        {id: 'truncate', text: 'Truncate long reviews'},
        {id: 'filter out', text: 'Filter out long reviews'}
      ],
      matching_reviews_loading: true,
      matching_reviews_count: false,
      saving: false,
      window_open: null,
      window_proc: null,
      selected_theme: null,
      generatingImage: false,
      review: null,
      generatedImage: null,
      loader: false,
      loading2: false,
      facebook_pages_loaded: false,
      facebook_pages: [],
      gmb_listings_loaded: false,
      gmb_listings: false,
      instagram_profiles_loaded: false,
      instagram_profiles: [],

      timezones: [
        'Europe/Andorra',
        'Asia/Dubai',
        'Asia/Kabul',
        'Europe/Tirane',
        'Asia/Yerevan',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia',
        'Pacific/Pago_Pago',
        'Europe/Vienna',
        'Australia/Lord_Howe',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Currie',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Broken_Hill',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Baku',
        'America/Barbados',
        'Asia/Dhaka',
        'Europe/Brussels',
        'Europe/Sofia',
        'Atlantic/Bermuda',
        'Asia/Brunei',
        'America/La_Paz',
        'America/Noronha',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Santarem',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Eirunepe',
        'America/Rio_Branco',
        'America/Nassau',
        'Asia/Thimphu',
        'Europe/Minsk',
        'America/Belize',
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Indian/Cocos',
        'Europe/Zurich',
        'Africa/Abidjan',
        'Pacific/Rarotonga',
        'America/Santiago',
        'America/Punta_Arenas',
        'Pacific/Easter',
        'Asia/Shanghai',
        'Asia/Urumqi',
        'America/Bogota',
        'America/Costa_Rica',
        'America/Havana',
        'Atlantic/Cape_Verde',
        'America/Curacao',
        'Indian/Christmas',
        'Asia/Nicosia',
        'Asia/Famagusta',
        'Europe/Prague',
        'Europe/Berlin',
        'Europe/Copenhagen',
        'America/Santo_Domingo',
        'Africa/Algiers',
        'America/Guayaquil',
        'Pacific/Galapagos',
        'Europe/Tallinn',
        'Africa/Cairo',
        'Africa/El_Aaiun',
        'Europe/Madrid',
        'Africa/Ceuta',
        'Atlantic/Canary',
        'Europe/Helsinki',
        'Pacific/Fiji',
        'Atlantic/Stanley',
        'Pacific/Chuuk',
        'Pacific/Pohnpei',
        'Pacific/Kosrae',
        'Atlantic/Faroe',
        'Europe/Paris',
        'Europe/London',
        'Asia/Tbilisi',
        'America/Cayenne',
        'Africa/Accra',
        'Europe/Gibraltar',
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
        'Europe/Athens',
        'Atlantic/South_Georgia',
        'America/Guatemala',
        'Pacific/Guam',
        'Africa/Bissau',
        'America/Guyana',
        'Asia/Hong_Kong',
        'America/Tegucigalpa',
        'America/Port-au-Prince',
        'Europe/Budapest',
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
        'Europe/Dublin',
        'Asia/Jerusalem',
        'Asia/Kolkata',
        'Indian/Chagos',
        'Asia/Baghdad',
        'Asia/Tehran',
        'Atlantic/Reykjavik',
        'Europe/Rome',
        'America/Jamaica',
        'Asia/Amman',
        'Asia/Tokyo',
        'Africa/Nairobi',
        'Asia/Bishkek',
        'Pacific/Tarawa',
        'Pacific/Enderbury',
        'Pacific/Kiritimati',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Almaty',
        'Asia/Qyzylorda',
        'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Beirut',
        'Asia/Colombo',
        'Africa/Monrovia',
        'Europe/Vilnius',
        'Europe/Luxembourg',
        'Europe/Riga',
        'Africa/Tripoli',
        'Africa/Casablanca',
        'Europe/Monaco',
        'Europe/Chisinau',
        'Pacific/Majuro',
        'Pacific/Kwajalein',
        'Asia/Yangon',
        'Asia/Ulaanbaatar',
        'Asia/Hovd',
        'Asia/Choibalsan',
        'Asia/Macau',
        'America/Martinique',
        'Europe/Malta',
        'Indian/Mauritius',
        'Indian/Maldives',
        'America/Mexico_City',
        'America/Cancun',
        'America/Merida',
        'America/Monterrey',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Chihuahua',
        'America/Ojinaga',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Bahia_Banderas',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Africa/Maputo',
        'Africa/Windhoek',
        'Pacific/Noumea',
        'Pacific/Norfolk',
        'Africa/Lagos',
        'America/Managua',
        'Europe/Amsterdam',
        'Europe/Oslo',
        'Asia/Kathmandu',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'America/Panama',
        'America/Lima',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'Pacific/Gambier',
        'Pacific/Port_Moresby',
        'Pacific/Bougainville',
        'Asia/Manila',
        'Asia/Karachi',
        'Europe/Warsaw',
        'America/Miquelon',
        'Pacific/Pitcairn',
        'America/Puerto_Rico',
        'Asia/Gaza',
        'Asia/Hebron',
        'Europe/Lisbon',
        'Atlantic/Madeira',
        'Atlantic/Azores',
        'Pacific/Palau',
        'America/Asuncion',
        'Asia/Qatar',
        'Indian/Reunion',
        'Europe/Bucharest',
        'Europe/Belgrade',
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Kirov',
        'Europe/Astrakhan',
        'Europe/Volgograd',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr',
        'Asia/Riyadh',
        'Pacific/Guadalcanal',
        'Indian/Mahe',
        'Africa/Khartoum',
        'Europe/Stockholm',
        'Asia/Singapore',
        'America/Paramaribo',
        'Africa/Juba',
        'Africa/Sao_Tome',
        'America/El_Salvador',
        'Asia/Damascus',
        'America/Grand_Turk',
        'Africa/Ndjamena',
        'Indian/Kerguelen',
        'Asia/Bangkok',
        'Asia/Dushanbe',
        'Pacific/Fakaofo',
        'Asia/Dili',
        'Asia/Ashgabat',
        'Africa/Tunis',
        'Pacific/Tongatapu',
        'Europe/Istanbul',
        'America/Port_of_Spain',
        'Pacific/Funafuti',
        'Asia/Taipei',
        'Europe/Kiev',
        'Europe/Uzhgorod',
        'Europe/Zaporozhye',
        'Pacific/Wake',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/Denver',
        'America/Boise',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Adak',
        'Pacific/Honolulu',
        'America/Montevideo',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'America/Caracas',
        'Asia/Ho_Chi_Minh',
        'Pacific/Efate',
        'Pacific/Wallis',
        'Pacific/Apia',
        'Africa/Johannesburg'
      ],
    }
  },
  computed: {
    ...mapState({
      fetchingListings: state => state.listings.fetchingListings,
      listings: state => state.listings.listings,
      integrations: state => state.integrations.integrations,
      me: state => state.me.me
    })
  },
  methods: {

    async save() {
      let self = this
      this.saving = true
      this.error = null

      try {
        this.saved = true
        await this.$store.dispatch('me/save', {
          social_content: this.me.social_content.trim(),
          company_name: this.me.company_name.trim(),
          templateEmail1: this.me.templateEmail1.trim(),
          templateSMS1: this.me.templateSMS1.trim(),
          templateEmail2: this.me.templateEmail2.trim(),
          templateSMS2: this.me.templateSMS2.trim(),
          templateEmail3: this.me.templateEmail3.trim(),
          templateSMS3: this.me.templateSMS3.trim(),
          userSegmentation: this.me.userSegmentation,
          social_posting_accounts: this.me.social_posting_accounts,
          social_posting_enabled: ((this.me.social_posting_enabled) ? 1 : 0),
          social_posting_frequency: this.me.social_posting_frequency,
          social_posting_theme: this.selected_theme,
          social_posting_size: this.previewSize,
          social_posting_rating: this.me.social_posting_rating,
          social_posting_long_reviews: this.me.social_posting_long_reviews,
          social_posting_review_sites: this.me.social_posting_review_sites,

          social_posting_day_of_week: this.me.settings.social_posting_day_of_week,
          social_posting_time_of_day: this.me.settings.social_posting_time_of_day,
          social_posting_timezone: this.me.settings.social_posting_timezone,
          social_posting_daily_limit: this.me.settings.social_posting_daily_limit ? this.me.settings.social_posting_daily_limit : "__EMPTY__",

          selected_facebook_page: this.me.selected_facebook_page,
          selected_gmb: this.me.selected_gmb,
          selected_insta: this.me.selected_insta
          })
          this.$store.dispatch('me/fetch')
        }catch(error){
          console.log(error)
          this.error = error
        }

        this.saving = false

        setTimeout(function() {
          self.saved = false
        }, 2000)

      },


       AuthProvider(provider) {

          if (this.me[provider])
            return;

          let self = this;

          this.window_open = this.popupCenter(process.env.VUE_APP_API_ENDPOINT + '/auth/'+provider + "?jwt=" + this.$store.state.auth.jwt + "&host=" + window.location.origin, 'window_open', 800, 900);

          if (this.window_proc) {
            clearInterval(this.window_proc);
            this.window_proc = null;
          }

          this.window_proc = setInterval(function() {
            try {
              // console.log(self.window_open.location.pathname);
              if (self.window_open.location.pathname == "/social-login-done") {
                self.window_open.close();
                self.window_open = null;
                clearInterval(self.window_proc);
                self.window_proc = null;

                self.$store.dispatch('me/fetch')
              }
            }
            catch (e) {
              console.log(e);
            }
          }, 300);

          return;

        },

        popupCenter(url, title, w, h) {
            var left = (screen.width/2)-(w/2);
            var top = (screen.height/2)-(h/2);
            return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
          },

      selectTheme(theme) {
        let selectedThemesArray = [];
        
        try {
          selectedThemesArray = this.selected_theme.split(",");
        }
        catch (e) {

        }

        if (selectedThemesArray.includes(theme.id.toString())) {
          let newThemes = [];

          for (var i in selectedThemesArray) {
            if (selectedThemesArray[i] != theme.id.toString())
              newThemes.push(selectedThemesArray[i]);
          }

          selectedThemesArray = JSON.parse(JSON.stringify(newThemes));

          // this.runGenerateImage()
        }
        else {
          selectedThemesArray.push(theme.id.toString());
          this.runGenerateImage(theme.id)
        }

        console.log('selectedThemesArray',selectedThemesArray)
        
        this.selected_theme = selectedThemesArray.join(",");
      },

      isThemeSelected(theme_id) {
        if (!this.selected_theme)
          return false;

        try {
          let selectedThemesArray = this.selected_theme.split(",");

          return selectedThemesArray.includes(theme_id.toString());
        }
        catch (e) {}
      },

      async runGenerateImage(theme_id = false) {
        this.generatingImage = true
        let review_id = (this.review) ? this.review.id : '';

        if (!theme_id) {
          try {
            if (this.selected_theme)
              theme_id = this.selected_theme.split(",")[0];
            else
              theme_id = this.me.social_posting_themes[0].id;
          }
          catch (e) {
            theme_id = this.me.social_posting_themes[0].id;
          }
        }

        // this.generatingImage = false
        // this.generatedImage = process.env.VUE_APP_API_ENDPOINT + '/me/generate-image?review_id=' + review_id + '&selected_theme=' + this.selected_theme + '&previewOption=' + this.previewOption + '&previewSize=' + this.previewSize;

        // + '&preview=1'

        try {
          let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/generate-image?review_id=' + review_id + '&selected_theme=' + theme_id + '&previewOption=' + this.previewOption + '&previewSize=' + this.previewSize + '&preview=1', {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })
          this.generatingImage = false
          this.generatedImage = response.data.url
        }catch(e){
          console.log(e)
        }
      },

      removeSocialAccount(type) {
        let self = this
        this.$swal({
            title: "Are you sure?",
            text: "This will delete the account permanently!",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
         }).then(function(result) {

            if (result.isConfirmed) {
              try {
                axios.delete(process.env.VUE_APP_API_ENDPOINT + '/me/delete-social-account/' + type, {
                  headers: {
                    'Authorization': 'Bearer ' + self.$store.state.auth.jwt
                  }
                })

              }catch(e){
                console.log(e)
              }

              self.me[type] = null;
            }

         });
      },

      async getReview() {
        let self = this
        if (this.$route.params.review_id) {
          try {
            let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/reviews/' + this.$route.params.review_id, {
              headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt
              }
            })
            this.review = response.data.review
            if (self.me.social_posting_theme)
              self.selected_theme = JSON.parse(JSON.stringify(self.me.social_posting_theme));
            else
              self.selected_theme = JSON.parse(JSON.stringify(self.me.social_posting_themes[0].id));
            
            // self.selected_theme = self.me.social_posting_themes[0].id;

            this.runGenerateImage()
          }catch(e){
            console.log(e)
          }
        }
        else {
          if (self.me.social_posting_theme)
            self.selected_theme = JSON.parse(JSON.stringify(self.me.social_posting_theme));
          else
            self.selected_theme = JSON.parse(JSON.stringify(self.me.social_posting_themes[0].id));

          this.runGenerateImage()
        }
      },

      async getFacebookPages() {
        this.facebook_pages = []
        this.facebook_pages_loaded = false
        if (this.me.facebook) {
          try {
            let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/get-fb-pages', {
              headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt
              }
            })
            this.facebook_pages = response.data.pages
            this.facebook_pages_loaded = true

            if (!this.me.selected_facebook_page && this.facebook_pages.length > 0)
              this.me.selected_facebook_page = this.facebook_pages[0].id

          }catch(e){
            console.log(e)
          }
        }
      },

      async getGMBListings() {
        this.gmb_listings = []
        this.gmb_listings_loaded = false
        console.log("MEEEE2")
        console.log(this.me)
        if (this.me.google) {
          try {
            let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/get-gmb-listings', {
              headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt
              }
            })
            this.gmb_listings = response.data.listings

            console.log('gmbbbb1')
            console.log(this.me.selected_gmb)

            if (!this.me.selected_gmb && this.gmb_listings.length > 0)
              this.me.selected_gmb = this.gmb_listings[0].id

            console.log('gmbbbb2')
            console.log(this.me.selected_gmb)

            this.gmb_listings_loaded = true

          }catch(e){
            console.log(e)
          }
        }
      },

      async getInstagramProfiles() {
        this.instagram_profiles = []
        this.instagram_profiles_loaded = false
        console.log("MEEEE2")
        console.log(this.me)
        if (this.me.google) {
          try {
            let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/get-instagram-profiles', {
              headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt
              }
            })
            this.instagram_profiles = response.data.profiles
            this.instagram_profiles_loaded = true

            console.log('instaaaa1')
            console.log(this.me.selected_insta)

            if (!this.me.selected_insta && this.instagram_profiles.length > 0)
              this.me.selected_insta = this.instagram_profiles[0].id

            console.log('instaaaa2')
            console.log(this.me.selected_insta)

          }catch(e){
            console.log(e)
          }
        }
      },

      async publishSocialPost() {
        let self = this
        this.loader = 'loading2'
        this.loading2 = true

        try {
            await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/publish-social-post', {
              review_id: this.$route.params.review_id,
              social_content: this.me.social_content.trim(),
              selected_accounts: this.me.social_posting_accounts,
              selected_theme: this.selected_theme,
              generatedImage: this.generatedImage,
              selected_facebook_page: this.me.selected_facebook_page,
              selected_gmb: this.me.selected_gmb,
              selected_insta: this.me.selected_insta
            } , {
              headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt
              },
            })
            
            this.$swal({
                title: "Posting completed!",
                text: "Your post has been published successfully! Please give it a few minutes before checking it on the social accounts.",
                showCancelButton: false,
                confirmButtonText: 'Back to reviews'
             }).then(function() {
                self.$router.push('/reviews')
             });

          }catch(e){
            console.log(e)
          }
      },

      async getMatchingReviewsCount() {
        let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/social-posting-matching-reviews-count?social_posting_rating=' + this.me.social_posting_rating + "&review_platforms=" + this.me.social_posting_review_sites, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })
        this.matching_reviews_loading = false
        this.matching_reviews_count = response.data.matching_reviews_count
      },

      getHostname(url) {
        return urlParser(url).hostname.replace("www.", "")
      },
      getPathname(url) {
        return urlParser(url).pathname + decodeURIComponent(urlParser(url).query);
      },
      formatDate: function(date) {
        return moment(String(date)).format(this.me.settings.date_format)
      },
      getIconUrl(url) {
        return require('@/assets/images/review-services/www.' + this.getHostname(url).replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com") + ".png");
      },
  },
  watch: {
      me: function(val, oldVal) {
        if (!val || !oldVal)
          return;

        if (val.facebook !== oldVal.facebook) {
          this.getFacebookPages()
        }

        if (val.google !== oldVal.google) {
          this.getGMBListings()
        }

        if (val.instagram !== oldVal.instagram) {
          this.getInstagramProfiles()
        }
      },

      'me.social_posting_frequency': function() {
        console.log('me.social_posting_frequency');
        console.log(this.me.social_posting_frequency);

        if (parseInt(this.me.social_posting_frequency) < 1 || this.me.social_posting_frequency == "") {
          this.me.social_posting_frequency = 1;
        }
      },

      'me.social_posting_rating': function() {
        console.log('me.social_posting_rating');
        console.log(this.me.social_posting_rating);

        this.getMatchingReviewsCount()
      },

      'me.social_posting_review_sites': function() {
        console.log('me.social_posting_review_sites');
        console.log(this.me.social_posting_review_sites);

        this.getMatchingReviewsCount()
      }
  },
  created() {
    var self = this;

    runStartScripts();

    function runStartScripts() {
      if (!self.me || self.me === null) {
        setTimeout(function() {
          runStartScripts();
        }, 1000);
        return;
      }

      self.getReview()

      setTimeout(function() {
        self.getFacebookPages()
      }, 1000)

      setTimeout(function() {
        self.getGMBListings()
      }, 1000)

      setTimeout(function() {
        self.getInstagramProfiles()
      }, 1000)

      setTimeout(function() {
        self.getMatchingReviewsCount();
      }, 1000)

      // self.selected_theme = self.me.social_posting_themes[0].id;
      // self.runGenerateImage()
    }
    // this.$store.dispatch('integrations/fetch')

    this.$store.state.listings.fetchingListings = true
    this.$store.dispatch('listings/fetch', { dashboard: false })
  }
}
</script>

<style>
  header .v-toolbar__content {
    width: auto;
  }

  .v-application .elevation-1 {
      border: 0px;
      webkit-box-shadow: none !important;
      box-shadow: none !important;
  }

  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    font-size: 12px;
  }

  .v-input--selection-controls.v-input {
    margin-bottom: -4px;
  }

  .auto-posting-frequency input[type="number"] {
    text-align: center;
  }

  .auth-provider {
    width: 45%;
    text-align: center;
    padding: 10px;
    /* padding-bottom: 3px; */
    background: rgba(25, 35, 58, 0.02);
  }

  .auth-provider.connected {
    background: rgba(36, 119, 246, 0.05);
  }
</style>