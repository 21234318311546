<template>

    <panel-navigation>
        <div>
            <div v-if="show_purchase_lr_advanced" class="fixed top-0 left-0 h-full w-full flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2); z-index: 8;">
                <div class="w-full sm:w-1/2 lg:w-1/2 bg-white rounded-lg mx-6">

                <div class="rg-modal-header">
                    Get Local Recon Advanced

                    <a href="javascript: void(0);" class="rg-modal-close-btn" @click="show_purchase_lr_advanced = false"><i class="fa fa-times"></i></a>
                </div>


                <div class="p-6">

                    Get access to our Advanced version of the Loca Recon App.

                    <br /><br />

                    <div class="float-left">
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; More Widgets</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; More Reports</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; Better Design</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; Custom CSS</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; Webhooks</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; API's</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; Scheduled Reports</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; Bulk Reports</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; Google Analytics</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; Lead Details Page</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; Widget Details Page</p>
                        <p class="text-sm"><i class="fa fa-check text-green-600"></i>&nbsp;&nbsp;&nbsp; and more...</p>
                    </div>

                    <div class="float-right">
                        <div class="flex-1 flex flex-col border rounded items-center mx-1 mb-5 justify-between">

                            <div class="text-lg uppercase w-full px-8 py-2 text-center bg-gray-300 text-gray-800">Monthly</div>
                            
                            <div class="px-10 py-5">
                                <div class="text-lg p-3 text-bold text-center">
                                    $99/month
                                </div>

                                <button class="py-2 px-3 rounded border bg-green-500 text-white mt-4 mb-4" @click="getLocalReconAdvanced('monthly')">Get Local Recon Monthly</button>
                            </div>

                        </div>

                        <div class="flex-1 flex flex-col border rounded items-center mx-1 mb-5 justify-between">

                            <div class="text-lg uppercase w-full px-8 py-2 text-center bg-gray-300 text-gray-800">Yearly</div>

                            <div class="px-10 py-5">
                                <div class="text-lg p-3 text-bold text-center">
                                    $499/year
                                </div>

                                <button class="py-2 px-3 rounded border bg-green-500 text-white mt-4 mb-4" @click="getLocalReconAdvanced('yearly')">Get Local Recon Yearly</button>
                            </div>

                        </div>
                    </div>

                    <div style="clear: both;"></div>
                </div>

                <div class="bg-gray-100 rounded-b-lg px-6 py-4">
                    <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 float-right" @click="show_purchase_lr_advanced = false">
                        CLOSE
                    </button>

                    <div class="clearfix"></div>
                </div>

                </div>
            </div>

            <div class="float-right ml-5 -mt-1">
                <div v-if="!hasLrAdvancedChecking && hasLrAdvanced" class="border-1 bg-green-600 text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="accessLocalReconAdvanced()" style="margin-top: -5px;"><i class="fa fa-sign-in-alt globe mr-2"> </i> ACCESS LOCAL RECON ADVANCED</div>

                <div v-if="!hasLrAdvancedChecking && !hasLrAdvanced" class="border-1 bg-orange-600 text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="show_purchase_lr_advanced = true" style="margin-top: -5px;"><i class="fa fa-globe mr-2"> </i> GET LOCAL RECON ADVANCED</div>
            </div>
            
            <h1 class="text-lg font-semibold mb-3">Local Recon</h1>

            <hr class="border-b border-gray-100 mb-3" />

            <v-skeleton-loader v-if="fetchingData" type="article, table, table"></v-skeleton-loader>

            <div class="-mx-2" v-if="!fetchingData">
                <div class="flex flex-row flex-wrap mb-4">
                    <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                    <v-card
                        class="pa-2"
                        tile
                    >
                        <div class="p-3">
                        <div class="dashboard-box-title">Leads <a href="javascript: void(0);" @click="$router.push('/local-recon/leads')"><i class="mdi mdi-logout-variant"></i></a></div>

                        <div class="stats-box-number">{{ dashboard_data.leads_count }}</div>
                        
                        <div class="stats-box-icon-bg"></div>
                        <div class="stats-box-icon">
                            <i class="mdi mdi-account-multiple"></i>
                        </div>
                        </div>
                    </v-card>
                    </div>

                    <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                    <v-card
                        class="pa-2"
                        tile
                    >
                        <div class="p-3">
                        <div class="dashboard-box-title">Reports <a href="javascript: void(0);" @click="$router.push('/local-recon/reports')"><i class="mdi mdi-logout-variant"></i></a></div>

                        <div class="stats-box-number">{{ dashboard_data.searches_count }}</div>
                        
                        <div class="stats-box-icon-bg"></div>
                        <div class="stats-box-icon">
                            <i class="mdi mdi-text-box-outline"></i>
                        </div>
                        </div>
                    </v-card>
                    </div>

                    <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                    <v-card
                        class="pa-2"
                        tile
                    >
                        <div class="p-3">
                        <div class="dashboard-box-title">Widgets <a href="javascript: void(0);" @click="$router.push('/local-recon/widgets')"><i class="mdi mdi-logout-variant"></i></a></div>

                        <div class="stats-box-number">{{ dashboard_data.widgets_count }}</div>
                        
                        <div class="stats-box-icon-bg"></div>
                        <div class="stats-box-icon">
                            <i class="mdi mdi-widgets-outline"></i>
                        </div>
                        </div>
                    </v-card>
                    </div>
                </div>
            </div>

            <div v-if="!fetchingData" class="mb-4">

                <div class="dashboard-box">
                    <v-card
                        class="pa-2"
                        tile
                    >
                        <div class="p-3">
                            <div class="dashboard-box-title">Latest Leads</div>

                            <div class="w-full mb-4">
                                <div v-if="dashboard_data.latest_leads.length == 0">
                                    <br /><br /><br /><br />

                                    <div class="dashboard-box-iconsquare">
                                    <div class="dashboard-box-icon-background"></div>
                                    <div class="dashboard-box-icon">
                                        <i class="mdi mdi-account-multiple"></i>
                                    </div>
                                    </div>

                                    <div style="text-align: center;">
                                        <div class="pt-2 pb-2">
                                            <strong>No Leads Yet</strong>
                                        </div>
                                    </div>
                                    
                                    <br /><br /><br /><br /><br />
                                </div>

                                <table v-else class="w-full rg-datatable mt-4">
                                    <thead class="border-b border-gray-100 w-full">
                                    <tr>
                                        <th>Lead Details</th>
                                        <th>Business</th>
                                        <th>Address</th>
                                        <th>Request Type</th>
                                        <th>Date</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody class="w-full" style="">
                                    <tr v-for="lead in dashboard_data.latest_leads" :key="lead.id">
                                        <td>
                                            <div v-if="lead.first_name || lead.last_name">
                                                {{ lead.first_name }} {{ lead.last_name }}
                                            </div>
                                            <a :href="'mailto:' + lead.email">{{ lead.email }}</a>
                                        </td>
                                        <td>{{ lead.company_name || "-" }}</td>
                                        <td>{{ getLeadAddress(lead) }}</td>
                                        <td>{{ ((lead.optin_type == 'view_gate') ? "Optin" : "Help Request") }}</td>
                                        <td>{{ formatDate(lead.created_at) }}</td>
                                        <td style="text-align: right;">
                                            <a href="javascript: void(0);" class="text-xs font-semibold rounded cursor-pointer" @click="openLeadReport(lead)">
                                                <i class="fa mdi mdi-text-box-outline mr-2 text-blue"></i>
                                                <span class="whitespace-no-wrap text-blue">View REPORT</span>
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </v-card>
                </div>
            </div>

            <div class="-mx-2" v-if="!fetchingData">
                <div class="flex flex-row flex-wrap mb-3">
                    <div class="dashboard-box stats-box w-full px-2 mb-4 md:w-1/3 lg:w-1/3">
                        <v-card
                            class="pa-2"
                            tile
                        >
                            <div class="p-3">
                                <div class="dashboard-box-title mb-2">Latest Reputation Reports</div>

                                <div v-if="dashboard_data.latest_reputation_searches.length == 0" class="flex flex-col justify-center items-center">
                                    <br /><br /><br /><br />

                                    <div class="dashboard-box-iconsquare">
                                    <div class="dashboard-box-icon-background"></div>
                                    <div class="dashboard-box-icon">
                                        <i class="mdi mdi-text-box-outline"></i>
                                    </div>
                                    </div>

                                    <div style="text-align: center;">
                                        <div class="pt-2 pb-2">
                                            <strong>No Reports Yet</strong>
                                        </div>
                                    </div>
                                    
                                    <br /><br /><br /><br /><br />
                                </div>
                                <div v-else class="">
                                    <div v-for="search in dashboard_data.latest_reputation_searches" :key="search.id" class="text-sm pt-3 text-gray-800 bg-white rounded">
                                        <div class="dashboard-activity-item mb-3">
                                            <div class="float-right not-mobile" style="font-size: 12px;">
                                                <div class="mb-2">
                                                    {{ formatDate(search.created_at) }}
                                                </div>
                                                <a href="javascript: void(0);" class="text-xs font-semibold rounded cursor-pointer" @click="openSearchReport(search)">
                                                    <i class="fa mdi mdi-text-box-outline mr-2 text-blue"></i>
                                                    <span class="whitespace-no-wrap text-blue">View REPORT</span>
                                                </a>
                                            </div>
                                            <div>
                                                <span class="mb-3">
                                                    <strong>{{ search.keyword }}</strong>
                                                </span>
                                                <br />
                                                <i class="mdi mdi-map-marker" style="font-size: 16px;"></i>
                                                &nbsp;
                                                {{ search.properties.formatted_address }}
                                            </div>
                                            <div style="clear: both;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <div class="dashboard-box stats-box w-full px-2 mb-4 md:w-1/3 lg:w-1/3">
                        <v-card
                            class="pa-2"
                            tile
                        >
                            <div class="p-3">
                                <div class="dashboard-box-title mb-2">Latest SEO Reports</div>

                                <div v-if="dashboard_data.latest_seo_searches.length == 0" class="flex flex-col justify-center items-center">
                                    <br /><br /><br /><br />

                                    <div class="dashboard-box-iconsquare">
                                    <div class="dashboard-box-icon-background"></div>
                                    <div class="dashboard-box-icon">
                                        <i class="mdi mdi-text-box-outline"></i>
                                    </div>
                                    </div>

                                    <div style="text-align: center;">
                                        <div class="pt-2 pb-2">
                                            <strong>No Reports Yet</strong>
                                        </div>
                                    </div>
                                    
                                    <br /><br /><br /><br /><br />
                                </div>

                                <div v-else class="">
                                    <div v-for="search in dashboard_data.latest_seo_searches" :key="search.id" class="text-sm pt-3 text-gray-800 bg-white rounded">
                                        <div class="dashboard-activity-item mb-3">
                                            <div class="float-right not-mobile" style="font-size: 12px;">
                                                <div class="mb-2">
                                                    {{ formatDate(search.created_at) }}
                                                </div>
                                                <a href="javascript: void(0);" class="text-xs font-semibold rounded cursor-pointer" @click="openSearchReport(search)">
                                                    <i class="fa mdi mdi-text-box-outline mr-2 text-blue"></i>
                                                    <span class="whitespace-no-wrap text-blue">View REPORT</span>
                                                </a>
                                            </div>
                                            <div>
                                                <span class="mb-3">
                                                    <strong>{{ search.keyword }}</strong>
                                                </span>
                                                <br />
                                                <i class="mdi mdi-web" style="font-size: 16px;"></i>
                                                &nbsp;
                                                {{ search.keyword }}
                                            </div>
                                            <div style="clear: both;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <div class="dashboard-box stats-box w-full px-2 mb-4 md:w-1/3 lg:w-1/3">
                        <v-card
                            class="pa-2"
                            tile
                        >
                            <div class="p-3">
                                <div class="dashboard-box-title mb-2">Latest Grid Reports</div>

                                <div v-if="dashboard_data.latest_grid_searches.length == 0" class="flex flex-col justify-center items-center">
                                    <br /><br /><br /><br />

                                    <div class="dashboard-box-iconsquare">
                                    <div class="dashboard-box-icon-background"></div>
                                    <div class="dashboard-box-icon">
                                        <i class="mdi mdi-text-box-outline"></i>
                                    </div>
                                    </div>

                                    <div style="text-align: center;">
                                        <div class="pt-2 pb-2">
                                            <strong>No Reports Yet</strong>
                                        </div>
                                    </div>
                                    
                                    <br /><br /><br /><br /><br />
                                </div>

                                <div v-else class="">
                                    <div v-for="search in dashboard_data.latest_grid_searches" :key="search.id" class="text-sm pt-3 text-gray-800 bg-white rounded">
                                        <div class="dashboard-activity-item mb-3">
                                            <div class="float-right not-mobile" style="font-size: 12px;">
                                                <div class="mb-2">
                                                    {{ formatDate(search.created_at) }}
                                                </div>
                                                <a href="javascript: void(0);" class="text-xs font-semibold rounded cursor-pointer" @click="openSearchReport(search)">
                                                    <i class="fa mdi mdi-text-box-outline mr-2 text-blue"></i>
                                                    <span class="whitespace-no-wrap text-blue">View REPORT</span>
                                                </a>
                                            </div>
                                            <div>
                                                <span class="mb-3">
                                                    <strong>{{ search.keyword }}</strong>
                                                </span>
                                                <br />
                                                <i class="mdi mdi-web" style="font-size: 16px;"></i>
                                                &nbsp;
                                                {{ search.properties.location.name }}
                                            </div>
                                            <div style="clear: both;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
  </panel-navigation>

</template>

<script>
    import { mapState } from 'vuex'
    import PanelNavigation from '@/components/PanelNavigation.vue'
    import moment from 'moment'
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Local Recon | Dashboard "
            }
        },
        components: {
            PanelNavigation
        },
        computed: {
            ...mapState({
                me: state => state.me.me
            })
        },
        data() {
            return {
                fetchingData: true,
                dashboard_data: false,
                hasLrAdvancedChecking: true,
                hasLrAdvanced: false,
                show_purchase_lr_advanced: false,
                lr_user_uuid: ""
            }
        },
        methods: {
            formatDate: function(date) {
                return moment(String(date)).format('MMM DD, YYYY')
            },
            async fetchDashboardData() {
                let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/local-recon/dashboard', {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    },
                })

                this.dashboard_data = response.data;
                this.fetchingData = false;
            },
            getLeadAddress(lead) {
                let items = [];

                if (lead.company_address)
                    items.push(lead.company_address);

                if (lead.city)
                    items.push(lead.city);

                if (lead.state)
                    items.push(lead.state);

                if (lead.zip)
                    items.push(lead.zip);

                if (items.length == 0)
                    return "-";
                
                return items.join(", ");
            },
            openLeadReport(lead) {
                window.open(window.location.origin + '/rf?s=' + lead.search.widget.uuid + '&p=' + lead.search.place_id);
            },
            openSearchReport(search) {
                window.open(window.location.origin + '/rf?s=' + search.widget.uuid + '&p=' + search.place_id);
            },
            getSearchReportUrl(search) {
                return window.location.origin + '/rf?s=' + search.widget.uuid + '&p=' + search.place_id;
            },
            onCopy: function (e) {
                this.$swal({
                    title: "Copied",
                    text: "URL copied successfully!",
                    showCancelButton: false,
                    });
            },
            onError: function (e) {
                alert('Failed to copy the text to the clipboard')
                // console.log(e);
            },
            async checkLrAdvanced() {
                let self = this

                let result = false; // CHANGE THIS

                let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/local-recon/check-advanced', {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    },
                })

                this.hasLrAdvanced = response.data.result;
                this.hasLrAdvancedChecking = false;

                if (this.hasLrAdvanced)
                    this.lr_user_uuid = response.data.lr_user_uuid;
            },
            getLocalReconAdvanced(frequency) {
                this.show_lr_advanced_popup = '';

                let local_recon_advanced_url = process.env.VUE_APP_LOCAL_RECON_ADVANCED_URL + '/signup/plan/9BE2YTCM3Q';

                if (frequency == "yearly")
                    local_recon_advanced_url = process.env.VUE_APP_LOCAL_RECON_ADVANCED_URL + '/signup/plan/OO3NZC66SZ';

                local_recon_advanced_url += "?company_name=" + this.me.company_name + "&email=" + this.me.email + "&first_name=" + this.me.first_name + "&last_name=" + this.me.last_name + "&phone=" + encodeURIComponent(this.me.phone);

                window.open(local_recon_advanced_url);
            },

            accessLocalReconAdvanced() {
                window.open(process.env.VUE_APP_LOCAL_RECON_ADVANCED_URL + "/login?u=" + this.lr_user_uuid);
            }
        },
        created() {
            this.fetchDashboardData();
            this.checkLrAdvanced();
        }
    }
</script>