<template>

  <div>
    <panel-navigation>

      <div class="float-right ml-5 -mt-1">
        <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="$router.push('/plans')" style="margin-top: -5px;"><i class="fa fa-arrow-left mr-2"> </i> Back</div>
      </div>

      <div class="flex flex-row items-top justify-between">
        <h1 class="text-lg font-semibold mb-3">
            <span v-if="$route.params.id">Edit Plan</span>
            <span v-else>Create Plan</span>
        </h1>
        <!-- <div class="bg-green-500 hover:bg-green-400 text-white inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': saving}" @click="save">Save</div> -->
      </div>
      
      <hr class="border-b border-gray-100 mb-3">

      <div v-if="me && $agency">
            <v-skeleton-loader v-if="loading" type="article, article, article"></v-skeleton-loader>

            <div v-else>
                <div class="rg-plan-editor flex justify-center items-top px-3">
                    <div style="min-width: 500px; max-width: 700px;">
                        <div class="border-teal p-8 border-t-12 bg-white mb-6 rounded-lg shadow-lg">
                            <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                              <div v-html="error"></div>
                            </div>

                            <div v-if="plan && plan.subscribers > 0" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                              <div>You can't delete this plan, there are <strong>{{ plan.subscribers }}</strong> subscribers on it.</div>
                            </div>
                            
                            <div class="w-full mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Plan
                                </label>
                                <input class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="background: rgb(224, 224, 224); font-size: 13px;" type="text"
                                    v-model="plan.name"
                                    placeholder="Enter plan name..."

                                    :readonly="plan.subscribers > 0"
                                >
                            </div>

                            <div class="mb-6">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Use Stripe Billing
                                </label>
                                <toggle-button v-model="plan.use_stripe" color="#48bb78" :sync="true" :disabled="((me.integrations.stripe.status != 1))" />

                                <div v-if="me.integrations.stripe.status != 1" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                                  <div>Stripe can't be enabled. Please integrate your Stripe account in settings first.</div>
                                </div>

                                <p class="text-xs text-gray-700 mt-2">When enabled, the plan will be syncronized with your Stripe account and your customers will be charged through our system. Keep the setting disabled if you want to handle billing externally.</p>
                            </div>

                            <div class="mb-6">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Separate Twilio
                                </label>
                                <toggle-button v-model="plan.own_twilio" color="#48bb78" :sync="true" />

                                <p class="text-xs text-gray-700 mt-2">Enabling this setting, will allow you to enter different Twilio credentials for each of your customers(businesses). They can even be credentials from a subaccount that you can create for them separately, inside of your Twilio account.</p>
                            </div>

                            <div class="mb-6">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Separate SMTP
                                </label>
                                <toggle-button v-model="plan.own_smtp" color="#48bb78" :sync="true" />

                                <p class="text-xs text-gray-700 mt-2">Enabling this setting, will allow you to enter different SMTP credentials for each of your customers(businesses). They will be able to use their own email to send from.</p>
                            </div>

                            <div class="mb-6">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Prices
                                </label>

                                <div v-for="(item, index) in plan.prices" :key="index">
                                    <div class="flex flew-row flex-wrap">
                                      <div class="w-full sm:w-2/3 pr-3">
                                        <v-text-field
                                            :label="plan.currency"
                                            v-model="plan.prices[index].unit_amount"
                                            suffix="/"
                                            outlined
                                            dense
                                        ></v-text-field>
                                      </div>
                                      <div class="w-full sm:w-1/3">
                                        <v-select
                                          :items="frequency_options"
                                          v-model="plan.prices[index].recurring.interval"
                                            outlined
                                            dense
                                          :append-outer-icon="'mdi-trash-can-outline'"
                                          @click:append-outer="deletePrice(index)"
                                        ></v-select>
                                      </div>
                                    </div>

                                    <p class="text-xs text-gray-700">Signup URL: <a :href="getSignupUrl(item)" target="_blank">{{ getSignupUrl(item) }}</a></p>
                                    <hr class="mb-2" />
                                </div>

                                <a href="javascript: void(0);" class="text-xs" @click="plan.prices.push(generateNewPrice())">
                                    <i class="fa fa-plus"></i> Add Price
                                </a>
                            </div>

                            <div class="w-full mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Currency
                                </label>
                                <v-select
                                  :items="currency_options"
                                  label=""
                                  v-model="plan.currency"
                                  solo
                                ></v-select>
                            </div>

                            <div class="w-full mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Free Trial
                                </label>
                                <v-select
                                  :items="trial_options"
                                  label=""
                                  v-model="plan.trial"
                                  suffix="days"
                                  solo
                                ></v-select>
                            </div>

                            <div class="w-full mb-2 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Locations
                                </label>

                                <v-text-field
                                    label="Enter 0 for Unlimited"
                                    v-model="plan.limits.locations"
                                    outlined
                                ></v-text-field>

                                <div class="mb-6">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        SMS Enabled
                                    </label>
                                    <toggle-button v-model="plan.limits.sms_enabled" color="#48bb78" :sync="true" />
                                </div>

                                <label v-show="plan.limits.sms_enabled == 1" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    SMS Limit
                                </label>

                                <div v-show="plan.limits.sms_enabled == 1" style="max-width: 300px;">
                                  <v-text-field
                                      label="Enter 0 for Unlimited"
                                      v-model="plan.limits.sms"
                                      type="number"
                                      outlined
                                  ></v-text-field>

                                  <v-text-field v-show="plan.limits.sms > 0 && plan.use_stripe == 1"
                                      label="Price per extra SMS"
                                      v-model="plan.limits.sms_price"
                                      suffix="/sms"
                                      type="number"
                                      outlined
                                  ></v-text-field>
                                </div>

                                <div v-show="agencyHasGridTrackerAddon()">
                                  <div class="mb-6">
                                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                          Grid Tracker Enabled
                                      </label>
                                      <toggle-button v-model="plan.limits.grid_tracker_enabled" color="#48bb78" :sync="true" />
                                  </div>

                                  <label v-show="plan.limits.grid_tracker_enabled == 1" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                      Reports Limit
                                  </label>

                                  <div v-show="plan.limits.grid_tracker_enabled == 1" style="max-width: 300px;">
                                    <v-text-field
                                        label="Enter 0 for Unlimited"
                                        v-model="plan.limits.grid_tracker_reports"
                                        type="number"
                                        outlined
                                    ></v-text-field>

                                    <v-text-field v-show="plan.limits.grid_tracker_reports > 0 && plan.use_stripe == 1"
                                        label="Price per extra SMS"
                                        v-model="plan.limits.grid_tracker_reports_extra"
                                        suffix="/report"
                                        type="number"
                                        outlined
                                    ></v-text-field>
                                  </div>
                                </div>

                                <div class="mb-6">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Generate Review Responses
                                    </label>
                                    <toggle-button v-model="plan.limits.generate_review_responses" color="#48bb78" :sync="true" />
                                </div>
                            </div>

                            <div class="mb-6">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Sections
                                </label>

                                <br clear="all" />

                                <div class="flex flex-row flex-wrap">
                                  <div v-for="(item, index) in plan.limits.permissions" :key="index" v-show="index != '/dashboard' && index != '/logout' && index != '/reports'" class="w-full px-2 mb-4 md:w-1/3 lg:w-1/3">
                                      <toggle-button v-model="plan.limits.permissions[index]" color="#48bb78" :sync="true" /> &nbsp;&nbsp; <span class="text-sm text-gray-600">{{ index }}</span>
                                  </div>
                                </div>

                                <div class="mb-6">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Hide Sections
                                    </label>
                                    <toggle-button v-model="plan.limits.hide_sections" color="#48bb78" :sync="true" />

                                    <p class="text-xs text-gray-700 mt-2">When enabled, sections will be completely hidden, not just greyed out.</p>
                                </div>
                            </div>

                            <div class="mb-6">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Features
                                </label>

                                <div v-for="(item, index) in plan.features" :key="index">
                                    <v-text-field
                                        v-model="plan.features[index]"
                                        hide-details
                                        :append-outer-icon="'mdi-trash-can-outline'"
                                        @click:append-outer="deleteFeature(index)"
                                        placeholder="Enter feature description..."
                                        outlined
                                        class="mb-2"
                                    ></v-text-field>
                                </div>

                                <a href="javascript: void(0);" class="text-xs" @click="plan.features.push('')">
                                    <i class="fa fa-plus"></i> Add Feature
                                </a>
                            </div>

                            <div v-if="plan && plan.subscribers > 0" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                              <div>You can't delete this plan, there are <strong>{{ plan.subscribers }}</strong> subscribers on it.</div>
                            </div>

                            <button
                                class="rounded bg-green-500 hover:bg-green-400 hover:border-green-500 text-white focus:outline-none py-3 px-4" @click="savePlan()">
                                Save
                            </button>

                            <br /><br /><br />

                            <button
                                v-if="plan.id && plan.subscribers == 0" class="rounded bg-red-500 hover:bg-red-400 hover:border-red-500 text-white focus:outline-none py-3 px-4" @click="deletePlan()">
                                Delete Plan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
      </div>

    </panel-navigation>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import PanelNavigation from '@/components/PanelNavigation.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import moment from 'moment'

export default {
  metaInfo () {
    return {
      title: "Manage Plan | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    ToggleButton
  },
  data() {
    return {
        loading: false,
        plan: {
            code: "",
            name: "",
            using_stripe: false,
            own_twilio: false,
            product_id: this.makeid(16),
            subscribers: 0,
            trial: 0,
            currency: "USD",
            prices: [{"unit_amount":0,"currency": ((this.plan) ? this.plan.currency : "USD"),"price_id":this.makeid(12),"recurring":{"interval":"month"}}],
            limits: {"locations":1,"sms_enabled": true, "sms":0,"sms_price":0,"review_profiles":0,"permissions":{"/dashboard":true,"/contacts":true,"/reviews":true,"/feedback":true,"/review-platforms":true,"/settings":true,"/social-posting":true,"/widgets":true,"/reports":true,"/grid-tracker-list":true,"/inbox":true,"/users":true,"/logout":true}},
            features: ["Feature 1", "Feature 2", "Feature 3"]
        },

        trial_options: [
          0, 7, 14, 20, 31
        ],

        currency_options: [
          'USD',
          'AED',
          'AUD',
          'BGN',
          'BRL',
          'CAD',
          'CHF',
          'CZK',
          'DKK',
          'EUR',
          'GBP',
          'HKD',
          'HRK',
          'HUF',
          'INR',
          'JPY',
          'MXN',
          'MYR',
          'NOK',
          'NZD',
          'PLN',
          'RON',
          'SEK',
          'SGD'
        ],

        frequency_options: ['month', 'year'],

        saving: false,

        error: null
    }
  },
  methods: {
    async getPlan() {
        this.loading = true;

        let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/plans/` + (this.$route.params.id || this.plan.id), {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
        })

        this.plan = response.data.plan;
        this.loading = false;
    },

    async savePlan() {
      this.saving = true;
      this.error = null

      try {
        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/plans/save`, {
            plan: this.plan
        }, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
        })

        this.plan = response.data.plan
        console.log('this.plan', this.plan);
        this.getPlan();
      }
      catch(e) {
        this.error = e.response.data.message
      }

      this.saving = false;
    },

    async deletePlan() {
      let self = this
        this.$swal({
            title: "Are you sure?",
            text: "This will delete the plan permanently!",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
         }).then(function(result) {

            if (result.isConfirmed) {
              axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/plans/delete/` + self.plan.id, {
                  headers: {
                    'Authorization': 'Bearer ' + self.$store.state.auth.jwt
                  }
              }).then(function() {
                self.$router.push("/plans");
              });
            }
         });
    },

    deleteFeature(index) {
      let all_features = this.plan.features;
      let new_features = [];

      for (var i in all_features) {
        if (i != index) {
          new_features.push(all_features[i]);
        }
      }

      this.plan.features = new_features;
    },

    deletePrice(index) {
      let all_prices = this.plan.prices;
      let new_prices = [];

      for (var i in all_prices) {
        if (i != index) {
          new_prices.push(all_prices[i]);
        }
      }

      this.plan.prices = new_prices;
    },

    generateNewPrice() {
      let new_price_id = this.makeid(12);
      let new_price = {'unit_amount':0,'currency':'usd','price_id':new_price_id,'recurring':{'interval':'month'}};

      return new_price;
    },

    getSignupUrl(item) {
      return window.location.origin + '/signup/business/plan/' + this.plan.product_id + '-' + item.price_id;
    },

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
      }
      return result;
    },

    agencyHasGridTrackerAddon() {
      for (var i in this.me.subscription.addons) {
        let userAddon = this.me.subscription.addons[i];
        for (var j in this.me.subscription.product_details.addons) {
          let productAddon = this.me.subscription.product_details.addons[j];
          if (productAddon.key == 'grid-tracker') {
            for (var x in this.me.subscription.product_details.addons[j].prices) {
              let price = this.me.subscription.product_details.addons[j].prices[x];
              if (userAddon.stripe_price == price.id) {
                return true;
              }
            }
          }
        }
      }

      return false;
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me,
      agency: state => state.agency
    })
  },
  mounted() {
      if (this.$route.params.id)
        this.getPlan();
  }
}
</script>

<style>
  .mdi-trash-can-outline::before {
    color: #F03737 !important;
  }
</style>