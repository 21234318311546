import { render, staticRenderFns } from "./Agencies.vue?vue&type=template&id=65b6e683&scoped=true"
import script from "./Agencies.vue?vue&type=script&lang=js"
export * from "./Agencies.vue?vue&type=script&lang=js"
import style0 from "./Agencies.vue?vue&type=style&index=0&id=65b6e683&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b6e683",
  null
  
)

export default component.exports