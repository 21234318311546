<template>

    <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/2 lg:w-3/4 bg-white rounded-lg mx-6" style="max-width: 700px;">
  
        <div class="rg-modal-header">
          <span v-if="!id">Move item to folder</span>
  
          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
        </div>
  
        <div class="p-6">
  
          <div class="flex flex-row flex-wrap">
            <div class="w-full sm:w-full">
  
              <div class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                  Select New Folder
                </strong></p>

                <div v-for="(folder, index) in members_area_folders" :key="index" class="dashboard-box stats-box float-left mr-4 mb-4" style='width: 100%; max-width: 150px !important; cursor: pointer;' @click="moveToFolder(folder.id, item.id)">
                    <v-card
                      class="pa-1"
                      tile
                    >
                      <div style="text-align: center;">
                        <i class="mdi mdi-folder-outline" style="font-size: 40px; color: blue;"></i>
                      </div>
                      <div class="p-1 pb-3" style="text-align: center;">
                        <strong>{{ folder.title }} </strong>
                      </div>
                    </v-card>
                  </div>

              </div>
  
              <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                {{success}}
              </div>
  
              <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                <div v-html="error"></div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="bg-gray-100 rounded-b-lg px-6 py-4">
  
          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
              CANCEL
          </button>
  
          <div class="clearfix"></div>
  
        </div>
  
      </div>
    </div>
  
  </template>
  
  <script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  
  export default {
    data: function() {
      return {
        showModal: false,
        saving: false,
        success: null,
        error: null,
        id: null,

        item: null,

        members_area_folders: null
      }
    },
    methods: {
      async getFolders() {
        let url = `${process.env.VUE_APP_API_ENDPOINT}/members-area`;

        let response = await axios.get(url, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

        this.members_area_folders = response.data.folders;
      },
      
      show() {
        this.showModal = true
      },
      hide() {
        this.showModal = false
        this.saving = false
      },
      async moveToFolder(folder_id, item_id) {
        this.saving = true
        this.error = null
        try {
  
          let formData = new FormData();
  
          formData.append("folder_id", folder_id);
          formData.append("item_id", item_id);
  
          await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/members-area/move-to-folder`, formData, {
              headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
                'Content-Type': 'multipart/form-data'
              }
          })
  
          this.hide()
  
          this.$parent.$parent.getFolders();
          this.error = null
  
        } catch (e) {
            console.log(e);
          this.error = e.response.data.message
        }
        this.saving = false
      },
  
      setItem(item) {
        this.item = item;
        this.getFolders();
      },
    },
    created() {
  
    }
  }
  </script>
  