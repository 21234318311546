<template>
    <auth-layout :page="'signup-omnitalk'">
      <h1 class="text-xl mb-2 text-bold">Sign up for OmniTalk</h1>
  
      <br />
  
      <div>
        <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          {{success}}
        </div>
  
        <div v-if="error && error.indexOf('email has already')">

            <div class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                <div class="text-md">
                    You already have a Review Grower account, which is great! All you have to do is verify your password and confirm the activation of addon {{ selected_plan.name }}
                    <br /><br />
                    Order Information:<br /><br />
                    <strong>Addon:</strong> {{ selected_plan.name }}<br />
                    <strong>Price:</strong> {{ selected_plan.price + ' ' + selected_plan.currency + '/' + selected_plan.frequency }}<br />
                    <strong>Paid with:</strong> Credit card ending in <strong>{{ user_details.paymentMethod.card.last4 }}</strong><br />
                    
                    <br />

                    <div class="mb-4" style="position: relative;">
                        <input :type="((show_password) ? 'text' : 'password')" v-model="verify_password" class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" :class="{'opacity-50 cursor-not-allowed': authenticating}" autocomplete="new-password" placeholder="Verify Password" style="background: #FFFFFF !important;">
          
                        <a href="javascript: void(0);" @click="show_password = !show_password" class="token_password_toggle">
                          <i v-if="show_password" class="fa fa-eye"></i>
                          <i v-if="!show_password" class="fa fa-eye-slash"></i>
                        </a>
                    </div>

                    <div v-if="error_confirming" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                        <div v-html="error_confirming"> </div>
                    </div>

                    <button class="w-full rounded bg-blue text-white text-bold text-xs focus:outline-none py-3 px-4" :style="((email && password) ? '' : 'background: rgba(204, 204, 204, 0.40); color: rgba(0, 0, 0, 0.30) !important;')" :class="{'spinner': confirming}" @click="confirmPurchase();">
                      Proceed to Checkout
                    </button>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                <div v-html="error"> </div>
            </div>
  
            <p class="text-xs mb-4">Enter your information below to create your account.</p>
          
            <div v-if="e1 == 1">
              <div v-if="$agency || isSpecial()">
              <div v-if="selected_plan" class="mb-4">
                <p class="mb-1 text-xs mb-2">
                  Plan
                </p>
                <input class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" style="background: rgb(224, 224, 224); font-size: 13px;" type="text" :value="selected_plan.name + '' + ((selected_plan.price == 0) ? ' - 0.00 ' + selected_plan.currency + '/' + selected_plan.frequency : ' - ' + selected_plan.price.toFixed(2) + ' ' + selected_plan.currency + '/' + selected_plan.frequency)" readonly>
              </div>
            </div>
            <div v-else class="mb-4">
              <p class="mb-1 text-xs mb-2">
                Plan
              </p>
  
              <v-select
                :items="rg_plans"
                label=""
                hide-details
                outlined
                dense
                v-model="selected_plan"
                class="-mt-2"
                color="grey"
                background-color="grey lighten-4"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.plan_full_name }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.plan_full_name }}
                </template>
              </v-select>
            </div>
            
            <div class="mb-4">
                <p class="mb-1 text-xs mb-2">Email</p>
                <input type="email" v-model="email" class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" :class="{'opacity-50 cursor-not-allowed': authenticating}" placeholder="e.g. johndoe@example.com">
            </div>
  
            <div class="mb-4" style="position: relative;">
                <p class="mb-1 text-xs mb-2">Password</p>
                <input :type="((show_password) ? 'text' : 'password')" v-model="password" class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" :class="{'opacity-50 cursor-not-allowed': authenticating}" autocomplete="new-password" placeholder="Create Password">
  
                <a href="javascript: void(0);" @click="show_password = !show_password" class="token_password_toggle">
                  <i v-if="show_password" class="fa fa-eye"></i>
                  <i v-if="!show_password" class="fa fa-eye-slash"></i>
                </a>
            </div>
  
            <button class="w-full rounded bg-blue text-white text-bold text-xs focus:outline-none py-3 px-4" :style="((email && password) ? '' : 'background: rgba(204, 204, 204, 0.40); color: rgba(0, 0, 0, 0.30) !important;')" :class="{'spinner': registering}" @click="((email && password) ? submit() : '')">
                CONTINUE
            </button>
  
          </div>
  
          <div v-if="e1 == 2">
            <div class="mb-4">
              <p class="mb-1 text-xs mb-2">
                  First Name
                </p>
                <input class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" type="text" v-model="first_name" placeholder="e.g. John">
            </div>
  
            <div class="mb-4">
                <p class="mb-1 text-xs mb-2">
                  Last Name
                </p>
                <input class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" type="text" v-model="last_name" placeholder="e.g. Doe">
            </div>
  
            <div class="mb-4">
              <p class="mb-1 text-xs mb-2">Phone Number</p>
              <input type="text" ref="phone" v-model="phone" @keyup="phone_valid = false" class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" placeholder="e.g. +18123124214">
            </div>
  
            <button class="w-full rounded bg-blue text-white text-bold text-xs focus:outline-none py-3 px-4" :style="((first_name && last_name && phone) ? '' : 'background: rgba(204, 204, 204, 0.40); color: rgba(0, 0, 0, 0.30) !important;')" :class="{'spinner': registering}" @click="((first_name && last_name && phone) ? submit() : '')">
                CONTINUE
            </button>
  
          </div>
  
          <div v-if="e1 == 3" style="position: relative;">
  
            <div>
              <!-- <p class="text-xs text-gray-700 mt-2">Use our tool below to search for your business online, or enter your details manually.</p> -->
  
              <v-combobox
                  label="Search for your business"
                  v-model="autocompleteLocationModel"
                  :items="locationFoundItems"
                  :search-input.sync="locationSearchText"
                  item-text="value"
                  item-value="id"
                  hide-no-data
                  return-object
                  :filter="filterPlaces"
                  color="grey"
                  background-color="grey lighten-4"
                  @change="selectPlace()"
                  dense
                  outlined
                >
              </v-combobox>
            </div>
  
            <div style="position: absolute; right: 0px; margin-top: -15px; z-index: 9;">
              <a href="javascript: void(0);" @click="show_company_fields = !show_company_fields" style="font-size: 12px;">+ Add Manually</a>
            </div>
  
            <div v-show="show_company_fields" class="flex flex-wrap">
              <div class="flex flex-wrap">
                <div :class="'w-full md:w-1/2 pr-3 mb-4 md:mb-0'">
                  <p class="mb-1 text-xs mb-2">
                    Company Name
                  </p>
                  <input class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" id="grid-city" type="text" ref="company_name" v-model="company_name" placeholder="e.g. My Company Inc.">
                </div>
  
                <div :class="'w-full md:w-1/2 pl-3 mb-4 md:mb-0'">
                  <p class="mb-1 text-xs mb-2">
                    Country
                  </p>
                  
                  <v-select
                    :items="countries"
                    label=""
                    item-text="name"
                    item-value="code"
                    hide-details
                    outlined
                    dense
                    v-model="business_location.country"
                    class="-mt-2"
                    color="grey"
                  ></v-select>
                </div>
  
                <div :class="'w-full md:w-1/2 pr-3 mb-4 md:mb-0'">
                  <p class="mb-1 text-xs mb-2">
                    Address
                  </p>
                  <input class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" type="text" v-model="business_location.address" placeholder="e.g. 5th Avenue">
                </div>
                <div :class="'w-full md:w-1/2 pl-3 mb-4 md:mb-0'">
                  <p class="mb-1 text-xs mb-2">
                    City
                  </p>
                  <input class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" type="text" v-model="business_location.city" placeholder="e.g. New York">
                </div>
  
                <div :class="'w-full md:w-1/2 pr-3 mb-4 md:mb-0'">
                  <p class="mb-1 text-xs mb-2">
                    <span v-if="business_location.country == 'US'">State</span>
                    <span v-if="business_location.country != 'US'">County</span>
                  </p>
                  <input class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" type="text" v-model="business_location.state" placeholder="e.g. NY">
                </div>
  
                <div :class="'w-full md:w-1/2 pl-3 mb-4 md:mb-0'">
                  <p class="mb-1 text-xs mb-2">
                    <span v-if="business_location.country == 'US'">Zip</span>
                    <span v-if="business_location.country != 'US'">Postal Code</span>
                  </p>
                  <input class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" type="text" v-model="business_location.zip" placeholder="00001">
                </div>
              </div>
            </div>
  
            <!-- <div v-if="company_type == 'business'" class="w-full px-3 md:mb-0">
              <v-checkbox
                v-model="pull_google_reviews"
                :label="`Pull my Google reviews`"
              ></v-checkbox>
            </div> -->
  
            <div v-if="!only_business && 0" class="w-full md:w-1/3 px-3 mb-4 md:mb-0">
              <p class="mb-1 text-xs mb-2">
                Company Type
              </p>
              <div class="relative">
                <select class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" id="grid-state" ref="company_type" v-model="company_type">
                  <option value="agency">Agency</option>
                  <option value="business">Business</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>
  
            <div class="mb-4" v-show="company_type == 'agency'">
              <p class="mb-1 text-xs mb-2">Subdomain
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="fa fa-question-circle" style="font-size: 16px;"
                      v-bind="attrs"
                      v-on="on"
                    ></i>
                  </template>
                  <span>Choose your your white label subdomain, <br />this will be where your application resides. <br />For example your company name could be HighRepAgency<br /> and your application would be: highrepagency.reviewgrower.com<br /> No capitals or special characters are allowed.</span>
                </v-tooltip>
  
              </p>
                <input type="text" v-model="subdomain" class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" :class="{'opacity-50 cursor-not-allowed': authenticating}" style="width: calc(100% - 160px); display: inline-block;" placeholder="e.g. digital">
                <input type="text" class="rg-text-field rg-auth-field block w-full border-0 px-2 py-2 rounded" :class="{'opacity-50 cursor-not-allowed': authenticating}" style="margin-left: -3px; width: 160px; display: inline-block; background-color: #e0e0e0;" value=".reviewgrower.com" disabled>
            </div>
  
            <div :class="'w-full mb-2 md:mb-0'">
              <p class="mb-1 text-xs mb-2">
                Timezone
              </p>
              <v-combobox
                class="-mt-1"
                :items="timezones"
                v-model="timezone"
                label="Timezone"
                background-color="grey lighten-4"
                outlined
                dense
                color="grey"
              ></v-combobox>
            </div>
  
            <div v-if="company_type == 'agency' || (company_type == 'business' && !$agency)">
              <div class="float-left">
                <v-checkbox v-model="agree" :ripple="false"></v-checkbox>
              </div>
  
              <div class="float-left pt-1">
                <p class="text-xs">By signing up, I agree to the <a href="https://reviewgrower.com/terms-of-service/" target="rg-tos">Terms of Service</a> & <a href="https://reviewgrower.com/privacy-policy/" target="rg-privacy">Privacy Policy</a></p>
              </div>
            </div>
  
            <button class="w-full rounded bg-blue text-white text-bold text-xs focus:outline-none py-3 px-4" :style="((company_type && ((company_type == 'agency' && subdomain) || company_type != 'agency')) ? '' : 'background: rgba(204, 204, 204, 0.40); color: rgba(0, 0, 0, 0.30) !important;')" :class="{'spinner': registering}" @click="((company_name && ((company_type == 'agency' && subdomain) || company_type != 'agency')) ? submit() : '')">
                CREATE ACCOUNT
            </button>
  
          </div>
        </div>
  
        <!-- <div v-show="e1 == 1" class="mt-6 text-xs">
          Already have an account? <router-link class="no-underline align-baseline font-medium text-xs" to="/login">Login</router-link>
        </div> -->
      </div>
    </auth-layout>
  
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import AuthLayout from '@/components/AuthLayout.vue'
  import { GetSuggestions } from '@/utils/PlaceUtils'
  import $ from 'jquery'
  import axios from 'axios'
  import countries from '@/config/countries';
  
  export default {
    components: {
      AuthLayout
    },
  
    data: function() {
      return {
        email: "",
        password: "",
        show_password: false,
  
        first_name: "",
        last_name: "",
        phone: "",
        
        company_name: "",
  
        subdomain: "",
  
        show_company_fields: false,
  
        success: null,
        info: null,
        error: null,
        registering: false,
        accountCreated: false,
        authenticating: false,
  
        company_type: 'agency',
        only_business: false,
  
        autocompleteLocationModel: null,
        locationSearchText: null,
        locationEntries: [],
  
        pull_google_reviews: false,

        verify_password: "",
  
        business_location: {
          country: "US"
        },
  
        selected_plan: null,
  
        countries: countries,
        
        rg_plans: [
            {
              "name": "Basic ($97/year)",
              "code": "omnitalk-basic",
              "price": 97,
              "price_id": "price_1OMWFHA6fbbQtvb38gIGM3CN",
              "currency": "USD",
              "frequency": "year",
              "plan_full_name": "OmniTalk Basic - 97.00 USD/year"
            },
            {
              "name": "Standard ($197/year)",
              "code": "omnitalk-standard",
              "price": 197,
              "price_id": "price_1OMWFWA6fbbQtvb3a6knbWEQ",
              "currency": "USD",
              "frequency": "year",
              "plan_full_name": "OmniTalk Standard - 197.00 USD/year"
            },
            {
              "name": "Advanced ($297/year)",
              "code": "omnitalk-advanced",
              "price": 297,
              "price_id": "price_1OMWFjA6fbbQtvb3Ml0MkPqE",
              "currency": "USD",
              "frequency": "year",
              "plan_full_name": "OmniTalk Advanced - 297.00 USD/year"
            },
            {
              "name": "Premier ($497/year)",
              "code": "omnitalk-premier",
              "price": 497,
              "price_id": "price_1OMWFwA6fbbQtvb33bs9jA61",
              "currency": "USD",
              "frequency": "year",
              "plan_full_name": "OmniTalk Premier - 497.00 USD/year"
            }
        ],
  
        e1: 1,
  
        phone: "",
        phone_valid: false,
        verifying_otp: false,
        otp_valid: false,
        otp_attempted: false,
        otp_generated_code: null,
  
        agree: false,
  
        timezone: "America/New_York",
  
        timezones: [
          'Europe/Andorra',
          'Asia/Dubai',
          'Asia/Kabul',
          'Europe/Tirane',
          'Asia/Yerevan',
          'Antarctica/Casey',
          'Antarctica/Davis',
          'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
          'Antarctica/Mawson',
          'Antarctica/Palmer',
          'Antarctica/Rothera',
          'Antarctica/Syowa',
          'Antarctica/Troll',
          'Antarctica/Vostok',
          'America/Argentina/Buenos_Aires',
          'America/Argentina/Cordoba',
          'America/Argentina/Salta',
          'America/Argentina/Jujuy',
          'America/Argentina/Tucuman',
          'America/Argentina/Catamarca',
          'America/Argentina/La_Rioja',
          'America/Argentina/San_Juan',
          'America/Argentina/Mendoza',
          'America/Argentina/San_Luis',
          'America/Argentina/Rio_Gallegos',
          'America/Argentina/Ushuaia',
          'Pacific/Pago_Pago',
          'Europe/Vienna',
          'Australia/Lord_Howe',
          'Antarctica/Macquarie',
          'Australia/Hobart',
          'Australia/Currie',
          'Australia/Melbourne',
          'Australia/Sydney',
          'Australia/Broken_Hill',
          'Australia/Brisbane',
          'Australia/Lindeman',
          'Australia/Adelaide',
          'Australia/Darwin',
          'Australia/Perth',
          'Australia/Eucla',
          'Asia/Baku',
          'America/Barbados',
          'Asia/Dhaka',
          'Europe/Brussels',
          'Europe/Sofia',
          'Atlantic/Bermuda',
          'Asia/Brunei',
          'America/La_Paz',
          'America/Noronha',
          'America/Belem',
          'America/Fortaleza',
          'America/Recife',
          'America/Araguaina',
          'America/Maceio',
          'America/Bahia',
          'America/Sao_Paulo',
          'America/Campo_Grande',
          'America/Cuiaba',
          'America/Santarem',
          'America/Porto_Velho',
          'America/Boa_Vista',
          'America/Manaus',
          'America/Eirunepe',
          'America/Rio_Branco',
          'America/Nassau',
          'Asia/Thimphu',
          'Europe/Minsk',
          'America/Belize',
          'America/St_Johns',
          'America/Halifax',
          'America/Glace_Bay',
          'America/Moncton',
          'America/Goose_Bay',
          'America/Blanc-Sablon',
          'America/Toronto',
          'America/Nipigon',
          'America/Thunder_Bay',
          'America/Iqaluit',
          'America/Pangnirtung',
          'America/Atikokan',
          'America/Winnipeg',
          'America/Rainy_River',
          'America/Resolute',
          'America/Rankin_Inlet',
          'America/Regina',
          'America/Swift_Current',
          'America/Edmonton',
          'America/Cambridge_Bay',
          'America/Yellowknife',
          'America/Inuvik',
          'America/Creston',
          'America/Dawson_Creek',
          'America/Fort_Nelson',
          'America/Vancouver',
          'America/Whitehorse',
          'America/Dawson',
          'Indian/Cocos',
          'Europe/Zurich',
          'Africa/Abidjan',
          'Pacific/Rarotonga',
          'America/Santiago',
          'America/Punta_Arenas',
          'Pacific/Easter',
          'Asia/Shanghai',
          'Asia/Urumqi',
          'America/Bogota',
          'America/Costa_Rica',
          'America/Havana',
          'Atlantic/Cape_Verde',
          'America/Curacao',
          'Indian/Christmas',
          'Asia/Nicosia',
          'Asia/Famagusta',
          'Europe/Prague',
          'Europe/Berlin',
          'Europe/Copenhagen',
          'America/Santo_Domingo',
          'Africa/Algiers',
          'America/Guayaquil',
          'Pacific/Galapagos',
          'Europe/Tallinn',
          'Africa/Cairo',
          'Africa/El_Aaiun',
          'Europe/Madrid',
          'Africa/Ceuta',
          'Atlantic/Canary',
          'Europe/Helsinki',
          'Pacific/Fiji',
          'Atlantic/Stanley',
          'Pacific/Chuuk',
          'Pacific/Pohnpei',
          'Pacific/Kosrae',
          'Atlantic/Faroe',
          'Europe/Paris',
          'Europe/London',
          'Asia/Tbilisi',
          'America/Cayenne',
          'Africa/Accra',
          'Europe/Gibraltar',
          'America/Godthab',
          'America/Danmarkshavn',
          'America/Scoresbysund',
          'America/Thule',
          'Europe/Athens',
          'Atlantic/South_Georgia',
          'America/Guatemala',
          'Pacific/Guam',
          'Africa/Bissau',
          'America/Guyana',
          'Asia/Hong_Kong',
          'America/Tegucigalpa',
          'America/Port-au-Prince',
          'Europe/Budapest',
          'Asia/Jakarta',
          'Asia/Pontianak',
          'Asia/Makassar',
          'Asia/Jayapura',
          'Europe/Dublin',
          'Asia/Jerusalem',
          'Asia/Kolkata',
          'Indian/Chagos',
          'Asia/Baghdad',
          'Asia/Tehran',
          'Atlantic/Reykjavik',
          'Europe/Rome',
          'America/Jamaica',
          'Asia/Amman',
          'Asia/Tokyo',
          'Africa/Nairobi',
          'Asia/Bishkek',
          'Pacific/Tarawa',
          'Pacific/Enderbury',
          'Pacific/Kiritimati',
          'Asia/Pyongyang',
          'Asia/Seoul',
          'Asia/Almaty',
          'Asia/Qyzylorda',
          'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
          'Asia/Aqtobe',
          'Asia/Aqtau',
          'Asia/Atyrau',
          'Asia/Oral',
          'Asia/Beirut',
          'Asia/Colombo',
          'Africa/Monrovia',
          'Europe/Vilnius',
          'Europe/Luxembourg',
          'Europe/Riga',
          'Africa/Tripoli',
          'Africa/Casablanca',
          'Europe/Monaco',
          'Europe/Chisinau',
          'Pacific/Majuro',
          'Pacific/Kwajalein',
          'Asia/Yangon',
          'Asia/Ulaanbaatar',
          'Asia/Hovd',
          'Asia/Choibalsan',
          'Asia/Macau',
          'America/Martinique',
          'Europe/Malta',
          'Indian/Mauritius',
          'Indian/Maldives',
          'America/Mexico_City',
          'America/Cancun',
          'America/Merida',
          'America/Monterrey',
          'America/Matamoros',
          'America/Mazatlan',
          'America/Chihuahua',
          'America/Ojinaga',
          'America/Hermosillo',
          'America/Tijuana',
          'America/Bahia_Banderas',
          'Asia/Kuala_Lumpur',
          'Asia/Kuching',
          'Africa/Maputo',
          'Africa/Windhoek',
          'Pacific/Noumea',
          'Pacific/Norfolk',
          'Africa/Lagos',
          'America/Managua',
          'Europe/Amsterdam',
          'Europe/Oslo',
          'Asia/Kathmandu',
          'Pacific/Nauru',
          'Pacific/Niue',
          'Pacific/Auckland',
          'Pacific/Chatham',
          'America/Panama',
          'America/Lima',
          'Pacific/Tahiti',
          'Pacific/Marquesas',
          'Pacific/Gambier',
          'Pacific/Port_Moresby',
          'Pacific/Bougainville',
          'Asia/Manila',
          'Asia/Karachi',
          'Europe/Warsaw',
          'America/Miquelon',
          'Pacific/Pitcairn',
          'America/Puerto_Rico',
          'Asia/Gaza',
          'Asia/Hebron',
          'Europe/Lisbon',
          'Atlantic/Madeira',
          'Atlantic/Azores',
          'Pacific/Palau',
          'America/Asuncion',
          'Asia/Qatar',
          'Indian/Reunion',
          'Europe/Bucharest',
          'Europe/Belgrade',
          'Europe/Kaliningrad',
          'Europe/Moscow',
          'Europe/Simferopol',
          'Europe/Kirov',
          'Europe/Astrakhan',
          'Europe/Volgograd',
          'Europe/Saratov',
          'Europe/Ulyanovsk',
          'Europe/Samara',
          'Asia/Yekaterinburg',
          'Asia/Omsk',
          'Asia/Novosibirsk',
          'Asia/Barnaul',
          'Asia/Tomsk',
          'Asia/Novokuznetsk',
          'Asia/Krasnoyarsk',
          'Asia/Irkutsk',
          'Asia/Chita',
          'Asia/Yakutsk',
          'Asia/Khandyga',
          'Asia/Vladivostok',
          'Asia/Ust-Nera',
          'Asia/Magadan',
          'Asia/Sakhalin',
          'Asia/Srednekolymsk',
          'Asia/Kamchatka',
          'Asia/Anadyr',
          'Asia/Riyadh',
          'Pacific/Guadalcanal',
          'Indian/Mahe',
          'Africa/Khartoum',
          'Europe/Stockholm',
          'Asia/Singapore',
          'America/Paramaribo',
          'Africa/Juba',
          'Africa/Sao_Tome',
          'America/El_Salvador',
          'Asia/Damascus',
          'America/Grand_Turk',
          'Africa/Ndjamena',
          'Indian/Kerguelen',
          'Asia/Bangkok',
          'Asia/Dushanbe',
          'Pacific/Fakaofo',
          'Asia/Dili',
          'Asia/Ashgabat',
          'Africa/Tunis',
          'Pacific/Tongatapu',
          'Europe/Istanbul',
          'America/Port_of_Spain',
          'Pacific/Funafuti',
          'Asia/Taipei',
          'Europe/Kiev',
          'Europe/Uzhgorod',
          'Europe/Zaporozhye',
          'Pacific/Wake',
          'America/New_York',
          'America/Detroit',
          'America/Kentucky/Louisville',
          'America/Kentucky/Monticello',
          'America/Indiana/Indianapolis',
          'America/Indiana/Vincennes',
          'America/Indiana/Winamac',
          'America/Indiana/Marengo',
          'America/Indiana/Petersburg',
          'America/Indiana/Vevay',
          'America/Chicago',
          'America/Indiana/Tell_City',
          'America/Indiana/Knox',
          'America/Menominee',
          'America/North_Dakota/Center',
          'America/North_Dakota/New_Salem',
          'America/North_Dakota/Beulah',
          'America/Denver',
          'America/Boise',
          'America/Phoenix',
          'America/Los_Angeles',
          'America/Anchorage',
          'America/Juneau',
          'America/Sitka',
          'America/Metlakatla',
          'America/Yakutat',
          'America/Nome',
          'America/Adak',
          'Pacific/Honolulu',
          'America/Montevideo',
          'Asia/Samarkand',
          'Asia/Tashkent',
          'America/Caracas',
          'Asia/Ho_Chi_Minh',
          'Pacific/Efate',
          'Pacific/Wallis',
          'Pacific/Apia',
          'Africa/Johannesburg'
        ],

        user_details: null,

        confirming: false
      }
    },
    computed: {
      ...mapState({
        me: state => state.me
      }),
      locationFoundItems () {
        return this.locationEntries
      }
    },
    metaInfo () {
      return {
        title: "Sign Up | " + this.$appName
      }
    },
    methods: {
      isSpecial() {
        return window.location.href.indexOf('_SP') != -1;
      },
  
      async submit(isIgnoreOtp) {
        this.error = null
  
        // CHANGE DIS!
        if (!this.$agency && !this.agree && this.e1 == 3) {
          this.error = "Please agree to our terms and conditions.";
          return;
        }
  
        // if (!this.$agency && typeof(isIgnoreOtp) == "undefined") {
        //   this.e1 = 3;
  
        //   this.sendOtpSms();
  
        //   return;
        // }
  
        let self = this
        this.registering = true
  
        try {
          //await this["auth/signup"]({ first_name: this.first_name, last_name: this.last_name, email: this.email, password: this.password })
          // android/ios chrome autofill fix (access input values directly)
  
          let params = { 
            e1: this.e1,
            company_name: this.company_name.trim(),
            company_type: this.company_type,
            first_name: this.first_name.trim(),
            last_name: this.last_name.trim(),
            email: this.email.trim(),
            phone: this.phone.trim(),
            password: this.password.trim(),
            pull_google_reviews: this.pull_google_reviews,
            timezone: this.timezone,
            is_omni: 1
          };
  
          if (this.company_type == 'agency')
            params.subdomain = this.subdomain.trim()
  
          if (window.location.origin != process.env.VUE_APP_MAIN_URL)
            params.host = window.location.host
  
          if (this.company_type == 'business')
            params.business_location = this.business_location
  
          if (this.selected_plan)
            params.selected_plan = this.selected_plan;
          
          if (this.$cookie.get("RGEmbedWidgetToken"))
            params.RGEmbedWidgetToken = this.$cookie.get("RGEmbedWidgetToken")
          
          if (this.$cookie.get("RGEmbedPlatformURL"))
            params.RGEmbedPlatformURL = this.$cookie.get("RGEmbedPlatformURL")
  
          await this.$store.dispatch("auth/signup", params)
  
          if (this.e1 == 1 || this.e1 == 2) {
            this.e1++;
            this.registering = false;
            return;
          }
  
          // self.$router.push('/signup/confirm')
  
          if (!this.$agency) {
            analytics.track('Signed Up', {
              type: this.company_type,
              first_name: self.first_name.trim(),
              last_name: self.last_name.trim(),
              email: self.email.trim(),
              username: self.email.trim()
            });
  
            window.uipe('track', 'Lead', {
              keys: {
                email: self.email.trim(),
                phone: self.phone.trim(),
              }, 
              lead: {         
                firstName: self.first_name.trim(),    
                lastName: self.last_name.trim(),
                company: self.company_name.trim() || self.company_name.trim(),
                country: (self.business_location) ? self.business_location.country : "US"
              },
            })
          }
  
          setTimeout(function() {
            if (self.selected_plan && self.selected_plan.price > 0 && (typeof(self.selected_plan.use_stripe) == "undefined" || self.selected_plan.use_stripe == 1)) {
              window.top.location = process.env.VUE_APP_API_ENDPOINT + "/billing/checkout/" + self.email.trim() + "?product=" + self.selected_plan.code + "&origin=" + window.location.origin + "&as=1" + "&tid=" + self.getCookie("_fprom_tid");
            }
            else {
              if (self.$cookie.get("RGEmbedWidgetToken"))
                window.top.location = '/widgets/edit/' + self.$cookie.get("RGEmbedWidgetToken");
              else
                window.top.location = '/welcome';
            }
          }, 1000);
  
        }catch(error){
          console.log('got errors')
          console.log(error);
          for (var i in error.errors) {
            if (error.errors[i] != 'null') {
              this.error = (this.error || '') + error.errors[i] + "<br />";
            }
          }
        }
  

        if (this.error.indexOf('email has already')) {
          this.getUserDetails();
        }
        else {
          this.registering = false
        }
      },

      async getUserDetails() {
        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/signup/get-user-details`, {
          email: this.email
        });

        console.log('response', response.data);

        this.user_details = response.data;

        this.registering = false
      },

      async confirmPurchase() {
        this.confirming = true;
        this.error_confirming = false;

        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/signup/confirm-purchase`, {
          email: this.email,
          selected_plan: this.selected_plan,
          password: this.verify_password
        });

        if (response.data.success) {
          // this.$store.state.auth.jwt = response.data.jwt_token;
          // console.log('this.$store.state.auth.jwt', this.$store.state.auth.jwt)

          // this.$router.push('/widgets/contact?');
          // window.top.location = '/widgets/contact?omni_activated=1';

          window.location = process.env.VUE_APP_API_ENDPOINT + "/billing/checkout/" + response.data.user.uuid + "?product=" + this.selected_plan.code + "&origin=" + window.location.origin;
        }
        else {
          this.error_confirming = response.data.message;
        }

        this.confirming = false;
      },

      sendOtpSms() {
        let self = this;
  
        self.verifying_otp = false;
        self.otp_valid = false;
        self.otp_attempted = false;
  
        self.$refs.otpInput.clearInput();
        
        this.otp_generated_code = Math.floor(100000 + Math.random() * 900000);
  
        axios.post(`${process.env.VUE_APP_API_ENDPOINT}/signup/send-otp-code`, {
          phone: this.phone,
          otp: btoa(this.otp_generated_code)
        });
      },
      verifyOtp(value) {
        let self = this;
        this.verifying_otp = true;
  
        if (value == this.otp_generated_code) {
          self.submit(true);
        }
        else {
          self.otp_attempted = true;
          self.verifying_otp = false;
          self.otp_valid = false;
        }
      },
      handleOnChange(value) {
  
      },
      clearOtp() {
        this.otpInput = "";
      },
      getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
      getAddressObject(address_components) {
        var ShouldBeComponent = {
          street_number: ["street_number"],
          zip: ["postal_code"],
          address: ["street_address", "route"],
          state: [
            "administrative_area_level_1",
            "administrative_area_level_2",
            "administrative_area_level_3",
            "administrative_area_level_4",
            "administrative_area_level_5"
          ],
          city: [
            "locality",
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2",
            "sublocality_level_3",
            "sublocality_level_4"
          ],
          country: ["country"]
        };
  
        var address = {
          street_number: "",
          zip: "",
          address: "",
          state: "",
          city: "",
          country: ""
        };
        address_components.forEach(component => {
          for (var shouldBe in ShouldBeComponent) {
            if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
              if (shouldBe === "country") {
                address[shouldBe] = component.short_name;
              } else {
                address[shouldBe] = component.long_name;
              }
            }
          }
        });
        return address;
      },
      filterPlaces (item, queryText, itemText) {
        return true
      },
      selectPlace() {
        let that = this
  
        $.get("https://maps.googleapis.com/maps/api/geocode/json?place_id=" + this.autocompleteLocationModel.id + "&fields=name,rating,formatted_phone_number&key=AIzaSyANqXQPtxZhmpNYyvoBPBrnatNxGMvN9Ks", function(response) {
          that.company_name = that.autocompleteLocationModel.value.split(",")[0];
  
          let address = that.getAddressObject(response.results[0].address_components);
          let business_location = {};
          business_location.street_number = address.street_number;
          business_location.address = address.address;
          business_location.city = address.city;
          business_location.state = address.state;
          business_location.zip = address.zip;
          business_location.country = address.country;
  
          business_location.lat = response.results[0].geometry.location.lat;
          business_location.lng = response.results[0].geometry.location.lng;
          business_location.place_id = response.results[0].place_id;
  
          that.business_location = business_location;
        });
      },
  
      async getSpecialPlanDetails(plan_id) {
        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/signup/get-special-plan-details`, {
          company_type: this.company_type,
          plan_id: this.$route.params.plan_id,
          agency_email: ((this.$agency) ? this.$agency.email : null)
        })
  
        this.selected_plan = response.data.plan
  
        if (!this.selected_plan.currency)
          this.selected_plan.currency = "USD";
        
        if (!this.selected_plan.frequency)
          this.selected_plan.frequency = "month";
  
          // if (!this.$agency.settings.signup_link && !this.$agency.settings.signup_page && this.$agency.settings.allow_stripe_plans_signups) {
          //   if (this.selected_plan.use_stripe == 1) {
              
          //   }
          //   else {
          //     this.$router.push('/login');
          //   }
          // }
          // else {
          //   if (this.$agency.settings.signup_page) {
  
          //   }
          //   else {
          //     this.$router.push('/login');
          //   }
          // }
  
          if (!this.$agency) {
            for (var i in this.rg_plans) {
              if (this.selected_plan.code == this.rg_plans[i].code) {
                this.selected_plan = this.rg_plans[i];
  
                if (this.selected_plan.code != 'white-label' && this.selected_plan.code != 'white-label-yearly' && this.selected_plan.code != 'white-label-special' && this.selected_plan.code != 'white-label-yearly-special'
                    && this.selected_plan.code.indexOf('omni') == -1
                )
                  this.company_type = 'business';
              }
            }
          }
      },
    },
    mounted() {
  
      let self = this
      setTimeout(function() {
        if (self.$agency) {
          self.company_type = "business"
          self.only_business = true
        }
  
        if (self.$route.params.plan_id)
          self.getSpecialPlanDetails(self.$route.params.plan_id)
      }, 0);
  
      setTimeout(function() {
        if (self.$route.query.email)
          self.email = self.$route.query.email
      }, 500);
  
      if (self.$route.params.company_type) {
        if (self.$route.params.company_type == 'b') {
          self.company_type = 'business';
          self.only_business = true
        }
      }
  
      if (self.$agency) {
        if (!self.$agency.settings.signup_link && !self.$agency.settings.signup_page) {
          if (self.$route.params.plan_id) {
            if (!self.$agency.settings.allow_stripe_plans_signups) {
              // self.$router.push('/login');
            }
          }
          else {
            self.$router.push('/login');
          }
        }
      }
      else {
        if (!self.selected_plan) {
          self.selected_plan = self.rg_plans[2];
        }
      }
    },
    watch: {
      locationSearchText (newVal) {
        var that = this;
  
        // If less than 3 chars typed, do not search
        if (!newVal || newVal.length <= 3) return
  
        // Call the method from the previous section here
        GetSuggestions(newVal)
          .then(function (res) {
            that.locationEntries = res;
          })
          .catch(function () {
            that.business = false;
            that.step = 1;
          })
      },
      company_name (newVal) {
        if (!this.only_business) {
          function convertToSlug(Text) {
            return Text.toLowerCase()
                      .replace(/ /g, '')
                      .replace(/[^\w-]+/g, '');
          }
  
          this.subdomain = convertToSlug(newVal);
        }
      },
  
      selected_plan: {
        handler(newval, oldval) {
          let self = this;
  
          if (oldval === null) {
            if (self.$route.params.company_type && self.$route.params.company_type == 'b') {
              this.company_type = 'business';
              this.only_business = true;
              return;
            }
            else {
              if (self.$agency) {
                this.company_type = "business"
                this.only_business = true
                return false;
              }
              else {
                this.company_type = 'agency';
                this.only_business = false;
                return false;
              }
            }
          }
  
          console.log('oldval', oldval);
          console.log('newval', newval);
  
          if (newval.code != 'white-label' && newval.code != 'white-label-yearly' && newval.code != 'white-label-special' && newval.code != 'white-label-yearly-special' && this.selected_plan.code.indexOf('omni') == -1) {
            this.company_type = 'business';
            this.only_business = true;
          }
          else {
            this.company_type = 'agency';
            this.only_business = false;
          }
        }
      },
  
      // selected_plan(oldval, newval) {
      //   console.log('oldval', oldval);
      //   console.log('newval', newval);
  
      //   if (!this.$agency) {
      //     if (newval.code != 'white-label' && newval.code != 'white-label-yearly' && newval.code != 'white-label-special' && newval.code != 'white-label-yearly-special') {
      //       this.company_type = 'business';
      //       this.only_business = true;
      //     }
      //     else {
      //       this.company_type = 'agency';
      //       this.only_business = false;
      //     }
      //   }
      //   else {
      //     this.company_type = 'business';
      //     this.only_business = true;
      //   }
      // }
    }
  }
  </script>
  
  <style scoped>
  
    .token_password_toggle {
      position: absolute;
      margin-top: -30px;
      right: 20px;
    }
    .v-stepper {
      border: 0px !important;
      box-shadow: none !important;
      padding: 0px !important;
    }
  
    .v-stepper__content {
      padding: 0px !important;
    }
  </style>
  
  <style>
    .otp-input {
      width: 40px;
      height: 40px;
      padding: 5px;
      margin: 0 10px;
      font-size: 20px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      text-align: center;
    }
  
    .otp-input.error {
      border: 1px solid red !important;
    }
  
    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  </style>