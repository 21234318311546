<template>

  <div>
    <panel-navigation>

      <div class="float-right ml-5 mt-1">
        <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="$router.push('/contacts/bulk-sms')"><i class="fa fa-arrow-left mr-2"> </i> BACK</div>
      </div>
      
      <h1 class="text-lg font-semibold mb-3">Send Bulk SMS</h1>

      <hr class="border-b border-gray-100 mb-3">

      <v-skeleton-loader v-if="!campaign" type="article, article, article"></v-skeleton-loader>

      <template v-if="me && campaign">
        <div class="dashboard-box">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div class="">
                  <div v-show="true" style="max-width: 700px;">

                    <div v-if="campaign.status == 'running' || campaign.status == 'completed'">
                      <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert" v-html="'You can\'t edit a campaign that is running or completed!'"></div>
                    </div>

                    <div class="w-full">
                      <p class="mb-1 text-xs mb-2"><strong>Campaign Name</strong></p>
                      <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="campaign.title" />
                    </div>

                    <div class="w-full pt-3">
                      <p class="mb-1 text-xs mb-2"><strong>From Number</strong></p>
                      
                      <v-select
                          v-model="campaign.from_number"
                          flat
                          solo-inverted
                          hide-details
                          :items="$agency.integrations.twilio.settings.numbers"
                          label="Choose Number"
                      ></v-select>

                      <div class="text-xs mb-1 text-gray-700 mt-2">Number you are going to use to send broadcast from. Leave this blank and system will use default number related to the contact.</div>
                    </div>

                    <div class="w-full pt-3">
                      <div class="float-right text-xs">

                        <a href="javascript: void(0);" class="text-xs" @click="selectAll()">Select All</a> / <a href="javascript: void(0);" class="text-xs" @click="selectNone()">Select None</a>

                      </div>
                      <p class="mb-1 text-xs mb-2"><strong>Select Contacts</strong></p>
                      
                      <div style="border: solid 1px #CCCCCC; padding: 15px;">
                        <div class="flex">
                          <div class="w-full sm:w-1/2 pr-3">
                            <v-select
                              :items="['In Campaign', 'Finished Campaign', 'Started Review Campaign', 'Started Any Campaign', 'Not Started Review Campaign', 'Not Started Any Campaign']"
                              label="Filter"
                              dense
                              outlined
                              clearable
                              v-model="filter_by"
                            >

                            </v-select>
                          </div>
                          <div v-show="filter_by == 'In Campaign' || filter_by == 'Finished Campaign'" class="w-full sm:w-1/2 pl-3">
                            <v-autocomplete
                              :items="invite_campaigns"
                              label="Select Campaign"
                              dense
                              outlined
                              clearable
                              item-value="id"
                              item-text="name"
                              v-model="filter_by_campaign"
                            >

                            </v-autocomplete>
                          </div>
                        </div>

                        <div style="max-height: 260px; overflow: auto;">
                          <div v-for="(item, index) in contacts" :key="index">
                            <div v-if="item.phone && !excluded_contacts.includes(item.id)" class="pb-1">
                              <div style="float: left;">
                                <v-checkbox v-model="selectedContacts" :value="item.id" hide-details></v-checkbox>
                              </div>

                              <div style="float: left; font-size: 12px; padding-top: 6px;">
                                {{ getContactName(item) }}
                              </div>

                              <div style="clear: both;"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="text-xs pt-2">Selected: <strong>{{ selectedContacts.length }}</strong></div>
                    </div>

                    <div class="w-full pt-3">
                      <div style="float: left;">
                        <p class="mb-1 text-xs mb-2"><strong>When would you like to send?</strong></p>
                        
                        <v-radio-group
                          class="mt-2"
                          row
                          v-model="campaign.send_when"
                          >
                              <v-radio
                              label="Now"
                              :value="'Now'"
                              on-icon="mdi-circle"
                              off-icon="mdi-circle-outline"
                              :ripple="false"
                              ></v-radio>
                              <v-radio
                              label="Later"
                              :value="'Later'"
                              on-icon="mdi-circle"
                              off-icon="mdi-circle-outline"
                              :ripple="false"
                              ></v-radio>
                          </v-radio-group>
                        </div>

                        <div v-if="campaign.send_when == 'Later'" style="float: left; padding-left: 30px; padding-top: 20px;">
                          <date-picker v-model="campaign.scheduled_on" type="datetime" valueType="format"></date-picker>
                        </div>

                        <div v-if="campaign.send_when == 'Later'" style="float: left; padding-left: 30px; padding-top: 16px;">
                          <v-autocomplete
                            class="-mt-1"
                            :items="timezones"
                            label="Timezone"
                            v-model="campaign.timezone"
                            outlined
                            dense
                        ></v-autocomplete>
                        </div>

                        <div style="clear: both;"></div>
                    </div>

                    <div class="w-full">
                      <p class="mb-1 text-xs mb-2"><strong>Message</strong></p>
                      <textarea type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="campaign.message" style="height: 140px;"></textarea>

                      <div class="bg-gray-100 text-xs p-3 mt-3 mb-3 rounded">
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[business_name]</span> will be replaced by your defined business name above</div>
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[contact_first_name]</span> will be replaced by contact's first name</div>
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[contact_last_name]</span> will be replaced by contact's last name</div>
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[contact_email]</span> will be replaced by contact's email address</div>
                          <div class="text-gray-600 mb-2"><span class="font-semibold p-1 text-gray-900">[contact_phone]</span> will be replaced by contact's phone number</div>
                          <!-- <div class="text-gray-600"><span class="font-semibold p-1 text-gray-900">Note!</span> Second and third email/SMS will be sent 1 day later, if contact does not click the link.</div> -->
                      </div>
                    </div>

                    <br clear="all" />

                    <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert" v-html="error"></div>

                  	<br clear="all" />

	                <hr class="mt-3" />

	                <div v-if="campaign.status == 'draft' || campaign.status == 'scheduled'" class="float-right mt-6">
	                  <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': saving}" @click="save">Save</div>
	                </div>

	                <div style="clear: both;"></div>
                  </div>
                </div>
          </div>
          </v-card>
        </div>
      </template>

    </panel-navigation>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import PanelNavigation from '@/components/PanelNavigation.vue'
import moment from 'moment-timezone'
import DialogAppletForm from '@/components/dialogs/get-reviews/dialogAppletForm.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  metaInfo () {
    return {
      title: "Campaign Edit | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    DialogAppletForm,
    DatePicker
  },
  data() {
    return {
      campaign: null,
      saving: false,

      filter_by: "",

      gettingTwilioNumbers: false,

      selectedContacts: [],

      excluded_contacts: [],

      filter_by_campaign: null,

      timezones: [
        'Europe/Andorra',
        'Asia/Dubai',
        'Asia/Kabul',
        'Europe/Tirane',
        'Asia/Yerevan',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia',
        'Pacific/Pago_Pago',
        'Europe/Vienna',
        'Australia/Lord_Howe',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Currie',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Broken_Hill',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Baku',
        'America/Barbados',
        'Asia/Dhaka',
        'Europe/Brussels',
        'Europe/Sofia',
        'Atlantic/Bermuda',
        'Asia/Brunei',
        'America/La_Paz',
        'America/Noronha',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Santarem',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Eirunepe',
        'America/Rio_Branco',
        'America/Nassau',
        'Asia/Thimphu',
        'Europe/Minsk',
        'America/Belize',
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Indian/Cocos',
        'Europe/Zurich',
        'Africa/Abidjan',
        'Pacific/Rarotonga',
        'America/Santiago',
        'America/Punta_Arenas',
        'Pacific/Easter',
        'Asia/Shanghai',
        'Asia/Urumqi',
        'America/Bogota',
        'America/Costa_Rica',
        'America/Havana',
        'Atlantic/Cape_Verde',
        'America/Curacao',
        'Indian/Christmas',
        'Asia/Nicosia',
        'Asia/Famagusta',
        'Europe/Prague',
        'Europe/Berlin',
        'Europe/Copenhagen',
        'America/Santo_Domingo',
        'Africa/Algiers',
        'America/Guayaquil',
        'Pacific/Galapagos',
        'Europe/Tallinn',
        'Africa/Cairo',
        'Africa/El_Aaiun',
        'Europe/Madrid',
        'Africa/Ceuta',
        'Atlantic/Canary',
        'Europe/Helsinki',
        'Pacific/Fiji',
        'Atlantic/Stanley',
        'Pacific/Chuuk',
        'Pacific/Pohnpei',
        'Pacific/Kosrae',
        'Atlantic/Faroe',
        'Europe/Paris',
        'Europe/London',
        'Asia/Tbilisi',
        'America/Cayenne',
        'Africa/Accra',
        'Europe/Gibraltar',
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
        'Europe/Athens',
        'Atlantic/South_Georgia',
        'America/Guatemala',
        'Pacific/Guam',
        'Africa/Bissau',
        'America/Guyana',
        'Asia/Hong_Kong',
        'America/Tegucigalpa',
        'America/Port-au-Prince',
        'Europe/Budapest',
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
        'Europe/Dublin',
        'Asia/Jerusalem',
        'Asia/Kolkata',
        'Indian/Chagos',
        'Asia/Baghdad',
        'Asia/Tehran',
        'Atlantic/Reykjavik',
        'Europe/Rome',
        'America/Jamaica',
        'Asia/Amman',
        'Asia/Tokyo',
        'Africa/Nairobi',
        'Asia/Bishkek',
        'Pacific/Tarawa',
        'Pacific/Enderbury',
        'Pacific/Kiritimati',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Almaty',
        'Asia/Qyzylorda',
        'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Beirut',
        'Asia/Colombo',
        'Africa/Monrovia',
        'Europe/Vilnius',
        'Europe/Luxembourg',
        'Europe/Riga',
        'Africa/Tripoli',
        'Africa/Casablanca',
        'Europe/Monaco',
        'Europe/Chisinau',
        'Pacific/Majuro',
        'Pacific/Kwajalein',
        'Asia/Yangon',
        'Asia/Ulaanbaatar',
        'Asia/Hovd',
        'Asia/Choibalsan',
        'Asia/Macau',
        'America/Martinique',
        'Europe/Malta',
        'Indian/Mauritius',
        'Indian/Maldives',
        'America/Mexico_City',
        'America/Cancun',
        'America/Merida',
        'America/Monterrey',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Chihuahua',
        'America/Ojinaga',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Bahia_Banderas',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Africa/Maputo',
        'Africa/Windhoek',
        'Pacific/Noumea',
        'Pacific/Norfolk',
        'Africa/Lagos',
        'America/Managua',
        'Europe/Amsterdam',
        'Europe/Oslo',
        'Asia/Kathmandu',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'America/Panama',
        'America/Lima',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'Pacific/Gambier',
        'Pacific/Port_Moresby',
        'Pacific/Bougainville',
        'Asia/Manila',
        'Asia/Karachi',
        'Europe/Warsaw',
        'America/Miquelon',
        'Pacific/Pitcairn',
        'America/Puerto_Rico',
        'Asia/Gaza',
        'Asia/Hebron',
        'Europe/Lisbon',
        'Atlantic/Madeira',
        'Atlantic/Azores',
        'Pacific/Palau',
        'America/Asuncion',
        'Asia/Qatar',
        'Indian/Reunion',
        'Europe/Bucharest',
        'Europe/Belgrade',
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Kirov',
        'Europe/Astrakhan',
        'Europe/Volgograd',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr',
        'Asia/Riyadh',
        'Pacific/Guadalcanal',
        'Indian/Mahe',
        'Africa/Khartoum',
        'Europe/Stockholm',
        'Asia/Singapore',
        'America/Paramaribo',
        'Africa/Juba',
        'Africa/Sao_Tome',
        'America/El_Salvador',
        'Asia/Damascus',
        'America/Grand_Turk',
        'Africa/Ndjamena',
        'Indian/Kerguelen',
        'Asia/Bangkok',
        'Asia/Dushanbe',
        'Pacific/Fakaofo',
        'Asia/Dili',
        'Asia/Ashgabat',
        'Africa/Tunis',
        'Pacific/Tongatapu',
        'Europe/Istanbul',
        'America/Port_of_Spain',
        'Pacific/Funafuti',
        'Asia/Taipei',
        'Europe/Kiev',
        'Europe/Uzhgorod',
        'Europe/Zaporozhye',
        'Pacific/Wake',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/Denver',
        'America/Boise',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Adak',
        'Pacific/Honolulu',
        'America/Montevideo',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'America/Caracas',
        'Asia/Ho_Chi_Minh',
        'Pacific/Efate',
        'Pacific/Wallis',
        'Pacific/Apia',
        'Africa/Johannesburg'
        ],

      error: ""
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me,
      invite_campaigns: state => state.campaigns.campaigns,
      campaigns: state => state.bulk_sms.campaigns,
      fetchingCampaigns: state => state.bulk_sms.fetchingCampaigns,
      contacts: state => state.contacts.contacts
    })
  },
  methods: {
    getContactName(contact) {
      let elements = [];

      if (contact.first_name)
        elements.push(contact.first_name);
      
      if (contact.last_name)
        elements.push(contact.last_name);

      if (elements.length == 0)
        if (contact.email)
          elements.push(contact.email);

      elements.push('(' + contact.phone + ')');

      return elements.join(" ");
    },

    selectAll() {
      this.selectedContacts = [];
      for (var i in this.contacts) {
        if (this.contacts[i].phone && !this.excluded_contacts.includes(this.contacts[i].id))
          this.selectedContacts.push(this.contacts[i].id)
      }
    },

    selectNone() {
      this.selectedContacts = [];
    },

    async getBulkCampaign() {
      try {
        let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/bulk-sms/campaign-details?id=' + this.$route.params.id, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

        this.campaign = response.data.campaign;

      }catch(e){
        console.log(e)
      }
    },

    async save() {
    	this.saving = true;
      this.campaign.contacts = this.selectedContacts;
      this.error = false;

      if (!this.campaign.title) {
        this.error = 'Please add a campaign name.';
        this.saving = false;
        return;
      }

      if (!this.campaign.from_number) {
        this.error = 'Please select a from number.';
        this.saving = false;
        return;
      }

      if (this.selectedContacts.length == 0) {
        this.error = 'Please select at least one contact.';
        this.saving = false;
        return;
      }

      if (!this.campaign.message) {
        this.error = 'Please enter message.';
        this.saving = false;
        return;
      }

      try {
        let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/bulk-sms/save', {
        	campaign: this.campaign
        }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

        // this.saving = false;

        this.$router.push('/contacts/bulk-sms');

      }catch(e){
        console.log(e)
      }
    },

    formatDate: function(date) {
      return moment(String(date)).format(this.me.settings.date_format)
    },

    async getTwilioNumbers() {

      this.gettingTwilioNumbers = true

      let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/agency/get-twilio-numbers`, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.gettingTwilioNumbers = false
        this.me.integrations.twilio.settings.numbers = response.data.integration.settings.numbers;
        this.me.integrations.twilio.settings.phone_number = response.data.integration.settings.phone_number;
      }

      this.gettingTwilioNumbers = false

    },

    filterCampaigns() {
      this.selectedContacts = 0;
      this.excluded_contacts = [];

      switch (this.filter_by) {
        case 'In Campaign':
          if (this.filter_by_campaign) {
            for (var i in this.contacts) {
              let contactInCampaign = false;
              for (var j in this.contacts[i].campaigns_to_contact) {
                if (this.contacts[i].campaigns_to_contact[j].log[0].campaign_id == this.filter_by_campaign) {
                  contactInCampaign = true;
                }
              }

              if (!contactInCampaign) {
                this.excluded_contacts.push(this.contacts[i].id);
              }
            }
          }
        break;
        case 'Finished Campaign':
            if (this.filter_by_campaign) {
              for (var i in this.contacts) {
                let contactInCampaign = false;
                for (var j in this.contacts[i].campaigns_to_contact) {
                  if (this.contacts[i].campaigns_to_contact[j].log[0].campaign_id == this.filter_by_campaign && this.contacts[i].campaigns_to_contact[j].status == 'completed') {
                    contactInCampaign = true;
                  }
                }

                if (!contactInCampaign) {
                  this.excluded_contacts.push(this.contacts[i].id);
                }
              }
            }
        break;
        case 'Started Review Campaign':
            for (var i in this.contacts) {
              let contactInCampaign = false;
              for (var j in this.contacts[i].campaigns_to_contact) {
                if (this.contacts[i].campaigns_to_contact[j].log[0].campaign.campaign_type == 'Reviews') {
                  contactInCampaign = true;
                }
              }

              if (!contactInCampaign) {
                this.excluded_contacts.push(this.contacts[i].id);
              }
            }
        break;
        case 'Started Any Campaign':
            for (var i in this.contacts) {
              let contactInCampaign = false;
              for (var j in this.contacts[i].campaigns_to_contact) {
                contactInCampaign = true;
              }

              if (!contactInCampaign) {
                this.excluded_contacts.push(this.contacts[i].id);
              }
            }
        break;
        case 'Not Started Review Campaign':
            for (var i in this.contacts) {
              let contactInCampaign = false;
              for (var j in this.contacts[i].campaigns_to_contact) {
                if (this.contacts[i].campaigns_to_contact[j].log[0].campaign.campaign_type == 'Reviews') {
                  contactInCampaign = true;
                }
              }

              if (contactInCampaign) {
                this.excluded_contacts.push(this.contacts[i].id);
              }
            }
        break;
        case 'Not Started Any Campaign':
            for (var i in this.contacts) {
              let contactInCampaign = false;
              for (var j in this.contacts[i].campaigns_to_contact) {
                contactInCampaign = true;
              }

              if (contactInCampaign) {
                this.excluded_contacts.push(this.contacts[i].id);
              }
            }
        break;
      }

      console.log('this.excluded_contacts', this.excluded_contacts)
    }
  },
  created() {
    this.getBulkCampaign();
    // this.getTwilioNumbers();

    this.$store.dispatch('campaigns/fetch')
    this.$store.dispatch('contacts/fetch')
  },

  watch: {
    filter_by: {
      handler() {
        this.filterCampaigns();
      }
    },

    filter_by_campaign: {
      handler() {
        this.filterCampaigns();
      }
    }
  }
}
</script>


<style type="text/css" scoped>
	.v-icon.v-icon {
	  font-size: 14px !important;
	}

	.remove-applet-btn {
	  background: gray;
	  border: solid 1px #FFFFFF;
	  border-radius: 50%;
	  display: block;
	  position: absolute;
	  width: 20px;
	  height: 20px;
	  line-height: 16px;
	  text-align: center;
	  right: -8px;
	  top: -8px;
	  z-index: 10;
	}

	.v-timeline-item {
	  padding-bottom: 35px;
	}

	.v-timeline-item .v-alert {
	  cursor: pointer;
	}

	.v-timeline-item .remove-applet-btn {
	  display: none;
	}

	.v-timeline-item:hover .remove-applet-btn {
	  display: block;
	}

	.add-applet-btn {
	  position:absolute;
	  z-index: 8;
	}

	.v-timeline .v-timeline-item:nth-child(odd) .add-applet-btn {
	  left: -72px;
	}

	.v-timeline .v-timeline-item:nth-child(even) .add-applet-btn {
	  right: -72px;
	}
</style>
