<template>

  <panel-navigation>

    <v-app-bar v-if="widgetLoaded" app color="" class="header-top px-5" elevation="0" height="80" style="position: absolute; top: 0px;">
        <a href="javascript: void(0);" @click="$router.push('/widgets/contact')" class="mr-6"><i class="mdi mdi-chevron-left text-gray-700" style="font-size: 40px;"></i></a>

        <img v-if="$agency && $agency.settings.favicon" :src="$agency.settings.favicon" alt="" class="mr-6" style="width: 30px; heigh: 30px;" />

        <div v-if="$agency && $agency.settings.favicon">
            <hr role="separator" aria-orientation="vertical" class="mr-6 v-divider v-divider--vertical theme--light" style="height: 60px;" />
        </div>

        <h1 v-if="widget" v-show="!edit_name" class="text-lg font-semibold">{{ widget.name }}</h1>

        <i  v-show="!edit_name" class="mdi mdi-pencil-outline cursor-pointer ml-4" @click="edit_name = true;" style="font-size: 20px;"></i>

        <v-text-field
            v-if="widget"
            v-show="edit_name"
            placeholder="Widget name..."
            hide-details="true"
            v-model="widget.name"
            outlined
            dense
            style="max-width: 300px !important;"
            :append-icon="'mdi-check'"
            @click:append="edit_name = false"
        ></v-text-field>

      <div class="bg-blue text-white text-bold text-xs inline-block rounded py-2 px-3 cursor-pointer float-right" :class="{'spinner': savingWidget}" @click="saveWidget()" style="margin-left: auto;">SAVE WIDGET</div>
    </v-app-bar>

	<v-skeleton-loader v-if="!widgetLoaded && !$route.params.action" type="article, article, article, article, article"></v-skeleton-loader>

	<div id="rg-widget-editor-full-wrapper" v-if="widgetLoaded || $route.params.action" class="bg-white" style="padding-top: 80px;">
        <div class="flex flex-row flex-wrap -mx-3" style="height: 100%;">

            <div class="w-full lg:w-1/3 md:w-1/3 sm:w-1/3 pl-12 pt-6 border-t border-gray-100" style="height: 100%;">
                <h2 class="text-lg font-semibold">Widget Preferences</h2>

                <div class="pr-3">

					<v-tabs>
						<v-tab>Layout</v-tab>
						<v-tab>Settings</v-tab>
						<v-tab>Embed Code</v-tab>

						<v-tab-item>

							<div class="pr-3">
								<p class="mb-1 text-xs mb-2 mt-4"><strong>Widget Type</strong></p>

								<div class="mt-2">
									<v-select
										v-model="widget.settings.widget_type"
										outlined
										dense
										hide-details
										:items="widget_types_values"
										item-value="value"
										item-text="name"
									></v-select>
								</div>
							</div>

							<div style="clear: both;">
								<div style="float: left; width: 30%;">
									<p class="mb-1 text-xs mb-2 mt-2"><strong>Icon <i :class="widget.settings.icon" style="font-size: 20px;"></i></strong></p>

									<div class="pl-4 pt-2">
										<v-icon-picker v-model="widget.settings.icon" ></v-icon-picker>
									</div>
								</div>

								<div style="float: right; width: 65%">
									<p class="mb-1 text-xs mt-4"><strong>Logo</strong></p>

									<v-file-input
										accept="image/*"
										label="Click here to upload"
										style="width: calc(100% - 50px);"
										v-model="file_logo"
										@change="onUploadLogo"
									></v-file-input>

									<div v-if="widget.settings.logo" style="padding-top: 0px;">
										<img :src="widget.settings.logo" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">

										<v-btn
											depressed
											color="error"
											icon
											small
											@click="widget.settings.logo = ''"
										>
											<i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
										</v-btn>
									</div>
								</div>

								<div style="clear: both;"></div>
							</div>

							<div class="flex flex-row flex-wrap">
								<div class="w-full sm:w-1/2 pr-3">
									<p class="mb-1 text-xs mb-2 mt-4"><strong>Primary Color</strong></p>

									<color-picker v-model="widget.settings.primary_color"></color-picker>
								</div>

								<div class="w-full sm:w-1/2 pr-3">
									<p class="mb-1 text-xs mb-2 mt-4"><strong>Secondary Color</strong></p>

									<color-picker v-model="widget.settings.mobile_button_secondary_color"></color-picker>
								</div>
							</div>

							<div class="pr-3">
								<p class="mb-1 text-xs mb-2 mt-4"><strong>Position</strong></p>

								<div class="mt-2">
									<v-select
										v-model="widget.settings.position"
										outlined
										dense
										hide-details
										:items="align_values"
									></v-select>
								</div>
							</div>

							<div class="flex flex-row flex-wrap">
								<div class="w-full sm:w-1/2 pr-3">
									<p class="mb-1 text-xs mb-2 mt-4"><strong>Margin X (px)</strong></p>

									<v-text-field
										type="number"
										v-model="widget.settings.margin_x"
										label=""
										outlined
										dense
										hide-details
									></v-text-field>
								</div>
								<div class="w-full sm:w-1/2 pr-3">
									<div class="pr-4 -mr-4">
										<p class="mb-1 text-xs mb-2 mt-4"><strong>Margin Y (px)</strong></p>

										<v-text-field
											type="number"
											v-model="widget.settings.margin_y"
											label=""
											outlined
											dense
											hide-details
										></v-text-field>
									</div>
								</div>
							</div>

                            <br clear="all" />
                        </v-tab-item>
						
						<v-tab-item class="widget-form">
							<div class="pt-5 rg-widget-settings" v-if="widgetLoaded"
								style="overflow: auto;"
								>

								<v-expansion-panels accordion 
									flat
									dense
									>
									<v-expansion-panel>
										<v-expansion-panel-header>General Settings</v-expansion-panel-header>
										<v-expansion-panel-content class="pt-5">

											<v-switch color="#48bb78" inset dense hide-details :ripple="false"
												v-model="widget.settings.show_on_desktop"
												label="Show widget on Desktop"
											></v-switch>

											<v-switch color="#48bb78" inset dense hide-details :ripple="false"
												v-model="widget.settings.show_on_mobile"
												label="Show widget on Mobile"
											></v-switch>

											<v-switch color="#48bb78" inset dense hide-details :ripple="false"
												v-model="widget.settings.show_durring_off_hours"
												label="Show during off hours"
											></v-switch>

											<p class="mb-1 text-xs mb-2 mt-4"><strong>Business Phone Number </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.phone_number"
												label=""
											></v-text-field>

											<!-- 
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Business Website </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.website_url"
												label=""
											></v-text-field> -->
											
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Timezone </strong></p>

											<v-autocomplete
												class="-mt-1"
												:items="timezones"
												label="Timezone"
												v-model="widget.settings.timezone"
												outlined
												dense
											></v-autocomplete>

										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel class="mt-1">
										<v-expansion-panel-header>Content</v-expansion-panel-header>
										<v-expansion-panel-content>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Header Title </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.header_title"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Gate Text </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.gate_text"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Button </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.call_button"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Text Button </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.text_button"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Text Open </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.call_text_open"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Text Closed </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.call_text_closed"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>SMS Text </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.sms_text"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>SMS Response Title </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.sms_response_title"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>SMS Response Message </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.sms_response_message"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Response Title </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.call_response_title"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Response Message </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.call_response_message"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Response Scheduled </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.call_response_scheduled"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Button Label </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.call_button_label"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Button Schedule Label </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.call_button_schedule_label"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>SMS Label </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.sms_button_label"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Back Button Label </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.back_button_label"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Mobile Number Label </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.mobile_number_label"
												label=""
											></v-text-field>
											<p class="mb-1 text-xs mb-2 mt-4"><strong>Message Label </strong></p>

											<v-text-field
												outlined
												dense
												hide-details
												v-model="widget.settings.message_label"
												label=""
											></v-text-field>
										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel class="mt-1">
										<v-expansion-panel-header>Hours</v-expansion-panel-header>
										<v-expansion-panel-content>
											<div style="padding-top: 10px;">
												<div class="mb-1 text-xs" style="clear: both;">
													<div style="float: left;">Mon:</div>
													
													<div style="float: left; padding-left: 10px; margin-top: -2px;">
														<v-switch color="#48bb78" inset dense hide-details :ripple="false"
															v-model="widget.settings.hours.Mon.open_24_hours"
															@click="selected_view = 2"
															label="Open 24 hours"
														></v-switch>
													</div>
													<div style="clear: both;"></div>
												</div>
												
												<div v-show="!widget.settings.hours.Mon.open_24_hours">
													<div style="float: left;">
														<v-select 
															v-model="widget.settings.hours.Mon.open"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>
													<div style="float: left; padding-left: 10px;">
														<v-select 
															v-model="widget.settings.hours.Mon.close"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>

													<div style="float: left; padding-left: 5px; padding-top: 7px;">
														<v-checkbox v-model="widget.settings.hours.Mon.closed"></v-checkbox>
													</div>
													<div style="float: left; padding-top: 6px;">
														<span class="text-xs">closed</span>
													</div>
													<div style="clear: both;"></div>
												</div>
											</div>

											<div style="padding-top: 10px;">
												<div class="mb-1 text-xs" style="clear: both;">
													<div style="float: left;">Tue:</div>
													
													<div style="float: left; padding-left: 10px; margin-top: -2px;">
														<v-switch color="#48bb78" inset dense hide-details :ripple="false"
															v-model="widget.settings.hours.Tue.open_24_hours"
															@click="selected_view = 2"
															label="Open 24 hours"
														></v-switch>
													</div>
													<div style="clear: both;"></div>
												</div>
												
												<div v-show="!widget.settings.hours.Tue.open_24_hours">
													<div style="float: left;">
														<v-select 
															v-model="widget.settings.hours.Tue.open"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>
													<div style="float: left; padding-left: 10px;">
														<v-select 
															v-model="widget.settings.hours.Tue.close"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>

													<div style="float: left; padding-left: 5px; padding-top: 7px;">
														<v-checkbox v-model="widget.settings.hours.Tue.closed"></v-checkbox>
													</div>
													<div style="float: left; padding-top: 6px;">
														<span class="text-xs">closed</span>
													</div>
													<div style="clear: both;"></div>
												</div>
											</div>

											<div style="padding-top: 10px;">
												<div class="mb-1 text-xs" style="clear: both;">
													<div style="float: left;">Wed:</div>
													
													<div style="float: left; padding-left: 10px; margin-top: -2px;">
														<v-switch color="#48bb78" inset dense hide-details :ripple="false"
															v-model="widget.settings.hours.Wed.open_24_hours"
															@click="selected_view = 2"
															label="Open 24 hours"
														></v-switch>
													</div>
													<div style="clear: both;"></div>
												</div>
												
												<div v-show="!widget.settings.hours.Wed.open_24_hours">
													<div style="float: left;">
														<v-select 
															v-model="widget.settings.hours.Wed.open"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>
													<div style="float: left; padding-left: 10px;">
														<v-select 
															v-model="widget.settings.hours.Wed.close"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>

													<div style="float: left; padding-left: 5px; padding-top: 7px;">
														<v-checkbox v-model="widget.settings.hours.Wed.closed"></v-checkbox>
													</div>
													<div style="float: left; padding-top: 6px;">
														<span class="text-xs">closed</span>
													</div>
													<div style="clear: both;"></div>
												</div>
											</div>

											<div style="padding-top: 10px;">
												<div class="mb-1 text-xs" style="clear: both;">
													<div style="float: left;">Thu:</div>
													
													<div style="float: left; padding-left: 10px; margin-top: -2px;">
														<v-switch color="#48bb78" inset dense hide-details :ripple="false"
															v-model="widget.settings.hours.Thu.open_24_hours"
															@click="selected_view = 2"
															label="Open 24 hours"
														></v-switch>
													</div>
													<div style="clear: both;"></div>
												</div>
												
												<div v-show="!widget.settings.hours.Thu.open_24_hours">
													<div style="float: left;">
														<v-select 
															v-model="widget.settings.hours.Thu.open"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>
													<div style="float: left; padding-left: 10px;">
														<v-select 
															v-model="widget.settings.hours.Thu.close"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>

													<div style="float: left; padding-left: 5px; padding-top: 7px;">
														<v-checkbox v-model="widget.settings.hours.Thu.closed"></v-checkbox>
													</div>
													<div style="float: left; padding-top: 6px;">
														<span class="text-xs">closed</span>
													</div>
													<div style="clear: both;"></div>
												</div>
											</div>

											<div style="padding-top: 10px;">
												<div class="mb-1 text-xs" style="clear: both;">
													<div style="float: left;">Fri:</div>
													
													<div style="float: left; padding-left: 10px; margin-top: -2px;">
														<v-switch color="#48bb78" inset dense hide-details :ripple="false"
															v-model="widget.settings.hours.Fri.open_24_hours"
															@click="selected_view = 2"
															label="Open 24 hours"
														></v-switch>
													</div>
													<div style="clear: both;"></div>
												</div>
												
												<div v-show="!widget.settings.hours.Fri.open_24_hours">
													<div style="float: left;">
														<v-select 
															v-model="widget.settings.hours.Fri.open"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>
													<div style="float: left; padding-left: 10px;">
														<v-select 
															v-model="widget.settings.hours.Fri.close"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>

													<div style="float: left; padding-left: 5px; padding-top: 7px;">
														<v-checkbox v-model="widget.settings.hours.Fri.closed"></v-checkbox>
													</div>
													<div style="float: left; padding-top: 6px;">
														<span class="text-xs">closed</span>
													</div>
													<div style="clear: both;"></div>
												</div>
											</div>

											<div style="padding-top: 10px;">
												<div class="mb-1 text-xs" style="clear: both;">
													<div style="float: left;">Sat:</div>
													
													<div style="float: left; padding-left: 10px; margin-top: -2px;">
														<v-switch color="#48bb78" inset dense hide-details :ripple="false"
															v-model="widget.settings.hours.Sat.open_24_hours"
															@click="selected_view = 2"
															label="Open 24 hours"
														></v-switch>
													</div>
													<div style="clear: both;"></div>
												</div>
												
												<div v-show="!widget.settings.hours.Sat.open_24_hours">
													<div style="float: left;">
														<v-select 
															v-model="widget.settings.hours.Sat.open"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>
													<div style="float: left; padding-left: 10px;">
														<v-select 
															v-model="widget.settings.hours.Sat.close"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>

													<div style="float: left; padding-left: 5px; padding-top: 7px;">
														<v-checkbox v-model="widget.settings.hours.Sat.closed"></v-checkbox>
													</div>
													<div style="float: left; padding-top: 6px;">
														<span class="text-xs">closed</span>
													</div>
													<div style="clear: both;"></div>
												</div>
											</div>

											<div style="padding-top: 10px;">
												<div class="mb-1 text-xs" style="clear: both;">
													<div style="float: left;">Sun:</div>
													
													<div style="float: left; padding-left: 10px; margin-top: -2px;">
														<v-switch color="#48bb78" inset dense hide-details :ripple="false"
															v-model="widget.settings.hours.Sun.open_24_hours"
															@click="selected_view = 2"
															label="Open 24 hours"
														></v-switch>
													</div>
													<div style="clear: both;"></div>
												</div>
												
												<div v-show="!widget.settings.hours.Sun.open_24_hours">
													<div style="float: left;">
														<v-select 
															v-model="widget.settings.hours.Sun.open"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>
													<div style="float: left; padding-left: 10px;">
														<v-select 
															v-model="widget.settings.hours.Sun.close"
															outlined
															dense
															:items="hours_items"
															style="width: 110px;"
															hide-details
														>

														</v-select>
													</div>

													<div style="float: left; padding-left: 5px; padding-top: 7px;">
														<v-checkbox v-model="widget.settings.hours.Sun.closed"></v-checkbox>
													</div>
													<div style="float: left; padding-top: 6px;">
														<span class="text-xs">closed</span>
													</div>
													<div style="clear: both;"></div>
												</div>
											</div>
										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel class="mt-1">
										<v-expansion-panel-header>Mobile</v-expansion-panel-header>
										<v-expansion-panel-content>

											<div class="pt-2">
												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													v-model="widget.settings.enable_mobile_button"
													label="Enable mobile button"
												></v-switch>

												<div v-if="widget.settings.enable_mobile_button">
													<p class="mb-1 text-xs mb-2 mt-4"><strong>Call Button Label </strong></p>

													<v-text-field
														outlined
														dense
														hide-details
														v-model="widget.settings.mobile_button_call_label"
														label=""
													></v-text-field>

													<p class="mb-1 text-xs mb-2 mt-4"><strong>Text Button Label </strong></p>

													<v-text-field
														outlined
														dense
														hide-details
														v-model="widget.settings.mobile_button_text_label"
														label=""
													></v-text-field>

													<p class="mb-1 text-xs mb-2 mt-4"><strong>Message Default Text </strong></p>

													<v-text-field
														outlined
														dense
														hide-details
														v-model="widget.settings.mobile_default_text"
														label=""
													></v-text-field>
												</div>
											</div>

										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel class="mt-1">
										<v-expansion-panel-header>GDPR</v-expansion-panel-header>
										<v-expansion-panel-content>

											<div class="pt-2">
												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													v-model="widget.settings.enable_gdpr"
													label="Enable GDPR/Consent Checkbox (text)"
												></v-switch>

												<div v-if="widget.settings.enable_gdpr">
													<p class="mb-1 text-xs mb-2 mt-4"><strong>GDRP/Legal Disclaimer </strong></p>

													<v-text-field
														outlined
														dense
														hide-details
														v-model="widget.settings.calls_gdpr_text"
														label=""
													></v-text-field>

													<p class="mb-1 text-xs mb-2 mt-4"><strong>Consent Checkbox Text </strong></p>

													<v-text-field
														outlined
														dense
														hide-details
														v-model="widget.settings.sms_gdpr_text"
														label=""
													></v-text-field>
												</div>
											</div>

										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel class="mt-1">
										<v-expansion-panel-header>Fields</v-expansion-panel-header>
										<v-expansion-panel-content>

											<div class="pt-2">
												<p class="mb-1 text-xs mb-2 mt-4"><strong>Mobile Number </strong></p>

												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
												disabled
													label="Enabled"
													v-model="mobile_number_enabled"
												></v-switch>

												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													disabled
													v-model="mobile_number_enabled"
													label="Required"
												></v-switch>
											</div>

											<div class="">
												<p class="mb-1 text-xs mb-2 mt-4"><strong>Name </strong></p>

												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													label="Enabled"
													v-model="widget.settings.show_name_field"
												></v-switch>

												<div v-show="widget.settings.show_name_field">
													<v-switch color="#48bb78" inset dense hide-details :ripple="false"
														label="Required"
														v-model="widget.settings.show_name_required"
													></v-switch>

													<p class="mb-1 text-xs mb-2"><strong>Label </strong></p>

													<v-text-field
														outlined
														dense
														hide-details
														v-model="widget.settings.show_name_label"
														label=""
													></v-text-field>
												</div>
											</div>

											<div class="">
												<p class="mb-1 text-xs mb-2 mt-4"><strong>Email </strong></p>

												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													label="Enabled"
													v-model="widget.settings.show_email_field"
												></v-switch>

												<div v-show="widget.settings.show_email_field">
													<v-switch color="#48bb78" inset dense hide-details :ripple="false"
														label="Required"
														v-model="widget.settings.show_email_required"
													></v-switch>

													<p class="mb-1 text-xs mb-2"><strong>Label </strong></p>

													<v-text-field
														outlined
														dense
														hide-details
														v-model="widget.settings.show_email_label"
														label=""
													></v-text-field>
												</div>
											</div>

										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel class="mt-1">
										<v-expansion-panel-header>Chat bubble</v-expansion-panel-header>
										<v-expansion-panel-content>

											<div class="pt-2">
												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													v-model="widget.settings.tooltip_enabled"
													label="Enable chat bubble"
												></v-switch>

												<div v-if="widget.settings.tooltip_enabled">
													<p class="mb-1 text-xs mb-2 mt-4"><strong>Text </strong></p>

													<v-text-field
														outlined
														dense
														hide-details
														v-model="widget.settings.tooltip_text"
														label=""
													></v-text-field>
												</div>
											</div>

										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel class="mt-1">
										<v-expansion-panel-header>Advanced</v-expansion-panel-header>
										<v-expansion-panel-content>
											<div class="pt-2">
												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													v-model="widget.settings.auto_popup"
													label="Auto Pop-Up"
												></v-switch>

												<div v-show="widget.settings.auto_popup">
													<p class="mb-1 text-xs mb-2 mt-4"><strong>Auto Pop-Up Delay</strong></p>

													<div class="mt-2">
														<v-select
															v-model="widget.settings.auto_popup_delay"
															outlined
															dense
															hide-details
															:items="delay_values"
														></v-select>
													</div>
												</div>
											</div>

											<div class="pt-6">
												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													v-model="widget.settings.popup_on_exit"
													label="Pop-Up on exit intent"
												></v-switch>

												<div v-show="widget.settings.popup_on_exit">
													<v-switch color="#48bb78" inset dense hide-details :ripple="false"
														v-model="widget.settings.popup_on_exit_only_once"
														label="Only Once"
													></v-switch>
												</div>
											</div>

											<div class="pt-6">
												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													v-model="widget.settings.dim_page_on_popup"
													label="Dim page on Pop-Up"
												></v-switch>

												<div v-show="widget.settings.dim_page_on_popup">
													<p class="mb-1 text-xs mb-2 mt-4"><strong>Color</strong></p>

													<color-picker v-model="widget.settings.dim_page_color"></color-picker>
												</div>
											</div>

											<div class="pt-6">
												<v-switch color="#48bb78" inset dense hide-details :ripple="false"
													v-model="widget.settings.redirect_url_on"
													label="Redirect after submission"
												></v-switch>

												<div v-show="widget.settings.redirect_url_on">
													<p class="mb-1 text-xs mb-2 mt-4"><strong>URL</strong></p>

													<v-text-field
														v-model="widget.settings.redirect_url"
														label=""
														outlined
														dense
														hide-details
													></v-text-field>
												</div>
											</div>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</div>
						</v-tab-item>
						
						<v-tab-item>
                            <p class="text-xs text-gray-600">Copy and paste the following code snippet into your website, before the end of the &lt;body&gt; tag.</p>

                            <div class="bg-gray-100 rounded p-1">
                                <div class="tab-pane-content p-2">
                                    <div class="card card-code bg-gray-100 border-0 text-gray-700" style="word-break: break-all; padding: 10px; font-size: 12px; min-height: 100px;">
                                        &lt;script src="{{ getEmbedCode() }}" type="text/javascript"&gt;&lt;/script&gt;

                                        <a
                                            href="javascript: void(0);"
                                            v-clipboard:copy="message"
                                            v-clipboard:success="onCopy"
                                            v-clipboard:error="onError"
                                            class="text-xs float-right"
                                            style="position: absolute; bottom: 0px; right: 10px;"
                                        >
                                            <i class="mdi mdi-content-copy"></i>
                                            Copy
                                        </a>
                                    </div>
                                </div>
                            </div>
						</v-tab-item>
					
					</v-tabs>
      			</div>
			</div>

			<div class="w-full sm:w-2/3 px-3 bg-gray-100 p-3 -mt-7 pb-0" style="height: 100%;">
                <div class="w-full" style="height: 100%;">
                    <div class="pl-4 bg-white" style="height: 100%;">
						<div class="mb-4 pt-4">
							<h2 class="font-bold text-gray-800">Preview</h2>
						</div>
						<div id="rg-widget-preview-wrapper" class="w-full" style="width: 100%; position: relative; overflow: auto; padding-top: 10px;">
							<WidgetsRender :preview_widget="widget" :fw="fw" :key="widgetPreviewKey" ref="widgetsRenderRef" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>

    <!--<div>
      <button class="bg-gray-200 py-2 px-3 text-gray-600 hover:bg-gray-300 rounded" @click="$refs.dialogPlatform.show()"><font-awesome-icon icon="plus-circle" class="mr-1"></font-awesome-icon> Add platform</button>
    </div>-->
  </panel-navigation>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import urlParser from 'url-parse'
import PanelNavigation from '@/components/PanelNavigation.vue'
import WidgetsRender from '@/views/panel/Business/ContactWidgets/Render/Index.vue'
import ColorPicker from '@/components/common/ColorPicker.vue'

export default {
	props: ['force_widget'],

  metaInfo () {
    return {
      title: "Widgets Edit | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
	WidgetsRender,
	ColorPicker
  },
  data () {
    return {
		edit_name: false,

		file_logo: null,

		mobile_number_enabled: true,

		hours_items: [
			'12:00 Am',
			'12:30 AM',
			'1:00 AM',
			'1:30 AM',
			'2:00 AM',
			'2:30 AM',
			'3:00 AM',
			'3:30 AM',
			'4:00 AM',
			'4:30 AM',
			'5:00 AM',
			'5:30 AM',
			'6:00 AM',
			'6:30 AM',
			'7:00 AM',
			'7:30 AM',
			'8:00 AM',
			'8:30 AM',
			'9:00 AM',
			'9:30 AM',
			'10:00 AM',
			'10:30 AM',
			'11:00 AM',
			'11:30 AM',
			'12:00 PM',
			'12:30 PM',
			'1:00 PM',
			'1:30 PM',
			'2:00 PM',
			'2:30 PM',
			'3:00 PM',
			'3:30 PM',
			'4:00 PM',
			'4:30 PM',
			'5:00 PM',
			'5:30 PM',
			'6:00 PM',
			'6:30 PM',
			'7:00 PM',
			'7:30 PM',
			'8:00 PM',
			'8:30 PM',
			'9:00 PM',
			'9:30 PM',
			'10:00 PM',
			'10:30 PM',
			'11:00 PM',
			'11:30 PM'
		],

		timezones: [
        'Europe/Andorra',
        'Asia/Dubai',
        'Asia/Kabul',
        'Europe/Tirane',
        'Asia/Yerevan',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia',
        'Pacific/Pago_Pago',
        'Europe/Vienna',
        'Australia/Lord_Howe',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Currie',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Broken_Hill',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Baku',
        'America/Barbados',
        'Asia/Dhaka',
        'Europe/Brussels',
        'Europe/Sofia',
        'Atlantic/Bermuda',
        'Asia/Brunei',
        'America/La_Paz',
        'America/Noronha',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Santarem',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Eirunepe',
        'America/Rio_Branco',
        'America/Nassau',
        'Asia/Thimphu',
        'Europe/Minsk',
        'America/Belize',
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Indian/Cocos',
        'Europe/Zurich',
        'Africa/Abidjan',
        'Pacific/Rarotonga',
        'America/Santiago',
        'America/Punta_Arenas',
        'Pacific/Easter',
        'Asia/Shanghai',
        'Asia/Urumqi',
        'America/Bogota',
        'America/Costa_Rica',
        'America/Havana',
        'Atlantic/Cape_Verde',
        'America/Curacao',
        'Indian/Christmas',
        'Asia/Nicosia',
        'Asia/Famagusta',
        'Europe/Prague',
        'Europe/Berlin',
        'Europe/Copenhagen',
        'America/Santo_Domingo',
        'Africa/Algiers',
        'America/Guayaquil',
        'Pacific/Galapagos',
        'Europe/Tallinn',
        'Africa/Cairo',
        'Africa/El_Aaiun',
        'Europe/Madrid',
        'Africa/Ceuta',
        'Atlantic/Canary',
        'Europe/Helsinki',
        'Pacific/Fiji',
        'Atlantic/Stanley',
        'Pacific/Chuuk',
        'Pacific/Pohnpei',
        'Pacific/Kosrae',
        'Atlantic/Faroe',
        'Europe/Paris',
        'Europe/London',
        'Asia/Tbilisi',
        'America/Cayenne',
        'Africa/Accra',
        'Europe/Gibraltar',
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
        'Europe/Athens',
        'Atlantic/South_Georgia',
        'America/Guatemala',
        'Pacific/Guam',
        'Africa/Bissau',
        'America/Guyana',
        'Asia/Hong_Kong',
        'America/Tegucigalpa',
        'America/Port-au-Prince',
        'Europe/Budapest',
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
        'Europe/Dublin',
        'Asia/Jerusalem',
        'Asia/Kolkata',
        'Indian/Chagos',
        'Asia/Baghdad',
        'Asia/Tehran',
        'Atlantic/Reykjavik',
        'Europe/Rome',
        'America/Jamaica',
        'Asia/Amman',
        'Asia/Tokyo',
        'Africa/Nairobi',
        'Asia/Bishkek',
        'Pacific/Tarawa',
        'Pacific/Enderbury',
        'Pacific/Kiritimati',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Almaty',
        'Asia/Qyzylorda',
        'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Beirut',
        'Asia/Colombo',
        'Africa/Monrovia',
        'Europe/Vilnius',
        'Europe/Luxembourg',
        'Europe/Riga',
        'Africa/Tripoli',
        'Africa/Casablanca',
        'Europe/Monaco',
        'Europe/Chisinau',
        'Pacific/Majuro',
        'Pacific/Kwajalein',
        'Asia/Yangon',
        'Asia/Ulaanbaatar',
        'Asia/Hovd',
        'Asia/Choibalsan',
        'Asia/Macau',
        'America/Martinique',
        'Europe/Malta',
        'Indian/Mauritius',
        'Indian/Maldives',
        'America/Mexico_City',
        'America/Cancun',
        'America/Merida',
        'America/Monterrey',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Chihuahua',
        'America/Ojinaga',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Bahia_Banderas',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Africa/Maputo',
        'Africa/Windhoek',
        'Pacific/Noumea',
        'Pacific/Norfolk',
        'Africa/Lagos',
        'America/Managua',
        'Europe/Amsterdam',
        'Europe/Oslo',
        'Asia/Kathmandu',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'America/Panama',
        'America/Lima',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'Pacific/Gambier',
        'Pacific/Port_Moresby',
        'Pacific/Bougainville',
        'Asia/Manila',
        'Asia/Karachi',
        'Europe/Warsaw',
        'America/Miquelon',
        'Pacific/Pitcairn',
        'America/Puerto_Rico',
        'Asia/Gaza',
        'Asia/Hebron',
        'Europe/Lisbon',
        'Atlantic/Madeira',
        'Atlantic/Azores',
        'Pacific/Palau',
        'America/Asuncion',
        'Asia/Qatar',
        'Indian/Reunion',
        'Europe/Bucharest',
        'Europe/Belgrade',
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Kirov',
        'Europe/Astrakhan',
        'Europe/Volgograd',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr',
        'Asia/Riyadh',
        'Pacific/Guadalcanal',
        'Indian/Mahe',
        'Africa/Khartoum',
        'Europe/Stockholm',
        'Asia/Singapore',
        'America/Paramaribo',
        'Africa/Juba',
        'Africa/Sao_Tome',
        'America/El_Salvador',
        'Asia/Damascus',
        'America/Grand_Turk',
        'Africa/Ndjamena',
        'Indian/Kerguelen',
        'Asia/Bangkok',
        'Asia/Dushanbe',
        'Pacific/Fakaofo',
        'Asia/Dili',
        'Asia/Ashgabat',
        'Africa/Tunis',
        'Pacific/Tongatapu',
        'Europe/Istanbul',
        'America/Port_of_Spain',
        'Pacific/Funafuti',
        'Asia/Taipei',
        'Europe/Kiev',
        'Europe/Uzhgorod',
        'Europe/Zaporozhye',
        'Pacific/Wake',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/Denver',
        'America/Boise',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Adak',
        'Pacific/Honolulu',
        'America/Montevideo',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'America/Caracas',
        'Asia/Ho_Chi_Minh',
        'Pacific/Efate',
        'Pacific/Wallis',
        'Pacific/Apia',
        'Africa/Johannesburg'
        ],

      ignoreUpdate: false,

	  savingWidget: false,
      saved: false,
      widget: {
		name: "",
      	settings: {
			star_color: "#FFAA00",
			background_color: "#FFFFFF",
			text_color: "#000000",
      		minimum_rating: "5 stars",
      		reviews_sorting: "Most Recent First"
      	}
      },
	  widgetPreviewKey: 1,
	  fw: null,
	  widgetLoaded: false,
	  widget_types_values: [
		  { value: "phone_and_text", name: "Phone and Text" },
		  { value: "phone_only", name: "Phone Only" },
		  { value: "text_only", name: "Text Only" }
	  ],
	  font_family_values: [
		  "Open Sans (Google)",
		  "Source Sans Pro (Google)",
		  "Roboto (Google)",
		  "Lato (Google)",
		  "PT Sans (Google)",
		  "EB Garamond (Google)",
		  "Raleway (Google)",
		  "Ubuntu (Google)",
		  "Montserrat (Google)",
		  "Courgette (Google)",
		  "Arial",
		  "Helvetica",
		  "Sans-serif",
	  ],

	  align_values: [
		  "Left",
		  "Right"
	  ],

	  delay_values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
	  message: '<script src="' + this.getEmbedCode() + '" type="text/javascript"><\/script>',
	  preview_url: ""
    }
  },
  computed: {
    ...mapState({
      listings: state => state.listings.listings,
      fetchingListings: state => state.listings.fetchingListings,
      me: state => state.me.me
    })
  },
  methods: {
	  getMessage2: function() {
		  return '<script type="application/ld+json" class="rg-reviews-schema">' + this.getReviewsSchemaCode2() +  '<\/script>';
	  },
	onCopy: function (e) {
		this.$swal({
			title: "Copied",
			text: "Code copied successfully!",
			showCancelButton: false,
			});
	},
	onError: function (e) {
		alert('Failed to copy the text to the clipboard')
		console.log(e);
	},
    getHostname(url) {
      return urlParser(url).hostname.replace("www.", "")
    },
    getPathname(url) {
      return urlParser(url).pathname
    },
    getIconUrl(url) {
      return require('@/assets/images/review-services/www.' + this.getHostname(url).replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com").replace("tripadvisor.co.uk", "tripadvisor.com").replace("opentable.co.uk", "opentable.com").replace("airbnb.co.uk", "airbnb.com").replace("yellowpages.ca", "yellowpages.com") + ".png");

    },
	getWidget: async function() {
		try {
			// let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/get-widget/' + this.$route.params.token, {

			// } , {
			// headers: {
			// 	'Authorization': 'Bearer ' + this.$store.state.auth.jwt
			// },
			// })

			console.log('this.force_widget', this.force_widget)

			let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/widgets/get-data/' + ((this.$route.params.token == undefined) ? this.force_widget.token : this.$route.params.token), {

			} , {
			headers: {
				'Authorization': 'Bearer ' + this.$store.state.auth.jwt
			},
			})
			
			this.widget = response.data.widget_data.widget
            this.widget_data = response.data.widget_data
			
			// this.widget = response.data.widget
			this.widgetLoaded = true
		}catch(e){
			console.log(e)
		}
	},
	getEmbedCode: function() {
		let self = this

		let host = window.location.host;

		if (this.$agency)
			if (this.$agency.domain)
				host = this.$agency.domain;

		let url = ((process.env.NODE_ENV == 'development') ? "http://localhost:8000" : "https://" + host) + "/js/v1/embed.js?token=" + self.$route.params.token;

		// Dev
		if (process.env.NODE_ENV == 'development') {
			url = url + "&env=dev";
		}

		return url;
	},
	getReviewsSchemaCode: function() {
		// console.log('this.widget_data', this.widget_data);

		return "{<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;\"@context\": \"http://schema.org\",<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;\"@type\": \"Product\",<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;\"name\": \"" + this.widget.name + "\",<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;\"aggregateRating\":<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;{<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"@type\": \"AggregateRating\",<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"ratingValue\": \"" + this.widget_data.average_rating + "\",<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"reviewCount\": \"" + this.widget_data.total_reviews + "\"<br />\
    &nbsp;&nbsp;&nbsp;&nbsp;}<br />\
}";
	},
	getReviewsSchemaCode2: function() {
		// console.log('this.widget_data', this.widget_data);

		return "\n{\n\
    \"@context\": \"http://schema.org\",\n\
    \"@type\": \"Product\",\n\
    \"name\": \"" + this.widget.name + "\",\n\
    \"aggregateRating\":\n\
    {\n\
		\"@type\": \"AggregateRating\",\n\
		\"ratingValue\": \"" + this.widget_data.average_rating + "\",\n\
		\"reviewCount\": \"" + this.widget_data.total_reviews + "\"\n\
    }\n\
}\n";
	},
	saveWidget: async function() {
		this.savingWidget = true
		this.saved = true
		let self = this
		try {
			let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/update-widget/' + ((this.$route.params.token == undefined) ? this.force_widget.token : this.$route.params.token), {
				widget: self.widget
			} , {
			headers: {
					'Authorization': 'Bearer ' + this.$store.state.auth.jwt
				},
			})
			setTimeout(function() {
				self.savingWidget = false
				self.saved = false
			}, 500)
		}catch(e){
			console.log(e)
		}
	},
	updatePreview: function() {
		if (this.widget.template.indexOf('embed') != -1) {
			this.align_values = ["Full"];
			this.widget.settings.align = "Full";
		}
		else {
			if (this.widget.template.indexOf('badge') != -1) {
				if (this.widget.settings.badge_display == "Embed") {
					this.align_values = ["Full"];
					this.widget.settings.align = "Full";
				}
				else {
					this.align_values = ["Left", "Right"];
					if (this.widget.settings.align == "Full")
						this.widget.settings.align = "Left";
				}
			}
			else {
				this.align_values = ["Left", "Right"];
				if (this.widget.settings.align == "Full")
					this.widget.settings.align = "Left";
			}
		}

		let widget_stringified = JSON.stringify(this.widget);

		let widget_encoded = btoa(unescape(encodeURIComponent(widget_stringified)));
		this.fw = widget_encoded;

		this.widgetPreviewKey++;

		// if (document.getElementById('preview_iframe')) {
		// 	let widget_stringified = JSON.stringify(this.widget);
		// 	let widget_encoded = btoa(widget_stringified);
		// 	let new_preview_url = '/widgets/preview/' + ((this.$route.params.token == undefined) ? this.force_widget.token : this.$route.params.token) + "?fw=" + widget_encoded;

		// 	document.getElementById('preview_iframe').src = new_preview_url;
		// }
	},
	previewUrl() {
		let widget_stringified = JSON.stringify(this.widget);
		let widget_encoded = btoa(widget_stringified);
		let new_preview_url = '/widgets/preview/' + ((this.$route.params.token == undefined) ? this.force_widget.token : this.$route.params.token) + "?url=" + encodeURIComponent(this.preview_url);
	
		window.open(new_preview_url)
	},

	async onUploadLogo() {
      if (this.file_logo !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file_logo)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.widget.settings.logo = response.data.url

        }catch(e){
        }

        this.file_logo = null
        this.uploading = false
      }
    },

    setSettings(settings) {
    	this.ignoreUpdate = true;
    	// console.log('setting settingsasssss', settings);
    	this.widget.settings = settings;

    	let self = this;

    	setTimeout(function() {
    		self.ignoreUpdate = false;
    	}, 3000);
    }
  },
  created() {
    this.$store.state.listings.fetchingListings = true
    this.$store.dispatch('listings/fetch', { dashboard: false })
    this.getWidget()

	for (var i in this.widget_templates)
		this.selected_widget_templates.push(JSON.parse(JSON.stringify(this.widget_templates[i])));

	if (this.$route.params.action) {
		setTimeout(function() {
			document.querySelectorAll('.v-tabs div[role="tab"]')[2].click()
		}, 20);
	}

	// let new_height = window.height - 200;
	// document.getElementById("preview_iframe").style.height = new_height + "px";
    
    function setTemplatesHeight() {
        try {
            let new_height = window.outerHeight - 350;
            document.querySelector(".rg-widget-templates").style.height = new_height + "px";
			document.querySelector(".rg-widget-settings").style.height = new_height + "px";
        }
        catch(e) {
            setTimeout(function() {
                setTemplatesHeight();
            }, 100);
        }
    }

    setTemplatesHeight();

	function setPreviewHeight() {
        try {
            let new_height = window.outerHeight - 250;
            document.querySelector("#rg-widget-preview-wrapper").style.height = new_height + "px";
        }
        catch(e) {
            setTimeout(function() {
                setPreviewHeight();
            }, 100);
        }
    }

    setPreviewHeight();

	function setFullHeight() {
        try {
            let new_height = window.outerHeight - 80;
            document.querySelector("#rg-widget-editor-full-wrapper").style.height = new_height + "px";
        }
        catch(e) {
            setTimeout(function() {
                setFullHeight();
            }, 100);
        }
    }

    setFullHeight();
  },
	watch: {
		'widget.template': {
			handler() {
				this.updatePreview()
			}
		},
		'widget.settings': {
			deep: true,

			handler() {
				if (!this.ignoreUpdate)
					this.updatePreview()
			}
		},

		'selected_tags': {
			handler() {
				this.selected_widget_templates = [];

				if (this.selected_tags == 0) {
					for (var i in this.widget_templates)
						this.selected_widget_templates.push(JSON.parse(JSON.stringify(this.widget_templates[i])));
				}
				else {
					for (var i in this.widget_templates) {
						let template = this.widget_templates[i];

						for (var j in this.selected_tags) {
							let tag = this.selected_tags[j];

							if (template.tags.includes(tag))
								this.selected_widget_templates.push(JSON.parse(JSON.stringify(template)));
						}
					}
				}
			}
		}
	}
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Open+Sans&family=Source+Sans+Pro&family=Roboto&family=Lato&family=PT+Sans&family=EB+Garamond&family=Raleway&family=Ubuntu&family=Montserrat&family=Courgette');

  .widget-form input, select {
    border-style: solid;
  }

  .widget-form .has-color-picker button {
  	width: 90px !important;
  }

  .widget-form #preview_iframe {
	  width: 100%;
	  height: 650px;
	  border: solid 1px #CCCCCC;
  }

  .widget-form .v-input--radio-group.v-input--radio-group--row .v-radio {
	  width: 130px;
  }

  .widget-form .v-label {
	  padding-top: 10px !important;
  }

  .widget-form .v-input--selection-controls.v-input--switch {
	  height: 35px;
  }

  .widget-form label {
	  font-weight: bold;
	  font-size: 14px;
	  /* margin-top: 10px; */
  }
</style>