<template>

    <panel-navigation>

        <div class="float-right ml-5 -mt-1">
        
        <div class="justify-between items-start mb-3 float-right ml-3" style="margin-top: -6px;">
            <v-menu
            bottom
            left
            >
            <template v-slot:activator="{ on, attrs }">
                <div
                v-bind="attrs"
                v-on="on"
                class="border-1 border-grey bg-white text-grey text-xs text-bold px-4 py-2 rounded cursor-pointer cursor-pointer" style="padding-top: 9px !important; padding-bottom: 9px !important;">
                    <span class="mr-2">Actions</span>
                    <span class="text-xs" style="padding-top: 3px;">
                        <font-awesome-icon icon="chevron-down"  />
                    </span>
                </div>
            </template>

            <v-list class="table-menu-actions">
                <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="$router.push('/widgets/contact/edit/' + widget.token)"><span class="table-menu-action-icon"><i class="fa fa-edit"></i></span> Edit</a></v-list-item-title></v-list-item>
                <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="$router.push('/widgets/contact/edit/' + widget.token + '/embed')"><span class="table-menu-action-icon"><i class="fa fa-code"></i></span> Get Code</a></v-list-item-title></v-list-item>
                <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="$router.push('/widgets/contact/edit/' + widget.token)"><span class="table-menu-action-icon"><i class="fa fa-eye"></i></span> View</a></v-list-item-title></v-list-item>
                <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="deleteWidget(widget)"><span class="table-menu-action-icon"><i class="fa fa-trash"></i></span> Delete</a></v-list-item-title></v-list-item>
            </v-list>
            </v-menu>
        </div>
        
        <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="$router.push({ name: 'local_recon_widgets'})" style="margin-top: -5px;"><i class="fa fa-arrow-left mr-2"> </i> BACK</div>
        </div>
  
        <h1 v-if="fetchingData" class="text-lg font-semibold mb-3">Widget Details</h1>

        <h1 v-if="!fetchingData && widget" class="text-lg font-semibold mb-3">{{ widget.name }}</h1>
  
        <hr class="border-b border-gray-100 mb-3">
  
        <v-skeleton-loader v-if="fetchingData" type="article, article, article"></v-skeleton-loader>

        <div class="-mx-2" v-if="!fetchingData">
            <div class="flex flex-row flex-wrap mb-4" style="align-items: stretch; flex-wrap: wrap;">
                <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                <v-card
                    class="pa-2"
                    tile
                    style="height: 100%;"
                >
                    <div class="p-3">
		                <div class="dashboard-box-iconsquare">
		                  <div class="dashboard-box-icon-background"></div>
		                  <div class="dashboard-box-icon">
		                    <i class="mdi mdi-widgets-outline"></i>
		                  </div>
		                </div>

		                <div style="text-align: center;">
		                  <div class="pt-2 pb-2">
		                    <strong><router-link :to="'/widgets/contact/view/' + widget.token"><span class="text-gray-900">{{ widget.name }}</span></router-link></strong>
		                  </div>

		                  <div>

		                    <div class="text-sm mb-6 text-gray-600">
		                      {{ formatDate(widget.created_at) }} · {{ formatTemplate(widget.template) }}
		                    </div>

		                    <div class="border-1 border-blue bg-white w-full text-blue text-xs text-bold px-4 py-2 inline-block rounded cursor-pointer" @click="getEmbedCode(widget)" style="margin-top: -5px;"><i class="fa fa-code mr-2"></i> EMBED CODE</div>

		                  </div>
		                </div>

		              </div>
                </v-card>
                </div>

                <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                <v-card
                    class="pa-2"
                    tile
                    style="height: 100%;"
                >
                    <div class="p-3">
                        <div class="flex flex-row flex-wrap mb-4">
                            <div class="w-full px-2 md:w-1/3 lg:w-1/3">
                                <div style="font-size: 12px; padding-top: 5px; text-align: center; color: #828282;">
                                    <strong style="font-size: 30px; color: #5c5c5c;">{{  widget.total_views_count }}</strong>
                                    <br />
                                    Total Views
                                </div>
                            </div>

                            <div class="w-full px-2 md:w-1/3 lg:w-1/3">
                                <div style="font-size: 12px; padding-top: 5px; text-align: center; color: #828282;">
                                    <strong style="font-size: 30px; color: #5c5c5c;">{{  widget.unique_views_count }}</strong>
                                    <br />
                                    Unique Views
                                </div>
                            </div>

                            <div class="w-full px-2 md:w-1/3 lg:w-1/3">
                                <div style="font-size: 12px; padding-top: 5px; text-align: center; color: #828282;">
                                    <strong style="font-size: 30px; color: #5c5c5c;">{{  widget.contacts_count }}</strong>
                                    <br />
                                    Contacts
                                </div>
                            </div>

                            <div class="w-full px-2 md:w-1/3 lg:w-1/3">
                                <div style="font-size: 12px; padding-top: 20px; text-align: center; color: #828282;">
                                    <strong style="font-size: 30px; color: #5c5c5c;">{{  widget.call_requests_count }}</strong>
                                    <br />
                                    Call Requests
                                </div>
                            </div>

                            <div class="w-full px-2 md:w-1/3 lg:w-1/3">
                                <div style="font-size: 12px; padding-top: 20px; text-align: center; color: #828282;">
                                    <strong style="font-size: 30px; color: #5c5c5c;">{{  widget.sms_count }}</strong>
                                    <br />
                                    SMS
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
                </div>

                <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                <v-card
                    class="pa-2"
                    tile
                    style="height: 100%;"
                >
                    <div class="p-3" style="padding-bottom: 18px !important;">
                        <div class="dashboard-box-title">Quick Actions</div>
                        
                        <table style="width: 100%; font-size: 14px; line-height: 40px; margin-left: 15px;">
                            <tr>
                                <th><i class="mdi mdi-chevron-right" style="font-size: 15px;"></i> <a href="javascript: void(0);" @click="$router.push('/widgets/contact/edit/' + widget.token + '/embed')">Get Code</a></th>
                            </tr>
                            <tr>
                                <th><i class="mdi mdi-chevron-right" style="font-size: 15px;"></i> <a href="javascript: void(0);" @click="$router.push('/widgets/contact/edit/' + widget.token)">View Widget</a></th>
                            </tr>
                            <tr>
                                <th><i class="mdi mdi-chevron-right" style="font-size: 15px;"></i> <a href="javascript: void(0);" @click="$router.push('/widgets/contact/edit/' + widget.token)">Edit Widget</a></th>
                            </tr>
                        </table>

                    </div>
                </v-card>
                </div>
            </div>

            <div class="flex flex-row flex-wrap mb-4" style="align-items: stretch; flex-wrap: wrap;">
                <div class="dashboard-box  stats-box w-full px-2">
                    <v-card
                        class="pa-2"
                        tile
                    >
                        <div class="p-3">
                            <apexchart height="250" type="line" :options="options" :series="series" :key="chartKey"></apexchart>
                        </div>
                    </v-card>
                </div>
            </div>

            <div class="flex flex-row flex-wrap mb-4" style="align-items: stretch; flex-wrap: wrap;">
                <div class="dashboard-box  stats-box w-full px-2">
                    <v-card
                        class="pa-2"
                        tile
                    >
                        <div class="p-3">
                            <div class="w-full mb-4">
                            	<div style="font-size: 16px; font-weight: bold; margin-bottom: 20px;">Contacts</div>

                                <div v-if="widget.contacts_list.length == 0">
                                    <br /><br /><br /><br />

                                    <div class="dashboard-box-iconsquare">
                                    <div class="dashboard-box-icon-background"></div>
                                    <div class="dashboard-box-icon">
                                        <i class="mdi mdi-account-multiple"></i>
                                    </div>
                                    </div>

                                    <div style="text-align: center;">
                                        <div class="pt-2 pb-2">
                                            <strong>No Contacts Yet</strong>
                                        </div>
                                    </div>
                                    
                                    <br /><br /><br /><br /><br />
                                </div>

                                <div v-else>
                                	<v-data-table
					                  :headers="headers"
					                  :items="widget.contacts_list"
					                  :items-per-page="20"
					                  class="rg-datatable elevation-1"
					                  :footer-props="{
					                  itemsPerPageOptions: [1, 5, 10, 20, 30, 40, 50, 100],
					                  showFirstLastPage: true
					                  }"
					                  mobile-breakpoint="10"
					                >
					                  <template v-slot:item.first_name="{ item }">
					                    <span :class="{'line-through text-gray-400': item.archived}">
					                      <router-link :to="'/contacts/' + item.id">{{ getContactName(item) }}</router-link>
					                    </span>
					                      
					                      <div v-if="item.survey_data">
					                        <v-menu offset-y 
					                        nudge-top="-35">
					                          <template v-slot:activator="{ on, attrs }">
					                            <a href="javascript: void(0)"
					                              v-bind="attrs"
					                              v-on="on"
					                            >survey</a>
					                          </template>
					                          <div style="background: #FFFFFF; padding: 10px; font-size: 14px;">
					                            <div v-for="(question, index) in item.survey_data.questions" :key="index">
					                              <div><strong>Q: {{ question.question }}</strong></div>
					                              <div>
					                                A: {{ question.answer || "-" }}
					                              </div>
					                              <br clear="all" />
					                            </div>

					                            <div>
					                              <div><strong>Additional Comments</strong></div>
					                              {{ item.survey_data.additional_comments || "-" }}
					                              <br clear="all" /><br clear="all" />
					                            </div>

					                            <div>
					                              <div><strong>Happy?</strong></div>
					                              {{ item.survey_data.happy }}
					                            </div>
					                          </div>
					                        </v-menu>
					                      </div>

					                      <div v-if="item.custom_fields && item.custom_fields != '[]' && item.custom_fields != 'null'">
					                        <v-menu offset-y
					                        nudge-top="-35">
					                          <template v-slot:activator="{ on, attrs }">
					                            <a href="javascript: void(0)"
					                              v-bind="attrs"
					                              v-on="on"
					                            >custom fields</a>
					                          </template>
					                          <div style="background: #FFFFFF; padding: 10px; font-size: 14px;" v-html="displayCustomFields(item.custom_fields)"></div>
					                        </v-menu>
					                      </div>
					                  </template>

					                  <template v-slot:item.email="{ item }">
					                    <span :class="{'line-through text-gray-400': item.archived}">{{ item.email ? item.email : '-' }}</span>
					                  </template>

					                  <template v-slot:item.phone="{ item }">
					                    <router-link v-if="item.phone" :to="'/contacts/' + item.id">{{ item.phone ? item.phone : '-' }}</router-link>
					                    <span v-else :class="{'line-through text-gray-400': item.archived}">{{ item.phone ? item.phone : '-' }}</span>
					                  </template>

					                  <template v-slot:item.firstSeenAt="{ item }">
					                    {{ fromNow(item.firstSeenAt) }}</router-link>
					                  </template>
					                </v-data-table>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </div>
            </div>
        </div>
      
    </panel-navigation>
  
  </template>
  
  <script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import urlParser from 'url-parse'
  import PanelNavigation from '@/components/PanelNavigation.vue'
  import Editable from '@/components/common/Editable';
  import ColorPicker from '@/components/common/ColorPicker.vue'
  import moment from 'moment'

  export default {
    metaInfo () {
      return {
        title: "Widget Detail | " + this.$appName
      }
    },
    components: {
      PanelNavigation,
      Editable,
      ColorPicker
    },
    data () {
      return {
        fetchingData: true,
        widget: null,

          options: {
                chart: {
                id: 'vuechart-example',
                toolbar: {
                    show: false
                },
                legend: {
                    show: true
                }
                },
                xaxis: {
                categories: [],
                tickAmount: 10,
                labels: {
                        rotate: 0
                    }
                }
            },
            series: [],

            chartKey: 0,

            headers: [
		        { text: 'Name', value: 'first_name' },
		        { text: 'Email', value: 'email' },
		        { text: 'Phone', value: 'phone' },
		        { text: 'Customer Age', value: 'firstSeenAt' },
		    ],
      }
    },
    computed: {
      ...mapState({
        me: state => state.me.me,
        profile: state => state.me.profile
      })
    },
    methods: {
        getWidget: async function() {
          try {
              let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/get-widget/' + this.$route.params.token, {
                detail_page: 1
              } , {
	              headers: {
	                  'Authorization': 'Bearer ' + this.$store.state.auth.jwt
	              },
              })

              this.widget = response.data.widget;

              console.log('response.data.chart_data.labels', response.data.chart_data.labels)
              console.log('response.data.chart_data.datasets', response.data.chart_data.datasets)

              this.options.xaxis.categories = response.data.chart_data.labels;
              this.options.xaxis.series = [];

              for (var url in response.data.chart_data.datasets) {
                this.series.push({
                    name: url,
                    data: response.data.chart_data.datasets[url]
                });
              }

              this.chartKey++;
  
              this.fetchingData = false
              console.log(this.widget)
          }catch(e){
              console.log(e)
          }
      },

	    fromNow(date) {
	      return moment.utc(date).local().fromNow();
	    },
      getContactName(contact) {
	      let elements = [];

	      if (contact.first_name)
	        elements.push(contact.first_name);
	      
	      if (contact.last_name)
	        elements.push(contact.last_name);

	      // if (elements.length == 0)
	      //   if (contact.email)
	      //     elements.push(contact.email);

	      // if (elements.length == 0)
	      //   if (contact.phone)
	      //     elements.push(contact.phone);

	      if (elements.length == 0)
	      	return "(No Name)";

	      return elements.join(" ");
	    },
      numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      getEmbedCode: function(widget) {
	      this.$router.push('/widgets/contact/edit/' + widget.token + "/embed");
	    },
      async deleteWidget(widget) {
        await this.$store.dispatch('local_recon_widgets/delete', widget.id)
        this.$store.dispatch('local_recon_widgets/fetch', { dashboard: false })

        this.$router.push('/widgets');
      
        },
        formatDate: function(date) {
            return moment(String(date)).format('MMM DD, YYYY')
        },
        formatTemplate: function(template) {
            if (template == 'default')
                return "Business Audit Widget";

            return template.replace("-", " ");
        },
        onCopy: function (e) {
            this.$swal({
                title: "Copied",
                text: "URL copied successfully!",
                showCancelButton: false,
                });
        },
        onError: function (e) {
            alert('Failed to copy the text to the clipboard')
            console.log(e);
        },
    },
    created() {
      this.getWidget()
  
      
    },
      watch: {

      }
  }
  </script>
  
  <style scoped>
    
  </style>