<template>

    <panel-navigation>

        <div class="rg-top-filter float-right -mt-3 ml-3">
          <v-text-field
              :value="formatDate(date_filter[0], 'MMM DD, YYYY') + '   -   ' + ((date_filter[1]) ? formatDate(date_filter[1], 'MMM DD, YYYY') : '')"
              append-icon="mdi-calendar-blank-outline"
              readonly
              outlined
              dense
              style="width: 230px; background: #FFFFFF; border: 0px !important;"
              hide-details
              placholder="Choose Date"
              @click="show_picker = !show_picker"
          ></v-text-field>
          
          <div v-if="show_picker" style="position: absolute; width: 490px; z-index: 999; margin-left: -260px; background: #FFFFFF; padding: 10px; margin-top: 5px; border-radius: 10px; box-shadow: 0px 0px 30px 0px #E2E2E2;">
            <div style="float: left; border-right: solid 1px #d5d5d5;">
              <div class="mb-2"><strong class="text-sm">Filter by Date</strong></div>

              <v-date-picker
              v-model="date_filter"
              range
              no-title
              scrollable
              @input="menu = false"
              style="border: 0px;"
              >
              <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="#44468F"
                      @click="show_picker = false"
                  >
                      Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="#44468F"
                      @click="filterDate();"
                  >
                      UPDATE
                  </v-btn>
              </v-date-picker>
            </div>

            <div style="float: left; margin-left: 20px; padding-left: 20px; padding-top: 20px;">
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('This Week')" :style="((selected_date_text == 'This Week') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">This Week</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last Week')" :style="((selected_date_text == 'Last Week') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last Week</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('This Month')" :style="((selected_date_text == 'This Month') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">This Month</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last Month')" :style="((selected_date_text == 'Last Month') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last Month</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last 30 days')" :style="((selected_date_text == 'Last 30 days') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last 30 days</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last 60 days')" :style="((selected_date_text == 'Last 60 days') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last 60 days</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last 90 days')" :style="((selected_date_text == 'Last 90 days') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last 90 days</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('This Year')" :style="((selected_date_text == 'This Year') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">This Year</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last Year')" :style="((selected_date_text == 'Last Year') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last Year</a></div>
              <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('All Time')" :style="((selected_date_text == 'All Time') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">All Time</a></div>
            </div>

            <div style="clear: both;"></div>
          </div>
      </div>

        <div class="float-right ml-5 -mt-1">
        
        <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="$router.push({ name: 'contact_widgets'})" style="margin-top: -5px;"><i class="fa fa-arrow-left mr-2"> </i> BACK</div>
        </div>
  
        <h1 class="text-lg font-semibold mb-3">Analytics</h1>
  
        <hr class="border-b border-gray-100 mb-3">
  
        <v-skeleton-loader v-if="fetchingData" type="article, article, article"></v-skeleton-loader>

        <div class="-mx-2" v-if="!fetchingData">

            <div class="dashboard-box">
                <v-card
                    class="pa-2"
                    tile
                    style="height: 100%;"
                >
                    <v-tabs>
                        <v-tab @click="selected_tab = 'Calls'">Calls</v-tab>
                        <v-tab  @click="selected_tab = 'Messages'">Messages</v-tab>
                    </v-tabs>
                </v-card>
            </div>

            <div class="pt-3">
                <div v-if="selected_tab == 'Calls'">
                    <div class="-mx-2">
                        <div class="flex flex-row flex-wrap mb-4">
                            <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Total Call Duration</div>

                                        <div class="stats-box-number">{{ toDuration(analytics.calls.total_call_duration) }}</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-clock-outline"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Average Call Duration</div>

                                        <div class="stats-box-number">{{ toDuration(analytics.calls.average_call_duration) }}</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-clock-outline"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Average Time To Answer</div>

                                        <div class="stats-box-number">{{ toDuration(analytics.calls.average_time_to_answer) }}</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-clock-outline"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/2 lg:w-1/2 mt-4">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Total Calls / Connected Calls</div>

                                        <div class="stats-box-number">{{ analytics.calls.total_calls }} / {{ analytics.calls.connected_calls }} ({{ analytics.calls.connected_calls_percentage }}%)</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-phone-outline"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/2 lg:w-1/2 mt-4">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Widget Views / Unique views</div>

                                        <div class="stats-box-number">{{ analytics.calls.widget_views }} / {{ analytics.calls.widget_unique_views }} ({{ analytics.calls.widget_unique_views_percentage }}%)</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-chart-bar"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard-box">
                        <v-card
                            class="pa-2"
                            tile
                            style="height: 100%;"
                        >
                            <div class="p-2">
                                <strong>Calls</strong>

                                <apexchart height="250" type="line" :options="calls_chart_options" :series="calls_chart_series" :key="calls_chart_key"></apexchart>
                            </div>
                        </v-card>
                    </div>

                    <div class="-mx-2 mt-4">
                        <div class="flex flex-row flex-wrap mb-4">
                            <div class="dashboard-box stats-box w-full px-2 md:w-2/3 lg:w-2/3">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-2">
                                        <strong>Time of Day Statistics</strong>

                                        <apexchart height="250" type="bar" :options="calls_time_of_day_chart_options" :series="calls_time_of_day_chart_series" :key="calls_time_of_day_chart_key"></apexchart>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div style="clear: both; font-size: 16px; padding: 22px;">
                                            <div style="float: left;">Initiated</div>
                                            <div style="float: right; font-weight: bold;">{{ analytics.calls.total_calls }}</div>

                                            <div style="clear: both;"></div>
                                        </div>

                                        <div style="clear: both; font-size: 16px; padding: 22px;">
                                            <div style="float: left;">Answered</div>
                                            <div style="float: right; font-weight: bold;">{{ analytics.calls.answered_calls }}</div>

                                            <div style="clear: both;"></div>
                                        </div>

                                        <div style="clear: both; font-size: 16px; padding: 22px;">
                                            <div style="float: left;">Connected</div>
                                            <div style="float: right; font-weight: bold;">{{ analytics.calls.connected_calls }}</div>

                                            <div style="clear: both;"></div>
                                        </div>

                                        <div style="clear: both; font-size: 16px; padding: 22px;">
                                            <div style="float: left;">Not Connected</div>
                                            <div style="float: right; font-weight: bold;">{{ analytics.calls.not_connected_calls }}</div>

                                            <div style="clear: both;"></div>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="selected_tab == 'Messages'">
                    <div class="-mx-2">
                        <div class="flex flex-row flex-wrap mb-4">
                            <div class="dashboard-box stats-box w-full px-2 md:w-1/4 lg:w-1/4">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Texts Sent</div>

                                        <div class="stats-box-number">{{ analytics.messages.texts_sent }}</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-message"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/4 lg:w-1/4">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Texts Received</div>

                                        <div class="stats-box-number">{{ analytics.messages.texts_received }}</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-message"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/4 lg:w-1/4">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Texts Failed</div>

                                        <div class="stats-box-number">{{ analytics.messages.texts_failed }}</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-stop"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/4 lg:w-1/4">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div class="dashboard-box-title">Broadcasts Sent</div>

                                        <div class="stats-box-number">{{ analytics.messages.broadcasts_sent }}</div>
                                        
                                        <div class="stats-box-icon-bg"></div>
                                        <div class="stats-box-icon">
                                            <i class="mdi mdi-broadcast"></i>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard-box">
                        <v-card
                            class="pa-2"
                            tile
                            style="height: 100%;"
                        >
                            <div class="p-2">
                                <strong>Messages</strong>

                                <apexchart height="250" type="line" :options="messages_chart_options" :series="messages_chart_series" :key="messages_chart_key"></apexchart>
                            </div>
                        </v-card>
                    </div>

                    <div class="-mx-2 mt-4">
                        <div class="flex flex-row flex-wrap mb-4">
                            <div class="dashboard-box stats-box w-full px-2 md:w-2/3 lg:w-2/3">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-2">
                                        <strong>Time of Day Statistics</strong>

                                        <apexchart height="250" type="bar" :options="messages_time_of_day_chart_options" :series="messages_time_of_day_chart_series" :key="messages_time_of_day_chart_key"></apexchart>
                                    </div>
                                </v-card>
                            </div>

                            <div class="dashboard-box stats-box w-full px-2 md:w-1/3 lg:w-1/3">
                                <v-card
                                    class="pa-2"
                                    tile
                                >
                                    <div class="p-3">
                                        <div style="clear: both; font-size: 16px; padding: 22px; padding-top: 34px; padding-bottom: 34px;">
                                            <div style="float: left;">Texts Sent</div>
                                            <div style="float: right; font-weight: bold;">{{ analytics.messages.texts_sent }}</div>

                                            <div style="clear: both;"></div>
                                        </div>

                                        <div style="clear: both; font-size: 16px; padding: 22px; padding-top: 34px; padding-bottom: 34px;">
                                            <div style="float: left;">Texts Received</div>
                                            <div style="float: right; font-weight: bold;">{{ analytics.messages.texts_received }}</div>

                                            <div style="clear: both;"></div>
                                        </div>

                                        <div style="clear: both; font-size: 16px; padding: 22px; padding-top: 34px; padding-bottom: 34px;">
                                            <div style="float: left;">Texts Failed</div>
                                            <div style="float: right; font-weight: bold;">{{ analytics.messages.texts_failed }}</div>

                                            <div style="clear: both;"></div>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
      
    </panel-navigation>
  
  </template>
  
  <script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import urlParser from 'url-parse'
  import PanelNavigation from '@/components/PanelNavigation.vue'
  import Editable from '@/components/common/Editable';
  import ColorPicker from '@/components/common/ColorPicker.vue'
  import moment from 'moment'

  export default {
    metaInfo () {
      return {
        title: "Contact Widgets Analytics | " + this.$appName
      }
    },
    components: {
      PanelNavigation,
      Editable,
      ColorPicker
    },
    data () {
      return {
        date_filter: [
              moment().subtract(30, 'days').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD')
        ],

        show_picker: false,

        selected_date_text: "Last 30 days",

        fetchingData: true,
        analytics: null,

        selected_tab: "Calls",

        calls_chart_options: {
            chart: {
                id: 'vuechart-example',
                toolbar: {
                    show: false
                },
                legend: {
                    show: true
                }
                },
                xaxis: {
                categories: [],
                tickAmount: 10,
                labels: {
                    rotate: 0
                }
            }
        },
        calls_chart_series: [],

        calls_chart_key: 0,

        calls_time_of_day_chart_options: {
            chart: {
                id: 'vuechart-example',
                toolbar: {
                    show: false
                },
                legend: {
                    show: true
                }
                },
                xaxis: {
                categories: [],
                tickAmount: 10,
                labels: {
                    rotate: 0
                }
            }
        },
        calls_time_of_day_chart_series: [],

        calls_time_of_day_chart_key: 0,

        messages_chart_options: {
            chart: {
                id: 'vuechart-example',
                toolbar: {
                    show: false
                },
                legend: {
                    show: true
                }
                },
                xaxis: {
                categories: [],
                tickAmount: 10,
                labels: {
                    rotate: 0
                }
            }
        },
        messages_chart_series: [],

        messages_chart_key: 0,

        messages_time_of_day_chart_options: {
            chart: {
                id: 'vuechart-example',
                toolbar: {
                    show: false
                },
                legend: {
                    show: true
                }
                },
                xaxis: {
                categories: [],
                tickAmount: 10,
                labels: {
                    rotate: 0
                }
            }
        },
        messages_time_of_day_chart_series: [],

        messages_time_of_day_chart_key: 0,
      }
    },
    computed: {
      ...mapState({
        me: state => state.me.me,
        profile: state => state.me.profile
      })
    },
    methods: {
        filterDate() {
            console.log(this.date_filter.length);
            if (!this.date_filter[1])
                return;

            this.show_picker = false;
            this.selected_date_text = '';
            this.getAnalytics();
        },

      applyDate(selected_date) {
        // console.log('selected_date', selected_date);
        this.selected_date_text = selected_date;
        switch (selected_date) {
          case 'This Week':
            this.date_filter[0] = moment().startOf('week').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('week').format('YYYY-MM-DD');
          break;
          case 'Last Week':
            this.date_filter[0] = moment().startOf('week').subtract(7, 'days').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('week').subtract(7, 'days').format('YYYY-MM-DD');
          break;
          case 'This Month':
            this.date_filter[0] = moment().startOf('month').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('month').format('YYYY-MM-DD');
          break;
          case 'Last Month':
            this.date_filter[0] = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD');
          break;
          case 'Last 30 days':
            this.date_filter[0] = moment().subtract(30, 'days').format('YYYY-MM-DD');
            this.date_filter[1] = moment().format('YYYY-MM-DD');
          break;
          case 'Last 60 days':
            this.date_filter[0] = moment().subtract(60, 'days').format('YYYY-MM-DD');
            this.date_filter[1] = moment().format('YYYY-MM-DD');
          break;
          case 'Last 90 days':
            this.date_filter[0] = moment().subtract(90, 'days').format('YYYY-MM-DD');
            this.date_filter[1] = moment().format('YYYY-MM-DD');
          break;
          case 'This Year':
            this.date_filter[0] = moment().startOf('year').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('year').format('YYYY-MM-DD');
          break;
          case 'Last Year':
            this.date_filter[0] = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
          break;

          case 'All Time':
            this.date_filter[0] = moment().startOf('year').subtract(10, 'years').format('YYYY-MM-DD');
            this.date_filter[1] = moment().format('YYYY-MM-DD');
          break;
        }
        // console.log(this.date_filter);
        this.show_picker = false;
        this.getAnalytics();
      },

        toDuration(duration) {
            var date = new Date(0);
            date.setSeconds(duration); // specify value for SECONDS here
            var timeString = date.toISOString().substring(14, 19);
            return timeString;
        },
        
        getAnalytics: async function() {
            this.selected_tab = 'Calls';
            this.fetchingData = true;
            let self = this
          try {
              let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/get-contact-analytics?date_start=' + this.date_filter[0] + '&date_end=' + this.date_filter[1] + ' 23:59:59', {
                
              } , {
	              headers: {
	                  'Authorization': 'Bearer ' + this.$store.state.auth.jwt
	              },
              })

              this.analytics = response.data.analytics;

            //   console.log('response.data.chart_data.labels', response.data.chart_data.labels)
            //   console.log('response.data.chart_data.datasets', response.data.chart_data.datasets)

            this.calls_chart_options.xaxis.categories = this.analytics.calls.calls_chart.labels;
            this.calls_chart_options.xaxis.series = [];

            this.calls_chart_series = [];
            for (var url in this.analytics.calls.calls_chart.datasets) {
                this.calls_chart_series.push({
                    name: url,
                    data: this.analytics.calls.calls_chart.datasets[url]
                });
            }

            setTimeout(function() {
                self.calls_chart_key++;
            }, 200);

            this.calls_time_of_day_chart_options.xaxis.categories = this.analytics.calls.time_of_day_chart.labels;
            this.calls_time_of_day_chart_options.xaxis.series = [];

            this.calls_time_of_day_chart_series = [];
            for (var url in this.analytics.calls.time_of_day_chart.datasets) {
                this.calls_time_of_day_chart_series.push({
                    name: url,
                    data: this.analytics.calls.time_of_day_chart.datasets[url]
                });
            }

            setTimeout(function() {
                self.calls_time_of_day_chart_key++;
            }, 200);


            this.messages_chart_options.xaxis.categories = this.analytics.messages.messages_chart.labels;
            this.messages_chart_options.xaxis.series = [];

            this.messages_chart_series = [];
            for (var url in this.analytics.messages.messages_chart.datasets) {
                this.messages_chart_series.push({
                    name: url,
                    data: this.analytics.messages.messages_chart.datasets[url]
                });
            }

            setTimeout(function() {
                self.messages_chart_key++;
            }, 200);

            this.messages_time_of_day_chart_options.xaxis.categories = this.analytics.messages.time_of_day_chart.labels;
            this.messages_time_of_day_chart_options.xaxis.series = [];

            this.messages_time_of_day_chart_series = [];
            for (var url in this.analytics.messages.time_of_day_chart.datasets) {
                this.messages_time_of_day_chart_series.push({
                    name: url,
                    data: this.analytics.messages.time_of_day_chart.datasets[url]
                });
            }

            setTimeout(function() {
                self.messages_time_of_day_chart_key++;
            }, 200);
  
            this.fetchingData = false
          }catch(e){
            //   console.log(e)
          }
      },
      numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      formatDate: function(date, format = "MM/DD/YYYY") {
        return moment(String(date)).format(format)
      },
    },
    created() {
      this.getAnalytics()
  
      
    },
      watch: {

      }
  }
  </script>
  
  <style scoped>
    
  </style>