<template>

    <panel-navigation>
        
        <div v-if="show_action_dialog" @click.self="show_action_dialog = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
        <div class="w-full bg-white rounded-lg mx-6" 
        
            :class="'sm:w-' + dialog_sizes[sub_section.slug] + ' lg:w-' + dialog_sizes[sub_section.slug] + ''"
            
            style="max-height: 100%;">

            <div class="rg-modal-header">
            <span>{{ sub_section.title }}</span>

            <a href="javascript: void(0);" class="rg-modal-close-btn" @click="show_action_dialog = false"><i class="fa fa-times"></i></a>
            </div>

            <div class="p-2 pt-4 pb-4" style="height: calc(100% - 110px); overflow: auto;">

                <div v-if="sub_section.slug == 'get-started-basic-information'">
                    <div class="w-full  px-3">
                        <p class="mb-1 text-xs mb-2"><strong>Agency Name</strong></p>
                        <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"
                        v-model="me.company_name"  />
                        <p class="text-xs text-gray-500 mt-2" style="font-size: 11px;">This will also replace "Reviewgrower" all over the app.</p>
                    </div>
                    <div class="w-full px-3 mb-2">
                        <p class="mb-1 text-xs mb-2"><strong>Privacy Policy Link</strong></p>
                        <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.stripe.settings.privacy_policy_link" placeholder="https://"  />
                    </div>
                    <div class="w-full px-3">
                        <p class="mb-1 text-xs mb-2"><strong>Terms of Service Link</strong></p>
                        <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.stripe.settings.terms_of_service_link" placeholder="https://" />
                    </div>

                    <p class="text-xs text-gray-500 mt-2 px-3" style="font-size: 11px;">Your Privacy Policy and Terms of Service links are used to generate your customer portal in Stripe and in Local Recon reports.</p>
                </div>

                <div v-if="sub_section.slug == 'get-started-custom-css'">
                    <div class="w-full px-3">
                        <p class="mb-1 text-xs mb-2"><strong>Custom CSS</strong></p>
                        <textarea name="" id="" rows="14" class="text-sm block appearance-none focus:outline-none focus:border-gray-500 w-full bg-gray-100 hover:border-grey px-4 py-4 rounded" maxlength="1000" v-model="me.settings.custom_css" placeholder="/* Example */
header {
background-color: purple !important;
}

nav.v-navigation-drawer {
background-color: purple !important;
}

.me-icon {
background-color: #b794f4 !important;  
}" ></textarea>

                        <p class="text-xs text-gray-500 mt-2">Enter custom CSS to change the UI of the app. <a href="https://reviewgrower.com/documentation/how-to-customize-your-user-interface-using-custom-css/" target="custom-css-documentation">Custom CSS Documentation</a></p>
                    </div>
                </div>

                <div v-if="sub_section.slug == 'get-started-teammates'">
                    <Users />
                </div>

                <div v-if="sub_section.slug == 'white-label-domain'">
                    <div class="w-full mb-4 px-3">
                      <div class="bg-gray-100 rounded p-4">

                        <h2 class="font-bold text-sm text-gray-800 mb-4">Domain</h2>

                        <a href="javascript: void(0);" v-if="!show_subdomain_field" @click="show_subdomain_field = true" style="font-size: 17px;">{{ me.domain || '- none, click here to setup -' }}</a>

                        <input type="text" ref="domain" v-if="show_subdomain_field === true" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white border hover:border-grey px-2 py-2 rounded" placeholder="e.b. mydomain.com" style="width: calc(100% - 100px); display: inline-block; margin-right: 10px;"  />

                        <div v-if="show_subdomain_field === true" class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingDomain}" @click="saveDomain()">Save</div>

                        <div v-if="errorDomain" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                          <div v-html="errorDomain"> </div>
                        </div>

                        <div class="flex flex-row flex-wrap -mx-3 mt-3" style="font-size: 13px;">
                          <div class="w-full sm:w-1/3 px-3">
                            <div class="">
                              DNS Settings:
                              <div class="mt-2">
                                <strong>CNAME:</strong> dns.reviewgrower.com
                                <br />
                                <strong>A Record:</strong> 54.163.86.186
                              </div>
                            </div>
                          </div>
                          <div class="w-full sm:w-1/3">
                            <p class="" style="margin-bottom: 0px;">
                              Domain Status: 
                            </p>

                            <div v-if="!me.domain">
                              <i class="fa fa-exclamation-circle" style="color: orange; font-size: 24px;"></i>
                              <br />
                              <p class="0">
                                No domain setup <i v-if="me.domain && (me.domain_status == 0 || me.ssl_status == 0)" class="fa fa-spin fa-spinner" style="margin-left: 4px; font-size: 14px;"></i>
                              </p>
                            </div>

                            <div v-if="me.domain && me.domain_status == 0">
                              <i class="fa fa-times-circle" style="color: red; font-size: 24px;"></i>
                              <br />
                              <p class="0">
                                Check DNS settings <i v-if="me.domain && (me.domain_status == 0 || me.ssl_status == 0)" class="fa fa-spin fa-spinner" style="margin-left: 4px; font-size: 14px;"></i>
                              </p>
                            </div>

                            <div v-if="me.domain && me.domain_status == 1">
                              <i class="mdi mdi-check-circle-outline" style="color: green; font-size: 24px;"></i>
                            </div>
                          </div>
                          <div class="w-full sm:w-1/3">
                            <p class="" style="margin-bottom: 0px;">
                              SSL Status: 
                            </p>

                            <p class="" style="display: inline-block;">
                              <i v-if="me.ssl_status == 1" class="mdi mdi-check-circle-outline float-left" style="color: green; font-size: 24px;"></i>
                              <span v-if="me.ssl_status == 1" class="float-right mt-2 ml-2">expires {{ formatDate(me.ssl_expiration) }}</span>
                              <i v-if="me.ssl_status == 0" class="fa fa-times-circle" style="color: red; font-size: 24px;"></i>
                              <span v-if="me.ssl_status == 0 && me.domain_status == 1">&nbsp;&nbsp;(please check later)</span>
                              <span v-if="me.ssl_status == 0 && me.domain_status == 0">&nbsp;&nbsp;(check DNS Settings)</span>

                              <i v-if="me.domain && (me.domain_status == 0 || me.ssl_status == 0)" class="fa fa-spin fa-spinner" style="margin-left: 5px; font-size: 14px;"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div v-if="sub_section.slug == 'white-label-logo'">
                    <div class="w-full px-3">
                      <div class="" >

                        <div class="float-left" style="width: 200px; max-width: 100%;">
                          <strong class="text-sm">Agency Logo</strong>
                          <br />

                          <v-file-input
                            accept="image/*"
                            v-model="file"
                            @change="onUpload"
                            class="rg-uploader"
                            prepend-icon="mdi-camera"
                            outlined
                            hide-input
                          ></v-file-input>

                          <div v-if="$agency.logo" style="padding-top: 0px;">
                            <span v-if="$agency.logo" style="text-align: center;">
                              <img :src="$agency.logo" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                              <v-btn
                                depressed
                                color="error"
                                icon
                                small
                                @click="$agency.logo = ''; removeLogo()"
                              >
                                <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                              </v-btn>
                            </span>
                          </div>

                          <i v-if="uploading" class="fa fa-spin fa-spinner" style=""></i>
                        </div>

                        <div class="float-left" style="width: 200px; max-width: 100%;">
                          <strong class="text-sm">Agency Mobile Logo</strong>
                          <br />

                          <v-file-input
                            accept="image/*"
                            v-model="file_mobile"
                            @change="onUploadMobile"
                            class="rg-uploader"
                            prepend-icon="mdi-camera"
                            outlined
                            hide-input
                          ></v-file-input>

                          <div v-if="$agency.settings.logo_mobile" style="padding-top: 0px;">
                            <span v-if="$agency.settings.logo_mobile" style="text-align: center;">
                              <img :src="$agency.settings.logo_mobile" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                              <v-btn
                                depressed
                                color="error"
                                icon
                                small
                                @click="$agency.settings.logo_mobile = ''; removeLogoMobile()"
                              >
                                <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                              </v-btn>
                            </span>
                          </div>

                          <i v-if="uploading_mobile" class="fa fa-spin fa-spinner" style=""></i>
                        </div>

                        <div class="float-left" style="width: 200px; max-width: 100%;">
                          <strong class="text-sm">Agency Favicon</strong>
                          <br />

                          <v-file-input
                            accept="image/*"
                            v-model="file_favicon"
                            @change="onUploadFavicon"
                            class="rg-uploader"
                            prepend-icon="mdi-camera"
                            outlined
                            hide-input
                          ></v-file-input>

                          <div v-if="$agency.settings.favicon" style="padding-top: 0px;">
                            <span v-if="$agency.settings.favicon" style="text-align: center;">
                              <img :src="$agency.settings.favicon" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                              <v-btn
                                depressed
                                color="error"
                                icon
                                small
                                @click="$agency.settings.favicon = ''; removeFavicon()"
                              >
                                <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                              </v-btn>
                            </span>
                          </div>

                          <i v-if="uploading_favicon" class="fa fa-spin fa-spinner" style=""></i>
                        </div>

                        <br clear="all" />
                      </div>
                    </div>
                </div>

                <div v-if="sub_section.slug == 'integrations-twilio'">
                    <div class="flex flex-row flex-wrap pl-3">
                        <div class="w-full lg:w-1/2 pr-3">
                        <p class="mb-1 text-xs mb-2"><strong>Account SID</strong></p>
                        <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"
                        v-model="me.integrations.twilio.settings.account_sid"  />
                        </div>
                        <div class="w-full lg:w-1/2 px-3" style="position: relative;">
                        <p class="mb-1 text-xs mb-2"><strong>Auth Token</strong></p>
                        <input :type="((show_auth_token) ? 'text' : 'password')" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"  v-model="me.integrations.twilio.settings.auth_token" style="padding-right: 35px !important;" />

                        <a href="javascript: void(0);" @click="show_auth_token = !show_auth_token" class="token_password_toggle">
                            <i v-if="show_auth_token" class="fa fa-eye"></i>
                            <i v-if="!show_auth_token" class="fa fa-eye-slash"></i>
                        </a>
                        </div>

                        <div class="w-full pr-3">
                        <div class="flex flex-row flex-wrap">
                            <div class="w-full lg:w-1/2 pt-3">
                            <div v-if="errorTwilio" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                                <div v-html="errorTwilio"> </div>
                            </div>

                            <p v-if="me.integrations.twilio.status == 1" class="mb-1 text-xs mb-2"><strong>Outgoing SMS Phone Number</strong></p>
                            
                            <v-select v-if="me.integrations.twilio.status == 1 && !gettingTwilioNumbers"
                                    v-model="me.integrations.twilio.settings.phone_number"
                                    flat
                                    solo-inverted
                                    hide-details
                                    :items="me.integrations.twilio.settings.numbers"
                                    label="Select Number"
                                    class="-mt-2"
                                ></v-select>

                            <div v-if="me.integrations.twilio.status == 1 && me.integrations.twilio.settings.numbers.length == 0 && !gettingTwilioNumbers" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4 mt-1" role="warning">
                                <div>Please purchase a phone number.</div>
                            </div>

                                <i v-if="gettingTwilioNumbers" class="fa fa-spin fa-spinner" style="font-size: 14px;"></i>
                            </div>
                            <div class="w-full lg:w-1/2 pl-5 pt-3">
                            <p class="mb-1 text-xs mb-2"><strong>Link Shortening</strong>
                                <a href="https://www.twilio.com/docs/messaging/how-to-send-messages-with-link-shortening" target="twilio-link-shortening" class="ml-2"><i class="mdi mdi-information" style="font-size: 16px;"></i></a>
                            </p>

                            <v-checkbox
                                class="mt-4"
                                label="Enabled"
                                value="yes"
                                hide-details
                                v-model="me.integrations.twilio.settings.link_shortening"
                                :ripple="false"
                            ></v-checkbox>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div v-if="sub_section.slug == 'integrations-connect-smtp'">
                    <div class="flex flex-row flex-wrap pl-3 pr-3">
                        <div class="w-full lg:w-1/3 pr-3">
                          <p class="mb-1 text-xs mb-2"><strong>From Name</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.from_name"  />
                        </div>
                        <div class="w-full lg:w-1/3 pl-3">
                          <p class="mb-1 text-xs mb-2"><strong>From Email</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.from_email"  />
                        </div>
                        <div class="w-full lg:w-1/3 px-3">
                          <p class="mb-1 text-xs mb-2"><strong>Username</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.username"  />
                        </div>
                        <div class="w-full lg:w-1/3 mt-3" style="position: relative;">
                          <p class="mb-1 text-xs mb-2"><strong>Password</strong></p>
                          <input :type="((show_smtp_password) ? 'text' : 'password')" data-lpignore="true" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.password" style="padding-right: 35px !important;" />

                          <a href="javascript: void(0);" @click="show_smtp_password = !show_smtp_password" class="token_password_toggle">
                            <i v-if="show_smtp_password" class="fa fa-eye"></i>
                            <i v-if="!show_smtp_password" class="fa fa-eye-slash"></i>
                          </a>
                        </div>
                        <div class="w-full lg:w-1/3 mt-3 px-3">
                          <p class="mb-1 text-xs mb-2"><strong>Host</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.host"  />
                        </div>
                        <div class="w-full lg:w-1/3 pr-3">
                          <div class="flex flex-row flex-wrap">
                            <div class="w-full lg:w-1/2 mt-3 pl-3">
                              <p class="mb-1 text-xs mb-2"><strong>Port</strong></p>
                              <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.port"  />
                            </div>
                            <div class="w-full lg:w-1/2 mt-3 px-3 pl-10">
                              <p class="mb-1 text-xs mb-2"><strong>SSL/TLS</strong></p>

                              <v-checkbox
                                class="mt-4"
                                label="Enabled"
                                value="yes"
                                hide-details
                                v-model="me.integrations.smtp.settings.ssl_tls"
                                 :ripple="false"
                              ></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="w-full mt-3">
                          <div class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingSMTP}" @click="saveSMTPSettings()">Save</div>

                          <v-chip
                            v-if="me.integrations.smtp.status == 1"
                            class="ma-2"
                            color="green"
                            outlined
                            @click="saved = false"
                          >
                            <v-icon left>
                              mdi-check
                            </v-icon>
                            Valid
                          </v-chip>

                          <!-- <div v-if="me.integrations.smtp.status == 1" class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer mt-2 ml-2  float-right" @click="$refs.dialogSendTestEmail.show()"><i class="fa fa-envelope"></i> Send Test Email</div> -->
                        </div>
                        <div class="w-full mt-3 pl-3">
                          <div v-if="errorSMTP" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                            <div v-html="errorSMTP"> </div>
                          </div>
                        </div>
                    </div>
                </div>

                <div v-if="sub_section.slug == 'widgets-create'">
                    <div v-if="force_lr_widget">
                        <LocalReconWidgetForm :force_widget="force_lr_widget" />
                    </div>
                </div>

                <div v-if="sub_section.slug == 'pricing-plans-create'">
                    <PricingPlans />
                </div>

                <div v-if="sub_section.slug == 'own-agency-as-business-get-started'" class="pl-3 pr-3">
                    <div class="flex flex-wrap">
                        <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
                            <p class="mb-1 text-xs mb-2"><strong>
                                First Name
                            </strong></p>
                            <input name="first_name" ref="first_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                        </div>

                        <div class="w-full md:w-1/2 mb-6 md:mb-0">
                            <p class="mb-1 text-xs mb-2"><strong>
                                Last Name
                            </strong></p>
                            <input name="last_name" ref="last_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                        </div>
                    </div>

                    <div class="flex flex-wrap">
                        <div class="w-full mb-3 md:mb-0">
                            <p class="mb-1 text-xs mb-2"><strong>
                                Email Address
                                
                                <span v-if="is_business && !loginEnabled">(optional)</span>
                            </strong></p>
                            <input name="email" ref="email" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                        </div>
                    </div>

                    <div class="flex flex-wrap">
                        <div class="w-full md:w-2/2 mb-1 md:mb-0">
                            <p class="text-xs text-gray-700 mt-2">Use our tool below to search for your business online, or enter your details manually.</p>

                            <v-combobox
                                :label="((is_business) ? 'Search for location' : 'Business name and main location')"
                                v-model="autocompleteLocationModel"
                                :items="locationFoundItems"
                                :search-input.sync="locationSearchText"
                                item-text="value"
                                item-value="id"
                                hide-no-data
                                return-object
                                :filter="filterPlaces"
                                color="grey"
                                background-color="grey lighten-4"
                                @change="selectPlace()"
                                dense
                                outlined
                            >
                            </v-combobox>
                        </div>
                    </div>

                    <div class="flex flex-wrap">
                    <div :class="'w-full mb-6 md:mb-0'">
                        <p class="mb-1 text-xs mb-2"><strong>
                        Company Name
                        </strong></p>
                        <input class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" ref="company_name" v-model="company_name">
                    </div>
                    </div>

                    <div class="flex flex-wrap">
                    <div :class="'w-full md:w-1/2 pr-5 mb-6 md:mb-0'">
                        <p class="mb-1 text-xs mb-2"><strong>
                        Country
                        </strong></p>
                        
                        <v-select
                        :items="countries"
                        label=""
                        item-text="name"
                        item-value="code"
                        hide-details
                        v-model="business_location.country"
                        class="-mt-2 bg-grey"
                        dense
                        outlined
                        ></v-select>
                    </div>

                    <div :class="'w-full md:w-1/2 mb-6 md:mb-0'">
                        <p class="mb-1 text-xs mb-2"><strong>
                        City
                        </strong></p>
                        <input class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-model="business_location.city">
                    </div>
                    </div>

                    <div class="flex flex-wrap">
                    <div :class="'w-full mb-6 md:mb-0'">
                        <p class="mb-1 text-xs mb-2"><strong>
                        Address
                        </strong></p>
                        <input class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-model="business_location.address">
                    </div>
                    </div>

                    <div class="flex flex-wrap">
                    <div :class="'w-full md:w-1/2 pr-5 mb-6 md:mb-0'">
                        <p class="mb-1 text-xs mb-2"><strong>
                        <span v-if="business_location.country == 'US'">State</span>
                        <span v-if="business_location.country != 'US'">County</span>
                        </strong></p>
                        <input class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-model="business_location.state">
                    </div>

                    <div :class="'w-full md:w-1/2 mb-6 md:mb-0'">
                        <p class="mb-1 text-xs mb-2"><strong>
                        <span v-if="business_location.country == 'US'">Zip</span>
                        <span v-if="business_location.country != 'US'">Postal Code</span>
                        </strong></p>
                        <input class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-model="business_location.zip">
                    </div>
                    </div>

                    <div class="flex flex-wrap">
                        <div class="w-full md:w-2/2 mb-3 md:mb-0">
                            <p class="mb-1 text-xs mb-2"><strong>
                                Optional
                            </strong></p>
                            <v-checkbox
                            v-model="loginEnabled"
                            label="Create Login"
                            value="yes"
                            hide-details
                            :ripple="false"
                            ></v-checkbox>
                        </div>
                    </div>

                    <div v-if="loginEnabled" class="flex flex-wrap">
                        <div class="w-full mb-6 md:mb-0">
                            <p class="mb-1 text-xs mb-2"><strong>
                                Password
                            </strong></p>
                            <input name="password" ref="password" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="password">
                        </div>

                        <!-- <div class="w-full mb-6 md:mb-0" style="font-size: 12px;">
                            A confirmation link will be sent to the customer to verify their email address.
                        </div> -->
                    </div>

                    <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                        <div v-html="error"></div>
                    </div>
                </div>

                <div v-if="sub_section.slug == 'own-agency-as-business-review-funnel'">
                    <ReviewFunnel :force_business="this.businesses[this.businesses.length - 1]" />
                </div>

                <div v-if="sub_section.slug == 'own-agency-as-business-review-campaign' || sub_section.slug == 'review-campaign-business'">
                    <div class="mt-20">
                  <div v-show="true" style="max-width: 700px; margin: auto;">

                    <div style="margin-left: 46.5%; margin-top: -32px;">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon dark>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="addApplet(0, 'email')">
                            <v-list-item-title>Send Email</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="addApplet(0, 'sms')">
                            <v-list-item-title>Send SMS</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="addApplet(0, 'delay')">
                            <v-list-item-title>Wait</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    
                    <v-timeline v-if="getReviewsCampaignLoaded">
                      <v-timeline-item
                        v-for="(item, index) in getReviewsCampaign.settings" 
                        :key="index"
                        :color="getColor(item)"
                        small
                        >
                          <v-alert
                            :color="getColor(item)"
                            :class="getTextColor(item)"
                            @click="editApplet(index, item)"
                          >
                            <i :class="getIcon(item)" style="font-size: 17px; margin-right: 10px;"></i>
                            {{ item.title }}
                            <!-- <a href="#"><i class="fa fa-cog"></i></a>-->
                            <a href="javascript: void(0);" @click.stop="removeApplet(index)" class="remove-applet-btn ml-2"><i class="fa fa-times" style="color: white; font-size: 12px;"></i></a>
                          </v-alert>

                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="add-applet-btn mx-2"
                                fab
                                x-small
                                color="white"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon dark>
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="addApplet(index + 1, 'email')">
                                <v-list-item-title>Send Email</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="addApplet(index + 1, 'sms')">
                                <v-list-item-title>Send SMS</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="addApplet(index + 1, 'delay')">
                                <v-list-item-title>Wait</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-timeline-item>
                    </v-timeline>
                  </div>
                </div>
                </div>

                <div v-if="sub_section.slug == 'own-agency-as-business-social-posting'">
                    <SocialPosting :force_business="this.businesses[this.businesses.length - 1]" />
                </div>
                
                <div v-if="sub_section.slug == 'get-started-connect-review-platforms-business'">
                    <ReviewPlatforms />
                </div>
                
                <div v-if="sub_section.slug == 'get-started-settings-business'">
                    <Settings :onboarding="true" />
                </div>
                
                <div v-if="sub_section.slug == 'get-started-contacts-business'">
                    <Contacts :onboarding="true" />
                </div>
                
                <div v-if="sub_section.slug == 'review-funnel-business'">
                    <ReviewFunnel />
                </div>
                
                <div v-if="sub_section.slug == 'widgets-create-business'">
                    <div v-if="force_widget">
                        <WidgetForm :force_widget="force_widget" />
                    </div>
                </div>

                <div v-if="sub_section.slug == 'contact-widgets-create-business'">
                    <div v-if="force_widget">
                        <WidgetFormContact :force_widget="force_widget" />
                    </div>
                </div>
                
                <div v-if="sub_section.slug == 'social-posting-business'">
                    <SocialPosting />
                </div>
                
                <div v-if="sub_section.slug == 'inbox-setup-business'">
                    <Inbox />
                </div>
            </div>

            <div class="bg-gray-100 rounded-b-lg px-6 py-4">

            <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': savingAction}" @click="saveAction()">
                SAVE
            </button>

            <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="show_action_dialog = false">
                CANCEL
            </button>

            <div class="clearfix"></div>

            </div>


        </div>
        </div>

        <dialog-applet-form ref="dialogAppletForm"></dialog-applet-form>

        <div style="max-width: 800px; margin: auto; padding-top: 35px; color: #19233A;">
            <div style="text-align: center; font-size: 26px; padding-bottom: 15px;">
                Your Account Has Been Created
            </div>

            <div style="text-align: center; font-size: 14px; color: rgba(25, 35, 58, 0.60); padding-bottom: 30px;">
                Complete these simple steps to get your account setup and running.
            </div>

            <div class="dashboard-box" v-if="me">
                <v-card
                    class="pa-2 pb-1"
                    tile
                >
                    <div class="p-1">
                        <v-expansion-panels multiple :key="expansionPanelKey" accordion flat dense v-model="openPanel" :value="openPanel">
                            <v-expansion-panel v-for="(item, index) in me.settings.onboarding_items" :key="index">
                                <v-expansion-panel-header @click="clickedExpansionHeader(index)">
                                    {{ item.title }}

                                    <div style="position: absolute; font-size: 12px; right: 42px;">
                                        <div v-if="item.status == 'pending'">
                                            {{ item.sub_sections.length - item.sections_completed }} Step{{ ((item.sub_sections.length - item.sections_completed) == 1) ? '' : 's' }} Left
                                        </div>
                                        <div v-else style="padding-top: 0px;">
                                            
                                            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20" cy="20" r="20" fill="#2FC972"/>
                                                <path d="M15.3654 20.3294C14.6983 19.4851 13.413 20.5008 14.0802 21.3449L17.3205 25.4483C17.6729 25.8939 18.3552 25.8535 18.6575 25.3838L26.2906 15.7331C26.9578 14.8889 25.6725 13.8732 25.0054 14.7176L17.9658 23.6179L15.3653 20.3294L15.3654 20.3294Z" fill="white"/>
                                            </svg>

                                        </div>
                                    </div>
                                
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <div v-for="(sub_section, index2) in item.sub_sections" :key="index2" style="margin-top: 20px; font-size: 14px;">

                                        <div style="float: left; width: 56px; height: 56px; background: rgba(36, 119, 246, 0.10); border-radius: 10px; text-align: center; padding-top: 14px;">
                                            <i class="mdi " :class="'mdi-' + icons[sub_section.slug]" style="color: #2477F6; font-size: 20px;"></i>
                                        </div>

                                        <div style="float: left; padding-left: 15px; line-height: 28px;" :style="((sub_section.status == 'completed') ? 'opacity: 0.6' : '')">
                                            <strong>{{ sub_section.title }}</strong>
                                            <br />
                                            <span style="opacity: 0.6;">{{ sub_section.subtitle }}</span>
                                        </div>

                                        <div style="float: right; padding-left: 15px; line-height: 28px;">
                                            <div v-if="sub_section.status == 'pending'">
                                                <div class="bg-blue text-white text-bold text-xs inline-block rounded py-2 px-3 cursor-pointer float-right" style="margin-left: auto; width: 80px; text-align: center; text-transform: uppercase;" @click="performAction(item, sub_section)">{{ sub_section.button }}</div>
                                            </div>
                                            <div v-else style="padding-top: 10px;">
                                                
                                                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="20" cy="20" r="20" fill="#2FC972"/>
                                                    <path d="M15.3654 20.3294C14.6983 19.4851 13.413 20.5008 14.0802 21.3449L17.3205 25.4483C17.6729 25.8939 18.3552 25.8535 18.6575 25.3838L26.2906 15.7331C26.9578 14.8889 25.6725 13.8732 25.0054 14.7176L17.9658 23.6179L15.3653 20.3294L15.3654 20.3294Z" fill="white"/>
                                                </svg>

                                            </div>
                                        </div>
                                        
                                        <div style="clear: both;"></div>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </v-card>
            </div>
            <div style="float: right; padding-top: 15px;">
                <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer" @click="continueLater()">
                    CONTINUE LATER
                </div>
            </div>

            <div style="float: right; padding-top: 25px; color: #757B89; font-size: 12px; padding-right: 15px;">
                Last saved {{ lastSaved() }}
            </div>

            <div style="float: right; padding-top: 22px; padding-right: 5px;">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0015 4.24276C7.93748 4.24276 4.64315 7.5372 4.64315 11.6011C4.64315 15.665 7.93759 18.9594 12.0015 18.9594C16.0654 18.9594 19.3598 15.665 19.3598 11.6011C19.3598 7.5372 16.0654 4.24276 12.0015 4.24276ZM4.27148 11.6011C4.27148 7.33188 7.73227 3.87109 12.0015 3.87109C16.2707 3.87109 19.7315 7.33188 19.7315 11.6011C19.7315 15.8703 16.2707 19.3311 12.0015 19.3311C7.73227 19.3311 4.27148 15.8703 4.27148 11.6011Z" stroke="#757B89"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0022 6.79998C12.381 6.79998 12.6881 7.10699 12.6881 7.48581V11.3165L14.8876 13.5162C15.1555 13.7839 15.1555 14.2182 14.8876 14.486C14.6198 14.7539 14.1855 14.7539 13.9178 14.486L11.5173 12.0856C11.3887 11.957 11.3164 11.7826 11.3164 11.6006V7.48564C11.3164 7.10682 11.6234 6.7998 12.0022 6.7998L12.0022 6.79998Z" fill="#757B89"/>
                </svg>
            </div>

            <div style="clear: both;"></div>
        </div>
    </panel-navigation>
  
  </template>
  
  <script>
        import { mapState } from 'vuex'
        import axios from 'axios'
        import PanelNavigation from '@/components/PanelNavigation.vue'
        import Users from '@/views/panel/Users.vue'
        import moment from 'moment'
        import LocalReconWidgetForm from '@/views/local-recon/WidgetForm.vue'
        import PricingPlans from '@/views/panel/Agency/Plans.vue'

        import { GetSuggestions } from '@/utils/PlaceUtils'
        import $ from 'jquery'
        import countries from '@/config/countries';

        import ReviewFunnel from '@/views/panel/ReviewFunnel.vue'
        import DialogAppletForm from '@/components/dialogs/get-reviews/dialogAppletForm.vue'
        import SocialPosting from '@/views/panel/SocialPosting.vue'
        import ReviewPlatforms from '@/views/panel/ReviewPlatforms.vue'
        import Settings from '@/views/panel/Settings.vue'
        import Contacts from '@/views/panel/Contacts.vue'
        import WidgetForm from '@/views/panel/Business/Widgets/Form.vue'
        import WidgetFormContact from '@/views/panel/Business/ContactWidgets/Form.vue'
        import Inbox from '@/views/panel/Inbox'
  
      export default {
        metaInfo () {
            return {
                title: "Onboarding | " + this.$appName
            }
        },
        components: {
            PanelNavigation,
            Users,
            LocalReconWidgetForm,
            PricingPlans,
            ReviewFunnel,
            DialogAppletForm,
            SocialPosting,
            ReviewPlatforms,
            Settings,
            Contacts,
            WidgetForm,
            WidgetFormContact,
            Inbox
        },
        data () {
            return {
                show_action_dialog: false,
                item: null,
                sub_section: null,

                openPanel: null,

                expansionPanelKey: 0,

                savingAction: false,

                dialog_sizes: {
                    'get-started-basic-information': '1/3',
                    'get-started-custom-css': '1/3',
                    'get-started-teammates': '2/3',
                    'white-label-domain': '2/3',
                    'white-label-logo': '2/3',
                    'integrations-twilio': '2/3',
                    'integrations-connect-smtp': '2/3',
                    'widgets-create': '5/5',
                    'pricing-plans-create': '5/5',
                    'own-agency-as-business-get-started': '1/2',
                    'own-agency-as-business-review-funnel': '3/3',
                    'own-agency-as-business-review-campaign': '2/3',
                    'own-agency-as-business-social-posting': '3/3'
                },

                icons: {
                    // Agency

                    'get-started-basic-information': 'briefcase-outline',
                    'get-started-custom-css': 'code-tags',
                    'get-started-teammates': 'account-plus',
                    'white-label-domain': 'web',
                    'white-label-logo': 'image-outline',
                    'integrations-twilio': 'cellphone-settings',
                    'integrations-connect-smtp': 'email-outline',
                    'widgets-create': 'widgets',
                    'pricing-plans-create': 'cash-multiple',
                    'own-agency-as-business-get-started': 'briefcase-outline',
                    'own-agency-as-business-review-funnel': 'filter-outline',
                    'own-agency-as-business-review-campaign': 'star-box-outline',
                    'own-agency-as-business-social-posting': 'nature-people-outline',

                    // Business,

                    'get-started-basic-information-business': 'briefcase-outline',
                    'get-started-connect-review-platforms-business': 'account-multiple-outline',
                    'get-started-settings-business': 'cog-outline',
                    'get-started-contacts-business': 'account-multiple-plus-outline',
                    'review-funnel-business': 'filter-outline',
                    'review-campaign-business': 'star-box-outline',
                    'widgets-create-business': 'widgets',
                    'social-posting-business': 'nature-people-outline',

                    // OmniTalk extra

                    'contact-widgets-create-business': 'widgets',
                    'inbox-setup-business': 'comment'
                },

                show_subdomain_field: false,
                errorDomain: "",
                savingDomain: false,

                file: null,
                file_mobile: null,
                file_favicon: null,
                uploading: false,
                uploading_mobile: false,
                uploading_favicon: false,

                savingTwilio: false,
                errorTwilio: null,
                gettingTwilioNumbers: false,
                show_auth_token: false,

                savingSMTP: false,
                errorSMTP: null,
                show_smtp_password: false,

                force_lr_widget: null,

                force_widget: null,

                loginEnabled: false,

                company_name: "",

                is_business: true,

                autocompleteLocationModel: null,
                locationSearchText: null,
                locationEntries: [],

                business_location: {
                    country: "US"
                },

                countries: countries,

                error: null,

                getReviewsCampaignLoaded: false,
                getReviewsCampaign: null,

                date_format_items: [
                    'DD/MM/YYYY',
                    'MM/DD/YYYY',
                    'YYYY/MM/DD'
                ]
            }
        },
        computed: {
            ...mapState({
                me: state => state.me.me,
                businesses: state => state.businesses.businesses,
            }),
            locationFoundItems () {
                return this.locationEntries
            }
        },
        methods: {
            async performAction(item, sub_section) {
                this.item = item
                this.sub_section = sub_section
                this.show_action_dialog = true

                if (this.sub_section.slug == 'widgets-create') {
                    let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/local-recon/widgets/create', {

                    } , {
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                        },
                    });

                    this.force_lr_widget = response.data.widget;
                }

                if (this.sub_section.slug == 'own-agency-as-business-review-campaign' || this.sub_section.slug == 'review-campaign-business') {
                    this.getGetReviewsCampaign();
                }
                
                if (this.sub_section.slug == 'widgets-create-business') {
                    let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/create-widget', {

                    } , {
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                        },
                    });

                    this.force_widget = response.data.widget;
                }

                if (this.sub_section.slug == 'contact-widgets-create-business') {
                    let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/widgets/create-widget', {
                        type: 'contact'
                    } , {
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                        },
                    });

                    this.force_widget = response.data.widget;
                }

                switch (this.sub_section.slug) {
                    case 'get-started-connect-review-platforms-business':
                        window.dataLayer.push({
                        'event': 'onboarding',
                        'step': 'onboarding_flow_step1.2',
                        'activity': 'Get started - connect review platforms',
                        'user_id': this.me.id
                        });
                    break;
                    case 'get-started-settings-business':
                        window.dataLayer.push({
                        'event': 'onboarding',
                        'step': 'onboarding_flow_step1.3',
                        'activity': 'Get started - settings',
                        'user_id': this.me.id
                        });
                    break;
                    case 'get-started-contacts-business':
                    window.dataLayer.push({
                        'event': 'onboarding',
                        'step': 'onboarding_flow_step1.4',
                        'activity': 'Get started - add contacts',
                        'user_id': this.me.id
                        });
                    break;
                    case 'review-funnel-business':
                        window.dataLayer.push({
                            'event': 'onboarding',
                            'step': 'onboarding_flow_step2',
                            'activity': 'Review funnel',
                            'user_id': this.me.id
                            });
                    break;
                    case 'review-campaign-business':
                        window.dataLayer.push({
                            'event': 'onboarding',
                            'step': 'onboarding_flow_step3',
                            'activity': 'Review campaign',
                            'user_id': this.me.id
                        });
                    break;
                    case 'widgets-create-business':
                             window.dataLayer.push({
                                'event': 'onboarding',
                                'step': 'onboarding_flow_step4',
                                'activity': 'Add Widgets',
                                'user_id': this.me.id
                            });
                    break;
                    case 'social-posting-business':
                            window.dataLayer.push({
                                'event': 'onboarding',
                                'step': 'onboarding_flow_step5',
                                'activity': 'Social posting',
                                'user_id': this.me.id
                            });
                    break;
                }
            },
            async saveAction() {
                let self = this

                switch (this.sub_section.slug) {
                    // Agency
                    case 'get-started-basic-information':
                    case 'get-started-custom-css':
                    case 'get-started-teammates':
                    case 'white-label-domain':
                    case 'white-label-logo':
                    case 'widgets-create':
                    case 'pricing-plans-create':
                    case 'own-agency-as-business-review-funnel':
                    case 'own-agency-as-business-review-campaign':
                    case 'own-agency-as-business-social-posting':

                        await this.saveAgencySettings();
                        saveProgress();
                    break;

                    // Business
                    case 'get-started-connect-review-platforms-business':
                    case 'get-started-settings-business':
                    case 'get-started-contacts-business':
                    case 'review-funnel-business':
                    case 'review-campaign-business':
                    case 'widgets-create-business':
                    case 'contact-widgets-create-business':
                    case 'social-posting-business':
                    case 'inbox-setup-business':
                        saveProgress();
                    break;

                    case 'integrations-twilio':
                        await self.saveTwilioSettings();

                        if (!self.errorTwilio)
                            saveProgress();
                    break;

                    case 'integrations-connect-smtp':
                        await self.saveSMTPSettings();

                        if (!self.errorSMTP)
                            saveProgress();
                    break;

                    case 'own-agency-as-business-get-started':
                        await self.saveBusiness();

                        if (!self.error)
                            saveProgress();
                    break;
                }

                function saveProgress() {
                    self.show_action_dialog = false

                    axios.post(`${process.env.VUE_APP_API_ENDPOINT}/onboarding/save-progress`, {
                            item: self.item,
                            sub_section: self.sub_section
                        }, {
                        headers: {
                            'Authorization': 'Bearer ' + self.$store.state.auth.jwt
                        }
                    }).then(function(response) {
                        self.$store.dispatch('me/fetch').then(function(response2) {
                            let total_items = 0;
                            let items_completed = 0;

                            for (var i in self.me.settings.onboarding_items) {
                                if (self.me.settings.onboarding_items[i].title == self.item.title) {
                                    for (var j in self.me.settings.onboarding_items[i].sub_sections) {
                                        total_items++;
                                        
                                        if (self.me.settings.onboarding_items[i].sub_sections[j].status == 'completed') {
                                            items_completed++;
                                        }
                                    }
                                }
                            }

                            if (total_items == items_completed) {
                                self.openPanel = [parseInt(self.openPanel[0]) + 1];
                            }

                            if (self.me.settings.onboarding_status == 1)
                                self.$router.push('/dashboard');
                        });
                    });
                }
            },

            async saveAgencySettings() {

                this.savingAgencySettings = true
                this.agencySettingsError = null

                let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-stripe-settings`, {
                    privacy_policy_link: this.me.integrations.stripe.settings.privacy_policy_link,
                    terms_of_service_link: this.me.integrations.stripe.settings.terms_of_service_link,
                    only_links: true
                }, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                }
                })

                this.me.integrations.stripe.status = response.data.integration.status;
                this.me.integrations.stripe.settings = response.data.integration.settings;

                try {
                    await this.$store.dispatch('me/save', {
                    company_name: this.me.company_name.trim(),
                    contacts_webhook: this.me.settings.contacts_webhook,
                    custom_css: this.me.settings.custom_css || "{}",
                    chat_widget_code: this.me.settings.chat_widget_code || "__EMPTY__",
                    chat_widget_code_2: this.me.settings.chat_widget_code_2 || "__EMPTY__",
                    allow_business_to_modify_review_sites: this.me.settings.allow_business_to_modify_review_sites ? '1' : '0',
                    allow_business_to_modify_sms_from_number: this.me.settings.allow_business_to_modify_sms_from_number ? '1' : '0',
                    signup_link: this.me.settings.signup_link ? '1' : '0',
                    signup_page: this.me.settings.signup_page ? '1' : '0',
                    allow_stripe_plans_signups: this.me.settings.allow_stripe_plans_signups ? '1' : '0',

                    grid_use_which_api: this.me.settings.grid_use_which_api,
                    grid_sab_enabled: this.me.settings.grid_sab_enabled ? '1' : '0',

                    dashboard_link: this.me.settings.dashboard_link ? '1' : '0',
                    dashboard_link_url: this.me.settings.dashboard_link_url,
                    dashboard_link_text: this.me.settings.dashboard_link_text,
                    dashboard_link_icon: this.me.settings.dashboard_link_icon,

                    members_area_enabled: this.me.settings.members_area_enabled ? '1' : '0',
                    members_area_nav_text: this.me.settings.members_area_nav_text
                    })
                    this.$store.dispatch('me/fetch')
                }catch(error){
                    console.log(error)
                }
            },
            clickedExpansionHeader(index) {
                let self = this;
                console.log('index', index)
                setTimeout(function() {
                    self.openPanel = [parseInt(index)];
                }, 100);
            },
            formatDate(date) {
              return moment(date).format("MMM D, YYYY")
            },

            async saveDomain() {
                let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-white-label-domain`, {
                    domain: ((this.$refs.domain) ? this.$refs.domain.value.trim() : this.me.domain)
                }, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                }
                })

                if (response.data.success) {
                    this.savingDomain = false
                    this.show_subdomain_field = false
                    this.me.domain = ((this.$refs.domain) ? this.$refs.domain.value.trim() : this.me.domain)
                    this.me.domain_status = response.data.domain_status
                    this.me.ssl_status = response.data.ssl_status
                    this.me.ssl_expiration = response.data.ssl_expiration
                }
                else {
                    this.errorDomain = response.data.error
                }
            },

            async onUpload() {
            // console.log(this.file);
            if (this.file !== null) {
                this.uploading = true
                let formData = new FormData()
                formData.append("file", this.file)

                try {
                let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
                    headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                })

                let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-logo`, {
                    logo: response.data.url
                }, {
                    headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                })

                this.me.logo = response.data.url
                this.$agency.logo = response.data.url

                }catch(e){
                console.log(e)
                }

                this.file = null
                this.uploading = false
            }
            },

            async onUploadMobile() {
            // console.log(this.file);
            if (this.file_mobile !== null) {
                this.uploading_mobile = true
                let formData = new FormData()
                formData.append("file", this.file_mobile)

                try {
                let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
                    headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                })

                let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-mobile-logo`, {
                    logo: response.data.url
                }, {
                    headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                })

                this.me.settings.logo_mobile = response.data.url
                this.$agency.settings.logo_mobile = response.data.url

                }catch(e){
                console.log(e)
                }

                this.file_mobile = null
                this.uploading_mobile = false
            }
            },

            async onUploadFavicon() {
            // console.log(this.file);
            if (this.file_favicon !== null) {
                this.uploading_favicon = true
                let formData = new FormData()
                formData.append("file", this.file_favicon)

                try {
                let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
                    headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                })

                let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-favicon`, {
                    favicon: response.data.url
                }, {
                    headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                })

                this.$agency.settings.favicon = response.data.url

                const favicon = document.getElementById("ReviewGrowerFavicon");
                favicon.href = this.$agency.settings.favicon;

                }catch(e){
                console.log(e)
                }

                this.file_favicon = null
                this.uploading_favicon = false
            }
            },

            async saveTwilioSettings() {

                this.savingAction = true
                this.errorTwilio = null

                let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-twilio-settings`, {
                account_sid: this.me.integrations.twilio.settings.account_sid,
                auth_token: this.me.integrations.twilio.settings.auth_token,
                phone_number: this.me.integrations.twilio.settings.phone_number || null,
                link_shortening: this.me.integrations.twilio.settings.link_shortening
                }, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                }
                })

                if (response.data.success) {
                this.savingTwilio = false
                this.me.integrations.twilio.status = response.data.integration.status;

                if (response.data.integration.status == 0)
                    this.errorTwilio = "Your Twilio credentials are invalid."
                else {
                    // if (this.me.integrations.twilio.settings.numbers.length == 0)
                    this.getTwilioNumbers()
                }
                }
                else {
                    this.errorTwilio = response.data.error
                }

                this.savingAction = false

            },

            async getTwilioNumbers() {

                this.gettingTwilioNumbers = true

                let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/agency/get-twilio-numbers`, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                }
                })

                if (response.data.success) {
                this.gettingTwilioNumbers = false
                this.me.integrations.twilio.settings.numbers = response.data.integration.settings.numbers;
                this.me.integrations.twilio.settings.phone_number = response.data.integration.settings.phone_number;
                }

                this.gettingTwilioNumbers = false

            },

            async saveSMTPSettings() {

                this.savingAction = true
                this.errorSMTP = null

                let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-smtp-settings`, {
                from_name: this.me.integrations.smtp.settings.from_name,
                from_email: this.me.integrations.smtp.settings.from_email,
                host: this.me.integrations.smtp.settings.host,
                port: this.me.integrations.smtp.settings.port,
                username: this.me.integrations.smtp.settings.username,
                password: this.me.integrations.smtp.settings.password,
                ssl_tls: this.me.integrations.smtp.settings.ssl_tls
                }, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                }
                })

                if (response.data.success) {
                this.savingAction = false
                this.me.integrations.smtp = response.data.integration;

                if (response.data.integration.status == 0)
                    this.errorSMTP = "Your SMTP settings are invalid."
                }
                else {
                this.errorSMTP = response.data.error
                }

                this.savingAction = false

            },

            filterPlaces (item, queryText, itemText) {
                return true
            },
            selectPlace() {
                let that = this

                $.get("https://maps.googleapis.com/maps/api/geocode/json?place_id=" + this.autocompleteLocationModel.id + "&fields=name,rating,formatted_phone_number&key=AIzaSyANqXQPtxZhmpNYyvoBPBrnatNxGMvN9Ks", function(response) {
                    that.company_name = that.autocompleteLocationModel.value.split(",")[0];
                    console.log(response.results[0])

                    let address = that.getAddressObject(response.results[0].address_components);
                    let business_location = {};
                    business_location.street_number = address.street_number;
                    business_location.address = address.address;
                    business_location.city = address.city;
                    business_location.state = address.state;
                    business_location.zip = address.zip;
                    business_location.country = address.country;

                    business_location.lat = response.results[0].geometry.location.lat;
                    business_location.lng = response.results[0].geometry.location.lng;
                    business_location.place_id = response.results[0].place_id;

                    that.business_location = business_location;
                });
            },

            async saveBusiness() {
                this.savingAction = true
                this.error = null
                try {
                    let formData = new FormData();
                    // formData.append("name", this.name);
                    formData.append("first_name", this.$refs.first_name.value);
                    formData.append("last_name", this.$refs.last_name.value);
                    formData.append("company_name", this.company_name);
                    formData.append("email", this.$refs.email.value);

                    if (this.loginEnabled)
                        formData.append("password", this.$refs.password.value);

                    formData.append("loginEnabled", this.loginEnabled);

                    formData.append("street_number", this.business_location.street_number);
                    formData.append("address", this.business_location.address);
                    formData.append("city", this.business_location.city);
                    formData.append("state", this.business_location.state);
                    formData.append("zip", this.business_location.zip);
                    formData.append("country", this.business_location.country);
                    formData.append("lat", this.business_location.lat);
                    formData.append("lng", this.business_location.lng);
                    formData.append("place_id", this.business_location.place_id);

                    let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/children/add`, formData, {
                        headers: {
                        'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
                        'Content-Type': 'multipart/form-data'
                        }
                    })

                    if (typeof(this.is_business) != "undefined") {
                    let response2 = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/get-child-jwt-token?id=${response.data.child.id}`, {
                        headers: {
                        'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                        }
                    })

                    this.error = null

                    this.$store.dispatch('businesses/fetch')

                    }
                    else {
                    
                    }

                } catch (error) {
                    console.log(error)
                    error.errors = error.response.data.errors
                    for (var i in error.errors) {
                        if (error.errors[i] != 'null') {
                        console.log(error.errors[i]);
                        this.error = (this.error || '') + error.errors[i] + "<br />";
                        }
                    }

                }
                this.savingAction = false

                // if (typeof(this.is_business) != "undefined")
                //   if (this.$route.name != "locations")
                //     this.$router.push('/locations')
                },

            getAddressObject(address_components) {
                var ShouldBeComponent = {
                    street_number: ["street_number"],
                    zip: ["postal_code"],
                    address: ["street_address", "route"],
                    state: [
                    "administrative_area_level_1",
                    "administrative_area_level_2",
                    "administrative_area_level_3",
                    "administrative_area_level_4",
                    "administrative_area_level_5"
                    ],
                    city: [
                    "locality",
                    "sublocality",
                    "sublocality_level_1",
                    "sublocality_level_2",
                    "sublocality_level_3",
                    "sublocality_level_4"
                    ],
                    country: ["country"]
                };

                var address = {
                    street_number: "",
                    zip: "",
                    address: "",
                    state: "",
                    city: "",
                    country: ""
                };
                address_components.forEach(component => {
                    for (var shouldBe in ShouldBeComponent) {
                    if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                        if (shouldBe === "country") {
                        address[shouldBe] = component.short_name;
                        } else {
                        address[shouldBe] = component.long_name;
                        }
                    }
                    }
                });
                return address;
                },

            async getGetReviewsCampaign() {
                this.getReviewsCampaignLoaded = false;

                let url = process.env.VUE_APP_API_ENDPOINT + '/me/get-get-reviews-campaign';

                if (this.me.company_type == 'agency')
                    url += '?fbid=' + this.businesses[this.businesses.length - 1].id;
                
                try {
                    let response = await axios.get(url, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                    })
                    this.getReviewsCampaignLoaded = true
                    this.getReviewsCampaign = response.data.campaign

                    console.log('this.getReviewsCampaign', this.getReviewsCampaign)
                }catch(e){
                    console.log(e)
                }
            },

            getIcon(item) {
                if (item.type == 'email')
                    return 'fa fa-envelope';
                
                if (item.type == 'sms')
                    return 'fa fa-sms';
                
                if (item.type == 'delay')
                    return 'fa fa-clock';
                },
                
                getColor(item) {
                if (item.type == 'email')
                    return 'primary lighten-1';
                
                if (item.type == 'sms')
                    return 'green lighten-1';
                
                if (item.type == 'delay')
                    return 'blue-grey lighten-4';
                },
                
                getTextColor(item) {
                if (item.type == 'email')
                    return 'white--text';
                
                if (item.type == 'sms')
                    return 'white--text';
                
                if (item.type == 'delay')
                    return 'black--text';
                },

                editApplet(index, item) {
                console.log('item.settings.email_type', item.settings.email_type);
                if (!item.settings.email_type)
                    item.settings.email_type = 'html';

                this.$refs.dialogAppletForm.index = index
                this.$refs.dialogAppletForm.mode = 'edit'
                this.$refs.dialogAppletForm.item = item

                this.$refs.dialogAppletForm.show()
                },

                addApplet(index, type) {
                console.log('index', index)
                this.$refs.dialogAppletForm.index = index
                this.$refs.dialogAppletForm.mode = 'add'
                let self = this;

                switch (type) {
                    case 'email':
                    self.$refs.dialogAppletForm.item = {
                        type: 'email',
                        title: '',
                        settings: {
                        subject: "Feedback for [business_name]",
                        body: "Thank you for using [business_name]! We really appreciate your business and truly value you as a customer.\
            \n\n\
            Customer satisfaction is extremely important to us and we'd love to hear your feedback on how you feel about your recent service! If you could take a few minutes to leave a review for us, we'd really appreciate it.\
            \n\n\
            To submit your review, simply click below and let us know what you think!\
            \n\n\
            <a href='[link]'>[link]</a>\
            \n\n\
            Thanks in advance for taking the time to leave a review!\
            \n\n\
            Sincerely,\n\
            Your [business_name] team",
                        email_type: 'html'
                        }
                    }
                    break;

                    case 'sms':
                    self.$refs.dialogAppletForm.item = {
                        type: 'sms',
                        title: '',
                        settings: {
                        body: "Thank you for using [business_name]! Customer satisfaction is extremely important to us and we'd love to hear your feedback. [link]"
                        }
                    }
                    break;

                    case 'delay':
                    self.$refs.dialogAppletForm.item = {
                        type: 'delay',
                        title: '',
                        settings: {
                        delay_type: 'for',
                        delay_number: 1,
                        delay_unit: 'hour',
                        delay_hour_of_day: "9",
                        delay_timezone: "America/New_York"
                        }
                    }
                    break;
                };
                this.$refs.dialogAppletForm.show()
                },

                async removeApplet(index) {
                await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/delete-get-reviews-campaign-item` + '?fbid=' + this.businesses[this.businesses.length - 1].id, {
                    index: index
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    }
                })

                this.getGetReviewsCampaign();
                },

                continueLater() {
                    let self = this;
                    axios.post(`${process.env.VUE_APP_API_ENDPOINT}/onboarding/continue-later`, {
                            later: true
                        }, {
                        headers: {
                            'Authorization': 'Bearer ' + self.$store.state.auth.jwt
                        }
                    }).then(function(response) {
                        self.$router.push('/dashboard');
                    });
                },

                lastSaved() {
                    return moment.utc(this.me.settings.onboarding_last_updated).local().fromNow();
                }
        },
        created() {
            let self = this;
            this.$store.dispatch('me/fetch').then(function(response) {
                for (var i in self.me.settings.onboarding_items) {

                    if(self.me.settings.onboarding_items[i].sub_sections[i].slug == 'get-started-basic-information-business' && self.me.settings.onboarding_items[i].sub_sections[i].status == 'completed'){
                        window.dataLayer.push({
                        'event': 'onboarding',
                        'step': 'onboarding_flow_step1.1',
                        'activity': 'Get started - add basic information',
                        'user_id': self.me.id
                        });
                    }
                    if (self.me.settings.onboarding_items[i].status == 'pending') {
                        self.openPanel = [parseInt(i)];
                        break;
                    }
                   
                }
            });

            self.$store.dispatch('businesses/fetch')
        },
        watch: {
            locationSearchText (newVal) {
            var that = this;

            // If less than 3 chars typed, do not search
            if (!newVal || newVal.length <= 3) return

            // Call the method from the previous section here
            GetSuggestions(newVal)
                .then(function (res) {
                
                that.locationEntries = res
                })
                .catch(function () {
                that.business = false;
                that.step = 1;
                })
            },
        }
      }
  </script>
  
  <style type="text/css">
      .theme--light.v-sheet--outlined {
          border: thin solid rgba(0, 0, 0, 0.12);
      }

    .token_password_toggle {
        position: absolute;
        margin-top: -30px;
        right: 20px;
    }

    .remove-applet-btn {
        background: gray;
        border: solid 1px #FFFFFF;
        border-radius: 50%;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 16px;
        text-align: center;
        right: -8px;
        top: -8px;
        z-index: 10;
        }

        .v-timeline-item {
        padding-bottom: 35px;
        }

        .v-timeline-item .v-alert {
        cursor: pointer;
        }

        .v-timeline-item .remove-applet-btn {
        display: none;
        }

        .v-timeline-item:hover .remove-applet-btn {
        display: block;
        }

        .add-applet-btn {
        position:absolute;
        z-index: 8;
        /* bottom: 30 px; */
        }

        .v-timeline .v-timeline-item:nth-child(odd) .add-applet-btn {
        left: -72px;
        }

        .v-timeline .v-timeline-item:nth-child(even) .add-applet-btn {
        right: -72px;
        }
  </style>