<template>
  <auth-layout :page="'welcome'" v-if="!$route.query.alt">
    <h1 class="text-xl mb-2 text-bold">Welcome!</h1>

    <!-- <p v-if="!loading" class="text-xs mb-6"></p> -->

    <div v-if="!loading">
      <div class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
        You have successfully created your account.
      </div>

      <div class="">

          <button class="w-full rounded bg-blue text-white text-bold text-xs focus:outline-none py-3 px-4" @click="submit">
              CONTINUE TO DASHBOARD
          </button>

      </div>

    </div>

    <div v-if="loading" style="text-align: center; padding: 40px;">
      <i class="fa fa-spin fa-spinner" style="font-size: 30px;"></i>
    </div>
  </auth-layout>

</template>



<script>

import axios from 'axios'
import AuthLayout from '@/components/AuthLayout.vue'
import { mapState } from 'vuex'

export default {
  components: {
    AuthLayout
  },

  data: function() {
    return {
      loading: false,
    }
  },
  metaInfo () {
    return {
      title: "Welcome | " + this.$appName
    }
  },
  computed: {
    ...mapState({
      profile: state => state.me.profile,
      me: state => state.me.me
    })
  },
  methods: {
    async submit() {
      window.dataLayer.push({
        'event': 'signUp',
        'step': 'signup_flow_complete',
        'activity': 'Account created',
      });
      window.location = '/dashboard';
    }
  }
};
</script>