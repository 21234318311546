<template>

  <div>
    <panel-navigation>

      <div class="float-right ml-5 -mt-1">
        <div v-if="profile.settings.role == null || (profile.settings.role != 'Manager (readonly)' && profile.settings.permissions.edit_plans)" class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="$router.push('/plans/create')" style="margin-top: -5px;"><i class="fa fa-plus mr-2"> </i> CREATE PLAN</div>
      </div>

      <h1 class="text-lg font-semibold mb-3">Manage Plans</h1>
      
      <hr class="border-b border-gray-100 mb-3">

      <v-skeleton-loader
        type="article"
        v-if="!$agency"
      ></v-skeleton-loader>

      <div v-if="me && $agency">
            <v-skeleton-loader v-if="loading" type="article, article, article"></v-skeleton-loader>

            <div v-else>
                <!-- <v-alert
                    color="blue-grey"
                    dark
                    icon="mdi-clock-fast"
                    text
                    v-if="plans.length == 0"
                    >
                    <strong>Get Started!</strong>
                    You currently don't have any plans. Use our default plans or create your own.
                    <br /><br />

                    <v-btn
                        tile
                        color="orange mb-10"
                        @click="generatePlans()"
                    >
                    <br />
                    <v-icon left>
                        mdi-reload
                    </v-icon>
                        Generate Default Plans
                    </v-btn>

                    <v-btn
                        tile
                        color="blue-grey mb-10 ml-4"
                        @click="$router.push('review-platforms')"
                    >
                    <br />
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                        Create First Plan
                    </v-btn>
                </v-alert> -->
                

                <div v-if="plans.length > 0" class="flex flex-row justify-center flex-wrap mb-8 -mx-1">
    
                    <div v-for="(plan, index) in plans" :key="index" :name="plan.name" :plan="index" class="flex-1 flex-col dashboard-box stats-box w-full px-2 mb-4 md:w-1/3 lg:w-1/3" style="position: relative;">
                        <v-card
                          class="pa-2"
                          tile
                        >
                          <div class="p-3">
                            <div class="text-center text-bold">{{ plan.name }}</div>

                            <a href="javascript: void(0);" v-if="profile.settings.role == null || (profile.settings.role != 'Manager (readonly)' && profile.settings.permissions.edit_plans)" @click="$router.push('/plans/edit/' + plan.id)" class="" style="position: absolute; right: 7px; top: 7px;"><i class="mdi mdi-pencil-outline text-gray-600"></i></a>

                            <div class="text-center" style="padding: 10px;">
                                <div v-for="(price, index) in plan.prices" :key="index" class="mb-1 mt-1">
                                  <div v-if="index == 0">
                                    <strong class="text-lg">{{ (price.unit_amount/100).toFixed(2) }} {{ plan.currency }}</strong> &nbsp; <span class="text-gray-600">/ {{ price.recurring.interval }}</span>
                                  </div>
                                </div>
                            </div>

                            <div class="text-center" style="padding: 10px;">
                                <strong style="color: #2477F6;">{{ plan.subscribers }} Subscriptions</strong>
                            </div>

                            <div style="padding: 10px; font-size: 12px; line-height: 27px;">
                                <strong>Settings:</strong><br />

                                <span class="text-sm">Use Stripe billing: </span>
                                
                                <strong v-if="plan.use_stripe == 0" class="text-red-600">No</strong>
                                <strong v-if="plan.use_stripe == 1" class="text-green-600">Yes</strong>
                                <br />

                                <span class="text-sm">Use Own Twilio: </span>

                                <strong v-if="plan.own_twilio == 0" class="text-red-600">No</strong>
                                <strong v-if="plan.own_twilio == 1" class="text-green-600">Yes</strong>
                            </div>

                            <div v-show="plan.trial > 0" style="padding: 10px; font-size: 12px; line-height: 27px;">
                                <strong>Free Trial:</strong> <span> {{ plan.trial }} days</span>
                            </div>

                            <div style="padding: 10px; font-size: 12px; line-height: 27px;">
                                <strong>Features:</strong><br />
                                <div v-for="(feature, index) in plan.features" :key="index" class="mb-1"><i class="fa fa-check text-green-600"></i> &nbsp;&nbsp; {{ feature }}</div>
                            </div>
                          </div>
                        </v-card>

                    </div>

                    <!-- <billing-product-plan name="Enterprise" buttonText="Contact sales" :active="me.currentProductSlug == 'enterprise'">
                        <div class="text-3xl">
                        <font-awesome-icon icon="leaf" class="text-gray-200"/>
                        </div>
                    </billing-product-plan> -->

                </div>

            </div>
      </div>

    </panel-navigation>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import PanelNavigation from '@/components/PanelNavigation.vue'
import BillingProductPlan from '@/components/billing/billingProductPlan.vue'
import moment from 'moment'

export default {
  metaInfo () {
    return {
      title: "Plans | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    BillingProductPlan
  },
  data() {
    return {
        loading: false,
        plans: []
    }
  },
  methods: {
    async getPlans() {
        this.loading = true;

        let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/plans`, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
        })

        this.plans = response.data.plans;
        this.loading = false;

        if (this.plans.length == 0)
            this.generatePlans();
    },

    async generatePlans() {
        this.loading = true;

        let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/plans/generate`, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
        })

        this.getPlans();
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me,
      profile: state => state.me.profile,
      agency: state => state.agency
    })
  },
  mounted() {
      this.getPlans();
  }
}
</script>

<style scoped>

</style>