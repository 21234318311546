<template>
    <div class="embed-slider-wrapper pl-3 pr-3" :style="
        'background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + ';'
    ">
        <Header :widget_data="widget_data" />

        <div v-show="widget_data.widget.settings.show_leave_a_review_button == 1" class="pt-0 mb-5" style="width: 160px; margin: auto;">
            <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer" @click="openReviewFunnel()" style="margin-top: -5px;"><i class="fa fa-star mr-2"> </i> WRITE A REVIEW</div>
        </div>

        <div class="clearfix"></div>

        <v-carousel height="auto">
            <v-carousel-item v-for="(item, index) in widget_data.reviews" :key="index">
                <v-row class="flex-nowrap" style="height:100%">
                    <v-col>
                        <v-sheet
                                height="100%"
                                color="transparent"
                        >
                            <Review :widget_data="widget_data" :item="widget_data.reviews[index]" />
                        </v-sheet>
                    </v-col> 
                </v-row>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import HeaderAggregate from '@/views/panel/Business/Widgets/Render/HeaderAggregate.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        HeaderAggregate,
        Review
    },

    methods: {
        openReviewFunnel() {
            window.open(this.widget_data.review_funnel_link);
        }
    },

    mounted() {
        repositionPrevNext();
        
        function repositionPrevNext() {
            try {
                document.querySelector(".v-window__prev").style.top = 'auto';
                document.querySelector(".v-window__prev").style.bottom = '1px';
                document.querySelector(".v-window__prev").style.left = 'auto';
                document.querySelector(".v-window__prev").style.right = '55px';

                document.querySelector(".v-window__next").style.top = 'auto';
                document.querySelector(".v-window__next").style.bottom = '1px';

                document.querySelector(".embed-slider-wrapper .v-window__container").style.paddingBottom = '25px';
            }
            catch (e) {
                setTimeout(function() {
                    repositionPrevNext();
                }, 100);
            }
        }
    }
}
</script>

<style type="text/css" scoped>
    .embed-slider-wrapper {
        width: 100%;
        max-width: 500px;
        padding-bottom: 10px;
        margin: auto;
    }

    .v-carousel__controls {
        display: none !important;
    }

    .embed-slider-wrapper .v-window-item {
        padding-left: 0px;
        padding-right: 0px;
    }
</style>