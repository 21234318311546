<template>

    <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-3/4 lg:w-3/4 bg-white rounded-lg mx-6" style="max-height: 100%; max-width: 800px;">
  
        <div class="rg-modal-header">
          View Posting Queue
  
          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
        </div>
  
        <div class="p-6" style="height: calc(100% - 110px); overflow: hidden;">
  
          <div style="height: calc(100%); overflow: auto;">
              <div class="text-gray-600 text-sm" v-if="queue_items.length == 0 && !fetching_log">
                Nothing found in the queue.
              </div>
              <v-data-table
                v-if="(fetching_log) || (queue_items.length > 0 && !fetching_log)"
                :headers="headers"
                :items="queue_items"
                :options.sync="options"
                :server-items-length="totalFilteredItems"
                :items-per-page="10"
                class="elevation-1"
                :loading="fetching_log"
                :footer-props="{ disableItemsPerPage : true, itemsPerPageOptions: [5] }"
              >
  
                <template v-slot:item.queue_item="{ item }">
                    <div class="reviewer pt-2">
                        <div style="float: right; padding-top: 10px; width: 100px; font-weight: bold; font-size: 17px; text-align: right;">
                        <img class="source-logo -mr-2" :src="require('../../../assets/images/review-services/www.' + getHostname(item.review.listing) + '.png')" />
                        </div>
                        
                        <div style="float: left;">
                        <span class="ml-2">
                            <img class="profile-pic" referrerPolicy="no-referrer" :src="item.review.profile_picture" v-if="item.review.profile_picture" />
                            <v-avatar color="blue" size="50" v-if="!item.review.profile_picture" class="-ml-3"><v-icon small color="white">mdi-account</v-icon></v-avatar>
                        </span>
                        </div>
                        <div style="float: left;">
                        <h3 style="padding-top: 5px;">
                            {{ item.review.name }}
                        </h3>

                        <br />

                        <strong class="pl-2">{{ item.review.rating_value }}</strong>

                        <v-rating
                        :value="item.review.rating_value"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="15"
                        ></v-rating>

                        <span class="review-date">{{ formatDate(item.review.date) }}</span>
                        </div>

                        <br clear="all" />

                        <div v-if="item.review.review_text !== null" class="pt-2 text-gray-600">
                            <div v-show="!item.review.show_all" v-html='((item.review.review_text.toString().length > 150) ? item.review.review_text.substring(0, 150) + "..." : item.review.review_text)' />
                        </div>
                        </div>
                </template>

                <template v-slot:item.queue_item_status="{ item }">
                    <v-chip
                        v-if="item.status == 1"
                        x-small
                        outlined
                        color="green"
                        text-color="green lighten-1"
                    >
                        Completed
                    </v-chip>

                    <v-chip
                        v-if="item.status == 0"
                        x-small
                        outlined
                        color="orange"
                    >
                        Queued
                    </v-chip>

                    <v-chip
                        v-if="item.status == 2"
                        x-small
                        outlined
                        color="blue"
                    >
                        <i class="fa fa-spin fa-spinner mr-2"></i> Submitting
                    </v-chip>

                    <div v-if="item.status == 0" class="pt-1">
                      <v-btn
                        color="red darken-1"
                        class="mt-2 white--text mr-3"
                        small
                        dense
                        @click="deleteSocialPostFromQueue(item)"
                      >
                        <v-icon
                          right
                          dark
                          class="mr-2"
                        >
                          mdi-delete
                        </v-icon>
                        Remove
                      </v-btn>
                    </div>
                </template>
            </v-data-table>
          </div>
        </div>
  
        <div class="bg-gray-100 rounded-b-lg px-6 py-4">
  
          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
              CLOSE
          </button>
  
          <div class="clearfix"></div>
  
        </div>
  
      </div>
    </div>
  
  </template>
  
  <script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import moment from 'moment'
  import bigInt from 'big-integer';
  
  export default {
    props: ['page', 'itemsPerPage'],
    data () {
      return {
        showModal: false,
        headers: [
            { 
                text: 'Queued Review', value: 'queue_item', html: true, sortable: false
            },
            { 
                text: 'Status', value: 'queue_item_status', html: true, sortable: false
            },
            // { 
            //     text: 'Actions', value: 'queue_item_actions', html: true, sortable: false
            // }
        ],
        totalFilteredItems: 0,
        totalItems: 0,
        fetching_log: true,
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        queue_items: []
      }
    },
    computed: {
      ...mapState({
        me: state => state.me.me
      })
    },
    methods: {
      show() {
        this.showModal = true
      },
      hide() {
        this.showModal = false
      },
      getLogItems: async function(ignore_loading) {
        const { page, itemsPerPage } = this.options
  
        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/get-social-posting-queue`,
          { 
            page: page,
            itemsPerPage: itemsPerPage
          },
          {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          }
        )
  
        this.queue_items = response.data.queue_items
        this.totalFilteredItems = response.data.totalFilteredItems
        this.totalItems = response.data.totalItems
        this.fetching_log = false
      },

      async deleteSocialPostFromQueue(item) {
        await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/delete-social-post-from-queue', {
          id: item.id
        } , {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          },
        })

        this.getLogItems();
      },
      formatDate: function(date) {
        return moment(String(date)).format(this.me.settings.date_format)
      },
      getHostname: function(item) {
        return new URL(item.url || item.listing.url).hostname.replace("www.", "").replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com");
      }
    },
    mounted () {
      this.getLogItems()
    },
    watch: {
      options: {
        handler () {
          this.getLogItems()
        }
      }
    }
  }
  </script>
  
  <style tyle="text/css">
    .v-data-table__progress {
      display: none;
    }
  </style>