<template>
    <div v-if="widget_data" :style="((fw) ? '' : 'padding-top: 25px;')">
        <div :class="'widgets-wrapper ' + widget_data.widget.settings.align"
                :style="
                    'font-family: ' + widget_data.widget.settings.font_family.replace(' (Google)', '') + ';' +
                    'color: ' + widget_data.widget.settings.text_color + ';' + 
                    
                    ((
                        widget_data.widget.template == 'embed-grid' || 
                        widget_data.widget.template == 'embed-column' || 
                        widget_data.widget.template == 'embed-page' || 
                        widget_data.widget.template == 'embed-list' || 
                        widget_data.widget.template == 'embed-table' || 
                        widget_data.widget.template == 'embed-slider' || 
                        widget_data.widget.template == 'embed-carousel' || 
                        widget_data.widget.template == 'embed-directory'
                    ) ? ' position: relative !important;' : '')
            "
        >
            <EmbedGrid v-if="widget_data.widget.template == 'embed-grid'" :widget_data="widget_data" :fw="fw" />
            <EmbedColumn v-if="widget_data.widget.template == 'embed-column'" :widget_data="widget_data" :fw="fw" />
            <EmbedPage v-if="widget_data.widget.template == 'embed-page'" :widget_data="widget_data" :fw="fw" />
            <EmbedList v-if="widget_data.widget.template == 'embed-list'" :widget_data="widget_data" :fw="fw" />
            <EmbedTable v-if="widget_data.widget.template == 'embed-table'" :widget_data="widget_data" :fw="fw" />
            <EmbedSlider v-if="widget_data.widget.template == 'embed-slider'" :widget_data="widget_data" :fw="fw" />
            <EmbedCarousel v-if="widget_data.widget.template == 'embed-carousel'" :widget_data="widget_data" :fw="fw" />
            <EmbedDirectory v-if="widget_data.widget.template == 'embed-directory'" :widget_data="widget_data" :fw="fw" />
            <EmbedNuggets v-if="widget_data.widget.template == 'embed-nuggets'" :widget_data="widget_data" :fw="fw" />
            <EmbedSidebarSlider v-if="widget_data.widget.template == 'embed-sidebar-slider'" :widget_data="widget_data" :fw="fw" />
            <EmbedSidebarNuggets v-if="widget_data.widget.template == 'embed-sidebar-nuggets'" :widget_data="widget_data" :fw="fw" />

            <Hover1 v-if="widget_data.widget.template == 'hover-1'" :widget_data="widget_data" :fw="fw" />
            <Hover2 v-if="widget_data.widget.template == 'hover-2'" :widget_data="widget_data" :fw="fw" />
            <Hover3 v-if="widget_data.widget.template == 'hover-3'" :widget_data="widget_data" :fw="fw" />
            <Hover4 v-if="widget_data.widget.template == 'hover-4'" :widget_data="widget_data" :fw="fw" />
            <Hover5 v-if="widget_data.widget.template == 'hover-5'" :widget_data="widget_data" :fw="fw" />
            <Hover6 v-if="widget_data.widget.template == 'hover-6'" :widget_data="widget_data" :fw="fw" />
            <Hover7 v-if="widget_data.widget.template == 'hover-7'" :widget_data="widget_data" :fw="fw" />

            <Badge1 v-if="widget_data.widget.template == 'badge-1'" :widget_data="widget_data" :fw="fw" />
            <Badge2 v-if="widget_data.widget.template == 'badge-2'" :widget_data="widget_data" :fw="fw" />
            <Badge3 v-if="widget_data.widget.template == 'badge-3'" :widget_data="widget_data" :fw="fw" />
            <Badge4 v-if="widget_data.widget.template == 'badge-4'" :widget_data="widget_data" :fw="fw" />
            <Badge5 v-if="widget_data.widget.template == 'badge-5'" :widget_data="widget_data" :fw="fw" />
            <Badge6 v-if="widget_data.widget.template == 'badge-6'" :widget_data="widget_data" :fw="fw" />
            <Badge7 v-if="widget_data.widget.template == 'badge-7'" :widget_data="widget_data" :fw="fw" />
            <Badge8 v-if="widget_data.widget.template == 'badge-8'" :widget_data="widget_data" :fw="fw" />
            <Badge9 v-if="widget_data.widget.template == 'badge-9'" :widget_data="widget_data" :fw="fw" />
            <Badge10 v-if="widget_data.widget.template == 'badge-10'" :widget_data="widget_data" :fw="fw" />
            <Badge11 v-if="widget_data.widget.template == 'badge-11'" :widget_data="widget_data" :fw="fw" />
            <Badge12 v-if="widget_data.widget.template == 'badge-12'" :widget_data="widget_data" :fw="fw" />
            <Badge13 v-if="widget_data.widget.template == 'badge-13'" :widget_data="widget_data" :fw="fw" />
            <Badge14 v-if="widget_data.widget.template == 'badge-14'" :widget_data="widget_data" :fw="fw" />
            <Badge15 v-if="widget_data.widget.template == 'badge-15'" :widget_data="widget_data" :fw="fw" />
            <Badge16 v-if="widget_data.widget.template == 'badge-16'" :widget_data="widget_data" :fw="fw" />
            <Badge17 v-if="widget_data.widget.template == 'badge-17'" :widget_data="widget_data" :fw="fw" />
            <Badge18 v-if="widget_data.widget.template == 'badge-18'" :widget_data="widget_data" :fw="fw" />
            <Badge19 v-if="widget_data.widget.template == 'badge-19'" :widget_data="widget_data" :fw="fw" />

            <Rotator1 v-if="widget_data.widget.template == 'rotator-1'" :widget_data="widget_data" :fw="fw" />
            <Rotator2 v-if="widget_data.widget.template == 'rotator-2'" :widget_data="widget_data" :fw="fw" />
            <Rotator3 v-if="widget_data.widget.template == 'rotator-3'" :widget_data="widget_data" :fw="fw" />
            <Rotator4 v-if="widget_data.widget.template == 'rotator-4'" :widget_data="widget_data" :fw="fw" />
            <Rotator5 v-if="widget_data.widget.template == 'rotator-5'" :widget_data="widget_data" :fw="fw" />

            <div v-if="
                    widget_data.widget.template.indexOf('embed') != -1 && 
                    widget_data.widget.template != 'embed-slider' && 
                    widget_data.widget.template != 'embed-carousel' && 
                    widget_data.widget.template != 'embed-nuggets' && 
                    widget_data.widget.template != 'embed-sidebar-nuggets' && 
                    widget_data.widget.template != 'embed-sidebar-slider'
            " :style="'background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; padding-bottom: 20px;'">
                <div v-if="widget_data.reviews.length == 0" class="text-center" style="background: #ebebeb; color: #707070; padding: 30px; margin: 20px; border-radius: 5px;">
                    <i class="fa fa-spin fa-spinner"></i>
                    <br />
                    Your reviews are being collected, please check back later.
                </div>
                <Pagination
                    :widget_data="widget_data"
                    :fw="fw"
                    :page="page"
                    :count="count"
                />
            </div>

            <div v-if="
                widget_data && (
                    widget_data.widget.subscription.stripe_id == 'starter' &&
                    (
                        widget_data.widget.template.indexOf('embed') != -1
                    )
                )
            " class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
                Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
            </div>

            <v-dialog
                v-model="dialog"
                transition="slide-x-transition"
                persistent
                :width="getWidth()"
                :content-class="widget_data.widget.settings.align"
                hide-overlay
                v-if="
                    (widget_data.widget.template.indexOf('hover') != -1) ||
                    (widget_data.widget.template.indexOf('badge') != -1 && widget_data.widget.settings.badge_display == 'Floating')
                "
            >

            <v-card class="main-card">
                <v-btn
                    fab
                    dark
                    small
                    color="blue-grey"
                    @click="closeDialog()"
                    class="dialog-close-btn"
                    style="z-index: 99; margin-top: 10px;"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                
                <div :class="'scrollable-reviews-list dialog-reviews-list ' + ((widget_data.widget.settings.click_to_call_enabled) ? 'c2c-enabled': '')">
                    <Header :widget_data="widget_data" />
                    <HeaderAggregate :widget_data="widget_data" />

                    <div v-for="item in widget_data.reviews" :key="item.id">
                        <Review :widget_data="widget_data" :item="item" />
                    </div>

                    <Pagination
                        :widget_data="widget_data"
                        :fw="fw"
                        :page="page"
                        :count="count"
                    />
                </div>

                <div v-if="widget_data.widget.settings.click_to_call_enabled" style="padding-left: 15px; padding-right: 15px;">
                    <div :style="((widget_data.widget.settings.click_to_call_shadow) ? 'box-shadow: 0px 18px 40px rgba(22, 51, 97, 0.42);' : '') + ' width: 100%; padding: 15px; border-radius: 10px; text-align: center; background: ' + widget_data.widget.settings.click_to_call_background + ';'">
                        <div :style="'color: #FFFFFF; padding: 10px; padding-bottom: 20px; padding-left: 50px; padding-right: 50px; font-weight: bold;'">{{ widget_data.widget.settings.click_to_call_text }}</div>
                        <div class="text-bold rounded py-2 px-3 cursor-pointer" :style="'background: ' + widget_data.widget.settings.click_to_call_button_background + ';'"><a :href="widget_data.widget.settings.click_to_call_href" :style="'color: ' + widget_data.widget.settings.click_to_call_button_text_color + ' !important;'"><i :class="widget_data.widget.settings.click_to_call_button_icon" style="font-size: 20px;"></i> {{ widget_data.widget.settings.click_to_call_button_text }}</a></div>
                    </div>
                </div>
            </v-card>

            </v-dialog>

            <v-card class="main-card"
                v-if="(widget_data.widget.template.indexOf('badge') != -1 && widget_data.widget.settings.badge_display == 'Embed')"
                v-show="dialog"
            >
                <v-btn
                    fab
                    dark
                    small
                    color="blue-grey"
                    @click="closeDialog()"
                    class="dialog-close-btn"
                    style="z-index: 99; margin-top: 10px;"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                
                <div class="scrollable-reviews-list">
                    <Header :widget_data="widget_data" />
                    <HeaderAggregate :widget_data="widget_data" />

                    <div v-for="item in widget_data.reviews" :key="item.id">
                        <Review :widget_data="widget_data" :item="item" />
                    </div>

                    <Pagination
                        :widget_data="widget_data"
                        :fw="fw"
                        :page="page"
                        :count="count"
                    />
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

// Embed Styles
import EmbedGrid from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedGrid.vue'
import EmbedColumn from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedColumn.vue'
import EmbedPage from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedPage.vue'
import EmbedList from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedList.vue'
import EmbedTable from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedTable.vue'
import EmbedSlider from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedSlider.vue'
import EmbedCarousel from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedCarousel.vue'
import EmbedDirectory from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedDirectory.vue'
import EmbedNuggets from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedNuggets.vue'
import EmbedSidebarSlider from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedSidebarSlider.vue'
import EmbedSidebarNuggets from '@/views/panel/Business/Widgets/Render/Styles/Embeds/EmbedSidebarNuggets.vue'

// Hover Styles
import Hover1 from '@/views/panel/Business/Widgets/Render/Styles/Hovers/Hover1.vue'
import Hover2 from '@/views/panel/Business/Widgets/Render/Styles/Hovers/Hover2.vue'
import Hover3 from '@/views/panel/Business/Widgets/Render/Styles/Hovers/Hover3.vue'
import Hover4 from '@/views/panel/Business/Widgets/Render/Styles/Hovers/Hover4.vue'
import Hover5 from '@/views/panel/Business/Widgets/Render/Styles/Hovers/Hover5.vue'
import Hover6 from '@/views/panel/Business/Widgets/Render/Styles/Hovers/Hover6.vue'
import Hover7 from '@/views/panel/Business/Widgets/Render/Styles/Hovers/Hover7.vue'

// Badge Styles
import Badge1 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge1.vue'
import Badge2 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge2.vue'
import Badge3 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge3.vue'
import Badge4 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge4.vue'
import Badge5 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge5.vue'
import Badge6 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge6.vue'
import Badge7 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge7.vue'
import Badge8 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge8.vue'
import Badge9 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge9.vue'
import Badge10 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge10.vue'
import Badge11 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge11.vue'
import Badge12 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge12.vue'
import Badge13 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge13.vue'
import Badge14 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge14.vue'
import Badge15 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge15.vue'
import Badge16 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge16.vue'
import Badge17 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge17.vue'
import Badge18 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge18.vue'
import Badge19 from '@/views/panel/Business/Widgets/Render/Styles/Badges/Badge19.vue'

// Rotator Styles
import Rotator1 from '@/views/panel/Business/Widgets/Render/Styles/Rotators/Rotator1.vue'
import Rotator2 from '@/views/panel/Business/Widgets/Render/Styles/Rotators/Rotator2.vue'
import Rotator3 from '@/views/panel/Business/Widgets/Render/Styles/Rotators/Rotator3.vue'
import Rotator4 from '@/views/panel/Business/Widgets/Render/Styles/Rotators/Rotator4.vue'
import Rotator5 from '@/views/panel/Business/Widgets/Render/Styles/Rotators/Rotator5.vue'

import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import HeaderAggregate from '@/views/panel/Business/Widgets/Render/HeaderAggregate.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import Pagination from '@/views/panel/Business/Widgets/Render/Pagination.vue'

export default {
    props: ["preview_widget", "fw"],

    components: {
        EmbedGrid,
        EmbedColumn,
        EmbedPage,
        EmbedList,
        EmbedTable,
        EmbedSlider,
        EmbedCarousel,
        EmbedDirectory,
        EmbedNuggets,
        EmbedSidebarSlider,
        EmbedSidebarNuggets,

        Hover1,
        Hover2,
        Hover3,
        Hover4,
        Hover5,
        Hover6,
        Hover7,

        Badge1,
        Badge2,
        Badge3,
        Badge4,
        Badge5,
        Badge6,
        Badge7,
        Badge8,
        Badge9,
        Badge10,
        Badge11,
        Badge12,
        Badge13,
        Badge14,
        Badge15,
        Badge16,
        Badge17,
        Badge18,
        Badge19,

        Rotator1,
        Rotator2,
        Rotator3,
        Rotator4,
        Rotator5,

        Header,
        HeaderAggregate,
        Review,
        Pagination
    },

    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        widget_data: false,
        scroll_working: false,
        current_visible: 0,
        show_rating: false,
        reviews_start: 0,
        finishedLoadingReviews: false,
        limit: 20,
        page: 1,
        count: 3,
        isPreview: false,

        me: null,

        slider: [
            "red", 
            "green", 
            "orange", 
            "blue", 
            "pink", 
            "purple", 
            "indigo", 
            "cyan", 
            "deep-purple", 
            "light-green", 
            "deep-orange", 
            "blue-grey"
        ],

        selected_tab: 'all'
      }
    },
    computed: {
        visibleReviews () {
            return this.widget_data.reviews.filter(p=>p.isActive).length
        }
    },
    methods: {
        showDialog: function() {
            let self = this
            this.dialog = true
            setTimeout(function() {
                self.show_rating = true
            }, 100);

            if (this.widget_data.widget.template.indexOf('badge') != -1 && this.widget_data.widget.settings.badge_display == 'Embed') {

            }
            else {
                parent.postMessage("showReviews","*");  //  `*` on any domain  
            }
        },
        closeDialog: function() {
            this.dialog = false, this.show_rating = false, parent.postMessage('closeReviews','*')
        },
        getWidgetData: async function() {
            let self = this

            let thistoken = ((this.$route.params.token) ? this.$route.params.token : this.preview_widget.token);

            try {
                let url = process.env.VUE_APP_API_ENDPOINT + '/widgets/get-data/' + thistoken;

                let params = {};

                // Preview
                let exploded = window.location.href.split("?fw");
                if (self.fw) {
                    params.fw = self.fw;
                    self.isPreview = true;
                    url = url + '?selected_tab=' + this.selected_tab;
                }
                else if (exploded.length > 1) {
                    params.fw = exploded[1];
                    url = url + '?selected_tab=' + this.selected_tab;
                }
                else {
                    url = url + '?selected_tab=' + this.selected_tab;
                }
                // END Preview
                
                let response = await axios.post(url, params, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    },
                })
                
                this.widget_data = response.data.widget_data

                if (this.widget_data.widget.template == 'embed-slider' ||
                    this.widget_data.widget.template == 'embed-carousel' || 
                    this.widget_data.widget.template == 'embed-nuggets' ||
                    this.widget_data.widget.template == 'embed-sidebar-slider' ||
                    this.widget_data.widget.template == 'embed-sidebar-nuggets') {

                    this.limit = 200;

                }
                else {
                    this.limit = this.widget_data.widget.settings.reviews_per_page;
                }

                this.count = Math.ceil(this.widget_data.total_reviews/this.limit);

                // Resize full width for embed widgets
                if (
                    this.widget_data.widget.template.indexOf("embed-") != -1 ||
                    this.widget_data.widget.template.indexOf("badge-") != -1
                ) {
                    setTimeout(function() {
                        try {
                            let thisInterval = setInterval(function() {
                                if (document.querySelector(".widgets-wrapper") === null) {
                                    clearInterval(thisInterval);
                                    thisInterval = null;
                                    return;
                                }

                                let new_height = document.querySelector(".widgets-wrapper").offsetHeight + 70;

                                parent.postMessage({
                                    event_id: 'resizeWidgetIframe_' + self.widget_data.widget.id,
                                    data: {
                                        new_height: new_height
                                    }
                                },"*");  //  `*` on any domain
                            }, 100);
                        }
                        catch (e) {}
                    }, 100);
                }
                // END Resize full width for embed widgets
                
                // Apply custom CSS
                if (this.widget_data.widget.settings.custom_css) {
                    if (document.getElementById("rg-widget-custom-css-sheet") === null) {
                        var styleSheet = document.createElement("style")
                        styleSheet.type = "text/css"
                        styleSheet.innerText = this.widget_data.widget.settings.custom_css.replace(/\n/g, " ")
                        styleSheet.id = 'rg-widget-custom-css-sheet';
                        document.body.appendChild(styleSheet);
                    }
                    else {
                        document.getElementById("rg-widget-custom-css-sheet").innerText = this.widget_data.widget.settings.custom_css.replace(/\n/g, " ");
                    }
                }
                else {
                    if (document.getElementById("rg-widget-custom-css-sheet") === null) {
                        var styleSheet = document.createElement("style")
                        styleSheet.type = "text/css"
                        styleSheet.innerText = ""
                        styleSheet.id = 'rg-widget-custom-css-sheet';
                        document.body.appendChild(styleSheet);
                    }
                    else {
                        document.getElementById("rg-widget-custom-css-sheet").innerText = '';
                    }
                }
                // END Apply custom CSS
            }catch(e){
            }
        },
        fetchMoreReviews: async function(new_start = false) {
            let self = this

            if (this.widget_data.widget.template == 'embed-slider' ||
                this.widget_data.widget.template == 'embed-carousel' || 
                this.widget_data.widget.template == 'embed-nuggets' ||
                this.widget_data.widget.template == 'embed-sidebar-slider' ||
                this.widget_data.widget.template == 'embed-sidebar-nuggets') {

                this.limit = 200;

            }
            else {
                this.limit = this.widget_data.widget.settings.reviews_per_page;
            }

            if (new_start !== false)
                self.reviews_start = new_start
            else
                self.reviews_start = self.reviews_start + parseInt(this.limit);

            let thistoken = ((this.$route.params.token) ? this.$route.params.token : this.preview_widget.token);

            try {
                let url = process.env.VUE_APP_API_ENDPOINT + '/widgets/get-data/' + thistoken + "?start=" + self.reviews_start + "&limit=" + this.limit;

                let params = {};

                // Preview
                let exploded = window.location.href.split("?fw");
                if (self.fw) {
                    params.fw = self.fw;
                    self.isPreview = true;
                }
                else if (exploded.length > 1) {
                    params.fw = exploded[1];
                }
                // END Preview
                
                let response = await axios.post(url, params, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                    },
                })
                
                if (response.data.widget_data.reviews.length == 0) {
                    self.finishedLoadingReviews = true;
                }
                else {
                    for (var i in response.data.widget_data.reviews) {
                        this.widget_data.reviews.push(response.data.widget_data.reviews[i]);
                    }

                    if (response.data.widget_data.reviews.length < this.limit)
                        self.finishedLoadingReviews = true;
                }
            }catch(e){

            }
        },
        paginate(val, oldVal) {
            this.widget_data.reviews = [];
            let new_start = (val - 1) * parseInt(this.limit);
            this.fetchMoreReviews(new_start);
        },

        getWidth() {
            if (window.innerWidth > 450)
                return 450;
            
            return window.innerWidth;
        },

        styleBody() {
            var styleSheet = document.createElement("style")
            styleSheet.type = "text/css"
            styleSheet.innerText = 'body::-webkit-scrollbar { display: none;  }';
            styleSheet.id = 'rg-widgets-custom-css-sheet';
            document.body.appendChild(styleSheet);
        }
    },
    created() {
        this.getWidgetData();

        if (!this.fw)
            this.styleBody();
    }
}
</script>

<style type="text/css">
    body, html, .theme--light.v-application {
        background: none transparent !important;
    }

    .widgets-wrapper {
        position: absolute;
        bottom: 10px;
    }

    .widgets-wrapper.Left {
        left: 15px;
    }

    .widgets-wrapper.Right {
        right: 15px;
    }

    .widgets-wrapper.Full {
        position: relative;
    }

    .hover-1-wrapper *, .hover-3-wrapper *, .hover-4-wrapper * {
        text-align: center;
        line-height: 24px;
    }

    .reviews-badge {
        margin-top: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
    }

    .reviews-title {
        font-size: 18px;
    }

    .reviews-average {
        font-size: 36px;
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .hover-3-wrapper .reviews-average {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .reviews-read-link a {
        text-decoration: underline;
        font-size: 12px;
    }

    .hover-3-wrapper .reviews-read-link a {
        font-size: 14px;
    }

    .v-dialog {
        position: absolute !important;
        top: 0px !important;
        margin: 0px !important;
        bottom: 0px !important;
    }

    .v-dialog.Left {
        left: 0px !important;
        border-right: solid 1px #CCCCCC;
    }

    .v-dialog.Right {
        right: 0px !important;
        border-left: solid 1px #CCCCCC;
    }

    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: inherit !important;
    }

    .v-dialog > .main-card {
        height: 100%;
    }

    .reviews-footer {
        font-size: 12px;
    }

   .hover-2-wrapper .reviews-footer, .hover-3-wrapper .reviews-footer {
        margin-top: 5px;
        text-align: center;
    }

    #drift-frame-controller {
        display: none;
    }

    .dialog-close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .scrollable-reviews-list {
        padding-left: 20px;
        padding-right: 20px;
        height: 100%;
        overflow: auto;
    }

    .scrollable-reviews-list.dialog-reviews-list.c2c-enabled {
        height: calc(100% - 190px);
        margin-bottom: 20px;
    }

    .widget-source-logo {
        float: right;
        width: 22px;
        height: 22px;
        opacity: 0.8;
        margin-right: 0px;
        margin-top: 8px;
    }

    .widget-source-logo-embed-column {
        float: left;
        width: 22px;
        height: 22px;
        opacity: 0.8;
        margin-right: 10px;
        margin-top: 8px;
    }

    .hover-3-variant-item {
        margin-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .v-dialog, .v-sheet.v-card {
        border-radius: 0px !important;
    }

    .v-rating .v-icon {
        padding: 0px !important;
    }

    .v-window__prev, .v-window__next {
        margin: 0px !important;
    }

    .embed-carousel-wrapper .v-window__prev, .embed-carousel-wrapper .v-window__next {
        display: none;
    }

    .embed-carousel-wrapper .v-carousel__controls {
        background: transparent;
        position: relative;
        margin-top: -30px;
    }

    .embed-carousel-wrapper .theme--dark.v-btn.v-btn--icon {
        color: gray;
    }

    .embed-slider-wrapper .v-carousel__controls {
        display: none;
    }

    @media screen and (max-width: 500px) {
        .widgets-wrapper.Left {
            left: 0px !important;
            right: 0px !important;
            bottom: 0px !important;
        }

        .widgets-wrapper.Right {
            left: 0px !important;
            right: 0px !important;
            bottom: 0px !important;
        }

        .widgets-wrapper > div {
            width: 100% !important;
            padding-bottom: 0px !important;
        }
    }
</style>