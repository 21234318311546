import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from './modules/auth'
import integrations from './modules/integrations'
import contacts from './modules/contacts'
import campaigns from './modules/campaigns'
import calls from './modules/calls'
import bulk_sms from './modules/bulk_sms'
import businesses from './modules/businesses'
import locations from './modules/locations'
import stats from './modules/stats'
import templates from './modules/templates'
import listings from './modules/listings'
import reviews from './modules/reviews'
import feedback from './modules/feedback'
import widgets from './modules/widgets'
import local_recon_widgets from './modules/local_recon_widgets'
import me from './modules/me'

import members_area from './modules/members_area'

import users from './modules/users'

import scans from './modules/scans'

import activity from './modules/activity'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth']
})

export default new Vuex.Store({
  modules: {
    auth,
    integrations,
    contacts,
    campaigns,
    calls,
    bulk_sms,
    businesses,
    locations,
    stats,
    templates,
    listings,
    reviews,
    feedback,
    widgets,
    local_recon_widgets,
    me,
    members_area,
    activity,
    users,
    scans
  },
  state: {},
  mutations: {},
  actions: {},
  plugins: [vuexLocal.plugin]
})