<template>
    <div class="badge-3-wrapper" :style="((widget_data.widget.settings.badge_display == 'Embed' ? 'margin: auto;': ''))">
        <v-card
            elevation="2"
            class="reviews-badge"
            @click="$parent.showDialog()"
            rounded
            :style="'border-radius: 0px !important; padding-bottom: 0px; background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';'"
        >
            <div class="clearfix">
                <div class="float-left" style="width: 60px; padding-top: 5px; padding-left: 10px;">
                    <div v-if="widget_data.widget.settings.platforms.length == 1">
                        <img v-for="(item, index) in widget_data.widget.settings.listings" :key="index" v-show="listingEnabled(item.id)"
                            :src="getIconUrl(item.url)"
                            class="listing-img"
                        >
                    </div>
                    <div v-else>
                        <i class="fa fa-star" :style="'font-size: 45px; color: ' + widget_data.widget.settings.star_color"></i>
                    </div>
                </div>
            

                <div class="float-left" style="width: calc(100% - 60px); padding-left: 10px; padding-top: 2px;">
                    <div style="display: inline-block;">
                        <v-rating
                            class="reviews-rating -ml-1"
                            :value="parseFloat(widget_data.average_rating)"
                            :color="widget_data.widget.settings.star_color"
                            dense
                            half-increments
                            readonly
                            size="24"
                        ></v-rating>
                    </div>

                    <div class="reviews-title reviews-read-link text-sm">
                        {{ ((widget_data.widget.settings.badge_3_title) ? widget_data.widget.settings.badge_3_title.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : widget_data.average_rating + " score, " + widget_data.total_reviews + " reviews") }}
                    </div>
                </div>
            </div>

            <div class="clearfix"></div>

            <div class="bottom-border" :style="'background: ' + widget_data.widget.settings.badge3_bottom_color">
                {{ ((widget_data.widget.settings.badge_3_subtitle) ? widget_data.widget.settings.badge_3_subtitle.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Our Rating") }}
            </div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import urlParser from 'url-parse'

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },
    
    methods: {
        getHostname(url) {
            return urlParser(url).hostname.replace("www.", "")
        },
        getPathname(url) {
            return urlParser(url).pathname
        },
        getIconUrl(url) {
            return require('@/assets/images/review-services/www.' + this.getHostname(url) + ".png");
        },
        listingEnabled(id) {
            console.log('id', id);
            console.log('this.widget_data.widget.settings.platforms', this.widget_data.widget.settings.platforms);
            for (var i in this.widget_data.widget.settings.platforms) {
                if (this.widget_data.widget.settings.platforms[i] == id)
                    return true;
            }

            return false;
        }
    }
}
</script>

<style type="text/css" scoped>
    .badge-3-wrapper {
        width: 235px;
        padding-bottom: 10px;
    }

    .bottom-border {
        width: calc(100% + 3px);
        margin-bottom: -2px;
        margin-top: 10px;
        margin-left: -1px;
        color: #FFFFFF;
        padding: 2px;
        text-align: center;
        font-size: 12px;
    }

    .listing-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-block;
    }
</style>