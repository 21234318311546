<template>
  <v-app>

    <v-main :class="$router.currentRoute.name">
      <router-view />
    </v-main>

  </v-app>
</template>

<style>
  .text-white {
      color: #fff !important;
  }

  .text-gray-600 {
    color: #757575 !important;
  }

  .font-semibold, .text-gray-700 {
    color: #616161 !important;
  }

  /* #gs {
    visibility: hidden !important;
  } */

  .not-mobile {
    display: initial;
  }

  .mobile-only {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .not-mobile {
      display: none;
    }

    .mobile-only {
      display: initial;
    }

    body {
      overflow: hidden;
    }
  }
</style>

<script>

import axios from 'axios'
import { mapState } from 'vuex'

export default {

  data: function() {
    return {
      loading: true,
      error: false
    }
  },
  methods: {
  },
  computed: {
    ...mapState({
      me: state => state.me.me
    })
  },
  created() {
    let self = this

    if (process.env.NODE_ENV != 'development') {
      if (window.location.protocol == "http:") {
        window.location = window.location.href.replace("http://", "https://")
        return;
      }
    }

    // Apply custom CSS

    let favIconEl = document.createElement("link");
    favIconEl.rel = "icon";
    favIconEl.id = "ReviewGrowerFavicon";

    let faviconHref = 'data:;base64,=';

    if (this.$agency) {
      if (this.$agency.settings.custom_css) {
        var styleSheet = document.createElement("style")
        styleSheet.type = "text/css"
        styleSheet.innerText = this.$agency.settings.custom_css.replace(/\n/g, " ")
        styleSheet.id = 'rg-custom-css-sheet';
        document.body.appendChild(styleSheet);
      }
      else {
        var styleSheet = document.createElement("style")
        styleSheet.type = "text/css"
        styleSheet.innerText = ""
        styleSheet.id = 'rg-custom-css-sheet';
        document.body.appendChild(styleSheet);
      }

      if (this.$agency.settings.favicon)
        faviconHref = this.$agency.settings.favicon;

      processCustomChatWidget();

      function processCustomChatWidget() {
        if (!self.me) {
          setTimeout(function() {
            processCustomChatWidget();
          }, 500);
          return;
        }

        if (self.me.parent_id > 0) {
          if (self.$agency.settings.chat_widget_code) {
            window.d = ''; window.s = '';
            let chatWidgetCode = self.$agency.settings.chat_widget_code;
            chatWidgetCode = chatWidgetCode.replace(/(<([^>]+)>)/gi, "");

            eval(chatWidgetCode);
          }

          if (self.$agency.settings.chat_widget_code_2) {
            window.d = ''; window.s = '';
            let chatWidgetCode = self.$agency.settings.chat_widget_code_2;
            chatWidgetCode = chatWidgetCode.replace(/(<([^>]+)>)/gi, "");

            eval(chatWidgetCode);
          }
        }
      }
    }
    else {
      faviconHref = '/favicon.png';
    }

    favIconEl.href = faviconHref;

    document.head.appendChild(favIconEl);
    // END Apply custom CSS
  }
};
</script>