import Vue from 'vue'
import Router from 'vue-router'

// Public
import Home from './views/landing/Home.vue'
import Signup from './views/landing/Signup.vue'
import SignupOmniTalk from './views/landing/SignupOmniTalk.vue'
import SignupConfirm from './views/landing/SignupConfirm.vue'
import Login from './views/landing/Login.vue'
import ForgotPassword from './views/landing/ForgotPassword.vue'
import ResetPassword from './views/landing/ResetPassword.vue'

// Private
import Onboarding from './views/panel/Onboarding/Index.vue'
import Dashboard from './views/panel/Dashboard/Index.vue'
import Import from './views/panel/Import.vue'
import Settings from './views/panel/Settings.vue'
import ReviewFunnel from './views/panel/ReviewFunnel.vue'
import ReviewFunnelPublic from './views/panel/ReviewFunnelPublic.vue'
import UserSettings from './views/panel/UserSettings.vue'
import Contacts from './views/panel/Contacts.vue'
import ContactDetails from './views/panel/ContactDetails.vue'
import Campaigns from './views/panel/Business/Campaigns/Index.vue'
import CampaignsForm from './views/panel/Business/Campaigns/Form.vue'
import BulkActions from './views/panel/Business/BulkActions/Index.vue'
import BulkActionsForm from './views/panel/Business/BulkActions/Form.vue'
import ContactForm from './views/panel/ContactForm.vue'
import ContactFormPublic from './views/panel/ContactFormPublic.vue'
import AddContactExternal from './views/panel/AddContactExternal.vue'
import Reviews from './views/panel/Reviews.vue'
import Feedback from './views/panel/Feedback.vue'
import Billing from './views/panel/Billing.vue'
import SocialPosting from './views/panel/SocialPosting.vue'
import SocialLoginDone from './views/panel/SocialLoginDone.vue'
import Widgets from './views/panel/Business/Widgets/Index.vue'
import WidgetsForm from './views/panel/Business/Widgets/Form.vue'
import WidgetsFormEmbed from './views/panel/Business/Widgets/FormEmbed.vue'
import WidgetsPreview from './views/panel/Business/Widgets/Preview.vue'
import WidgetsRender from './views/panel/Business/Widgets/Render/Index.vue'
import ContactWidgets from './views/panel/Business/ContactWidgets/Index.vue'
import ContactWidgetsCalls from './views/panel/Business/ContactWidgets/Calls.vue'
import ContactWidgetsAnalytics from './views/panel/Business/ContactWidgets/Analytics.vue'
import ContactWidgetsForm from './views/panel/Business/ContactWidgets/Form.vue'
import ContactWidgetsDetails from './views/panel/Business/ContactWidgets/Details.vue'
import ContactWidgetsFormEmbed from './views/panel/Business/ContactWidgets/FormEmbed.vue'
import ContactWidgetsPreview from './views/panel/Business/ContactWidgets/Preview.vue'
import ContactWidgetsRender from './views/panel/Business/ContactWidgets/Render/Index.vue'
import ReviewPlatforms from './views/panel/ReviewPlatforms.vue'
import ProviderSuccess from './views/panel/ProviderSuccess.vue'
import Locations from './views/panel/Locations.vue'
import Inbox from './views/panel/Inbox.vue'
import MembersArea from './views/panel/MembersArea.vue'

// Agency components
import Businesses from './views/panel/Agency/Businesses.vue'
import Plans from './views/panel/Agency/Plans.vue'
import PlanForm from './views/panel/Agency/PlanForm.vue'
import AgencySettings from './views/panel/Agency/Settings.vue'
import Resources from './views/panel/Agency/Resources.vue'
// END Agency components

// Admin components
import Agencies from './views/panel/Admin/Agencies.vue'
// END Admin components

import EmailVerify from './views/panel/EmailVerify.vue'

// Local Recon
import LocalReconDashboard from './views/local-recon/Dashboard.vue'
import LocalReconLeads from './views/local-recon/Leads.vue'
import LocalReconReports from './views/local-recon/Reports.vue'
import LocalReconWidgets from './views/local-recon/Widgets.vue'
import LocalReconWidgetForm from './views/local-recon/WidgetForm.vue'
import LocalReconReportPublic from './views/local-recon/ReportPublic.vue'
import LocalReconWidgetsRender from './views/local-recon/WidgetsRender.vue'

// Common
import Users from './views/panel/Users.vue'
import GridTracker from './views/panel/GridTracker.vue'
import GridTrackerApp from './views/panel/GridTrackerApp.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup
    },
    {
      path: '/signup/plan/:plan_id',
      name: 'signup_plan',
      component: Signup
    },
    {
      path: '/signup-omnitalk',
      name: 'signup-omnitalk',
      component: SignupOmniTalk
    },
    {
      path: '/signup-omnitalk/plan/:plan_id',
      name: 'signup-omnitalk-plan',
      component: SignupOmniTalk
    },
    {
      path: '/signup/:company_type/plan/:plan_id',
      name: 'signup_company_type',
      component: Signup
    },
    {
      path: '/signup/confirm',
      name: 'signup_confirm',
      component: SignupConfirm
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: SignupConfirm
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: ResetPassword
    },
    // {
    //   path: '/blog',
    //   name: 'blog',
    //   component: Blog
    // },
    // {
    //   path: '/blog/should-you-buy-online-reviews',
    //   component: () => import('./views/landing/articles/should-you-buy-online-reviews.vue')
    // },
    // {
    //   path: '/contact',
    //   name: 'contact',
    //   component: Contact
    // },
    // {
    //   path: '/privacy-policy',
    //   name: 'privacypolicy',
    //   component: Privacy
    // },
    // {
    //   path: '/terms-of-service',
    //   name: 'termsofservice',
    //   component: Terms
    // },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/business/:id',
      name: 'Business',
      component: () => import('./views/panel/BusinessDetail.vue')
    },
    {
      path: '/import',
      name: 'import',
      component: Import
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings
    },
    {
      path: '/review-funnel',
      name: 'review-funnel',
      component: ReviewFunnel
    },
    {
      path: '/user-settings',
      name: 'user-settings',
      component: UserSettings
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts
    },
    {
      path: '/contacts/campaigns',
      name: 'campaigns',
      component: Campaigns
    },
    {
      path: '/contacts/campaigns/:id',
      name: 'campaigns_form',
      component: CampaignsForm
    },
    {
      path: '/contacts/bulk-actions',
      name: 'bulk_actions',
      component: BulkActions
    },
    {
      path: '/contacts/bulk-sms/:id',
      name: 'bulk_sms_form',
      component: BulkActionsForm
    },
    {
      path: '/contacts/form',
      name: 'contact_form',
      component: ContactForm
    },
    {
      path: '/contact-form/:uuid',
      name: 'contact_form_public',
      component: ContactFormPublic
    },
    {
      path: '/contacts/:id',
      name: 'contact_details',
      component: ContactDetails
    },
    {
      path: '/add-contact/:uuid',
      name: 'add-contact',
      component: AddContactExternal
    },
    {
      path: '/reviews',
      name: 'reviews',
      component: Reviews
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: Feedback
    },
    {
      path: '/billing',
      name: 'billing',
      component: Billing
    },
    {
      path: '/social-posting',
      name: 'social-posting',
      component: SocialPosting
    },
    {
      path: '/social-login-done',
      name: 'social-login-done',
      component: SocialLoginDone
    },
    {
      path: '/social-share/:review_id',
      name: 'social-share',
      component: SocialPosting
    },
    {
      path: '/widgets',
      name: 'widgets',
      component: Widgets
    },
    {
      path: '/widgets/create',
      name: 'widgets_create',
      component: WidgetsForm
    },
    {
      path: '/widget-iframe-creator',
      name: 'widgets_embed',
      component: WidgetsFormEmbed
    },
    {
      path: '/widgets/edit/:token',
      name: 'widgets_edit',
      component: WidgetsForm
    },
    {
      path: '/widgets/edit/:token/:action',
      name: 'widgets_edit_token',
      component: WidgetsForm
    },
    {
      path: '/widgets/preview/:token',
      name: 'widgets_preview',
      component: WidgetsPreview
    },
    {
      path: '/widgets/render/:token',
      name: 'widgets_render',
      component: WidgetsRender
    },
    {
      path: '/widgets/contact',
      name: 'contact_widgets',
      component: ContactWidgets
    },
    {
      path: '/widgets/contact/calls',
      name: 'contact_widgets_calls',
      component: ContactWidgetsCalls
    },
    {
      path: '/widgets/contact/analytics',
      name: 'contact_widgets_analytics',
      component: ContactWidgetsAnalytics
    },
    {
      path: '/widgets/contact/create',
      name: 'contact_widgets_create',
      component: ContactWidgetsForm
    },
    {
      path: '/widget-iframe-creator',
      name: 'contact_widgets_embed',
      component: ContactWidgetsFormEmbed
    },
    {
      path: '/widgets/contact/edit/:token',
      name: 'contact_widgets_edit',
      component: ContactWidgetsForm
    },
    {
      path: '/widgets/contact/view/:token',
      name: 'contact_widgets_view',
      component: ContactWidgetsDetails
    },
    {
      path: '/widgets/contact/edit/:token/:action',
      name: 'contact_widgets_edit_token',
      component: ContactWidgetsForm
    },
    {
      path: '/widgets/contact/preview/:token',
      name: 'contact_widgets_preview',
      component: ContactWidgetsPreview
    },
    {
      path: '/widgets/contact/render/:token',
      name: 'contact_widgets_render',
      component: ContactWidgetsRender
    },
    {
      path: '/review-platforms',
      name: 'reviewplatforms',
      component: ReviewPlatforms
    },
    {
      path: '/provider/:devslug/success',
      name: 'providersuccess',
      component: ProviderSuccess
    },
    {
      path: '/auth/:provider/callback',
      component: {
        template: '<div class="auth-component"></div>'
      }
    },
    {
      path: '/locations',
      name: 'locations',
      component: Locations
    },

    // Agency routes
    {
      path: '/businesses',
      name: 'businesses',
      component: Businesses
    },

    {
      path: '/plans',
      name: 'plans',
      component: Plans
    },

    {
      path: '/plans/create',
      name: 'create_plan',
      component: PlanForm
    },

    {
      path: '/plans/edit/:id',
      name: 'plans_edit',
      component: PlanForm
    },

    {
      path: '/agency-settings',
      name: 'agency-settings',
      component: AgencySettings
    },
    {
      path: '/resources',
      name: 'resources',
      component: Resources
    },
    // END Agency routes

    // Admin routes
    {
      path: '/agencies',
      name: 'agencies',
      component: Agencies
    },
    // END Admin routes

    {
      path: '/email/verify/:user_id/:token',
      name: 'email-verify',
      component: EmailVerify
    },


    // Local Recon Routes
    {
      path: '/local-recon/dashboard',
      name: 'local_recon_dashboard',
      component: LocalReconDashboard
    },
    {
      path: '/local-recon/leads',
      name: 'local_recon_leads',
      component: LocalReconLeads
    },
    {
      path: '/local-recon/reports',
      name: 'local_recon_reports',
      component: LocalReconReports
    },
    {
      path: '/local-recon/widgets',
      name: 'local_recon_widgets',
      component: LocalReconWidgets
    },
    {
      path: '/local-recon/widgets/create',
      name: 'local_recon_widgets_create',
      component: LocalReconWidgetForm
    },
    {
      path: '/local-recon/widgets/edit/:token',
      name: 'local_recon_widgets_edit',
      component: LocalReconWidgetForm
    },
    {
      path: '/local-recon/widgets/edit/:token/:action',
      name: 'local_recon_widgets_edit_token',
      component: LocalReconWidgetForm
    },
    {
      path: '/rf',
      name: 'local_recon_report',
      component: LocalReconReportPublic
    },
    {
      path: '/local-recon/widgets/render/:token',
      name: 'widgets_render_local_recon',
      component: LocalReconWidgetsRender
    },
    // END Local Recon Routes

    // Common routes
    {
      path: '/users',
      name: 'users',
      component: Users
    },

    {
      path: '/grid-tracker-list',
      name: 'grid-tracker-list',
      component: GridTracker
    },

    {
      path: '/grid-tracker/app',
      name: 'grid-tracker-app',
      component: GridTrackerApp
    },

    {
      path: '/inbox',
      name: 'Inbox',
      component: Inbox
    },

    {
      path: '/members-area',
      name: 'MembersArea',
      component: MembersArea
    },


    // This needs to be the last item
    {
      path: '/:review_funnel_short_name',
      name: 'review-funnel-public',
      component: ReviewFunnelPublic
    },
  ]
})
