<template>

  <div>
    <dialog-add-contact ref="dialogAddContact"></dialog-add-contact>
    <dialog-integration-csvfile ref="dialogCsvFile"></dialog-integration-csvfile>
    <dialog-invites-log ref="dialogInvitesLog"></dialog-invites-log>
    <dialog-contacts-settings ref="dialogContactsSettings"></dialog-contacts-settings>
    <dialog-copy-link-to-form ref="dialogCopyLinkToForm" :contact_form="contact_form"></dialog-copy-link-to-form>
    <dialog-webhook ref="dialogWebhook"></dialog-webhook>

    <div v-if="showBulkInviteModal" @click.self="showBulkInviteModal = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/4 lg:w-1/4 bg-white rounded-lg mx-6">

        <div class="rg-modal-header">
          Add Selected Contacts to Campaign

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="showBulkInviteModal = false"><i class="fa fa-times"></i></a>
        </div>

        <div class="p-6">
          <div class="mb-3">
            <p class="mb-1 text-lg mb-2"><strong>
              Select Campaign
            </strong></p>
            
            <div v-for="(campaign, index_campaign) in campaigns" :key="index_campaign" class="bg-gray-300 hover:bg-gray-200 mb-1 text-sm p-2" style="border-radius: 5px; cursor: pointer;" @click="bulkInvite(campaign)">
              <a href="javascript: void(0);" style="color: #000000 !important;">{{ campaign.name }}</a>
            </div>
          </div>

        </div>

        <div class="bg-gray-100 rounded-b-lg px-6 py-4">

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="showBulkInviteModal = false">
              CANCEL
          </button>

          <div class="clearfix"></div>

        </div>

      </div>
    </div>

    <div v-if="show_contact_log" @click.self="show_contact_log = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/2 lg:w-1/2 bg-white rounded-lg mx-6">

        <div class="rg-modal-header">
          Show Contact log for: {{ getContactName(log_contact) }}

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="show_contact_log = false"><i class="fa fa-times"></i></a>
        </div>

        <div class="p-6">
          <div class="mb-3">
            <table class="w-full rg-datatable">
              <thead class="border-b border-gray-100 w-full">
                <tr>
                  <th>Campaign Name</th>
                  <th>Status</th>
                  <th>Started</th>
                  <th>Completed On</th>
                </tr>
              </thead>
              <tbody class="w-full" style="">
                <tr v-for="(item, index) in log_contact.campaigns_to_contact" :key="item" class="hover:bg-gray-100">
                  <td>{{ item.log[0].campaign.name }}</td>
                  <td>
                    <v-menu
                      nudge-top="-35" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <a href="javascript: void(0)"
                            v-bind="attrs"
                            v-on="on"
                          >
                          <span v-if="item.status == 'in progress'" style="color: orange !important;">{{ item.status }}</span>
                          <span v-if="item.status == 'completed'" style="color: green !important;">{{ item.status }}</span>  
                          <br />
                          (view details)
                          </a>
                        </template>
                        <v-list>
                          <v-list-item v-for="item2 in item.log" :key="item2.id">
                            <i v-if="item2.status == 'pending'" class="fa fa-clock" style="color: orange; width: 20px;"></i>
                            <i v-if="item2.status == 'in-progress'" class="fa fa-sync fa-spin" style="color: gray; margin-right: 7px;"></i>
                            <i v-if="item2.status == 'completed' || item2.status == 'clicked'" class="fa fa-check" style="color: green; width: 20px;"></i>
                            <i v-if="item2.status == 'canceled'" class="fa fa-ban" style="color: orange; width: 20px;"></i>

                            <v-tooltip v-if="item2.status == 'failed'" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <i v-bind="attrs" v-on="on" class="fa fa-times" style="color: red; width: 20px;"></i>
                              </template>
                              <span>{{ displayResult(item2.result) }}</span>
                            </v-tooltip>
                            
                            {{ item2.title }}

                            <v-chip
                                class="ml-2"
                                color="green"
                                text-color="white"
                                x-small
                                v-if="item2.status == 'clicked'"
                            >
                                clicked
                            </v-chip>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                  </td>
                  <td>
                    {{ fromNow(item.created_at) }}
                  </td>
                  <td>
                    <span v-if="item.status == 'completed'">
                      {{ fromNow(item.updated_at) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

        <div class="bg-gray-100 rounded-b-lg px-6 py-4">

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="show_contact_log = false">
              Close
          </button>

          <div class="clearfix"></div>

        </div>

      </div>
    </div>

    <div v-if="showAddTagModal" @click.self="showAddTagModal = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/4 lg:w-1/4 bg-white rounded-lg mx-6">

        <div class="rg-modal-header">
          Add Tags to Selected Contacts

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="showAddTagModal = false"><i class="fa fa-times"></i></a>
        </div>

        <div class="p-6">
          <div class="mb-3">
            <p class="mb-1 text-lg mb-2"><strong>
              Select Tags
            </strong></p>
            
            <v-combobox
              v-model="selected_tags"
              :items="tags_items"
              label="Select tags"
              multiple
              outlined
              dense
              hide-details
            ></v-combobox>
          </div>

        </div>

        <div class="bg-gray-100 rounded-b-lg px-6 py-4">

          <button class="rounded bg-blue text-white text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="addTags(selectedContactIds, selected_tags, 'contact')">
              <span v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
              <span v-else>ADD</span>
          </button>

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="showAddTagModal = false">
              CANCEL
          </button>

          <div class="clearfix"></div>

        </div>

      </div>
    </div>

    <div v-if="showRemoveTagModal" @click.self="showRemoveTagModal = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/4 lg:w-1/4 bg-white rounded-lg mx-6">

        <div class="rg-modal-header">
          Remove Tags from Selected Contacts

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="showRemoveTagModal = false"><i class="fa fa-times"></i></a>
        </div>

        <div class="p-6">
          <div class="mb-3">
            <p class="mb-1 text-lg mb-2"><strong>
              Select Tags
            </strong></p>
            
            <v-combobox
              v-model="selected_tags"
              :items="tags_items"
              label="Select tags"
              multiple
              outlined
              dense
              hide-details
            ></v-combobox>
          </div>

        </div>

        <div class="bg-gray-100 rounded-b-lg px-6 py-4">

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="removeTags(selectedContactIds, selected_tags, 'contact')">
              <span v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
              <span v-else>REMOVE</span>
          </button>

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="showRemoveTagModal = false">
              CANCEL
          </button>

          <div class="clearfix"></div>

        </div>

      </div>
    </div>

    <div v-if="showSendSmsModal" @click.self="showSendSmsModal = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/3 lg:w-1/3 bg-white rounded-lg mx-6">

        <div class="rg-modal-header">
          Send SMS to Selected Contacts

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="showSendSmsModal = false"><i class="fa fa-times"></i></a>
        </div>

        <div class="p-6">
          <div class="mb-3">
             <div>
                <div class="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert" v-html="'Note: the actions will be performed over a period of time. You can track the progress on the bulk actions page.'"></div>
              </div>

              <div class="w-full">
                <p class="mb-1 text-xs mb-2"><strong>Action Name</strong></p>
                <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="bulk_sms_campaign.title" />
              </div>

              <div class="w-full pt-3">
                <p class="mb-1 text-xs mb-2"><strong>From Number</strong></p>
                
                <v-select
                    v-model="bulk_sms_campaign.from_number"
                    flat
                    solo-inverted
                    hide-details
                    :items="$agency.integrations.twilio.settings.numbers"
                    label="Choose Number"
                ></v-select>

                <div class="text-xs mb-1 text-gray-700 mt-2">Number you are going to use to send broadcast from. Leave this blank and system will use default number related to the contact.</div>
              </div>

              <div class="w-full pt-3">
                <div style="float: left;">
                  <p class="mb-1 text-xs mb-2"><strong>When would you like to send?</strong></p>
                  
                  <v-radio-group
                    class="mt-2"
                    row
                    v-model="bulk_sms_campaign.send_when"
                    >
                        <v-radio
                        label="Now"
                        :value="'Now'"
                        on-icon="mdi-circle"
                        off-icon="mdi-circle-outline"
                        :ripple="false"
                        ></v-radio>
                        <v-radio
                        label="Later"
                        :value="'Later'"
                        on-icon="mdi-circle"
                        off-icon="mdi-circle-outline"
                        :ripple="false"
                        ></v-radio>
                    </v-radio-group>
                  </div>

                  <div v-if="bulk_sms_campaign.send_when == 'Later'" style="float: left; padding-left: 30px; padding-top: 20px;">
                    <date-picker v-model="bulk_sms_campaign.scheduled_on" type="datetime" valueType="format"></date-picker>
                  </div>

                  <div v-if="bulk_sms_campaign.send_when == 'Later'" style="float: left; padding-left: 30px; padding-top: 16px;">
                    <v-autocomplete
                      class="-mt-1"
                      :items="timezones"
                      label="Timezone"
                      v-model="bulk_sms_campaign.timezone"
                      outlined
                      dense
                  ></v-autocomplete>
                  </div>

                  <div style="clear: both;"></div>
              </div>

              <div class="w-full">

                <div class="float-right -mt-3">
                  <v-select
                    :items="sms_templates"
                    item-text="title"
                    item-value="content"
                    v-model="selected_sms_template"
                    outlined
                    dense
                    label="Template"
                  >
                  </v-select>
                </div>

                <p class="mb-1 text-xs mb-2"><strong>Message</strong></p>

                <div style="clear: both;"></div>

                <tinymce id="rg-tnmce-eml-tmpl" v-model="bulk_sms_campaign.message" :menubar="false"
                  :toolbar1="'mybutton'"
                  height="150"
                ></tinymce>
              </div>

              <br clear="all" />

              <div v-if="bulk_sms_error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert" v-html="bulk_sms_error"></div>
          </div>

        </div>

        <div class="bg-gray-100 rounded-b-lg px-6 py-4">

          <button class="rounded bg-blue text-white text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="sendSms()">
              <span v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
              <span v-else>SEND</span>
          </button>

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="showSendSmsModal = false">
              CANCEL
          </button>

          <div class="clearfix"></div>

        </div>

      </div>
    </div>

    <div v-if="showSendEmailModal" @click.self="showSendEmailModal = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-2/4 lg:w-2/4 bg-white rounded-lg mx-6">

        <div class="rg-modal-header">
          Send Email to Selected Contacts

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="showSendEmailModal = false"><i class="fa fa-times"></i></a>
        </div>

        <div class="p-6">
          <div class="mb-3">
             <div>
                <div class="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert" v-html="'Note: the actions will be performed over a period of time. You can track the progress on the bulk actions page.'"></div>
              </div>

              <div class="w-full">
                <p class="mb-1 text-xs mb-2"><strong>Action Name</strong></p>
                <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="bulk_email_campaign.title" />
              </div>

              <div class="w-full pt-3">
                <p class="mb-1 text-xs mb-2"><strong>Subject</strong></p>
                
                <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="bulk_email_campaign.subject" />
              </div>

              <div class="w-full pt-3">
                <div style="float: left;">
                  <p class="mb-1 text-xs mb-2"><strong>When would you like to send?</strong></p>
                  
                  <v-radio-group
                    class="mt-2"
                    row
                    v-model="bulk_email_campaign.send_when"
                    >
                        <v-radio
                        label="Now"
                        :value="'Now'"
                        on-icon="mdi-circle"
                        off-icon="mdi-circle-outline"
                        :ripple="false"
                        ></v-radio>
                        <v-radio
                        label="Later"
                        :value="'Later'"
                        on-icon="mdi-circle"
                        off-icon="mdi-circle-outline"
                        :ripple="false"
                        ></v-radio>
                    </v-radio-group>
                  </div>

                  <div v-if="bulk_email_campaign.send_when == 'Later'" style="float: left; padding-left: 30px; padding-top: 20px;">
                    <date-picker v-model="bulk_email_campaign.scheduled_on" type="datetime" valueType="format"></date-picker>
                  </div>

                  <div v-if="bulk_email_campaign.send_when == 'Later'" style="float: left; padding-left: 30px; padding-top: 16px;">
                    <v-autocomplete
                      class="-mt-1"
                      :items="timezones"
                      label="Timezone"
                      v-model="bulk_email_campaign.timezone"
                      outlined
                      dense
                  ></v-autocomplete>
                  </div>

                  <div style="clear: both;"></div>
              </div>

              <div class="w-full">

                <div class="float-right -mt-3">
                  <v-select
                    :items="email_templates"
                    item-text="title"
                    item-value="content"
                    v-model="selected_email_template"
                    outlined
                    dense
                    label="Template"
                  >
                  </v-select>
                </div>

                <p class="mb-1 text-xs mb-2"><strong>Message</strong></p>

                <div style="clear: both;"></div>
                
                <tinymce id="rg-tnmce-eml-tmpl" v-model="bulk_email_campaign.message" :menubar="false"
                  :toolbar1="'bold italic bullist  numlist link image forecolor backcolor fontselect fontsize formatselect mybutton'"
                  height="300"
                ></tinymce>
              </div>

              <br clear="all" />

              <div v-if="bulk_email_error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert" v-html="bulk_email_error"></div>
          </div>

        </div>

        <div class="bg-gray-100 rounded-b-lg px-6 py-4">

          <button class="rounded bg-blue text-white text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="sendEmail()">
              <span v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
              <span v-else>SEND</span>
          </button>

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="showSendEmailModal = false">
              CANCEL
          </button>

          <div class="clearfix"></div>

        </div>

      </div>
    </div>

    <panel-navigation>

      <div class="float-right ml-5 mt-1" v-if="!fetchingContacts">
        <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="$refs.dialogAddContact.createContact(); $refs.dialogAddContact.show()" style="margin-top: -5px;"><i class="fa fa-plus mr-2"> </i> ADD NEW CONTACT</div>
        <div class="border-1 border-blue text-primary text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="$refs.dialogCsvFile.show();" style="margin-top: -5px;"><i class="fa fa-download mr-2"> </i> IMPORT CONTACTS</div>
        <div class="border-1 border-blue text-primary text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="$refs.dialogCopyLinkToForm.show()" style="margin-top: -5px;"><i class="fa fa-link mr-2"> </i> COPY LINK TO FORM</div>
        <div class="border-1 border-blue text-primary text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="$refs.dialogWebhook.show();" style="margin-top: -5px;"><i class="fa fa-globe mr-2"> </i> WEBHOOK</div>
        <div v-show="!$agency.settings.is_omni" class="border-1 border-blue text-primary text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="openReviewFunnelLink();" style="margin-top: -5px;"><i class="fa fa-link mr-2"> </i> REVIEW FUNNEL PREVIEW</div>
      </div>
      
      <h1 class="text-lg font-semibold mb-3">Contacts</h1>

      <hr class="border-b border-gray-100 mb-3">

      <v-skeleton-loader v-if="fetchingContacts" type="article, article, article"></v-skeleton-loader>

      <template v-else>
        <div class="dashboard-box">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3 custom-data-table">
              <div style="clear: both; padding-top: 0px;">
                <v-data-table
                  :headers="headers"
                  :items="contacts"
                  :items-per-page="20"
                  v-model="selected"
                  class="rg-datatable elevation-1"
                  mobile-breakpoint="10"
                  show-select
                  :search="search"
                >
                  <template v-slot:top>
                    <div class="justify-between items-start mb-3 float-right">
                      <v-menu
                        bottom
                        left
                        nudge-top="-35"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="flex flex-row items-center px-4 py-2 rounded border-1 border-gray-500 text-xs text-gray-600 cursor-pointer">
                            <span class="mr-2">Actions</span>
                            <span class="text-xs" style="padding-top: 3px;">
                              <font-awesome-icon icon="chevron-down"  />
                            </span>
                          </div>
                        </template>

                        <v-list class="table-menu-actions">
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="showBulkInviteModal = true"><span class="table-menu-action-icon"><i class="fa fa-user-plus"></i></span> Add To Campaign</a></v-list-item-title></v-list-item>
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="showSendSmsModal = true"><span class="table-menu-action-icon"><i class="mdi mdi-message" style="font-size: 14px;"></i></span> Send SMS</a></v-list-item-title></v-list-item>
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="selected_tags = []; showSendEmailModal = true"><span class="table-menu-action-icon"><i class="mdi mdi-email" style="font-size: 14px;"></i></span> Send Email</a></v-list-item-title></v-list-item>
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="selected_tags = []; showAddTagModal = true"><span class="table-menu-action-icon"><i class="mdi mdi-tag-plus" style="font-size: 14px;"></i></span> Add Tag</a></v-list-item-title></v-list-item>
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="selected_tags = []; showRemoveTagModal = true"><span class="table-menu-action-icon"><i class="mdi mdi-tag-remove" style="font-size: 14px;"></i></span> Remove Tag</a></v-list-item-title></v-list-item>
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="bulkArchive()"><span class="table-menu-action-icon"><i class="fa fa-box"></i></span> Archive</a></v-list-item-title></v-list-item>
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="bulkUnarchive()"><span class="table-menu-action-icon"><i class="fa fa-box-open"></i></span> Unarchive</a></v-list-item-title></v-list-item>
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="bulkDelete()"><span class="table-menu-action-icon"><i class="fa fa-trash"></i></span> Delete</a></v-list-item-title></v-list-item>
                        </v-list>
                      </v-menu>
                    </div>

                    <div class="flex flex-wrap pb-2">
                      <div class="w-full md:w-1/5 pr-3">
                        <v-text-field
                          v-model="search"
                          clearable
                          outlined
                          dense
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          label="Search"
                        ></v-text-field>
                      </div>
                      <div class="w-full md:w-1/5 pr-3">
                        <v-combobox
                          v-model="filter_tags"
                          :items="tags_items"
                          label="Filter Tags"
                          multiple
                          outlined
                          dense
                          hide-details
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" small>
                              <span>{{ item }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                            (+{{ filter_tags.length - 1 }} more)
                            </span>
                        </template>

                        </v-combobox>
                      </div>
                      <div class="w-full sm:w-1/5 pr-3">
                        <v-select
                          :items="['In Campaign', 'Finished Campaign', 'Started Review Campaign', 'Started Any Campaign', 'Not Started Review Campaign', 'Not Started Any Campaign']"
                          label="Filter Campaigns"
                          dense
                          outlined
                          clearable
                          v-model="filter_campaigns"
                        >

                        </v-select>
                      </div>
                      <div v-show="filter_campaigns == 'In Campaign' || filter_campaigns == 'Finished Campaign'" class="w-full sm:w-1/5">
                        <v-autocomplete
                          :items="campaigns"
                          label="Select Campaign"
                          dense
                          outlined
                          clearable
                          item-value="id"
                          item-text="name"
                          v-model="filter_by_campaign"
                        >

                        </v-autocomplete>
                      </div>
                    </div>
                  </template>

                  <template v-slot:item.first_name="{ item }">
                    <span :class="{'line-through text-gray-400': item.archived}">
                      <router-link :to="'/contacts/' + item.id">{{ getContactName(item) }}</router-link>
                    </span>
                      
                      <div v-if="item.survey_data">
                        <v-menu offset-y 
                        nudge-top="-35">
                          <template v-slot:activator="{ on, attrs }">
                            <a href="javascript: void(0)"
                              v-bind="attrs"
                              v-on="on"
                            >survey</a>
                          </template>
                          <div style="background: #FFFFFF; padding: 10px; font-size: 14px;">
                            <div v-for="(question, index) in item.survey_data.questions" :key="index">
                              <div><strong>Q: {{ question.question }}</strong></div>
                              <div>
                                A: {{ question.answer || "-" }}
                              </div>
                              <br clear="all" />
                            </div>

                            <div>
                              <div><strong>Additional Comments</strong></div>
                              {{ item.survey_data.additional_comments || "-" }}
                              <br clear="all" /><br clear="all" />
                            </div>

                            <div>
                              <div><strong>Happy?</strong></div>
                              {{ item.survey_data.happy }}
                            </div>
                          </div>
                        </v-menu>
                      </div>

                      <div v-if="item.custom_fields && item.custom_fields != '[]' && item.custom_fields != 'null' && isJSON(item.custom_fields)">
                        <v-menu offset-y
                        nudge-top="-35">
                          <template v-slot:activator="{ on, attrs }">
                            <a href="javascript: void(0)"
                              v-bind="attrs"
                              v-on="on"
                            >custom fields</a>
                          </template>
                          <div style="background: #FFFFFF; padding: 10px; font-size: 14px;" v-html="displayCustomFields(item.custom_fields)"></div>
                        </v-menu>
                      </div>
                  </template>

                  <template v-slot:item.email="{ item }">
                    <span :class="{'line-through text-gray-400': item.archived}">{{ item.email ? item.email : '-' }}</span>
                  </template>

                  <template v-slot:item.phone="{ item }">
                    <router-link v-if="item.phone" :to="'/contacts/' + item.id">{{ item.phone ? item.phone : '-' }}</router-link>
                    <span v-else :class="{'line-through text-gray-400': item.archived}">{{ item.phone ? item.phone : '-' }}</span>
                  </template>

                  <template v-slot:item.firstSeenAt="{ item }">
                    <span :class="{'line-through text-gray-400': item.archived}"><router-link :to="'/contacts/' + item.id">{{ fromNow(item.firstSeenAt) }}</router-link></span>
                  </template>

                  <template v-slot:item.campaigns_to_contact="{ item }">
                    <a href="javascript: void(0);" @click="viewContactCampaignLog(item)">{{ item.campaigns_to_contact_completed_count }}/{{ item.campaigns_to_contact.length }}</a>
                  </template>

                  <template v-slot:item.campaign_to_contact="{ item }">
                    <span v-if="!item.campaign_to_contact" class="text-gray-500">
                        <span v-if="item.invitedEmail == 1 || item.invitedPhone == 1">
                          <a href="javascript: void(0);" @click="$refs.dialogInvitesLog.show()">invited (static)</a>
                        </span>
                        <span v-else>
                          not invited
                        </span>
                      </span>
                      <span v-else>
                        Campaign Initiated
                        <br />
                        <v-menu
                        nudge-top="-35" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <a href="javascript: void(0)"
                              v-bind="attrs"
                              v-on="on"
                            >{{ item.campaign_to_contact.status }}</a>
                          </template>
                          <v-list>
                            <v-list-item v-for="item2 in item.campaign_to_contact.log" :key="item2.id">
                              <i v-if="item2.status == 'pending'" class="fa fa-clock" style="color: orange; width: 20px;"></i>
                              <i v-if="item2.status == 'in-progress'" class="fa fa-sync fa-spin" style="color: gray; margin-right: 7px;"></i>
                              <i v-if="item2.status == 'completed' || item2.status == 'clicked'" class="fa fa-check" style="color: green; width: 20px;"></i>
                              <i v-if="item2.status == 'canceled'" class="fa fa-ban" style="color: orange; width: 20px;"></i>

                              <v-tooltip v-if="item2.status == 'failed'" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <i v-bind="attrs" v-on="on" class="fa fa-times" style="color: red; width: 20px;"></i>
                                </template>
                                <span>{{ displayResult(item2.result) }}</span>
                              </v-tooltip>
                              
                              {{ item2.title }}

                              <v-chip
                                  class="ml-2"
                                  color="green"
                                  text-color="white"
                                  x-small
                                  v-if="item2.status == 'clicked'"
                              >
                                  clicked
                              </v-chip>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </span>
                  </template>

                  <template v-slot:item.id="{ item }">
                    <div style="text-align: right;">
                      <v-menu
                        bottom
                        left
                        nudge-top="-17"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-bind="attrs"
                            v-on="on"

                            href="javascript: void(0);" class="text-xs font-semibold rounded cursor-pointer">
                            <i class="fa fa-sign-in-alt mr-2 text-blue"></i>
                            <span class="whitespace-no-wrap text-blue">Start CAMPAIGN</span>
                          </a>
                        </template>

                        <v-list class="table-menu-actions">
                          <v-list-item><v-list-item-title class='text-sm text-bold'>Choose Campaign:</v-list-item-title></v-list-item>

                          <v-list-item v-for="(campaign, index_campaign) in campaigns" :key="index_campaign" class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="startInviteCampaign(item, campaign)">{{ campaign.name }}</a></v-list-item-title></v-list-item>
                        </v-list>
                      </v-menu>

                      <!-- <a v-if="!item.campaign_to_contact" href="javascript: void(0);" class="text-xs font-semibold rounded cursor-pointer" @click="startInviteCampaign(item)">
                        <i class="fa fa-sign-in-alt mr-2 text-blue"></i>
                        <span class="whitespace-no-wrap text-blue">Start CAMPAIGN</span>
                      </a> -->

                      <v-menu
                        bottom
                        left
                        nudge-top="-35"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="ml-3"
                            color="grey darken-1"
                          >
                            <i class="mdi mdi-dots-vertical"></i>
                          </v-btn>
                        </template>

                        <v-list class="table-menu-actions">
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="$refs.dialogAddContact.editContact(item); $refs.dialogAddContact.show()"><span class="table-menu-action-icon"><i class="fa fa-pen"></i></span> Edit</a></v-list-item-title></v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                </v-data-table>

                <div class="mt-5">
                  <v-pagination
                      v-model="currentPage"
                      :length="Math.ceil(totalItems / perPage)"
                      :total-visible="9"
                      color="primary"
                      rounded="circle"
                  ></v-pagination>
                </div>
              </div>
          </div>
          </v-card>
        </div>
      </template>

    </panel-navigation>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import PanelNavigation from '@/components/PanelNavigation.vue'
import DialogIntegrationCsvfile from '@/components/dialogs/dialogIntegrationCsvFile.vue'
import DialogAddContact from '@/components/dialogs/contacts/addContact.vue'
import DialogInvitesLog from '@/components/dialogs/contacts/invitesLog.vue'
import DialogContactsSettings from '@/components/dialogs/contacts/contactsSettings.vue'
import DialogCopyLinkToForm from '@/components/dialogs/contacts/copyLinkToForm.vue'
import DialogWebhook from '@/components/dialogs/contacts/webhook.vue'
import moment from 'moment-timezone'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  metaInfo () {
    return {
      title: "Contacts | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    DialogIntegrationCsvfile,
    DialogAddContact,
    DialogInvitesLog,
    DialogContactsSettings,
    DialogCopyLinkToForm,
    DialogWebhook,
    DatePicker
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      fetchingContacts: true,
      contacts: [],
      selected: [],
      selectedContactIds: [],
      contactsInt: null,

      review_funnel: null,
      contact_form: null,

      showBulkInviteModal: false,
      showAddTagModal: false,
      showRemoveTagModal: false,

      saving: false,

      filter_tags: [],
      filter_campaigns: "",
      filter_by_campaign: "",

      showSendSmsModal: false,
      showSendEmailModal: false,

      selected_tags: [],
      tags_items: ['15 min call', 'application complete', 'completed survey', 'go live', 'lead', 'payment made', 'scheduled with calendly', 'value video optin'],

      headers: [
        { text: 'Name', value: 'first_name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Customer Age', value: 'firstSeenAt' },
        // { text: 'Status', value: 'campaign_to_contact' },
        { text: 'Campaigns Completed / Total', value: 'campaigns_to_contact' },
        { text: '', value: 'id', sortable: false }
      ],

      show_contact_log: false,

      searchLocal: "",
      search: "",

      bulk_sms_error: "",
      bulk_email_error: "",

      bulk_sms_campaign: {
        title: "",
        from_number: "",
        send_when: "Now",
        scheduled_on: "",
        timezone: "America/New_York",
        message: "",
        contacts: []
      },

      bulk_email_campaign: {
        title: "",
        from_number: "",
        subject: "",
        send_when: "Now",
        scheduled_on: "",
        timezone: "America/New_York",
        message: "",
        contacts: []
      },

      timezones: [
        'Europe/Andorra',
        'Asia/Dubai',
        'Asia/Kabul',
        'Europe/Tirane',
        'Asia/Yerevan',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia',
        'Pacific/Pago_Pago',
        'Europe/Vienna',
        'Australia/Lord_Howe',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Currie',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Broken_Hill',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Baku',
        'America/Barbados',
        'Asia/Dhaka',
        'Europe/Brussels',
        'Europe/Sofia',
        'Atlantic/Bermuda',
        'Asia/Brunei',
        'America/La_Paz',
        'America/Noronha',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Santarem',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Eirunepe',
        'America/Rio_Branco',
        'America/Nassau',
        'Asia/Thimphu',
        'Europe/Minsk',
        'America/Belize',
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Indian/Cocos',
        'Europe/Zurich',
        'Africa/Abidjan',
        'Pacific/Rarotonga',
        'America/Santiago',
        'America/Punta_Arenas',
        'Pacific/Easter',
        'Asia/Shanghai',
        'Asia/Urumqi',
        'America/Bogota',
        'America/Costa_Rica',
        'America/Havana',
        'Atlantic/Cape_Verde',
        'America/Curacao',
        'Indian/Christmas',
        'Asia/Nicosia',
        'Asia/Famagusta',
        'Europe/Prague',
        'Europe/Berlin',
        'Europe/Copenhagen',
        'America/Santo_Domingo',
        'Africa/Algiers',
        'America/Guayaquil',
        'Pacific/Galapagos',
        'Europe/Tallinn',
        'Africa/Cairo',
        'Africa/El_Aaiun',
        'Europe/Madrid',
        'Africa/Ceuta',
        'Atlantic/Canary',
        'Europe/Helsinki',
        'Pacific/Fiji',
        'Atlantic/Stanley',
        'Pacific/Chuuk',
        'Pacific/Pohnpei',
        'Pacific/Kosrae',
        'Atlantic/Faroe',
        'Europe/Paris',
        'Europe/London',
        'Asia/Tbilisi',
        'America/Cayenne',
        'Africa/Accra',
        'Europe/Gibraltar',
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
        'Europe/Athens',
        'Atlantic/South_Georgia',
        'America/Guatemala',
        'Pacific/Guam',
        'Africa/Bissau',
        'America/Guyana',
        'Asia/Hong_Kong',
        'America/Tegucigalpa',
        'America/Port-au-Prince',
        'Europe/Budapest',
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
        'Europe/Dublin',
        'Asia/Jerusalem',
        'Asia/Kolkata',
        'Indian/Chagos',
        'Asia/Baghdad',
        'Asia/Tehran',
        'Atlantic/Reykjavik',
        'Europe/Rome',
        'America/Jamaica',
        'Asia/Amman',
        'Asia/Tokyo',
        'Africa/Nairobi',
        'Asia/Bishkek',
        'Pacific/Tarawa',
        'Pacific/Enderbury',
        'Pacific/Kiritimati',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Almaty',
        'Asia/Qyzylorda',
        'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Beirut',
        'Asia/Colombo',
        'Africa/Monrovia',
        'Europe/Vilnius',
        'Europe/Luxembourg',
        'Europe/Riga',
        'Africa/Tripoli',
        'Africa/Casablanca',
        'Europe/Monaco',
        'Europe/Chisinau',
        'Pacific/Majuro',
        'Pacific/Kwajalein',
        'Asia/Yangon',
        'Asia/Ulaanbaatar',
        'Asia/Hovd',
        'Asia/Choibalsan',
        'Asia/Macau',
        'America/Martinique',
        'Europe/Malta',
        'Indian/Mauritius',
        'Indian/Maldives',
        'America/Mexico_City',
        'America/Cancun',
        'America/Merida',
        'America/Monterrey',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Chihuahua',
        'America/Ojinaga',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Bahia_Banderas',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Africa/Maputo',
        'Africa/Windhoek',
        'Pacific/Noumea',
        'Pacific/Norfolk',
        'Africa/Lagos',
        'America/Managua',
        'Europe/Amsterdam',
        'Europe/Oslo',
        'Asia/Kathmandu',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'America/Panama',
        'America/Lima',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'Pacific/Gambier',
        'Pacific/Port_Moresby',
        'Pacific/Bougainville',
        'Asia/Manila',
        'Asia/Karachi',
        'Europe/Warsaw',
        'America/Miquelon',
        'Pacific/Pitcairn',
        'America/Puerto_Rico',
        'Asia/Gaza',
        'Asia/Hebron',
        'Europe/Lisbon',
        'Atlantic/Madeira',
        'Atlantic/Azores',
        'Pacific/Palau',
        'America/Asuncion',
        'Asia/Qatar',
        'Indian/Reunion',
        'Europe/Bucharest',
        'Europe/Belgrade',
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Kirov',
        'Europe/Astrakhan',
        'Europe/Volgograd',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr',
        'Asia/Riyadh',
        'Pacific/Guadalcanal',
        'Indian/Mahe',
        'Africa/Khartoum',
        'Europe/Stockholm',
        'Asia/Singapore',
        'America/Paramaribo',
        'Africa/Juba',
        'Africa/Sao_Tome',
        'America/El_Salvador',
        'Asia/Damascus',
        'America/Grand_Turk',
        'Africa/Ndjamena',
        'Indian/Kerguelen',
        'Asia/Bangkok',
        'Asia/Dushanbe',
        'Pacific/Fakaofo',
        'Asia/Dili',
        'Asia/Ashgabat',
        'Africa/Tunis',
        'Pacific/Tongatapu',
        'Europe/Istanbul',
        'America/Port_of_Spain',
        'Pacific/Funafuti',
        'Asia/Taipei',
        'Europe/Kiev',
        'Europe/Uzhgorod',
        'Europe/Zaporozhye',
        'Pacific/Wake',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/Denver',
        'America/Boise',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Adak',
        'Pacific/Honolulu',
        'America/Montevideo',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'America/Caracas',
        'Asia/Ho_Chi_Minh',
        'Pacific/Efate',
        'Pacific/Wallis',
        'Pacific/Apia',
        'Africa/Johannesburg'
        ],

        sms_templates: [],
        email_templates: [],
        selected_sms_template: "",
        selected_email_template: ""
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me,
      campaigns: state => state.campaigns.campaigns
    }),
    sortedContacts() {
      return this.contacts.slice().sort((a, b) => new Date(b.firstSeenAt) - new Date(a.firstSeenAt))
    }
  },
  methods: {
    async sendSms() {
      console.log('here');
      this.saving = true;
      this.bulk_sms_error = false;

      if (!this.bulk_sms_campaign.title) {
        console.log('bulk_sms_error', this.bulk_sms_error)
        this.bulk_sms_error = 'Please enter action name.';
        this.saving = false;
        return;
      }

      if (!this.bulk_sms_campaign.from_number) {
        this.bulk_sms_error = 'Please select a from number.';
        this.saving = false;
        return;
      }

      if (!this.bulk_sms_campaign.message) {
        this.bulk_sms_error = 'Please enter message.';
        this.saving = false;
        return;
      }

      this.bulk_sms_campaign.contacts = this.selectedContactIds;

      try {
        let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/bulk-sms/save', {
          campaign: this.bulk_sms_campaign,
          operation_type: 'bulk_sms'
        }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

        // this.saving = false;

        this.$router.push('/contacts/bulk-actions');

      }catch(e){
        console.log(e)
      }
    },

    async sendEmail() {
      console.log('here');
      this.saving = true;
      this.bulk_email_error = false;

      if (!this.bulk_email_campaign.title) {
        console.log('bulk_email_error', this.bulk_email_error)
        this.bulk_email_error = 'Please enter action name.';
        this.saving = false;
        return;
      }

      if (!this.bulk_email_campaign.subject) {
        console.log('bulk_email_error', this.bulk_email_error)
        this.bulk_email_error = 'Please add subject.';
        this.saving = false;
        return;
      }

      if (!this.bulk_email_campaign.message) {
        this.bulk_email_error = 'Please enter message.';
        this.saving = false;
        return;
      }

      this.bulk_email_campaign.contacts = this.selectedContactIds;

      try {
        let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/bulk-sms/save', {
          campaign: this.bulk_email_campaign,
          operation_type: 'bulk_email'
        }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

        // this.saving = false;

        this.$router.push('/contacts/bulk-actions');

      }catch(e){
        console.log(e)
      }
    },

    getContactName(contact) {
      let elements = [];

      if (contact.first_name)
        elements.push(contact.first_name);
      
      if (contact.last_name)
        elements.push(contact.last_name);

      if (elements.length == 0)
        if (contact.email)
          elements.push(contact.email);

      if (elements.length == 0)
        if (contact.phone)
          elements.push(contact.phone);

      return elements.join(" ");
    },

    viewContactCampaignLog(contact) {
      this.log_contact = contact;
      this.show_contact_log = true;
    },
    async inviteEmail(contact) {
      if (!contact.invitedEmail) {
        await this.$store.dispatch('contacts/inviteEmail', {contactId: contact.id})
        this.fetchContacts();
      }
    },
    async startInviteCampaign(contact, campaign) {
      await this.$store.dispatch('contacts/invite', {contactId: contact.id, campaignId: campaign.id})
      this.fetchContacts();
    },
    async invitePhone(contact) {
      if (!contact.invitedPhone) {
        await this.$store.dispatch('contacts/invitePhone', {contactId: contact.id})
        this.fetchContacts();
      }
    },
    async bulkInvite(campaign) {
      await this.$store.dispatch('contacts/bulkInvite', {contactIds: this.selectedContactIds, campaignId: campaign.id})
      this.fetchContacts();
      this.selected = []
      this.selectedContactIds = []

      this.showBulkInviteModal = false;
    },
    async bulkInviteEmail() {
      await this.$store.dispatch('contacts/bulkInviteEmail', {contactIds: this.selectedContactIds})
      this.fetchContacts();
      this.selected = []
      this.selectedContactIds = []
    },
    async bulkInviteSms() {
      await this.$store.dispatch('contacts/bulkInvitePhone', {contactIds: this.selectedContactIds})
      this.fetchContacts();
      this.selected = []
      this.selectedContactIds = []
    },
    async bulkArchive() {
      await this.$store.dispatch('contacts/bulkArchive', {contactIds: this.selectedContactIds})
      this.fetchContacts();
      this.selected = []
      this.selectedContactIds = []
    },
    async bulkUnarchive() {
      await this.$store.dispatch('contacts/bulkUnarchive', {contactIds: this.selectedContactIds})
      this.fetchContacts();
      this.selected = []
      this.selectedContactIds = []
    },
    async bulkDelete() {
      await this.$store.dispatch('contacts/bulkDelete', {contactIds: this.selectedContactIds})
      this.fetchContacts();
      this.selected = []
      this.selectedContactIds = []
    },

    getContactName(contact) {
      let name = contact.first_name + ' ' + contact.last_name;
      name = name.trim();

      if (!name)
        return "-";

      return name;
    },

    fromNow(date) {
      return moment.utc(date).local().fromNow();
    },

    async getGetReviewsCampaign() {
      this.getReviewsCampaignLoaded = false;
      
      try {
        axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/get-get-reviews-campaign', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

      }catch(e){
        console.log(e)
      }
    },

    getReviewFunnel: async function() {
        try {
          let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/review-funnels/get-settings', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          },
          })
          
          this.review_funnel = response.data.review_funnel
        }
        catch (e) {}
	  },

    openReviewFunnelLink() {
      window.open(this.getFunnelLink());
    },

    getFunnelLink() {
        return window.location.origin + '/' + this.review_funnel.short_name;
    },

    displayResult(result) {
      result = JSON.parse(result);

      return result.error;
    },

    getContactForm: async function() {
        let self = this;

        try {
          let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/contacts/get-form', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          },
          })
          
            this.contact_form = response.data.contact_form
        }catch(e){
                console.log(e);
        }
      },

    async getTemplates() {
      let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/templates?template_type=sms`, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      })

      this.sms_templates = response.data;

      let response2 = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/templates?template_type=email`, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      })

      this.email_templates = response2.data;
    },

    displayCustomFields(custom_fields) {
      let html = '';

      let parsed_fields = JSON.parse(custom_fields);

      for (var i in parsed_fields) {
        html += '<strong>' + parsed_fields[i].name + "</strong>: " + parsed_fields[i].value + "<br />";
      }

      return html;
    },

    async addTags(objects_ids, tags, object_type) {
      this.saving = true;

      let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/tags/assign-tags', {
        tags: tags,
        object_type: object_type,
        objects_ids: objects_ids
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      });

      this.showAddTagModal = false;
      this.saving = false;
    },

    async removeTags(objects_ids, tags, object_type) {
      this.saving = true;

      let response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/tags/remove-tags', {
        tags: tags,
        object_type: object_type,
        objects_ids: objects_ids
      }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      });

      this.showRemoveTagModal = false;
      this.saving = false;
    },

    fetchContacts() {
      return new Promise(async (resolve, reject) => {
      
      try {
        let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/contacts`, {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            tags: this.filter_tags,
            filter_campaigns: this.filter_campaigns,
            filter_by_campaign: this.filter_by_campaign
          },
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        });
       
        if (response.status == 200) {
          this.contacts = response.data.contacts
          this.totalItems =  response.data.totalItems
          this.fetchingContacts = false
          resolve()
          return;
        }
      } catch (error) {
        reject(error)
        return
      }

      reject()

    })
    },
    isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  },
  watch: {
    currentPage(val) {
      this.fetchContacts()
    },
    perPage(val) {
      this.fetchContacts()
    },
    selected: {
      handler() {
        this.selectedContactIds = [];

        for (var i in this.selected) {
          this.selectedContactIds.push(this.selected[i].id);
        }
      }
    },
    filter_tags: {
      handler () {
        this.fetchContacts();
      }
    },
    filter_campaigns: {
      handler () {
        this.fetchContacts();
      }
    },
    filter_by_campaign: {
      handler () {
        this.fetchContacts();
      }
    },
    selected_sms_template: {
      handler () {
        this.bulk_sms_campaign.message = this.selected_sms_template;
      }
    },
    selected_email_template: {
      handler () {
        this.bulk_email_campaign.message = this.selected_email_template;
      }
    }
  },
  created() {
    this.fetchContacts();

    this.$store.dispatch('campaigns/fetch')

    let self = this;
    // this.contactsInt = setInterval(function() {
    //   self.$store.dispatch('contacts/fetch')
    //   self.getGetReviewsCampaign();
    // }, 10000);

    self.getReviewFunnel();
    self.getContactForm();
    this.getTemplates();
  },

  destroy() {
    clearInterval(this.contactsInt);
    this.contactsInt = null;
  }
}
</script>

<style lang="scss" scoped>

.btn-invite {
  background-color: #00ad9e;
}

table tbody tr {
  transition: all 0.1s linear;
}

table tbody tr:hover {
  background-color: rgb(245, 245, 245);
}

.v-list-item {
  min-height: 27px;
  font-size: 13px;
}
</style>
