import axios from 'axios'

export default {
  namespaced: true,
  state: {
    listings: false,
    listings_search: false,
    fetchingListings: true,
    chart_data: {
      chart_data: [],
      overall_rating: 0,
      reviews_count: 0,
      initial_loading: false
    }
  },
  getters: {
    getListings(state){ return state.listings }
  },
  mutations: {
    setListings(state, listings) {
      state.listings = listings.listings
      state.listings_search = listings.listings_search
      state.fetchingListings = false
    },

    setChartData(state, chart_data) {
      chart_data.initial_loading = false
      state.chart_data = chart_data
    }
  },
  actions: {
    add(_, {url, place_id} ){
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/listings`, {
            url: url, place_id: place_id
          }, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    },
    fetch({commit}, {dashboard, date}) {
      return new Promise(async (resolve, reject) => {
  
        try {
          let url = `${process.env.VUE_APP_API_ENDPOINT}/me/listings?dashboard=${dashboard}`;

          if (typeof(date) != "undefined")
            url += '&date=' + date;

          let response = await axios.get(url, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
  
          if (response.status == 200) {
            commit('setListings', response.data)
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }
  
        reject()
  
      })
    },
    fetch_dashboard_chart_data({commit}, {review_sites, date}) {

      return new Promise(async (resolve, reject) => {
  
        try {
          let url = `${process.env.VUE_APP_API_ENDPOINT}/me/listings/dashboard-chart-data`;

          if (review_sites && date)
            url += '?review_sites=' + review_sites.join(",") + "&date=" + date;

          let response = await axios.get(url, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
  
          if (response.status == 200) {
            commit('setChartData', response.data)
            resolve()
            return;
          }
        } catch (error) {
          console.log('errorrrrrr');
          console.log(error);
          reject(error.response.data.message)
          return
        }
  
        reject()
  
      })
    },
    delete(_, listingId) {
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/me/listings/${listingId}`, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    },
  }

}