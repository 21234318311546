<template>
    <div class="position-relative" :style="backgroundStyle" style="height: 100%;">
        <div class="mt-5 mb-2">
            <b-input
            placeholder="Search for photos here"
            v-model="search"
            @input="waitSearch" style="padding: 20px;">
            </b-input>
        </div>
        <div style="height: calc(100% - 300px); overflow: auto;">
            <div class="rg-unsplash-photo" v-for="photo in photos" :key="photo.id">
              <img class="image-list" :src="photo.urls.thumb" alt="img" @click="applyPhoto(photo)" />
              <div style="padding-top: 10px;">
                Photo by <a :href="'https://unsplash.com/@' + photo.user.username + '?utm_source=your_app_name&utm_medium=referral'" target="unsplash-user">{{ photo.user.first_name }} {{ photo.user.last_name }}</a> on <a href="https://unsplash.com/?utm_source=your_app_name&utm_medium=referral" target="unsplash">Unsplash</a>
              </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import _ from 'lodash'
export default {
  name: 'HelloWorld',
  data () {
    return {
      searchKeywords: ['apple', 'love', 'nature', 'waterfall', 'macbook'],
      photos: [],
      search: null,
      photo_url: null,
      download_url: null,
      msg: 'Welcome to Your Vue.js App'
    }
  },
  created() {
    this.getPhoto()
  },
  computed: {
      backgroundStyle(){
        let style = {}
        if(this.photo_url){
          style.background = `no-repeat center/100% 100% url(${this.photo_url})`;
        } else {
         // style.background = `#cee2b5`;
        }
        return style
      }
  },
  methods: {
    getPhoto() {
      // get the app id using the global variable of unsplash
      let appid = 'ZwoPe28xIyyB7EN9qznQWGLJXDURoDK2M61c4IWDeIk';
      let keywords = this.searchKeywords;
      //to get random keyboard during search whne user opens the page
      let query = keywords[Math.floor(Math.random()*keywords.length)];
      //axios.get(`https://api.unsplash.com/photos?client_id=${appid}`)
       axios.get(`https://api.unsplash.com/search/photos?page=1&per_page=20&query=${query}&client_id=${appid}`)
      .then(response => {
        // JSON responses are automatically parsed.
        this.photos = response.data.results
      })
      .catch(e => {
        console.log(e)
      })
    },
    searchPhoto(){
      let appid = 'ZwoPe28xIyyB7EN9qznQWGLJXDURoDK2M61c4IWDeIk';
      let query  = this.search
      axios.get(`https://api.unsplash.com/search/photos?page=1&per_page=20&query=${query}&client_id=${appid}`)
      .then(response => {
        // JSON responses are automatically parsed.
        this.photos = response.data.results
        console.log('this.photos', this.photos)
      })
      .catch(e => {
        console.log(e)
      })
    },
    getRandom(){
      axios.get('https://source.unsplash.com/random')
      .then(response =>{
      })
    },
    applyPhoto(photo){
    //   this.photo_url = photo.urls.regular
    //   this.download_url = photo.links.download + '?force=true'
        this.$parent.$parent.$parent.$parent.selectImage(photo.urls.regular)
    },
    waitSearch: _.debounce(
                function () {
                     this.searchPhoto();
                },1000)
  }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.image-list{
  margin-top: 12px;
  height: 140px;
  display: inline-block;
}

.rg-unsplash-photo {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 200px;
    height: 220px;
    text-align: center;
    background: #e9e8e8;
    padding: 10px;
    font-size: 11px;
}
/* .search-input{
  display: inline;
} */
</style>