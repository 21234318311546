<template>

  <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-1/2 bg-white rounded-lg mx-6" style="max-height: 100%;">

      <div class="rg-modal-header">
        <span v-if="!user.id">Add User</span>

        <span v-else>Edit User</span>

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6" style="height: calc(100% - 110px); overflow: auto;">

        <hr class="border-b border-gray-100 mb-4">

        <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          {{success}}
        </div>

        <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div v-html="error"></div>
        </div>

        <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    First Name
                </label>
                <input v-model="user.first_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div class="w-full md:w-1/2 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Last Name
                </label>
                <input v-model="user.last_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>
        </div>

        <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Email Address
                </label>
                <input v-model="user.email" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div class="w-full md:w-1/2 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Password
                </label>
                <input name="password" ref="password" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="password">
            </div>
        </div>

        <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
                <label v-show="me.company_type == 'agency'" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Role
                </label>

                <v-select v-show="me.company_type == 'agency'"
                    :items="roles_items"
                    v-model="user.settings.role"
                    outlined
                    dense
                ></v-select>
            </div>

            <div :class="((me.company_type == 'agency') ? 'w-full md:w-1/2 mb-1 md:mb-0' : 'w-full md:w-1/2 mb-1 md:mb-0')">
                <label v-if="me.company_type == 'agency'" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Clients Access
                </label>

                <label v-else class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Locations Access
                </label>

                <v-select
                    :items="accounts_access_items"
                    v-model="user.settings.accounts"
                    outlined
                    dense
                ></v-select>
            </div>
        </div>

        <div v-show="!$agency.settings.is_omni" class="flex flex-wrap">
            <div :class="'w-full md:w-1/2 pr-5 mb-1 md:mb-0' + ((me.company_type == 'agency') ? '' : ' -mt-24')">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                    Sections
                </label>

                <div :style="'height: ' + ((me.company_type == 'agency') ? '254' : '300') + 'px; padding-top: 10px; '">
                    <div v-for="(item, index) in user.settings.sections" :key="index">
                        <div v-show="index != '/dashboard' && index != '/logout'" class="pt-1">
                          <toggle-button v-model="user.settings.sections[index]" color="#48bb78" :sync="true" /> <span class="text-gray-500 ml-3">{{ index }}</span>
                        </div>
                    </div>
                </div>

                <label v-show="me.company_type == 'agency'" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Permissions
                </label>

                <div v-show="me.company_type == 'agency' && user.settings.role == 'Administrator'">
                    <div v-for="(item, index) in user.settings.permissions" :key="index">
                        <div class="pt-1">
                          <toggle-button v-model="user.settings.permissions[index]" color="#48bb78" :sync="true" /> <span class="text-gray-500 ml-3">{{ index.replace(/\_/g, " ") }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="user.settings.accounts != 'All'" class="w-full md:w-1/2 mb-1 md:mb-0">
                <label v-if="me.company_type == 'agency'" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Select Clients
                </label>

                <label v-else class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Select Locations
                </label>

                <div style="height: 254px; padding-top: 10px; overflow: auto;">
                    <div v-if="is_business">
                        <div v-if="locations.length == 0">
                            You have no clients available.
                        </div>
                        <div v-else>
                            <div v-for="(item) in locations" :key="item.id">
                                <div class="pt-1">
                                  <toggle-button v-model="user.settings.selected_accounts[item.id]" color="#48bb78" :sync="true" /> <span class="text-gray-500 ml-3">{{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="businesses.length == 0">
                            You have no clients available.
                        </div>
                        <div v-else>
                            <div v-for="(item) in businesses" :key="item.id">
                                <div class="pt-1">
                                  <toggle-button v-model="user.settings.selected_accounts[item.id]" color="#48bb78" :sync="true" /> <span class="text-gray-500 ml-3">{{ item.company_name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rounded hover:bg-gray-200 text-gray-800 border border-gray-800 focus:outline-none py-2 px-4 mr-2" @click="hide">
            Cancel
        </button>

        <button class="rg-dialog-button-save rounded bg-gray-500 hover:bg-gray-600 text-white focus:outline-none py-2 px-4" :class="{'spinner': saving}" @click="save">
            Save
        </button>

      </div>



    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { GetSuggestions } from '@/utils/PlaceUtils'
import $ from 'jquery'
import { ToggleButton } from 'vue-js-toggle-button'
import countries from '@/config/countries';

export default {
  data: function() {
    return {
      showModal: false,
      saving: false,
      success: null,
      error: null,

      roles_items: ["Administrator", "Manager (readonly)"],
      accounts_access_items: ["All", "Select"],

      permissions: ['add_business','delete_business','change_business_plan','create_widgets','edit_plans','edit_agency_settings','manage_billing'],

      user: null,

      agency_sections: {
        '/businesses': true,
        // '/billing': true,
        '/help': true,
        '/resources': true,
        '/agency-settings': true,
        '/local-recon': true
      },

      business_sections: {
        '/reviews': true,
        '/review-platforms': true,
        '/widgets': true,
        '/contacts': true,
        '/feedback': true,
        '/settings': true,
        '/social-posting': true,
        '/grid-tracker': true,
        '/inbox': true
      },

      default_user: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        settings: {
            role: "Administrator",
            accounts: "All",
            selected_accounts: {},
            sections: {
                '/businesses': true,
                '/billing': true,
                '/help': true,
                '/resources': true,
                '/agency-settings': true,
                '/local-recon': true
            },
            permissions: {
              'add_business': true,
              'delete_business': true,
              'change_business_plan': true,
              'create_widgets': true,
              'edit_plans': true,
              'edit_agency_settings': true,
              'manage_billing': true
            }
        }
      }
    }
  },
  components: {
    ToggleButton
  },
  props: ['is_business'],
  computed: {
    locationFoundItems () {
      return this.locationEntries
    },
    ...mapState({
      me: state => state.me.me,
      businesses: state => state.businesses.businesses,
      locations: state => state.locations.locations
    }),
  },
  methods: {
    show() {
      this.showModal = true
    },
    hide() {
      this.showModal = false
      this.saving = false
    },
    createUser() {
        this.user = JSON.parse(JSON.stringify(this.default_user));

        if (this.me.company_type == 'agency') {
          this.user.settings.sections = JSON.parse(JSON.stringify(this.agency_sections));
        }
        else {
          this.user.settings.sections = JSON.parse(JSON.stringify(this.business_sections));
        }
    },
    editUser(user) {
        this.user = JSON.parse(JSON.stringify(user));

        if (this.user.settings.sections.length == 0)
          this.user.settings.sections = {};

        if (this.user.settings.selected_accounts.length == 0)
          this.user.settings.selected_accounts = {};
    },
    async save() {
      this.saving = true
      this.error = null
      
      try {

        let formData = new FormData();
        // formData.append("name", this.name);

        if (this.user.id)
          formData.append("id", this.user.id);

        formData.append("first_name", this.user.first_name);
        formData.append("last_name", this.user.last_name);
        formData.append("email", this.user.email);
        formData.append("password", this.$refs.password.value);

        console.log('this.user.settings', this.user.settings);

        formData.append("settings", JSON.stringify(this.user.settings));
        formData.append("is_user", 1);

        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/children/add`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
              'Content-Type': 'multipart/form-data'
            }
        })

        this.hide()
        this.$store.dispatch('users/fetch')
        this.error = null
        /**
         *  #### Track Event When User Added
         */
        let childId = response.data.child.id;
        window.dataLayer.push({
            'event': 'add_user',
            'activity': 'New User Added',
            'user_id': this.me.id,
            'child_user_data':{
                'id': childId,
                'first name':this.user.first_name,
                'last name':this.user.last_name,
                'email':this.user.email,
            }
          });


      } catch (error) {
        console.log(error)
          error.errors = error.response.data.errors
          for (var i in error.errors) {
            if (error.errors[i] != 'null') {
              console.log(error.errors[i]);
              this.error = (this.error || '') + error.errors[i] + "<br />";
            }
          }

      }
      this.saving = false
    }
  },
  
  watch: {

  },

  created() {
    this.$store.dispatch('businesses/fetch')
    this.$store.dispatch('locations/fetch')
  }
}
</script>
