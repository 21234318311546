<template>

    <panel-navigation>
        <div>
            <h1 class="text-lg font-semibold mb-3">Resources</h1>

            <hr class="border-b border-gray-100 mb-3">

              <div class="flex">
                <div class="dashboard-box stats-box w-full mb-4 md:w-1/4 lg:w-1/4 mr-4">
                  <v-card
                    class="pa-2"
                    tile
                  >
                    <img :src="require('../../../assets/images/resources/sr-postcard.png')" />
                    <div class="p-3">
                      <strong>Review Postcard  For Marketing Agencies </strong>
                      <p class="text-gray-600 text-sm" style="min-height: 70px;">
                        Grab our Canva Google review request print-out template. Use with existing clients and with potential prospects.
                      </p>
                      <a class="text-sm" href="https://www.canva.com/design/DAE9Is8wxvA/JvRGOa-6tT2YO6ZaETclfA/view?utm_content=DAE9Is8wxvA&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview" target="_blank">Learn More &nbsp; <i class="fa fa-chevron-right" style="font-size: 12px;"></i></a>
                    </div>
                  </v-card>
                </div>

                <div class="dashboard-box stats-box w-full mb-4 md:w-1/4 lg:w-1/4 mr-4">
                  <v-card
                    class="pa-2"
                    tile
                  >
                    <img :src="require('../../../assets/images/resources/sr-canva.png')" />
                    <div class="p-3">
                      <strong>Canva Image Links </strong>
                      <p class="text-gray-600 text-sm" style="min-height: 70px;">
                        100 Social Media quote images sized for each of the top 4 social media platforms.
                      </p>
                      <a class="text-sm" href="https://docs.google.com/document/d/1w7pJ7Y_nhyrwlX22wGb7npyGsRPAmcp5FIRuI7sdIxw/copy" target="_blank">Learn More &nbsp; <i class="fa fa-chevron-right" style="font-size: 12px;"></i></a>
                    </div>
                  </v-card>
                </div>

                <div class="dashboard-box stats-box w-full mb-4 md:w-1/4 lg:w-1/4">
                  <v-card
                    class="pa-2"
                    tile
                  >
                    <img :src="require('../../../assets/images/resources/sr-onboarding-slides.png')" />
                    <div class="p-3">
                      <strong>Reputation Onboarding Call Slides </strong>
                      <p class="text-gray-600 text-sm" style="min-height: 70px;">
                        Onboarding clients successfully is key to their success and yours. Use our template. 
                      </p>
                      <a class="text-sm" href="https://docs.google.com/presentation/d/1OsOWM0Fe7ZDReeiap8Miwz5fdLnftuA-/copy" target="_blank">Learn More &nbsp; <i class="fa fa-chevron-right" style="font-size: 12px;"></i></a>
                    </div>
                  </v-card>
                </div>
              </div>
              <div class="flex">
                <div class="dashboard-box stats-box w-full mb-4 md:w-1/4 lg:w-1/4 mr-4">
                  <v-card
                    class="pa-2"
                    tile
                  >
                    <img :src="require('../../../assets/images/resources/sr-quotes.png')" />
                    <div class="p-3">
                      <strong>100+ Review & Reputation Quotes </strong>
                      <p class="text-gray-600 text-sm" style="min-height: 70px;">
                        Use them in your marketing materials, add them to your website, or post them on social media…
                      </p>
                      <a class="text-sm" href="https://docs.google.com/spreadsheets/d/1GdJ_OtOVBS2KAyGnNackjmx8yneh5ep6_nDqls4fXDU/copy" target="_blank">Learn More &nbsp; <i class="fa fa-chevron-right" style="font-size: 12px;"></i></a>
                    </div>
                  </v-card>
                </div>

                <div class="dashboard-box stats-box w-full mb-4 md:w-1/4 lg:w-1/4 mr-3">
                  <v-card
                    class="pa-2"
                    tile
                  >
                    <img :src="require('../../../assets/images/resources/sr-slide-deck.png')" />
                    <div class="p-3">
                      <strong>Slide Deck Template </strong>
                      <p class="text-gray-600 text-sm" style="min-height: 70px;">
                        One of the most requested Resources from Agency owners is always - "Do you have a pitch deck?""
                      </p>
                      <a class="text-sm" href="https://docs.google.com/presentation/d/1aRUTuKqe5Zye5igUTVxVwi8kEnuDzmVG/copy" target="_blank">Learn More &nbsp; <i class="fa fa-chevron-right" style="font-size: 12px;"></i></a>
                    </div>
                  </v-card>
                </div>

                <div class="dashboard-box stats-box w-full mb-4 md:w-1/4 lg:w-1/4">
                  <v-card
                    class="pa-2"
                    tile
                  >
                    <img :src="require('../../../assets/images/resources/sr-free-review-checkedr.png')" />
                    <div class="p-3">
                      <strong>Free Review Checker </strong>
                      <p class="text-gray-600 text-sm" style="min-height: 70px;">
                        What people are saying about you online…see your reviews and ratings
                      </p>
                      <a class="text-sm" href="https://reviewgrower.com/free-review-checker/" target="_blank">Learn More &nbsp; <i class="fa fa-chevron-right" style="font-size: 12px;"></i></a>
                    </div>
                  </v-card>
                </div>
              </div>
            </div>
    </panel-navigation>
</template>

<script>
import PanelNavigation from '@/components/PanelNavigation.vue'

export default {
  metaInfo () {
    return {
      title: "Resources | " + this.$appName
    }
  },
  components: {
    PanelNavigation
  },
}
</script>