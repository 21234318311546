<template>

  <div>
    <dialog-add-user v-if="me" ref="dialogAddUser" :is_business="me.company_type != 'agency'"></dialog-add-user>

    <panel-navigation>

      <div class="float-right ml-5 -mt-1">
        <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="$refs.dialogAddUser.createUser(); $refs.dialogAddUser.show()" style="margin-top: -5px;"><i class="fa fa-plus mr-2"> </i> ADD USER</div>
      </div>

      <h1 class="text-lg font-semibold mb-3">Users</h1>

      <hr class="border-b border-gray-100 mb-3">

      <v-skeleton-loader v-if="fetchingUsers" type="article, article, article"></v-skeleton-loader>

      <div class="dashboard-box" v-if="!fetchingUsers && users.length == 0">
        <v-card
          class="pa-2"
          tile
        >
          <div class="p-3">

            <br /><br /><br /><br />

            <div class="dashboard-box-iconsquare">
              <div class="dashboard-box-icon-background"></div>
              <div class="dashboard-box-icon">
                <i class="mdi mdi-accounts"></i>
              </div>
            </div>

            <div style="text-align: center;">
              <div class="pt-2 pb-2">
                <strong>No Users Yet</strong>
              </div>

              <div class="text-sm mb-4 text-gray-600">
                You have no users yet. Use our tools to add users.
              </div>

              <br />

              <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 inline-block rounded cursor-pointer" @click="$refs.dialogAddUser.createUser(); $refs.dialogAddUser.show()" style="margin-top: -5px;"><i class="fa fa-plus mr-2"></i> ADD NEW USER</div>
            </div>
            
            <br /><br /><br /><br /><br />
          </div>
        </v-card>
      </div>

      <template v-if="!fetchingUsers && users.length > 0">
        <div class="dashboard-box">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div class="justify-between items-start mb-3 float-right">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="flex flex-row items-center px-4 py-2 rounded border-1 border-gray-500 text-xs text-gray-600 cursor-pointer">
                      <span class="mr-2">Actions</span>
                      <span class="text-xs" style="padding-top: 3px;">
                        <font-awesome-icon icon="chevron-down"  />
                      </span>
                    </div>
                  </template>

                  <v-list class="table-menu-actions">
                    <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="bulkDelete()"><span class="table-menu-action-icon"><i class="fa fa-trash"></i></span> Delete</a></v-list-item-title></v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <br clear="all" />

              <div class="overflow-x-scroll">
              <table class="w-full rg-datatable">
                <thead class="border-b border-gray-100 w-full">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Added</th>
                    <th>Access</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody class="w-full" style="">
                  <tr v-for="user in sortedUsers" :key="user.id">
                    <td class="">
                      <v-checkbox
                        :id="user.id+'-checkbox'"
                        :value="user.id"
                        v-model="selectedIds"
                        hide-details
                         :ripple="false"
                      >
                      </v-checkbox>
                    </td>
                    <td>{{ user.first_name }} {{ user.last_name }}</td>
                    <td>{{ user.email ? user.email : '-' }}</td>
                    <td>{{ user.settings.role }}</td>
                    <td>{{ formatDate(user.created_at) }}</td>
                    <td>
                      <v-chip
                        v-if="user.status == 1"
                       
                        small
                        color="green lighten-5"
                        text-color="green lighten-1"
                      >
                        Enabled
                      </v-chip>

                      <v-chip
                        v-if="user.status == 0"
                       
                        small
                        color="grey lighten-3"
                        text-color="grey"
                      >
                        Disabled
                      </v-chip>
                    </td>
                    <td style="text-align: right;">
                      <v-menu
                        bottom
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="ml-3"
                            color="grey darken-1"
                          >
                            <i class="mdi mdi-dots-vertical"></i>
                          </v-btn>
                        </template>

                        <v-list class="table-menu-actions">
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="$refs.dialogAddUser.editUser(user); $refs.dialogAddUser.show()"><span class="table-menu-action-icon"><i class="fa fa-pen"></i></span> Edit</a></v-list-item-title></v-list-item>
                          <v-list-item v-if="user.status == 1" class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="disableAccess(user)"><span class="table-menu-action-icon"><i class="fa fa-times"></i></span> Disable Access</a></v-list-item-title></v-list-item>
                          <v-list-item v-if="user.status == 0" class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="enableAccess(user)"><span class="table-menu-action-icon"><i class="fa fa-check"></i></span> Enable Access</a></v-list-item-title></v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </v-card>
        </div>
      </template>
    </panel-navigation>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import PanelNavigation from '@/components/PanelNavigation.vue'
import MenuUserActions from '@/components/menus/menuUserActions.vue'
import DialogAddUser from '@/components/dialogs/users/addUser.vue'
import moment from 'moment'

export default {
  metaInfo () {
    return {
      title: "Users | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    MenuUserActions,
    DialogAddUser
  },
  data() {
    return {
      selectedIds: []
    }
  },
  computed: {
    ...mapState({
      users: state => state.users.users,
      fetchingUsers: state => state.users.fetchingUsers,
      me: state => state.me.me
    }),
    sortedUsers() {
      return this.users.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
  },
  methods: {
    async bulkDelete() {
      console.log(this.selectedIds)
      await this.$store.dispatch('users/bulkDelete', {Ids: this.selectedIds})
      this.$store.dispatch('users/fetch')
    },

    async disableAccess(business) {
      this.$store.state.users.fetchingUsers = true

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/disable-access`, {
          id: business.id
        },
        {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      this.$store.dispatch('users/fetch')
    },

    async enableAccess(business) {
      this.$store.state.users.fetchingUsers = true

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/enable-access`, {
          id: business.id
        },
        {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      this.$store.dispatch('users/fetch')
    },

    formatDate(date) {
      return moment(date).format("MMM D, YYYY")
    }
  },
  mounted() {
    this.$store.state.users.fetchingUsers = true
    this.$store.dispatch('users/fetch')
  }
}
</script>

<style lang="scss" scoped>

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 0px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: rgb(228, 228, 228);
  }

  // Box hover
  /*&:hover + label:before {
    background: #f35429;
  }*/
  
  // Box focus
  &:focus + label:before {
    
    border-width: 0;
  }

  // Box checked
  &:checked + label:before {
    background: #48bb78;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.btn-invite {
  background-color: #00ad9e;
}

table tbody tr {
  transition: all 0.1s linear;
}

table tbody tr:hover {
  background-color: rgb(245, 245, 245);
}
</style>
