import axios from 'axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    me: null,
    profile: null
  },
  getters: {
    getMe(state){ return state.me }
  },
  mutations: {
    setMe(state, data) {
      console.log('data', data)
      state.me = data.me
      state.profile = data.profile
    }
  },
  actions: {
    fetch({commit}) {
      return new Promise(async (resolve, reject) => {

        let r = router.history.current.name

        let url = `${process.env.VUE_APP_API_ENDPOINT}/me?r=${r}`;

        if (this.state.selected_location_email)
          url = url + "&selected_location_email=" + this.state.selected_location_email;
  
        try {
          let response = await axios.get(url, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })

          if (response.status == 200) {
            
            // Record identify for segment.com
            if (process.env.NODE_ENV != "development") {

              // if (!this.state.me.me) {
                if (!response.data.user.parent_id) {
                  // send login data to segment
                  window.analytics.identify(response.data.user.gosquared_person_id, {
                    name: response.data.user.first_name + " " + response.data.user.last_name,
                    email: response.data.user.email,
                    type: response.data.user.company_type
                  });
                // }
              }
            }

            // localStorage.clear();

            // if (localStorage.getItem('business_user'))
            //   response.data.profile = JSON.parse(localStorage.getItem('business_user'));

            commit('setMe', {
              me: response.data.user, 
              profile: response.data.profile
            });

            try {
              this.state.me.me.settings.notifications_emails = this.state.me.me.settings.notifications_emails.split(",");
            }
            catch(e) {}

            // If Agency logs in on main reviewgrower website, redirect them to their subdomain or domain.
            // alt = auto login token
            if (!this.state.auth.admin) {
              if (window.location.origin == process.env.VUE_APP_MAIN_URL && this.state.me.me.company_type == "agency" && r != "welcome") {
                if (this.state.me.me.domain && this.state.me.me.domain_status == 1)
                  window.location = window.location.protocol + '//' + this.state.me.me.domain + '/login?alt=' + btoa(this.state.auth.jwt);
                else
                  window.location = window.location.protocol + '//' + this.state.me.me.subdomain + '/login?alt=' + btoa(this.state.auth.jwt);

                return;
              }
            }

            // Check subscription
            try {
              if (this.state.me.me.company_type != "admin") {
                if (!this.state.me.me.subscription) {
                  router.push("/billing");
                  return
                }
                else {
                  if (this.state.me.me.subscription.stripe_status != "active" && this.state.me.me.subscription.stripe_status != "trialing") {
                    router.push("/billing");
                    return
                  }
                }
              }
            }
            catch (e) {}

            if (this.state.me.me.settings.onboarding_status == 0) {
              router.push("/onboarding");
              return;
            }

            if (typeof(clarity) == "undefined") {
              if (this.state.me.me.parent_id == 0) {
                // Install Miscrosoft Clarity
                (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "iumzntlm8o");
                // END Install Miscrosoft Clarity
              }
            }
            
            resolve()
            return;
          }
        } catch (error) {
          console.log(error)
          reject(error.response.data.message)
          return
        }
  
        reject()
  
      })
    },
    save(_, payload) {
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me`, payload, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    },
    change_password(_, payload) {
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/change_password`, payload, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    },
    createSubscription(_, payload) {
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/stripe/create`, payload, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    },
    changeSubscription(_, payload) {
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/stripe/change`, payload, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    },
    cancelSubscription(_, payload) {
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/stripe/cancel`, payload, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    }
  }

}