import axios from 'axios'

export default {
  namespaced: true,
  state: {
    items: false,
    folders: false
  },
  getters: {
    getItems(state){ return state.items },
    getFolders(state){ return state.folders }
  },
  mutations: {
    setItems(state, items) {
      state.items = items.items
      state.folders = items.folders
      state.fetchingitems = false
    },

    setFolders(state, folders) {
      state.folders = folders
    },

    setChartData(state, chart_data) {
      chart_data.initial_loading = false
      state.chart_data = chart_data
    }
  },
  actions: {
    fetch({commit}) {
      return new Promise(async (resolve, reject) => {
  
        try {
          let url = `${process.env.VUE_APP_API_ENDPOINT}/members-area`;

          let response = await axios.get(url, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
  
          if (response.status == 200) {
            commit('setItems', response.data)
            resolve()
            return;
          }
        } catch (error) {
          console.log(error)
          reject(error.response.data.message)
          return
        }
  
        reject()
  
      })
    },
    delete(_, id) {
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/members-area/${id}`, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    },
    delete_folder(_, id) {
      return new Promise(async (resolve, reject) => {

        try {
          let response = await axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/members-area/delete-folder/${id}`, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
          if (response.status == 200) {
            resolve()
            return;
          }
        } catch (error) {
          reject(error.response.data.message)
          return
        }

        reject()

      })
    },
  }

}