module.exports = {
    "www.agoda.com": "Agoda", // FOURTH BATCH
    "www.avvo.com": "Avvo", // FOURTH BATCH
	"www.expedia.com": "Expedia", // FOURTH BATCH
	"www.facebook.com": "Facebook",
	"www.google.com": "Google",
	"www.playstore.com": "Playstore", // FOURTH BATCH
	"www.tripadvisor.com": "Tripadvisor",
	"www.yelp.com": "Yelp",
	"www.zillow.com": "Zillow", // SECOND BATCH
	"www.zomato.com": "Zomato", // SECOND BATCH
	"www.airbnb.com": "Airbnb", // FOURTH BATCH
	"www.aliexpress.com": "Aliexpress", // FOURTH BATCH
	"www.alternativeto.com": "Alternativeto", // FOURTH BATCH
	"www.amazon.com": "Amazon", // THIRD BATCH
	"www.angi.com": "Angie's List", // FOURTH BATCH
	"www.apartmentratings.com": "Apartmentratings", // FOURTH BATCH
	"www.apartments.com": "Apartments", // THIRD BATCH
	"www.apps.apple.com": "Appstore", // FOURTH BATCH
	"www.bbb.org": "Bbb",
	"www.bookatable.co.uk": "Bookatable", // FIFTH BATCH
	"www.booking.com": "Booking", // SECOND BATCH
	"www.capterra.com": "Capterra", // FIFTH BATCH
	"www.cargurus.com": "Cargurus", // SIXTH BATCH
	"www.cars.com": "Cars", // FIFTH BATCH
	"www.citysearch.com": "Citysearch", // THIRD BATCH
	"www.classpass.com": "Classpass", // SIXTH BATCH
	"www.consumeraffairs.com": "Consumeraffairs", // SIXTH BATCH
	"www.creditkarma.com": "Creditkarma", // SIXTH BATCH
	"www.customerlobby.com": "Customerlobby", // SIXTH BATCH
	"www.dealerrater.com": "Dealerrater", // THIRD BATCH
	"www.ebay.com": "Ebay", // SECOND BATCH
	"www.edmunds.com": "Edmunds", // SIXTH BATCH
	"www.etsy.com": "Etsy", // SECOND BATCH
	"www.findlaw.com": "Findlaw", // SIXTH BATCH
	"www.foursquare.com": "Foursquare",
	"www.g2crowd.com": "G2crowd", // SECOND BATCH
	"www.gartner.com": "Gartner", // FIFTH BATCH
	"www.glassdoor.com": "Glassdoor",
	"www.greatschools.org": "Greatschools", // SIXTH BATCH
	"www.healthgrades.com": "Healthgrades",  // THIRD BATCH
	"www.homeadvisor.com": "Homeadvisor",
	"www.homeaway.com.au": "Homeaway",  // THIRD BATCH
	"www.homestars.com": "Homestars", // SIXTH BATCH
	"www.houzz.com": "Houzz", // FIFTH BATCH
	"www.hotels.com": "Hotels", // SECOND BATCH
	"www.indeed.com": "Indeed", // FIFTH BATCH
	"www.influenster.com": "Influenster", // FIFTH BATCH
	"www.insiderpages.com": "Insiderpages",
	"www.itcentralstation.com": "Itcentralstation", // SIXTH BATCH
	"www.lawyers.com": "Lawyers", // FIFTH BATCH
	// "www.lendingtree.com": "Lendingtree",
	// "www.martindale.com": "Martindale",
	"www.niche.com": "Niche", // FIFTH BATCH
	// "www.openrice.com": "Openrice",
	"www.opentable.com": "Opentable", // SECOND BATCH
	"www.producthunt.com": "Producthunt", // THIRD BATCH
	// "www.productreview.com.au": "Productreview",
	"www.ratemds.com": "Ratemds", // THIRD BATCH
	"www.realself.com": "RealSelf", // THIRD BATCH
	// "www.reserveout.com": "Reserveout",
	// "www.sitejabber.com": "Sitejabber",
	// "www.softwareadvice.com": "Softwareadvice",
	// "www.talabat.com": "Talabat",
	// "www.theknot.com": "Theknot",
	"www.thumbtack.com": "Thumbtack",
	// "www.trulia.com": "Trulia",
	// "www.trustedshops.com": "Trustedshops",
	"www.trustpilot.com": "Trustpilot", // SECOND BATCH
	"www.trustradius.com": "Trustradius", // SECOND BATCH
	// "www.vitals.com": "Vitals",
	"www.vrbo.com": "Vrbo", // FIFTH BATCH
	"www.walmart.com": "Walmart", // THIRD BATCH
	"www.webmd.com": "Webmd", // THIRD BATCH
	// "www.weddingwire.com": "Weddingwire",
	"www.yell.com": "Yell",
	"www.yellowpages.com": "Yellowpages",
	"www.zocdoc.com": "Zocdoc",
};