<template>

  <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-1/2 bg-white rounded-lg mx-6">

      <div class="rg-modal-header">
        Copy link to form

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6">

        <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          {{success}}
        </div>

        <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div v-html="error"></div>
        </div>

        <div class="mb-3">
          <p class="mb-1 text-xs mb-2"><strong>
              Link
          </strong></p>
          <input name="link" ref="link" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text"
            :value="getExternalContactFormLink()"
          >
        </div>

        <div id="qr-code-wrapper" class="mb-3 text-center">
          <p class="mb-1 text-xs mb-2"><strong>
              QR Code
          </strong></p>
          
          <div id="qrcode"></div>

          <a href="javascript: void(0);" @click="downloadQrCode();" class="mt-5" style="font-size: 13px;">Download PNG</a>
        </div>

      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" v-clipboard:copy="getExternalContactFormLink()" v-clipboard:success="onCopy" v-clipboard:error="onError">
            COPY LINK
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="$router.push('/contacts/form')">
            EDIT FORM
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
            CLOSE
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  props: ['contact_form'],
  data: function() {
    return {
      showModal: false,
      saving: false,
      success: null,
      error: null,
      qrc: null
    }
  },
  methods: {
    show() {
      let self = this
      this.showModal = true

      if (!this.qrc) {
        setTimeout(function() {
          this.qrc = new QRCode(document.getElementById("qrcode"), self.getExternalContactFormLink());
        }, 200);
      }
    },
    hide() {
      this.showModal = false
      this.saving = false
    },
	onCopy: function (e) {
		this.$swal({
			title: "Copied",
			text: "Link copied successfully!",
			showCancelButton: false,
			});
	},
	onError: function (e) {
		alert('Failed to copy the link to the clipboard')
		console.log(e);
	},
    copy() {
      
    },
    getExternalContactFormLink() {
      return this.contact_form.url;
      // let url = window.location.origin + "/add-contact/" + this.$store.state.me.me.uuid

      // return url;
    },

    downloadQrCode() {
      let img = document.querySelector("#qr-code-wrapper img");
      let imgSrc = img.src;

      // window.location.href = 'data:application/octet-stream;base64,' + img;
      window.location.href = imgSrc;

      var a = document.createElement("a"); //Create <a>
      // a.href = "data:image/png;base64," + ImageBase64; //Image Base64 Goes here
      a.href = imgSrc //Image Base64 Goes here
      a.download = "QrCode.png"; //File name Here
      a.click(); //Downloaded file
    }
  },

  mounted() {

  }
}
</script>

<style scoped>
  #qrcode {
    margin: auto; width: 250px;
  }
</style>