module.exports = {
    "www.agoda.com": "rgb(32, 123, 135)",
    "www.avvo.com": "rgb(4, 68, 124)",
	"www.expedia.com": "rgb(251, 195, 12)",
	"www.facebook.com": "#2477F6",
	"www.google.com": "#3EBB80",
	"www.playstore.com": "rgb(82, 213, 198)",
	"www.tripadvisor.com": "rgb(124, 177, 83)",
	"www.yelp.com": "#DD0909",
	"www.zillow.com": "rgb(4, 115, 227)",
	"www.zomato.com": "rgb(220, 92, 124)",
	"www.airbnb.com": "rgb(251, 91, 113)",
	"www.aliexpress.com": "rgb(228, 44, 4)",
	"www.alternativeto.com": "rgb(20, 116, 172)",
	"www.amazon.com": "rgb(51, 51, 51)",
	"www.angi.com": "rgb(40, 158, 53)",
	"www.apartmentratings.com": "rgb(38, 79, 128)",
	"www.apartments.com": "rgb(116, 188, 4)",
	"www.apps.apple.com": "rgb(20, 164, 247)",
	"www.bbb.org": "rgb(20, 100, 148)",
	"www.bookatable.co.uk": "rgb(27, 67, 75)",
	"www.booking.com": "rgb(28, 60, 116)",
	"www.capterra.com": "rgb(76, 164, 212)",
	"www.cargurus.com": "rgb(11, 165, 220)",
	"www.cars.com": "rgb(72, 29, 118)",
	"www.citysearch.com": "rgb(34, 145, 200)",
	"www.classpass.com": "rgb(4, 80, 241)",
	"www.consumeraffairs.com": "rgb(20, 108, 163)",
	"www.creditkarma.com": "rgb(60, 220, 164)",
	"www.customerlobby.com": "rgb(252, 144, 46)",
	"www.dealerrater.com": "rgb(219, 227, 237)",
	"www.ebay.com": "rgb(195, 183, 15)",
	"www.edmunds.com": "rgb(4, 124, 228)",
	"www.etsy.com": "rgb(244, 100, 4)",
	"www.findlaw.com": "rgb(243, 124, 29)",
	"www.foursquare.com": "rgb(242, 65, 109)",
	"www.g2crowd.com": "rgb(69, 100, 116)",
	"www.g2.com": "rgb(236, 76, 52)",
	"www.gartner.com": "rgb(6, 43, 85)",
	"www.glassdoor.com": "rgb(131, 179, 35)",
	"www.greatschools.org": "rgb(97, 202, 239)",
	"www.healthgrades.com": "rgb(5, 5, 234)",
	"www.homeadvisor.com": "rgb(92, 108, 124)",
	"www.homeaway.com.au": "rgb(42, 128, 226)",
	"www.homestars.com": "rgb(30, 172, 237)",
	"www.houzz.com": "rgb(75, 188, 19)",
	"www.hotels.com": "rgb(204, 44, 50)",
	"www.indeed.com": "rgb(30, 91, 242)",
	"www.influenster.com": "rgb(36, 28, 36)",
	"www.insiderpages.com": "rgb(148, 193, 182)",
	"www.itcentralstation.com": "rgb(123, 172, 52)",
	"www.lawyers.com": "rgb(6, 116, 202)",
	"www.lendingtree.com": "rgb(120, 196, 82)",
	"www.martindale.com": "rgb(215, 197, 164)",
	"www.niche.com": "rgb(84, 164, 60)",
	"www.openrice.com": "rgb(250, 202, 4)",
	"www.opentable.com": "rgb(212, 84, 88)",
	"www.producthunt.com": "rgb(219, 84, 52)",
	"www.productreview.com.au": "rgb(130, 187, 66)",
	"www.ratemds.com": "rgb(117, 180, 181)",
	"www.realself.com": "rgb(245, 129, 127)",
	"www.reserveout.com": "rgb(244, 107, 112)",
	"www.sitejabber.com": "rgb(244, 180, 124)",
	"www.softwareadvice.com": "rgb(248, 187, 100)",
	"www.talabat.com": "rgb(246, 164, 95)",
	"www.theknot.com": "rgb(167, 214, 244)",
	"www.thumbtack.com": "rgb(92, 100, 100)",
	"www.trulia.com": "rgb(104, 207, 151)",
	"www.trustedshops.com": "rgb(228, 196, 12)",
	"www.trustpilot.com": "rgb(16, 148, 124)",
	"www.trustradius.com": "rgb(24, 88, 156)",
	"www.vitals.com": "rgb(90, 66, 103)",
	"www.vrbo.com": "rgb(6, 32, 158)",
	"www.walmart.com": "rgb(249, 219, 120)",
	"www.webmd.com": "rgb(124, 172, 204)",
	"www.weddingwire.com": "rgb(204, 236, 238)",
	"www.yell.com": "rgb(251, 227, 4)",
	"www.yellowpages.com": "rgb(193, 188, 12)",
	"www.zocdoc.com": "rgb(188, 188, 76)",
	"www.direct.com": "#999999",
};