<template>

  <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-3/4 bg-white rounded-lg mx-6" style="max-width: 1000px;">

      <div class="rg-modal-header">
        <span v-if="id">Edit Item</span>
        <span v-if="!id">Add a Item</span>

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6">

        <div class="flex flex-row flex-wrap">
          <div class="w-full md:w-1/2 lg:w-1/2 sm:w-full">

            <div class="mb-3">
              <p class="mb-1 text-xs mb-2"><strong>
                Title
              </strong></p>
              <input name="title" ref="title" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div class="mb-3">
              <p class="mb-1 text-xs mb-2"><strong>
                Description
              </strong></p>
              <textarea name="description" ref="description" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="height: 200px;"></textarea>
            </div>

            <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
              {{success}}
            </div>

            <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
              <div v-html="error"></div>
            </div>
          </div>

          <div class="w-full md:w-1/2 lg:w-1/2 sm:w-full">
            <div class="pl-3">
              <p class="mb-1 text-xs mb-2"><strong>
                Image
              </strong></p>
              
              <v-file-input
                accept="image/*"
                v-model="file"
                @change="onUpload"
                class="rg-uploader"
                prepend-icon="mdi-camera"
                outlined
                hide-input
              ></v-file-input>

              <div v-if="image" style="padding-top: 0px;">
                <span v-if="image" style="text-align: center;">
                  <img :src="image" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                  <v-btn
                    depressed
                    color="error"
                    icon
                    small
                    @click="image = '';"
                  >
                    <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                  </v-btn>
                </span>
              </div>

              <i v-if="uploading" class="fa fa-spin fa-spinner" style=""></i>
            </div>

            <div class="mb-3 pl-3">
              <p class="mb-1 text-xs mb-2"><strong>
                Action Link
              </strong></p>
              <input name="link" ref="link" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div class="mb-3 pl-3">
              <p class="mb-1 text-xs mb-2"><strong>
                Action Text
              </strong></p>
              <input name="link_text" ref="link_text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': saving}" @click="save">
            SAVE
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
            CANCEL
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  data: function() {
    return {
      showModal: false,
      saving: false,
      success: null,
      error: null,
      id: null,

      file: null,
      image: null,

      uploading: false,

      selected_folder_id: null
    }
  },
  methods: {
    show() {
      this.showModal = true
    },
    hide() {
      this.showModal = false
      this.saving = false
    },
    async save() {
      this.saving = true
      this.error = null
      try {

        let formData = new FormData();
        // formData.append("name", this.name);
        if (this.id)
          formData.append("id", this.id);

        if (this.selected_folder_id)
          formData.append("selected_folder_id", this.selected_folder_id);

        formData.append("title", this.$refs.title.value);
        formData.append("description", this.$refs.description.value);
        formData.append("image", ((this.image == null) ? "" : this.image));
        formData.append("link", this.$refs.link.value);
        formData.append("link_text", this.$refs.link_text.value);

        await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/members-area/add`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
              'Content-Type': 'multipart/form-data'
            }
        })

        this.hide()

      this.$parent.$parent.getFolders();
    	this.error = null

      } catch (e) {
      	console.log(e);
        this.error = e.response.data.message
      }
      this.saving = false
    },

    addItem(selected_folder_id) {
      let self = this;
      this.id = null;
      this.selected_folder_id = selected_folder_id;
      setTimeout(function() {
        try {
          this.$refs.title.value = "";
          this.$refs.description.value = "";
          this.$refs.image.value = "";
          this.$refs.link.value = "";
          this.$refs.link_text.value = "";
        }
        catch (e) {}
      }, 10);
    },

    editItem(item) {
      let self = this;
      this.id = item.id;
      this.selected_folder_id = null;
      console.log('item', item);
      setTimeout(function() {
        self.$refs.title.value = item.title;
        self.$refs.description.value = item.description;
        self.image = item.image;
        self.$refs.link.value = item.link;
        self.$refs.link_text.value = item.link_text;
      }, 100);
    },

    async onUpload() {
      // console.log(this.file);
      if (this.file !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.image = response.data.url

        }catch(e){
          console.log(e)
        }

        this.file = null
        this.uploading = false
      }
    }
  },
  created() {

  }
}
</script>
