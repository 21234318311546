<template>

  <div>
    <dialog-send-test-email ref="dialogSendTestEmail"></dialog-send-test-email>
    <dialog-view-integrations-instructions ref="dialogViewIntegrationsInstructions"></dialog-view-integrations-instructions>
    <panel-navigation>
    <dialog-buy-number ref="dialogBuyNumber"></dialog-buy-number>

    <dialog-add-item ref="dialogAddItem"></dialog-add-item>
    <dialog-add-folder ref="dialogAddFolder"></dialog-add-folder>
    <dialog-move-to-folder ref="dialogMoveToFolder"></dialog-move-to-folder>

    <dialog-setup-a2p ref="dialogSetupA2p"></dialog-setup-a2p>

      <div class="float-right ml-5 -mt-1">
        <div v-show="!$agency.settings.is_omni" class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="openGuide()" style="margin-top: -5px;"><i class="fa fa-link mr-2"> </i> GETTING STARTED GUIDE</div>
      </div>
      
      <div class="flex flex-row items-top justify-between">
        <h1 class="text-lg font-semibold mb-3">Settings</h1>
        <!-- <div class="bg-green-500 hover:bg-green-400 text-white inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': saving}" @click="save">Save</div> -->
      </div>
      
      <hr class="border-b border-gray-100 mb-3">

      <v-skeleton-loader
        type="article"
        v-if="!$agency"
      ></v-skeleton-loader>

      <div class="dashboard-box" v-if="me && $agency">
        <v-card
          class="pa-2"
          tile
        >
          <div class="p-3 pt-1">

            <template>
              <div v-if="profile.settings.role == 'Manager (readonly)' || !profile.settings.permissions.edit_agency_settings">
                You do not have permission to change the settings of the agency. Contact your account owner.
              </div>
              <v-tabs v-else>
                <v-tab>Main Settings</v-tab>
                <v-tab v-show="!$agency.settings.is_omni">Registration</v-tab>
                <v-tab>White Label</v-tab>
                <v-tab>Integrations</v-tab>
                <v-tab v-show="!$agency.settings.is_omni">Grid Tracker</v-tab>
                <v-tab>Custom Javascript/HTML</v-tab>
                <v-tab v-show="!$agency.settings.is_omni">Members Area</v-tab>

                <v-tab-item class="pt-5">
                  <div class="flex flex-row flex-wrap -mx-3">
                    <div class="w-full lg:w-1/3  px-3">
                      <p class="mb-1 text-xs mb-2"><strong>Agency Name</strong></p>
                      <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"
                      v-model="me.company_name"  />
                      <p class="text-xs text-gray-500 mt-2">This will also replace "Reviewgrower" all over the app.</p>
                    </div>
                    <div v-show="!$agency.settings.is_omni" class="w-full lg:w-2/3  px-3">
                      <div class="flex flex-row flex-wrap -mx-3">
                        <div class="w-full lg:w-1/2  px-3">
                          <p class="mb-1 text-xs mb-2"><strong>Privacy Policy Link</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.stripe.settings.privacy_policy_link" placeholder="https://"  />
                        </div>
                        <div class="w-full lg:w-1/2 pl-3" style="position: relative;">
                          <p class="mb-1 text-xs mb-2"><strong>Terms of Service Link</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.stripe.settings.terms_of_service_link" placeholder="https://" />
                        </div>
                      </div>

                      <p class="text-xs text-gray-500 mt-2">Your Privacy Policy and Terms of Service links are used to generate your customer portal in Stripe and in Local Recon reports.</p>
                    </div>
                  </div>

                  <hr class="mt-4 mb-4">

                  <div v-show="!$agency.settings.is_omni" class="w-full">
                    <p class="mb-1 text-xs mb-2"><strong>Permissions</strong></p>
                    
                    <v-checkbox
                       :ripple="false"
                      v-model="me.settings.allow_business_to_modify_review_sites"
                      label="Allow businesses to modify review sites"
                      hide-details
                    ></v-checkbox>

                    <br />
                    
                    <v-checkbox
                       :ripple="false"
                      v-model="me.settings.allow_business_to_modify_sms_from_number"
                      label="Allow businesses to modify SMS from number"
                      hide-details
                    ></v-checkbox>
                  </div>

                  <hr v-show="!$agency.settings.is_omni" class="mt-4 mb-4" />

                  <div class="w-full">
                    <p class="mb-1 text-xs mb-2"><strong>Contacts Webhook</strong></p>

                    <p class="text-xs text-gray-500">Select which webhook your customers will use for adding contacts.</p>
                    
                    <v-radio-group
                      class="mt-2"
                    v-model="me.settings.contacts_webhook"
                    >
                      <v-radio
                        :label="getExternalContactFormLink()"
                        value="regular"
                        on-icon="mdi-circle"
                        off-icon="mdi-circle-outline"
                        :ripple="false"
                      ></v-radio>
                      <v-radio
                        :label="getExternalContactFormLinkWhiteLabeled()"
                        value="white_labeled"
                        on-icon="mdi-circle"
                        off-icon="mdi-circle-outline"
                        :ripple="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>

                  <hr class="mt-4 mb-4">

                  <div class="w-full">
                    <p class="mb-1 text-xs mb-2"><strong>Custom CSS</strong></p>
                    
                    <textarea name="" id="" rows="14" class="text-sm block appearance-none focus:outline-none focus:border-gray-500 w-full bg-gray-100 hover:border-grey px-4 py-4 rounded" v-model="me.settings.custom_css" placeholder="/* Example */
header {
    background-color: purple !important;
}

nav.v-navigation-drawer {
    background-color: purple !important;
}

.me-icon {
    background-color: #b794f4 !important;  
}" ></textarea>

                    <p class="text-xs text-gray-500 mt-2">Enter custom CSS to change the UI of the app. <a href="https://reviewgrower.com/documentation/how-to-customize-your-user-interface-using-custom-css/" target="custom-css-documentation">Custom CSS Documentation</a></p>
                  </div>

                  <hr class="mt-3" />

                  <div class="float-right mt-6">
                    <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingAgencySettings}" @click="saveAgencySettings()">Save</div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pt-5">
                  <div class="w-full">
                    <p class="mb-1 text-xs mb-2"><strong>Signup</strong></p>
                    
                    <v-checkbox
                       :ripple="false"
                      v-model="me.settings.signup_link"
                      label="Enable signup"
                      hide-details
                    ></v-checkbox>
                  </div>

                  <div v-show="me.settings.signup_link == 0" class="w-full mt-3">
                    <p class="mb-1 text-xs mb-2"><strong>Signup Page</strong></p>
                    
                    <v-checkbox
                       :ripple="false"
                      v-model="me.settings.signup_page"
                      label="Allow opening the signup page directly"
                      hide-details
                    ></v-checkbox>
                  </div>

                  <div v-show="me.settings.signup_link == 0 && me.settings.signup_page == 0" class="w-full mt-3">
                    <p class="mb-1 text-xs mb-2"><strong>Stripe Plams</strong></p>
                    
                    <v-checkbox
                      :ripple="false"
                      v-model="me.settings.allow_stripe_plans_signups"
                      label="Only Allow Signup for Stripe Plans"
                      hide-details
                    ></v-checkbox>
                  </div>

                  <div class="w-full mt-3">
                    <p class="mb-1 text-xs mb-2"><strong>Onboarding</strong></p>
                    
                    <v-checkbox
                      :ripple="false"
                      v-model="me.settings.onboarding_enabled"
                      label="Enable or disable onboarding for businesses"
                      hide-details
                    ></v-checkbox>
                  </div>

                  <hr class="mt-3" />

                  <div class="float-right mt-6">
                    <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingAgencySettings}" @click="saveAgencySettings()">Save</div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pt-5">
                  <div class="flex flex-row flex-wrap -mx-3">
                    <div class="w-full mb-4 px-3">
                      <div class="bg-gray-100 rounded p-4">

                        <h2 class="font-bold text-sm text-gray-800 mb-4">Domain</h2>

                        <a href="javascript: void(0);" v-if="!show_subdomain_field" @click="show_subdomain_field = true" style="font-size: 17px;">{{ me.domain || '- none, click here to setup -' }}</a>

                        <input type="text" ref="domain" v-if="show_subdomain_field === true" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white border hover:border-grey px-2 py-2 rounded" placeholder="e.b. mydomain.com" style="width: calc(100% - 100px); display: inline-block; margin-right: 10px;"  />

                        <div v-if="show_subdomain_field === true" class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingDomain}" @click="saveDomain()">Save</div>

                        <div v-if="errorDomain" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                          <div v-html="errorDomain"> </div>
                        </div>

                        <div class="flex flex-row flex-wrap -mx-3 mt-3" style="font-size: 13px;">
                          <div class="w-full sm:w-1/3 px-3">
                            <div class="">
                              DNS Settings:
                              <div class="mt-2">
                                <strong>CNAME:</strong> dns.reviewgrower.com
                                <br />
                                <strong>A Record:</strong> 54.163.86.186
                              </div>
                            </div>
                          </div>
                          <div class="w-full sm:w-1/3">
                            <p class="" style="margin-bottom: 0px;">
                              Domain Status: 
                            </p>

                            <div v-if="!me.domain">
                              <i class="fa fa-exclamation-circle" style="color: orange; font-size: 24px;"></i>
                              <br />
                              <p class="0">
                                No domain setup <i v-if="me.domain && (me.domain_status == 0 || me.ssl_status == 0)" class="fa fa-spin fa-spinner" style="margin-left: 4px; font-size: 14px;"></i>
                              </p>
                            </div>

                            <div v-if="me.domain && me.domain_status == 0">
                              <i class="fa fa-times-circle" style="color: red; font-size: 24px;"></i>
                              <br />
                              <p class="0">
                                Check DNS settings <i v-if="me.domain && (me.domain_status == 0 || me.ssl_status == 0)" class="fa fa-spin fa-spinner" style="margin-left: 4px; font-size: 14px;"></i>
                              </p>
                            </div>

                            <div v-if="me.domain && me.domain_status == 1">
                              <i class="mdi mdi-check-circle-outline" style="color: green; font-size: 24px;"></i>
                            </div>
                          </div>
                          <div class="w-full sm:w-1/3">
                            <p class="" style="margin-bottom: 0px;">
                              SSL Status: 
                            </p>

                            <p class="" style="display: inline-block;">
                              <i v-if="me.ssl_status == 1" class="mdi mdi-check-circle-outline float-left" style="color: green; font-size: 24px;"></i>
                              <span v-if="me.ssl_status == 1" class="float-right mt-2 ml-2">expires {{ formatDate(me.ssl_expiration) }}</span>
                              <i v-if="me.ssl_status == 0" class="fa fa-times-circle" style="color: red; font-size: 24px;"></i>
                              <span v-if="me.ssl_status == 0 && me.domain_status == 1">&nbsp;&nbsp;(please check later)</span>
                              <span v-if="me.ssl_status == 0 && me.domain_status == 0">&nbsp;&nbsp;(check DNS Settings)</span>

                              <i v-if="me.domain && (me.domain_status == 0 || me.ssl_status == 0)" class="fa fa-spin fa-spinner" style="margin-left: 5px; font-size: 14px;"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="w-full px-3">
                      <div class="" >

                        <div class="float-left" style="width: 200px; max-width: 100%;">
                          <strong class="text-sm">Agency Logo</strong>
                          <br />

                          <v-file-input
                            accept="image/*"
                            v-model="file"
                            @change="onUpload"
                            class="rg-uploader"
                            prepend-icon="mdi-camera"
                            outlined
                            hide-input
                          ></v-file-input>

                          <div v-if="$agency.logo" style="padding-top: 0px;">
                            <span v-if="$agency.logo" style="text-align: center;">
                              <img :src="$agency.logo" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                              <v-btn
                                depressed
                                color="error"
                                icon
                                small
                                @click="$agency.logo = ''; removeLogo()"
                              >
                                <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                              </v-btn>
                            </span>
                          </div>

                          <i v-if="uploading" class="fa fa-spin fa-spinner" style=""></i>
                        </div>

                        <div class="float-left" style="width: 200px; max-width: 100%;">
                          <strong class="text-sm">Agency Mobile Logo</strong>
                          <br />

                          <v-file-input
                            accept="image/*"
                            v-model="file_mobile"
                            @change="onUploadMobile"
                            class="rg-uploader"
                            prepend-icon="mdi-camera"
                            outlined
                            hide-input
                          ></v-file-input>

                          <div v-if="$agency.settings.logo_mobile" style="padding-top: 0px;">
                            <span v-if="$agency.settings.logo_mobile" style="text-align: center;">
                              <img :src="$agency.settings.logo_mobile" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                              <v-btn
                                depressed
                                color="error"
                                icon
                                small
                                @click="$agency.settings.logo_mobile = ''; removeLogoMobile()"
                              >
                                <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                              </v-btn>
                            </span>
                          </div>

                          <i v-if="uploading_mobile" class="fa fa-spin fa-spinner" style=""></i>
                        </div>

                        <div class="float-left" style="width: 200px; max-width: 100%;">
                          <strong class="text-sm">Agency Favicon</strong>
                          <br />

                          <v-file-input
                            accept="image/*"
                            v-model="file_favicon"
                            @change="onUploadFavicon"
                            class="rg-uploader"
                            prepend-icon="mdi-camera"
                            outlined
                            hide-input
                          ></v-file-input>

                          <div v-if="$agency.settings.favicon" style="padding-top: 0px;">
                            <span v-if="$agency.settings.favicon" style="text-align: center;">
                              <img :src="$agency.settings.favicon" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                              <v-btn
                                depressed
                                color="error"
                                icon
                                small
                                @click="$agency.settings.favicon = ''; removeFavicon()"
                              >
                                <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                              </v-btn>
                            </span>
                          </div>

                          <i v-if="uploading_favicon" class="fa fa-spin fa-spinner" style=""></i>
                        </div>

                        <br clear="all" />

                        <br clear="all" /><br clear="all" />

                        <p v-show="!$agency.settings.is_omni" class="-mb-2"><strong>Dashboard Link (for businesses)</strong></p>


                        <toggle-button v-show="!$agency.settings.is_omni" v-model="me.settings.dashboard_link" color="#48bb78" :sync="true" />

                        <div class="flex flex-row flex-wrap -mx-3" v-show="me.settings.dashboard_link">
                          <div class="w-full lg:w-1/3  px-3">
                            <p class="mb-1 text-xs mb-2"><strong>Link</strong></p>
                            <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"
                            v-model="me.settings.dashboard_link_url"  />
                          </div>

                          <div class="w-full lg:w-1/3  px-3">
                            <p class="mb-1 text-xs mb-2"><strong>Text</strong></p>
                            <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"
                            v-model="me.settings.dashboard_link_text"  />
                          </div>

                          <div class="w-full lg:w-1/3  px-3">
                            <p class="mb-1 text-xs mb-2"><strong>Icon</strong></p>
                            
                            <div class="float-left">
                              <v-icon-picker v-model="me.settings.dashboard_link_icon" direction="up"></v-icon-picker>
                            </div>

                            <div class="float-left pl-2">
                              <i :class="me.settings.dashboard_link_icon" style="font-size: 20px;"></i>
                            </div>
                          </div>
                        </div>

                        <hr class="mt-3" />

                        <div class="float-right mt-6">
                          <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingAgencySettings}" @click="saveAgencySettings()">Save</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pt-5">

                  <v-chip
                    v-if="agency_settings_saved"
                    class="ma-2"
                    color="green"
                    outlined
                    @click="agency_settings_saved = false"
                  >
                    <v-icon left>
                      mdi-check
                    </v-icon>
                    Saved
                  </v-chip>

                  <div class="mb-4 mt-4">
                    <h2 class="font-bold text-gray-800">Twilio Settings</h2>
                  </div>

                  <div class="flex flex-row flex-wrap">
                    <div class="w-full lg:w-1/3 pr-3">
                      <p class="mb-1 text-xs mb-2"><strong>Account SID</strong></p>
                      <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"
                      v-model="me.integrations.twilio.settings.account_sid"  />
                    </div>
                    <div class="w-full lg:w-1/3 px-3" style="position: relative;">
                      <p class="mb-1 text-xs mb-2"><strong>Auth Token</strong></p>
                      <input :type="((show_auth_token) ? 'text' : 'password')" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"  v-model="me.integrations.twilio.settings.auth_token" style="padding-right: 35px !important;" />

                      <a href="javascript: void(0);" @click="show_auth_token = !show_auth_token" class="token_password_toggle">
                        <i v-if="show_auth_token" class="fa fa-eye"></i>
                        <i v-if="!show_auth_token" class="fa fa-eye-slash"></i>
                      </a>
                    </div>

                    <div class="w-full lg:w-1/3 pr-3">
                      <div class="flex flex-row flex-wrap">
                        <div class="w-full lg:w-1/2 pl-3">
                          <div v-if="errorTwilio" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                            <div v-html="errorTwilio"> </div>
                          </div>

                          <p v-if="me.integrations.twilio.status == 1" class="mb-1 text-xs mb-2"><strong>Outgoing SMS Phone Number</strong></p>
                          
                          <v-select v-if="me.integrations.twilio.status == 1 && !gettingTwilioNumbers"
                                v-model="me.integrations.twilio.settings.phone_number"
                                flat
                                solo-inverted
                                hide-details
                                :items="me.integrations.twilio.settings.numbers"
                                label="Select Number"
                                class="-mt-2"
                            ></v-select>

                          <div v-if="me.integrations.twilio.status == 1 && me.integrations.twilio.settings.numbers.length == 0 && !gettingTwilioNumbers" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4 mt-1" role="warning">
                            <div>Please purchase a phone number.</div>
                          </div>

                            <i v-if="gettingTwilioNumbers" class="fa fa-spin fa-spinner" style="font-size: 14px;"></i>
                        </div>
                        <div class="w-full lg:w-1/2 px-3 pl-10">
                          <p class="mb-1 text-xs mb-2"><strong>Link Shortening</strong>
                            <a href="https://www.twilio.com/docs/messaging/how-to-send-messages-with-link-shortening" target="twilio-link-shortening" class="ml-2"><i class="mdi mdi-information" style="font-size: 16px;"></i></a>
                          </p>

                          <v-checkbox
                            class="mt-4"
                            label="Enabled"
                            value="yes"
                            hide-details
                            v-model="me.integrations.twilio.settings.link_shortening"
                              :ripple="false"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer mt-2" :class="{'spinner': savingTwilio}" @click="saveTwilioSettings()">Save</div>

                  <v-chip
                    v-if="me.integrations.twilio.status == 1 && me.integrations.twilio.settings.phone_number"
                    class="ma-2"
                    color="green"
                    outlined
                    @click="saved = false"
                  >
                    <v-icon left>
                      mdi-check
                    </v-icon>
                    Valid
                  </v-chip>

                  <div v-if="me.integrations.twilio.status == 1 && me.integrations.twilio.settings.phone_number" style="display: inline-block; font-size: 12px; padding-left: 15px;">
                    <a href="javascript: void(0);" @click="$refs.dialogSetupA2p.show(); $refs.dialogSetupA2p.success = ''">Setup A2P Campaign</a>
                  </div>

                  <div v-if="me.integrations.twilio.status == 1 && me.integrations.twilio.settings.phone_number"
                    class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer mt-2 ml-2  float-right" @click="$refs.dialogBuyNumber.show()"><i class="fa fa-plus"></i> Buy Number</div>

                  <hr class="mt-5 mb-5" />

                  <div class="flex flex-row flex-wrap">
                    <div class="w-full mb-4">
                      
                      <div v-show="!$agency.settings.is_omni">
                        <div class="mb-4">
                          <h2 class="font-bold text-gray-800">Stripe Settings</h2>
                        </div>
                        
                        <div class="flex flex-row flex-wrap">
                          <div class="w-full lg:w-1/3 pr-3">
                            <p class="mb-1 text-xs mb-2"><strong>Publishable Key</strong></p>
                            <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.stripe.settings.publishable_key"  />
                          </div>
                          <div class="w-full lg:w-1/3 pl-3" style="position: relative;">
                            <p class="mb-1 text-xs mb-2"><strong>Secret Key</strong></p>
                            <input :type="((show_stripe_key) ? 'text' : 'password')" data-lpignore="true" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"  v-model="me.integrations.stripe.settings.secret_key" style="padding-right: 35px !important;" />

                            <a href="javascript: void(0);" @click="show_stripe_key = !show_stripe_key" class="token_password_toggle">
                              <i v-if="show_stripe_key" class="fa fa-eye"></i>
                              <i v-if="!show_stripe_key" class="fa fa-eye-slash"></i>
                            </a>
                          </div>
                        </div>

                        <p class="text-xs text-gray-500 mt-2">Connect your Stripe account to bill your customers.</p>

                        <div v-if="stripeSettingsError" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                          <div v-html="stripeSettingsError"> </div>
                        </div>
                        
                        <div class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingStripeSettings}" @click="saveStripeSettings()">Save</div>

                        <v-chip
                          v-if="me.integrations.stripe.status == 1 && !stripeSettingsError && !savingStripeSettings && me.integrations.stripe.settings.customer_portal"
                          class="ma-2"
                          color="green"
                          outlined
                          @click="saved = false"
                        >
                          <v-icon left>
                            mdi-check
                          </v-icon>
                          Valid
                        </v-chip>

                        <hr class="mt-5 mb-5" />
                      </div>

                      <div class="mb-4 mt-4">
                        <h2 class="font-bold text-gray-800">Email Settings</h2>
                      </div>

                      <div class="flex flex-row flex-wrap">
                        <div class="w-full lg:w-1/3 pr-3">
                          <p class="mb-1 text-xs mb-2"><strong>From Name</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.from_name"  />
                        </div>
                        <div class="w-full lg:w-1/3 pl-3">
                          <p class="mb-1 text-xs mb-2"><strong>From Email</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.from_email"  />
                        </div>
                        <div class="w-full lg:w-1/3 px-3">
                          <p class="mb-1 text-xs mb-2"><strong>Username</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.username"  />
                        </div>
                        <div class="w-full lg:w-1/3 mt-3" style="position: relative;">
                          <p class="mb-1 text-xs mb-2"><strong>Password</strong></p>
                          <input :type="((show_smtp_password) ? 'text' : 'password')" data-lpignore="true" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.password" style="padding-right: 35px !important;" />

                          <a href="javascript: void(0);" @click="show_smtp_password = !show_smtp_password" class="token_password_toggle">
                            <i v-if="show_smtp_password" class="fa fa-eye"></i>
                            <i v-if="!show_smtp_password" class="fa fa-eye-slash"></i>
                          </a>
                        </div>
                        <div class="w-full lg:w-1/3 mt-3 px-3">
                          <p class="mb-1 text-xs mb-2"><strong>Host</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.host"  />
                        </div>
                        <div class="w-full lg:w-1/3 pr-3">
                          <div class="flex flex-row flex-wrap">
                            <div class="w-full lg:w-1/2 mt-3 pl-3">
                              <p class="mb-1 text-xs mb-2"><strong>Port</strong></p>
                              <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.port"  />
                            </div>
                            <div class="w-full lg:w-1/2 mt-3 px-3 pl-10">
                              <p class="mb-1 text-xs mb-2"><strong>SSL/TLS</strong></p>

                              <v-checkbox
                                class="mt-4"
                                label="Enabled"
                                value="yes"
                                hide-details
                                v-model="me.integrations.smtp.settings.ssl_tls"
                                 :ripple="false"
                              ></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="w-full mt-3">
                          <div class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingSMTP}" @click="saveSMTPSettings()">Save</div>

                          <v-chip
                            v-if="me.integrations.smtp.status == 1"
                            class="ma-2"
                            color="green"
                            outlined
                            @click="saved = false"
                          >
                            <v-icon left>
                              mdi-check
                            </v-icon>
                            Valid
                          </v-chip>

                          <div v-if="me.integrations.smtp.status == 1" class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer mt-2 ml-2  float-right" @click="$refs.dialogSendTestEmail.show()"><i class="fa fa-envelope"></i> Send Test Email</div>
                        </div>
                        <div class="w-full mt-3 pl-3">
                          <div v-if="errorSMTP" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                            <div v-html="errorSMTP"> </div>
                          </div>
                        </div>
                      </div>

                      <hr class="mt-5 mb-5" />

                      <div v-show="agencyHasGridTrackerAddon && !$agency.settings.is_omni">
                      
                        <div class="w-full mb-4">

                          <div class="mb-4">
                            <h2 class="font-bold text-gray-800">Google Maps</h2>
                          </div>

                          <div class="flex flex-row flex-wrap">
                            <div class="w-full lg:w-1/3 pr-3" style="position: relative;">
                              <p class="mb-1 text-xs mb-2"><strong>API Key</strong></p>
                              <input :type="((show_maps_key) ? 'text' : 'password')" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"  v-model="me.integrations.google_maps.settings.api_key" style="padding-right: 35px !important;" />

                              <a href="javascript: void(0);" @click="show_maps_key = !show_maps_key" class="token_password_toggle">
                                <i v-if="show_maps_key" class="fa fa-eye"></i>
                                <i v-if="!show_maps_key" class="fa fa-eye-slash"></i>
                              </a>
                            </div>
                          </div>

                          <div v-if="errorGoogleMaps" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                            <div v-html="errorGoogleMaps"> </div>
                          </div>

                          <div class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer mt-2" :class="{'spinner': savingGoogleMapsKey}" @click="saveGoogleMapsKey()">Save</div>

                          <v-chip
                            v-if="me.integrations.google_maps.settings.api_key && me.integrations.google_maps.status == 1"
                            class="ma-2"
                            small
                            color="green lighten-5"
                            text-color="green lighten-1"
                            @click="saved = false"
                          >
                            <i class="mdi mdi-checkbox-marked-circle-outline mr-1"></i>
                            Valid
                          </v-chip>

                        </div>

                        <hr class="mt-5 mb-5" />
                      
                        <div class="w-full mb-4">

                          <div class="mb-4">
                            <h2 class="font-bold text-gray-800">Value Serp</h2>

                            <a href="https://valueserp.com" class="text-sm">Sign Up for ValueSerp</a>
                          </div>

                          <div class="flex flex-row flex-wrap">
                            <div class="w-full lg:w-1/3 pr-3" style="position: relative;">
                              <p class="mb-1 text-xs mb-2"><strong>API Key</strong></p>
                              <input :type="((show_value_serp_key) ? 'text' : 'password')" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"  v-model="me.integrations.value_serp.settings.api_key" style="padding-right: 35px !important;" />

                              <a href="javascript: void(0);" @click="show_value_serp_key = !show_value_serp_key" class="token_password_toggle">
                                <i v-if="show_value_serp_key" class="fa fa-eye"></i>
                                <i v-if="!show_value_serp_key" class="fa fa-eye-slash"></i>
                              </a>
                            </div>
                          </div>

                          <div v-if="errorValueSerp" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                            <div v-html="errorValueSerp"> </div>
                          </div>

                          <div class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer mt-2" :class="{'spinner': savingValueSerpKey}" @click="saveValueSerpKey()">Save</div>

                          <v-chip
                            v-if="me.integrations.value_serp.settings.api_key && me.integrations.value_serp.status == 1"
                            class="ma-2"
                            small
                            color="green lighten-5"
                            text-color="green lighten-1"
                            @click="saved = false"
                          >
                            <i class="mdi mdi-checkbox-marked-circle-outline mr-1"></i>
                            Valid
                          </v-chip>

                        </div>

                      

                        <hr class="mt-5 mb-2" />

                      </div>

                    </div>


                    <div class="flex flex-row flex-wrap">
                      <div class="v-full">
                        <p class="text-xs text-gray-500">Please configure your Twilio and SMTP settings to be able to send emails and SMS. For more information view <a href="javascript: void(0);" @click="$refs.dialogViewIntegrationsInstructions.show()">instructions</a>.</p>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pt-5">
                  
                    <div class="w-full mb-4">

                      <div class="mb-4">
                        <h2 class="font-bold text-gray-800">Grid Tracker</h2>
                      </div>

                      <div class="flex flex-row flex-wrap">
                        <div class="w-full lg:w-1/3 pr-3" style="position: relative;">
                          <p class="mb-1 text-xs mb-2"><strong>Business Search Preferred API</strong></p>
                          <v-radio-group
                            class="mt-2"
                          v-model="me.settings.grid_use_which_api"
                          >
                            <v-radio
                              :label="'Google Maps'"
                              value="google_maps"
                              on-icon="mdi-circle"
                              off-icon="mdi-circle-outline"
                              :ripple="false"
                            ></v-radio>
                            <v-radio
                              :label="'Value Serp'"
                              value="value_serp"
                              on-icon="mdi-circle"
                              off-icon="mdi-circle-outline"
                              :ripple="false"
                            ></v-radio>
                          </v-radio-group>

                          <p class="text-xs text-gray-700 mt-2">Choose your preferred method of searching for businesses.</p>
                        </div>
                      </div>

                      <hr class="mb-5" />

                      <div class="flex flex-row flex-wrap">
                        <div class="w-full lg:w-1/3 pr-3" style="position: relative;">
                          <p class="mb-1 text-xs mb-2"><strong>Service Area Search</strong></p>
                          
                          <toggle-button v-model="me.settings.grid_sab_enabled" color="#48bb78" :sync="true" />

                          <p class="text-xs text-gray-700 mt-2">When enabled, a SAB option will be displayed on the grid to search for service areas.</p>
                        </div>
                      </div>

                      <hr class="mt-3" />

                      <div class="float-right mt-6">
                        <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingAgencySettings}" @click="saveAgencySettings()">Save</div>
                      </div>

                    </div>
                </v-tab-item>

                <v-tab-item class="pt-5">

                  <hr class="mt-4 mb-4">

                  <div class="flex flex-row flex-wrap">

                    <div class="w-full lg:w-1/2 pr-3">
                      <p class="mb-1 text-xs mb-2"><strong>Before the &lt;/HEAD&gt; Closing Tag (Header)</strong></p>
                      
                      <textarea name="" id="" rows="14" class="text-sm block appearance-none focus:outline-none focus:border-gray-500 w-full bg-gray-100 hover:border-grey px-4 py-4 rounded" v-model="me.settings.chat_widget_code" placeholder="/* Example */
<script>// Your code here...</script>" ></textarea>
                    </div>

                    <div class="w-full lg:w-1/2 pl-3">
                      <p class="mb-1 text-xs mb-2"><strong>Before the &lt;/BODY&gt; Closing Tag (Footer)</strong></p>
                      
                      <textarea name="" id="" rows="14" class="text-sm block appearance-none focus:outline-none focus:border-gray-500 w-full bg-gray-100 hover:border-grey px-4 py-4 rounded" v-model="me.settings.chat_widget_code_2" placeholder="/* Example */
<script>// Your code here...</script>" ></textarea>
                    </div>
                  </div>

                  <hr class="mt-3" />

                  <div class="float-right mt-6">
                    <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingAgencySettings}" @click="saveAgencySettings()">Save</div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pt-5">
                  <p class="-mb-2"><strong>Enabled</strong></p>

                  <toggle-button v-model="me.settings.members_area_enabled" color="#48bb78" :sync="true" />

                  <div class="flex flex-row flex-wrap -mx-3" v-show="me.settings.members_area_enabled">
                    <div class="w-full lg:w-1/3  px-3">
                      <p class="mb-1 text-xs mb-2"><strong>Nav Item Text</strong></p>
                      <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"
                      v-model="me.settings.members_area_nav_text"  />
                    </div>
                  </div>

                  <br clear="all" /><br clear="all" />

                  <div>

                    <div v-if="selected_folder_id">
                      <div class="border-1 border-blue bg-white text-blue text-xs text-bold px-4 py-2 rounded cursor-pointer float-left mr-3" @click="selected_folder_id = null;" style="margin-top: -7px;">&lt; Back</div>

                      <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-left" @click="addItem();" style="margin-top: -7px;">+ ADD ITEM</div>

                      <br clear="all" /><br clear="all" />

                      <draggable v-model="members_area_items" tag="div" @change="logItemsReorder">
                        <div v-for="(item, index) in members_area_items" :key="index" class="float-left">
                          <div v-if="item.folder_id == selected_folder_id" class="dashboard-box stats-box mr-4 mb-4" style='width: 300px !important;'>
                            <v-card
                              class="pa-2"
                              tile
                            >

                              <div style="text-align: center;">
                                <img :src="item.image" style="height: 200px; display: inline-block;" />
                              </div>
                              <div class="p-3">
                                <strong>{{ item.title }} </strong>
                                <p class="text-gray-600 text-sm" style="min-height: 70px;">
                                  {{ item.description }}
                                </p>
                                <a class="text-sm" :href="item.link" target="_blank">{{ item.link_text }} &nbsp; <i class="fa fa-chevron-right" style="font-size: 12px;"></i></a>
                              </div>
                            
                            <div class="dashboard-box-menu-actions" style="top: auto !important; bottom: 17px !important;">
                              <v-menu
                                bottom
                                left
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    dark
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-3"
                                    color="grey darken-1"
                                  >
                                    <i class="mdi mdi-dots-vertical"></i>
                                  </v-btn>
                                </template>

                                <v-list class="table-menu-actions">
                                  <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="editItem(item)"><span class="table-menu-action-icon"><i class="fa fa-pen"></i></span> Edit</a></v-list-item-title></v-list-item>
                                  <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="moveToFolder(item)"><span class="table-menu-action-icon"><i class="mdi mdi-cursor-move"></i></span> Move to Folder</a></v-list-item-title></v-list-item>
                                  <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="deleteItem(item.id)"><span class="table-menu-action-icon"><i class="fa fa-trash"></i></span> Delete</a></v-list-item-title></v-list-item>
                                </v-list>
                              </v-menu>
                            </div>
                            </v-card>
                          </div>
                        </div>
                      </draggable>
                    </div>
                    
                    <div v-if="!selected_folder_id">
                      <div class="border-1 border-blue bg-white text-blue text-xs text-bold px-4 py-2 rounded cursor-pointer float-right mr-2" @click="exportMembersArea();" style="margin-top: -7px;"><i class="mdi mdi-upload-outline"></i> EXPORT</div>

                      <div class="border-1 border-blue bg-white text-blue text-xs text-bold px-4 py-2 rounded cursor-pointer float-right mr-2" @click="handleFileImport();" style="margin-top: -7px;"><i class="mdi mdi-download-outline"></i> IMPORT</div>
                      
                      <div class="border-1 border-blue bg-white text-blue text-xs text-bold px-4 py-2 rounded cursor-pointer float-right mr-2" @click="downloadMembersAreaTemplate();" style="margin-top: -7px;"><i class="mdi mdi-download-outline"></i> DOWNLOAD TEMPLATE</div>

                      <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-left" @click="addFolder();" style="margin-top: -7px;">+ ADD FOLDER</div>

                      <input 
                          ref="uploader" 
                          class="d-none" 
                          type="file" 
                          @change="onFileChanged"
                          accept=".json"
                      >
                      
                      <br clear="all" /><br clear="all" />

                      <draggable v-model="members_area_folders" tag="div" @change="logFoldersReorder">
                        <div v-for="(folder, index) in members_area_folders" :key="index" class="dashboard-box stats-box float-left mr-4 mb-4" style='width: 100%; max-width: 250px !important; cursor: pointer;' @click="selected_folder_id = folder.id">
                          <v-card
                            class="pa-1"
                            tile
                          >
                            <div style="text-align: center;">
                              <i class="mdi mdi-folder-outline" style="font-size: 80px; color: blue;"></i>
                            </div>
                            <div class="p-1 pb-3" style="text-align: center;">
                              <strong>{{ folder.title }} </strong>
                            </div>
                          
                            <div class="dashboard-box-menu-actions" style="top: auto !important; bottom: 17px !important;">
                              <v-menu
                                bottom
                                left
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    dark
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-3"
                                    color="grey darken-1"
                                  >
                                    <i class="mdi mdi-dots-vertical"></i>
                                  </v-btn>
                                </template>

                                <v-list class="table-menu-actions">
                                  <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="editFolder(folder)"><span class="table-menu-action-icon"><i class="fa fa-pen"></i></span> Edit</a></v-list-item-title></v-list-item>
                                  <v-list-item class="hover:bg-gray-200" v-if="members_area_folders.length > 1"><v-list-item-title><a href="javascript: void(0);" @click="deleteFolder(folder.id)"><span class="table-menu-action-icon"><i class="fa fa-trash"></i></span> Delete</a></v-list-item-title></v-list-item>
                                </v-list>
                              </v-menu>
                            </div>
                          </v-card>
                        </div>
                      </draggable>
                    </div>
                    
                  </div>

                  <br clear="all" />

                  <hr class="mt-3" />

                  <div class="float-right mt-6">
                    <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingAgencySettings}" @click="saveAgencySettings()">Save</div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </template>
          </div>
        </v-card>
      </div>
    </panel-navigation>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import PanelNavigation from '@/components/PanelNavigation.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import DialogSendTestEmail from '@/components/dialogs/integrations/sendTestEmail.vue'
import DialogViewIntegrationsInstructions from '@/components/dialogs/integrations/viewInstructions.vue'
import DialogBuyNumber from '@/components/dialogs/integrations/buyNumber.vue'
import DialogSetupA2p from '@/components/dialogs/integrations/setupA2p.vue'
import DialogAddItem from '@/components/dialogs/members-area/addItem.vue'
import DialogAddFolder from '@/components/dialogs/members-area/addFolder.vue'
import DialogMoveToFolder from '@/components/dialogs/members-area/moveToFolder.vue'
import moment from 'moment'

import draggable from 'vuedraggable'

export default {
  metaInfo () {
    return {
      title: "Agency Settings | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    ToggleButton,
    DialogSendTestEmail,
    DialogViewIntegrationsInstructions,
    DialogBuyNumber,
    DialogAddItem,
    DialogAddFolder,
    DialogMoveToFolder,
    DialogSetupA2p,
    draggable
  },
  data() {
    return {
      show_subdomain_field: false,
      userSegmentation: false,
      panel: [0],
      saving: false,
      error: null,

      savingDomain: false,
      errorDomain: null,

      file: null,
      file_mobile: null,
      file_favicon: null,
      uploading: false,
      uploading_mobile: false,
      uploading_favicon: false,

      agency_settings_saved: false,
      savingAgencySettings: false,

      savingTwilio: false,
      errorTwilio: null,
      gettingTwilioNumbers: false,
      show_auth_token: false,

      savingSMTP: false,
      errorSMTP: null,
      show_smtp_password: false,
      
      savingStripeSettings: false,
      stripeSettingsError:null,
      show_stripe_key: false,

      savingGoogleMapsKey: false,
      errorGoogleMaps: null,

      show_maps_key: false,

      savingValueSerpKey: false,
      errorValueSerp: null,

      show_value_serp_key: false,

      selected_folder_id: null,

      members_area_folders: [],
      members_area_items: []
    }
  },
  methods: {

    addItem() {
        this.$refs.dialogAddItem.addItem(this.selected_folder_id);
        this.$refs.dialogAddItem.show();
    },

    editItem(item) {
        this.$refs.dialogAddItem.editItem(item);
        this.$refs.dialogAddItem.show();
    },

    moveToFolder(item) {
      this.$refs.dialogMoveToFolder.setItem(item);
      this.$refs.dialogMoveToFolder.show();
    },

    addFolder() {
        this.$refs.dialogAddFolder.addFolder();
        this.$refs.dialogAddFolder.show();
    },

    editFolder(folder) {
        this.$refs.dialogAddFolder.editFolder(folder);
        this.$refs.dialogAddFolder.show();
    },

    async getFolders() {
      let url = `${process.env.VUE_APP_API_ENDPOINT}/members-area`;

      let response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      this.members_area_folders = response.data.folders;
      this.members_area_items = response.data.items;
    },

    async logFoldersReorder() {
      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/members-area/update-folders-order`, {
        members_area_folders: this.members_area_folders
      }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      })
    },

    async logItemsReorder() {
      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/members-area/update-items-order`, {
        members_area_items: this.members_area_items
      }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      })
    },

    async saveDomain() {

      this.savingDomain = true
      this.errorDomain = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-white-label-domain`, {
        domain: ((this.$refs.domain) ? this.$refs.domain.value.trim() : this.me.domain)
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.savingDomain = false
        this.show_subdomain_field = false
        this.me.domain = ((this.$refs.domain) ? this.$refs.domain.value.trim() : this.me.domain)
        this.me.domain_status = response.data.domain_status
        this.me.ssl_status = response.data.ssl_status
        this.me.ssl_expiration = response.data.ssl_expiration
      }
      else {
        this.errorDomain = response.data.error
      }

      this.savingDomain = false
      let self = this

      setTimeout(function() {
        if (self.me.domain && (self.me.domain_status == 0 || self.me.ssl_status == 0)) {
          if (!self.show_subdomain_field)
            self.saveDomain();
        }
      }, 5000)

    },

    async saveAgencySettings() {

      this.savingAgencySettings = true
      this.agencySettingsError = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-stripe-settings`, {
        privacy_policy_link: this.me.integrations.stripe.settings.privacy_policy_link,
        terms_of_service_link: this.me.integrations.stripe.settings.terms_of_service_link,
        only_links: true
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      this.me.integrations.stripe.status = response.data.integration.status;
      this.me.integrations.stripe.settings = response.data.integration.settings;

      try {
        await this.$store.dispatch('me/save', {
          company_name: this.me.company_name.trim(),
          contacts_webhook: this.me.settings.contacts_webhook,
          custom_css: this.me.settings.custom_css || "{}",
          chat_widget_code: this.me.settings.chat_widget_code || "__EMPTY__",
          chat_widget_code_2: this.me.settings.chat_widget_code_2 || "__EMPTY__",
          allow_business_to_modify_review_sites: this.me.settings.allow_business_to_modify_review_sites ? '1' : '0',
          allow_business_to_modify_sms_from_number: this.me.settings.allow_business_to_modify_sms_from_number ? '1' : '0',
          signup_link: this.me.settings.signup_link ? '1' : '0',
          signup_page: this.me.settings.signup_page ? '1' : '0',
          allow_stripe_plans_signups: this.me.settings.allow_stripe_plans_signups ? '1' : '0',
          onboarding_enabled: this.me.settings.onboarding_enabled ? '1' : '0',

          grid_use_which_api: this.me.settings.grid_use_which_api,
          grid_sab_enabled: this.me.settings.grid_sab_enabled ? '1' : '0',

          dashboard_link: this.me.settings.dashboard_link ? '1' : '0',
          dashboard_link_url: this.me.settings.dashboard_link_url,
          dashboard_link_text: this.me.settings.dashboard_link_text,
          dashboard_link_icon: this.me.settings.dashboard_link_icon,

          members_area_enabled: this.me.settings.members_area_enabled ? '1' : '0',
          members_area_nav_text: this.me.settings.members_area_nav_text
        })
        this.$store.dispatch('me/fetch')
      }catch(error){
        console.log(error)
        this.agencySettingsError = error
      }

      this.savingAgencySettings = false
      this.agency_settings_saved = true

      let self = this

      if (this.me.company_name.trim()) {
        this.$agency.company_name = this.me.company_name.trim()
        this.$appName = this.me.company_name.trim()
      }

      // if (this.me.settings.custom_css) {
        let css_el = document.querySelector("#rg-custom-css-sheet");
        css_el.innerText = this.me.settings.custom_css.replace(/\n/g, " ")
      // }

      setTimeout(() => {
        self.agency_settings_saved = false
      }, 2000);
    },

    async saveStripeSettings() {

      this.savingStripeSettings = true
      this.stripeSettingsError = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-stripe-settings`, {
        publishable_key: this.me.integrations.stripe.settings.publishable_key,
        secret_key: this.me.integrations.stripe.settings.secret_key,
        privacy_policy_link: this.me.integrations.stripe.settings.privacy_policy_link,
        terms_of_service_link: this.me.integrations.stripe.settings.terms_of_service_link
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.savingStripeSettings = false
        this.me.integrations.stripe.status = response.data.integration.status;
        this.me.integrations.stripe.settings = response.data.integration.settings;
      }
      else {
        this.stripeSettingsError = response.data.error
      }

      this.savingStripeSettings = false
    },

    async saveTwilioSettings() {

      this.savingTwilio = true
      this.errorTwilio = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-twilio-settings`, {
        account_sid: this.me.integrations.twilio.settings.account_sid,
        auth_token: this.me.integrations.twilio.settings.auth_token,
        phone_number: this.me.integrations.twilio.settings.phone_number || null,
        link_shortening: this.me.integrations.twilio.settings.link_shortening
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.savingTwilio = false
        this.me.integrations.twilio.status = response.data.integration.status;

        if (response.data.integration.status == 0)
          this.errorTwilio = "Your Twilio credentials are invalid."
        else {
          // if (this.me.integrations.twilio.settings.numbers.length == 0)
          this.getTwilioNumbers()
        }
      }
      else {
        this.errorTwilio = response.data.error
      }

      this.savingTwilio = false

    },

    async saveGoogleMapsKey() {
      this.savingGoogleMapsKey = true
      this.errorGoogleMaps = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-google-maps-settings`, {
        api_key: this.me.integrations.google_maps.settings.api_key
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.savingGoogleMapsKey = false
        this.me.integrations.google_maps.status = response.data.integration.status;

        if (response.data.integration.status == 0)
          this.errorGoogleMaps = "Your Google Maps key is invalid."
      }
      else {
        this.errorGoogleMaps = response.data.error
      }

      this.savingGoogleMapsKey = false
    },

    async saveValueSerpKey() {
      this.savingValueSerpKey = true
      this.errorValueSerp = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-value-serp-settings`, {
        api_key: this.me.integrations.value_serp.settings.api_key
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.savingValueSerpKey = false
        this.me.integrations.value_serp.status = response.data.integration.status;

        if (response.data.integration.status == 0)
          this.errorValueSerp = "Your Value Serp key is invalid."
      }
      else {
        this.errorValueSerp = response.data.error
      }

      this.savingValueSerpKey = false
    },

    async getTwilioNumbers() {

      this.gettingTwilioNumbers = true

      let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/agency/get-twilio-numbers`, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.gettingTwilioNumbers = false
        this.me.integrations.twilio.settings.numbers = response.data.integration.settings.numbers;
        this.me.integrations.twilio.settings.phone_number = response.data.integration.settings.phone_number;
      }

      this.gettingTwilioNumbers = false

    },

    async onUpload() {
      // console.log(this.file);
      if (this.file !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-logo`, {
            logo: response.data.url
          }, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.me.logo = response.data.url
          this.$agency.logo = response.data.url

        }catch(e){
          console.log(e)
        }

        this.file = null
        this.uploading = false
      }
    },

    async onUploadMobile() {
      // console.log(this.file);
      if (this.file_mobile !== null) {
        this.uploading_mobile = true
        let formData = new FormData()
        formData.append("file", this.file_mobile)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-mobile-logo`, {
            logo: response.data.url
          }, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.me.settings.logo_mobile = response.data.url
          this.$agency.settings.logo_mobile = response.data.url

        }catch(e){
          console.log(e)
        }

        this.file_mobile = null
        this.uploading_mobile = false
      }
    },

    async onUploadFavicon() {
      // console.log(this.file);
      if (this.file_favicon !== null) {
        this.uploading_favicon = true
        let formData = new FormData()
        formData.append("file", this.file_favicon)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-favicon`, {
            favicon: response.data.url
          }, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.$agency.settings.favicon = response.data.url

          const favicon = document.getElementById("ReviewGrowerFavicon");
          favicon.href = this.$agency.settings.favicon;

        }catch(e){
          console.log(e)
        }

        this.file_favicon = null
        this.uploading_favicon = false
      }
    },

    async saveSMTPSettings() {

      this.savingSMTP = true
      this.errorSMTP = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-smtp-settings`, {
        from_name: this.me.integrations.smtp.settings.from_name,
        from_email: this.me.integrations.smtp.settings.from_email,
        host: this.me.integrations.smtp.settings.host,
        port: this.me.integrations.smtp.settings.port,
        username: this.me.integrations.smtp.settings.username,
        password: this.me.integrations.smtp.settings.password,
        ssl_tls: this.me.integrations.smtp.settings.ssl_tls
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.savingSMTP = false
        this.me.integrations.smtp = response.data.integration;

        if (response.data.integration.status == 0)
          this.errorSMTP = "Your SMTP settings are invalid."
      }
      else {
        this.errorSMTP = response.data.error
      }

      this.savingSMTP = false

    },

    getExternalContactFormLink() {
        let url = process.env.VUE_APP_API_ENDPOINT + "/contacts/add-contact-externally"
        return url;
    },

    getExternalContactFormLinkWhiteLabeled() {
        let url = window.location.origin + "/api/contacts/add-contact-externally"
        return url;
    },

    async removeLogo() {
      await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/remove-logo`, {}, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      let self = this
      setTimeout(function() {
        window.location = window.location.href
      }, 200);
    },

    async removeLogoMobile() {
      await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/remove-mobile-logo`, {}, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      let self = this
      setTimeout(function() {
        window.location = window.location.href
      }, 200);
    },

    async removeFavicon() {
       await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/remove-favicon`, {}, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      let self = this
      setTimeout(function() {
        window.location = window.location.href
      }, 200);
    },

    async exportMembersArea() {
      let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/members-area/export`, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      });

      let filename = 'members_area.json';

      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON. stringify(response.data, null, 2)));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    downloadMembersAreaTemplate() {
      let contents = `[
  {
    "folder": "Default",
    "items": [
      {
        "title": "asdga",
        "description": "sdgasdgasdg",
        "image": "https://ravu-uploads.s3.amazonaws.com/35/1677662777.png",
        "link": "asdgasd",
        "link_text": "gasdgasdg"
      },
      {
        "title": "t1",
        "description": "t2",
        "image": "https://ravu-uploads.s3.amazonaws.com/35/1677660198.png",
        "link": "https://www.plm.com",
        "link_text": "PLM"
      }
    ]
  },
  {
    "folder": "NF3",
    "items": []
  },
  {
    "folder": "New Folder2",
    "items": [
      {
        "title": "222asdgasdgasd",
        "description": "asdgasdg",
        "image": "https://ravu-uploads.s3.amazonaws.com/35/1677662007.png",
        "link": "gasdgasdg",
        "link_text": "asdgasdgadsg"
      }
    ]
  }
]`;

      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + contents);
      element.setAttribute('download', 'members_area_template.json');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    handleFileImport() {
        this.isSelecting = true;

        // After obtaining the focus when closing the FilePicker, return the button state to normal
        window.addEventListener('focus', () => {
            this.isSelecting = false
        }, { once: true });
        
        // Trigger click on the FileInput
        this.$refs.uploader.click();
    },

    
    async onFileChanged(e) {
        this.selectedFile = e.target.files[0];

        let formData = new FormData()
        formData.append("file", this.selectedFile)

        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/members-area/import`, formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        });

        this.selectedFile = null;

        this.getFolders()
    },

    formatDate(date) {
      return moment(date).format("MMM D, YYYY")
    },

    openGuide() {
      window.open('https://reviewgrower.com/documentation/getting-started-guide/');
    },

    async deleteItem(item_id) {
      await this.$store.dispatch('members_area/delete', item_id)
      this.getFolders()
    },

    async deleteFolder(item_id) {
      await this.$store.dispatch('members_area/delete_folder', item_id)
      this.getFolders()
    },
  },
  computed: {
    ...mapState({
      me: state => state.me.me,
      profile: state => state.me.profile,
      agency: state => state.agency
    }),

    agencyHasGridTrackerAddon() {
      return true;

      for (var i in this.me.subscription.addons) {
        let userAddon = this.me.subscription.addons[i];
        for (var j in this.me.subscription.product_details.addons) {
          let productAddon = this.me.subscription.product_details.addons[j];
          if (productAddon.key == 'grid-tracker') {
            for (var x in this.me.subscription.product_details.addons[j].prices) {
              let price = this.me.subscription.product_details.addons[j].prices[x];
              if (userAddon.stripe_price == price.id) {
                return true;
              }
            }
          }
        }
      }

      return false;
    }
  },
  mounted() {
    let self = this
    setTimeout(function() {
      if (self.me.domain && (self.me.domain_status == 0 || self.me.ssl_status == 0)) {
        self.saveDomain();
      }

      if (self.me.integrations.twilio.settings.account_sid && self.me.integrations.twilio.status == 0)
        self.errorTwilio = "Your Twilio credentials are invalid."

      if (self.me.integrations.smtp.settings.host && self.me.integrations.smtp.status == 0)
        self.errorSMTP = "Your SMTP settings are invalid."

    }, 1000)

    if (typeof(self.$agency) == "undefined" || self.$agency == null) {
      self.$agency = self.me
    }
  },
  created() {
    this.getFolders()

    this.getFolders();
  }
}
</script>

<style scoped>
.setting-header {
  background-color: #fbfbfb;
}
.setting-body {
  background-color: #fdfdfd;
}

input, textarea {
  border-style: solid;
}

.token_password_toggle {
  position: absolute;
  margin-top: -30px;
  right: 20px;
}
</style>

