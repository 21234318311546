<template>

  <div v-if="showModal" @click.self="showModal = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column" style="background-color: rgba(0,0,0,.2);">
    
    
    <div class="w-full sm:w-2/5 lg:w-2/5 bg-white rounded-lg" style="position: fixed; right: 0px; top: 64px; bottom: 0px">

      <div class="rg-modal-header">
          <span v-if="mode == 'add'">{{ capitalizeFirstLetter(item.type) }}</span>
          <span v-else>{{ capitalizeFirstLetter(item.type) }}</span>

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
        </div>

      <div class="p-6" style="height: calc(100% - 110px); overflow: auto;">

        <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          {{success}}
        </div>

        <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div v-html="error"></div>
        </div>

        <div class="mb-3">
            <p class="mb-1 text-xs mb-2"><strong>
                Action Name
            </strong></p>
            <input v-model="item.title" :placeholder="getPlaceholder()" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
        </div>

        <div v-if="item.type == 'email' || item.type == 'sms'">
            <div class="mb-3" v-if="item.type == 'email'">
                <p class="mb-1 text-xs mb-2"><strong>
                    Subject
                </strong></p>
                <input v-model="item.settings.subject" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div class="mb-3" v-if="item.type == 'email'">
                <p class="mb-1 text-xs mb-2"><strong>
                    Template
                </strong></p>
                
                <v-select
                    :items="email_templates"
                    item-text="title"
                    item-value="content"
                    v-model="selected_email_template"
                    outlined
                    dense
                    label="Template"
                  >
                </v-select>
            </div>

            <div class="mb-3" v-if="item.type == 'sms'">
                <p class="mb-1 text-xs mb-2"><strong>
                    Template
                </strong></p>
                
                <v-select
                    :items="sms_templates"
                    item-text="title"
                    item-value="content"
                    v-model="selected_sms_template"
                    outlined
                    dense
                    label="Template"
                  >
                </v-select>
            </div>

            <div v-if="item.type == 'email'" class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                    Body
                </strong></p>

                <tinymce :id="'rg-tnmce-eml-tmpl-applt-' + this_key" v-model="content" :menubar="false"
                  :toolbar1="'bold italic bullist  numlist link image forecolor backcolor fontselect fontsize formatselect mybutton'"
                  height="300"
                ></tinymce>
            </div>

            <div v-if="item.type == 'sms'" class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                    Message
                </strong></p>

                <tinymce :id="'rg-tnmce-eml-tmpl-applt-' + this_key" v-model="content_sms" :menubar="false"
                  :toolbar1="'bold italic bullist  numlist link image forecolor backcolor fontselect fontsize formatselect mybutton'"
                  height="300"
                ></tinymce>
            </div>

            <div class="" v-show="item.type == 'email'">
                <p class="mb-1 text-xs mb-2"><strong>
                    Email Type
                </strong></p>
                <v-radio-group
                  class="mt-2"
                  row
                  v-model="item.settings.email_type"
                  >
                    <v-radio
                    label="HTML"
                    :value="'html'"
                    on-icon="mdi-circle"
                    off-icon="mdi-circle-outline"
                    :ripple="false"
                    ></v-radio>
                    <v-radio
                    label="Plain Text"
                    :value="'plain_text'"
                    on-icon="mdi-circle"
                    off-icon="mdi-circle-outline"
                    :ripple="false"
                    ></v-radio>
                </v-radio-group>
            </div>

            <div class="mb-3" v-if="item.type == 'email'">
                <p class="mb-1 text-xs mb-2"><strong>
                    Test Email
                </strong></p>

                <div style="float: left; width: calc(100% - 120px);">
                    <input name="email" ref="test_email" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save" placeholder="email@example.com">
                </div>

                <div style="float: right; padding-top: 3px;">
                    <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" :class="{'spinner': sending}" @click="sendTest">
                        SEND TEST
                    </button>
                </div>

                <div style="clear: both; margin-bottom: 10px;"></div>

                <div v-if="success2" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                    {{success2}}
                  </div>

                  <div v-if="error2" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                    <div v-html="error2"></div>
                  </div>
            </div>

            <div class="mb-3" v-if="item.type == 'sms'">
                <p class="mb-1 text-xs mb-2"><strong>
                    Test SMS
                </strong></p>

                <div style="float: left; width: calc(100% - 120px);">
                    <input name="text" ref="test_number" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save" placeholder="Add phone number">
                </div>

                <div style="float: right; padding-top: 3px;">
                    <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" :class="{'spinner': sending}" @click="sendTest">
                        SEND TEST
                    </button>
                </div>

                <div style="clear: both; margin-bottom: 10px;"></div>

                <p class="text-xs text-gray">Please add country codes along with the numbers.</p>

                <div v-if="success2" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                    {{success2}}
                  </div>

                  <div v-if="error2" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                    <div v-html="error2"></div>
                  </div>
            </div>
        </div>

        <div v-if="item.type == 'delay'">
            <div class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                    Wait
                </strong></p>

                <div class="flex flex-row flex-wrap">
                    <div class="w-full sm:w-1/3 mb-4 pr-3">
                        <select v-model="item.settings.delay_type" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                            <option value="for">For</option>
                            <option value="until">Until</option>
                        </select>
                    </div>

                    <div v-if="item.settings.delay_type == 'for'" class="w-full sm:w-1/3 mb-4 px-3">
                        <input v-model="item.settings.delay_number" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="number" />
                    </div>

                    <div v-if="item.settings.delay_type == 'for'" class="w-full sm:w-1/3 mb-4 px-3 pr-0">
                        <select v-model="item.settings.delay_unit" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                            <option value="minute">Second(s)</option>
                            <option value="minute">Minute(s)</option>
                            <option value="hour">Hour(s)</option>
                            <option value="day">Day(s)</option>
                        </select>
                    </div>

                    <div v-if="item.settings.delay_type == 'until'" class="w-full sm:w-1/3 mb-4 px-3">
                        <select v-model="item.settings.delay_hour_of_day" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                            <option value="0">12 AM</option>
                            <option value="1">1 AM</option>
                            <option value="2">2 AM</option>
                            <option value="3">3 AM</option>
                            <option value="4">4 AM</option>
                            <option value="5">5 AM</option>
                            <option value="6">6 AM</option>
                            <option value="7">7 AM</option>
                            <option value="8">8 AM</option>
                            <option value="9">9 AM</option>
                            <option value="10">10 AM</option>
                            <option value="11">11 AM</option>
                            <option value="12">12 PM</option>
                            <option value="13">1 PM</option>
                            <option value="14">2 PM</option>
                            <option value="15">3 PM</option>
                            <option value="16">4 PM</option>
                            <option value="17">5 PM</option>
                            <option value="18">6 PM</option>
                            <option value="19">7 PM</option>
                            <option value="20">8 PM</option>
                            <option value="21">9 PM</option>
                            <option value="22">10 PM</option>
                            <option value="23">11 PM</option>
                        </select>
                    </div>

                    <div v-if="item.settings.delay_type == 'until'" class="w-full sm:w-1/3 mb-4 px-3 pr-0">
                        <v-autocomplete
                            class="-mt-1"
                            :items="timezones"
                            label="Timezone"
                            v-model="item.settings.delay_timezone"
                            outlined
                            dense
                        ></v-autocomplete>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="item.type == 'condition'">
          <div v-for="(branch, index_branch) in item.settings.branches" :key="'branch-' + index_branch" v-show="!branch.hidden" style="border: solid 1px #CCCCCC; padding: 15px; border-radius: 5px; margin-bottom: 15px; position: relative;">
              <div style="position: absolute; right: 40px;">
                <a href="javascript: void(0);" @click.stop="branch.hidden = true" class="remove-applet-btn ml-2"><i class="mdi mdi-delete" style="color: white; font-size: 12px;"></i></a>
              </div>

            <div class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                    Branch Name
                </strong></p>
                <input v-model="branch.name" :placeholder="'Branch Name'" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div v-for="(condition, index_condition) in branch.conditions" :key="'condition-' + index_branch + '-' + index_condition" v-show="!condition.hidden" style="border-radius: 5px; position: relative;">
              <div style="position: absolute; right: 10px;">
                <a href="javascript: void(0);" @click.stop="condition.hidden = true" class="remove-applet-btn ml-2"><i class="mdi mdi-delete" style="color: white; font-size: 12px;"></i></a>
              </div>
              <div class="flex flex-row flex-wrap mb-3">
                <div class="w-full sm:w-1/3 pr-3">
                    <v-select
                        :items="['Contact Reply']"
                        v-model="condition.input"
                        outlined
                        dense
                        label="Input"
                        hide-details
                      >
                    </v-select>
                </div>
                <div class="w-full sm:w-1/3 pr-3">
                    <v-select
                        :items="['Is', 'Is not', 'Contains', 'Does not contain', 'Is not empty', 'Is empty']"
                        v-model="condition.operator"
                        outlined
                        dense
                        label="Operator"
                        hide-details
                      >
                    </v-select>
                </div>
                <div class="w-full sm:w-1/3 pr-3">
                  <input v-model="condition.value" label="Value" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
                </div>
              </div>

              <div style="font-size: 16px;" v-show="index_condition < branch.conditions.length - 1">{{ branch.operator }}</div>
            </div>

            <div v-if="branch.conditions.length > 1" style="margin-bottom: -45px;">
              <v-radio-group
                class="mt-2"
                row
                v-model="branch.operator"
                >
                    <v-radio
                    label="OR"
                    :value="'OR'"
                    on-icon="mdi-circle"
                    off-icon="mdi-circle-outline"
                    :ripple="false"
                    ></v-radio>
                    <v-radio
                    label="AND"
                    :value="'AND'"
                    on-icon="mdi-circle"
                    off-icon="mdi-circle-outline"
                    :ripple="false"
                    ></v-radio>
                </v-radio-group>
            </div>

            <a href="javascript: void(0);" @click="addCondition(branch)" style="font-size: 12px; float: right; z-index: 1; position: relative;">+ Add Condition</a>

            <div style="clear: both;"></div>

          </div>

          <a href="javascript: void(0);" @click="addBranch()" style="font-size: 12px;">+ Add Branch</a>
        </div>

        <div v-if="item.type == 'add_tag' || item.type == 'remove_tag'">
            <p class="mb-1 text-xs mb-2"><strong>
                Select Tags
            </strong></p>

            <v-combobox
              v-model="item.settings.tags"
              :items="tags_items"
              multiple
              outlined
              dense
              hide-details
            ></v-combobox>
        </div>

        <div v-if="item.type == 'add_to_campaign' || item.type == 'remove_from_campaign'">
            <p class="mb-1 text-xs mb-2"><strong>
                Select Campaigns
            </strong></p>

            <v-combobox
              v-model="item.settings.campaigns"
              :items="campaigns"
              multiple
              outlined
              dense
              hide-details
              item-value="id"
              item-text="name"
            ></v-combobox>
        </div>
      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': saving}" @click="save">
            SAVE
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
            CANCEL
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="deleteApplet">
            DELETE
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
        me: state => state.me.me,
        businesses: state => state.businesses.businesses,
        campaigns: state => state.campaigns.campaigns
    }),
  },
  data: function() {
    return {
        this_key: 0,
      showModal: false,
      saving: false,
      success: null,
      error: null,
      success2: null,
      error2: null,
      index: 0,
      mode: 'add',
      plm: "asdplm123",
      item: {},
      tags_items: ['15 min call', 'application complete', 'completed survey', 'go live', 'lead', 'payment made', 'scheduled with calendly', 'value video optin'],
      timezones: [
        'Europe/Andorra',
        'Asia/Dubai',
        'Asia/Kabul',
        'Europe/Tirane',
        'Asia/Yerevan',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Antarctica/Mawson',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia',
        'Pacific/Pago_Pago',
        'Europe/Vienna',
        'Australia/Lord_Howe',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Currie',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Broken_Hill',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Baku',
        'America/Barbados',
        'Asia/Dhaka',
        'Europe/Brussels',
        'Europe/Sofia',
        'Atlantic/Bermuda',
        'Asia/Brunei',
        'America/La_Paz',
        'America/Noronha',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Santarem',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Eirunepe',
        'America/Rio_Branco',
        'America/Nassau',
        'Asia/Thimphu',
        'Europe/Minsk',
        'America/Belize',
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Indian/Cocos',
        'Europe/Zurich',
        'Africa/Abidjan',
        'Pacific/Rarotonga',
        'America/Santiago',
        'America/Punta_Arenas',
        'Pacific/Easter',
        'Asia/Shanghai',
        'Asia/Urumqi',
        'America/Bogota',
        'America/Costa_Rica',
        'America/Havana',
        'Atlantic/Cape_Verde',
        'America/Curacao',
        'Indian/Christmas',
        'Asia/Nicosia',
        'Asia/Famagusta',
        'Europe/Prague',
        'Europe/Berlin',
        'Europe/Copenhagen',
        'America/Santo_Domingo',
        'Africa/Algiers',
        'America/Guayaquil',
        'Pacific/Galapagos',
        'Europe/Tallinn',
        'Africa/Cairo',
        'Africa/El_Aaiun',
        'Europe/Madrid',
        'Africa/Ceuta',
        'Atlantic/Canary',
        'Europe/Helsinki',
        'Pacific/Fiji',
        'Atlantic/Stanley',
        'Pacific/Chuuk',
        'Pacific/Pohnpei',
        'Pacific/Kosrae',
        'Atlantic/Faroe',
        'Europe/Paris',
        'Europe/London',
        'Asia/Tbilisi',
        'America/Cayenne',
        'Africa/Accra',
        'Europe/Gibraltar',
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
        'Europe/Athens',
        'Atlantic/South_Georgia',
        'America/Guatemala',
        'Pacific/Guam',
        'Africa/Bissau',
        'America/Guyana',
        'Asia/Hong_Kong',
        'America/Tegucigalpa',
        'America/Port-au-Prince',
        'Europe/Budapest',
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
        'Europe/Dublin',
        'Asia/Jerusalem',
        'Asia/Kolkata',
        'Indian/Chagos',
        'Asia/Baghdad',
        'Asia/Tehran',
        'Atlantic/Reykjavik',
        'Europe/Rome',
        'America/Jamaica',
        'Asia/Amman',
        'Asia/Tokyo',
        'Africa/Nairobi',
        'Asia/Bishkek',
        'Pacific/Tarawa',
        'Pacific/Enderbury',
        'Pacific/Kiritimati',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Almaty',
        'Asia/Qyzylorda',
        'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Beirut',
        'Asia/Colombo',
        'Africa/Monrovia',
        'Europe/Vilnius',
        'Europe/Luxembourg',
        'Europe/Riga',
        'Africa/Tripoli',
        'Africa/Casablanca',
        'Europe/Monaco',
        'Europe/Chisinau',
        'Pacific/Majuro',
        'Pacific/Kwajalein',
        'Asia/Yangon',
        'Asia/Ulaanbaatar',
        'Asia/Hovd',
        'Asia/Choibalsan',
        'Asia/Macau',
        'America/Martinique',
        'Europe/Malta',
        'Indian/Mauritius',
        'Indian/Maldives',
        'America/Mexico_City',
        'America/Cancun',
        'America/Merida',
        'America/Monterrey',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Chihuahua',
        'America/Ojinaga',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Bahia_Banderas',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Africa/Maputo',
        'Africa/Windhoek',
        'Pacific/Noumea',
        'Pacific/Norfolk',
        'Africa/Lagos',
        'America/Managua',
        'Europe/Amsterdam',
        'Europe/Oslo',
        'Asia/Kathmandu',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Auckland',
        'Pacific/Chatham',
        'America/Panama',
        'America/Lima',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'Pacific/Gambier',
        'Pacific/Port_Moresby',
        'Pacific/Bougainville',
        'Asia/Manila',
        'Asia/Karachi',
        'Europe/Warsaw',
        'America/Miquelon',
        'Pacific/Pitcairn',
        'America/Puerto_Rico',
        'Asia/Gaza',
        'Asia/Hebron',
        'Europe/Lisbon',
        'Atlantic/Madeira',
        'Atlantic/Azores',
        'Pacific/Palau',
        'America/Asuncion',
        'Asia/Qatar',
        'Indian/Reunion',
        'Europe/Bucharest',
        'Europe/Belgrade',
        'Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Kirov',
        'Europe/Astrakhan',
        'Europe/Volgograd',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr',
        'Asia/Riyadh',
        'Pacific/Guadalcanal',
        'Indian/Mahe',
        'Africa/Khartoum',
        'Europe/Stockholm',
        'Asia/Singapore',
        'America/Paramaribo',
        'Africa/Juba',
        'Africa/Sao_Tome',
        'America/El_Salvador',
        'Asia/Damascus',
        'America/Grand_Turk',
        'Africa/Ndjamena',
        'Indian/Kerguelen',
        'Asia/Bangkok',
        'Asia/Dushanbe',
        'Pacific/Fakaofo',
        'Asia/Dili',
        'Asia/Ashgabat',
        'Africa/Tunis',
        'Pacific/Tongatapu',
        'Europe/Istanbul',
        'America/Port_of_Spain',
        'Pacific/Funafuti',
        'Asia/Taipei',
        'Europe/Kiev',
        'Europe/Uzhgorod',
        'Europe/Zaporozhye',
        'Pacific/Wake',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/Denver',
        'America/Boise',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Adak',
        'Pacific/Honolulu',
        'America/Montevideo',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'America/Caracas',
        'Asia/Ho_Chi_Minh',
        'Pacific/Efate',
        'Pacific/Wallis',
        'Pacific/Apia',
        'Africa/Johannesburg'
        ],
      sending: false,
      content: "",
      content_sms: "",

      sms_templates: [],
      email_templates: [],
      selected_sms_template: "",
      selected_email_template: "",

      add_applet_branch_index: null
    }
  },
  methods: {
    show() {
      this.showModal = true
      this.this_key++;

      let self = this;

      setTimeout(function() {
        self.content = "";
        self.content_sms = "";

        // console.log('self.item.body',self.item.settings.body)
      }, 300);

      setTimeout(function() {
        if (self.item.settings.body) {
            if (self.item.type == "email")
                self.content = self.item.settings.body.replace(/\n/g, "<br />");
            else
                self.content_sms = self.item.settings.body;
        }
      }, 600);
    },
    hide() {
      this.showModal = false
      this.saving = false
    },
    async save(shouldHide = true) {
        let self = this;

        if (shouldHide)
            self.saving = true
      
      self.error = null

      let disItem = JSON.parse(JSON.stringify(self.item));
      try {

        // console.log('disItem.settings.body', disItem.settings.body);
        // console.log('self.content', self.content);

        try {
          if (self.item.type == "email")
            disItem.settings.body = self.content.trim().replace(/\n/g, '');
          else
            disItem.settings.body = self.content_sms;
        }
        catch (e) {}

        // console.log('params1', disItem.settings);
        // console.log('params2', disItem.settings.body);


        console.log('this.add_applet_branch_index', this.add_applet_branch_index);

        if (this.add_applet_branch_index !== null) {
          self.$parent.$parent.addActionToBranch(this.add_applet_branch_index, disItem);

          //self.hide()
          if (shouldHide)
            self.hide()
            
          await self.$parent.$parent.save();

          self.$parent.$parent.getGetReviewsCampaign();

          self.error = null
        }
        else {
          await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/save-get-reviews-campaign-item` + '?fbid=' + self.businesses[self.businesses.length - 1].id, {
              index: self.index,
              mode: self.mode,
              item: disItem,
              campaign_id: self.$route.params.id
          }, {
              headers: {
                'Authorization': 'Bearer ' + self.$store.state.auth.jwt
              }
          })

          //self.hide()
          if (shouldHide)
            self.hide()
          
          self.$parent.$parent.getGetReviewsCampaign();

          self.error = null
        }

      } catch (e) {
          console.log('self.error', e)
        self.error = e.response.data.message
      }
      self.saving = false
    },

    async sendTest() {
      this.save(false);

      this.sending = true
      this.success2 = null
      this.error2 = null
      try {

        console.log('this.item', this.item)

        let formData = new FormData();
        formData.append('item_type', this.item.type);
        formData.append('applet_nr', this.index);

        if (this.item.type == 'email')
          formData.append("email", this.$refs.test_email.value);

        if (this.item.type == 'sms')
          formData.append("number", this.$refs.test_number.value);

        formData.append("campaign_id", this.$route.params.id);
          
        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/contacts/send-test`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
              'Content-Type': 'multipart/form-data'
            }
        });

        this.error2 = null
        try {
            this.$refs.test_email.value = ''
        }
        catch (e) {}

        try {
            this.$refs.test_number.value = ''
        }
        catch (e) {}

        if (this.item.type == 'email')
          this.success2 = "Email sent! Please check your email address and make sure the email was received. If not, please contact us."
        else
          this.success2 = "SMS sent! Please check your phone and make sure the SMS was received. If not, please contact us."

      } catch (e) {
        console.log('e', e)
        for (var i in e.response.data.errors) {
            if (e.response.data.errors[i] != 'null') {
              console.log(e.response.data.errors[i]);
              this.error2 = (this.error2 || '') + e.response.data.errors[i] + "<br />";
            }
        }
      }
      this.sending = false
    },

    getPlaceholder() {
        switch (this.item.type) {
            case "email":
                return "e.g. Initial Email or Follow Up Email...";
            break;

            case "sms":
                return "e.g. Initial SMS or Follow Up SMS...";
            break;

            case "delay":
                return "e.g. Wait 1 hour...";
            break;
        }
    },

    capitalizeFirstLetter(string) {
        try {
            string = string.replace(/\_/g, " ");
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        catch(e) {
            return "";
        }
    },

    async getTemplates() {
      let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/templates?template_type=sms`, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      })

      this.sms_templates = response.data;

      let response2 = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/templates?template_type=email`, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      })

      this.email_templates = response2.data;
    },

    deleteApplet() {
        this.$parent.$parent.removeApplet(this.index);
    },

    addCondition(branch) {
      branch.conditions.push({
        "input": "Contact Reply",
        "operator": "Is",
        "value": "",
        "hidden": false
      });
    },

    addBranch() {
      this.item.settings.branches.push({
        "name": "Branch",
        "conditions": [
          {
            "input": "Contact Reply",
            "operator": "Is",
            "value": "",
            "hidden": false
          }
        ],
        "operator": "OR",
        "hidden": false,
        "actions": [

        ]
      });
    }
  },
  created() {
    this.$store.dispatch('businesses/fetch')
    this.$store.dispatch('campaigns/fetch')

    this.getTemplates();
  },
  watch: {
    selected_sms_template: {
      handler () {
        this.content_sms = this.selected_sms_template;
      }
    },
    selected_email_template: {
      handler () {
        this.content = this.selected_email_template;
      }
    }
  }
}
</script>
