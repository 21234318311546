<template>
  <div>

    <div class="w-full mb-4 reviews-wrapper" v-if="initial_loading" :class="((dashboard == 'true') ? 'dashboard-box-content-border' : '')">
      <v-skeleton-loader v-if="initial_loading"
        type="article, article, article"
      ></v-skeleton-loader>
    </div>

    <div class="w-full mb-4 reviews-wrapper" v-if="!initial_loading" :class="((dashboard == 'true') ? 'dashboard-box-content-border' : '')">

      <v-tabs
        v-model="showByResponded"
        v-show="dashboard != 'true' && social_posting != 'true'"
        >
        <v-tab :value="'all'" @click="getReviews()">All</v-tab>
        <v-tab :value="'responded'" @click="getReviews()">Responded</v-tab>
        <v-tab :value="'not-responded'" @click="getReviews()">Not Responded ({{ notRespondedCount }})</v-tab>
      </v-tabs>

      <div class="-mt-10 mb-14">
        <!-- <div class="float-right">

          <v-checkbox
            class="mb-2"
            v-model="all_selected"
            value="1"
            label="Select All"
            hide-details
             :ripple="false"
            v-if="showByResponded != 'responded' && dashboard != 'true' && social_posting != 'true' && reviews.length > 0"
          ></v-checkbox>
        </div> -->

        <div v-if="all_selected == 1 || selected_ids.length > 0 || all_selected_all_pages" class="float-right mb-2 mr-3">
          <v-btn
            color="blue darken-1"
            class="mt-2 white--text"
            small
            dense
            @click="respondToReviewsSelected()"
          >
            <v-icon
              right
              dark
              class="mr-2"
            >
              mdi-message
            </v-icon>
            Responded
          </v-btn>
        </div>

        <div v-if="all_selected == 1 || selected_ids.length > 0 || all_selected_all_pages" class="float-right mb-2 mr-3">
          <v-btn
            color="red darken-1"
            class="mt-2 white--text mr-3"
            small
            dense
            @click="deleteReviewsSelected()"
          >
            <v-icon
              right
              dark
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            Delete Selected
          </v-btn>

          <v-btn
            color="blue darken-1"
            class="mt-2 white--text"
            small
            dense
            @click="postReviewsSelected()"
          >
            <v-icon
              right
              dark
              class="mr-2"
            >
              mdi-google-circles-extended
            </v-icon>
            Post Selected
          </v-btn>
        </div>
      </div>

        <div style="clear: both;"></div>

        <v-alert
          color="blue-grey"
          dark
          text
          v-if="all_selected == 1"
        >
          <div v-if="!all_selected_all_pages">
            All {{ options.itemsPerPage }} reviews on this page are selected.
            <a href="javascript: void(0);" @click="all_selected_all_pages = true">Select all {{ totalFilteredReviews }} reviews</a>
          </div>

          <div v-else>
            All {{ totalFilteredReviews }} reviews are selected.
            <a href="javascript: void(0);" @click="resetSelection()">Clear selection</a>
          </div>
        </v-alert>

        <!-- <v-btn v-show="!responding[item.id]"
          color="blue-grey"
          class="mt-2 white--text"
          small
          @click="respondToReview(item)"
        >
          Responded
          <v-icon
            right
            dark
          >
            mdi-message
          </v-icon>
        </v-btn> -->

        <v-row>
          <!-- <v-col cols="12" md="3" sm="3" xs="6">
              <v-text-field
              v-model="searchLocal"
              clearable
              outlined
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
              ></v-text-field>
          </v-col> -->
            <v-col cols="12" md="3" sm="3" xs="6">
            <v-select
                v-model="sortByLocal"
                outlined
                dense
                hide-details
                :items="sort_by_keys"
            ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3" sm="3" xs="6">
            <v-combobox
                v-model="filterWebsiteLocal"
                outlined
                dense
                hide-details
                :items="filter_website_keys"
                item-text="name"
                item-value="domain"
            >
            <template v-slot:item="{ item }" v-slot:scope="data">
              <span v-if="item.toString().indexOf('object') === -1">
                {{ (item.toString().replace(".com", "").replace("www.", "")).charAt(0).toUpperCase() + (item.toString().replace(".com", "").replace("www.", "")).slice(1) }}
              </span>
              <span v-else>
                All Sources
              </span>
            </template>
            </v-combobox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3" sm="3" xs="6">
            <v-select
                v-model="filterRatingLocal"
                outlined
                dense
                hide-details
                :items="filter_rating_keys"
            ></v-select>
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="12" md="3" sm="3" xs="6">
              <v-text-field
                  :value="((filtered_date) ? formatDate(date_filter[0], 'MMM DD, YYYY') + '   -   ' + ((date_filter[1]) ? formatDate(date_filter[1], 'MMM DD, YYYY') : '') : 'Select Date')"
                  append-icon="mdi-calendar-blank-outline"
                  readonly
                  outlined
                  dense
                  style="background: #FFFFFF; border: 0px !important;"
                  hide-details
                  placholder="Choose Date"
                  @click="show_picker = !show_picker"
              ></v-text-field>
              
              <div v-if="show_picker" style="position: absolute; width: 490px; z-index: 999; margin-left: -260px; background: #FFFFFF; padding: 10px; margin-top: 5px; border-radius: 10px; box-shadow: 0px 0px 30px 0px #E2E2E2;">
                <div style="float: left; border-right: solid 1px #d5d5d5;">
                  <div class="mb-2"><strong class="text-sm">Filter by Date</strong></div>

                  <v-date-picker
                  v-model="date_filter"
                  range
                  no-title
                  scrollable
                  @input="menu = false"
                  style="border: 0px;"
                  >
                  <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="#44468F"
                          @click="show_picker = false"
                      >
                          Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="#44468F"
                          @click="filterDate();"
                      >
                          UPDATE
                      </v-btn>
                  </v-date-picker>
                </div>

                <div style="float: left; margin-left: 20px; padding-left: 20px; padding-top: 20px;">
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('This Week')" :style="((selected_date_text == 'This Week') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">This Week</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last Week')" :style="((selected_date_text == 'Last Week') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last Week</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('This Month')" :style="((selected_date_text == 'This Month') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">This Month</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last Month')" :style="((selected_date_text == 'Last Month') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last Month</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last 30 days')" :style="((selected_date_text == 'Last 30 days') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last 30 days</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last 60 days')" :style="((selected_date_text == 'Last 60 days') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last 60 days</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last 90 days')" :style="((selected_date_text == 'Last 90 days') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last 90 days</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('This Year')" :style="((selected_date_text == 'This Year') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">This Year</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('Last Year')" :style="((selected_date_text == 'Last Year') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">Last Year</a></div>
                  <div class="mb-2"><a href="javascript: void(0);" @click="applyDate('All Time')" :style="((selected_date_text == 'All Time') ? 'font-size: 14px; font-weight: bold;' : 'color: #696969 !important; font-size: 14px;')">All Time</a></div>
                </div>

                <div style="clear: both;"></div>
              </div>
            </v-col>
        </v-row>

        <div v-if="!loading_reviews && reviews.length == 0">
          <br /><br /><br /><br />

          <div class="dashboard-box-iconsquare">
            <div class="dashboard-box-icon-background"></div>
            <div class="dashboard-box-icon">
              <i class="mdi mdi-star"></i>
            </div>
          </div>

          <div style="text-align: center;">
            <div class="pt-2 pb-2">
              <strong>No Reviews</strong>
            </div>

            <div class="text-sm mb-4 text-gray-600">
              There are no reviews to be displayed.
            </div>
          </div>
          
          <br /><br /><br /><br />
        </div>

        <div class="dashboard-box" v-else>

          <v-data-table
              :headers="headers"
              :items="reviews"
              :options.sync="options"
              :server-items-length="totalFilteredReviews"
              :items-per-page="((social_posting == 'true') ? 9999999 : 5)"
              class="rg-datatable elevation-1 mt-5"
              :loading="loading_reviews"
              :footer-props="{
              itemsPerPageOptions: ((social_posting == 'true') ? [9999999] : [1, 5, 10, 50, 100]),
              showFirstLastPage: true
              }"
              :hide-default-footer="dashboard == 'true' || social_posting == 'true'"
              mobile-breakpoint="10"
              show-select
              v-model="selected_ids"
          >

          <template v-slot:header>
            </template>
              <template v-slot:item.url="{ item }">
              <span v-if="item">{{ getHostname(item) }}</span>
              </template>

              <template v-slot:item.rating_value="{ item }">
              <v-rating
                  :value="item.rating_value"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="20"
              ></v-rating>
              </template>

              <template v-slot:item.url="{ item }" style="color: #757B89;">
                <img class="source-logo -mr-2" :src="require('../assets/images/review-services/www.' + getHostname(item) + '.png')">
              </template>

              <template v-slot:item.name="{ item }" style="color: #757B89;">
                <div class="reviewer">
                    <div style="float: left;">
                      <span class="ml-2">
                          <img class="profile-pic" referrerPolicy="no-referrer" :src="item.profile_picture" v-if="item.profile_picture" />
                          <v-avatar color="blue" size="50" v-if="!item.profile_picture" class="-ml-3"><v-icon small color="white">mdi-account</v-icon></v-avatar>
                      </span>
                    </div>
                    <div style="float: left;">
                      <h3 style="padding-top: 5px;">
                          {{ item.name }}
                      </h3>

                      <br />

                      <strong class="pl-2">{{ item.rating_value }}</strong>

                      <v-rating
                      :value="item.rating_value"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="15"
                      ></v-rating>

                      <span class="review-date">{{ formatDate(item.date) }}</span>
                    </div>

                    <br clear="all" />
                </div>
              </template>

              <template v-slot:item.review_text="{ item }" style="color: #757B89;">

                <div class="review_text" v-if="dashboard != 'true'">
                  {{ item.review_text }}
                </div>

                <div v-else class="review_text" :id="'item_text_' + item.id">
                  <div v-if="item.review_text !== null">
                    <div v-show="!item.show_all" v-html='((item.review_text.toString().length > 150) ? item.review_text.substring(0, 150) + "..." : item.review_text)' />
                    <a v-if="!item.show_all && item.review_text.toString().length > 150" href="javascript: void(0);" @click="showFullText(item);">Read more</a>
                  </div>
                </div>

                <div v-show="me.company_type == 'agency' || me.subscription.product_details.limits.generate_review_responses == 1" v-if="responding[item.id]" class="pt-5">
                  <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="openReview(item)" style="margin-top: -5px;"><i class="mdi mdi-link mr-2"> </i> OPEN REVIEW</div>

                  <strong>Your AI response Generated</strong>

                  <br clear="all "/>

                  <div v-if="responding[item.id] == 1" class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-left mt-3" @click="generateReviewResponses(item)" style="margin-top: -5px;"><i class="mdi mdi-reload mr-2"> </i> GENERATE RESPONSES</div>

                  <div v-if="responding[item.id] == 2" class="mt-3"><i class="fa fa-spin fa-spinner"></i></div>

                  <div style="clear: both;"></div>
                  <br clear="all "/>

                  <div v-if="Array.isArray(responding[item.id])">
                    <div v-for="(item, index) in responding[item.id]" :key="index" style="clear: both; position: relative; background: #F1F1F1; padding: 20px; border-left: solid 6px #2477F6; border-radius: 5px; margin-bottom: 10px; color: #757B89; font-size: 12px;">
                      {{ item }}

                      <a
                          href="javascript: void(0);"
                          v-clipboard:copy="item"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          class="text-xs float-right"
                          style="position: absolute; bottom: 7px; right: 7px;"
                      >
                          <i class="mdi mdi-content-copy"></i>
                          Copy
                      </a>
                    </div>
                  </div>
                </div>

                <div v-if="me.company_type == 'admin' || me.company_type == 'agency'" class="mt-3" style="font-size: 12px; color: #757B89;">
                  <i class="mdi mdi-briefcase-outline" style="font-size: 16px;"></i>
                  &nbsp;&nbsp;
                  {{ item.user.company_name }}
                  <span class="not-mobile">&nbsp;&nbsp;&nbsp;
                  |
                  &nbsp;&nbsp;
                  </span>
                  <br class="mobile-only" />
                  <span>
                    <span class="not-mobile">&nbsp;&nbsp;</span>
                    <i class="mdi mdi-map-marker-outline" style="font-size: 16px;"></i>
                    <span class="not-mobile">&nbsp;</span>&nbsp;
                    {{ item.user.address }} {{ item.user.street_number }}<span v-if="(item.user.address || item.user.street_number)">, </span>{{ item.user.city }} <span v-if="!item.user.city">{{ item.user.country }}</span> &nbsp;<span v-if="item.user.company_type == 'business'" style="font-weight: bold;">(Main)</span>
                  </span>
                </div>
              </template>

              <template v-slot:item.id="{ item }">
                <div style="float: right;" v-if="social_posting == 'true'">
                  <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer ml-10" @click="viewReviewCard(item.id)" style="margin-top: -5px;"><i class="mdi mdi-google-circles-extended mr-2"> </i> PREVIEW SOCIAL POST CARD</div>
                </div>

                <div style="float: right;" v-if="social_posting != 'true'">
                  <div v-if="item.url" class="float-right">
                    <div v-if="item.responded" class="-mr-4">
                      <v-chip
                        class="ml-2 mr-2"
                        color="blue lighten-4"
                        text-color="blue darken-3"
                      >
                        <i class="mdi mdi-check-circle mr-2"></i>
                        Responded
                      </v-chip>
                    </div>

                    <div v-else>
                      <div v-show="!responding[item.id]" class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer ml-3" @click="respondToReview(item)" style="margin-top: -5px;"><i class="mdi mdi-forum-outline mr-2"> </i> RESPOND</div>

                      <div v-if="responding[item.id]" class="ml-5">
                        <div class="text-gray-600 text-xs mb-3">Did you respond?</div>

                        <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-3" @click="notRespondedToReview(item)" style="margin-top: -5px;"><i class="mdi mdi-close mr-2"> </i> NO</div>

                        <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="respondedToReview(item)" style="margin-top: -5px;"><i class="mdi mdi-check mr-2"> </i> YES</div>
                      </div>
                    </div>
                  </div>

                  <div v-if="me && (me.company_type == 'business' || me.company_type == 'location') && me.subscription.product_details.limits.permissions['/social-posting'] && sectionEnabled('/social-posting')" class="float-right">
                    <div v-show="!responding[item.id]">
                      <div v-if="item.social_posted" class="-mr-1">
                        <v-chip
                          class="ml-2 mr-2"
                          color="blue lighten-4"
                          text-color="blue darken-3"
                        >
                          <i class="mdi mdi-check-circle mr-2"></i>
                          Posted
                        </v-chip>
                      </div>

                      <div v-else class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer ml-10" @click="selectReviewItem(item.id)" style="margin-top: -5px;"><i class="mdi mdi-google-circles-extended mr-2"> </i> POST</div>
                    </div>
                  </div>

                </div>
              </template>
          </v-data-table>
        </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import PanelNavigation from '@/components/PanelNavigation.vue'
  import moment from 'moment'
  import reviewSitesNames from '@/config/review_sites/names';
  import reviewSites from '@/config/review_sites/list';

  export default {
    components: {
        PanelNavigation
    },
    props: ['dashboard', 'social_posting', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'search', 'filter_rating', 'filter_website', 'review_sites', 'date', 'selected_client'],
    data () {
      return {
        options: {
          sortBy: "Newest First",
          sortDesc: true
        },
        headers: [
        { text: 'User & Rating', value: 'name', width: 280, html: true, sortable: false },
        { text: 'Feedback', value: 'review_text', html: true, sortable: false },
        { text: 'Website', value: 'url', html: true, sortable: false },
        { text: 'Actions', value: 'id', width: 300, html: true, sortable: false }
        ],
        read_more: [],
        percentage_complete: 0,
        sort_by_keys: [
          'Newest First',
          'Oldest First'
        ],
        filter_rating_keys: [
          "Any Rating",
          "1",
          "2",
          "3",
          "4",
          "5",
          "Positive",
          "Negative"
        ],
        filter_website_keys: [{ name: 'All Sources', domain: "All Sources" }].concat(reviewSites),
        showByResponded: "all",
        searchLocal: "",
        sortByLocal: "Newest First",
        filterRatingLocal: "Any Rating",
        filterWebsiteLocal: "All Sources",
        review_manage_items: [
          { title: 'Social Post' }
        ],
        reviewSitesNames: reviewSitesNames,
        responding: {},

        all_selected: 0,
        all_selected_all_pages: false,
        selected_ids: [],

        actions_values: ['Mark As Responded'],

        date_filter: [
                moment().subtract(30, 'days').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
        ],

        filtered_date: false,

        show_picker: false,

        selected_date_text: "",
      }
    },
    computed: {
      ...mapState({
        reviews: state => state.reviews.reviews,
        loading_reviews: state => state.reviews.loading_reviews,
        initial_loading: state => state.reviews.initial_loading,
        totalFilteredReviews: state => state.reviews.totalFilteredReviews,
        totalReviews: state => state.reviews.totalReviews,
        notRespondedCount: state => state.reviews.notRespondedCount,
        me: state => state.me.me,
        profile: state => state.me.profile
      })
    },
    methods: {
      filterDate() {
            console.log(this.date_filter.length);
            if (!this.date_filter[1])
                return;

            this.show_picker = false;
            this.selected_date_text = '';
            this.getReviews();

            this.filtered_date = true;
        },

      applyDate(selected_date) {
        this.filtered_date = true;
        // console.log('selected_date', selected_date);
        this.selected_date_text = selected_date;
        switch (selected_date) {
          case 'This Week':
            this.date_filter[0] = moment().startOf('week').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('week').format('YYYY-MM-DD');
          break;
          case 'Last Week':
            this.date_filter[0] = moment().startOf('week').subtract(7, 'days').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('week').subtract(7, 'days').format('YYYY-MM-DD');
          break;
          case 'This Month':
            this.date_filter[0] = moment().startOf('month').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('month').format('YYYY-MM-DD');
          break;
          case 'Last Month':
            this.date_filter[0] = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD');
          break;
          case 'Last 30 days':
            this.date_filter[0] = moment().subtract(30, 'days').format('YYYY-MM-DD');
            this.date_filter[1] = moment().format('YYYY-MM-DD');
          break;
          case 'Last 60 days':
            this.date_filter[0] = moment().subtract(60, 'days').format('YYYY-MM-DD');
            this.date_filter[1] = moment().format('YYYY-MM-DD');
          break;
          case 'Last 90 days':
            this.date_filter[0] = moment().subtract(90, 'days').format('YYYY-MM-DD');
            this.date_filter[1] = moment().format('YYYY-MM-DD');
          break;
          case 'This Year':
            this.date_filter[0] = moment().startOf('year').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('year').format('YYYY-MM-DD');
          break;
          case 'Last Year':
            this.date_filter[0] = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
            this.date_filter[1] = moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
          break;

          case 'All Time':
            this.date_filter[0] = moment().startOf('year').subtract(10, 'years').format('YYYY-MM-DD');
            this.date_filter[1] = moment().format('YYYY-MM-DD');
          break;
        }
        // console.log(this.date_filter);
        this.show_picker = false;
        this.getReviews();
      },

    // formatDate: function(date, format = "MM/DD/YYYY") {
    //   return moment(String(date)).format(format)
    // },
    
      sectionEnabled(section) {
        try {
          let sections = JSON.parse(this.profile.settings.sections);

          if (typeof(sections[section]) == "undefined") {
            return true;
          }
          
          if (!sections[section]) {
            return false;
          }
        }
        catch(e) {
        }


        return true;
      },
      
      getReviews: function(ignore_loading) {

        this.resetSelection();

        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        let sbr = 0;

        if (this.showByResponded == 0)
          sbr = 'all';
        else if (this.showByResponded == 1)
          sbr = 'responded';
        else if (this.showByResponded == 2)
          sbr = 'not-responded';

        let params = {
          dashboard: this.dashboard,
          social_posting: this.social_posting,
          social_posting_rating: ((this.$parent && this.$parent.$parent.me) ? this.$parent.$parent.me.social_posting_rating : 'undefined'),
          social_posting_review_sites: ((this.$parent && this.$parent.$parent.me) ? this.$parent.$parent.me.social_posting_review_sites : 'undefined'),
          sortBy: sortBy,
          sortDesc: sortDesc,
          page: page,
          itemsPerPage: itemsPerPage,
          search: this.searchLocal || "",
          filter_rating: this.filterRatingLocal || "",
          filter_website: this.filterWebsiteLocal || "",
          showByResponded: sbr,
          selected_client: this.selected_client || ""
        };

        if (typeof(this.review_sites) != "undefined")
          params.review_sites = this.review_sites;
        
        if (typeof(this.date) != "undefined")
          params.date = this.date;

        if (this.filtered_date && this.date_filter[0] && this.date_filter[1]) {
          params.start_date = this.date_filter[0];
          params.end_date = this.date_filter[1];
        }

        this.$store.dispatch('reviews/fetch', params);

        return;
      },
      getHostname: function(item) {
        return new URL(item.url || item.listing.url).hostname.replace("www.", "").replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com");
      },
      formatDate: function(date) {
        return moment(String(date)).format(this.me.settings.date_format)
      },
      displayExpansionTitle(item) {
        if (item.review_title)
          return item.review_title;

        if (item.review_text)
          return item.review_text.substring(0, 50) + '...';

        return 'No text';
      },
      async viewReviewCard(review_id) {
        let theme_id = this.me.social_posting_themes[0].id;
        let previewOption = "Facebook";
        let previewSize = "Text: Medium";

        try {
          let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/generate-image?review_id=' + review_id + '&selected_theme=' + theme_id + '&previewOption=' + previewOption + '&previewSize=' + previewSize + '&preview=1', {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          window.open(response.data.url);
        }catch(e){
          console.log(e)
        }
      },
      async selectReviewItem(review_id) {
        // this.$router.push('social-share/' + review_id)

        await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/publish-social-post', {
          reviews_ids: [review_id]
        } , {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          },
        })

        this.$swal({
          title: "Queued",
          text: "Selected review was queued for posting!",
          showCancelButton: false,
        });

        this.getReviews();
      },
      respondToReview(item) {
        this.responding[item.id] = 1;

        if (!this.me.subscription.product_details.limits.generate_review_responses && this.me.company_type != 'agency')
          window.open(item.url);

        this.getReviews()
      },
      openReview(item) {
        window.open(item.url);
      },
      async generateReviewResponses(item) {
        let self = this
        this.responding[item.id] = 2;
        this.getReviews()

        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/reviews/get-responses`, {
          review_text: item.review_text,
          business_uuid: this.me.uuid
        }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

        // setTimeout(function() {
          // console.log('response', response);
          self.responding[item.id] = response.data.responses;
          self.getReviews()
        // }, 2000);
      },
      async respondedToReview(item) {
        this.responding[item.id] = 0;

        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/reviews/responded`, {
          id: item.id
        }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

        this.getReviews();
      },
      notRespondedToReview(item) {
        this.responding[item.id] = false;
        this.getReviews()
      },
      resetSelection() {
        this.all_selected = 0;
        this.selected_ids = [];
        this.all_selected_all_pages = false;
      },
      async respondToReviewsSelected() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        let params = {
          selected_ids: this.selected_ids,
          all_selected_all_pages: this.all_selected_all_pages,
          dashboard: this.dashboard,
          social_posting: this.social_posting,
          social_posting_rating: ((this.$parent && this.$parent.$parent.me) ? this.$parent.$parent.me.social_posting_rating : 'undefined'),
          social_posting_review_sites: ((this.$parent && this.$parent.$parent.me) ? this.$parent.$parent.me.social_posting_review_sites : 'undefined'),
          sortBy: sortBy,
          sortDesc: sortDesc,
          page: page,
          itemsPerPage: itemsPerPage,
          search: this.searchLocal || "",
          filter_rating: this.filterRatingLocal || "",
          filter_website: this.filterWebsiteLocal || "",
          showByResponded: this.showByResponded
        };
        
        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/reviews/responded-bulk`, params, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        });

        this.getReviews()
      },

      async postReviewsSelected() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/publish-social-post', {
          reviews_ids: this.selected_ids,
          all_selected_all_pages: this.all_selected_all_pages,
          dashboard: this.dashboard,
          social_posting: this.social_posting,
          social_posting_rating: ((this.$parent && this.$parent.$parent.me) ? this.$parent.$parent.me.social_posting_rating : 'undefined'),
          social_posting_review_sites: ((this.$parent && this.$parent.$parent.me) ? this.$parent.$parent.me.social_posting_review_sites : 'undefined'),
          sortBy: sortBy,
          sortDesc: sortDesc,
          page: page,
          itemsPerPage: itemsPerPage,
          search: this.searchLocal || "",
          filter_rating: this.filterRatingLocal || "",
          filter_website: this.filterWebsiteLocal || "",
          showByResponded: this.showByResponded
        } , {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          },
        })

        this.$swal({
          title: "Queued",
          text: "Selected reviews were queued for posting!",
          showCancelButton: false,
        });

        this.getReviews();
      },

      async deleteReviewsSelected() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        await axios.post(process.env.VUE_APP_API_ENDPOINT + '/me/delete-selected-reviews', {
          reviews_ids: this.selected_ids,
          all_selected_all_pages: this.all_selected_all_pages,
          dashboard: this.dashboard,
          social_posting: this.social_posting,
          social_posting_rating: ((this.$parent && this.$parent.$parent.me) ? this.$parent.$parent.me.social_posting_rating : 'undefined'),
          social_posting_review_sites: ((this.$parent && this.$parent.$parent.me) ? this.$parent.$parent.me.social_posting_review_sites : 'undefined'),
          sortBy: sortBy,
          sortDesc: sortDesc,
          page: page,
          itemsPerPage: itemsPerPage,
          search: this.searchLocal || "",
          filter_rating: this.filterRatingLocal || "",
          filter_website: this.filterWebsiteLocal || "",
          showByResponded: this.showByResponded
        } , {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          },
        })

        this.$swal({
          title: "Removed",
          text: "Selected reviews were permanently deleted from our system!",
          showCancelButton: false,
        });

        this.getReviews();
      },

      showFullText: function(item) {
          document.getElementById("item_text_" + item.id).innerHTML = item.review_text;
      },

      onCopy: function (e) {
        this.$swal({
          title: "Copied",
          text: "Response copied! Open the review link and paste your review.",
          showCancelButton: false,
          });
      },
      onError: function (e) {
        alert('Failed to copy the text to the clipboard')
        console.log(e);
      },
    },
    watch: {
      options: {
        handler () {
          this.getReviews()
        }
      },
      showByResponded: {
        handler () {
          this.getReviews();
        }
      },
      sortByLocal: {
        handler () {
          if (this.sortByLocal == 'Newest First') {
              this.options.sortBy = "date";
              this.options.sortDesc = true;
            }
            else if (this.sortByLocal == 'Oldest First') {
              this.options.sortBy = "date";
              this.options.sortDesc = false;
            }
            this.getReviews()
        }
      },
      searchLocal: {
        handler () {
          this.options.page = 1
          this.getReviews()
        }
      },
      filterRatingLocal: {
        handler () {
          this.options.page = 1
          this.getReviews()
        }
      },
      filterWebsiteLocal: {
        handler () {
          this.options.page = 1
          this.getReviews()
        }
      },
      selected_ids: {
        handler() {
          if (this.selected_ids.length > 0) {
            this.all_selected = 1;

            if (this.all_selected == 1) {
              // for (var i in this.reviews) {
              //   this.selected_ids.push(this.reviews[i].id);
              // }
            }
          }
          else {
            // this.resetSelection();
          }
        }
      }
    },
    mounted () {
      var that = this
      this.$store.state.reviews.initial_loading = true
      setTimeout(function() {
        that.getReviews()
      }, 1000)
    },
  }
</script>

<style>
  tbody tr:hover {
    background-color: transparent !important;
  }

  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  }

	.v-avatar {
		cursor: pointer;
		border: solid 1px #CCCCCC !important;
	}

	.v-avatar.add-more {
    border: solid 1px #58c1d6 !important;
    color: #58c1d6 !important;
	}

  .searching .v-card__title {
    font-size: 15px;
  }

  .reviewer {
    padding-bottom: 10px;
  }

  table .v-rating {
    display: inline-block;
    margin-left: 20px;
    margin-top: -1px;
  }

  table h3 {
    display: inline-block;
    padding-left: 8px;
    padding-top: 3px;
    font-weight: bold;
  }

  .reviews-wrapper table .v-chip {
    float: right;
  }

  .manage-btn {
    float: right;
  }

  .searching .v-card__title {
    font-size: 15px;
  }

  .source-logo {
    /* float: right; */
    display: inline-block;
    width: 30px;
    height: 30px;
    opacity: 0.8;
    margin-right: 0px;
    margin-top: 0px;
  }

  .profile-pic {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    float: left;
  }

  /* .reviews-wrapper .v-data-table-header, .v-data-table__mobile-row__header {
    display: none;
  } */
  
  header .v-toolbar__content {
    width: auto;
  }

  .v-application .elevation-1 {
      border: 0px;
      webkit-box-shadow: none !important;
      box-shadow: none !important;
  }

  .reviewer {
    padding-bottom: 10px;
    position: relative;
  }

  .reviewer .v-rating {
    display: inline-block;
    margin-left: 10px;
    margin-top: -1px;
  }

  .reviewer h3 {
    display: inline-block;
    padding-left: 8px;
    padding-top: 3px;
    font-weight: bold;
    color: #19233A;
  }

  .review-date {
    font-size: 12px;
    color: #757B89;
    padding-left: 10px;
  }

  .review_text {
    padding-top: 10px;
    color: #757B89;
  }

  @media screen and (max-width: 767px) {
    .reviews-wrapper table td {
      padding: 5px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    
    .reviewer .v-rating {
      display: block;
      margin-left: 10px;
    }

    .manage-btn {
      position: absolute;
      top: 0px;
      right: 30px;
    }

    .source-logo {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .reviews-wrapper table .v-chip {
      float: none;
    }
  }
</style>