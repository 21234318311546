module.exports = [
    "www.agoda.com", // FOURTH BATCH
    "www.avvo.com", // FOURTH BATCH
	"www.expedia.com", // FOURTH BATCH
	"www.facebook.com",
	"www.google.com",
	"www.playstore.com", // FOURTH BATCH
	"www.tripadvisor.com",
	"www.yelp.com",
	"www.zillow.com", // SECOND BATCH
	"www.zomato.com", // SECOND BATCH
	"www.airbnb.com", // FOURTH BATCH
	"www.aliexpress.com", // FOURTH BATCH
	"www.alternativeto.com", // FOURTH BATCH
	"www.amazon.com", // THIRD BATCH
	"www.angi.com", // FOURTH BATCH
	"www.apartmentratings.com", // FOURTH BATCH
	"www.apartments.com",  // THIRD BATCH
	"www.apps.apple.com", // FOURTH BATCH
	"www.bbb.org",
	"www.bookatable.co.uk", // FIFTH BATCH
	"www.booking.com", // SECOND BATCH
	"www.capterra.com", // FIFTH BATCH
	"www.cargurus.com", // SIXTH BATCH
	"www.cars.com", // FIFTH BATCH
	"www.citysearch.com", // THIRD BATCH
	"www.classpass.com", // SIXTH BATCH
	"www.consumeraffairs.com", // SIXTH BATCH
	"www.creditkarma.com", // SIXTH BATCH
	"www.customerlobby.com", // SIXTH BATCH
	"www.dealerrater.com",  // THIRD BATCH
	"www.ebay.com", // SECOND BATCH
	"www.edmunds.com", // SIXTH BATCH
	"www.etsy.com", // SECOND BATCH
	"www.findlaw.com", // SIXTH BATCH
	"www.foursquare.com",
	"www.g2crowd.com", // SECOND BATCH
	"www.gartner.com", // FIFTH BATCH
	"www.glassdoor.com",
	"www.greatschools.org", // SIXTH BATCH
	"www.healthgrades.com", // THIRD BATCH
	"www.homeadvisor.com",
	"www.homeaway.com.au",  // THIRD BATCH
	"www.homestars.com", // SIXTH BATCH
	"www.houzz.com", // FIFTH BATCH
	"www.hotels.com", // SECOND BATCH
	"www.indeed.com", // FIFTH BATCH
	"www.influenster.com", // FIFTH BATCH
	"www.insiderpages.com",
	"www.itcentralstation.com", // SIXTH BATCH
	"www.lawyers.com", // FIFTH BATCH
	// "www.lendingtree.com",
	// "www.martindale.com",
	"www.niche.com", // FIFTH BATCH
	// "www.openrice.com",
	"www.opentable.com", // SECOND BATCH
	"www.producthunt.com",  // THIRD BATCH
	// "www.productreview.com.au",
	"www.ratemds.com",  // THIRD BATCH
	"www.realself.com",  // THIRD BATCH
	// "www.reserveout.com",
	// "www.sitejabber.com",
	// "www.softwareadvice.com",
	// "www.talabat.com",
	// "www.theknot.com",
	"www.thumbtack.com",
	// "www.trulia.com",
	// "www.trustedshops.com",
	"www.trustpilot.com", // SECOND BATCH
	"www.trustradius.com", // SECOND BATCH
	// "www.vitals.com",
	"www.vrbo.com", // FIFTH BATCH
	"www.walmart.com",  // THIRD BATCH
	"www.webmd.com", // THIRD BATCH
	// "www.weddingwire.com",
	"www.yell.com",
	"www.yellowpages.com",
	"www.zocdoc.com",
];