<template>

  <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-2/5 bg-white rounded-lg mx-6">

      <div class="rg-modal-header">
        <span v-if="!contact.id">Add Contact</span>

        <span v-else>Edit Contact</span>

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6">

        <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          {{success}}
        </div>

        <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div v-html="error"></div>
        </div>

        <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
                <p class="mb-1 text-xs mb-2"><strong>
                    First Name
                </strong></p>
                <input name="first_name" ref="first_name" placeholder="e.g. John" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>

            <div class="w-full md:w-1/2 mb-6 md:mb-0">
                <p class="mb-1 text-xs mb-2"><strong>
                    Last Name
                </strong></p>
                <input name="last_name" ref="last_name" placeholder="e.g. Doe" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
            </div>
        </div>

        <!-- <p class="text-xs text-gray-700 mb-4">Your contact's name.</p> -->

        <div class="mb-3">
          <p class="mb-1 text-xs mb-2"><strong>
            Email
          </strong></p>
          <input name="email" ref="email" placeholder="e.g. johndoe@example.com" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
        </div>

        <!-- <p class="text-xs text-gray-700 mb-4">Your contact's email.</p> -->

        <div class="mb-3">
          <p class="mb-1 text-xs mb-2"><strong>
            Phone Number
          </strong></p>
          <input name="phone" ref="phone" placeholder="e.g. +18131234567" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
        </div>

        <!-- <p class="text-xs text-gray-700 mb-4">Your contact's phone number.</p> -->

        <a href="javascript: void(0);" @click="addField();" class="text-xs">+ Add Field</a>

        <div class="mt-2" v-for="(page, index) in custom_fields" :key="index">
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
              <v-text-field
                    v-model="custom_fields[index].name"
                    placeholder="Field Name"
                    label=""
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </div>
            
              <div class="w-full md:w-1/2 pr-5 mb-1 md:mb-0">
                <v-text-field
                    v-model="custom_fields[index].value"
                    placeholder="Field Value"
                    label=""
                    outlined
                    dense
                    hide-details
                    :append-outer-icon="'mdi-trash-can-outline'"
                    @click:append-outer="removeField(index)"
                ></v-text-field>
              </div>
            </div>
        </div>

      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': saving}" @click="save">
            SAVE
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
            CANCEL
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  data: function() {
    return {
      showModal: false,
      saving: false,
      success: null,
      error: null,

      contact: null,

      default_contact: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
      },

      custom_fields: []
    }
  },
  methods: {
    show() {
      this.showModal = true
    },
    hide() {
      this.showModal = false
      this.saving = false
    },
    createContact() {
        this.custom_fields = [];
        this.contact = JSON.parse(JSON.stringify(this.default_contact));
        this.success = ''
    },
    editContact(contact) {
      this.success = ''
      this.custom_fields = [];
        let self = this
        this.contact = JSON.parse(JSON.stringify(contact));

        setTimeout(function() {
          self.$refs.first_name.value = self.contact.first_name;
          self.$refs.last_name.value = self.contact.last_name;
          self.$refs.email.value = self.contact.email;
          self.$refs.phone.value = self.contact.phone;
          self.custom_fields = JSON.parse(self.contact.custom_fields);
        }, 200);
    },
    async save() {
      this.saving = true
      this.error = null
      try {

        let formData = new FormData();
        // formData.append("name", this.name);
        
        if (this.contact.id)
          formData.append("id", this.contact.id);

        formData.append("first_name", this.$refs.first_name.value);
        formData.append("last_name", this.$refs.last_name.value);
        formData.append("email", this.$refs.email.value);
        formData.append("phone", this.$refs.phone.value);
        formData.append("custom_fields", JSON.stringify(this.custom_fields));
        await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/contacts`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
              'Content-Type': 'multipart/form-data'
            }
        })

        //this.hide()
        // this.hide()
        // this.$store.state.contacts.fetchingContacts = true
    	// this.$store.dispatch('contacts/fetch')
      this.$parent.fetchContacts();
    	this.error = null

      this.$refs.first_name.value = '';
      this.$refs.last_name.value = '';
      this.$refs.email.value = '';
      this.$refs.phone.value = '';

      this.success = 'Contact successfully added!';

      this.custom_fields = []

      } catch (e) {
        console.log('e', e);
        this.error = e.response.data.message
      }
      this.saving = false
    },

    addField() {
        this.custom_fields.push({
          'name': 'Field Name',
          'value': ''
        });
    },
    removeField(index) {
        let all_pages = this.custom_fields;
        let new_fields = [];

        for (var i in all_pages) {
            if (i != index) {
              new_fields.push(all_pages[i]);
            }
        }

        this.custom_fields = new_fields;
    }
  }
}
</script>
