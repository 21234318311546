<template>

    <div>
  
      <panel-navigation>

        <div v-if="show_compare" @click.self="show_compare = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
          <div class="w-full sm:w-3/4 lg:w-3/4 bg-white rounded-lg mx-6">

            <div class="rg-modal-header">
              <span>Create Comparison</span>

              <a href="javascript: void(0);" class="rg-modal-close-btn" @click="show_compare = false"><i class="fa fa-times"></i></a>
            </div>

            <div class="p-6">

              <div v-if="!compareGenerated">

                <div class="text-center mb-2">
                  <i class="fa fa-spin fa-spinner"></i>

                  &nbsp;&nbsp;

                  <span class="text-sm">Generating comparison... please wait</span>
                </div>

                <div style="height: 700px;">
                  <iframe v-if="generatingGridTrackerImage" :src="'/grid-tracker/app.html'" style="width: 900px; height: 700px; margin: auto;"></iframe>
                </div>
              
              </div>

              <div v-else>
                <div class="text-center">
                  <div class="text-sm mb-2">Comparison generated! Grab your code below:</div>

                  <div class="card card-code bg-gray-100 border-0 text-gray-700" style="word-break: break-all; padding: 10px; font-size: 12px; min-height: 100px; text-align: left; max-width: 500px; margin: auto;">
                      &lt;script src="{{ compareCode }}" type="text/javascript"&gt;&lt;/script&gt;

                      <a
                          href="javascript: void(0);"
                          v-clipboard:copy="compareCodeMessage"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          class="text-xs float-right"
                          style="position: absolute; bottom: 0px; right: 10px;"
                      >
                          <i class="mdi mdi-content-copy"></i>
                          Copy
                      </a>
                  </div>
                </div>
              </div>

            </div>

            <div class="bg-gray-100 rounded-b-lg px-6 py-4">

              <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="show_compare = false">
                  CLOSE
              </button>

              <div class="clearfix"></div>

            </div>

          </div>
        </div>
  
        <div class="float-right ml-5 -mt-1">
          <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="openLink('/grid-tracker/app')" style="margin-top: -5px;"><i class="fa fa-file mr-2"> </i> CREATE REPORT</div>
        </div>
  
        <h1 class="text-lg font-semibold mb-3">Grid Ranker</h1>
  
        <hr class="border-b border-gray-100 mb-3">
  
        <v-skeleton-loader v-if="fetchingScans" type="article, article, article"></v-skeleton-loader>
  
        <div class="dashboard-box" v-if="!fetchingScans && scans.length == 0">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
  
              <br /><br /><br /><br />
  
              <div class="dashboard-box-iconsquare">
                <div class="dashboard-box-icon-background"></div>
                <div class="dashboard-box-icon">
                  <i class="mdi mdi-chart-timeline"></i>
                </div>
              </div>
  
              <div style="text-align: center;">
                <div class="pt-2 pb-2">
                  <strong>No Reports Yet</strong>
                </div>
  
                <div class="text-sm mb-4 text-gray-600">
                  You have no reports yet. Use our tool to create reports.
                </div>
  
                <br />
  
                <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 inline-block rounded cursor-pointer" @click="openLink('/grid-tracker/app')" style="margin-top: -5px;"><i class="fa fa-plus mr-2"></i> CREATE REPORT</div>
              </div>
              
              <br /><br /><br /><br /><br />
            </div>
          </v-card>
        </div>
  
        <template v-if="!fetchingScans && scans.length > 0">
          <div class="dashboard-box">
            <v-card
              class="pa-2"
              tile
            >
              <div class="p-3">
                <div class="justify-between items-start mb-3 float-right">
                  <v-menu
                    bottom
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="flex flex-row items-center px-4 py-2 rounded border-1 border-gray-500 text-xs text-gray-600 cursor-pointer">
                        <span class="mr-2">Actions</span>
                        <span class="text-xs" style="padding-top: 3px;">
                          <font-awesome-icon icon="chevron-down"  />
                        </span>
                      </div>
                    </template>
  
                    <v-list class="table-menu-actions">
                      <v-list-item v-if="selectedIds.length == 2" class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="showCompare()"><span class="table-menu-action-icon"><i class="fa fa-not-equal"></i></span> Compare</a></v-list-item-title></v-list-item>
                      <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="bulkDelete()"><span class="table-menu-action-icon"><i class="fa fa-trash"></i></span> Delete</a></v-list-item-title></v-list-item>
                    </v-list>
                  </v-menu>
                </div>
  
                <br clear="all" />
  
                <div class="overflow-x-scroll">
                <table class="w-full rg-datatable">
                  <thead class="border-b border-gray-100 w-full">
                    <tr>
                      <th></th>
                      <th>Location</th>
                      <th>Search Query</th>
                      <th>Search Settings</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody class="w-full" style="">
                    <tr v-for="scan in scans" :key="scan.id">
                      <td class="">
                        <v-checkbox
                          :id="scan.id+'-checkbox'"
                          :value="scan.id"
                          v-model="selectedIds"
                          hide-details
                           :ripple="false"
                        >
                        </v-checkbox>
                      </td>
                      <td>{{ scan.business }}</td>
                      <td>{{ scan.keyword }}</td>
                      <td>
                        <i class="mdi mdi-dots-grid" style="color: #2477F6;"></i>
                        {{ scan.grid_size }} x {{ scan.grid_size }}
                        &nbsp;&nbsp;
                        <i class="mdi mdi-arrow-top-right-bottom-left" style="color: #2477F6;"></i>
                        {{ scan.distance }} {{ ((scan.distance_type == "K") ? "Miles" : "KM") }}
                      </td>
                      <td>{{ formatDate(scan.created_at) }}</td>
                      <td style="text-align: right;">
                        <a href="javascript: void(0);" class="text-xs font-semibold rounded cursor-pointer" @click="openLink('/grid-tracker/app?action=scan&r=' + scan.id)">
                          <i class="fa fa-sync mr-2 text-blue"></i>
                          <span class="whitespace-no-wrap text-blue">Scan Again</span>
                        </a>

                        <a href="javascript: void(0);" class="text-xs font-semibold rounded cursor-pointer ml-3" @click="openLink('/grid-tracker/app?action=view&r=' + scan.id)">
                          <i class="fa fa-file mr-2 text-blue"></i>
                          <span class="whitespace-no-wrap text-blue">View Report</span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </v-card>
          </div>
        </template>
      </panel-navigation>
    </div>
  
  </template>
  
  <script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import PanelNavigation from '@/components/PanelNavigation.vue'
  import MenuUserActions from '@/components/menus/menuUserActions.vue'
  import moment from 'moment'
  
  export default {
    metaInfo () {
      return {
        title: "Grid Tracker | " + this.$appName
      }
    },
    components: {
      PanelNavigation,
      MenuUserActions
    },
    data() {
      return {
        selectedIds: [],

        show_compare: false,

        generatingGridTrackerImage: false,

        compare_image_url1: "",
        compare_image_url2: "",

        compareGenerated: false,
        compareCode: "",
        compareCodeMessage: ""
      }
    },
    computed: {
      ...mapState({
        scans: state => state.scans.scans,
        fetchingScans: state => state.scans.fetchingScans,
        me: state => state.me.me
      })
    },
    methods: {
      async bulkDelete() {
        console.log(this.selectedIds)
        await this.$store.dispatch('scans/bulkDelete', {Ids: this.selectedIds})
        this.$store.dispatch('scans/fetch')
      },
  
      formatDate(date) {
        return moment(date).format("MMM D, YYYY")
      },

      openLink(link) {
        window.location = link;
      },

      async showCompare() {
        let self = this;
        this.show_compare = true;
        this.compareGenerated = false;

        processScan1();

        function processScan1() {
          let scan_id = self.selectedIds[0];

          window.RG_GRID_ACTION = {
            ACTION: "view",
            SUB_ACTION: "generate_screenshot",
            IMAGE: 1,
            SCAN_ID: scan_id
          }

          self.generatingGridTrackerImage = true;
        }

        function processScan2() {
          let scan_id = self.selectedIds[1];

          window.RG_GRID_ACTION = {
            ACTION: "view",
            SUB_ACTION: "generate_screenshot",
            IMAGE: 2,
            SCAN_ID: scan_id
          }

          self.generatingGridTrackerImage = false;

          setTimeout(function() {
            self.generatingGridTrackerImage = true;
          }, 100);
        }

        function processComplete() {
          console.log('we reached here...');
          console.log('self.compare_image_url1', self.compare_image_url1);
          console.log('self.compare_image_url2', self.compare_image_url2);

          axios.post(`${process.env.VUE_APP_API_ENDPOINT}/grid-tracker/save-compare`, {
            compare_image_url1: self.compare_image_url1,
            compare_image_url2: self.compare_image_url2,
          }, {
            headers: {
              'Authorization': 'Bearer ' + self.$store.state.auth.jwt
            }
          }).then(function(response) {
            self.compareGenerated = true;

            self.compareCode = self.getCompareEmbedCode(response.data.token);
            self.compareCodeMessage = '<script src="' + self.compareCode + '" type="text/javascript"><\/script>';
          });
        }

        window.processCompareScreenshotUrl = undefined;

        if (typeof(window.processCompareScreenshotUrl) == "undefined") {
          window.processCompareScreenshotUrl = function(dataUrl) {
            console.log('process_data_url');

              axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, {
                file_code: dataUrl
              }, {
                headers: {
                  'Authorization': 'Bearer ' + self.$store.state.auth.jwt
                }
              }).then(function(response) {
                console.log('response.data.url', response.data.url)

                if (window.RG_GRID_ACTION.IMAGE == 1) {
                  self.compare_image_url1 = response.data.url;
                  processScan2();
                }
                else {
                  self.compare_image_url2 = response.data.url;
                  processComplete();
                }
              });
          }
        }
      },

      getCompareEmbedCode: function(token) {
        let self = this

        let host = window.location.host;

        if (this.$agency)
          if (this.$agency.domain)
            host = this.$agency.domain;

        let url = ((process.env.NODE_ENV == 'development') ? "http://localhost:8000" : "https://" + host) + "/js/v1/embed.js?token=" + token;

        // Dev
        if (process.env.NODE_ENV == 'development') {
          url = url + "&env=dev";
        }

        return url;
      },

      async runCreated() {
          let myUUid;

          if (this.$route.query.u) {
            myUUid = this.$route.query.u;
          }
          else {
            await this.$store.dispatch('me/fetch');

            if (this.$store.state.me) {
              myUUid = this.$store.state.me.me.uuid;
            }
          }

          let logo = "https://reviewgrower.com/wp-content/uploads/2021/09/reviewgrower-blue-green-middle-e1652210601332.png";

          if (this.$agency.logo)
            logo = this.$agency.logo;
          
          let companyName = "Review Grower";

          if (this.$agency) {
            companyName = this.$agency.company_name;
          }

          let self = this
          window.RG_GRID_CONFIG = {
              API_URL: process.env.VUE_APP_API_ENDPOINT,
              USER: {
                  uuid: myUUid
              },
              LOGO: logo,
              COMPANY_NAME: companyName,
              GOOGLE_MAPS_KEY: this.$agency.integrations.google_maps.settings.api_key,
              VALUE_SERP_KEY: this.$agency.integrations.value_serp.settings.api_key,
              SAB_ENABLED: (this.$agency.settings.grid_sab_enabled == 1)
          }

          console.log('window.RG_GRID_CONFIG.COMPANY_NAME', window.RG_GRID_CONFIG.COMPANY_NAME)

          this.loaded = true;
      },

      onCopy: function (e) {
        this.$swal({
          title: "Copied",
          text: "Code copied successfully!",
          showCancelButton: false,
          });
      },
      onError: function (e) {
        alert('Failed to copy the text to the clipboard')
        console.log(e);
      },
    },
    mounted() {
      this.$store.state.scans.fetchingScans = true
      this.$store.dispatch('scans/fetch')
      
      this.runCreated();
    }
  }
  </script>