import axios from 'axios'

export default {
  namespaced: true,
  state: {
    locations: [],
    fetchingLocations: true
  },
  getters: {
    getLocations(state){ return state.locations }
  },
  mutations: {
    setLocations(state, locations) {
      state.locations = locations
      state.fetchingLocations = false
    }
  },
  actions: {
    fetch({commit}) {
      return new Promise(async (resolve, reject) => {

        let uuid = null;

        if (localStorage.getItem("business_user")) {
          let bn = JSON.parse(localStorage.getItem("business_user"));
          uuid = bn.uuid;
        }
  
        try {
          let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/locations?pu=` + uuid, {
            headers: {
              'Authorization': 'Bearer ' + this.state.auth.jwt
            }
          })
  
          if (response.status == 200) {
            commit('setLocations', response.data)
            resolve()
            return;
          }
        } catch (error) {
          console.log('error',error)
          return
        }
  
        reject()
  
      })
    }
  }
}