import { render, staticRenderFns } from "./EmbedSidebarSlider.vue?vue&type=template&id=b25d97ea&scoped=true"
import script from "./EmbedSidebarSlider.vue?vue&type=script&lang=js"
export * from "./EmbedSidebarSlider.vue?vue&type=script&lang=js"
import style0 from "./EmbedSidebarSlider.vue?vue&type=style&index=0&id=b25d97ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b25d97ea",
  null
  
)

export default component.exports