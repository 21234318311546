<template>
    <div class="badge-4-wrapper" :style="((widget_data.widget.settings.badge_display == 'Embed' ? 'margin: auto;': ''))">
        <v-card
            elevation="2"
            class="reviews-badge"
            rounded
            :style="'border-radius: 3px !important; padding-top: 0px; cursor: initial; background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';'"
        >
            <div class="top-border" :style="'background: ' + widget_data.widget.settings.badge4_color + ';'"></div>

            <div class="top-rating-box">
                <div class="top-average-rating">
                    {{ widget_data.average_rating }}
                </div>

                <div class="top-average-subtitle text-xs">
                    {{ ((widget_data.widget.settings.badge_4_title) ? widget_data.widget.settings.badge_4_title.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Out of 5 stars") }}
                </div>
            </div>

            <div class="text-center -mt-6">
                <v-rating
                    class="reviews-rating"
                    :value="parseFloat(widget_data.average_rating)"
                    :color="widget_data.widget.settings.star_color"
                    dense
                    half-increments
                    readonly
                    size="28"
                ></v-rating>
            </div>

            <div class="text-xs text-center pt-2 mb-5">
                {{ ((widget_data.widget.settings.badge_4_subtitle) ? widget_data.widget.settings.badge_4_subtitle.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Based on " + widget_data.total_reviews + " reviews") }}
            </div>

            <div v-show="widget_data.widget.settings.show_leave_a_review_button == 1" class="pt-0 mb-5" style="width: 160px; margin: auto;">
                <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer" @click="openReviewFunnel()" :style="'margin-top: -5px; border-color: #148c9c; color: ' + widget_data.widget.settings.badge4_color + ' !important;'"><i class="fa fa-star mr-2"> </i> WRITE A REVIEW</div>
            </div>

            <div class="clearfix"></div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import urlParser from 'url-parse'

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },
    
    methods: {
        getHostname(url) {
            return urlParser(url).hostname.replace("www.", "")
        },
        getPathname(url) {
            return urlParser(url).pathname
        },
        getIconUrl(url) {
            return require('@/assets/images/review-services/www.' + this.getHostname(url) + ".png");
        },
        listingEnabled(id) {
            console.log('id', id);
            console.log('this.widget_data.widget.settings.platforms', this.widget_data.widget.settings.platforms);
            for (var i in this.widget_data.widget.settings.platforms) {
                if (this.widget_data.widget.settings.platforms[i] == id)
                    return true;
            }

            return false;
        },
        openReviewFunnel() {
            window.open(this.widget_data.review_funnel_link);
        }
    }
}
</script>

<style type="text/css" scoped>
    .badge-4-wrapper {
        width: 235px;
        padding-bottom: 10px;
    }

    .top-border {
        width: calc(100% + 3px);
        height: 100px;
        margin-bottom: 5px;
        margin-left: -1px;
    }

    .top-rating-box {
        width: 100px;
        height: 100px;
        background: #FFFFFF;
        border-radius: 50%;
        margin: auto;
        margin-top: -55px;
        text-align: center;
    }

    .top-average-rating {
        font-size: 24px;
        font-weight: bold;
        padding-top: 10px;
    }

    .listing-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-block;
    }
</style>