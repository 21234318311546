<template>

  <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-2/5 bg-white rounded-lg mx-6">

      <div class="rg-modal-header">
        Import Contacts

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6">

        <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          {{success}}
        </div>

        <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          {{error}}
        </div>

        <div class="mb-6">
          <input name="csv" placeholder="Test" ref="file" class="block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white border-2 border-dashed border-grey-light hover:border-grey px-2 py-2 rounded" type="file">
        </div>

        <p class="text-xs text-gray-700 mb-4">Your CSV file should be in the following format. Please make sure Header and Fields match.</p>

        <table class="w-full rg-datatable">
          <thead>
            <tr class="">
              <th>First</th>
              <th>Last</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Brian</td>
              <td>Adams</td>
              <td>info1@example.com</td>
              <td>+123456781</td>
              <td>2019-03-25 12:00:00 </td>
            </tr>
            <tr>
              <td>David</td>
              <td></td>
              <td>info2@example.com</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>+123456783</td>
              <td>2019-04-22 12:00:00</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>info4@example.com</td>
              <td>+123456784</td>
              <td>2019-05-22 12:00:00</td>
            </tr>
          </tbody>
        </table>

      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': uploading}" @click="upload">
            UPLOAD
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
            CANCEL
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

</template>

<script>
import axios from 'axios'
export default {
  data: function() {
    return {
      showModal: false,
      uploading: false,
      success: null,
      error: null
    }
  },
  methods: {
    show() {
      this.showModal = true
    },
    hide() {
      this.showModal = false
      this.uploading = false
    },
    async upload() {
      this.uploading = true
      this.error = null
      try {

        let formData = new FormData();
        formData.append("csv", this.$refs.file.files[0]);
        await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/provider/csv_file/upload`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
              'Content-Type': 'multipart/form-data'
            }
        })

        //this.hide()
        this.$router.push('/provider/csv_file/success')
      } catch (e) {
        this.error = e.response.data.message
      }
      this.uploading = false
    }
  }
}
</script>
