<template>

  <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-4/5 bg-white rounded-lg mx-6" style="max-height: 100%;">

    <div class="rg-modal-header">
        View {{ this.$parent.matching_reviews_count }} Matching Reviews

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6" style="height: calc(100% - 110px); overflow: hidden;">

        <div style="height: calc(100%); overflow: auto;">
          <div class="dashboard-box">
            <ReviewsTable dashboard="false" social_posting="true" />
          </div>
        </div>
      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
            CLOSE
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import ReviewsTable from '@/components/ReviewsTable.vue'

export default {
  data: function() {
    return {
      showModal: false
    }
  },
  components: {
      ReviewsTable
  },
  methods: {
    show() {
      this.showModal = true
    },
    hide() {
      this.showModal = false
    }
  },
  created() {

  }
}
</script>
