<template>

  <panel-navigation>

    <dialog-add-review ref="dialogAddReview"></dialog-add-review>

    <div v-if="me" class="float-right ml-5 -mt-1">
      <div v-if="me.company_type == 'agency'">
        <v-autocomplete
          v-if="!fetchingLocations"
          label="All Clients"
          dense
          solo
          flat
          solo-inverted
          hide-details
          clearable
          :items="locations"
          v-model="selected_client"
          class="locations-dropdown"
          prepend-inner-icon="mdi-account-outline"
          append-icon="mdi-chevron-down"
          item-text="name"
          item-value="value"
          @change="selectClient()"
        >
          <template v-slot:item="{ item }">
            <template v-if="me.company_type == 'agency'">
              <template v-if="item.type == 'business'">
                <span style='font-size: 14px;' v-html="'<i class=\'mdi mdi-briefcase-outline\'></i>&nbsp; ' + item.name"></span>
              </template>
              <template v-if="item.type == 'location'">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='font-size: 12px;' v-html="'<i class=\'fa fa-map-marker\'></i>&nbsp; ' + item.name"></span>
              </template>
            </template>
          </template>
        </v-autocomplete>
      </div>
      
      <div v-else class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="$refs.dialogAddReview.show()" style="margin-top: -5px;"><i class="fa fa-plus mr-2"> </i> ADD REVIEW MANUALLY</div>
    </div>
    
    <h1 class="text-lg font-semibold mb-3">Reviews</h1>

    <hr class="border-b border-gray-100 mb-3">

    <div class="dashboard-box stats-box w-full px-2 -mx-2">
      <v-card
        class="pa-2"
        tile
      >
        <div class="p-3">
          <div class="reviews-section-wrapper">
            <ReviewsTable dashboard="false" social_posting="false" :key="reviewsTableKey" :selected_client="selected_client" />
          </div>
        </div>
      </v-card>
    </div>

  </panel-navigation>

</template>

<script>
import { mapState } from 'vuex'
import PanelNavigation from '@/components/PanelNavigation.vue'
import ReviewsTable from '@/components/ReviewsTable.vue'
import DialogAddReview from '@/components/dialogs/reviews/addReview.vue'
import moment from 'moment'

export default {
  metaInfo () {
    return {
      title: "Reviews | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    ReviewsTable,
    DialogAddReview
  },

  computed: {
    ...mapState({
      me: state => state.me.me,
      profile: state => state.me.profile,
      fetchingLocations: state => state.locations.fetchingLocations,
      locations: state => state.locations.locations
    })
  },

  data () {
    return {
      reviewsTableKey: 0,
      selected_client: null
    }
  },

  methods: {
    refreshReviews() {
      this.reviewsTableKey++;
    },
    async fetchMeStatsLocations() {
      await this.$store.dispatch('me/fetch')
      await this.$store.dispatch('locations/fetch')
    },
    selectClient() {
      // console.log('this.selected_client', this.selected_client)
      this.refreshReviews();

      localStorage.setItem('rgReviewsSelectedClient', this.selected_client)
    }
  },
  created() {
    this.fetchMeStatsLocations();

    this.selected_client = localStorage.getItem('rgReviewsSelectedClient')

    if (this.selected_client)
      this.refreshReviews();
  }
}
</script>

<style>
  .reviews-section-wrapper .pa-2.v-card {
    border: 0px;
  }
</style>