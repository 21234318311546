<template>

  <panel-navigation>
    
    <h1 class="text-lg font-semibold mb-4">Feedback</h1>

    <hr class="border-b border-gray-100">


    <div v-if="fetchingFeedback">
      <br /><br />
      <v-skeleton-loader type="article, article, article"></v-skeleton-loader>
    </div>

    <div v-else>

      <v-text-field
        v-model="search"
        clearable
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Search"
        style="max-width: 200px; margin-bottom: 20px; background: #FFFFFF;"
      ></v-text-field>

      <div class="dashboard-box" v-if="sortedFeedback.length == 0">
        <v-card
          class="pa-2"
          tile
        >
          <div class="p-3">

            <br /><br /><br /><br /><br />

            <div class="dashboard-box-iconsquare">
              <div class="dashboard-box-icon-background"></div>
              <div class="dashboard-box-icon">
                <i class="mdi mdi-forum-outline"></i>
              </div>
            </div>

            <div style="text-align: center;">
              <div class="pt-2 pb-2">
                <strong>No Feedback Yet</strong>
              </div>

              <div class="text-sm mb-4 text-gray-600">
                No direct feedback yet. Check back later.
              </div>
            </div>
            
            <br /><br /><br /><br /><br /><br />
          </div>
        </v-card>
      </div>

	    <div v-if="sortedFeedback.length > 0">

        <div class="dashboard-box stats-box w-full px-2 -mx-2">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">

              <v-tabs
                v-model="showByResponded"
                class="mb-3"
                >
                <v-tab :value="'all'" @click="getFeedback()">All</v-tab>
                <v-tab :value="'responded'" @click="getFeedback()">Responded</v-tab>
                <v-tab :value="'not-responded'" @click="getFeedback()">Not Responded ({{ notRespondedCount }})</v-tab>
              </v-tabs>
              
              <div v-for="fb in sortedFeedback" :key="fb.id" class="border-b border-gray-200 text-sm p-3 text-gray-800 bg-white mb-2">
                <div class="absolute text-gray-400"><svg width="20" height="20"  class="fill-current" viewBox="0 0 1941 1941" xmlns="http://www.w3.org/2000/svg"><path d="M906.667 1088v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136V768q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136zm896 0v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136V768q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136z"/></svg></div>
                <div class="ml-8">
                  <strong>
                    <strong v-if="fb.contact.first_name || fb.contact.last_name">{{ fb.contact.first_name }} {{ fb.contact.last_name }}:</strong>
                  </strong>
                  {{ fb.text }}

                  <span v-if="fb.rating">
                    | Rating: <strong>{{ fb.rating }}</strong>
                  </span>

                  <span class="float-right not-mobile" style="font-size: 12px; text-align: right;">
                    {{ formatDate(fb.created_at) }}

                    <br />

                    <div v-if="!fb.responded" class="border-1 border-blue text-primary text-xs text-bold px-2 py-1 rounded cursor-pointer mt-2" @click="respondToFeedback(fb)" style="margin-top: -5px;"><i class="mdi mdi-forum-outline mr-2"> </i> RESPONDED</div>

                    <div v-else class="mt-2">
                      <v-chip
                        class=""
                        color="blue lighten-4"
                        text-color="blue darken-3"
                        small
                      >
                        <i class="mdi mdi-check-circle mr-2"></i>
                        Responded
                      </v-chip>
                    </div>
                  </span>
                  <div class="mobile-only mt-1" style="font-size: 12px; font-style: italic;">
                    <i class="fa fa-calendar"></i> {{ formatDate(fb.created_at) }}
                  </div>
                  <br class="not-mobile" />
                    <span v-if="fb.contact.email && fb.contact.phone" class="text-xs">
                      <a :href="'mailto: ' + fb.contact.email">{{ fb.contact.email }}</a> &nbsp; • &nbsp; {{ fb.contact.phone }}
                    </span>
                    <span v-else class="text-xs">
                      <span v-if="fb.contact.email">
                        <a :href="'mailto: ' + fb.contact.email">{{ fb.contact.email }}</a>
                      </span>
                      <span v-else>
                        {{ fb.contact.phone }}
                      </span>
                    </span>
                    <span v-if="fb.contact.custom_fields && fb.contact.custom_fields != '[]' && fb.contact.custom_fields != 'null'" class="text-xs">
                      &nbsp; • &nbsp;
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <a href="javascript: void(0)"
                            v-bind="attrs"
                            v-on="on"
                          >custom fields</a>
                        </template>
                        <div style="background: #FFFFFF; padding: 10px; font-size: 14px;" v-html="displayCustomFields(fb.contact.custom_fields)"></div>
                      </v-menu>
                    </span>
                  </div>
              </div>
            </div>
          </v-card>
        </div>
	    </div>
	</div>

  </panel-navigation>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import PanelNavigation from '@/components/PanelNavigation.vue'
import moment from 'moment'

export default {
  metaInfo () {
    return {
      title: "Feedback | " + this.$appName
    }
  },
  data() {
    return {
      search: "",

      showByResponded: "all",
    }
  },
  components: {
    PanelNavigation
  },
  computed: {
    ...mapState({
      feedback: state => state.feedback.feedback,
      fetchingFeedback: state => state.feedback.fetchingFeedback,
      notRespondedCount: state => state.feedback.notRespondedCount,
      ...mapState({
        me: state => state.me.me
      })
    }),
    sortedFeedback() {
      return this.feedback
    },
  },
  methods: {
  	formatDate: function(date) {
      return moment(String(date)).format(this.me.settings.date_format)
    },
    displayCustomFields(custom_fields) {
      let html = '';

      let parsed_fields = JSON.parse(custom_fields);

      for (var i in parsed_fields) {
        html += '<strong>' + parsed_fields[i].name + "</strong>: " + parsed_fields[i].value + "<br />";
      }

      return html;
    },
    async respondToFeedback(item) {
      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/feedback/responded`, {
        id: item.id
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      this.$store.dispatch('feedback/fetch', {
        review_sites: null,
        date: null
      })
    },

    getFeedback() {
      let sbr = 0;

      let self = this;
      setTimeout(function() {
        if (self.showByResponded == 0)
          sbr = 'all';
        else if (self.showByResponded == 1)
          sbr = 'responded';
        else if (self.showByResponded == 2)
          sbr = 'not-responded';

        self.$store.dispatch('feedback/fetch', {
          review_sites: null,
          date: null,
          search: this.search,
          showByResponded: sbr
        })
      }, 100);
    }
  },
  created() {
    this.$store.state.feedback.fetchingFeedback = true
    this.$store.dispatch('feedback/fetch', {
      review_sites: null,
      date: null,
      showByResponded: this.showByResponded
    })
  },

  watch: {
    search: {
      handler() {
        this.$store.dispatch('feedback/fetch', {
          review_sites: null,
          date: null,
          search: this.search,
          showByResponded: this.showByResponded
        })
      }
    }
  },
}
</script>

