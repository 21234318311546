<template>
    <div class="badge-18-wrapper" :style="((widget_data.widget.settings.badge_display == 'Embed' ? 'margin: auto;': ''))">
        <v-card
            elevation="0"
            class="reviews-badge"
            @click="$parent.showDialog()"
            outlined
            flat
            :style="'border-radius: 0px !important; padding-top: 0px; background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';'"
        >
            <div class="clearfix pl-1">
                <div class="float-left" style="width: 60px; padding-top: 15px; padding-left: 10px; position: relative;">
                    <div style="position: absolute; -webkit-transform: rotate(230deg); -moz-transform: rotate(230deg); -o-transform: rotate(230deg); -ms-transform: rotate(230deg); transform: rotate(230deg);"
                        :style="((widget_data.widget.settings.platforms.length == 1) ? 'left: -1px; top: 4px;' : 'top: 6px; left: 3px;')"
                        >
                        <svg class="media-object" width="60" height="60" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.17687 31.1719C7.27449 30.8126 7.05875 30.313 6.85285 29.9529C6.78874 29.8455 6.71919 29.7415 6.64449 29.6412C4.87857 26.9631 3.93735 23.8256 3.93746 20.6177C3.90875 11.4097 11.5442 3.9375 20.986 3.9375C29.2203 3.9375 36.0937 9.64195 37.6999 17.2143C37.9405 18.3369 38.0621 19.4819 38.0625 20.63C38.0625 29.8512 30.7215 37.4407 21.2797 37.4407C19.7785 37.4407 17.7523 37.0634 16.6474 36.7541C15.5424 36.4448 14.4391 36.0347 14.1545 35.9248C13.8633 35.8129 13.5541 35.7553 13.2423 35.755C12.9016 35.7537 12.5642 35.8215 12.2505 35.9543L6.68633 37.9624C6.56443 38.015 6.43526 38.0487 6.30324 38.0625C6.19906 38.0622 6.09597 38.0412 5.99996 38.0008C5.90394 37.9603 5.81691 37.9012 5.74389 37.8269C5.67087 37.7526 5.61333 37.6645 5.57459 37.5678C5.53585 37.4711 5.51668 37.3677 5.5182 37.2635C5.52504 37.1721 5.54154 37.0816 5.56742 36.9936L7.17687 31.1719Z" stroke="#95A7B1" stroke-width="1" stroke-miterlimit="10" stroke-linecap="round"></path>
                        </svg>
                    </div>

                    <div v-if="widget_data.widget.settings.platforms.length == 1">
                        <img v-for="(item, index) in widget_data.widget.settings.listings" :key="index" v-show="listingEnabled(item.id)"
                            :src="getIconUrl(item.url)"
                            class="listing-img"
                        >
                    </div>
                    <div v-else class="-mt-2">
                        <i class="mdi mdi-share-variant-outline" :style="'font-size: 38px; color: ' + widget_data.widget.settings.star_color"></i>
                    </div>
                </div>
            

                <div class="float-left" style="width: calc(100% - 60px); padding-left: 10px; padding-top: 4px;">
                    <div class="reviews-title reviews-read-link text-sm pt-1 -mb-1"
                        v-html="((widget_data.widget.settings.badge_18_title) ? widget_data.widget.settings.badge_18_title.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating).replace(/\[bold\]/g, '<strong>').replace(/\[\/bold\]/g, '</strong>') : 'Our Reviews')"
                    ></div>

                    <div :style="'float: left; font-size: 37px; margin-top: -7px; margin-bottom: -6px;'">
                        {{ widget_data.average_rating }}
                    </div>

                    <div :style="'float: left; padding-left: 4px;'">
                        <div style="">
                            <v-rating
                                class="reviews-rating"
                                :value="parseFloat(widget_data.average_rating)"
                                :color="widget_data.widget.settings.star_color"
                                dense
                                half-increments
                                readonly
                                size="16"
                            ></v-rating>
                        </div>

                        <div class="text-xs -mt-1" style="padding-left: 3px; letter-spacing: 1.8px;"
                            v-html="((widget_data.widget.settings.badge_18_subtitle) ? widget_data.widget.settings.badge_18_subtitle.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating).replace(/\[bold\]/g, '<strong>').replace(/\[\/bold\]/g, '</strong>') : widget_data.total_reviews + 'reviews')"
                        >
                        </div>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="clearfix"></div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import urlParser from 'url-parse'

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },
    
    methods: {
        getHostname(url) {
            return urlParser(url).hostname.replace("www.", "")
        },
        getPathname(url) {
            return urlParser(url).pathname
        },
        getIconUrl(url) {
            return require('@/assets/images/review-services/www.' + this.getHostname(url) + ".png");
        },
        listingEnabled(id) {
            console.log('id', id);
            console.log('this.widget_data.widget.settings.platforms', this.widget_data.widget.settings.platforms);
            for (var i in this.widget_data.widget.settings.platforms) {
                if (this.widget_data.widget.settings.platforms[i] == id)
                    return true;
            }

            return false;
        }
    }
}
</script>

<style type="text/css" scoped>
    .badge-18-wrapper {
        width: 240px;
        padding-bottom: 10px;
    }

    .listing-img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: inline-block;
    }
</style>