import '@fortawesome/fontawesome-free/css/all.css'
import Vue from "vue";
import App from "./App.vue";
import VueMoment from "vue-moment";
import VueAnalytics from "vue-analytics";
import VueMeta from "vue-meta";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueClipboard from 'vue-clipboard2'
import VueMasonry from 'vue-masonry-css'
import VueApexCharts from 'vue-apexcharts'
import Vue2Editor from "vue2-editor";
import VueCookie from 'vue-cookie';

import tinymce from 'vue-tinymce-editor'

import 'tinymce/skins/lightgray/skin.min.css'

import 'vue-tel-input/dist/vue-tel-input.css';

import {
  faCoffee,
  faPlusCircle,
  faChartLine,
  faChevronDown,
  faMobile,
  faEnvelope,
  faClock,
  faUsers,
  faPaperPlane,
  faCheckCircle,
  faCheck,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify';
import Vuetify from 'vuetify/lib'

import VueSweetalert2 from 'vue-sweetalert2';
import LoadingOverlay from 'vue-swal2-loading-overlay';

import Gravatar from 'vue-gravatar';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import OtpInput from "@bachdgvn/vue-otp-input";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import Segment from '@dansmaculotte/vue-segment'

import VueIconPicker from "v-icon-picker";

import vClickOutside from 'v-click-outside'

let appName = 'Reviewgrower'

if (window.location.origin != process.env.VUE_APP_MAIN_URL) {
  axios.get(`${process.env.VUE_APP_API_ENDPOINT}/agency/get-agency-details` + "?host=" + window.location.host).then(function(response) {
    if (response.data.success) {
      appName = response.data.agency.company_name

      Vue.use({
        install() {
          Vue.agency = response.data.agency;
          Vue.prototype.$agency = response.data.agency;
          Vue.appName = response.data.agency.company_name;
          Vue.prototype.$appName = response.data.agency.company_name;
        }
      });

      continueLoadingApp()
    }
    else {
      return "Not a valid domain."
    }
  });
}
else {
  Vue.use({
    install() {
      Vue.agency = null;
      Vue.prototype.$agency = {
        settings: {
          is_omni: false,
          allow_business_to_modify_review_sites: 1
        }
      };
      Vue.appName = appName;
      Vue.prototype.$appName = appName;
    }
  });

  continueLoadingApp()
}

function continueLoadingApp() {
  let account_type = '';
  let loggedIn = false;

  let vuex = {auth: {jwt: null}};

  try {
    vuex = JSON.parse(window.localStorage.vuex);
  }
  catch (e) {}

  // console.log('vuex', vuex)

  if (vuex.auth.jwt) {
    try {
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me?field=account_type`, {
        headers: {
          'Authorization': 'Bearer ' + vuex.auth.jwt
        }
      }).then(function(response) {
        // console.log('response.data', response.data)
        account_type = response.data.account_type;
        loggedIn = true;
        continueLoadingApp2();
      }).catch(function (error) {
        continueLoadingApp2();
      });
    }
    catch (e) {
      continueLoadingApp2();
    }
  }
  else {
    continueLoadingApp2();
  }
  
  function continueLoadingApp2() {
    library.add([
      faCoffee,
      faPlusCircle,
      faChartLine,
      faChevronDown,
      faMobile,
      faEnvelope,
      faClock,
      faUsers,
      faPaperPlane,
      faCheckCircle,
      faCheck,
      faLeaf,
    ]);

    Vue.use(VueMoment);
    Vue.use(VueMeta);
    Vue.component("font-awesome-icon", FontAwesomeIcon);

    Vue.component('v-gravatar', Gravatar);

    Vue.use(VueSweetalert2);

    Vue.use(LoadingOverlay);

    Vue.use(VueClipboard)

    Vue.use(Vuetify)

    Vue.use(VueMasonry);

    Vue.use(VueApexCharts)

    Vue.component('apexchart', VueApexCharts)

    Vue.component('tinymce', tinymce)

    Vue.use(VueCookie);

    // Make BootstrapVue available throughout your project
    Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
    Vue.use(IconsPlugin)

    Vue.use(Vue2Editor);

    Vue.component("v-otp-input", OtpInput);

    Vue.use(VueIconPicker);

    Vue.use(vClickOutside)

    Vue.use({
      install() {
          Vue.appName = appName;
          Vue.prototype.$appName = appName;
      }
    });

    // console.log('account_type', account_type)

    let trackingDisabled = ((process.env.NODE_ENV == 'development' || account_type != 'agency') && loggedIn) ? true : false;

    if (window.location.href.split('/render').length > 1)
      trackingDisabled = true;

    if (window.location.href.split('/rf').length > 1)
      trackingDisabled = true;

    if (window.location.href.split('admin.').length > 1)
      trackingDisabled = true;

    if (!loggedIn)
      trackingDisabled = true;

    // console.log('trackingDisabled', trackingDisabled);

    new Vue({
      router
    });

    if (router.currentRoute.name == 'review-funnel-public')
      trackingDisabled = true;

    if (router.currentRoute.name == 'widgets_embed')
      trackingDisabled = true;

    Vue.use(Segment, {
      writeKey: 'q9tq8zclRB6L3YSiSpZO9vpt10cm9juu',
      disabled: trackingDisabled,
      router,
      settings: {}
    })


    if (!trackingDisabled) {
      Vue.use(VueAnalytics, {
        id: "xxx",
        router,
      });
    }

    axios.interceptors.response.use(undefined, async function (error) {
      if (error.response.status === 401) {
        await store.dispatch("auth/logout");
        router.push("/login");
      }
      return Promise.reject(error);
    });

    // Plugins
    // ...

    // Sass file
    require("./assets/styles/main.css");

    Vue.config.productionTip = false;

    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App)
    }).$mount("#app");
  }
}