<template>

  <div>
    <div v-if="showModal" class="fixed top-0 left-0 h-full w-full z-50 flex flex-col items-center justify-top px-4" style="background-color: rgba(0,0,0,.3);">
      <div class="w-full bg-white shadow rounded-lg mx-6" style="height: 100%; max-width: 800px;">

      <div class="rg-modal-header">
          Customize Themes

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6" style="height: calc(100% - 110px); overflow: hidden;">

        <div class="flex flex-wrap" style="height: calc(100%); overflow: auto;">
          <div class="w-full md:w-1/4">
            <div class="text-md text-bold mb-3">Themes</div>

            <br />

            <template v-if="me">
              <v-row class="pl-2 pt-3">
                <v-avatar v-for="theme in themes" :key="theme.id" :class="'mr-4 ml-4 mb-2 ' + ((selected_theme.id == theme.id) ? 'selected' : '')" v-bind:style="{ backgroundColor: theme.background_color, color: theme.text_color }" @click="selectTheme(theme)">
                  <img
                    :src="theme.background_image"

                    class="social-share-circle"

                    v-if="theme.background_image"
                  >
                  <span class="headline" style="position: absolute;">Aa</span>
                </v-avatar>

                <v-avatar v-if="themes.length < 10" class="add-more ml-4" @click="addTheme()">
                  <span class="headline"><i class="fa fa-plus"></i></span>
                </v-avatar>
              </v-row>
            </template>
          </div>

          <div class="w-full md:w-3/4 sm:pr-6 mb-8">
            <div class="text-md text-bold mb-3">Background Options</div>

            <br />

            <v-card class="mb-5"
                elevation="2"
            >
              <div style="position: relative; text-align: center; padding: 120px; overflow:hidden;" v-bind:style="{ backgroundColor: selected_theme.background_color, color: selected_theme.text_color }">
                <img :src="selected_theme.background_image" v-if="selected_theme.background_image" style="position: absolute; left: 0px; top: 0px; width: 100%; min-height: 100%;" />
                <div style="z-index: 3; position: relative;">This is an example of what the feedback text will look like over this background.</div>
                <button v-if="selected_theme.background_image" class="bg-red-500 text-white text-sm text-bold inline-block rounded px-2 cursor-pointer" style="padding-top: 2px; position: absolute; z-index: 2; right: 10px; bottom: 10px;" @click="removeImage()"><i class="fa fa-times text-orange mr-2"></i> Remove Image</button>
                <div :class="'social-share-theme-modal-overlay js-modal-theme-overlay is-none ' + ((selected_theme.overlay_type == 'Dark') ? 'is-dark' : '') + ' ' + ((selected_theme.overlay_type == 'Light') ? 'is-light' : '')"></div>
              </div>
            </v-card>

            <div class="flex flex-wrap">

              <div class="w-full md:w-1/2 sm:pr-6 mb-8">

                <div class="has-color-picker">
                  <p class="mb-1 text-xs mb-2"><strong>Background Color:</strong></p>

                  <color-picker v-model="selected_theme.background_color"></color-picker>
                </div>

                <div class="has-color-picker mt-3">
                  <p class="mb-1 text-xs mb-2"><strong>Text Color:</strong></p>

                  <color-picker v-model="selected_theme.text_color"></color-picker>
                </div>

                <p class="mb-1 text-xs mb-2 mt-3"><strong>Overlay:</strong></p>

                <v-select
                  v-model="selected_theme.overlay_type"
                  :items="overlay_items"
                  outlined
                  dense
                  hide-details
                ></v-select>

              </div>

              <div class="w-full md:w-1/2 sm:pr-6 mb-8">

                <p class="mb-1 text-xs mb-2"><strong>Background Image:</strong></p>

                <!-- <v-file-input
                  accept="image/*"
                  v-model="file"
                  @change="onUpload"
                  class="rg-uploader"
                  prepend-icon="mdi-camera"
                  outlined
                  hide-input
                ></v-file-input> -->

                <button class="rg-dialog-button-save rounded bg-gray-500 hover:bg-gray-600 text-white focus:outline-none py-2 px-4" @click="showImageLibraryModal = true;">
                    <i class="fa fa-image"></i> OPEN PHOTO LIBRARY
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>

        <div class="bg-gray-100 rounded-b-lg px-6 py-4">

          <button class="rounded bg-blue text-white text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right"  :class="{'spinner': saving}" @click="save">
              SAVE
          </button>

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
              CLOSE
          </button>

          <div class="clearfix"></div>

        </div>

      </div>
    </div>

    <div v-if="showImageLibraryModal" class="fixed top-0 left-0 h-full w-full z-50 flex flex-col items-center justify-top px-4" style="background-color: rgba(0,0,0,.3); margin: 0px !important;">
      <div class="w-full bg-white shadow rounded-lg mx-6 mt-24" style="height: 100%; max-width: 1100px;  margin: 0px !important;">
        <div class="rg-modal-header">
          Image Library

            <a href="javascript: void(0);" class="rg-modal-close-btn" @click="showImageLibraryModal = false"><i class="fa fa-times"></i></a>
        </div>


        <div class="p-6" style="height: calc(100% - 60px);">

          <div class="flex flex-wrap" style="height: 100%;">
            
            <v-tabs style="height: 100%;">
              <v-tab>Hosted Images</v-tab>
              <v-tab>Unsplash</v-tab>

              <v-tab-item style="height: 100%;">
                <div class="pt-3" style="height: 100%;">
                  <div v-if="uploading">
                    <div style="text-align: center; padding: 100px; border: solid 1px #CCCCCC; border-radius: 5px;">
                      <i class="fa fa-spin fa-spinner"></i>
                    </div>
                  </div>
                  <div v-else>
                    <UploadImages @changed="handleImages" :key="uploaderKey" />
                  </div>

                  <div class="imgsPreview" style="max-height: calc(100% - 300px); overflow: auto;">
                    <div class="imageHolder" v-for="(item, index) in library_images" :key="index">
                      <img :src="item.image_url" @click="selectImage(item.image_url)"><span class="delete" style="color: white;" @click="deleteImage(item.id)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="icon"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg></span>
                    </div>
                  </div>
                </div>

              </v-tab-item>
              <v-tab-item>
                <Unsplash />
              </v-tab-item>
            </v-tabs>

            <br />

            <br />
        
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import ColorPicker from '@/components/common/ColorPicker.vue'
import UploadImages from "vue-upload-drop-images"
import Unsplash from '@/components/Unsplash.vue'

export default {
  props: {
    plan: String,
    price: String
  },
  components: {
    ColorPicker,
    UploadImages,
    Unsplash
  },
  computed: {
    ...mapState({
      me: state => state.me.me
    }),
    swatchStyle() {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  data: function() {
    return {
      show_background_color: false,
      showModal: false,
      complete: false,
      subscribing: false,
      selected_theme: null,
      mask: '!#XXXXXXXX',
      menu: false,
      overlay_items: [
        'None',
        'Dark',
        'Light'
      ],
      themes: [],
      saving: false,
      file: null,
      uploading: false,

      showImageLibraryModal: false,
      uploaderKey: 0,
      browsedFiles: [],
      uploading: false,
      library_images: []
    }
  },
  methods: {
    show() {
      var self = this;

      setThemes();

      function setThemes() {
        if (!self.me) {
          setTimeout(function() {
            setThemes();
          }, 1000);
          return;
        }

        self.themes = [];

        for (var i in self.me.social_posting_themes) {
          self.themes.push({
            id: self.me.social_posting_themes[i].id,
            background_color: self.me.social_posting_themes[i].background_color,
            background_image: self.me.social_posting_themes[i].background_image,
            overlay_type: self.me.social_posting_themes[i].overlay_type,
            text_color: self.me.social_posting_themes[i].text_color
          });
        }
        self.selected_theme = self.themes[0];
      }
      this.showModal = true
    },
    hide() {
      this.showModal = false
    },
    async save() {
      this.saving = true

      try {
        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/save-posting-themes`, { themes: this.themes }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

        this.hide()
        this.$store.dispatch('me/fetch')
        this.saving = false
      }catch(e){
        console.log(e)
      }
    },

    selectTheme(theme) {
      this.selected_theme = theme;
    },

    async onUpload() {
      // console.log(this.file);
      if (this.file !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.selected_theme.background_image = response.data.url
        }catch(e){
          console.log(e)
        }

        this.file = null
        this.uploading = false
      }
    },

    handleImages(files){
      this.browsedFiles = files;
      console.log(files)
      this.uploadBrowsedImages();
        /*
          [
            {
                "name": "Screenshot from 2021-02-23 12-36-33.png",
                "size": 319775,
                "type": "image/png",
                "lastModified": 1614080193596
                ...
            },
            ...
            ]
          */
        // setTimeout(function() {
        //   self.uploaderKey++;
        // }, 2000);
    },

    uploadBrowsedImages() {
      let self = this;
      this.uploading = true;
      console.log('this.browsedFiles', this.browsedFiles);

      let fileKey = 0;
      
      processFile();

      function processFile() {
        let file = self.browsedFiles[fileKey];

        if (typeof(file) == "undefined") {
          self.uploading = false;
          self.fetchImages();
          return
        }

        let formData = new FormData()
        formData.append("file", file)
        formData.append("saveInLibrary", 1);

        axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + self.$store.state.auth.jwt
            }
        }).then(function(response) {
          console.log('response', response)
          fileKey++;
          processFile();
        });
      }
    },

    fetchImages() {
      let self = this
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/get-library-images`, {
          headers: {
            'Authorization': 'Bearer ' + self.$store.state.auth.jwt
          }
      }).then(function(response) {
        self.library_images = response.data.images;
      });
    },

    removeImage() {
      this.selected_theme.background_image = ''
    },

    selectImage(image_url) {
      this.selected_theme.background_image = image_url
      this.showImageLibraryModal = false
    },

    deleteImage(image_id) {
      let self = this
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/me/delete-library-image?id=` + image_id, {
          headers: {
            'Authorization': 'Bearer ' + self.$store.state.auth.jwt
          }
      }).then(function(response) {
        self.fetchImages();
      });
    },

    addTheme() {
      let new_theme = {
        background_color: "#FFFFFF",
        background_image: "",
        text_color: "#000000",
        overlay_type: "None",
        id: this.themes.length
      }

      this.themes.push(new_theme)
      this.selectTheme(new_theme)
    }

  },
  created() {
    this.fetchImages();
    // this.$store.dispatch('integrations/fetch')
  }
}
</script>

<style>
  .social-share-theme-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .social-share-theme-modal-overlay.is-light {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .social-share-theme-modal-overlay.is-dark {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .imgsPreview .imageHolder {
      width: 140px;
      height: 140px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      margin: 5px 5px;
      display: inline-block;
  }

  .imgsPreview .imageHolder img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
  }

  .imgsPreview .imageHolder .delete {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 29px;
      height: 29px;
      color: #fff;
      background: red;
      border-radius: 50%;
      cursor: pointer;
  }

  .imgsPreview .imageHolder .delete .icon {
      width: 66%;
      height: 66%;
      display: block;
      margin: 4px auto;
  }

  .v-tabs-items {
    height: 100% !important;
  }
</style>
