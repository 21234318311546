<template>

  <div v-if="contact_form" style="height: 100%;">
    <div :style="'width: 100%; height: 100%; position: relative; font-family: ' + contact_form.font_family.replace(' (Google)', '') + ';'">
        <div class="rgcf-header" :style="'background-color: ' + contact_form.header_background_color + '; color: ' + contact_form.header_text_color + '; text-align: ' + contact_form.header_alignment.toLowerCase() + ';'">
            <div class="rgcf-business-name">
                {{ contact_form.company_name }}
            </div>

            <div class="rgcf-subtitle">
                {{ contact_form.header_subtitle }}
            </div>
        </div>

        <div class="rgcf-body" :style="'background-color: ' + contact_form.background_color + '; color: ' + contact_form.text_color">
            <div class="rgcf-logo" v-if="contact_form.logo">
                <img :src="contact_form.logo" alt="" style="height: 60px;">
            </div>

            <div class="rgcf-contact-form" :style="'max-width: 500px;'">
                <div class="shadow p-6" v-if="selected_view == 0">
                    <h1 class="text-2xl mb-6 text-center font-semibold text-gray-700">{{ contact_form.form_title }}</h1>

                    <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                      {{success}}
                    </div>

                    <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                        <div v-html="error"> </div>
                    </div>

                    <div class="mb-6">
                        <p class="mb-1 text-xs mb-2"><strong>{{ contact_form.first_name_text }}</strong></p>
                        <input type="text" ref="first_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                    </div>

                    <div class="mb-6">
                        <p class="mb-1 text-xs mb-2"><strong>{{ contact_form.last_name_text }}</strong></p>
                        <input type="text" ref="last_name" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                    </div>

                    <div class="mb-6">
                        <p class="mb-1 text-xs mb-2"><strong>{{ contact_form.email_text }}</strong></p>
                        <input type="email" ref="email" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                    </div>

                    <div class="mb-6">
                        <p class="mb-1 text-xs mb-2"><strong>{{ contact_form.phone_number_text }}</strong></p>
                        <input type="text" ref="phone" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded">
                    </div>

                    <div v-for="(item, index) in contact_form.custom_fields" :key="index">
                        <div v-show="item.visible" class="mb-3">
                            <p class="mb-1 text-xs mb-2"><strong>
                                {{ item.name }}
                            </strong></p>
                            <input v-model="item.value" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" v-on:keyup.enter="save">
                        </div>
                    </div>

                    <div class="">

                        <button class="w-full rounded text-white focus:outline-none py-3 px-4" :class="{'spinner': addingContact}" @click="submit" :style="'background-color: ' + contact_form.button_background_color + '; color: ' + contact_form.button_text_color + ' !important;'">
                            {{ contact_form.add_contact_button }}
                        </button>

                    </div>
                </div>

                <div class="shadow p-6" v-if="selected_view == 1">
                    <div class="rgcf-confirmation-text">{{ contact_form.confirmation_text }}</div>
                </div>
            </div>
        </div>

        <div class="rgcf-footer" :style="'background-color: ' + contact_form.footer_background_color + '; color: ' + contact_form.footer_text_color + '; text-align: ' + contact_form.footer_alignment.toLowerCase() + ';'">

            <div class="rgcf-google-compliant-link-wrapper text-center" v-if="selected_view == 2 && contact_form.google_compliant_link_enabled">
                <a :href="contact_form.google_compliant_link" target="google-leave-review-link">{{ contact_form.google_compliant_link_text }}</a>
            </div>

            <div class="rgcf-footer-text" v-html="contact_form.footer_text"></div>
        </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import urlParser from 'url-parse'
import PanelNavigation from '@/components/PanelNavigation.vue'

import { VueEditor } from "vue2-editor";

export default {
  metaInfo () {
    return {
      title: "Contact Form Builder | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    VueEditor
  },
  data () {
    return {
        saved: false,
        file: null,

        edit_url: false,

        contact_form: null,

        selected_view: 0,

        widget: null,

        text: "",

	  contactFormLoaded: false,
	  font_family_values: [
		  "Open Sans (Google)",
		  "Source Sans Pro (Google)",
		  "Roboto (Google)",
		  "Lato (Google)",
		  "PT Sans (Google)",
		  "EB Garamond (Google)",
		  "Raleway (Google)",
		  "Ubuntu (Google)",
		  "Montserrat (Google)",
		  "Courgette (Google)",
		  "Arial",
		  "Helvetica",
		  "Sans-serif",
	  ],
	  align_values: [
		  "Left",
          "Center",
		  "Right"
	  ],

      customToolbar: [
            ["bold", "italic", "underline"],
            // [{ list: "ordered" }, { list: "bullet" }],
            ["image", "link"]
        ],

        addingContact: false,
        error: null,

        original_fields: []
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me
    })
  },
  methods: {
      async onUpload() {
      if (this.file !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

        //   let response2 = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/review-funnels/save-logo`, {
        //     logo: response.data.url
        //   }, {
        //     headers: {
        //       'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        //     }
        //   })

          this.contact_form.logo = response.data.url

        }catch(e){
        }

        this.file = null
        this.uploading = false
      }
    },

    async submit() {
      this.addingContact = true
      this.error = null
      this.success = null

      try {

        let formData = new FormData();
        // formData.append("name", this.name);
        formData.append("first_name", this.$refs.first_name.value);
        formData.append("last_name", this.$refs.last_name.value);
        formData.append("email", this.$refs.email.value);
        formData.append("phone", this.$refs.phone.value);
        formData.append("uuid", this.contact_form.user.uuid);

        formData.append("custom_fields", JSON.stringify(this.contact_form.custom_fields));

        await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/contacts/add-contact-externally`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
              'Content-Type': 'multipart/form-data'
            }
        })

        // this.selected_view = 1

        this.contact_form.custom_fields = JSON.parse(JSON.stringify(this.original_fields));

        this.$refs.first_name.value = '';
        this.$refs.last_name.value = '';
        this.$refs.email.value = '';
        this.$refs.phone.value = '';

        this.success = 'Contact successfully added!';

      } catch (e) {
        this.error = e.response.data.message
      }
      this.addingContact = false

    },

    setCustomCSS() {
        // Apply custom CSS
        if (this.contact_form.custom_css) {
            if (document.getElementById("rgcf-custom-css-sheet") === null) {
                var styleSheet = document.createElement("style")
                styleSheet.type = "text/css"
                styleSheet.innerText = this.contact_form.custom_css.replace(/\n/g, " ")
                styleSheet.id = 'rgcf-custom-css-sheet';
                document.body.appendChild(styleSheet);
            }
            else {
                document.getElementById("rgcf-custom-css-sheet").innerText = this.contact_form.custom_css.replace(/\n/g, " ");
            }
        }
        else {
            if (document.getElementById("rgcf-custom-css-sheet") === null) {
                var styleSheet = document.createElement("style")
                styleSheet.type = "text/css"
                styleSheet.innerText = ""
                styleSheet.id = 'rgcf-custom-css-sheet';
                document.body.appendChild(styleSheet);
            }
            else {
                document.getElementById("rgcf-custom-css-sheet").innerText = '';
            }
        }
        // END Apply custom CSS
    },
    
	getContactForm: async function() {
        let self = this;

        try {
          let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/contacts/get-form-by-uuid/' + this.$route.params.uuid, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          },
          })
          
            this.contact_form = response.data.contact_form

            this.original_fields = JSON.parse(JSON.stringify(this.contact_form.custom_fields))
        }catch(e){
            console.log(e);
        }

        this.contactFormLoaded = true;
    },
  },
  created() {
    this.getContactForm()
  },
	watch: {
		'contact_form.custom_css': {
			handler() {
				this.setCustomCSS()
			}
		}
	}
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Open+Sans&family=Source+Sans+Pro&family=Roboto&family=Lato&family=PT+Sans&family=EB+Garamond&family=Raleway&family=Ubuntu&family=Montserrat&family=Courgette');
  
  a {
      text-decoration: none !important;
  }

  #editor1 {
      height: 120px;
  }

  .rgcf-header {
      width: 100%;
      min-height: 80px;
      padding: 20px;
  }

  .rgcf-footer {
      /* margin-top: 30px; */
      width: 100%;
      min-height: 60px;
      background-color: #f5f5f5;
      padding: 20px;
  }

  .rgcf-footer-text {
      font-size: 15px;
  }

  .rgcf-business-name {
      font-size: 20px;
  }

  .rgcf-subtitle {
      padding-top: 10px;
      font-size: 14px;
  }

  .rgcf-body {
      /* background-color: #f5f5f5; */
      width: 100%;
      /* max-width: 1200px; */
      margin: auto;
      padding: 20px;
      min-height: calc(100% - 165px);
  }

  .rgcf-contact-form {
      width: 100%;
      max-width: 800px;
      margin: auto;
      padding-top: 40px;
      padding-bottom: 40px;
  }

  .rgcf-confirmation-text {
      padding: 30px;
      text-align: center;
  }

  .rgcf-body .rgcf-logo {
      padding-top: 20px;
      padding-bottom: 10px;
      text-align: center;
  }

  .rgcf-body .rgcf-logo img {
      display: initial;
  }
</style>