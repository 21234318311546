<template>

    <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/2 lg:w-2/5 bg-white rounded-lg mx-6">
  
        <div class="rg-modal-header">
          Add Review
  
          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
        </div>
  
        <div class="p-6">
  
          <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
            {{success}}
          </div>
  
          <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
            <div v-html="error"></div>
          </div>
  
          <div class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                    Reviewer Name
                </strong></p>
                <input v-model="review.name" placeholder="e.g. John Doe" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text">
          </div>

          <div class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                    Profile Picture
                </strong></p>
                
                <v-file-input
                    accept="image/*"
                    v-model="file"
                    @change="onUpload"
                    class="rg-uploader"
                    prepend-icon="mdi-camera"
                    outlined
                    hide-input
                ></v-file-input>

                <div v-if="review.profile_picture" style="padding-top: 0px;">
                    <span v-if="review.profile_picture" style="text-align: center;">
                    <img :src="review.profile_picture" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                    <v-btn
                        depressed
                        color="error"
                        icon
                        small
                        @click="review.profile_picture = ''; removeLogo()"
                    >
                        <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                    </v-btn>
                    </span>
                </div>

                <i v-if="uploading" class="fa fa-spin fa-spinner" style=""></i>
          </div>
  
          <!-- <p class="text-xs text-gray-700 mb-4">Your contact's name.</p> -->
  
          <div class="mb-3">
            <p class="mb-1 text-xs mb-2"><strong>
              Star Rating
            </strong></p>
            <v-rating size="30" v-model="review.rating_value" color="amber"></v-rating>
          </div>
  
          <!-- <p class="text-xs text-gray-700 mb-4">Your contact's email.</p> -->
  
          <div class="mb-3">
            <p class="mb-1 text-xs mb-2"><strong>
              Content
            </strong></p>
            <textarea name="" id="" rows="10" v-model="review.review_text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" maxlength="1000" style="border-style: solid; height: 150px;"></textarea>
          </div>
  
            <div class="mb-3">
                <p class="mb-1 text-xs mb-2"><strong>
                Date
                </strong></p>
                
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="review.date"
                        append-icon="mdi-calendar-blank-outline"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        style="background: #FFFFFF; border: 0px !important;"
                        hide-details
                        placholder="Choose Date"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="review.date"
                    @input="menu = false"
                    >
                    </v-date-picker>
                </v-menu>
            </div>
  
          <!-- <p class="text-xs text-gray-700 mb-4">Your contact's phone number.</p> -->
  
        </div>
  
        <div class="bg-gray-100 rounded-b-lg px-6 py-4">
  
          <button class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': saving}" @click="save">
              SAVE
          </button>
  
          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
              CANCEL
          </button>
  
          <div class="clearfix"></div>
  
        </div>
  
      </div>
    </div>
  
  </template>
  
  <script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  
  export default {
    data: function() {
      return {
        showModal: false,
        saving: false,
        success: null,
        error: null,

        review: {
            name: "",
            rating_value: 5,
            review_text: "",
            date: "",
            profile_picture: ""
        },
        
        file: null,
        uploading: false,

        menu: false,
      }
    },
    methods: {
      show() {
        this.showModal = true
      },
      hide() {
        this.showModal = false
        this.saving = false
      },
      async save() {
        this.saving = true
        this.error = null
        try {

          await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/reviews/add-review`, this.review, {
              headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt
              }
          })
  
          //this.hide()
          this.hide()
          // this.$store.state.contacts.fetchingContacts = true
          // this.$store.dispatch('reviews/fetch')
          this.$parent.$parent.refreshReviews();
          this.error = null

            this.review = {
                name: "",
                rating_value: 5,
                review_text: "",
                date: "",
                profile_picture: ""
            };
  
        } catch (e) {
            console.log(e.response.data);
          this.error = e.response.data.message
        }
        this.saving = false
      },

      async onUpload() {
        // console.log(this.file);
        if (this.file !== null) {
            this.uploading = true
            let formData = new FormData()
            formData.append("file", this.file)

            try {
            let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
                headers: {
                'Authorization': 'Bearer ' + this.$store.state.auth.jwt
                }
            })

            this.review.profile_picture = response.data.url;

            }catch(e){
            console.log(e)
            }

            this.file = null
            this.uploading = false
        }
        },
    }
  }
  </script>