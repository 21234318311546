<template>

  <panel-navigation>

    <dialog-applet-form ref="dialogAppletForm"></dialog-applet-form>

    <div class="flex flex-row items-top justify-between">
      <h1 class="text-lg font-semibold mb-3">Settings</h1>
    </div>
    
    <hr class="border-b border-gray-100 mb-3">

    <div class="dashboard-box" v-if="me">
        <v-card
          class="pa-2"
          tile
        >
          <div class="p-3 pt-1">

            <v-tabs>
              <v-tab>Main Settings</v-tab>
              <v-tab v-show="!$agency.settings.is_omni" v-if="!onboarding && ((me.subscription && me.subscription.product_details && me.subscription.product_details.limits.sms_enabled == 1) || ($agency && $agency.settings.allow_business_to_modify_sms_from_number == 1))">Integrations</v-tab>
              <v-tab v-show="!$agency.settings.is_omni">Contacts</v-tab>
              <v-tab v-show="!$agency.settings.is_omni" v-if="!onboarding">Invite Campaign</v-tab>
              <v-tab v-show="!$agency.settings.is_omni">Notifications</v-tab>
              <v-tab>SMS Templates</v-tab>
              <v-tab>Email Templates</v-tab>

              <v-tab-item class="pt-5">
                <div class="flex flex-row flex-wrap -mx-3">

                  <div class="w-full sm:w-1/3 mb-4 px-3">
                    <div class="mt-4 mb-4">
                      <h2 class="font-bold text-gray-800">Logo</h2>
                    </div>
                    <div class="w-full">
                      <v-file-input
                          accept="image/*"
                          v-model="file"
                          @change="onUpload"
                          class="rg-uploader"
                          prepend-icon="mdi-camera"
                          outlined
                          hide-input
                        ></v-file-input>

                      <div v-if="me.logo" style="padding-top: 0px;">
                        <span v-if="me.logo" style="text-align: center;">
                          <img :src="me.logo" alt="" style="display: inline-block; max-height: 40px; margin-right: 20px; border: solid 1px #CCCCCC; padding: 2px;">
                          <v-btn
                            depressed
                            color="error"
                            icon
                            small
                            @click="me.logo = ''; removeLogo()"
                          >
                            <i class="mdi mdi-trash-can-outline" style="font-size: 20px;"></i>
                          </v-btn>
                        </span>
                      </div>

                      <div class="text-sm mb-1 text-gray-700 mt-2">
                        Your logo will be displayed in emails.
                      </div>
                      
                    </div>
                  </div>

                  <div class="w-full sm:w-2/3 mb-4 px-3">

                    <div class="w-full pt-10">
                      <p class="mb-1 text-xs mb-2"><strong>Business Name</strong></p>
                      <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.company_name" />
                    </div>

                    <div class="w-full mt-4">
                      <p class="mb-1 text-xs mb-2"><strong>Email From Name</strong></p>
                      <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.settings.from_name" />
                    </div>

                    <div class="w-full mt-4">
                      <p class="mb-1 text-xs mb-2"><strong>Date Format</strong></p>
                      
                      <v-select
                            v-model="me.settings.date_format"
                            outlined
                            dense
                            hide-details
                            :items="date_format_items"
                            class="-mt-2"
                      ></v-select>
                    </div>

                    <!-- <div class="w-full lg:w-1/2 mt-3">
                      <p class="mb-1 text-xs mb-2"><strong>Timezone (coming soon)</strong></p>
                      <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" disabled placeholder="America/New_York" />
                    </div> -->
                  </div>

                </div>

                <hr class="mt-3" />

                <div class="float-right mt-6">
                  <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': saving}" @click="save">Save Changes</div>
                </div>
              </v-tab-item>

              <v-tab-item v-if="!onboarding && ((me.subscription && me.subscription.product_details && me.subscription.product_details.limits.sms_enabled == 1) || ($agency && $agency.settings.allow_business_to_modify_sms_from_number == 1))">
                  <div class="pt-5">

                    <div v-if="me.subscription && me.subscription.product_details && me.subscription.product_details.own_twilio == 1">
                      <div class="mb-4 mt-4">
                        <h2 class="font-bold text-gray-800">Twilio Settings</h2>
                      </div>

                      <div class="flex flex-row flex-wrap">
                        <div class="w-full lg:w-1/3 pr-3">
                          <p class="mb-1 text-xs mb-2"><strong>Account SID</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"
                          v-model="me.integrations.twilio.settings.account_sid"  />
                        </div>
                        <div class="w-full lg:w-1/3 px-3" style="position: relative;">
                          <p class="mb-1 text-xs mb-2"><strong>Auth Token</strong></p>
                          <input :type="((show_auth_token) ? 'text' : 'password')" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded"  v-model="me.integrations.twilio.settings.auth_token" style="padding-right: 35px !important;" />

                          <a href="javascript: void(0);" @click="show_auth_token = !show_auth_token" class="token_password_toggle">
                            <i v-if="show_auth_token" class="fa fa-eye"></i>
                            <i v-if="!show_auth_token" class="fa fa-eye-slash"></i>
                          </a>
                        </div>

                          <div class="w-full lg:w-1/3">

                            <p v-if="me.integrations.twilio.status == 1" class="mb-1 text-xs mb-2"><strong>Outgoing SMS Phone Number</strong></p>
                            
                            <v-select v-if="me.integrations.twilio.status == 1 && !gettingTwilioNumbers"
                                  v-model="me.integrations.twilio.settings.phone_number"
                                  outlined
                                  dense
                                  hide-details
                                  :items="me.integrations.twilio.settings.numbers"
                                  class="-mt-2"
                              ></v-select>

                            <div v-if="me.integrations.twilio.status == 1 && me.integrations.twilio.settings.numbers.length == 0 && !gettingTwilioNumbers" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4 mt-1" role="warning">
                              <div>Please purchase a phone number.</div>
                            </div>

                              <i v-if="gettingTwilioNumbers" class="fa fa-spin fa-spinner" style="font-size: 14px;"></i>
                          </div>
                        <div class="w-full float-right mt-6">
                            <div v-if="errorTwilio" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                              <div v-html="errorTwilio"> </div>
                            </div>
                            <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingTwilio}" @click="saveTwilioSettings">Save Changes</div>
                        </div>
                      </div>

                      <hr class="mt-3" />

                    </div>

                    <div v-else>
                      <div v-if="$agency && $agency.settings.allow_business_to_modify_sms_from_number == 1">
                        <div class="mb-4">
                          <h2 class="font-bold text-gray-800">SMS Settings</h2>
                        </div>
                        <div class="w-full">
                          <p class="mb-1 text-xs mb-2"><strong>Phone Number</strong></p>
                          <!-- <p>Select a number to send SMS to your contacts from</p> -->

                          <v-alert
                            color="blue-grey"
                            dark
                            icon="mdi-clock-fast"
                            text
                            v-if="$agency && !$agency.integrations.twilio.settings.numbers"
                          >
                            There are no phone numbers available. Please contact your account owner.
                          </v-alert>

                          <v-select v-else
                              v-model="me.sms_phone_number"
                              flat
                              solo-inverted
                              hide-details
                              :items="$agency.integrations.twilio.settings.numbers"
                              label="Choose Number"
                          ></v-select>
                          
                        </div>

                        <hr class="mt-3" />

                        <div class="float-right mt-6">
                          <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingTwilio}" @click="save">Save Changes</div>
                        </div>

                      </div>
                    </div>

                    <div v-if="me.subscription && me.subscription.product_details && me.subscription.product_details.own_smtp == 1">
                      <div class="mb-4 mt-4">
                        <h2 class="font-bold text-gray-800">Email Settings</h2>
                      </div>

                      <div class="flex flex-row flex-wrap">
                        <div class="w-full lg:w-1/3 pr-3">
                          <p class="mb-1 text-xs mb-2"><strong>From Name</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.from_name"  />
                        </div>
                        <div class="w-full lg:w-1/3 pl-3">
                          <p class="mb-1 text-xs mb-2"><strong>From Email</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.from_email"  />
                        </div>
                        <div class="w-full lg:w-1/3 px-3">
                          <p class="mb-1 text-xs mb-2"><strong>Username</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.username"  />
                        </div>
                        <div class="w-full lg:w-1/3 mt-3" style="position: relative;">
                          <p class="mb-1 text-xs mb-2"><strong>Password</strong></p>
                          <input :type="((show_smtp_password) ? 'text' : 'password')" data-lpignore="true" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.password" style="padding-right: 35px !important;" />

                          <a href="javascript: void(0);" @click="show_smtp_password = !show_smtp_password" class="token_password_toggle">
                            <i v-if="show_smtp_password" class="fa fa-eye"></i>
                            <i v-if="!show_smtp_password" class="fa fa-eye-slash"></i>
                          </a>
                        </div>
                        <div class="w-full lg:w-1/3 mt-3 px-3">
                          <p class="mb-1 text-xs mb-2"><strong>Host</strong></p>
                          <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.host"  />
                        </div>
                        <div class="w-full lg:w-1/3 pr-3">
                          <div class="flex flex-row flex-wrap">
                            <div class="w-full lg:w-1/2 mt-3 pl-3">
                              <p class="mb-1 text-xs mb-2"><strong>Port</strong></p>
                              <input type="text" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" v-model="me.integrations.smtp.settings.port"  />
                            </div>
                            <div class="w-full lg:w-1/2 mt-3 px-3 pl-10">
                              <p class="mb-1 text-xs mb-2"><strong>SSL/TLS</strong></p>

                              <v-checkbox
                                class="mt-4"
                                label="Enabled"
                                value="yes"
                                hide-details
                                v-model="me.integrations.smtp.settings.ssl_tls"
                                 :ripple="false"
                              ></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="w-full mt-3">
                          <div class="bg-blue text-white inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': savingSMTP}" @click="saveSMTPSettings()">Save</div>

                          <v-chip
                            v-if="me.integrations.smtp.status == 1"
                            class="ma-2"
                            color="green"
                            outlined
                            @click="saved = false"
                          >
                            <v-icon left>
                              mdi-check
                            </v-icon>
                            Valid
                          </v-chip>

                          <!-- <div v-if="me.integrations.smtp.status == 1" class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer mt-2 ml-2  float-right" @click="$refs.dialogSendTestEmail.show()"><i class="fa fa-envelope"></i> Send Test Email</div> -->
                        </div>
                        <div class="w-full mt-3 pl-3">
                          <div v-if="errorSMTP" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 mt-1" role="alert">
                            <div v-html="errorSMTP"> </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </v-tab-item>

              <v-tab-item class="pt-5">
                <div class="w-full sm:w-2/3 mb-4 px-3">
                  <div class="">

                    <div class="">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          <p class="mb-1 text-xs mb-2"><strong>
                              Auto invite for review request
                          </strong></p>
                      </label>

                      <div class="text-xs text-gray-600 mb-2">This setting, when enabled, will automatically start the default reviews campaign for a contact as soon as they are created. </div>

                      <v-switch color="#48bb78" inset dense hide-details :ripple="false" v-model="me.settings.auto_invite_contacts"></v-switch>
                    </div>

                    <div class="mt-8">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          <p class="mb-1 text-xs mb-2"><strong>
                              Allow campaign re-entry
                          </strong></p>
                      </label>

                      <div class="text-xs text-gray-600">Allows a contact to re-enter once it has completed a campaign. If the contact attempts to re-enter while it is still in progress in a campaign, it will get skipped. </div>

                      <v-radio-group
                          class="mt-2"
                          row
                          v-model="me.settings.reinvite_contacts"
                          >
                              <v-radio
                              label="Yes"
                              :value="1"
                              on-icon="mdi-circle"
                              off-icon="mdi-circle-outline"
                              :ripple="false"
                              ></v-radio>
                              <v-radio
                              label="No"
                              :value="0"
                              on-icon="mdi-circle"
                              off-icon="mdi-circle-outline"
                              :ripple="false"
                              ></v-radio>
                          </v-radio-group>
                    </div>

                    <div v-show="me.settings.reinvite_contacts == 1 && 0" class="mb-3">
                        <v-text-field class="auto-posting-frequency" style="width: 120px; margin-top: -30px;"
                            v-model="me.settings.reinvite_contacts_days"
                            type="number"
                            prefix="Wait"
                            suffix="days"
                            hide-details
                            single-line
                            min="1"
                        ></v-text-field>
                        
                        <div class="text-xs text-gray-600" style="padding-top: 10px;">
                            (since last review request to ask contact for another review)
                        </div>
                    </div>

                    <div class="mt-4">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            <p class="mb-1 text-xs mb-2"><strong>
                                When would you like to stop the invite campaign for a contact?
                            </strong></p>
                        </label>

                        <v-radio-group
                            class="mt-2"
                            row
                            v-model="me.settings.when_to_stop_invite_campaign"
                            >
                            <v-radio
                            label="After they click the link in the email/sms"
                            :value="'link_in_email'"
                            on-icon="mdi-circle"
                            off-icon="mdi-circle-outline"
                            :ripple="false"
                            ></v-radio>
                            <br />
                            <v-radio
                            label="After they leave a feedback or click a review link"
                            :value="'review_link'"
                            on-icon="mdi-circle"
                            off-icon="mdi-circle-outline"
                            :ripple="false"
                            ></v-radio>
                        </v-radio-group>
                      
                    </div>
                    
                  </div>
                </div>

                <hr class="mt-3" />

                <div class="float-right mt-6">
                  <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': saving}" @click="save">Save Changes</div>
                </div>
              </v-tab-item>

              <v-tab-item v-if="!onboarding" class="pt-5">
                <div class="mb-4">
                  <h2 class="font-bold text-gray-800">Invite Campaign</h2>
                </div>

                <div class="dashboard-box">
                  <v-card
                    class="pa-2"
                    tile
                  >
                    <div class="p-3">

                      <br /><br /><br /><br />

                      <div class="dashboard-box-iconsquare">
                        <div class="dashboard-box-icon-background"></div>
                        <div class="dashboard-box-icon">
                          <i class="mdi mdi-file-document-multiple-outline"></i>
                        </div>
                      </div>

                      <div style="text-align: center;">
                        <div class="pt-2 pb-2">
                          <strong>This section was moved <router-link :to="'/contacts/campaigns'">here</router-link></strong>
                        </div>

                        <div class="text-sm mb-4 text-gray-600">
                          You can now create multiple campaigns and campaigns that can be used for other purposes, other than inviting for reviews.
                        </div>

                        <br />

                        <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 inline-block rounded cursor-pointer" @click="$router.push('/contacts/campaigns')" style="margin-top: -5px;"><i class="fa fa-plus mr-2"></i> GO TO CAMPAIGNS</div>
                      </div>
                      
                      <br /><br /><br /><br /><br />
                    </div>
                  </v-card>
                </div>
              </v-tab-item>

              <v-tab-item class="pt-5">
                <div class="mb-4">
                  <h2 class="font-bold text-gray-800">Notifications</h2>
                </div>

                <p class="mb-1 text-xs mb-2"><strong>Enter email addresses to receive notifications</strong></p>

                <v-combobox
                  v-model="me.settings.notifications_emails"
                  multiple
                  small-chips
                  color="blue"
                  outlined
                  dense
                ></v-combobox>

                <hr class="mt-3" />

                <div class="float-right mt-6">
                  <div class="bg-blue text-white text-sm inline-block rounded py-2 px-3 cursor-pointer" :class="{'spinner': saving}" @click="save">Save Changes</div>
                </div>
              </v-tab-item>

              <v-tab-item>
                <Templates :template_type="'sms'" />
              </v-tab-item>

              <v-tab-item>
                <Templates :template_type="'email'" />
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card>

    </div>



  </panel-navigation>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import PanelNavigation from '@/components/PanelNavigation.vue'
import DialogAppletForm from '@/components/dialogs/get-reviews/dialogAppletForm.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import Templates from '@/views/panel/Templates.vue'

export default {
  props: ['onboarding'],
  metaInfo () {
    return {
      title: "Settings | " + this.$appName
    }
  },
  components: {
    PanelNavigation,
    ToggleButton,
    DialogAppletForm,
    Templates
  },
  data() {
    return {
      userSegmentation: false,
      panel: [0],
      saving: false,
      error: null,
      getReviewsCampaignLoaded: false,
      getReviewsCampaign: null,
      file: null,

      savingTwilio: false,
      errorTwilio: null,
      gettingTwilioNumbers: false,
      show_auth_token: false,

      savingSMTP: false,
      errorSMTP: null,
      show_smtp_password: false,

      date_format_items: [
        'DD/MM/YYYY',
        'MM/DD/YYYY',
        'YYYY/MM/DD'
      ]
    }
  },
  methods: {
    async save() {

      this.saving = true
      this.error = null

      try {
        await this.$store.dispatch('me/save', {
          company_name: this.me.company_name.trim(),
          templateEmail1: this.me.templateEmail1.trim(),
          templateSMS1: this.me.templateSMS1.trim(),
          templateEmail2: this.me.templateEmail2.trim(),
          templateSMS2: this.me.templateSMS2.trim(),
          templateEmail3: this.me.templateEmail3.trim(),
          templateSMS3: this.me.templateSMS3.trim(),
          userSegmentation: this.me.userSegmentation,
          sms_phone_number: this.me.sms_phone_number,
          auto_invite_contacts: ((this.me.settings.auto_invite_contacts) ? 1 : 0),
          reinvite_contacts: ((this.me.settings.reinvite_contacts) ? 1 : 0),
          reinvite_contacts_days: this.me.settings.reinvite_contacts_days,
          when_to_stop_invite_campaign: this.me.settings.when_to_stop_invite_campaign,
          from_name: ((this.me.settings.from_name) ? this.me.settings.from_name : "_EMPTY_"),
          logo: ((this.me.logo) ? this.me.logo : "_EMPTY_"),
          notifications_emails: ((this.me.settings.notifications_emails) ? this.me.settings.notifications_emails : "_EMPTY_"),
          date_format: this.me.settings.date_format
        })
        this.$store.dispatch('me/fetch')
      }catch(error){
        console.log(error)
        this.error = error
      }

      this.saving = false
    },

    async onUpload() {
      // console.log(this.file);
      if (this.file !== null) {
        this.uploading = true
        let formData = new FormData()
        formData.append("file", this.file)

        try {
          let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/upload-image`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt
            }
          })

          this.me.logo = response.data.url

        }catch(e){
          console.log(e)
        }

        this.file = null
        this.uploading = false
      }
    },

    async removeLogo() {
      this.me.logo = '';
    },

    async saveTwilioSettings() {

      this.savingTwilio = true
      this.errorTwilio = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-twilio-settings`, {
        account_sid: this.me.integrations.twilio.settings.account_sid,
        auth_token: this.me.integrations.twilio.settings.auth_token,
        phone_number: this.me.integrations.twilio.settings.phone_number || null
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.savingTwilio = false
        this.me.integrations.twilio.status = response.data.integration.status;

        if (response.data.integration.status == 0)
          this.errorTwilio = "Your Twilio credentials are invalid."
        else {
          // if (this.me.integrations.twilio.settings.numbers.length == 0)
          this.getTwilioNumbers()
        }
      }
      else {
        this.errorTwilio = response.data.error
      }

      this.savingTwilio = false

    },

    

    async saveSMTPSettings() {

      this.savingSMTP = true
      this.errorSMTP = null

      let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/save-smtp-settings`, {
        from_name: this.me.integrations.smtp.settings.from_name,
        from_email: this.me.integrations.smtp.settings.from_email,
        host: this.me.integrations.smtp.settings.host,
        port: this.me.integrations.smtp.settings.port,
        username: this.me.integrations.smtp.settings.username,
        password: this.me.integrations.smtp.settings.password,
        ssl_tls: this.me.integrations.smtp.settings.ssl_tls
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.savingSMTP = false
        this.me.integrations.smtp = response.data.integration;

        if (response.data.integration.status == 0)
          this.errorSMTP = "Your SMTP settings are invalid."
      }
      else {
        this.errorSMTP = "Your SMTP settings are invalid."
      }

      this.savingSMTP = false

    },

    async getTwilioNumbers() {

      this.gettingTwilioNumbers = true

      let response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/agency/get-twilio-numbers`, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.jwt
        }
      })

      if (response.data.success) {
        this.gettingTwilioNumbers = false
        this.me.integrations.twilio.settings.numbers = response.data.integration.settings.numbers;
        this.me.integrations.twilio.settings.phone_number = response.data.integration.settings.phone_number;
      }

      this.gettingTwilioNumbers = false

    },

    async getGetReviewsCampaign() {
      this.getReviewsCampaignLoaded = false;
      
      try {
        let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/get-get-reviews-campaign', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })
        this.getReviewsCampaignLoaded = true
        this.getReviewsCampaign = response.data.campaign

        console.log('this.getReviewsCampaign', this.getReviewsCampaign)
      }catch(e){
        console.log(e)
      }
    },
    
    getIcon(item) {
      if (item.type == 'email')
        return 'fa fa-envelope';
      
      if (item.type == 'sms')
        return 'fa fa-sms';
      
      if (item.type == 'delay')
        return 'fa fa-clock';
    },
    
    getColor(item) {
      if (item.type == 'email')
        return 'primary lighten-1';
      
      if (item.type == 'sms')
        return 'green lighten-1';
      
      if (item.type == 'delay')
        return 'blue-grey lighten-4';
    },
    
    getTextColor(item) {
      if (item.type == 'email')
        return 'white--text';
      
      if (item.type == 'sms')
        return 'white--text';
      
      if (item.type == 'delay')
        return 'black--text';
    },

    editApplet(index, item) {
      console.log('item.settings.email_type', item.settings.email_type);
      if (!item.settings.email_type)
        item.settings.email_type = 'html';

      this.$refs.dialogAppletForm.index = index
      this.$refs.dialogAppletForm.mode = 'edit'
      this.$refs.dialogAppletForm.item = item

      this.$refs.dialogAppletForm.show()
    },

    addApplet(index, type) {
      console.log('index', index)
      this.$refs.dialogAppletForm.index = index
      this.$refs.dialogAppletForm.mode = 'add'
      let self = this;

      switch (type) {
        case 'email':
          self.$refs.dialogAppletForm.item = {
            type: 'email',
            title: '',
            settings: {
              subject: "Feedback for [business_name]",
              body: "Thank you for using [business_name]! We really appreciate your business and truly value you as a customer.\
\n\n\
Customer satisfaction is extremely important to us and we'd love to hear your feedback on how you feel about your recent service! If you could take a few minutes to leave a review for us, we'd really appreciate it.\
\n\n\
To submit your review, simply click below and let us know what you think!\
\n\n\
<a href='[link]'>[link]</a>\
\n\n\
Thanks in advance for taking the time to leave a review!\
\n\n\
Sincerely,\n\
Your [business_name] team",
            email_type: 'html'
            }
          }
        break;

        case 'sms':
          self.$refs.dialogAppletForm.item = {
            type: 'sms',
            title: '',
            settings: {
              body: "Thank you for using [business_name]! Customer satisfaction is extremely important to us and we'd love to hear your feedback. [link]"
            }
          }
        break;

        case 'delay':
          self.$refs.dialogAppletForm.item = {
            type: 'delay',
            title: '',
            settings: {
              delay_type: 'for',
              delay_number: 1,
              delay_unit: 'hour',
              delay_hour_of_day: "9",
              delay_timezone: "America/New_York"
            }
          }
        break;
      };
      this.$refs.dialogAppletForm.show()
    },

    async removeApplet(index) {
      await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/me/delete-get-reviews-campaign-item`, {
          index: index
      }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
      })

      this.getGetReviewsCampaign();
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me
    })
  },
  created() {
    // this.$store.dispatch('me/fetch')

    this.getGetReviewsCampaign();
  }
}
</script>

<style scoped>
.setting-header {
  background-color: #fbfbfb;
}
.setting-body {
  background-color: #fdfdfd;
}

input, textarea {
  border-style: solid;
}

.v-icon.v-icon {
  font-size: 14px !important;
}

.remove-applet-btn {
  background: gray;
  border: solid 1px #FFFFFF;
  border-radius: 50%;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  right: -8px;
  top: -8px;
  z-index: 10;
}

.v-timeline-item {
  padding-bottom: 35px;
}

.v-timeline-item .v-alert {
  cursor: pointer;
}

.v-timeline-item .remove-applet-btn {
  display: none;
}

.v-timeline-item:hover .remove-applet-btn {
  display: block;
}

.add-applet-btn {
  position:absolute;
  bottom: 30 px;
}

.v-timeline .v-timeline-item:nth-child(odd) .add-applet-btn {
  left: -72px;
}

.v-timeline .v-timeline-item:nth-child(even) .add-applet-btn {
  right: -72px;
}
.token_password_toggle {
  position: absolute;
  margin-top: -30px;
  right: 20px;
}
</style>

