import { render, staticRenderFns } from "./Badge17.vue?vue&type=template&id=4743974a&scoped=true"
import script from "./Badge17.vue?vue&type=script&lang=js"
export * from "./Badge17.vue?vue&type=script&lang=js"
import style0 from "./Badge17.vue?vue&type=style&index=0&id=4743974a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4743974a",
  null
  
)

export default component.exports