<template>

  <div v-if="showModal" @click.self="hide" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-2/5 bg-white rounded-lg mx-6" style="max-height: 100%;">

      <div class="rg-modal-header">
        <span>Setup A2P Campaign</span>

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="hide"><i class="fa fa-times"></i></a>
      </div>

      <div class="p-6" style="height: calc(100% - 110px); overflow: auto;">
        <div v-if="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert" v-html="success"></div>
        <div v-else>
	        <div v-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
	          <div v-html="error"></div>
	        </div>

	        <p style="font-size: 12px;">
	        	A Campaign represents your messaging use case with Twilio, for example, sending account notifications or conducting marketing. Every campaign is connected to a registered A2P Brand and a Messaging Service. Once successfully registered, you will be able to associate your 10DLC numbers with the connected Messaging Service to avail A2P Messaging benefits and avoid any carrier penalties for unregistered messaging.

	        	<br /><br />
	        	After having success with A2P approval, we built this pre-approved campaign for you ready to use. You are able to change any information, but is is optional.
	        </p>

	        <div class="mb-3">
	          <p class="mb-1 text-xs mb-2"><strong>A2P Use Case</strong></p>
	          <v-select
	            :items="['Low Volume Mixed']"
	            label=""
	            hide-details
	            value="Low Volume Mixed"
	            class="-mt-2"
	            dense
	            outlined
	          ></v-select>
	        </div>

	        <div class="mb-3">
	          <p class="mb-1 text-xs mb-2"><strong>Messaging Service</strong></p>
	          <v-select
	            :items="['Create new Messaging Service', 'Use existing Messaging service']"
	            label=""
	            hide-details
	            v-model="messaging_service_type"
	            class="-mt-2"
	            dense
	            outlined
	          ></v-select>
	        </div>

	        <div v-show="messaging_service_type == 'Use existing Messaging service'" class="mb-3">
	          <p class="mb-1 text-xs mb-2"><strong>Messaging Service ID</strong></p>
	          <input name="text" v-model="existing_messaging_service_id" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" />

	          <p class="text-xs text-gray-500 mt-2">If you already have Campaign Registration submitted manually, Please provide us Message Service ID to sync Data from Twilio.</p>
	        </div>

	        <div class="mb-3">
	          <p class="mb-1 text-xs mb-2"><strong>Campaign Description</strong></p>
	          
	          <textarea name="text" v-model="campaign_description" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="height: 60px;"></textarea>

	          <p class="text-xs text-gray-500 mt-2">Describe what you will be using this campaign for.</p>
	        </div>

	        <div class="mb-3">
	          <p class="mb-1 text-xs mb-2"><strong>Sample message #1</strong></p>
	          
	          <textarea name="text" v-model="sample_message_1" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="height: 60px;"></textarea>

	          <p class="text-xs text-gray-500 mt-2">Provide an example of a message that you will be sending with this campaign. This message sample will be used by carriers to help identify your traffic.</p>
	        </div>

	        <div class="mb-3">
	          <p class="mb-1 text-xs mb-2"><strong>Sample message #2</strong></p>
	          
	          <textarea name="text" v-model="sample_message_2" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="height: 60px;"></textarea>

	          <p class="text-xs text-gray-500 mt-2">Provide an example of a message that you will be sending with this campaign. This message sample will be used by carriers to help identify your traffic.</p>
	        </div>

		      <div class="w-full">
		        <p class="mb-1 text-xs mb-2"><strong>Message contents</strong></p>
		        
		        <v-checkbox
		           :ripple="false"
		          v-model="message_contains_links"
		          label="Messages will include embedded links."
		          hide-details
		        ></v-checkbox>
		        
		        <v-checkbox
		           :ripple="false"
		          v-model="message_contains_phone_numbers"
		          label="Messages will include phone numbers."
		          hide-details
		        ></v-checkbox>
		      </div>

	        <div class="mb-3 mt-3">
	          <p class="mb-1 text-xs mb-2"><strong>How do end-users consent to receive messages? (40-2048 characters)</strong></p>
	          
	          <textarea name="text" v-model="end_users_consent" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="height: 60px;"></textarea>

	          <p class="text-xs text-gray-500 mt-2">This field should describe how end users opt-in to the campaign, therefore giving consent to the sender to receive their messages. If multiple opt-in methods can be used for the same campaign, they must all be listed.</p>
	        </div>

	        <div class="mb-3 mt-3">
	          <p class="mb-1 text-xs mb-2"><strong>Opt-in Keywords (max 255 characters)</strong></p>
	          
	          <input name="text" v-model="opt_in_keywords" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" type="text" />

	          <p class="text-xs text-gray-500 mt-2">Add opt-in keywords, separated by a comma.</p>
	        </div>

	        <div class="mb-3 mt-3">
	          <p class="mb-1 text-xs mb-2"><strong>Opt-in Message</strong></p>
	          
	          <textarea name="text" v-model="opt_in_message" class="rg-text-field block appearance-none focus:outline-none focus:border-gray-500 w-full bg-white hover:border-grey px-2 py-2 rounded" style="height: 60px;"></textarea>

	          <p class="text-xs text-gray-500 mt-2">If end users can text in a keyword to start receiving messages from your campaign, the auto-reply messages sent to the end users must be provided.</p>
	        </div>
	       </div>

      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4">

        <button v-show="!success" class="rg-dialog-button-save rounded text-white text-xs text-bold focus:outline-none py-2 px-4 float-right" :class="{'spinner': sending}" @click="save">
            SUBMIT
        </button>

        <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="hide">
            CLOSE
        </button>

        <div class="clearfix"></div>

      </div>

    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import countries from '@/config/countries';

export default {
  props: ['inbox'],

  data: function() {
    return {
      showModal: false,
      sending: false,
      success: null,
      error: null,

      country: "US",

      countries: countries,

      numbers_found: [],

      messaging_service_type: 'Create new Messaging Service',
      existing_messaging_service_id: "",

      campaign_description: "This campaign is used to send out marketing messages, and other promotional purposes.",

      sample_message_1: "Hey! Mike here. Just wanted to let you know that today's the last day to get in on our promo.",
      sample_message_2: "Hey! Mike here. I just sent out an email re a free workbook. Just wanted to make sure you don't miss it.",

      message_contains_links: true,
      message_contains_phone_numbers: false,

      end_users_consent: "End users opt-in during sign up and they check a box asking if they wish to receive SMS updates and promotional messages.",

      opt_in_keywords: "CANCEL, END, QUIT, UNSUBSCRIBE, STOP, STOPALL",

      opt_in_message: "You have successfully been opted-in. For help, reply HELP. To opt-out, reply STOP."

    }
  },

  computed: {
    ...mapState({
      me: state => state.me.me
    })
  },
  methods: {
    show() {
      this.showModal = true
    },
    hide() {
      this.showModal = false
      this.sending = false
    },
    async save() {
    	let self = this

      if (this.messaging_service_type == 'Use existing Messaging service') {
      	this.error = "Please enter messaging ID.";
      	return;
      }

      if (!this.campaign_description) {
      	this.error = "Please add campaign description.";
      	return;
      }

      if (!this.sample_message_1) {
      	this.error = "Please add sample message #1.";
      	return;
      }

      if (!this.sample_message_2) {
      	this.error = "Please add sample message #2.";
      	return;
      }

      if (!this.end_users_consent) {
      	this.error = "Please specify user consent.";
      	return;
      }

      if (!this.opt_in_keywords) {
      	this.error = "Please add at least one opt-out keyword.";
      	return;
      }

      this.sending = true
      this.success = null
      this.error = null

      setTimeout(function() {
      	self.sending = false
      	self.success = "Your registration has been submitted and is under review, may take several weeks to complete. You may check back on this registration status in a couple of days <a href='https://console.twilio.com/us1/develop/sms/regulatory-compliance/campaigns' target='a2p-link'>Here</a>.";
      }, 2500);

      return;

      try {

        let formData = new FormData();
        formData.append("country", this.country);
        formData.append("area_code", this.$refs.area_code.value);

        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/search-phone-numbers`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
              'Content-Type': 'multipart/form-data'
            }
        })

        //this.hide()
        // this.hide()
        // this.$store.state.contacts.fetchingContacts = true

        this.numbers_found = response.data.numbers;

      } catch (e) {
        for (var i in e.response.data.errors) {
            if (e.response.data.errors[i] != 'null') {
              console.log(e.response.data.errors[i]);
              this.error = (this.error || '') + e.response.data.errors[i] + "<br />";
            }
        }
      }
      this.sending = false
    },
    async buyNumber(phoneNumber) {
      this.sending = true
      this.success = null
      this.error = null
      try {

        let formData = new FormData();
        formData.append("phoneNumber", phoneNumber);

        if (this.inbox)
          formData.append("inbox", 1);

        let response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agency/buy-number`, formData, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.jwt,
              'Content-Type': 'multipart/form-data'
            }
        })

        this.hide()


        if (this.inbox) {
          window.location = window.location.href;
        }
        else {
          this.$parent.$parent.getTwilioNumbers();
        }

      } catch (e) {
        console.log(e);
        for (var i in e.response.data.errors) {
            if (e.response.data.errors[i] != 'null') {
              console.log(e.response.data.errors[i]);
              this.error = (this.error || '') + e.response.data.errors[i] + "<br />";
            }
        }
      }
      this.sending = false
    }
  }
}
</script>
