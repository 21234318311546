<template>

  <div>
    <panel-navigation>

      <div v-if="show_campaign_log" @click.self="show_campaign_log = false" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
      <div class="w-full sm:w-1/2 lg:w-1/2 bg-white rounded-lg mx-6">

        <div class="rg-modal-header">
          Show Contact log for: {{ log_campaign.title }}

          <a href="javascript: void(0);" class="rg-modal-close-btn" @click="show_campaign_log = false"><i class="fa fa-times"></i></a>
        </div>

        <div class="p-6">
          <div class="mb-3">
            <table class="w-full rg-datatable">
              <thead class="border-b border-gray-100 w-full">
                <tr>
                  <th v-show="log_campaign.operation_type == 'bulk_sms'">Phone</th>
                  <th v-show="log_campaign.operation_type == 'bulk_email'">Email</th>
                  <th>Status</th>
                  <th>Error Message</th>
                  <th>Processed At</th>
                </tr>
              </thead>
              <tbody class="w-full" style="">
                <tr v-for="(item, index) in log_campaign.contacts" :key="'ba-log-' + index" class="hover:bg-gray-100">
                  <td v-show="log_campaign.operation_type == 'bulk_sms'">{{ item.phone }}</td>
                  <td v-show="log_campaign.operation_type == 'bulk_email'">{{ item.email }}</td>
                  <td>
                    <v-chip v-if="item.status == 'failed'" color="red" small text-color="white">failed</v-chip>
                    <v-chip v-if="item.status == 'success'" color="green" small text-color="white">delivered</v-chip>
                  </td>
                  <td>
                    {{ item.error_message || "" }}
                  </td>
                  <td>
                    {{ formatDate(item.processed_at) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="bg-gray-100 rounded-b-lg px-6 py-4">

          <button class="rounded text-blue text-xs text-bold border border-blue focus:outline-none py-2 px-4 mr-2 float-right" @click="show_campaign_log = false">
              Close
          </button>

          <div class="clearfix"></div>

        </div>

      </div>
    </div>

      <div class="float-right ml-5 mt-1" v-if="!fetchingCampaigns && campaigns.length > 0">
        <!-- <div class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2"><i class="fa fa-plus mr-2"> </i> ADD NEW CAMPAIGN</div> -->

        <!-- <div
          class="border-1 border-blue bg-blue text-white text-xs text-bold px-4 py-2 rounded cursor-pointer float-right ml-2" @click="createNewCampaign()"><i class="fa fa-plus mr-2"> </i> BULK ACTIONS
        </div> -->
      </div>
      
      <h1 class="text-lg font-semibold mb-3">Bulk Actions</h1>

      <hr class="border-b border-gray-100 mb-3">

      <v-skeleton-loader v-if="fetchingCampaigns" type="article, article, article"></v-skeleton-loader>

      <div class="dashboard-box" v-if="!fetchingCampaigns && campaigns.length == 0">
        <v-card
          class="pa-2"
          tile
        >
          <div class="p-3">

            <br /><br /><br /><br />

            <div class="dashboard-box-iconsquare">
              <div class="dashboard-box-icon-background"></div>
              <div class="dashboard-box-icon">
                <i class="mdi mdi-briefcase-outline"></i>
              </div>
            </div>

            <div style="text-align: center;">
              <div class="pt-2 pb-2">
                <strong>No Actions Yet</strong>
              </div>

              <div class="text-sm mb-4 text-gray-600">
                You have not created any bulk actions campaigns yet. Use our tools to send out bulk sms and email.
              </div>

              <br />

              <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 inline-block rounded cursor-pointer mr-3" @click="$router.push('/contacts')" style="margin-top: -5px;"><i class="fa fa-plus mr-2"></i> GO TO CONTACTS</div>
            </div>
            
            <br /><br /><br /><br /><br />
          </div>
        </v-card>
      </div>

      <template v-if="!fetchingCampaigns && campaigns.length > 0">
        <div class="dashboard-box">
          <v-card
            class="pa-2"
            tile
          >
            <div class="p-3">
              <div style="clear: both; padding-top: 0px;">
                <v-data-table
                  :headers="headers"
                  :items="campaigns"
                  :items-per-page="20"
                  v-model="selected"
                  class="rg-datatable elevation-1"
                  :footer-props="{
                  itemsPerPageOptions: [1, 5, 10, 20, 30, 40, 50, 100],
                  showFirstLastPage: true
                  }"
                  mobile-breakpoint="10"
                  show-select
                  :search="search"
                >
                  <template v-slot:top>
                    <div class="justify-between items-start mb-3 float-right">
                      <v-menu
                        bottom
                        left
                        nudge-top="-35"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="flex flex-row items-center px-4 py-2 rounded border-1 border-gray-500 text-xs text-gray-600 cursor-pointer">
                            <span class="mr-2">Actions</span>
                            <span class="text-xs" style="padding-top: 3px;">
                              <font-awesome-icon icon="chevron-down"  />
                            </span>
                          </div>
                        </template>

                        <v-list class="table-menu-actions">
                          <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="bulkDelete()"><span class="table-menu-action-icon"><i class="fa fa-trash"></i></span> Delete</a></v-list-item-title></v-list-item>
                        </v-list>
                      </v-menu>
                    </div>

                    <div style="clear: both;"></div>

                    <!-- <v-text-field
                      v-model="search"
                      clearable
                      outlined
                      dense
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      style="max-width: 200px; margin-bottom: 20px;"
                    ></v-text-field> -->
                  </template>

                  <template v-slot:item.operation_type="{ item }">
                    {{ item.operation_type.replace(/\_/g, ' ') }}
                  </template>

                  <template v-slot:item.title="{ item }">
                    <a href="javascript: void(0);" @click="viewLog(item)">{{ item.title }}</a>
                  </template>

                  <template v-slot:item.status="{ item }">
                    <v-chip v-if="item.status == 'draft'" color="gray" small>draft</v-chip>

                    <v-chip v-if="item.status == 'scheduled'" color="purple" small text-color="white">scheduled</v-chip>

                    <v-chip v-if="item.status == 'running'" color="orange" small text-color="white">running</v-chip>

                    <v-chip v-if="item.status == 'completed'" color="green" small text-color="white">completed</v-chip>
                  </template>

                  <template v-slot:item.scheduled_on="{ item }">
                    <span v-if="item.status == 'scheduled'">
                      {{ formatDate(item.scheduled_on, item.timezone) }}
                    </span>
                  </template>

                  <template v-slot:item.updated_at="{ item }">
                    <span v-if="item.status != 'draft' && item.status != 'scheduled'">
                      {{ formatDate(item.updated_at, item.timezone) }}
                    </span>
                  </template>

                  <template v-slot:item.id="{ item }">
                    <div style="text-align: right;">
                      <v-menu
                        bottom
                        left
                        nudge-top="-35"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="ml-3"
                            color="grey darken-1"
                          >
                            <i class="mdi mdi-dots-vertical"></i>
                          </v-btn>
                        </template>

                        <v-list class="table-menu-actions">
                          <!-- <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="$router.push('/contacts/bulk-sms/' + item.id)"><span class="table-menu-action-icon"><i style="font-size: 16px;" class="mdi mdi-pen"></i></span> Edit</a></v-list-item-title></v-list-item> -->
                          <v-list-item v-if="item.status == 'completed'" class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="viewLog(item)"><span class="table-menu-action-icon"><i style="font-size: 16px;" class="mdi mdi-file-document-outline"></i></span> View Log</a></v-list-item-title></v-list-item>
                          <!-- <v-list-item class="hover:bg-gray-200"><v-list-item-title><a href="javascript: void(0);" @click="createNewCampaign(item)"><span class="table-menu-action-icon"><i style="font-size: 16px;" class="mdi mdi-content-copy"></i></span> Duplicate</a></v-list-item-title></v-list-item> -->
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                </v-data-table>
              </div>
          </div>
          </v-card>
        </div>
      </template>

    </panel-navigation>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import PanelNavigation from '@/components/PanelNavigation.vue'
import moment from 'moment-timezone'

export default {
  metaInfo () {
    return {
      title: "Bulk Actions | " + this.$appName
    }
  },
  components: {
    PanelNavigation
  },
  data() {
    return {
      selected: [],
      selectedIds: [],

      review_funnel: null,
      contact_form: null,

      campaignsInt: null,

      show_campaign_log: false,

      log_campaign: null,

      headers: [
        { text: 'Name', value: 'title' },
        { text: 'Operation Type', value: 'operation_type' },
        { text: 'Total Contacts', value: 'total_contacts' },
        { text: 'Total Completed', value: 'total_completed' },
        { text: 'Total Failed', value: 'total_failed' },
        { text: 'Total Success', value: 'total_success' },
        { text: 'Status', value: 'status' },
        // { text: 'Default Reviews Campaign', value: 'default' },
        { text: 'Scheduled On', value: 'scheduled_on' },
        { text: 'Sent On', value: 'updated_at' },
        { text: '', value: 'id', sortable: false }
      ],

      searchLocal: "",
      search: ""
    }
  },
  computed: {
    ...mapState({
      me: state => state.me.me,
      campaigns: state => state.bulk_sms.campaigns,
      fetchingCampaigns: state => state.bulk_sms.fetchingCampaigns
    })
  },
  methods: {
    viewLog(log_campaign) {
      this.show_campaign_log = true;
      this.log_campaign = log_campaign;
    },
    async bulkDelete() {
      let self = this;

      if (this.selectedIds.length > 0) {
        await this.$store.dispatch('bulk_sms/bulkDelete', {ids: this.selectedIds})
        this.$store.dispatch('bulk_sms/fetch')
        this.selected = []
        this.selectedIds = []
      }
    },

    async getGetReviewsCampaign() {
      this.getReviewsCampaignLoaded = false;
      
      try {
        axios.get(process.env.VUE_APP_API_ENDPOINT + '/me/get-get-reviews-campaign', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        })

      }catch(e){
        console.log(e)
      }
    },

    async createNewCampaign(duplicated_campaign = null) {
      let self = this;
      
      try {
        axios.post(process.env.VUE_APP_API_ENDPOINT + '/bulk-sms/create', {
          duplicated_campaign: duplicated_campaign
        }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        }).then(function(response) {
          self.$router.push("/contacts/bulk-sms/" + response.data.campaign.id);
        });

      }catch(e){
        console.log(e)
      }
    },

    async setDefault(campaign) {
      let self = this;
      
      try {
        axios.post(process.env.VUE_APP_API_ENDPOINT + '/campaigns/set-default', {
          id: campaign.id
        }, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.jwt
          }
        }).then(function(response) {
          self.$store.dispatch('bulk_sms/fetch')
        });

      }catch(e){
        console.log(e)
      }
    },

    formatDate: function(date, timezone) {
      return moment.utc(String(date)).format(this.me.settings.date_format + " h:mm:ss a")
    },
  },
  watch: {
    selected: {
      handler() {
        this.selectedIds = [];

        for (var i in this.selected) {
          this.selectedIds.push(this.selected[i].id);
        }

        console.log('this.selectedIds', this.selectedIds)
      }
    }
  },
  created() {
    let self = this
    this.$store.state.campaigns.fetchingCampaigns = true
    this.$store.dispatch('bulk_sms/fetch')

    this.campaignsInt = setInterval(function() {
      self.$store.dispatch('bulk_sms/fetch')
    }, 10000);
  },

  destroy() {
    clearInterval(this.campaignsInt);
    this.campaignsInt = null;
  }
}
</script>
