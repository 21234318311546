<template>
    <div class="badge-1-wrapper" :style="((widget_data.widget.settings.badge_display == 'Embed' ? 'margin: auto;': ''))">
        <v-card
            elevation="2"
            class="reviews-badge"
            @click="$parent.showDialog()"
            rounded
            :style="'border-radius: 15px !important; background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';'"
        >
            <div class="text-center pt-1 pb-1">
                <img v-for="(item, index) in widget_data.widget.settings.listings" :key="index" v-show="listingEnabled(item.id)"
                    :src="getIconUrl(item.url)"
                    class="listing-img"
                >

                <div class="clearfix"></div>

                <div class="reviews-average -mb-2" style="display: inline-block;">
                    {{ widget_data.average_rating }}
                </div>

                <div style="display: inline-block; vertical-align: super;">
                    <v-rating
                        class="reviews-rating"
                        :value="parseFloat(widget_data.average_rating)"
                        :color="widget_data.widget.settings.star_color"
                        dense
                        half-increments
                        readonly
                        size="26"
                    ></v-rating>
                </div>

                <div class="reviews-title text-underline reviews-read-link text-xs pt-2">
                    {{ ((widget_data.widget.settings.badge_1_title) ? widget_data.widget.settings.badge_1_title.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "From " + widget_data.total_reviews + " verified reviews") }}
                </div>
            </div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import urlParser from 'url-parse'

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },
    
    methods: {
        getHostname(url) {
            return urlParser(url).hostname.replace("www.", "")
        },
        getPathname(url) {
            return urlParser(url).pathname
        },
        getIconUrl(url) {
            return require('@/assets/images/review-services/www.' + this.getHostname(url) + ".png");
        },
        listingEnabled(id) {
            console.log('id', id);
            console.log('this.widget_data.widget.settings.platforms', this.widget_data.widget.settings.platforms);
            for (var i in this.widget_data.widget.settings.platforms) {
                if (this.widget_data.widget.settings.platforms[i] == id)
                    return true;
            }

            return false;
        }
    }
}
</script>

<style type="text/css" scoped>
    .badge-1-wrapper {
        width: 210px;
        padding-bottom: 10px;
    }

    .listing-img {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 5px;
    }
</style>